import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';

export const Columns = (data, handleEdit, isReadOnly, translate): SisDataGridColumns => [
  {
    dataField: 'customId',
    caption: 'Revision cycle id',
    width: '7%',
    allowEditing: false,
  },
  {
    dataField: 'capacityCategoryId',
    caption: 'Capacity category',
    width: '10%',
    allowEditing: true,
    cellRender: (data) => data.row.data.capacityCategoryName,
    requiredRule: true,
  },
  {
    dataField: 'revisionCategoryId',
    caption: 'Revision category',
    width: '15%',
    cellRender: (data) => data.row.data.revisionCategoryName,
    requiredRule: true,
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '11%',
  },
  {
    dataField: 'revisionStandard',
    caption: 'Revision standard',
    width: '19%',
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '19%',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, isReadOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, readOnly, translate) => {
  return (
    !readOnly && (
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={() => handleEdit(cellData)}
      />
    )
  );
};
