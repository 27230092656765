import moment from 'moment';
import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';
import { dateFieldOptionsWithSerialization } from '../../../utils/dates.types';

export const Columns = (
  handleEdit,
  isReadOnly,
  translate,
  dateFormat,
  dateTimeFormat,
  transFormName,
): SisDataGridColumns => [
  {
    dataField: 'id',
    caption: 'Measured id',
    dataType: IDataType.string,
    width: '5%',
  },
  {
    dataField: 'assetParameterName',
    caption: 'Parameter',
    dataType: IDataType.string,
    width: '23%',
  },
  {
    dataField: 'unitName',
    caption: 'Unit',
    dataType: IDataType.string,
    width: '20%',
  },
  {
    dataField: 'value',
    caption: 'Value',
    dataType: IDataType.string,
    cellRender: (data) => {
      if (data.row.data.valueBool !== null)
        return data.row.data.valueBool
          ? translate!('True', transFormName)
          : translate!('False', transFormName);
      if (data.row.data.valueInt) return data.row.data.valueInt;
      if (data.row.data.valueFloat) return data.row.data.valueFloat;
      if (data.row.data.valueDate) return moment(data.row.data.valueDate).format(dateFormat());
      if (data.row.data.valueDateTime)
        return moment(data.row.data.valueDateTime).format(dateTimeFormat());
      if (data.row.data.valueText) return data.row.data.valueText;
      if (data.row.data.value) return data.row.data.value;
      return null;
    },
    width: '22%',
  },
  {
    dataField: 'measureToDate',
    dataType: IDataType.date,
    caption: 'Measure to date',
    width: '10%',
    editorOptions: dateFieldOptionsWithSerialization,
  },
  {
    dataField: 'created',
    dataType: IDataType.dateTime,
    caption: 'Created',
    width: '10%',
  },
  {
    dataField: 'visible',
    caption: 'Visible',
    dataType: IDataType.boolean,
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, isReadOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, readOnly, translate) => {
  return (
    !readOnly && (
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={() => handleEdit(cellData)}
      />
    )
  );
};
