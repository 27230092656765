import { Switch } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translation';
import { useRowSelectionSync } from '../../hooks/useRowSelectionSync';
import { Columns as ModalColumns } from '../../pages/Assets/Columns/AssetsBulkEditColumns';
import { Columns } from '../../pages/Assets/Columns/AssetsColumns';
import { Asset } from '../../types/asset.types';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import AssetsBulkEditModal from '../modals/AssetsBulkEditModal';
import { SelectionMode, SisDataGrid } from '../table';
export interface ITreeViewDataGrid extends IDataGrid {
  setIsTreeView: (e: any) => void;
}

export interface IDataGrid {
  dataGridRef?: React.Ref<any>;
  data: any;
  handleEdit?: (e) => void;
  handleCopy?: (e) => void;
  onToolbarPreparing?: (e: any) => void;
  onSaving?: (e: any) => void;
  resetFilter?: () => void;
  mutateData?: () => void;
}

export const AssetsDataGrid = ({
  dataGridRef,
  data,
  handleEdit,
  handleCopy,
  onToolbarPreparing,
  setIsTreeView,
  mutateData,
}: ITreeViewDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.List';
  const transFormNameBulkChanges = 'Bulk.Change';

  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Array<Asset>>([]);
  const { rows, onRowsChange } = useRowSelectionSync(STORAGE_KEYS.ASSETS_ROW_SELECTION);

  useEffect(() => {
    if (isEditModalVisible) {
      setSelectedRows(data?.filter((item) => rows?.map((x) => x).includes(item.id)));
    }
  }, [data, isEditModalVisible]);

  const onToolbarMerge = async (e) => {
    onToolbarPreparing && onToolbarPreparing(e);
    e.toolbarOptions.items.splice(1, 0, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'edit',
        type: 'default',
        text: translate!('Bulk changes', transFormNameBulkChanges),
        stylingMode: 'outlined',
        onClick: () => setEditModalVisible(true),
        disabled: rows?.length === 0,
      },
    });
  };

  const onSelectionChanged = async (e: any) => {
    // @ts-ignore
    if (!dataGridRef || !dataGridRef?.current) return;
    // @ts-ignore
    const keys = await dataGridRef?.current?.instance.getSelectedRowKeys();
    onRowsChange(keys);
  };

  const columns = Columns(handleEdit, handleCopy, translate);
  const modalColumns = ModalColumns();

  return (
    <>
      <SisDataGrid
        enableXlsxExport
        exportName={'assets-data'}
        dataGridRef={dataGridRef}
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={columns}
        onToolbarPreparing={onToolbarMerge}
        stateStoringKey={STORAGE_KEYS.ASSETS_DATAGRID_DESKTOP.toString()}
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeChangeVisible={true}
        enableGrouping
        selectedRowKeys={rows}
        selectionMode={SelectionMode.MULTIPLE}
        onSelectionChanged={onSelectionChanged}
      >
        {isEditModalVisible && (
          <AssetsBulkEditModal
            data={isEditModalVisible ? selectedRows : []}
            isVisible={isEditModalVisible}
            mutate={mutateData}
            onClose={() => setEditModalVisible(false)}
            columns={modalColumns}
            transFormNameColumns={transFormName}
          />
        )}
        <Template
          name="totalGroupCount"
          render={() => (
            <div>
              <span>{translate!('Tree view', '')}</span>
              <Switch
                style={{ marginLeft: '5px' }}
                onValueChange={setIsTreeView}
                defaultValue={false}
              />
            </div>
          )}
        />
      </SisDataGrid>
    </>
  );
};
