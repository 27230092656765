import React, { useEffect, useRef, useState } from 'react';
import { AssetNfcTagsDataGrid } from '../../../components/datagrid/AssetNfcTagsDataGrid';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { NfcTag } from '../../../types/assignTagToAsset.types';
import { ITab } from '../AssetsEdit';

interface Props extends ITab {
  nfcTags: NfcTag[];
  mutateNfcTags: any;
  isReadOnly: boolean;
}

export const AssetTabNfcTags = ({ active, nfcTags, mutateNfcTags, isReadOnly }: Props) => {
  const dataGridRef = useRef<any>(null);

  useEffect(() => {
    if (active) mutateNfcTags();
  }, [active]);

  return (
    <PageContentWrapper noContentBlock noDxCard>
      <AssetNfcTagsDataGrid
        data={nfcTags}
        dataGridRef={dataGridRef}
        mutateData={mutateNfcTags}
        isReadOnly={isReadOnly}
      />
    </PageContentWrapper>
  );
};
