import React, { useEffect, useState } from 'react';
import { IDataType, SisDataGrid, SisDataGridColumns } from '../../components/table';
import TaskHistoryFilter from '../../components/taskHistoryFilter/TaskHistoryFilter';
import { useTranslation } from '../../contexts/translation';
import { TaskHistory as TaskHistoryType } from '../../types/task.types';
import { TaskHistoryItem } from './TaskHistoryItem';

export enum TaskHistoryVariant {
  SIMPLE = 'simple',
  EXTENDED = 'extended',
}

type Props = {
  initialData: Array<TaskHistoryType>;
  taskId: string;
  variant?: TaskHistoryVariant;
  disableOnToolbarPreparing?: boolean;
  columnsWidth?: number[];
};

export const TaskHistory = ({
  initialData,
  taskId,
  variant = TaskHistoryVariant.SIMPLE,
  disableOnToolbarPreparing,
  columnsWidth,
}: Props) => {
  const transFormName = 'Task.History';
  const { translate } = useTranslation();
  const [filteredData, setFilteredData] = useState<Array<TaskHistoryType>>([]);
  const [terminalData, setTerminalData] = useState<Array<TaskHistoryType>>([]);

  const composeText = (text: string, params: string): string => {
    const args: string[] = params.split(';');
    let result: string = text;
    for (let i = 0; i < args.length; i++) {
      const regex: RegExp = new RegExp(`\\{${i}\\}`);
      result = result.replace(regex, args[i]);
    }
    return result;
  };

  useEffect(() => {
    if (!initialData) return;

    setTerminalData(
      initialData.map((item) => {
        return {
          ...item,
          description: composeText(translate!(item.description, transFormName), item.params ?? ''),
        };
      }),
    );
  }, [initialData]);

  if (variant === TaskHistoryVariant.EXTENDED)
    return (
      <>
        <TaskHistoryFilter
          initialData={initialData}
          taskId={taskId}
          setFilteredData={setFilteredData}
        />
        {filteredData.length > 0
          ? filteredData.map((item, idx) => (
              <TaskHistoryItem key={idx} data={item} variant={variant} composeText={composeText} />
            ))
          : initialData?.map((item, idx) => (
              <TaskHistoryItem key={idx} data={item} variant={variant} composeText={composeText} />
            ))}
      </>
    );

  const columns: SisDataGridColumns = [
    {
      dataField: 'description',
      caption: 'Description',
      width: columnsWidth ? columnsWidth[0] + '%' : '66%',
    },
    {
      dataField: 'created',
      caption: 'Created',
      width: columnsWidth ? columnsWidth[1] + '%' : '17%',
      dataType: IDataType.dateTime,
      format: 'dd.MM.yy HH:mm',
    },
    {
      dataField: 'createdByName',
      caption: 'Created by',
      width: columnsWidth ? columnsWidth[2] + '%' : '17%',
    },
  ];

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Task history', transFormName),
    });
  };

  return (
    <SisDataGrid
      onToolbarPreparing={!disableOnToolbarPreparing ? onToolbarPreparing : undefined}
      allowColumnReordering={false}
      allowColumnResizing={false}
      columnChooser={false}
      filterRow={false}
      data={terminalData}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={columns}
    />
  );
};
