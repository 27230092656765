import React from 'react';

interface Props {
  title: string;
  children?: any;
}

export function HomePageBlock({ title, children }: Props) {
  return (
    <div style={{ padding: '5px', textAlign: 'center' }}>
      <div className={'dx-card responsive-paddings'}>
        <div style={{ fontSize: '18px', marginBottom: '5px' }}>{title}</div>
        {children}
      </div>
    </div>
  );
}

export function HomePageBlockCount({ count }: { count: number }) {
  return <div style={{ fontWeight: 'bold', fontSize: '30px' }}>{count}</div>;
}
