import React, { createContext, useContext, useEffect, useState } from 'react';
import { getListApi } from '../apiUtils';
import { Loader } from '../components/loader/Loader';
import { Report } from '../types/report.types';
import { RouteType } from '../types/route.types';
import { API_URL } from '../utils/apiUrl';

interface IRoutingContext {
  routes: Array<RouteType>;
}

const RoutingContext = createContext<IRoutingContext>({
  routes: [],
});

interface Props {
  children: any;
}

export const RoutingProvider = ({ children }: Props) => {
  const [routes, setRoutes] = useState<Array<RouteType>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getMenu = async () => {
    setLoading(true);

    await getListApi<Array<Report>>({
      url: API_URL.MENU_LIST,
      params: {},
      callAfterSuccess: (result) => {
        let routes = result
          .filter((x) => !x.parentId)
          .map((item) => {
            return {
              id: item.id,
              text: item.name,
              path: item.actionUrl,
              icon: item.icon,
              restrictedAccess: item.actionRequiredRole && item.actionRequiredRole.split(','),
              items: [],
            };
          });

          
        result
          .filter((x) => x.parentId && x.visible)
          .forEach((item) => {
            const parent = routes.find((x) => x.id === item.parentId && item.visible);
            if (parent)
              parent.items = [
                ...parent.items,
                {
                  id: item.id,
                  text: item.name,
                  path:
                    item.actionUrl +
                    (item.reportSettingId !== null ? '/' + item.reportSettingId : ''),
                  icon: item.icon,
                  restrictedAccess: item.actionRequiredRole && item.actionRequiredRole.split(','),
                },
              ];
          });
        setRoutes(routes);
      },
    });

    setLoading(false);
  };

  useEffect(() => {
    getMenu();
  }, []);

  return (
    <RoutingContext.Provider
      value={{
        routes,
      }}
    >
      {loading ? <Loader /> : children}
    </RoutingContext.Provider>
  );
};

export const useRouting = () => {
  return useContext<IRoutingContext>(RoutingContext);
};
