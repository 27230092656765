/* eslint @typescript-eslint/no-use-before-define: 0 */
import { Box, Button, TreeView } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import DropDownBox from 'devextreme-react/drop-down-box';
import notify from 'devextreme/ui/notify';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteApi, getItemApi, getListApi, postApi, putApi } from '../../apiUtils';
import { useAuth } from '../../contexts/auth';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { useClearButtonProps } from '../../hooks/useClearButtonProps';
import { getParameters, parseFilterParameters, setParameters } from '../../hooks/useField';
import { useSaveDataGridFilter } from '../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { ReportSetting, ReportSettingInput } from '../../types/reportSetting.types';
import { API_URL } from '../../utils/apiUrl';
import './ReportSelector.scss';

export enum FilterActionState {
  NEW = 'Filter.New',
  EDIT = 'Filter.Edit',
}

type ReportType = {
  id: number;
  key: string;
};
export interface Props {
  fields: Array<any>;
  dataGridRef: any;
  filterName: string;
  onClear: () => void;
  onFilterChange: (filter: string | null) => void;
  report: ReportType;
  openDialog: (action: FilterActionState | null) => void;
  fetchData: (params: any) => void;
  isDefault?: boolean;
  setIsDefault: (value: boolean) => void;
  loadDefaultSetting: boolean;
  loadDefaultSettingStorageKey: string;
}

const ReportFilterSelector = forwardRef(
  (
    {
      fields,
      filterName,
      dataGridRef,
      report,
      onClear,
      onFilterChange,
      openDialog,
      fetchData,
      isDefault = false,
      setIsDefault,
      loadDefaultSetting,
      loadDefaultSettingStorageKey,
    }: Props,
    ref,
  ) => {
    const [filters, setFilters] = useState<Array<ReportSetting>>([]);
    const [filterId, setFilterId] = useState<any>(null);
    const [selectedFilter, setSelectedFilter] = useState<any>(null);
    const [filterBoxOpened, setFilterBoxOpened] = useState<boolean>(false);
    const treeViewRef = useRef<any>(null);
    const { user } = useAuth();
    const userId = user?.personalNumber ?? '';
    const { loadFilter, saveFilter, getActualJsonState } = useSaveDataGridFilter(
      report.key,
      dataGridRef,
    );
    const transFormName = 'Reports.ReportSelector';
    const { translate } = useTranslation();
    const clearButtonProps = useClearButtonProps();
    const { startLoading, loading, stopLoading } = useLoading();

    const { pathname } = useLocation();
    const settingId = pathname.split('/')[3];

    const [filterSettingSession, setFilterSettingSession] = useSessionStorage(
      loadDefaultSettingStorageKey,
      {},
    );

    /**
     * Fetch všech filtrů daného typu z API pri mountu komponenty
     */
    useEffect(() => {
      if (filters) {
        handleDefaultFilterSelection(filters);
      }
    }, [pathname]);

    /**
     * Fetch všech filtrů daného typu z API pri mountu komponenty
     */
    useEffect(() => {
      getFavoriteFilters();
    }, []);

    const handleDefaultFilterSelection = (filters: Array<ReportSetting>) => {
      const defaultFromUrl = filters.find((item) => item.id === Number(settingId));
      const defaultGlobal = filters.find((item) => !item.user || item.user === 0);
      const defaultUser = filters.find((item) => item.isDefault && item.user === userId);
      const defaultFilter = defaultFromUrl ?? defaultUser ?? defaultGlobal;
      setSelectedFilter(defaultFilter);
      setFilterId(defaultFilter?.id ?? null);
    };

    /**
     * Slouží pro možnost volání funkcí childu z parenta
     */
    useImperativeHandle(ref, () => ({
      /**
       * Funkce pro smazání vybraného filtru
       */
      async deleteFilter() {
        startLoading();
        await deleteApi({
          url: `${API_URL.REPORT_SETTING_DELETE}`,
          id: filterId,
          callAfterSuccess: () => {
            const newFilters: ReportSetting[] = [...filters];
            const idx = newFilters.findIndex((item) => item.id === filterId);
            newFilters.splice(idx, 1);
            setFilters(newFilters);
            setFilterId(-1);
            setSelectedFilter(null);
            openDialog(null);
            stopLoading();
          },
        });
      },

      /**
       * Funkce pro přidání nebo editaci filtru
       * @param actionState informace o tom jestli se jedná o přidání nového filtru nebo editaci stávajícího
       */
      async saveOrEditFilter(actionState: FilterActionState) {
        startLoading();
        const filterParams = getParameters(fields);
        const dataGridState = JSON.parse(getActualJsonState(true) ?? '');
        const data: ReportSettingInput = {
          reportId: report.id,
          name: filterName,
          user: user?.personalNumber ?? null,
          isDefault: isDefault!,
          setting: JSON.stringify({
            filter: filterParams,
            dataGrid: dataGridState,
          }),
        };

        if (actionState === FilterActionState.EDIT) {
          // edit API action
          const sendData = {
            ...data,
            id: filterId,
            isDefault: isDefault!,
          };

          const response = await putApi({
            url: API_URL.REPORT_SETTING_PUT,
            data: sendData,
            hideNotifications: true,
          });
          if (response !== null) {
            getFavoriteFilters();
            setSelectedFilter(sendData.id);
            setFilterId(sendData.id);
            openDialog(null);
          }
        } else {
          // create API action
          const response = await postApi<ReportSetting>({
            url: API_URL.REPORT_SETTING_POST,
            data,
            hideNotifications: true,
            callAfterError: (result) => {
              notify(
                translate!(
                  'Filter setting with this name already exists, select another.',
                  'Reports.ReportSelector',
                ),
                'error',
              );
            },
          });
          if (response) {
            const newFilters: ReportSetting[] = [...filters, response];
            setFilters(newFilters);
            setSelectedFilter(response.id);
            setFilterId(response.id);
            openDialog(null);
          }
        }
        stopLoading();
      },
    }));

    /**
     * Funkce pro nastavené vybraného filtru do UI (fieldy + datagrid) při změně filtru
     */
    useEffect(() => {
      if (filterId !== -1) {
        applyFilter(filterId);
      }
    }, [filterId]);

    /**
     * Fetch všech filtrů daného typu z API
     */
    const getFavoriteFilters = async () => {
      await getListApi<Array<ReportSetting>>({
        url: `${API_URL.REPORT_SETTING_LIST_FOR_USER}`,
        params: {
          userId,
          reportId: report.id,
        },
        callAfterSuccess: (result: Array<ReportSetting>) => {
          setFilters(result);
          handleDefaultFilterSelection(result);
        },
      });
    };

    /**
     * Funkce pro fetch detailu vybraného filtru a nastavení parametrů pro fieldy a datagrid
     * @param filterId id filtru
     */
    const applyFilter = async (filterId) => {
      if (filterId) {
        await getItemApi<ReportSetting>({
          url: API_URL.REPORT_SETTING_ITEM,
          params: {
            id: filterId.id ?? filterId,
          },
          callAfterSuccess: async (result) => {
            const filterSetting = JSON.parse(result.setting);
            const { filter, dataGrid } = filterSetting;
            const currentFilter = !loadDefaultSetting ? filterSettingSession : filter;
            onFilterChange(result.name);
            setParameters(fields, currentFilter);
            setFilterSettingSession(currentFilter);
            if (loadDefaultSetting) {
              loadFilter(dataGrid);
              saveFilter();
            }
            openDialog(null);
            fetchData(parseFilterParameters(currentFilter));
            setIsDefault(result.isDefault);
          },
        });
      }
    };

    /**
     * Funkce pro zavření dropdownboxu po vybrání položky
     */
    const onFilterBoxOpened = (e) => {
      if (e.name === 'opened') {
        setFilterBoxOpened(e.value);
      }
    };

    /**
     * Funkce pro výběr filtru ze seznamu
     *  + pokud je zaznamenáno kliknutí na "clear X", volá se funkce pro nastavení výchozích hodnot
     */
    const onFilterSelect = (e) => {
      setFilterBoxOpened(false);
      syncTreeViewSelection(e);
      if (!e.value) {
        onClear();
        onFilterChange(null);
      }
    };

    /**
     * Nastavení výběru v TreeView komponentě po výběru položky ze seznamu
     */
    const syncTreeViewSelection = (e) => {
      if (!treeViewRef) return;
      setFilterId(e.value);
      if (e.value) {
        treeViewRef?.current?.instance.selectItem(e.value);
      }
    };

    /**
     * Obslužná funkce pro vrácení vybraného filtru ze seznamu
     */
    const getSelectedFilter = (filterId: number) => {
      return filters.find((filter) => filter.id === filterId);
    };

    /**
     * Listener TreeView komponenty po změně výběru v seznamu
     */
    const treeViewItemSelectionChanged = (e) => {
      setSelectedFilter(e.itemData);
      setFilterId(e.itemData?.id);
    };

    const treeViewOnContentReady = (e) => {
      e.component.selectItem(filterId);
    };

    const renderItem = (itemData: ReportSetting) => {
      const isGlobal = !itemData.user || itemData.user === 0;
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {isGlobal && <i className="dx-icon-globe icon" style={{ marginRight: 8 }}></i>}
          {!isGlobal && <i className="dx-icon-user icon" style={{ marginRight: 8 }}></i>}
          {itemData.name}
        </span>
      );
    };

    /**
     * Render TreeView komponenty pro DropDownBox
     */
    const treeViewRender = () => (
      <TreeView
        ref={treeViewRef}
        dataSource={filters}
        dataStructure="plain"
        selectionMode="single"
        keyExpr="id"
        selectByClick={true}
        searchEnabled={true}
        displayExpr="name"
        itemRender={renderItem}
        onContentReady={treeViewOnContentReady}
        onItemClick={() => setFilterBoxOpened(false)}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );

    return (
      <>
        <Box direction="row" width="100%">
          <Item ratio={0} baseSize="20%">
            <DropDownBox
              value={filterId}
              valueExpr="id"
              displayExpr="name"
              opened={filterBoxOpened}
              dataSource={filters}
              placeholder={translate!('Select a filter...', transFormName)}
              onValueChanged={onFilterSelect}
              onOptionChanged={onFilterBoxOpened}
              contentRender={treeViewRender}
              {...clearButtonProps}
            />
          </Item>
          <Item ratio={0} baseSize={100}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{ marginLeft: 16 }}
                text={translate!('Save as', transFormName)}
                type="success"
                onClick={() => openDialog(FilterActionState.NEW)}
              />
              <Button
                style={{ marginLeft: 16 }}
                text={translate!('Edit filter', transFormName)}
                type="success"
                onClick={() => openDialog(FilterActionState.EDIT)}
                disabled={
                  !filterId ||
                  !getSelectedFilter(filterId)?.user ||
                  getSelectedFilter(filterId)?.user !== user?.personalNumber
                }
              />
            </div>
          </Item>
        </Box>
      </>
    );
  },
);

ReportFilterSelector.displayName = 'ReportFilterSelector';

export default ReportFilterSelector;
