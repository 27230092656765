//** * Soubor s custom renderery */

// Custom renderer pro celkový čas - celkový počet minut -> hodiny:minuty
export const timespan = (timeString: string) => {
  const totalMinutes = Number(timeString);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

// Cell render funkce pro zobrazení hodnoty u buněk, kde je není rovna 0
export function AttachmentCountFormat(cellData) {
  return cellData.data[cellData.column.dataField] === 0
    ? ''
    : cellData.data[cellData.column.dataField];
}
