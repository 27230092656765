import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useTranslation } from '../../contexts/translation';
import { useSaveDataGridFilter } from '../../hooks/useSaveDataGridFilter';
import { ColumnsTaskWorkSheet } from '../../pages/WorkSheets/Columns';
import { paintRowByWorkSheetColor } from '../../pages/WorkSheets/utils/paintRowByColor';
import { Task } from '../../types/task.types';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  task?: Task;
}

export const TaskWorkSheetsDataGrid = ({
  task,
  data,
  handleEdit,
  dataGridRef,
  userCanCreate,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkSheet.List';
  const history = useHistory();

  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const config = useConfigByKey(CONFIG_KEYS.TASK_WORKSHEET_LIST_DATAGRID_DESKTOP);
  // Hook, který umožňuje manipulovat s nastavením DataGridu. Defaultně mu předáváme nastavení z localStorage.
  const { loadFilter, resetFilter, saveFilter, getActualJsonState } = useSaveDataGridFilter(
    STORAGE_KEYS.TASKS_WORKSHEET_DATAGRID_DESKTOP,
    dataGridRef,
  );

  const loadConfig = (config: any) => {
    if (config) {
      loadFilter(JSON.parse(config.value));
      saveFilter();
    }
  };

  useEffect(() => {
    if (config && resetFilterAction) {
      resetFilter();
      loadConfig(config);
      saveFilter();
    }

    // @ts-ignore
    if (!dataGridRef.current || !dataGridRef.current.instance) return;
    if (config && !getActualJsonState(false)) loadConfig(config);
  }, [config, resetFilterAction]);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () =>
            history.push({
              pathname: '/workSheets/new/0/' + task?.id,
              state: { backRoute: `/tasks/edit/${task?.id}` },
            }),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };
  return (
    <>
      <SisDataGrid
        enableXlsxExport
        exportName={'worksheet-data'}
        onRowPrepared={paintRowByWorkSheetColor}
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        columns={ColumnsTaskWorkSheet(handleEdit, translate)}
        onToolbarPreparing={onToolbarPreparing}
        resetFilter={() => setResetFilterAction(true)}
        stateStoringKey={STORAGE_KEYS.TASKS_WORKSHEET_DATAGRID_DESKTOP.toString()}
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeFromConfig={true}
      />
    </>
  );
};
