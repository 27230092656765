import { LoadPanel } from 'devextreme-react';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from './translation';

interface ILoadingContext {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  setLoading: (b: boolean) => void;
}
const LoadingContext = createContext<ILoadingContext>({
  startLoading(): void {},
  stopLoading(): void {},
  loading: false,
  setLoading: () => {},
});

interface Props {
  children: any;
}
export const LoadingProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useTranslation();
  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),
      }}
    >
      {loading && <LoadPanel visible={true} message={translate!('Loading', '')} />}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext<ILoadingContext>(LoadingContext);
};
