/**
 * Types pro nastavení dateboxů v celé aplikace. Cílem je formát data měnit v závilosti na vybraném jazyku.
 * Na API následně hodnoty posílat ve formátu yyyy-MM-DD HH:mm.
 * Úpravy pro mobilní verzi: V mobilní verzi nepoužíváme komponentu Form z důvodu custom html v těle.
 * Z toho důvodu je nutné rozlišovat date typy with/without serialization. Komponenta DateBox s atributem dateSerializationFormat nefunguje.
 * FIXME: V mobilní verzi již komponentu form používáme.
 */

import { IDateBoxOptions } from 'devextreme-react/date-box';

export const MomentDateSerializationFormat = 'yyyy-MM-DD';
export const MomentDateTimeSerializationFormat = 'yyyy-MM-DD HH:mm';

export const DevExpressDateSerializationFormat = 'yyyy-MM-dd';
export const DevExpressDateTimeSerializationFormat = 'yyyy-MM-dd HH:mm';
export const DevExpressTimeSerializationFormat = 'HH:mm';

export const HourMinSecFormat = 'HH:mm:ss';
export const HourMinFormat = 'HH:mm';

export const displayDateFormat_EN = 'MM/DD/YY';
export const displayDateTimeFormat_EN = 'MM/DD/YY HH:mm';
export const displayDateFormat_CZ = 'DD.MM.yyyy';
export const displayDateTimeFormat_CZ = 'DD.MM.yyyy HH:mm';

// Date bez serializationFormatu - používá se v mobilní verzi
export const dateFieldOptions: IDateBoxOptions = {
  type: 'date',
  pickerType: 'calendar',
  acceptCustomValue: true,
  adaptivityEnabled: true,
  showClearButton: true,
  invalidDateMessage: 'The date must have the valid format',
};

// Date s serializationFormatem - používá se všude mimo mobilní verzi
export const dateFieldOptionsWithSerialization: IDateBoxOptions = {
  ...dateFieldOptions,
  dateSerializationFormat: DevExpressDateSerializationFormat,
};

// Datetime bez serializationFormatu - používá se v mobilní verzi
export const dateTimeFieldOptions: IDateBoxOptions = {
  ...dateFieldOptions,
  type: 'datetime',
};

// Date s serializationFormatem - používá se všude mimo mobilní verzi
export const dateTimeFieldOptionsWithSerialization: IDateBoxOptions = {
  ...dateTimeFieldOptions,
  dateSerializationFormat: DevExpressDateTimeSerializationFormat,
};

// Date s serializationFormatem - používá se ve worksheetDesktop
export const dateTimeFieldOptionsWithSerializationAndInterval: IDateBoxOptions = {
  ...dateTimeFieldOptions,
  interval: 15,
  dateSerializationFormat: DevExpressDateTimeSerializationFormat,
};

export const timeFieldOptionsWithSerialization: IDateBoxOptions = {
  type: 'time',
  pickerType: 'rollers',
  adaptivityEnabled: true,
  showClearButton: true,
  invalidDateMessage: 'The time must have the valid format',
  dateSerializationFormat: DevExpressTimeSerializationFormat,
};

export const timeFieldOptionsWithSerializationWithInterval: IDateBoxOptions = {
  type: 'time',
  pickerType: 'list',
  defaultValue: new Date(),
  adaptivityEnabled: true,
  showClearButton: true,
  invalidDateMessage: 'The time must have the valid format',
  dateSerializationFormat: DevExpressTimeSerializationFormat,
  interval: 15,
};

export const timeFieldOptions: IDateBoxOptions = {
  type: 'time',
  pickerType: 'list',
  defaultValue: new Date(),
  adaptivityEnabled: true,
  showClearButton: true,
  invalidDateMessage: 'The time must have the valid format',
  dateSerializationFormat: HourMinSecFormat,
  interval: 15,
};

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};
