import { CheckBox } from 'devextreme-react';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { DataGridButton } from '../../components/table';
import { useTranslation } from '../../contexts/translation';
import { TaskStep, TaskStepStatus } from '../../types/step.types';
import { displayDateTimeFormat_CZ, displayDateTimeFormat_EN } from '../../utils/dates.types';

type Props = {
  step: TaskStep;
  isCompleteAllowed: boolean | null;
  isReopenAllowed: boolean | null;
  isPartiallyCompleteAllowed: boolean | null;
  isEditAllowed: boolean | null;
  isCancelAllowed: boolean | null;
  platform: 'desktop' | 'terminal';
  handleValueChange: (e: any, step: TaskStep) => void;
  handleOpenCustomStepModal: (step: TaskStep) => void;
  handleCancelStep: (stepId: number) => void;
  handleReopenStep: (stepId: number) => void;
};

// returns checkbox value
const getCheckBoxValue = (status: TaskStepStatus) => {
  switch (status) {
    case TaskStepStatus.OPEN:
      return false;
    case TaskStepStatus.COMPLETE:
      return true;
    default:
      return false;
  }
};

enum TouchState {
  NONE,
  HOLDING,
  MOVING,
}

export const TaskStepsRow = ({
  step,
  isCompleteAllowed,
  isReopenAllowed,
  isPartiallyCompleteAllowed,
  isEditAllowed,
  isCancelAllowed,
  platform,
  handleValueChange,
  handleOpenCustomStepModal,
  handleCancelStep,
  handleReopenStep,
}: Props) => {
  const [rowClicks, setRowClicks] = useState<number>(0);
  const [touchTimer, setTouchTimer] = useState<Moment | null>(null);
  const [isDetailVisible, setDetailVisible] = useState<boolean>(false);
  const [isHighlight, setHiglight] = useState<boolean>(false);

  const [touchState, setTouchState] = useState<TouchState>(TouchState.NONE);

  const [val, setVal] = useState<boolean | null>(getCheckBoxValue(step.status));
  const { translate, lang } = useTranslation();
  const isCanceled = step.status === TaskStepStatus.CANCELED;
  const dateTimeFormat = lang === 'en-US' ? displayDateTimeFormat_EN : displayDateTimeFormat_CZ;

  /**
   * Vrací barvu řádku podle stavu.
   * @param status
   * @returns
   */
  const getRowColor = (status: TaskStepStatus): string => {
    switch (status) {
      case TaskStepStatus.OPEN:
        return 'transparent';
      case TaskStepStatus.COMPLETE:
        return '#ccfccc';
      default:
        return 'transparent';
    }
  };

  /**
   * Provedení check/uncheck - podle stavu
   * @param item
   */
  const handleCheck = (item: TaskStep) => {
    if (item.status === TaskStepStatus.OPEN && isCompleteAllowed) {
      handleValueChange(true, item);
      setVal(true);
    }
    if (item.status === TaskStepStatus.COMPLETE && isReopenAllowed) {
      handleReopenStep(item.id);
      setVal(false);
    }
  };

  /**
   * Handle kliknutí na řádek. Zvětšujeme počet kliků - kvůli potencionálnmímu double clicku.
   * @param e
   */
  const handleRowClick = (e) => {
    setRowClicks(rowClicks + 1);
    setHiglight(false);
  };

  /**
   * Řeší doubleclick
   */
  useEffect(() => {
    if (rowClicks < 2) return;
    handleCheck(step);
    setRowClicks(0);
  }, [rowClicks]);
  useEffect(() => {
    setTimeout(() => {
      setRowClicks(0);
    }, 200);
  }, [rowClicks]);

  const onTouchStart = () => {
    // Nastavíme časovou značku stisknutí
    setTouchTimer(moment());
    // Změní touch stav
    setTouchState(TouchState.HOLDING);
  };

  const onTouchMoveCapture = () => {
    // Došlo k pohybu, promázneme časovou značku stihnusní (TOUCH&HOLD nechceme aby fungoval)
    setTouchTimer(null);
    // Změní touch stav
    setTouchState(TouchState.MOVING);
  };

  const onTouchEnd = () => {
    // Pokud máme uloženou časovou touch značku, provedeme kontrolu, jak dlouho a vyhodnotíme
    if (touchTimer && touchTimer?.add(500, 'milliseconds').isBefore(moment())) {
      setDetailVisible(!isDetailVisible);
      setTouchTimer(null);
    }

    setTouchState(TouchState.NONE);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (step.status !== TaskStepStatus.CANCELED && isCancelAllowed) {
        handleCancelStep(step.id);
        setVal(false);
      }
    },
    onSwipedRight: () => {
      if (step.status === TaskStepStatus.CANCELED && isReopenAllowed) {
        handleReopenStep(step.id);
        setVal(false);
      }
    },
  });

  return (
    <>
      <tr
        aria-disabled={isCanceled}
        style={{
          padding: 15,
          width: '30vw',
          backgroundColor: isHighlight ? '#fac7be' : getRowColor(step.status),
          color: step.status === TaskStepStatus.CANCELED ? '#d9d9d9' : '#333',
        }}
        onClick={(e) => handleRowClick(e)}
        onTouchMoveCapture={onTouchMoveCapture}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        {...handlers}
      >
        <td style={{ width: 50 }}>
          {step.status !== TaskStepStatus.CANCELED &&
          ((step.status === TaskStepStatus.OPEN && isCompleteAllowed) ||
            (step.status === TaskStepStatus.COMPLETE && isReopenAllowed)) ? (
            <CheckBox
              value={val}
              defaultValue={getCheckBoxValue(step.status)}
              onOptionChanged={(e) => {
                if (e.value === null) return;
                // false --> true --> null
                const ev = e as any;
                let newValue;
                if (ev.value === false && ev.previousValue === true) {
                  // partially complete
                  newValue = null;
                } else if (ev.value === true && ev.previousValue === false) {
                  // complete
                  newValue = true;
                } else {
                  // reopen
                  newValue = false;
                }

                // Disable the action if it's not allowed
                if (newValue === null && !isPartiallyCompleteAllowed) {
                  if (!isReopenAllowed) return (newValue = true);
                  newValue = false;
                } else if (newValue === true && !isCompleteAllowed) {
                  if (!isCancelAllowed) return (newValue = false);
                  newValue = null;
                } else if (newValue === false && !isReopenAllowed) {
                  if (!isCompleteAllowed) return (newValue = null);
                  newValue = true;
                }
                if (step.status === newValue) return;
                setVal(newValue);
                handleValueChange(newValue, step);
              }}
            />
          ) : (
            <CheckBox
              disabled={true}
              defaultValue={getCheckBoxValue(step.status)}
              style={{ color: 'gray' }}
            />
          )}
        </td>
        <td>
          <span
            style={{
              color: isCanceled ? '#d9d9d9' : undefined,
            }}
          >
            {step.name}
          </span>
        </td>
        <td>
          {step.capacityCategoryName && (
            <span
              style={{
                color: isCanceled ? '#d9d9d9' : undefined,
              }}
            >
              {step.capacityCategoryName}
            </span>
          )}
        </td>
        {platform === 'desktop' && (
          <td>
            {step.description && (
              <span
                style={{
                  color: isCanceled ? '#d9d9d9' : undefined,
                }}
              >
                {step.description}
              </span>
            )}
          </td>
        )}
        <td>
          {step.completedByName && (
            <span
              style={{
                color: isCanceled ? '#d9d9d9' : undefined,
              }}
            >
              {step.completedByName}
            </span>
          )}
        </td>
        <td>
          {step.completed && (
            <span
              style={{
                color: isCanceled ? '#d9d9d9' : undefined,
              }}
            >
              {moment(step.completed).format(dateTimeFormat)}
            </span>
          )}
        </td>

        <td style={{ textAlign: 'right' }}>
          {isEditAllowed && (
            <div style={{ marginRight: '10px', display: 'inline-block' }}>
              <DataGridButton
                hint={translate!('Edit', '')}
                icon="edit"
                cellData={null}
                onClick={() => handleOpenCustomStepModal(step)}
              />
            </div>
          )}
          {isCancelAllowed && step.status !== TaskStepStatus.CANCELED && (
            <div style={{ marginRight: '10px', display: 'inline-block' }}>
              <DataGridButton
                hint={translate!('Cancel', '')}
                icon="remove"
                stylingMode="contained"
                cellData={null}
                onClick={() => handleCancelStep(step.id)}
              />
            </div>
          )}
          {isReopenAllowed && step.status === TaskStepStatus.CANCELED && (
            <div style={{ marginRight: '10px', display: 'inline-block' }}>
              <DataGridButton
                hint={translate!('Reopen', '')}
                icon="undo"
                stylingMode="contained"
                cellData={null}
                onClick={() => handleReopenStep(step.id)}
              />
            </div>
          )}
        </td>
      </tr>
      {platform === 'terminal' && isDetailVisible && (
        <tr
          aria-disabled={isCanceled}
          style={{
            padding: 15,
            width: '30vw',
            backgroundColor: isHighlight ? '#fac7be' : getRowColor(step.status),
            color: step.status === TaskStepStatus.CANCELED ? '#d9d9d9' : '#333',
          }}
          onClick={(e) => handleRowClick(e)}
          onTouchMoveCapture={onTouchMoveCapture}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          {...handlers}
        >
          <td colSpan={6} style={{ borderBottom: 'solid 1px #b0aeae' }}>
            {step.description}
          </td>
        </tr>
      )}
    </>
  );
};
