import { SWRConfiguration } from 'swr';
import { ConfigType } from '../types/config.types';

export enum DEFAULT_MILLIS {
  FIVE = 5000,
  TEN = 10000,
  TWENTY = 20000,
}
export const toMillis = (value: string | number) => {
  return Number(value) * 1000;
};

export const swrTimeoutByConfig = (config: ConfigType | null | undefined): SWRConfiguration => {
  if (!config) {
    return {} as SWRConfiguration;
  }
  return {
    refreshInterval: config.enabled ? toMillis(config.value) : 0,
  } as SWRConfiguration;
};
