// @ts-ignore
import React from 'react';
import {
  DataGridButton,
  dateTimeFormat,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../components/table';
import { TimeSpanCell, TimeSpanCellSortValue } from '../Tasks/Columns';

export default function ChartCell(cellData) {
  return cellData.column.dataField === 'workSheetStatusNameTranslated' ? (
    <span className="type-name-translated-container">
      {cellData.data.workSheetStatusNameTranslated}
    </span>
  ) : (
    cellData.data[cellData.column.dataField]
  );
}

export const Columns = (handleEdit, history, translate): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'taskCustomId',
    width: '100px',
    caption: 'Task id',
    cellRender: (cellData) => {
      return (
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => history.push(`/tasks/edit/${cellData.data.taskId}`)}
        >
          {cellData.data[cellData.column.dataField]}
        </span>
      );
    },
  },
  {
    dataType: IDataType.string,
    dataField: 'taskTypeNameTranslated',
    width: '100px',
    caption: 'Task type',
  },
  {
    dataField: 'taskName',
    width: '100px',
    caption: 'Task name',
    lookupPickBy: 'taskName',
  },
  {
    dataField: 'taskAssetName',
    width: '100px',
    caption: 'Asset name',
    lookupPickBy: 'taskAssetName',
  },
  {
    dataField: 'taskAssetTopLevelParenName',
    width: '100px',
    caption: 'Top level asset name',
  },
  {
    dataField: 'workActivityCapacityCategoryName',
    width: '100px',
    caption: 'Capacity Category',
  },
  {
    dataField: 'workActivityName',
    width: '100px',
    caption: 'Activity',
    lookupPickBy: 'workActivityName',
  },
  {
    dataField: 'workSheetStatusNameTranslated',
    caption: 'Status',
    width: '100px',
    cellRender: ChartCell,
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'operatorName',
    width: '100px',
    caption: 'Operator',
    lookupPickBy: 'operatorName',
  },
  {
    dataField: 'activityStarted',
    width: '100px',
    caption: 'Activity Start',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'activityFinished',
    width: '100px',
    caption: 'Activity Finish',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'activityDuration',
    width: '100px',
    caption: 'Activity Duration',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'activityDuration');
    },
  },
  {
    dataField: 'description',
    width: '100px',
    caption: 'Description',
    lookupPickBy: 'description',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '100px',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={handleEdit}
    />
  );
};

export const ColumnsTaskWorkSheet = (handleEdit, translate): SisDataGridColumns => [
  {
    dataField: 'workActivityCapacityCategoryName',
    width: '100px',
    caption: 'Capacity Category',
  },
  {
    dataField: 'workActivityName',
    width: '100px',
    caption: 'Activity',
    lookupPickBy: 'workActivityName',
  },
  {
    dataField: 'workSheetStatusNameTranslated',
    caption: 'Status',
    width: '100px',
    cellRender: ChartCell,
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'operatorName',
    width: '100px',
    caption: 'Operator',
    lookupPickBy: 'operatorName',
  },
  {
    dataField: 'activityStarted',
    width: '100px',
    caption: 'Activity Start',
    dataType: IDataType.dateTime,
  },
  {
    dataField: 'activityFinished',
    width: '100px',
    caption: 'Activity Finish',
    dataType: IDataType.dateTime,
  },
  {
    dataField: 'activityDuration',
    width: '100px',
    caption: 'Activity Duration',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'activityDuration');
    },
  },
  {
    dataField: 'description',
    width: '100px',
    caption: 'Description',
    lookupPickBy: 'description',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCellTaskWorkSheet(data, handleEdit, translate),
    width: '100px',
  },
];

const ButtonCellTaskWorkSheet = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={handleEdit}
    />
  );
};
