import notify from 'devextreme/ui/notify';
import { propOr } from 'ramda';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getItemApi, getListApi, postApi } from '../apiUtils';
import { Loader } from '../components/loader/Loader';
import { Platform } from '../components/loginForm/LoginForm';
import { clearLocalSession, useSessionStorage } from '../hooks/useSessionStorage';
import { ApplicationUser } from '../types/applicationUser.types';
import { TerminalMapping } from '../types/terminalMapping.types';
import { API_URL } from '../utils/apiUrl';
import { STORAGE_KEYS } from '../utils/localStorageKeys';
import { useAssetClass } from './assetClass';
import { CONFIG_KEYS, useConfig, useConfigByKey } from './configContext';
import { useTranslation } from './translation';

interface AuthContextType {
  isAuthenticated: boolean;
  user: ApplicationUser | null | undefined;
  loading: boolean;
  entryUrl: string | null;
  signIn?: (
    email: string,
    password: string,
    uid: string,
    assetClassId: number,
    platform: string,
  ) => Promise<void>;
  signOut?: () => Promise<void>;
  loginTechnicalUser?: () => Promise<void>;
  loginTechnicalUserByTerminalMappingId?: (idTerminalMapping: number) => Promise<void>;
  isTechnicalUser: boolean;
  terminalMappings: TerminalMapping[] | null;
  terminalMappingRedirectEnabled: boolean;
  setEntryUrl?: (entryUrl: string | null) => void;
  assetIdFromUrl?: number | null;
  setAssetIdFromUrl?: (value: number | null) => void;
}

const TerminalAuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  loading: false,
  isTechnicalUser: false,
  terminalMappings: null,
  terminalMappingRedirectEnabled: false,
  entryUrl: null,
  assetIdFromUrl: null,
});
const useTerminalAuth = () => useContext<AuthContextType>(TerminalAuthContext);

interface Props {
  children?: any;
}

function TerminalAuthProvider(props: Props) {
  const [user, setUser] = useState<ApplicationUser | null>();
  const [entryUrl, setEntryUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [terminalMappings, setTerminalMappings] = useState<TerminalMapping[] | null>(null);
  const [terminalMappingRedirectEnabled, setTerminalMappingEnabled] = useState<boolean>(true);
  const [assetIdFromUrl, setAssetIdFromUrl] = useState<number | null>(null);
  const [_, setSessionTerminalMappingId] = useSessionStorage(STORAGE_KEYS.TERMINAL_MAPPING_ID, {});
  const { changeAssetClass } = useAssetClass();
  const { pathname } = useLocation();
  const { setDefaultConfigToStorage } = useConfig();
  const configIdleLogoutTimeout = useConfigByKey(
    CONFIG_KEYS.TERMINAL_IDLE_LOGOUT_TIMEOUT_DEFAULT_USER,
  );
  const { translate } = useTranslation();
  const transFormName = 'LoginPage';

  const history = useHistory();

  const loginTechnicalUserByTerminalMappingId = async (idTerminalMapping: number) => {
    setLoading(true);
    setSessionTerminalMappingId(idTerminalMapping);
    await postApi<ApplicationUser>({
      url: `${API_URL.LOGIN_TECHNICAL_USER}?terminalMapingId=${idTerminalMapping}`,
      data: {},
      hideNotifications: true,
      callAfterSuccess: async (user: ApplicationUser) => {
        // Pokud má uživatel jinou platformu než TERMINAL, musíme ji změnit
        if (user.platform !== Platform.TERMINAL) {
          await postApi<ApplicationUser>({
            url: `${API_URL.CHANGE_CURRENT_PLATFORM}`,
            data: { platform: Platform.TERMINAL },
            callAfterSuccess: () => {
              user.platform = Platform.TERMINAL;
            },
          });
        }

        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
        if (changeAssetClass && user.assetClassId !== null) changeAssetClass(user.assetClassId);
        history.push(pathname);
      },
      callAfterUnAuthorized: () => {
        setLoading(false);
        setUser(null);
        setIsAuthenticated(false);
        setTerminalMappingEnabled(false);
        // Config nemusí mít uživatele, v takovém případě přesměrujeme na login #430
        history.push('/terminal/login');
      },
      callAfterError: () => {
        setLoading(false);
        setUser(null);
        setIsAuthenticated(false);
        setTerminalMappingEnabled(false);
      },
    });
  };

  useEffect(() => {
    (async function () {
      setLoading(true);

      let _terminalMappings: TerminalMapping[] | null = null;
      await getListApi({
        url: `${API_URL.TERMINAL_MAPPING_LIST_FROM_CURRENT_IP}`,
        params: {},
        callAfterSuccess: async (terminalMappings: TerminalMapping[]) => {
          _terminalMappings = terminalMappings;
        },
        hideNotifications: true,
      });

      // Kontrola, jestli je uživatel přihlášen
      await getItemApi({
        url: `${API_URL.IS_USER_AUTHENTICATED}`,
        params: {},
        callAfterSuccess: async (result: boolean) => {
          if (result) {
            // Pokud ano, načteme si informace o uživateli a nastavíme stavy
            await getItemApi<ApplicationUser>({
              url: `${API_URL.GET_CURRENT_USER}`,
              params: {},
              callAfterSuccess: async (user: ApplicationUser) => {
                // Pokud má uživatel jinou platformu než TERMINAL, musíme ji změnit
                if (user.platform !== Platform.TERMINAL) {
                  await postApi<ApplicationUser>({
                    url: `${API_URL.CHANGE_CURRENT_PLATFORM}`,
                    data: { platform: Platform.TERMINAL },
                    callAfterSuccess: () => {
                      user.platform = Platform.TERMINAL;
                    },
                  });
                }

                setTerminalMappings(_terminalMappings);
                setIsAuthenticated(true);
                setUser(user);
                setLoading(false);
                if (changeAssetClass && user.assetClassId !== null)
                  changeAssetClass(user.assetClassId);
              },
              callAfterError: () => {
                // Pokud by API nevrátilo usera, přesměrujeme na login
                setUser(null);
                setIsAuthenticated(false);
                setLoading(false);
                history.push('/terminal/login');
              },
              hideNotifications: true,
            });
          } else {
            setTerminalMappings(_terminalMappings);
            setLoading(false);

            // Pokud 1 pracoviště -> automatické přihlášení (stávající stav)
            if (_terminalMappings && _terminalMappings.length === 1) {
              const terminalMappingToUse: TerminalMapping = _terminalMappings[0];
              await loginTechnicalUserByTerminalMappingId(terminalMappingToUse.id);
            }

            // Pokud žádné pracoviště -> login dialog (stávající stav)
            if (!_terminalMappings || _terminalMappings.length === 0) {
              history.push('/terminal/login');
              return;
            }

            // Pokud více než 1 pracoviště -> formulář s výběrem pracoviště
            history.push('/terminal/terminal-mapping-login');
          }
        },
        hideNotifications: true,
      });
    })();
  }, []);

  const signIn = useCallback(
    async (
      username: string,
      password: string,
      uid: string,
      assetClassId: number,
      platform: string,
    ): Promise<void> => {
      setLoading(true);
      if (uid) {
        await postApi<ApplicationUser>({
          url: `${API_URL.LOGIN_UID}`,
          data: { uid: uid, assetClassId, platform },
          ignoreUnAuthorizedError: true,
          callAfterSuccess: (result) => {
            setIsAuthenticated(true);
            setUser(result);
            if (changeAssetClass && result.assetClassId !== null)
              changeAssetClass(result.assetClassId);

            // TemrinalMappingId nechceme ze session mazat
            const _sessionTerminalMappingId = window.sessionStorage.getItem(
              STORAGE_KEYS.TERMINAL_MAPPING_ID,
            );
            clearLocalSession();
            if (_sessionTerminalMappingId)
              setSessionTerminalMappingId(Number(_sessionTerminalMappingId));

            setDefaultConfigToStorage!();
          },
          callAfterError: () => {
            notify(translate!('Wrong login or password', transFormName), 'error');
          },
        });
      } else {
        await postApi<ApplicationUser>({
          url: `${API_URL.LOGIN}`,
          data: { login: username, password, assetClassId, platform },
          ignoreUnAuthorizedError: true,
          callAfterSuccess: (result) => {
            setIsAuthenticated(true);
            setUser(result);
            if (changeAssetClass && result.assetClassId !== null)
              changeAssetClass(result.assetClassId);

            // TemrinalMappingId nechceme ze session mazat
            const _sessionTerminalMappingId = window.sessionStorage.getItem(
              STORAGE_KEYS.TERMINAL_MAPPING_ID,
            );
            clearLocalSession();
            if (_sessionTerminalMappingId)
              setSessionTerminalMappingId(Number(_sessionTerminalMappingId));

            setDefaultConfigToStorage!();
          },
          callAfterError: () => {
            notify(translate!('Wrong login or password', transFormName), 'error');
          },
        });
      }
      setLoading(false);
    },
    [],
  );

  const signOut = useCallback(
    async (isFromIdle?: boolean, entryUrl?: string | null): Promise<void> => {
      setLoading(true);
      await postApi<ApplicationUser>({
        url: `${API_URL.LOGOUT}`,
        data: { logout: true },
        ignoreUnAuthorizedError: true,
        callAfterSuccess: () => {
          setIsAuthenticated(false);
          if (!entryUrl) setEntryUrl(null);
          else setEntryUrl(entryUrl);
          setUser(null);
          //loginTechnicalUser();
        },
        callAfterError(arg) {
          if (!isFromIdle) return;

          setIsAuthenticated(false);
          if (!entryUrl) setEntryUrl(null);
          else setEntryUrl(entryUrl);
          setUser(null);
        },
      });
      setLoading(false);
    },
    [],
  );

  // *** Idle check ***
  const handleIdle = () => {
    // Odhlasíme jen pokud se jedná o technického uživatele a počet mappingů > 1
    if (
      !isAuthenticated ||
      !signOut ||
      !propOr(false, 'technicalUser', user) ||
      !terminalMappings ||
      terminalMappings.length <= 1
    )
      return;

    console.log('entryUrl out', entryUrl);
    signOut(true, entryUrl);
  };

  useIdleTimer({
    timeout:
      configIdleLogoutTimeout && Number(configIdleLogoutTimeout.value) !== 0
        ? configIdleLogoutTimeout.value
        : 1,
    onIdle: handleIdle,
    debounce: 500,
    disabled:
      !configIdleLogoutTimeout ||
      !configIdleLogoutTimeout.enabled ||
      Number(configIdleLogoutTimeout.value) === 0,
  });

  return (
    <TerminalAuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        loading,
        isAuthenticated,
        terminalMappings,
        terminalMappingRedirectEnabled,
        loginTechnicalUserByTerminalMappingId,
        isTechnicalUser: propOr(false, 'technicalUser', user),
        setEntryUrl,
        entryUrl,
        assetIdFromUrl,
        setAssetIdFromUrl,
      }}
      {...props}
    >
      {loading ? <Loader /> : <>{props.children}</>}
    </TerminalAuthContext.Provider>
  );
}

export { TerminalAuthProvider, useTerminalAuth };
