import React from 'react';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { TaskSparePart } from '../../../types/task.types';
import { ITab } from '../../Assets/AssetsEdit';
import { TaskSpareParts } from '../TaskSpareParts';

interface Props extends ITab {
  id: string;
  data: TaskSparePart[];
  errorSparePartIssued: any;
}
export const TaskSparePartsTab = ({ data, errorSparePartIssued }: Props) => {
  return (
    <PageContentWrapper error={errorSparePartIssued} noContentBlock noDxCard>
      <TaskSpareParts initialData={data} />
    </PageContentWrapper>
  );
};
