import React from 'react';
import { DataGridButton, IColumnType, IDataType, SisDataGridColumns } from '../../components/table';

export default function ButtonCell(cellData, handleClose, handleOpen, translate) {
  return cellData?.data?.statusId === 1 ? (
    <DataGridButton
      hint={translate!('Close', 'TimePeriods.Close')}
      text={translate!('Close', 'TimePeriods.Close')}
      stylingMode="contained"
      cellData={cellData}
      onClick={handleClose}
    />
  ) : (
    <DataGridButton
      hint={translate!('Open', 'TimePeriods.Open')}
      text={translate!('Open', 'TimePeriods.Open')}
      stylingMode="contained"
      cellData={cellData}
      onClick={handleOpen}
    />
  );
}

export const Columns = (handleClose, handleOpen, translate): SisDataGridColumns => [
  {
    dataField: 'name',
    width: '26.66%',
    caption: 'Name',
  },
  {
    dataField: 'statusNameTranslated',
    width: '26.66%',
    caption: 'Status',
  },
  {
    dataField: 'periodStart',
    width: '10%',
    caption: 'Period start',
    dataType: IDataType.date,
  },
  {
    dataField: 'periodEnd',
    width: '10%',
    caption: 'Period end',
    dataType: IDataType.date,
  },
  {
    type: IColumnType.buttons,
    dataField: '',
    cellRender: (data) => ButtonCell(data, handleClose, handleOpen, translate),
    width: '8%',
  },
];
