import { Popup } from 'devextreme-react';
import { isNil } from 'ramda';
import React, { useRef, useState } from 'react';
import { postApi } from '../../apiUtils';
import { IDataType, SisDataGrid, SisDataGridColumns } from '../../components/table';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AddNewCommentForm } from '../../forms/addNewComment';
import { TaskCommentInput, TaskComments } from '../../types/task.types';
import { API_URL } from '../../utils/apiUrl';

interface Props {
  data: TaskComments;
  taskId: string;
  mutateTask: () => void;
  disbaleOnToolbarPreparing?: boolean;
  hideTitle?: boolean;
  poupWidth: number;
  platform: string;
}

export const TasksComments = ({
  data,
  taskId,
  mutateTask,
  disbaleOnToolbarPreparing,
  hideTitle,
  poupWidth,
  platform,
}: Props) => {
  const transFormName = 'Task.Update';
  const { translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const { startLoading, stopLoading } = useLoading();
  const popupRef = useRef<any>(null);

  const Columns: SisDataGridColumns = [
    {
      dataField: 'comment',
      caption: 'Comment',
      width: '66%',
    },
    {
      dataField: 'created',
      caption: 'Created',
      width: '17%',
      dataType: IDataType.dateTime,
    },
    {
      dataField: 'createdByName',
      caption: 'Created by',
      width: '17%',
    },
  ];
  const onToolbarPreparing = (e) => {
    if (!hideTitle)
      e.toolbarOptions.items.unshift({
        location: 'before',
        html: translate!('Comments', transFormName),
      });

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        type: 'default',
        stylingMode: 'contained',
        onClick: () => {
          setModalVisible(true);
        },
      },
    });
  };

  const formData: TaskCommentInput = {
    comment: '',
    taskId: taskId,
  };

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();

    await postApi<TaskCommentInput>({
      url: API_URL.TASK_COMMENT_NEW,
      data: formData,
      callAfterSuccess: () => {
        mutateTask();
        setModalVisible(false);
      },
    });
    stopLoading();
  };

  const onShown = () => {
    // @ts-ignore
    formRef?.current?.instance.getEditor('comment')?.focus();
  };

  const init = (ref) => {
    setFormRef(ref);
  };

  return (
    <>
      <Popup
        ref={popupRef}
        visible={modalVisible}
        dragEnabled={false}
        showCloseButton={false}
        closeOnOutsideClick={true}
        onHiding={() => setModalVisible(false)}
        onShown={onShown}
        showTitle={true}
        title={translate!('Add new comment', transFormName)}
        container=".dx-viewport"
        width={poupWidth}
        height={'auto'}
      >
        <AddNewCommentForm
          onInitialize={init}
          onSubmit={onSubmit}
          modalVisible={modalVisible}
          formData={formData}
          closeModal={() => setModalVisible(false)}
          platform={platform}
        />
      </Popup>
      {!isNil(data) && (
        <SisDataGrid
          onToolbarPreparing={!disbaleOnToolbarPreparing ? onToolbarPreparing : undefined}
          allowColumnReordering={false}
          allowColumnResizing={false}
          columnChooser={false}
          filterRow={false}
          data={data}
          keyExpr={'id'}
          transFormName={transFormName}
          columns={Columns}
        />
      )}
    </>
  );
};
