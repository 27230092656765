import { FileUploader } from 'devextreme-react';
import React from 'react';
import useSWR, { mutate } from 'swr';
import { useTranslation } from '../../contexts/translation';
// abych odlišil od nativního File
import { File as FileType } from '../../types/file.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { FileFigureList } from './FileFigureList';
import './FileManager.scss';
import { fileToBlob } from './ImagePreview';

interface TabFilesProps {
  id: number;
  entityName: string;
  isReadonly: boolean;
  onFileCountChange?: any;
  uploadMode?: 'instantly' | 'useButtons' | 'useForm' | undefined;
  reference?: any;
  hideUploadButtons?: boolean;
  hideFileList?: boolean;
  isEdit?: boolean;
  tmpFiles?: any[];
  setTmpFiles?: any;
  onSubmit?: any;
  setOpenedTab?: any;
}

export function TabFiles(props: TabFilesProps) {
  const { translate } = useTranslation();
  const transFormName = 'File';
  const apiUrlList = `${API_URL.FILE_LIST}?entity=${props.entityName}&entityId=${props.id}`;
  // Načteme subory
  const { data: files, mutate: mutateFiles } = useSWR<FileType[]>(
    props.entityName && props.id ? `${apiUrlList}` : null,
    fetcher,
  );

  const handleFilesUpload = () => {
    mutate(`${apiUrlList}`);
    if (props.onFileCountChange) props.onFileCountChange();
  };

  const handleOnFileDelete = (id: number) => {
    if ((id !== null || id !== undefined) && props.tmpFiles) {
      // delete tmp souborů
      let fileUploader = props.reference.current.instance;
      if (fileUploader) fileUploader.removeFile(id);
    }
    // přenačtení indikátoru počtu souborů
    mutateFiles();
    if (props.onFileCountChange) props.onFileCountChange();
  };

  // jen u tmp souborů
  const handleOnFileRename = (id: number, newName: string) => {
    // u tmpFiles je id index
    if (!props.tmpFiles || !newName) return;
    let files = props?.tmpFiles;
    // file --> blob
    let fileUploader = props.reference.current.instance;
    fileToBlob(fileUploader._files[id].value).then((res) => {
      // nový file s novým názvem
      let file = new File([res], newName, {
        type: fileUploader._files[id].value.type,
      } as FilePropertyBag);
      fileUploader._files[id].value = file;
      files[id] = file;
      props.setTmpFiles(files);
      // název se změní, ale list souborů se přenačte až při zrušení a znovu vytvoření reference
      // TODO lepší řešení?
      props.setOpenedTab(3);
      props.setOpenedTab(4);
    });
  };

  return (
    <div
      className={`file-manager responsive-paddings${
        props.hideUploadButtons ? ' hide-upload-buttons' : ''
      }`}
    >
      {!props.isReadonly && (
        <FileUploader
          name={'files'}
          multiple={true}
          accept={'*'}
          uploadMode={!props.uploadMode ? 'instantly' : props.uploadMode}
          selectButtonText={translate!('Select files', transFormName)}
          labelText={translate!('or drop files here', transFormName)}
          uploadUrl={`${API_URL.FILE_UPLOAD}?entity=${props.entityName}&entityId=${props.id}`}
          onUploaded={handleFilesUpload}
          onValueChange={(e: any) => {
            if (!e || !props.setTmpFiles) return;
            props.setTmpFiles(e);
          }}
          defaultValue={props.tmpFiles ? props.tmpFiles : []}
          showFileList={false}
          ref={props.reference || null}
        />
      )}
      {!props.hideFileList && (
        <div>
          {/* soubory vytvořené z aktuálního formuláře (zatím neuploadnuty) */}
          {props.tmpFiles && (
            <FileFigureList
              items={props.tmpFiles}
              entityName={props.entityName}
              entityId={props.id}
              isReadOnly={props.isReadonly}
              onFileDelete={handleOnFileDelete}
              onFileRename={handleOnFileRename}
              isTmpFile={true}
            />
          )}
          {/* pro soubory natáhlé z Attachments/GetList */}
          <FileFigureList
            items={files}
            entityName={props.entityName}
            entityId={props.id}
            isReadOnly={props.isReadonly}
            onFileDelete={handleOnFileDelete}
            onFileRename={handleOnFileRename}
          />
        </div>
      )}
    </div>
  );
}
