import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteApi, postApi, putApi } from '../../apiUtils';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabResponsiblePersonsColumns';
import { AssetResponsiblePersonInputs } from '../../types/assetResponsiblePerson.types';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { API_URL } from '../../utils/apiUrl';
import AssetResponsiblePersonModal from '../modals/AssetResponsiblePersonModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export interface IAssetTabResponsiblePersonsDataGrid extends IDataGrid {
  assetId: string;
  isReadOnly?: boolean;
  capacityCategoryChanged: (category: any) => void;
  validateRespFromTo: (e: any) => void;
  filteredPersons: any;
  capacityCategories: any;
  persons: any;
  mutateData: () => void;
}

const baseEditorOptions = {
  width: 200,
};

export const AssetTabResponsiblePersonsDataGrid = ({
  assetId,
  data,
  isReadOnly,
  validateRespFromTo,
  capacityCategories,
  capacityCategoryChanged,
  filteredPersons,
  persons,
  mutateData,
}: IAssetTabResponsiblePersonsDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.ResponsiblePerson';
  const [editing, setEditing] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetResponsiblePersonInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset responsible persons', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_RESPONSIBLE_PERSON_POST,
          data: { ..._data, assetId: +assetId },
        });
        break;
      case DataGridActionType.Update:
        const oldData = data.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_RESPONSIBLE_PERSON_PUT,
            data: { ...oldData, ..._data, assetId: +assetId },
          }));
        break;
      case DataGridActionType.Remove:
        await deleteApi({
          url: API_URL.ASSET_RESPONSIBLE_PERSON_DELETE,
          id: key,
        });
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateData();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const handleRemove = (e: any) => {
    const keyId = e?.data?.id;
    keyId && handleApiCall({ type: DataGridActionType.Remove, key: keyId, data: null });
  };

  const onSubmit = (formData: AssetResponsiblePersonInputs, type: DataGridActionType) => {
    handleApiCall({ type, key: formData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(
          data,
          isReadOnly,
          editing,
          capacityCategories,
          translate,
          handleEdit,
          handleRemove,
        )}
        onToolbarPreparing={onToolbarPreparing}
        columnChooser={false}
        allowColumnResizing={false}
        isReadOnly={isReadOnly}
        allowColumnReordering={false}
        prompt={Prompt}
      />
      {isModalVisible && (
        <AssetResponsiblePersonModal
          isVisible={isModalVisible}
          onClose={handleClose}
          capacityCategories={capacityCategories}
          persons={filteredPersons}
          data={selectedRowData}
          actionType={selectedAction}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
