import Box, { Item } from 'devextreme-react/box';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { Loader } from '../../components/loader/Loader';
import { SisDataGrid, SisDataGridColumns } from '../../components/table/SisDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { DetailPageParams } from '../../types/pageParams.types';
import { Task } from '../../types/task.types';
import { TimePeriod } from '../../types/timeperiod.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { TypeNameCell } from '../Tasks/Columns';
import { paintCellByTaskColor } from '../Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../Tasks/utils/paintRowByColor';

export function TimePeriodsOpen() {
  const history = useHistory();
  const { translate } = useTranslation();
  const { startLoading, loading, stopLoading } = useLoading();
  const transFormName = 'TimePeriods.Open';
  const { id } = useParams<DetailPageParams>();

  const [tasks, setTasks] = useState<Task[]>([]);

  const { setValue } = useBreadcrumbs();

  const { data, error, isValidating } = useSWR<TimePeriod>(
    `${API_URL.TIME_PERIOD_ITEM}?id=${id}`,
    fetcher,
  );

  useEffect(() => {
    postApi({
      url: `${API_URL.TASK_REPORT_REQUEST}`,
      data: {
        timePeriodIdArr: [+id],
      },
      callAfterSuccess: (result) => {
        setTasks(result);
      },
      hideNotifications: true,
    });

    setValue({
      pageName: 'Open time period',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Time periods', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const TasksColumns = (): SisDataGridColumns => [
    {
      dataField: 'customId',
      width: '10%',
      caption: 'Custom id',
      cellRender: (cellData) => {
        return (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => history.push(`/tasks/edit/${cellData.data.id}`)}
          >
            {cellData.data[cellData.column.dataField]}
          </span>
        );
      },
    },
    {
      dataField: 'typeNameTranslated',
      width: '10%',
      caption: 'Type',
      lookupPickBy: 'typeNameTranslated',
      cssClass: 'dg-align-center',
      cellRender: TypeNameCell,
    },
    {
      dataField: 'capacityCategoryName',
      width: '10%',
      caption: 'Capacity category',
      lookupPickBy: 'capacityCategoryName',
    },
    {
      dataField: 'statusNameTranslated',
      width: '10%',
      caption: 'Status',
      lookupPickBy: 'statusNameTranslated',
      cssClass: 'dg-align-center',
    },
    {
      dataField: 'assetTopLevelParenName',
      caption: 'Line',
      width: '14%',
    },
    {
      dataField: 'assetName',
      width: '23%',
      caption: 'Asset',
      lookupPickBy: 'assetName',
    },
    {
      dataField: 'taskName',
      width: '23%',
      caption: 'Task name',
    },
  ];

  const handleTimePeriodOpen = async () => {
    startLoading();
    await postApi({
      url: `${API_URL.TIME_PERIOD_REOPEN}`,
      params: {
        id: data!.id,
      },
      callAfterSuccess: async () => {
        history.push(`/timeperiods`);
      },
      callAfterError: (result) => {
        notify(`This action is not allowed now`, 'error');
      },
      hideNotifications: true,
    });
    stopLoading();
  };

  if (!data || isValidating) return <Loader />;

  return (
    <>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ marginBottom: '5px' }}>
          <div style={{ paddingTop: '5px' }}>
            <Box direction="row" width="100%">
              <Item ratio={0.9}>
                <div style={{ fontSize: '22px' }}>{`${data!.name}, ${
                  data!.statusNameTranslated ? data!.statusNameTranslated : data!.statusName
                }`}</div>
              </Item>
              <Item ratio={0.1}>
                <Button
                  text={translate!(`Open`, transFormName)}
                  disabled={loading}
                  type="success"
                  stylingMode="contained"
                  onClick={handleTimePeriodOpen}
                />
              </Item>
            </Box>
          </div>
        </div>
        <Box direction="row" width="100%">
          <Item ratio={1}>
            <div className={'dx-card responsive-paddings'}>
              <div style={{ fontSize: '18px', marginBottom: '5px' }}>
                {translate!(`Period tasks`, transFormName)}
              </div>
              {tasks.length > 0 && (
                <SisDataGrid
                  data={tasks}
                  keyExpr={'id'}
                  transFormName={'TimePeriods.Close'}
                  columns={TasksColumns()}
                  columnChooser={false}
                  allowColumnResizing={false}
                  allowColumnReordering={false}
                  onRowPrepared={paintRowByTaskColor}
                  onCellPrepared={paintCellByTaskColor}
                />
              )}
            </div>
          </Item>
        </Box>
      </div>
    </>
  );
}
