import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { StepForm } from '../../forms/step/StepForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { StepInputs } from '../../types/step.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function StepsEdit() {
  const { translate } = useTranslation();
  const transFormName = 'Step.Form';
  const { id } = useParams<DetailPageParams>();
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();
  const { assetClass } = useAssetClass();

  useEffect(() => {
    setValue({
      pageName: 'Edit step',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Steps', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<StepInputs>(`${API_URL.STEP_ITEM}?id=${id}`, fetcher);

  /**
   * Načte seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();
    let isOk = false;
    const _data = formData as any;
    await putApi({
      url: API_URL.STEP_PUT,
      data: { ...formData, capacityCategoryId: _data?.capacityId },
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      error={error || capacityCategoriesError}
      loading={!formData}
      heading={translate!('Edit step', transFormName)}
    >
      <StepForm
        onSubmit={onSubmit}
        formData={formData}
        capacityCategories={capacityCategories}
        isEdit
      />
    </PageContentWrapper>
  );
}

export default StepsEdit;
