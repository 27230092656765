import { Button } from 'devextreme-react';
import moment from 'moment';
import 'moment/min/locales';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translation';
import { getTranslationByField } from '../../utils/getTranslationByTaskStateChangeFields';
import { TaskHistoryVariant } from './TaskHistory';
import './TaskHistoryItem.scss';
interface Props {
  data: any;
  variant: TaskHistoryVariant;
  composeText: (text: string, params: string) => string;
}

interface DifferenceCheckType {
  field: string;
  before: any;
  after: any;
}

export const TaskHistoryItem = ({ data, variant, composeText }: Props) => {
  const [extended, setExtended] = useState<Boolean>(variant === TaskHistoryVariant.EXTENDED);
  const [stateDiff, setStateDiff] = useState<DifferenceCheckType[] | null>(null);
  const transFormName = 'Task.History';
  const { translate, lang } = useTranslation();

  const { description, params, stateBeforeChange, stateAfterChange, createdByName, created } = data;
  // eslint-disable-next-line
  const localizedMoment = moment(created).locale(lang.replace(/\-.*/, ''));

  const checkDifferences = (compared: Object, base: Object) => {
    if (!base) return;
    const diff: DifferenceCheckType[] = [];
    if (base) {
      const baseKeys: string[] = Object.keys(base);
      for (const key of baseKeys) {
        if (Array.isArray(base[key])) continue;
        const translation = getTranslationByField(key);
        if (translation && base[key] !== compared[key]) {
          diff.push({
            field: translate!(translation.key, transFormName),
            before: translation.translatable ? translate!(base[key], transFormName) : base[key],
            after: translation.translatable
              ? translate!(compared[key], transFormName)
              : compared[key],
          });
        }
      }
    }
    setStateDiff(diff.length > 0 ? diff : null);
  };

  useEffect(() => {
    checkDifferences(JSON.parse(stateAfterChange), JSON.parse(stateBeforeChange));
  }, []);

  return (
    <div className="container">
      <div className="header">
        <span className="header-username">{createdByName}</span>
        <span className="header-time">{localizedMoment.fromNow()}</span>
        {stateDiff && (
          <Button
            className="header-changes-button"
            stylingMode="text"
            onClick={() => setExtended(!extended)}
          >
            {extended ? (
              <i className="dx-icon-chevrondown" />
            ) : (
              <i className="dx-icon-chevronnext" />
            )}
          </Button>
        )}
      </div>
      <div>{composeText(translate!(description, transFormName), params ?? '')}</div>
      {extended && stateDiff && (
        <>
          <div className="changes-container-label">
            {translate!('Changed values', transFormName)}:
          </div>
          {stateDiff?.map((diff, idx) => (
            <div className="changes-container" key={idx}>
              <div className="changes-container-padding-line" />
              <div className="changes-container-item">
                <strong>{diff.field}:</strong> {diff.before}
                <span className="changes-container-item-change">{' ⟶ '}</span>
                {diff.after}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
