import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TabFiles } from '../../../components/fileManager/TabFiles';
import { DetailPageParams } from '../../../types/pageParams.types';
import { ITab } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
}

/**
 * Záložka pro spárvu souborů v assetech. Pro správu assetů se použvá shared component. Tato komponenta slouží
 * pouze jako wrapper, aby byla zachována struktura tabů.
 * @constructor
 */
export function AssetTabFiles({ active, isReadonly }: Props) {
  const { id } = useParams<DetailPageParams>();

  useEffect(() => {}, [active]);
  return <TabFiles id={id as unknown as number} entityName="Asset" isReadonly={isReadonly} />;
}
