import moment from 'moment';
import { isNilOrEmpty } from 'ramda-extension';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { WorkSheetsDataGrid } from '../../components/datagrid/WorkSheetsDataGrid';
import { DatagridNoResponsiveContainer } from '../../components/datagridNoResponsive/DatagridNoResponsiveContainer';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useIsWorkSheetCreatePermission } from '../../hooks/useIsWorkSheetCreatePermission';
import { useSaveDataGridFilter } from '../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { WorkSheet } from '../../types/workSheet.types';
import { API_URL } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { swrTimeoutByConfig } from '../../utils/toMillis';

export interface WorkSheetsFilterData {
  dateFrom: string | null;
  dateTo: string | null;
  ownFlag: boolean | null;
}

const defaultFilterData: WorkSheetsFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
  ownFlag: true,
};

export function WorkSheets() {
  const { user, loading } = useAuth();
  const history = useHistory();
  const { assetClass } = useAssetClass();
  const dataGrid = useRef<any>(null);
  const [userHasPermissionToCreate, loadingPermission] = useIsWorkSheetCreatePermission();
  const config = useConfigByKey(CONFIG_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP);
  const { loadFilter, resetFilter, saveFilter, getActualJsonState, data: datagridJsonData, } = useSaveDataGridFilter(
    STORAGE_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP,
    dataGrid,
  );

  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.WORKSHEETS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'WorkSheets',
      steps: [{ name: 'Home', pathToCutCount: 1 }],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const loadConfig = (config: any) => {
    if (config) {
      loadFilter(JSON.parse(config.value));
      saveFilter();
    }
  };

  useEffect(() => {
    if (config && resetFilterAction) {
      resetFilter();
      loadConfig(config);
      saveFilter();
      setResetFilterAction(false);
      setFilterData({ ...defaultFilterData });
    }
    if (!dataGrid.current || !dataGrid.current.instance) return;
    if (config && !getActualJsonState(false)) loadConfig(config);
  }, [config, resetFilterAction]);

  let own = '&operatorId=' + user?.personalNumber!;
  let workSheetsApi = `${API_URL.WORKSHEET_LIST_FILTERED}?classId=${assetClass}${
    filterData.ownFlag ? own : ''
  }`;
  if (filterData.dateFrom)
    workSheetsApi = `${API_URL.WORKSHEET_LIST_FILTERED}?classId=${assetClass}&filterFrom=${
      filterData.dateFrom
    }T00:00:00${filterData.ownFlag ? own : ''}`;
  if (filterData.dateTo)
    workSheetsApi = `${API_URL.WORKSHEET_LIST_FILTERED}?classId=${assetClass}&filterTo=${
      filterData.dateTo
    }T23:59:59${filterData.ownFlag ? own : ''}`;
  if (filterData.dateFrom && filterData.dateTo)
    workSheetsApi = `${API_URL.WORKSHEET_LIST_FILTERED}?classId=${assetClass}&filterFrom=${
      filterData.dateFrom
    }T00:00:00&filterTo=${filterData.dateTo}T23:59:59${filterData.ownFlag ? own : ''}`;

  const {
    data,
    error,
    mutate: mutateSheets,
  } = useSWR<WorkSheet[]>(workSheetsApi, swrTimeoutByConfig(config));

  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push(`/workSheets/edit/${e.row.data.id}`);
    }
  };

  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateSheets();
  };

  function sortJSON(arr, key, way) {
    if (!arr) return arr;
    return arr.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      if (way === '123') {
        return x < y ? -1 : x > y ? 1 : 0;
      }
      if (way === '321') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  return (
    <DatagridNoResponsiveContainer error={error} loading={!data || loadingPermission}>
      <WorkSheetsDataGrid        
        onFilterChanged={handleFilterChanged}
        resetFilter={() => setResetFilterAction(true)}
        dataGridRef={dataGrid}
        data={sortJSON(data, 'activityStarted', '321')}
        handleEdit={handleEditButton}
        userCanCreate={userHasPermissionToCreate}
        isDateFilter
        defaultFilterData={filterData}
      />
    </DatagridNoResponsiveContainer>
  );
}

export default WorkSheets;
