import { equals, find, ifElse, includes, isEmpty, isNil, o, or } from 'ramda';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import { useTerminalAuth } from '../contexts/terminalAuthContext';
import { UserRoles } from '../types/applicationUser.types';

export const useIsWorkSheetCreatePermission = (): [
  hasPermissionToCreateWorkSheet: boolean,
  loading: boolean,
] => {
  const { pathname } = useLocation();
  const { user: authUser, loading } = useAuth();

  const { user: terminalUser } = useTerminalAuth();

  return [
    o(
      (r) => !or(isNil(r), isEmpty(r)),
      find<UserRoles>(
        (r) => equals(UserRoles.WorksheetAll, r) || equals(UserRoles.WorksheetOwn, r),
      ),
    )(
      // @ts-ignore
      ifElse(
        // @ts-ignore
        includes('/terminal'),
        () => terminalUser?.roles,
        () => authUser?.roles,
      )(pathname),
    ),
    loading,
  ];
};
