import { Button } from 'devextreme-react';
import React, { forwardRef, useState } from 'react';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import '../ReportFilter.scss';

export enum DeleteState {
  WARNING_STATE = 'Warning.State',
  DELETE_STATE = 'Delete.State',
}

/**
 * Komponenta tlačítka pro smazání filtru pro editační dialog - potřebuje referenci na ReportSelector
 */
const DeleteButton = forwardRef((props, ref) => {
  const [deleteState, setDeleteState] = useState<DeleteState>(DeleteState.WARNING_STATE);
  const transFormName = 'Reports.ReportSelector';
  const { translate } = useTranslation();
  const { loading, startLoading } = useLoading();

  const handleDeleteAction = () => {
    startLoading();
    // @ts-ignore
    if (ref?.current) {
      // @ts-ignore
      ref?.current.deleteFilter();
      setDeleteState(DeleteState.WARNING_STATE);
    }
  };

  return (
    <Button
      className="button-spacing"
      type={deleteState === DeleteState.WARNING_STATE ? 'normal' : 'danger'}
      text={
        deleteState === DeleteState.WARNING_STATE
          ? translate!('Delete filter', transFormName)
          : translate!('Confirm delete', transFormName)
      }
      icon={deleteState === DeleteState.WARNING_STATE ? 'trash' : 'remove'}
      disabled={deleteState === DeleteState.DELETE_STATE && loading}
      onClick={() =>
        deleteState === DeleteState.WARNING_STATE
          ? setDeleteState(DeleteState.DELETE_STATE)
          : handleDeleteAction()
      }
    />
  );
});

DeleteButton.displayName = 'DeleteButton';

export default DeleteButton;
