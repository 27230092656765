import { Form, Popup } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { ButtonItem, ButtonOptions, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { Step } from '../../types/step.types';
import './EditCreateModal.scss';
import './TasksBulkEditModal.scss';

type Props = {
  data: any;
  stepList?: Step[];
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
  transFormName: string;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Asset cycle step v datagridu
 */
export default function AssetCycleStepModal({
  isVisible,
  data,
  stepList,
  actionType,
  onClose,
  onSubmit,
  transFormName,
}: Props) {
  const { translate } = useTranslation();
  const formRef = useRef<Form>(null);
  const { loading } = useLoading();
  const [formData, setFormData] = useState<any>({
    ...data,
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });
  const [isCounterVisible, setCounterVisible] = useState<boolean>(true);

  useEffect(() => {
    setFormData({
      ...data,
      ...(actionType === DataGridActionType.Insert && { visible: true }),
      frequency: data && data.frequency ? data.frequency : 1, // Defaultní hodnota bude 1. Pozor, pokud by byla skutečně frequence 0, v editu se přepíše na 1.
    });
  }, []);

  useEffect(() => {
    handleCounterVisibility();
  }, [formData]);

  const handleCounterVisibility = (): boolean => {
    // Skryjeme counter, pokud je měsíční frekvence
    const isFrequencyOne = formData.frequency === 1;
    setCounterVisible(!isFrequencyOne);
    return isFrequencyOne;
  };

  const onFrequencyChanged = () => {
    console.log('xxx');
    const isFrequencyOne = handleCounterVisibility();
    if (isFrequencyOne)
      setFormData({
        ...formData,
        counter: 0,
      });
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset cycle step', transFormName)}
      width={'20%'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="stepId"
              editorType="dxSelectBox"
              editorOptions={{
                items: stepList,
                valueExpr: 'id',
                displayExpr: 'name',
                readOnly: actionType === DataGridActionType.Update,
              }}
            >
              <Label text={translate!('Step', transFormName)} />
              <RequiredRule message={translate!('Select step', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="frequency"
              editorType="dxNumberBox"
              editorOptions={{ onValueChanged: () => onFrequencyChanged() }}
            >
              <Label text={translate!('Frequency', transFormName)} />
            </SimpleItem>
          </GroupItem>
          {isCounterVisible && (
            <GroupItem>
              <SimpleItem dataField="counter" editorType="dxNumberBox">
                <Label text={translate!('Counter', transFormName)} />
              </SimpleItem>
            </GroupItem>
          )}
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                disabled={loading}
                type={'success'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions type={'normal'} text={translate!('Close', '')} onClick={onClose} />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
