import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { AssetClassesDataGrid } from '../../components/datagrid/AssetClassesDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetClass } from '../../types/assetClass.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function AssetClasses() {
  const history = useHistory();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Asset class', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const { data, error } = useSWR<AssetClass[]>(
    `${API_URL.ASSET_CLASS_LIST}?visibleOnly=false`,
    fetcher,
    swrConfig,
  );

  const handleEditButton = (e): void => {
    history.push(`/asset-classes/edit/${e.row.data.id}`);
  };

  return (
    <PageContentWrapper loading={!data} error={error}>
      <AssetClassesDataGrid data={data} handleEdit={handleEditButton} />
    </PageContentWrapper>
  );
}
export default AssetClasses;
