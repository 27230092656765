import { confirm } from 'devextreme/ui/dialog';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { deleteApi, postApi, putApi } from '../../../../apiUtils';
import { AssetTabCycleStepsDataGrid } from '../../../../components/datagrid/AssetTabCycleStepsDataGrid';
import { useLoading } from '../../../../contexts/loadingContext';
import { useTranslation } from '../../../../contexts/translation';
import { AssetCycleParameterInputs } from '../../../../types/assetCycleParameter.types';
import { DataGridActionType } from '../../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../../types/pageParams.types';
import { ParameterInputs } from '../../../../types/parameter.types';
import { Step } from '../../../../types/step.types';
import { StepGroup } from '../../../../types/stepGroup.types';
import { API_URL, fetcher } from '../../../../utils/apiUrl';
import { swrConfig } from '../../AssetsEdit';

interface AssetTabCycleStepsProps {
  cycleId: number;
  assetId: number;
  isReadonly: boolean;
  mutateParent: () => void;
  active: boolean;
}

export interface StepView {
  id: number;

  stepId: number;
  stepGroupId: number;
  stepName: string;
  stepGroupName: string;

  capacityCategoryName: string;
  frequency: number;
  counter: number;
  estimatedOccurrence: string;
}

export function AssetTabCycleSteps(props: AssetTabCycleStepsProps) {
  const { translate } = useTranslation();
  const { id } = useParams<DetailPageParams>();

  const transFormName = 'Asset.CycleStep';
  const apiUrlAssetCycleAllSteps = `${API_URL.ASSET_CYCLE_GET_ALL_STEPS}?parentId=${props.cycleId}`;

  const [isModalStepVisible, setModalStepVisible] = useState<boolean>(false);
  const [isModalStepGroupVisible, setModalStepGroupVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetCycleParameterInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  const { startLoading, stopLoading } = useLoading();

  // Načteme stepy pro daný cyklus
  const { data: allSteps, mutate: mutateAllSteps } = useSWR(apiUrlAssetCycleAllSteps, fetcher);
  const [allStepsLocal, setAllStepsLocal] = useState<StepView[] | null>(null);

  // Steps pro modal
  const { data: stepList, mutate: mutateStepList } = useSWR<Step[]>(
    `${API_URL.STEP_LIST_BY_CYCLE}?assetCycleId=${props.cycleId}`,
    fetcher,
    swrConfig,
  );

  // Step groups pro modal
  const { data: stepGroupList, mutate: mutateStepGroupList } = useSWR<StepGroup[]>(
    `${API_URL.STEP_GROUP_LIST_BY_CYCLE}?assetCycleId=${props.cycleId}`,
    fetcher,
    swrConfig,
  );

  // Kontrola, jestli se jedná o měsíční cycle
  const { data: isCycleMonthly } = useSWR<boolean>(
    `${API_URL.ASSET_CYCLE_IS_CYCLE_MONTHLY}?parentId=${props.cycleId}`,
    fetcher,
    swrConfig,
  );

  useEffect(() => {
    if (props.active) mutateAllSteps();
  }, [props.active]);

  useEffect(() => {
    let _allSteps: StepView[] = [];
    allSteps?.forEach((item) => {
      _allSteps.push({
        id: item.id,
        stepId: item.stepId,
        stepGroupId: item.stepGroupId,
        stepName: item.stepName,
        stepGroupName: item.stepGroupName || 'Nezařazeno',
        capacityCategoryName: item.capacityCategoryName,
        frequency: item.frequency,
        counter: item.counter,
        estimatedOccurrence: item.estimatedOccurrence,
      });
    });
    setAllStepsLocal(_allSteps);
  }, [allSteps]);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset cycle steps', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          text: translate!('Add step', transFormName),
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNewStep,
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          text: translate!('Add step group', transFormName),
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNewStepGroup,
        },
      },
    );
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    const isStepGroup = !!_data.stepGroupId;

    switch (type) {
      case DataGridActionType.Insert:
        if (isStepGroup) {
          await postApi({
            url: API_URL.ASSET_CYCLE_STEP_GROUP_POST,
            data: {
              assetCycleId: props.cycleId,
              stepGroupId: _data.stepGroupId,
              frequency: _data.frequency,
              counter: _data.counter,
            },
          });
        } else {
          await postApi({
            url: API_URL.ASSET_CYCLE_STEP_POST,
            data: {
              assetCycleId: props.cycleId,
              stepId: _data.stepId,
              frequency: _data.frequency,
              counter: _data.counter,
            },
          });
        }
        break;
      case DataGridActionType.Update:
        if (isStepGroup) {
          await putApi({
            url: API_URL.ASSET_CYCLE_STEP_GROUP_PUT,
            data: {
              id: _data.id,
              stepGroupId: _data.stepId,
              frequency: _data.frequency,
              counter: _data.counter,
            },
          });
        } else {
          await putApi({
            url: API_URL.ASSET_CYCLE_STEP_PUT,
            data: {
              id: _data.id,
              stepId: _data.stepId,
              frequency: _data.frequency,
              counter: _data.counter,
            },
          });
        }
        break;
      default:
        break;
    }

    setSelectedRowData(null);
    setSelectedAction(null);
    mutateAllSteps();
    mutateStepList();
    mutateStepGroupList();
    handleClose();
  };

  const handleNewStep = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalStepVisible(true);
  };

  const handleNewStepGroup = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalStepGroupVisible(true);
  };

  const handleEdit = (e: any) => {
    let data = e.data;
    if (e.rowType === 'group') data = e.data.items[0];
    setSelectedRowData(data);
    setSelectedAction(DataGridActionType.Update);
    const isStepGroup = !!data.stepGroupId;
    if (isStepGroup) setModalStepGroupVisible(true);
    else setModalStepVisible(true);
  };

  const handleDelete = async (e: any) => {
    let data = e.data;
    if (e.rowType === 'group') data = e.data.items[0];

    const isStepGroup = !!data.stepGroupId;
    if (
      !(await confirm(
        translate!(
          `Are you sure you want to delete ${isStepGroup ? 'step group' : 'step'}?`,
          transFormName,
        ),
        translate!('Confirm', ''),
      ))
    )
      return;

    await deleteApi({
      url: isStepGroup ? API_URL.ASSET_CYCLE_STEP_GROUP_DELETE : API_URL.ASSET_CYCLE_STEP_DELETE,
      id: data.id,
    });
    mutateAllSteps();
    mutateStepList();
    mutateStepGroupList();
  };

  const onSubmit = (formData: ParameterInputs, type?: DataGridActionType) => {
    startLoading();
    handleApiCall({ type, key: selectedRowData?.id, data: formData }).then(() => stopLoading());
  };

  const handleClose = () => {
    setModalStepVisible(false);
    setModalStepGroupVisible(false);
    setSelectedRowData(null);
  };

  return (
    <AssetTabCycleStepsDataGrid
      isReadonly={props.isReadonly}
      data={allStepsLocal}
      stepList={stepList}
      stepGroupList={stepGroupList}
      onToolbarPreparing={onToolbarPreparing}
      modalVisibilityStep={isModalStepVisible}
      modalVisibilityStepGroup={isModalStepGroupVisible}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleClose={handleClose}
      onDataSubmit={onSubmit}
      selectedData={selectedRowData}
      selectedAction={selectedAction}
      isCycleMonthly={isCycleMonthly}
      assetCycleId={props.cycleId}
    />
  );
}
