/**
 * Funkce pro sčítání hodin a minut (v podobě řetězců) v group a total summary sloupcích
 * @param prevValue stará hodnota (total)
 * @param nextValue nová hodnota
 * @returns nový totální součet
 */
const parseTime = (prevValue, nextValue) => {
  const [pHours, pMinutes] = prevValue.split(':');
  const [nHours, nMinutes] = nextValue.split(':');
  let sHours = parseInt(pHours) + parseInt(nHours);
  let sMinutes = parseInt(pMinutes) + parseInt(nMinutes);
  // @ts-ignore
  let [dHours, dMinutes] = [parseInt(sMinutes / 60), sMinutes % 60];
  sHours += dHours;
  sMinutes = dMinutes;
  return `${sHours < 10 ? `0${sHours}` : sHours}:${sMinutes < 10 ? `0${sMinutes}` : sMinutes}`;
};

/**
 * Funkce pro parsování hodin z formátu "početDnů.hodiny"
 *    -> overlapsed hodiny (víc jak 24) se automaticky převádí na počty dnů - 50 hodin = 02.2 (2 dny a 2 hodiny)
 * @param time hodivy ve formátu "početDnů.hodiny"
 * @returns součet hodin
 */
const parseHours = (time) => {
  const [days, hours] = time.split('.');
  return hours ? parseInt(days) * 24 + parseInt(hours) : parseInt(days);
};

/**
 * Custom agregační funkce pro výpočty group/total summary řádky
 * @param options název summary sloupce pro custom agregační funkci
 */
export const customCalculationForSummaryRow = (options) => {
  // custom parser/sum funkce pro totální součty časů v datagridu u groupování
  // name: 'TimeCount'
  if (options.name === 'TimeCount') {
    // hodnota na začátku (init)
    if (options.summaryProcess === 'start') {
      options.totalValue = '00:00';
      // zpracování hodnoty v průběhu
    } else if (options.summaryProcess === 'calculate') {
      const [hours, minutes] = options.value.split(':');
      const nextValue = `${parseHours(hours)}:${minutes}`;
      options.totalValue = parseTime(options.totalValue, nextValue);
    }
  }
};
