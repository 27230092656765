export interface Unit {
  id: number;
  name: string;
  description: string;
  baseValueDataType: BaseValueDataType;
  visible: boolean;
}

export interface UnitInputs {
  id: number;
  name: string;
  description: string;
  baseValueDataType?: BaseValueDataType;
  visible: boolean;
}

// Base value data type
export enum BaseValueDataType {
  Bool = 0,
  Int = 1,
  Float = 2,
  Date = 3,
  DateTime = 4,
  Text = 5,
}
