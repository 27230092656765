import DataGrid, { FilterRow, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import Form, {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { AssetLocation } from '../../types/assetLocation.types';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { IForm } from '../../types/iForm';
import { NotificationTemplate } from '../../types/notificationTemplate.types';
import { TaskStatus } from '../../types/TaskStatus';
import { timeFieldOptionsWithSerializationWithInterval } from '../../utils/dates.types';

export interface ITaskNotificationConfigForm extends IForm {
  formData: any;
  assetLocationList: AssetLocation[];
  notificationTemplateList: NotificationTemplate[];
  taskProcessConfigList: TaskStatus[];
  catacityCategoryList: CapacityCategory[];
}

interface GridBox {
  gridBoxValue?: any;
  isGridBoxOpened?: boolean;
}

export const TaskNotificationConfigForm = ({
  onSubmit,
  formData,
  assetLocationList,
  notificationTemplateList,
  taskProcessConfigList,
  catacityCategoryList,
}: ITaskNotificationConfigForm) => {
  const [gridState, setGridState] = useState<GridBox>({
    gridBoxValue: [
      taskProcessConfigList.find((x: TaskStatus) => x.id === formData.taskProcessConfigId),
    ],
    isGridBoxOpened: false,
  });

  const { translate } = useTranslation();
  const transFormName = 'TaskNotificationConfig.Form';
  const history = useHistory();
  const backRoute = '/tasks/notification-config';
  const formRef = useRef<Form>(null);
  const { loading } = useLoading();

  const [prompt, setDirty, setClean, handleCloseButton] = useUnsavedChangesWarning(
    backRoute,
    () => {
      if (!formRef) return;
      if (formRef.current!.instance.validate().isValid) {
        setClean();
        onSubmit(null).then((result) => {
          if (result) history.push(backRoute);
          else setDirty();
        });
      }
    },
  );
  const gridColumns = [
    {
      dataField: 'documentTypeNameTranslated',
      caption: translate!('Document type name', transFormName),
      minWidth: 200,
    },
    {
      dataField: 'actionNameTranslated',
      caption: translate!('Action name', transFormName),
      minWidth: 200,
    },
    {
      dataField: 'statusFromNameTranslated',
      caption: translate!('Status from name', transFormName),
      minWidth: 200,
    },
    {
      dataField: 'statusToNameTranslated',
      caption: translate!('Status to name', transFormName),
      minWidth: 200,
    },
    {
      dataField: 'documentTypeToNameTranslated',
      caption: translate!('Document type to name', transFormName),
      minWidth: 200,
    },
    {
      dataField: 'id',
      caption: translate!('Role (id)', transFormName),
      width: 150,
    },
  ];

  console.log(gridColumns, 'gridColumns');
  const dataGridRender = () => {
    return (
      <DataGrid
        dataSource={taskProcessConfigList}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridState.gridBoxValue}
        onSelectionChanged={dataGridOnSelectionChanged}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };

  // gridBox
  const gridBoxDisplayExpr = () => {
    if (!gridState.gridBoxValue) return '';
    let taskProcesConfigText: string = '';
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.documentTypeNameTranslated === null
        ? ''
        : gridState.gridBoxValue[0]?.documentTypeNameTranslated;
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.actionNameTranslated === null
        ? ''
        : ', ' + gridState.gridBoxValue[0]?.actionNameTranslated;
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.statusFromNameTranslated === null
        ? ''
        : ', ' + gridState.gridBoxValue[0]?.statusFromNameTranslated;
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.statusToNameTranslated === null
        ? ''
        : ', ' + gridState.gridBoxValue[0]?.statusToNameTranslated;
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.documentTypeToNameTranslated === null
        ? ''
        : ', ' + gridState.gridBoxValue[0]?.documentTypeToNameTranslated;
    formData.taskProcessConfigId = gridState.gridBoxValue[0]?.id;
    taskProcesConfigText +=
      gridState.gridBoxValue[0]?.id === null ? '' : ` (${gridState.gridBoxValue[0]?.id})`;
    return taskProcesConfigText;
  };

  const syncDataGridSelection = (e) => {
    if (e.value && e.value[0]) {
      formData.taskProcessConfigId = e.value[0].id;
    } else {
      formData.taskProcessConfigId = null;
    }
    setGridState({ gridBoxValue: e.value });
  };

  const dataGridOnSelectionChanged = (e) => {
    setGridState({ gridBoxValue: e.selectedRowKeys, isGridBoxOpened: false });
  };

  const onGridBoxOpened = (e) => {
    if (e.name === 'opened') {
      setGridState({ isGridBoxOpened: e.value });
    }
  };
  return (
    <form
      onSubmit={(e) => {
        // @ts-ignore
        setClean();
        onSubmit(e);
      }}
    >
      <Form
        colCount={1}
        formData={formData}
        showValidationSummary={true}
        onFieldDataChanged={() => {
          // @ts-ignore
          setDirty();
        }}
        ref={formRef}
      >
        <GroupItem>
          <SimpleItem
            dataField="assetLocationId"
            editorType="dxSelectBox"
            editorOptions={{
              items: [{ id: null, name: '' } as AssetLocation, ...assetLocationList],
              valueExpr: 'id',
              displayExpr: 'name',
            }}
          >
            <Label text={translate!('Asset locations', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="taskProcessConfigId"
            editorType="dxSelectBox"
            editorOptions={{
              items: taskProcessConfigList,
              valueExpr: 'id',
              displayExpr: 'actionNameTranslated',
            }}
          >
            <Label text={translate!('Task process config', transFormName)} />
            <DropDownBox
              value={gridState.gridBoxValue}
              opened={gridState.isGridBoxOpened}
              valueExpr="id"
              deferRendering={false}
              displayExpr={gridBoxDisplayExpr}
              placeholder={translate!('Select a value...', '')}
              showClearButton={true}
              dataSource={taskProcessConfigList}
              onValueChanged={syncDataGridSelection}
              onOptionChanged={onGridBoxOpened}
              contentRender={dataGridRender}
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="capacityCategoryId"
            editorType="dxSelectBox"
            editorOptions={{
              items: [{ id: null, name: '' } as CapacityCategory, ...catacityCategoryList],
              valueExpr: 'id',
              displayExpr: 'name',
            }}
          >
            <Label text={translate!('Capacity category', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="templateId"
            editorType="dxSelectBox"
            editorOptions={{
              items: notificationTemplateList,
              valueExpr: 'id',
              displayExpr: function (data: NotificationTemplate) {
                if (data && data.id) {
                  return `${data.name} ${'(' + data.id + ')'}`;
                }
              },
            }}
          >
            <Label text={translate!('Notification template', transFormName)} />
            <RequiredRule message={translate!('Select notification template', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="delay"
            editorType="dxDateBox"
            editorOptions={{ ...timeFieldOptionsWithSerializationWithInterval }}
          >
            <Label text={translate!('Delay', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="repeat"
            editorType="dxDateBox"
            editorOptions={{ ...timeFieldOptionsWithSerializationWithInterval }}
          >
            <Label text={translate!('Repeat', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="isActive" editorType="dxCheckBox">
            <Label text={translate!('Is active', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="description" editorType="dxTextArea">
            <Label text={translate!('Description', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={14}>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              text={translate!('Close', '')}
              type={'normal'}
              width={'100%'}
              useSubmitBehavior={false}
              onClick={handleCloseButton}
            />
          </ButtonItem>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              width={'100%'}
              text={translate!('Save', '')}
              type={'success'}
              disabled={loading}
              useSubmitBehavior
            />
          </ButtonItem>
        </GroupItem>
        {prompt}
      </Form>
    </form>
  );
};
