import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/WorkActivityGroups/Columns';
import { WorkActivityGroupsFilterData } from '../../pages/WorkActivityGroups/WorkActivityGroups';
import { CapacityCategory } from '../../types/capacityCategory.types';
import WorkActivityGroupModal from '../modals/WorkActivityGroupModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  defaultFilterData?: WorkActivityGroupsFilterData;
  onFilterChanged?: (WorkActivityGroupsFilterData) => void;
  isDateFilter?: boolean;
  mutateData?: () => void;
  storageKey: string;
  modalVisibility: boolean;
  selectedData: any;
  selectedAction: any;
  capacityCategories?: Array<CapacityCategory>;
  onDataSubmit: (data: any, actionType: any) => void;
  handleModalVisibility: () => void;
}

export const WorkActivityGroupsDataGrid = ({
  data,
  handleEdit,
  defaultFilterData,
  onToolbarPreparing,
  isDateFilter,
  modalVisibility,
  selectedData,
  selectedAction,
  capacityCategories,
  onDataSubmit,
  handleModalVisibility,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkActivityGroup.List';
  const [filterData, setFilterData] = useState<WorkActivityGroupsFilterData | undefined>(
    isDateFilter ? defaultFilterData : undefined,
  );

  useEffect(() => {
    setFilterData(isDateFilter ? defaultFilterData : undefined);
  }, [defaultFilterData]);

  const columns = Columns(handleEdit, translate);

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={columns}
        onToolbarPreparing={onToolbarPreparing}
        exportName="work-activity-groups"
        enableXlsxExport
        columnChooser
        allowColumnResizing
        allowColumnReordering
        allowHeaderFiltering
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
      />
      {modalVisibility && (
        <WorkActivityGroupModal
          isVisible={modalVisibility}
          capacityCategories={capacityCategories ?? []}
          data={selectedData ?? null}
          actionType={selectedAction}
          onSubmit={onDataSubmit}
          onClose={handleModalVisibility}
        />
      )}
    </>
  );
};
