import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Loader } from '../../components/loader/Loader';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useTranslation } from '../../contexts/translation';
import WorkSheetsForm, { getDuration } from '../../forms/workSheets/WorkSheetsForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { Asset } from '../../types/asset.types';
import { CapacityCategory, CapacityCategoryPerson } from '../../types/capacityCategory.types';
import { DocumentType } from '../../types/documentType.types';
import { DetailWorkSheetParams } from '../../types/pageParams.types';
import { Person } from '../../types/person.types';
import { Task } from '../../types/task.types';
import { TaskStatusApiResponse } from '../../types/TaskStatus';
import { WorkActivity } from '../../types/workActivity.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function WorkSheetsNew() {
  const { user } = useAuth();
  const { translate } = useTranslation();
  const { assetId, taskId } = useParams<DetailWorkSheetParams>();
  const transFormName = 'WorkSheet.Create';
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();
  const configRestrictWorkActivitiesByCapacityCategoryPerson = useConfigByKey(
    CONFIG_KEYS.RESTRICT_WA_BY_CAPACITY_CATEGORY,
  );
  const restrictWorkActivitiesByCapacityCategoryPerson =
    configRestrictWorkActivitiesByCapacityCategoryPerson
      ? Boolean(configRestrictWorkActivitiesByCapacityCategoryPerson.value === '1')
      : false;

  useEffect(() => {
    setValue({
      pageName: 'New workSheet',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'WorkSheets', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const {
    data: task,
    error: errorTask,
    isValidating: taskLoading,
  } = useSWR<Task>(taskId ? `${API_URL.TASK_ITEM}?id=${taskId}` : null, fetcher, swrConfig);
  /**
   * Nacte typ pozadavku pro vytvareni z vykazu
   */
  const { data: newTaskDocumentType, error: errorNewTaskDocumentType } = useSWR<DocumentType[]>(
    `${API_URL.GET_LIST_FOR_TASK_CREATE}?parentId=${assetClass}&taskCreateWorksheet=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Na�te se seznam topLevelAsset� - linky
   */
  const { data: topLevelAssets, error: topLevelAssetsError } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS_TOP_LEVEL}?classId=${assetClass}&activeOnly=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Na�te se seznam �innost�
   */
  const { data: workActivities, error: workActivitiesError } = useSWR<WorkActivity[]>(
    `${API_URL.WORK_ACTIVITY_LIST}`,
    fetcher,
    swrConfig,
  );

  const { data: taskProcessConfigList, error: errorTaskProcessConfigList } =
    useSWR<TaskStatusApiResponse>(
      `${API_URL.TASK_PROCESS_CONFIG_LIST}?visibleOnly=${true}`,
      fetcher,
      swrConfig,
    );

  /**
   * Na�te zodpov�dn� osoby pro task action. Pouze v editaci.
   */
  const { data: workSheetOperators, error: workSheetOperatorsError } = useSWR<
    CapacityCategoryPerson[]
  >(`${API_URL.CAPACITY_CATEGORY_PERSON_LIST}?visibleOnly=true`, fetcher, swrConfig);

  let localWorkSheetOperators: CapacityCategoryPerson[] = [];
  workSheetOperators?.forEach((item) => {
    if (localWorkSheetOperators?.filter((x) => x?.personId === item.personId).length === 0) {
      localWorkSheetOperators = [...localWorkSheetOperators, item];
    }
  });

  // Defaultní kapacitní kategorie se načte z uživatele. Uživatel může být ve více kategoriích. Vezme se první.
  const defaultCapacityCategory = workSheetOperators?.find(
    (x) => x.personId === user?.personalNumber,
  );

  // Profiltrují se aktivity na základě kapacitní kategorie (defaultní nebo kategorie z tasku)
  const actions = workActivities?.filter(
    (x) =>
      x.capacityCategoryId ===
      (task
        ? task.capacityCategoryId
        : defaultCapacityCategory
        ? defaultCapacityCategory.capacityCategoryId
        : -1),
  );

  /**
   * Na�te seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  /**
   * Na�te seznam asset�, pokud je vybr�na linka (topLevelParenId), filtruj� se assety podle t�to linky
   */
  const {
    data: assets,
    error: assetsError,
    isValidating: isValidatingAssets,
  } = useSWR<Asset[]>(`${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`, fetcher, swrConfig);

  function dateFormat(days: number) {
    return moment(
      new Date(24 * 3600 * 1000 * Math.floor(new Date().getTime() / (24 * 3600 * 1000) + days)),
    ).format('Y-MM-DDTHH:mm:ss');
  }

  /**
   * Na�te se seznam task�
   */
  const { data: tasksByClass, error: errorTasksByClass } = useSWR<Task[]>(
    `${API_URL.TASK_BY_CLASS_WORKSHEET_CREATE_EDIT}?classId=${assetClass}&statusIdArr=10&statusIdArr=20&statusIdArr=50`,
    fetcher,
    swrConfig,
  );

  // Na�teme ��seln�k - Persons
  const { data: operators, error: errorOperators } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  const getTaskCloseReadonlyFlag = () => {
    const taskDocumentType = task
      ? task.typeId
      : newTaskDocumentType && newTaskDocumentType?.length > 0
      ? newTaskDocumentType.find((x) => x !== undefined)!.id
      : undefined;
    const taskStatusId = task ? task.statusId : 10;
    const config = taskProcessConfigList
      ? taskProcessConfigList.find(
          (x) =>
            x.documentTypeId === taskDocumentType &&
            x.statusFromId === taskStatusId &&
            x.actionId === 14,
        )
      : null;
    const result = !((!taskId || config) && activityFinished);
    return result;
  };

  if (
    (taskId && !task) ||
    !operators ||
    !workActivities ||
    !capacityCategories ||
    !assets ||
    !topLevelAssets ||
    !tasksByClass
  )
    return <Loader />;

  let tasksByClassWithCurrent = [...tasksByClass];
  // Vybraný task vložíme do seznamu tasků (pokud tam již není). Stávalo se, že ve výběru nebyl kvůli API podmínkám.
  if (task && taskId && tasksByClass.filter((x) => x.id === parseInt(taskId)).length === 0)
    tasksByClassWithCurrent = [...tasksByClass, task];

  let activityStarted = moment().format('Y-MM-DD HH:mm');
  let activityFinished: any = undefined;
  if (task && task.started) activityStarted = task.started;
  if (task && task.finished) activityFinished = task.finished;

  const taskResponsiblePersonId = task ? task.responsiblePersonId : null;
  const operatorId = user == null ? 0 : user.personalNumber;
  const result = getTaskCloseReadonlyFlag();
  const resultDefault = !taskId || taskResponsiblePersonId === operatorId;

  let duration = '';
  if (activityStarted && activityFinished) {
    const dateActivityFinished =
      String(activityFinished).split('T').length == 2
        ? moment(
            String(activityFinished).split('T')[0] + ' ' + String(activityFinished).split('T')[1],
          )
        : moment(activityFinished + ':00');

    const dateActivityStarted =
      String(activityStarted).split('T').length == 2
        ? moment(
            String(activityStarted).split('T')[0] + ' ' + String(activityStarted).split('T')[1],
          )
        : moment(activityStarted + ':00');
    duration = getDuration(dateActivityStarted, dateActivityFinished);
  }

  // Zkontroluje, že kategorie existuje, pokud ano vrátí její id, jinak undefined
  const checkCategoryExists = (categoryId: number | undefined) => {
    if (!capacityCategories) return;
    const category = capacityCategories.find((x: any) => x.id === categoryId);
    return category ? category.id : undefined;
  };

  return (
    <PageContentWrapper
      heading={translate!('New workSheet', transFormName)}
      loading={false}
      error={false}
    >
      <WorkSheetsForm
        isReadOnly={false}
        defaultIsTaskNameCombo={true}
        defaultFormData={{
          taskName: assetId ? assets.find((x) => x.id === parseInt(assetId))?.name : '',
          operatorName: '',
          workActivityName: '',
          capacityCategoryName: '-',
          assetName: '',
          topLevelParenName: '',
          statusNameTranslated: '',
          typeNameTranslated: '',
          responsiblePersonName: '',

          taskProcessConfigList: taskProcessConfigList,
          task: task,
          operators: operators,
          workActivities: workActivities,
          capacityCategories: capacityCategories,
          assets: assets,
          topLevelAssets: topLevelAssets,
          tasksByClass: tasksByClassWithCurrent,
          workSheetOperatorsCapacityCategories: workSheetOperators,
          workSheetOperators: localWorkSheetOperators,
          assetId: assetId
            ? parseInt(assetId) === 0
              ? task?.assetId
                ? task?.assetId
                : undefined
              : undefined
            : undefined,
          id: 0,
          taskId: taskId ? parseInt(taskId) : 0,
          taskCreated: taskId ? task?.created : null,
          topLevelParenId: task
            ? task.assetTopLevelParenId
            : assetId
            ? assets.find((x) => x.id === parseInt(assetId))?.topLevelParenId
            : undefined,
          capacityCategoryId: checkCategoryExists(
            task
              ? task.capacityCategoryId
              : defaultCapacityCategory
              ? defaultCapacityCategory.capacityCategoryId
              : undefined,
          ),
          // Pokud má defaultní aktivity podtržítko, vybere ji, jinak nic
          workActivityId:
            actions && actions.length > 0
              ? actions[0].name.indexOf('__') === 0
                ? actions[0].id
                : undefined
              : undefined,
          taskDocumentType: task
            ? task.typeId
            : newTaskDocumentType && newTaskDocumentType?.length > 0
            ? newTaskDocumentType.find((x) => x !== undefined)!.id
            : undefined,
          taskDocumentTypeCompleteBehavior: task
            ? task.typeTaskCompleteBehavior
            : newTaskDocumentType && newTaskDocumentType?.length > 0
            ? newTaskDocumentType.find((x) => x !== undefined)!.typeTaskCompleteBehavior
            : undefined,
          taskCloseReadonlyFlag: result,
          taskCloseDefaultValue: result ? false : resultDefault,
          taskComplete: result ? false : resultDefault,
          defaultNewTaskDocumentType:
            newTaskDocumentType && newTaskDocumentType?.length > 0
              ? newTaskDocumentType.find((x) => x !== undefined)!.id
              : undefined,
          defaultTaskCompleteBehavior:
            newTaskDocumentType && newTaskDocumentType?.length > 0
              ? newTaskDocumentType.find((x) => x !== undefined)!.worksheetTaskCompleteBehavior
              : undefined,
          workSheetStatusId: 10,
          taskStatusId: task ? task.statusId : 10,
          taskResponsiblePersonId: task ? task.responsiblePersonId : null,
          activityStarted: activityStarted,
          activityFinished: activityFinished,
          operatorId: user == null ? 0 : user.personalNumber,
          activityDuration: duration!,
        }}
        defaultFormDataTask={{}}
        transFormName={'WorkSheet.Create'}
        today={moment()}
        restrictWorkActivitiesByCapacityCategoryPerson={
          restrictWorkActivitiesByCapacityCategoryPerson
        }
      />
    </PageContentWrapper>
  );
}
