import { cond, equals, identity } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AssetGeneralForm } from '../../forms/assetGeneral/AssetGeneralForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { Asset, AssetInputs } from '../../types/asset.types';
import { AssetLevel } from '../../types/assetLevel.types';
import { AssetLocation } from '../../types/assetLocation.types';
import { AssetStatus } from '../../types/assetStatus.types';
import { AssetType } from '../../types/assetType.type';
import { DetailPageParams } from '../../types/pageParams.types';
import { Person } from '../../types/person.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import { swrConfig } from './AssetsEdit';

const initFormData: AssetInputs = {
  id: 0,
  inventoryNumber: '',
  serialNumber: '',
  partNumber: '',
  model: '',
  name: '',
  description: '',
  producedBySupplierName: '',
  deliveredBySupplierName: '',
  // statusId: null,
  // assetLocationId: null,
  ownerName: '',
  centre: '',
  building: '',
  room: '',
  visible: true,
};

export function AssetsNew() {
  const { translate } = useTranslation();
  const transFormName = 'Asset.Create';
  const { assetClass } = useAssetClass();
  const [selectedAssetLevelId, setSelectedAssetLevelId] = useState<number | null>(null);
  const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
  const [who, setWho] = useState<string>('new');
  const { startLoading, stopLoading } = useLoading();
  const { id } = useParams<DetailPageParams>();
  const [formData, setFormData] = useState<AssetInputs>(initFormData);
  const [isNewInstance, setNewInstance] = useState<boolean>(true);
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New asset',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Assets', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  /**
   * Načte asset podle id.
   */
  const { data: asset } = useSWR<Asset>(
    id ? `${API_URL.ASSET_ITEM}?id=${id}` : '',
    fetcher,
    swrConfig,
  );

  /**
   * Po načtení assetu je nutné nastavit defaultní hodnoty formuláře.
   */
  useEffect(() => {
    if (!asset) return;
    setFormData({
      ...formData,
      name: asset.name,
      typeId: asset.typeId,
      levelId: asset.levelId,
      parentAssetId: asset.parentAssetId,
      statusId: asset.statusId,
      assetLocationId: asset.assetLocationId,
      model: asset.model,
      producedBySupplierName: asset.producedBySupplierName,
      deliveredBySupplierName: asset.deliveredBySupplierName,
    });
    setSelectedTypeId(asset.typeId);
  }, [asset]);

  // Načteme číselník - Asset - kvůli parentu
  const { data: assets, error: errorAssets } = useSWR<Asset[]>(
    formData?.typeId ? `${API_URL.ASSET_LIST_WITH_HIGHER_LEVEL}?typeId=${formData?.typeId}` : null,
    fetcher,
  );

  // Načteme číselník - AssetType
  const { data: assetTypes, error: errorAssetTypes } = useSWR<AssetType[]>(
    API_URL.ASSET_TYPE_LIST,
    fetcher,
  );
  // Načteme číselník - AssetStatus
  const { data: assetStatuses, error: errorAssetStatuses } = useSWR<AssetStatus[]>(
    API_URL.ASSET_STATUS_LIST,
    fetcher,
  );
  // Načteme číselník - Persons
  const { data: persons, error: errorPersons } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );
  // Načteme číselník - AssetLevel
  const { data: levels, error: errorLevels } = useSWR<AssetLevel[]>(
    `${API_URL.ASSET_LEVEL_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
  );
  // Načteme číselník - AsseLocation
  const {
    data: assetLocations,
    error: errorAssetLocations,
    mutate: mutateAssetLocations,
    isValidating: isValidatingAssetLocations,
  } = useSWR<AssetLocation[]>(`${API_URL.ASSET_LOCATION_LIST}?visibleOnly=${true}`, fetcher);

  const history = useHistory();

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await postApi<AssetInputs>({
      url: API_URL.ASSET_POST,
      data: formData,
      callAfterSuccess: (d) => {
        isOk = true;
        setNewInstance(true);
        // @ts-ignore
        cond([
          // @ts-ignore
          [equals('new'), () => setFormData({ ...initFormData })],
          // @ts-ignore
          [equals('list'), () => history.push('/assets')],
          // @ts-ignore
          [equals('continue'), () => history.push(`/assets/edit/${d.id}`)],
        ])(
          // @ts-ignore
          who,
        );
      },
    });
    stopLoading();
    return isOk;
  };

  const handleAssetLevelChange = (e) => {
    setSelectedAssetLevelId(e.value);
  };

  const handleTypeChange = (e) => {
    if (e.value !== selectedTypeId) {
      setFormData({ ...formData, typeId: e.value, parentAssetId: undefined });
      setSelectedTypeId(e.value);
    }
  };

  const getAssetTypes = (): AssetType[] => {
    if (selectedAssetLevelId === null || !assetTypes) return [];
    return assetTypes.filter((x) => x.levelId === selectedAssetLevelId);
  };

  return (
    <PageContentWrapper
      heading={translate!('New asset', transFormName)}
      loading={!assetTypes || !assetStatuses || !persons || !levels || !assetLocations}
      error={
        errorAssets ||
        errorAssetTypes ||
        errorAssetStatuses ||
        errorPersons ||
        errorLevels ||
        errorAssetLocations
      }
    >
      <AssetGeneralForm
        formData={formData}
        defaultFormData={formData}
        isReadOnly={false}
        assets={assets}
        levels={levels}
        handleAssetLevelChange={handleAssetLevelChange}
        handleTypeChange={handleTypeChange}
        getAssetTypes={getAssetTypes}
        assetStatuses={assetStatuses}
        assetLocations={assetLocations}
        persons={persons}
        dateFieldOptions={dateFieldOptionsWithSerialization}
        handleCloseButton={identity}
        setWho={setWho}
        onSubmit={onSubmit}
        isCopy={!!asset}
        isNewInstance={isNewInstance}
        setNewInstance={setNewInstance}
      />
    </PageContentWrapper>
  );
}

export default AssetsNew;
