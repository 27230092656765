import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { postApi, putApi } from '../../apiUtils';
import { WorkActivityGroupsDataGrid } from '../../components/datagrid/WorkActivityGroupsDatagrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { WorkActivityGroup, WorkActivityGroupInputsNew } from '../../types/workActivityGroup.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';

export interface WorkActivityGroupsFilterData {
  dateFrom: string | null;
  dateTo: string | null;
}

const defaultFilterData: WorkActivityGroupsFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
};

/**
 * Přehled entity Work Activity Group.
 */
export function WorkActivityGroups() {
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'WorkActivityGroup.List';
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<WorkActivityGroupInputsNew | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGrid = useRef<any>(null);
  // Nastavení filtru (mimo DataGrid) - ukládáme/načítáme do/z localStorage
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.TASKS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const {
    data: workActivityGroups,
    error: workActivityGroupsError,
    mutate: mutateWAGroups,
  } = useSWR<WorkActivityGroup[]>(
    `${API_URL.WORK_ACTIVITY_GROUP_LIST_BY_ASSET_CLASS}?assetClassId=${assetClass}&visibleOnly=false`,
    fetcher,
  );

  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  useEffect(() => {
    setValue({ pageName: 'Work activity groups', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Work activity groups', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.WORK_ACTIVITY_GROUP_POST,
          data: { ..._data },
        });
        break;
      case DataGridActionType.Update:
        const oldData = workActivityGroups?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.WORK_ACTIVITY_GROUP_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      // case DataGridActionType.Remove:
      //   await deleteApi({
      //     url: API_URL.WORK_ACTIVITY_GROUP_POST,
      //     id: key,
      //   });
      //   break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateWAGroups();
    handleClose();
  };

  const handleNew = () => {
    history.push({
      pathname: `/work-activity-groups/detail/`,
      state: { backRoute: '/work-activity-groups', isNew: true },
    });
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    history.push({
      pathname: `/work-activity-groups/detail/${e.row.data.id}`,
      state: { backRoute: '/work-activity-groups', isNew: false },
    });
  };

  const onSubmit = (formData: WorkActivityGroupInputsNew, type?: DataGridActionType) => {
    handleApiCall({ type, key: formData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateWAGroups();
  };

  return (
    <PageContentWrapper
      error={workActivityGroupsError || capacityCategoriesError}
      loading={!workActivityGroups || !capacityCategories}
    >
      <WorkActivityGroupsDataGrid
        dataGridRef={dataGrid}
        data={workActivityGroups}
        mutateData={mutateWAGroups}
        onToolbarPreparing={onToolbarPreparing}
        defaultFilterData={filterData}
        onFilterChanged={handleFilterChanged}
        handleEdit={handleEdit}
        storageKey={STORAGE_KEYS.WORK_ACTIVITY_GROUPS_DATAGRID_DESKTOP.toString()}
        isDateFilter
        modalVisibility={isModalVisible}
        handleModalVisibility={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
        capacityCategories={capacityCategories}
      />
    </PageContentWrapper>
  );
}

export default WorkActivityGroups;
