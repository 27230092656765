import Form, {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { IForm } from '../../types/iForm';
import { dxSelectBoxConfig } from '../tasks/TasksForm';

export interface IStepsForm extends IForm {
  capacityCategories?: CapacityCategory[];
}

export const StepGroupForm = ({ onSubmit, formData, capacityCategories, isEdit }: IStepsForm) => {
  const { translate } = useTranslation();
  const transFormName = 'StepGroup.Form';
  const { loading } = useLoading();
  const history = useHistory();
  const backRoute = '/step-groups';
  const formRef = useRef<Form>(null);
  const [prompt, setDirty, setClean, handleCloseButton] = useUnsavedChangesWarning(
    backRoute,
    () => {
      if (!formRef) return;
      if (formRef.current!.instance.validate().isValid) {
        setClean();
        onSubmit(null).then((result) => {
          if (result) history.push(backRoute);
          else setDirty();
        });
      }
    },
  );

  return (
    <form
      onSubmit={(e) => {
        onSubmit(e);
        if (formRef && formRef.current && !isEdit) formRef.current?.instance.resetValues();
        // @ts-ignore
        setClean();
      }}
    >
      <Form
        colCount={1}
        formData={formData}
        showValidationSummary={true}
        onFieldDataChanged={() => {
          // @ts-ignore
          setDirty();
        }}
        ref={formRef}
      >
        <GroupItem>
          <SimpleItem dataField="name" editorType="dxTextBox">
            <Label text={translate!('Name', transFormName)} />
            <RequiredRule message={translate!('Enter name', transFormName)} />
          </SimpleItem>
        </GroupItem>

        <GroupItem>
          <SimpleItem dataField="description" editorType="dxTextArea">
            <Label text={translate!('Description', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="capacityCategoryId"
            editorType="dxSelectBox"
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              items: capacityCategories,
            }}
          >
            <RequiredRule message={translate!('Capacity category required', transFormName)} />
            <Label text={translate!('Capacity category', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="visible" editorType="dxCheckBox">
            <Label text={translate!('Visible', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={14}>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              width={'100%'}
              text={translate!('Close', '')}
              type={'normal'}
              useSubmitBehavior={false}
              onClick={handleCloseButton}
            />
          </ButtonItem>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              width={'100%'}
              disabled={loading}
              text={translate!('Save', '')}
              type={'success'}
              useSubmitBehavior
            />
          </ButtonItem>
        </GroupItem>
        {prompt}
      </Form>
    </form>
  );
};
