/**
 * Spočítá šířku neresponzivního datagridu na základě šířky jeho sloupců.
 **/
export const getWidthByColumns = (columns, defaultColumns, ignoreDefaultColumns) => {
  let datagridWidth = 0;
  columns
    .filter((x) => x.visible === undefined || x.visible === null || x.visible)
    .forEach((item) => {
      datagridWidth += Number(item.width?.toString().replace('px', '').replace('%', '')) + 17;
    });

  if (ignoreDefaultColumns) return datagridWidth;

  // Může se stát, že v jsonu chybí nějaké sloupce, šířku cheme ale přičíst
  defaultColumns
    .filter((x) => x.visible === undefined || x.visible === null || x.visible)
    .forEach((item) => {
      const isInJsonColumns = columns.filter((x) => x.dataField === item.dataField).length > 0;
      if (!isInJsonColumns) {
        console.log('missing', item);
        datagridWidth += Number(item.width?.toString().replace('px', '').replace('%', '')) + 17;
      }
    });

  return datagridWidth;
};

export const getWidthByColumnsByStorageKey = (storageKey: string, defaultColumns) => {
  const item = window.sessionStorage.getItem(storageKey);
  if (!item || Object.keys(item).length === 2)
    return getWidthByColumns(defaultColumns, defaultColumns, true);
  return getWidthByColumns(JSON.parse(item).columns, defaultColumns, false);
};

export const scrollDatagridNoResponsive = (menuSize?: number) => {
  const datagridHeader = document.querySelector(
    '.datagrid-no-responsive .dx-datagrid-header-panel',
  );
  const datagridNoResponsive = document.querySelector('.datagrid-no-responsive');
  const datagridFooter = document.querySelector('.datagrid-no-responsive .dx-datagrid-pager');
  const scrollContainer = document.querySelector('.datagrid-scroll-container');
  const reportFilter = document.querySelector('.no-responsive-report-filter-scrollable');

  const headerToolbar = document.querySelector('.header-component');

  if (!datagridHeader || !scrollContainer || !datagridNoResponsive || !headerToolbar) return;
  if (!menuSize) menuSize = 250;

  if (datagridNoResponsive.clientWidth < scrollContainer.clientWidth) {
    // @ts-ignore
    headerToolbar.style.left = `0px`;
    return;
  }

  // @ts-ignore
  datagridHeader.style.left = `${scrollContainer.scrollLeft}px`;
  // @ts-ignore
  datagridFooter.style.left = `${scrollContainer.scrollLeft}px`;

  // // @ts-ignore
  // headerToolbar.style.position = `relative`;
  // @ts-ignore
  headerToolbar.style.left = `${scrollContainer.scrollLeft}px`;

  if (reportFilter)
    // @ts-ignore
    reportFilter.style.left = `${scrollContainer.scrollLeft}px`;

  // @ts-ignore
  datagridHeader.style.width = `${document.body.clientWidth - (menuSize + 50)}px`;
  // @ts-ignore
  datagridFooter.style.width = `${document.body.clientWidth - (menuSize + 50)}px`;
};
