import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getItemApi, postApi } from '../../../apiUtils';
import { ChartConfig, ReportPosition } from '../../../components/chart/Chart.types';
import { ChartContainer } from '../../../components/chart/ChartContainer';
import { GeneralReportDataGrid } from '../../../components/datagrid/GeneralReportDataGrid';
import { DatagridNoResponsiveContainer } from '../../../components/datagridNoResponsive/DatagridNoResponsiveContainer';
import { Loader } from '../../../components/loader/Loader';
import { BreadcrumbValueType } from '../../../contexts/breadcrumbsContext';
import { useLoading } from '../../../contexts/loadingContext';
import GeneralReportFilter from '../../../forms/filter/generalReport/GeneralReportFilter';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { ReportsState } from '../../../types/locationState.types';
import { API_URL } from '../../../utils/apiUrl';
import { isNilOrEmpty } from '../../../utils/ramdaUtils';
import '../Reports.scss';

export type GeneralDataType = {
  data: Array<any>;
  // filterFields: Array<any>;
  datagrid: object;
  report: {
    name: string;
    breadcrumbs: BreadcrumbValueType;
    settings: {
      id: number;
      key: string;
    };
    actions: {
      new: string;
    };
  };
};

export type GeneralReport = {
  id: number;
  name: string;
  sqlProcedure: string;
  created: string;
  createdBy?: number;
  description?: string;
  updated?: string;
  updatedBy?: number;
  visible: boolean;
};

export type GeneralReportParameter = {
  id: number;
  generalReportId: number;
  name?: string;
  type?: string;
  procedureParameterName?: string;
  defaultValue?: string;
  sourceApi?: string;
  valueName?: string;
  textName?: string;
  ordering: number;
  createdBy: number;
  created?: string;
  updatedBy: null;
  updated?: string;
};

type DataForGeneralReport = {
  data: Array<unknown>;
  datagrid: {
    columns: Array<unknown>;
    allowedPageSizes: Array<number | string>;
    filterPanel: {
      filterEnabled: boolean;
    };
    filterValue?: any;
    searchText?: string;
    pageIndex: number;
    pageSize: number;
  };
  chart?: ChartConfig;
};

export function GeneralReport() {
  const [report, setReport] = useState<GeneralReport | null>(null);
  const [reportParameters, setReportParameters] = useState<Array<GeneralReportParameter> | null>(
    null,
  );
  const [dataAndStructure, setDataAndStructure] = useState<DataForGeneralReport | null>(null);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [selectedFilterDatagrid, setSelectedFilterDatagrid] = useState<any | null>(null);
  const { stopLoading } = useLoading();

  const { pathname } = useLocation();
  const { state }: { state: ReportsState | undefined } = useLocation();

  // Report ID. Správné načítání ID z nějakého důvodu nefunguje korektně.
  const id = pathname.split('/')[2];

  useEffect(() => {
    if (id) {
      getReportInfo(Number(id));
      getReportParameters(Number(id));
    }
  }, []);

  const history = useHistory();
  // Načtení práv uživatele
  const [userHasPermissionToCreate] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const reportKey = `general_reports_${report?.id}`;

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        // TODO: přidat edit path
        pathname: `${''}${e.row.data.id}`,
        state: { backRoute: pathname },
      });
    }
  };

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const getReportInfo = async (id: number) => {
    await getItemApi<any>({
      url: `${API_URL.GENERAL_REPORT_ITEM}?id=${id}`,
      params: {},
      callAfterSuccess: async (result) => {
        setReport(result);
      },
    });
  };

  const getReportParameters = async (id: number) => {
    await getItemApi<any>({
      url: `${API_URL.GENERAL_REPORT_PARAMETER}?parentId=${id}`,
      params: {},
      callAfterSuccess: async (result) => {
        setReportParameters(result);
      },
    });
  };

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const onParamsValueChange = async (params = {}) => {
    const response = await postApi<DataForGeneralReport>({
      url: `${API_URL.GENERAL_REPORT_DATA}?reportId=${id}`,
      data: params,
      hideSuccessNotifications: true,
    });
    setDataAndStructure(response ?? null);
    stopLoading();
  };

  const getReport = (className?: string) => {
    if (dataAndStructure && dataAndStructure.chart && dataAndStructure.chart.enabled)
      return (
        <ChartContainer
          data={dataAndStructure.data}
          config={dataAndStructure.chart}
          className={className}
        />
      );
    return <></>;
  };

  if (!report && !reportParameters) return <Loader />;

  return (
    <DatagridNoResponsiveContainer loading={!report}>
      {reportParameters && report && (
        <GeneralReportFilter
          dataGridRef={dataGridRef}
          onParamsValueChange={onParamsValueChange}
          report={report}
          onFilterChange={(name: string | null, dataGrid: any | null) => {
            setSelectedFilter(name);
            setSelectedFilterDatagrid(dataGrid);
          }}
          selectedFilter={selectedFilter}
          filterVisibility={isFilterVisible}
          loadDefaultSetting={!state?.loadSettingFromSession}
          storageKey={reportKey}
          transFormName={''}
          filterFields={reportParameters}
          isDataPresent={dataAndStructure !== null}
          heading={`${report?.name}`}
          setSelectedFilter={setSelectedFilter}
        />
      )}
      {dataAndStructure &&
        dataAndStructure.chart?.reportPosition === ReportPosition.TOP &&
        getReport()}
      {dataAndStructure &&
        dataAndStructure.chart?.reportPosition === ReportPosition.LEFT &&
        getReport('inline left')}
      {dataAndStructure && (
        <GeneralReportDataGrid
          dataGridRef={dataGridRef}
          selectedFilterDatagrid={selectedFilterDatagrid}
          data={null}
          dataAndStructure={dataAndStructure}
          resetFilter={() => setResetFilterAction(true)}
          userCanCreate={userHasPermissionToCreate}
          handleEdit={handleEditButton}
          filterVisibility={isFilterVisible}
          setFilterVisibility={setFilterVisible}
          transFormName={''}
          newEntryActionPath={''}
          datagridNoResponsiveClassName={
            dataAndStructure && dataAndStructure.chart?.reportPosition === ReportPosition.RIGHT
              ? 'left'
              : ''
          }
        />
      )}
      {dataAndStructure &&
        dataAndStructure.chart?.reportPosition === ReportPosition.RIGHT &&
        getReport('inline')}
      {dataAndStructure &&
        dataAndStructure.chart?.reportPosition === ReportPosition.BOTTOM &&
        getReport()}
    </DatagridNoResponsiveContainer>
  );
}
export default GeneralReport;
