import Form, {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useRef, useState } from 'react';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategory } from '../../types/capacityCategory.types';

interface Props {
  data: any;
  capacityCategories?: Array<CapacityCategory>;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const WorkActivityGroupForm = ({ onSubmit, data, onClose, capacityCategories }: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkActivityGroup.List';
  const formRef = useRef<Form>(null);

  const [formData, setFormData] = useState<any>();
  const { loading } = useLoading();

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      <Form
        formData={formData}
        showColonAfterLabel={true}
        showValidationSummary={true}
        ref={formRef}
      >
        <GroupItem colCount={10}>
          <SimpleItem dataField="name" editorType="dxTextBox" colSpan={3}>
            <Label text={translate!('Name', transFormName)} />
            <RequiredRule message={translate!('Name is required', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="capacityCategoryId"
            editorType="dxSelectBox"
            editorOptions={{
              items: capacityCategories,
              valueExpr: 'id',
              displayExpr: 'name',
              width: 345,
            }}
          >
            <Label text={translate!('Capacity category', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="visible" editorType="dxCheckBox">
            <Label text={translate!('Visible', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={14}>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              text={translate!('Close', '')}
              type={'normal'}
              width={'100%'}
              useSubmitBehavior={false}
              onClick={onClose}
            />
          </ButtonItem>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              text={translate!('Save', '')}
              type={'success'}
              width={'100%'}
              disabled={loading}
              useSubmitBehavior
            />
          </ButtonItem>
        </GroupItem>
      </Form>
    </form>
  );
};
