import notify from 'devextreme/ui/notify';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getItemApi, postApi } from '../apiUtils';
import { Loader } from '../components/loader/Loader';
import { Platform } from '../components/loginForm/LoginForm';
import { clearLocalSession } from '../hooks/useSessionStorage';
import { ApplicationUser } from '../types/applicationUser.types';
import { API_URL } from '../utils/apiUrl';
import { useAssetClass } from './assetClass';
import { useConfig } from './configContext';
import { useTranslation } from './translation';

interface AuthContextType {
  user: ApplicationUser | null | undefined;
  loading: boolean;
  entryUrl: string | null;
  signIn?: (
    username: string,
    password: string,
    uid: string,
    assetClassId: number,
    platform: string,
  ) => Promise<void>;
  signOut?: () => Promise<void>;
  setEntryUrl?: (entryUrl: string | null) => void;
}

const MobileAuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  entryUrl: null,
});

const useMobileAuth = () => useContext<AuthContextType>(MobileAuthContext);

interface Props {
  children?: any;
}

function MobileAuthProvider(props: Props) {
  const [user, setUser] = useState<ApplicationUser | null>();
  const [entryUrl, setEntryUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { changeAssetClass } = useAssetClass();
  const { setDefaultConfigToStorage } = useConfig();
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'LoginPage';

  useEffect(() => {
    (async function () {
      await getItemApi({
        url: `${API_URL.IS_USER_AUTHENTICATED}`,
        params: {},
        callAfterSuccess: async (result: boolean) => {
          if (result) {
            await getItemApi<ApplicationUser>({
              url: `${API_URL.GET_CURRENT_USER}`,
              params: {},
              callAfterSuccess: async (user: ApplicationUser) => {
                // Pokud má uživatel jinou platformu než MOBILE, musíme ji změnit
                if (user.platform !== Platform.MOBILE) {
                  await postApi<ApplicationUser>({
                    url: `${API_URL.CHANGE_CURRENT_PLATFORM}`,
                    data: { platform: Platform.MOBILE },
                    callAfterSuccess: () => {
                      user.platform = Platform.MOBILE;
                    },
                  });
                }

                setUser(user);
                if (changeAssetClass && user.assetClassId !== null)
                  changeAssetClass(user.assetClassId);
              },
              hideNotifications: true,
            });
          }
        },
        hideNotifications: true,
      });
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(
    async (
      username: string,
      password: string,
      uid: string,
      assetClassId: number,
      platform: string,
    ): Promise<void> => {
      if (uid) {
        await postApi<ApplicationUser>({
          url: `${API_URL.LOGIN_UID}`,
          data: { uid: uid, assetClassId, platform },
          callAfterSuccess: (result) => {
            setUser(result);
            if (changeAssetClass && result.assetClassId !== null)
              changeAssetClass(result.assetClassId);
            clearLocalSession();
            setDefaultConfigToStorage!();
          },
        });
        return;
      }

      await postApi<ApplicationUser>({
        url: `${API_URL.LOGIN}`,
        data: { login: username, password, assetClassId, platform },
        ignoreUnAuthorizedError: true,
        callAfterSuccess: (result) => {
          setUser(result);
          if (changeAssetClass && result.assetClassId !== null)
            changeAssetClass(result.assetClassId);
          clearLocalSession();
          setDefaultConfigToStorage!();
        },
        callAfterError: () => {
          notify(translate!('Wrong login or password', transFormName), 'error');
        },
      });
    },
    [],
  );

  const signOut = useCallback(async (): Promise<void> => {
    await postApi<ApplicationUser>({
      url: `${API_URL.LOGOUT}`,
      data: { logout: true },
      callAfterSuccess: () => {
        setEntryUrl(null);
        setUser(null);
      },
    });
  }, []);

  return (
    <MobileAuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        loading,
        setEntryUrl,
        entryUrl,
      }}
      {...props}
    >
      {loading ? <Loader /> : <>{props.children}</>}
    </MobileAuthContext.Provider>
  );
}

export { MobileAuthProvider, useMobileAuth };
