import { Switch } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { useClearButtonProps } from '../../hooks/useClearButtonProps';
import { Columns } from '../../pages/WorkSheets/Columns';
import { paintCellByWorkSheetColor } from '../../pages/WorkSheets/utils/paintCellByColor';
import { paintRowByWorkSheetColor } from '../../pages/WorkSheets/utils/paintRowByColor';
import { WorkSheetsFilterData } from '../../pages/WorkSheets/WorkSheets';
import { getWidthByColumnsByStorageKey } from '../../utils/datagridNoResponsive';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { DatagridNoResponsive } from '../datagridNoResponsive/DatagridNoResponsive';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  defaultFilterData?: WorkSheetsFilterData;
  onFilterChanged?: (WorkSheetsFilterData) => void;
  isDateFilter: boolean;
}

export const WorkSheetsDataGrid = ({
  data,
  handleEdit,
  resetFilter,
  dataGridRef,
  userCanCreate,
  defaultFilterData,
  onFilterChanged,
  isDateFilter,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkSheet.List';
  const history = useHistory();
  const [filterData, setFilterData] = useState<WorkSheetsFilterData | undefined>(
    isDateFilter ? defaultFilterData : undefined,
  );

  useEffect(() => {
    setFilterData(isDateFilter ? defaultFilterData : undefined);
  }, [defaultFilterData]);

  const dateFromProps = useClearButtonProps();
  const dateToProps = useClearButtonProps();

  const onToolbarPreparing = (e) => {
    if (isDateFilter && onFilterChanged && filterData) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        html: translate!('WorkSheets', transFormName),
      });

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            visible: userCanCreate,
            icon: 'add',
            type: 'default',
            stylingMode: 'contained',
            onClick: () => history.push(`/workSheets/new/`),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'clear',
            type: 'info',
            text: translate!('Clear filter', transFormName),
            stylingMode: 'outlined',
            onClick: () => {
              if (resetFilter && isFunction(resetFilter)) {
                resetFilter();
              }
            },
          },
        },
      );

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          template: () => {
            return translate!('Date from', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateFrom,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateFrom: e.value });
            },
            ...dateFromProps,
          },
        },
        {
          location: 'after',
          template: () => {
            return translate!('Date to', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateTo,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateTo: e.value });
            },
            ...dateToProps,
          },
        },
      );

      e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'totalGroupCount',
      });
    }
  };

  const columns = Columns(handleEdit, history, translate);

  return (
    <DatagridNoResponsive
      datagridWidth={getWidthByColumnsByStorageKey(
        STORAGE_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP.toString(),
        columns,
      )}
    >
      <SisDataGrid
        enableXlsxExport
        exportName={'worksheet-data'}
        onRowPrepared={paintRowByWorkSheetColor}
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        columns={Columns(handleEdit, history, translate)}
        onToolbarPreparing={onToolbarPreparing}
        stateStoringKey={STORAGE_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP.toString()}
        onCellPrepared={paintCellByWorkSheetColor}
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeChangeVisible={true}
        wordWrapEnabled
      >
        <Template
          name="totalGroupCount"
          render={() => (
            <div>
              <span>{translate!('My Worksheets', transFormName)}</span>
              <Switch
                style={{ marginLeft: '5px' }}
                onValueChange={(e) => {
                  onFilterChanged &&
                    onFilterChanged({
                      ...filterData,
                      ownFlag: e,
                    });
                }}
                defaultValue={filterData?.ownFlag}
              />
            </div>
          )}
        />
      </SisDataGrid>
    </DatagridNoResponsive>
  );
};
