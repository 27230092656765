import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { ParametersForm } from '../../forms/parameters/ParametersForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { ParameterInputs } from '../../types/parameter.types';
import { Unit } from '../../types/unit.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function ParametersNew() {
  const { translate } = useTranslation();
  const transFormName = 'Parameter.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New parameter',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Parameters', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Načteme číselník - Unit
  const { data: dataUnits, error: errorUnits } = useSWR<Unit[]>(API_URL.UNIT_LIST, fetcher);

  const formData: ParameterInputs = {
    id: 0,
    name: '',
    description: '',
    measurementAllowed: true,
    visible: true,
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    let isOk = false;
    startLoading();
    await postApi<ParameterInputs>({
      url: API_URL.PARAMETER_POST,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!dataUnits}
      error={errorUnits}
      heading={translate!('New parameter', transFormName)}
    >
      <ParametersForm onSubmit={onSubmit} dataUnits={dataUnits} formData={formData} />
    </PageContentWrapper>
  );
}

export default ParametersNew;
