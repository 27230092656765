import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi, putApi } from '../../apiUtils';
import { WorkActivityDataGrid } from '../../components/datagrid/WorkActivitiesDatagrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { WorkActivityInputsNew } from '../../types/workActivity.types';
import { WorkActivityGroup } from '../../types/workActivityGroup.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';

export interface WorkActivityFilterData {
  dateFrom: string | null;
  dateTo: string | null;
}

const defaultFilterData: WorkActivityFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
};

/**
 * Přehled entity Work Activity.
 */
export default function WorkActivity() {
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();

  const { translate } = useTranslation();
  const transFormName = 'WorkActivity.List';
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<WorkActivityInputsNew | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGrid = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);
  // Nastavení filtru (mimo DataGrid) - ukládáme/načítáme do/z localStorage
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.TASKS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const {
    data: workActivities,
    error: workActivitiesError,
    mutate: mutateWorkActivities,
  } = useSWR<WorkActivityGroup[]>(
    `${API_URL.WORK_ACTIVITY_LIST_BY_ASSET_CLASS}?assetClassId=${assetClass}&visibleOnly=false`,
    fetcher,
  );

  const { data: workActivityGroups, error: workActivityGroupsError } = useSWR<WorkActivityGroup[]>(
    `${API_URL.WORK_ACTIVITY_GROUP_LIST_BY_ASSET_CLASS}?assetClassId=${assetClass}`,
    fetcher,
  );

  useEffect(() => {
    setValue({ pageName: 'Work activity', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Work activity', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.WORK_ACTIVITY_POST,
          data: { ..._data },
        });
        break;
      case DataGridActionType.Update:
        const oldData = workActivities?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.WORK_ACTIVITY_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      // case DataGridActionType.Remove:
      //   await deleteApi({
      //     url: API_URL.WORK_ACTIVITY_DELETE,
      //     id: key,
      //   });
      //   break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateWorkActivities();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  // const handleRemove = (e: any) => {
  //   const keyId = e?.data?.id;
  //   keyId && handleApiCall({ type: DataGridActionType.Remove, key: keyId, data: null });
  // };

  const onSubmit = (formData: WorkActivityInputsNew, type?: DataGridActionType) => {
    handleApiCall({ type, key: formData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateWorkActivities();
  };

  return (
    <PageContentWrapper
      error={workActivitiesError || workActivityGroupsError}
      loading={!workActivities || !workActivityGroups}
    >
      <WorkActivityDataGrid
        dataGridRef={dataGrid}
        data={workActivities}
        mutateData={mutateWorkActivities}
        onToolbarPreparing={onToolbarPreparing}
        defaultFilterData={filterData}
        onFilterChanged={handleFilterChanged}
        handleEdit={handleEdit}
        storageKey={STORAGE_KEYS.WORK_ACTIVITIES_DATAGRID_DESKTOP.toString()}
        isDateFilter
        modalVisibility={isModalVisible}
        handleModalVisibility={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
        workActivityGroups={workActivityGroups}
      />
    </PageContentWrapper>
  );
}
