import { CheckBox } from 'devextreme-react';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { TaskStep, TaskStepStatus } from '../../../../../types/step.types';

interface Props {
  step: TaskStep;
  isCompleteAllowed: boolean;
  isCancelAllowed: boolean;
  isReopenAllowed: boolean;
  isReadonly: boolean;
  onCheck: (stepId: number) => void;
  onUncheck: (stepId: number) => void;
  onCancel: (stepId: number) => void;
}

enum TouchState {
  NONE,
  HOLDING,
  MOVING,
}

export function StepRow({
  step,
  onCheck,
  onUncheck,
  onCancel,
  isCompleteAllowed,
  isCancelAllowed,
  isReopenAllowed,
  isReadonly,
}: Props) {
  const [rowClicks, setRowClicks] = useState<number>(0);
  const [touchTimer, setTouchTimer] = useState<Moment | null>(null);
  const [isDetailVisible, setDetailVisible] = useState<boolean>(false);
  const [isHighlight, setHiglight] = useState<boolean>(false);

  const [touchState, setTouchState] = useState<TouchState>(TouchState.NONE);

  /**
   * Vrací hodnotu checkboxu (true/false) podle stavu stepu.
   * @param status
   * @returns
   */
  const getCheckBoxValue = (status: TaskStepStatus): boolean => {
    switch (status) {
      case TaskStepStatus.OPEN:
        return false;
      case TaskStepStatus.COMPLETE:
        return true;
      default:
        return false;
    }
  };

  /**
   * Vrací barvu řádku podle stavu.
   * @param status
   * @returns
   */
  const getRowColor = (status: TaskStepStatus): string => {
    switch (status) {
      case TaskStepStatus.OPEN:
        return '#f8f8f8';
      case TaskStepStatus.COMPLETE:
        return '#ccfccc';
      default:
        return '#f8f8f8';
    }
  };

  /**
   * Provedení check/uncheck - podle stavu
   * @param item
   */
  const handleCheck = (item: TaskStep) => {
    if (item.status === TaskStepStatus.OPEN && isCompleteAllowed) onCheck(item.id);
    if (item.status === TaskStepStatus.COMPLETE && isReopenAllowed) onUncheck(item.id);
  };

  /**
   * Handle kliknutí na řádek. Zvětšujeme počet kliků - kvůli potencionálnmímu double clicku.
   * @param e
   */
  const handleRowClick = (e) => {
    if (isReadonly) return;

    setRowClicks(rowClicks + 1);
    setHiglight(false);
  };

  /**
   * Řeší doubleclick
   */
  useEffect(() => {
    if (rowClicks < 2) return;
    handleCheck(step);
    setRowClicks(0);
  }, [rowClicks]);
  useEffect(() => {
    setTimeout(() => {
      setRowClicks(0);
    }, 200);
  }, [rowClicks]);

  const onTouchStart = () => {
    // Nastavíme časovou značku stisknutí
    setTouchTimer(moment());
    // Změní touch stav
    setTouchState(TouchState.HOLDING);
  };

  const onTouchMoveCapture = () => {
    // Došlo k pohybu, promázneme časovou značku stihnusní (TOUCH&HOLD nechceme aby fungoval)
    setTouchTimer(null);
    // Změní touch stav
    setTouchState(TouchState.MOVING);
  };

  const onTouchEnd = () => {
    // Pokud máme uloženou časovou touch značku, provedeme kontrolu, jak dlouho a vyhodnotíme
    if (touchTimer && touchTimer?.add(500, 'milliseconds').isBefore(moment())) {
      setDetailVisible(!isDetailVisible);
      setTouchTimer(null);
    }

    setTouchState(TouchState.NONE);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isReadonly && step.status !== TaskStepStatus.CANCELED && isCancelAllowed)
        onCancel(step.id);
    },
    onSwipedRight: () => {
      if (!isReadonly && step.status === TaskStepStatus.CANCELED && isReopenAllowed)
        onUncheck(step.id);
    },
  });

  return (
    <tr
      key={step.id}
      style={{
        backgroundColor: isHighlight ? '#fac7be' : getRowColor(step.status),
        userSelect: 'none',
        color: step.status === TaskStepStatus.CANCELED ? '#d9d9d9' : '#333',
      }}
      onClick={(e) => handleRowClick(e)}
      onTouchMoveCapture={onTouchMoveCapture}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      {...handlers}
    >
      {!isReadonly && (
        <td
          style={{
            width: '10%',
            padding: '3px',
            border: 'solid 1px white',
            borderRight: '0px',
            borderLeft: 'solid white 4px',
            borderTop: 'solid white 4px',
            borderBottom: 'solid white 4px',
          }}
        >
          <CheckBox
            iconSize={35}
            value={getCheckBoxValue(step.status)}
            onOptionChanged={() => handleCheck(step)}
            disabled={
              step.status === TaskStepStatus.CANCELED ||
              (step.status === TaskStepStatus.COMPLETE && !isReopenAllowed) ||
              (step.status === TaskStepStatus.OPEN && !isCompleteAllowed)
            }
          />
        </td>
      )}
      <td
        style={{
          width: '80%',
          padding: '10px 7px',
          border: 'solid 1px white',
          borderLeft: '0px',
          borderRight: 'solid white 4px',
          borderTop: 'solid white 4px',
          borderBottom: 'solid white 4px',
        }}
      >
        <div style={{ fontWeight: 'bold' }}>{step.name}</div>
        {isDetailVisible && <div>{step.description}</div>}
      </td>
    </tr>
  );
}
