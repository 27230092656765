import moment from 'moment';

export enum FilterToolBarStates {
  NONE,
  NOT_TAKEN_OVER,
  ONLY_MINE,
}

export interface TerminalFilter {
  productionLines: number[] | null;
  statuses: number[] | null;
  types: number[] | null;
  capacityCategories: number[] | null;
  description: string;
  from: Date | string;
  to: Date | string;
  filterToolBarState: FilterToolBarStates;
  isFilterActive: boolean;
  isFilterOpened: boolean;
  isUser: boolean;
}

export interface MobileViewFilter {
  dateFrom: string | null;
  dateTo: string | null;
}

export const DefaultTerminalFilter = (): TerminalFilter => {
  const dateFrom = new Date();
  dateFrom.setDate(dateFrom.getDate() - 14);
  const dateTo = new Date();
  dateTo.setDate(dateTo.getDate());
  return {
    productionLines: null,
    statuses: null,
    types: null,
    capacityCategories: null,
    description: '',
    from: dateFrom.getFullYear() + '-' + (dateFrom.getMonth() + 1) + '-' + dateFrom.getDate(),
    to: dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) + '-' + dateTo.getDate(),
    filterToolBarState: FilterToolBarStates.NONE,
    isFilterActive: false,
    isFilterOpened: false,
    isUser: false,
  };
};

export const DefaultMobileViewFilter = (): MobileViewFilter => {
  return {
    dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
    dateTo: `${moment().format('YYYY-MM-DD')}`,
  };
};
