import React from 'react';
import { useParams } from 'react-router-dom';
import { MobileMenu } from '../../components/MobileMenu/MobileMenu';
import { MenuPageParams } from '../../components/MobileMenu/mobileMenu.types';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import MobileLayout from '../Tasks/Mobile/components/MobileLayout';

export function MenuPage() {
  const { typeId, assetId, taskId, worksheetId, tagId } = useParams<MenuPageParams>();

  return (
    <PageContentWrapper noContentBlock noDxCard noResponsivePaddings>
      <MobileLayout>
        <MobileMenu
          typeId={typeId !== undefined ? Number(typeId) : undefined}
          assetId={assetId !== undefined ? Number(assetId) : undefined}
          taskId={typeId !== undefined ? Number(taskId) : undefined}
          worksheetId={worksheetId !== undefined ? Number(worksheetId) : undefined}
          tagId={tagId !== undefined ? tagId : undefined}
        />
      </MobileLayout>
    </PageContentWrapper>
  );
}
