export const STORAGE_KEYS = {
  /* *** Desktop *** */
  // Filtr mimo DataGrid
  TASKS_FILTER_DESKTOP: 'tasks_filter_desktop',
  TASKS_DATAGRID_DESKTOP: 'tasks_datagrid_desktop',
  TASKS_FILTER_DESKTOP_MOBILE_VIEW: 'tasks_filter_desktop_mobile_view',
  TASKS_DATAGRID_DESKTOP_MOBILE_VIEW: 'tasks_datagrid_desktop_mobile_view',
  ASSET_RESPONSIBLE_PERSON_DATAGRID_DESKTOP: 'asset_responsible_person_datagrid_desktop',
  ASSET_TASKS_DATAGRID_DESKTOP: 'asset_tasks_datagrid_desktop',

  TASKS_REPORT_DATAGRID_DESKTOP: 'tasks_report_datagrid_desktop',
  TASKS_REPORT_FILTER_DESKTOP: 'tasks_report_filter_desktop',
  WORKSHEETS_REPORT_DATAGRID_DESKTOP: 'worksheets_report_datagrid_desktop',
  WORKSHEETS_REPORT_FILTER_DESKTOP: 'worksheets_report_filter_desktop',
  GROUPED_WORKSHEETS_REPORT_DATAGRID_DESKTOP: 'grouped_worksheets_report_datagrid_desktop',
  ASSETS_REPORT_DATAGRID_DESKTOP: 'assets_report_datagrid_desktop',
  ASSETS_REPORT_FILTER_DESKTOP: 'assets_report_filter_desktop',
  RESP_PERSONS_REPORT_DATAGRID_DESKTOP: 'resp_persons_report_datagrid_desktop',
  RESP_PERSONS_REPORT_FILTER_DESKTOP: 'resp_persons_report_filter_desktop',
  SPARE_PARTS_REPORT_DATAGRID_DESKTOP: 'spare_parts_report_datagrid_desktop',
  SPARE_PARTS_REPORT_FILTER_DESKTOP: 'spare_parts_report_filter_desktop',

  DEFAULT_THEME: 'default_theme',

  TASKS_WORKSHEET_DATAGRID_DESKTOP: 'tasks_worksheet_datagrid_desktop',

  WORKSHEET_LIST_DATAGRID_DESKTOP: 'worksheets_list_datagrid_desktop',
  WORKSHEETS_FILTER_DESKTOP: 'worksheets_filter_desktop',
  WORKSHEETS_FILTER_MOBILE: 'worksheets_filter_mobile',

  WORK_ACTIVITY_GROUPS_DATAGRID_DESKTOP: 'work_activity_groups_datagrid_desktop',
  WORK_ACTIVITIES_DATAGRID_DESKTOP: 'work_activities_datagrid_desktop',

  TASK_ASSISTANCE_PANELS_DATAGRID_DESKTOP: 'task_assistance_panels_datagrid_desktop',

  CONTEXT_STATE: 'context_state',

  /* *** Terminal *** */
  // Filtr mimo DataGrid
  TASKS_FILTER_TERMINAL: 'tasks_filter_terminal',

  /* *** Mobile *** */
  // Filtr mimo DataGrid
  TASKS_FILTER_MOBILE: 'tasks_filter_mobile',

  ASSETS_DATAGRID_DESKTOP: 'assets_datagrid_desktop',
  TASK_LIST_DATAGRID_TERMINAL: 'tasks_list_datagrid_terminal',

  ASSETS_ROW_SELECTION: 'assets_row_selection',
  TASKS_ROW_SELECTION: 'tasks_row_selection',

  TASKS_DETAIL_ACTIVE_TAB: 'mobile_tasks_active_tab',

  TERMINAL_MAPPING_ID: 'terminal_mapping_id',
};
