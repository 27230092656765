import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/TaskAssistancePanelDesktop/Columns';
import { Asset } from '../../types/asset.types';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DocumentType } from '../../types/documentType.types';
import TaskAssistancePanelModal from '../modals/TaskAssistancePanelModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  onFilterChanged?: (TaskAssistancePanelGroupsFilterData) => void;
  mutateData?: () => void;
  storageKey: string;
  modalVisibility: boolean;
  selectedData: any;
  selectedAction: any;
  onDataSubmit: (data: any, actionType: any) => void;
  handleModalVisibility: () => void;
  capacityCategories: CapacityCategory[];
  documentTypes: DocumentType[];
  assets: Asset[];
  handleRemove: (e) => void;
}

export const TaskAssistancePanelDataGrid = ({
  data,
  handleEdit,
  handleRemove,
  onToolbarPreparing,
  modalVisibility,
  selectedData,
  selectedAction,
  onDataSubmit,
  handleModalVisibility,
  capacityCategories,
  documentTypes,
  assets,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'TaskAssistancePanel.List';
  const columns = Columns(handleEdit, handleRemove, translate);
  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={columns}
        onToolbarPreparing={onToolbarPreparing}
        exportName="task-assistance-panel"
        enableXlsxExport
        columnChooser
        allowColumnResizing
        allowColumnReordering
        allowHeaderFiltering
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
      />
      {modalVisibility && (
        <TaskAssistancePanelModal
          isVisible={modalVisibility}
          data={selectedData ?? null}
          actionType={selectedAction}
          onSubmit={onDataSubmit}
          onClose={handleModalVisibility}
          capacityCategories={capacityCategories}
          documentTypes={documentTypes}
          assets={assets}
        />
      )}
    </>
  );
};
