import React, { createContext, useCallback, useContext, useState } from 'react';
import { Step } from '../components/Breadcrumbs/BreadcrumbsWithHistory';

interface IBreadcrumbsContext {
  value: BreadcrumbValueType | null;
  setValue: (value: BreadcrumbValueType | null) => void;
}
const BreadcrumbsContext = createContext<IBreadcrumbsContext>({
  value: null,
  setValue: () => {},
});

export type BreadcrumbValueType = {
  pageName: string;
  steps: Array<Step>;
};

export const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<BreadcrumbValueType | null>(null);

  const setValue = useCallback((value: BreadcrumbValueType | null) => setState(value), []);

  return (
    <BreadcrumbsContext.Provider
      value={{
        value: state,
        setValue,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  return useContext<IBreadcrumbsContext>(BreadcrumbsContext);
};
