import { HtmlEditor } from 'devextreme-react';
import Popup, { Position } from 'devextreme-react/popup';
import React, { useEffect, useState } from 'react';
import { getItemApi } from '../../apiUtils';
import { useTranslation } from '../../contexts/translation';
import { ConfigType } from '../../types/config.types';
import { API_URL } from '../../utils/apiUrl';

type Props = {
  visibility: boolean;
  onClose: () => void;
};

export default function HelpPopup({ visibility, onClose }: Props) {
  const [content, setContent] = useState<string>('');
  const { translate } = useTranslation();
  const transFormName = 'Terminal.Common';

  /**
   * Načtení nápovědy v konfiguraci z databáze a nastavení pro zobraztení v popupu
   */
  useEffect(() => {
    const getHelp = async () => {
      await getItemApi<ConfigType>({
        url: API_URL.GET_CONFIG_HELP,
        params: {},
        callAfterSuccess: async (result) => {
          setContent(result.value);
        },
      });
    };
    getHelp();
  }, []);

  return (
    <Popup
      visible={visibility}
      onHiding={onClose}
      closeOnOutsideClick
      showCloseButton
      showTitle
      dragEnabled={false}
      title={translate!('Help', transFormName)}
      width={'95%'}
      height={'95%'}
    >
      <Position at={'center'} my="center" />
      <HtmlEditor
        value={content}
        valueType="html"
        style={{ borderWidth: 0 }}
        readOnly
        height={'100%'}
      />
    </Popup>
  );
}
