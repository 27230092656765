import Box, { Item } from 'devextreme-react/box';
import Form, { ButtonItem, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import { useVersion } from '../../contexts/versionContext';
import { useScreenSize } from '../../hooks/mediaQuery';
import { IForm } from '../../types/iForm';
import './LoginForm.scss';

export interface Props extends IForm {
  dataAssetClasses?: any;
  onClickTechnicalUser?: () => void;
  disableAssetClassInput?: boolean;
  allowUIdLogin?: boolean;
  showBackButton?: boolean;
  showCustomerLogo?: boolean;
}

export const LoginForm = ({
  onSubmit,
  formData,
  dataAssetClasses,
  disableAssetClassInput,
  allowUIdLogin,
  showBackButton,
  showCustomerLogo,
}: Props) => {
  const history = useHistory();
  const { translate } = useTranslation();
  const { currentVersion } = useVersion();
  const transFormName = 'LoginPage';
  const { isXSmall } = useScreenSize();

  const formRef = useRef(null);

  useEffect(() => {
    if (!formRef.current || !allowUIdLogin) return;
    // @ts-ignore
    formRef.current.instance.getEditor('uid').focus();
  }, [formRef]);

  return (
    <div className={'dx-card login-form'}>
      <div className={'title'} style={{ borderBottom: '1px solid #bfbfbf', marginBottom: '50px' }}>
        <Box>
          <Item ratio={0.7}>
            <div style={{ position: 'relative', height: isXSmall ? '65px' : '70px' }}>
              <span
                style={{ color: '#bfbfbf', fontSize: '25px', position: 'absolute', bottom: '0' }}
              >
                {translate!('e-service', transFormName)}
              </span>
            </div>
          </Item>
          <Item ratio={0.3}>
            {isXSmall ? (
              <div style={{ textAlign: 'right' }}>
                <img
                  src={process.env.PUBLIC_URL + '/img/e-Service.svg'}
                  title="Logo e-service"
                  alt="Logo e-service"
                  style={{ width: '75%' }}
                />
              </div>
            ) : (
              <img
                src={process.env.PUBLIC_URL + '/img/e-Service.svg'}
                title="Logo e-service"
                alt="Logo e-service"
              />
            )}
          </Item>
        </Box>
      </div>
      <form onSubmit={onSubmit}>
        <Form colCount={1} formData={formData} showValidationSummary={false} ref={formRef}>
          <GroupItem>
            <SimpleItem dataField="username" editorType="dxTextBox">
              <Label text={translate!('Username', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="password"
              editorType="dxTextBox"
              editorOptions={{ mode: 'password' }}
            >
              <Label text={translate!('Password', transFormName)} />
            </SimpleItem>
          </GroupItem>
          {allowUIdLogin && (
            <GroupItem>
              <SimpleItem dataField="uid" editorType="dxTextBox">
                <Label text={translate!('UID', transFormName)} />
              </SimpleItem>
            </GroupItem>
          )}
          {!disableAssetClassInput ? (
            <GroupItem>
              <SimpleItem
                dataField="assetClassId"
                editorType="dxSelectBox"
                editorOptions={{
                  items: dataAssetClasses,
                  valueExpr: 'id',
                  displayExpr: 'nameTranslated',
                }}
              >
                <Label text={translate!('Asset class', transFormName)} />
                <RequiredRule message={translate!('Select asset class', transFormName)} />
              </SimpleItem>
            </GroupItem>
          ) : (
            <></>
          )}
          <ButtonItem
            horizontalAlignment="center"
            buttonOptions={{
              text: translate!('Sign in', transFormName),
              type: 'success',
              useSubmitBehavior: true,
              width: '100%',
            }}
          />
          {showBackButton && (
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={{
                text: translate!('Back', transFormName),
                type: 'normal',
                width: '100%',
                onClick: () => history.goBack(),
              }}
            />
          )}
        </Form>
      </form>
      <div className={'footer'} style={{ borderTop: '1px solid #bfbfbf', marginTop: '50px' }}>
        <Box>
          <Item ratio={0.5}>
            {showCustomerLogo && (
              <div style={{ display: 'inline-block' }}>
                <img
                  src={process.env.PUBLIC_URL + '/img/customer_logo.png'}
                  title="Customer logo"
                  alt="Customer logo"
                />
              </div>
            )}
          </Item>
          <Item ratio={0.1} />
          <Item ratio={0.4}>
            <img
              src={process.env.PUBLIC_URL + '/img/logo-apsys.png'}
              title="Logo APSYS"
              alt="Logo APSYS"
              style={{ width: '100%' }}
            />
          </Item>
        </Box>
      </div>
      <div style={{ color: '#aaa', textAlign: 'right' }}>{currentVersion}</div>
    </div>
  );
};
