import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { deleteApi, postApi, putApi } from '../../apiUtils';
import { StepGroupStepDataGrid } from '../../components/datagrid/StepGroupStepDatagrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { StepGroupForm } from '../../forms/stepGroup/StepGroupForm';
import { confirmDialogActions, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { Step } from '../../types/step.types';
import { StepGroupInputs, StepGroupStep } from '../../types/stepGroup.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function StepGroupsEdit() {
  const { translate } = useTranslation();
  const transFormName = 'StepGroup.Form';
  const { id } = useParams<DetailPageParams>();
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();
  const { assetClass } = useAssetClass();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setValue({
      pageName: 'Edit step group',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Step groups', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<StepGroupInputs>(
    `${API_URL.STEP_GROUP_ITEM}?id=${id}`,
    fetcher,
  );

  const {
    data: stepGroupSteps,
    error: errorStepGroupSteps,
    mutate: mutateStepGroupStep,
  } = useSWR<StepGroupStep[]>(`${API_URL.STEP_GROUP_STEP_LIST}?parentId=${id}`, fetcher);

  const { data: stepList, error: errorStepList } = useSWR<Step[]>(`${API_URL.STEP_LIST}`, fetcher);

  /**
   * Načte seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();
    let isOk = false;
    await putApi({
      url: API_URL.STEP_GROUP_PUT,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  const onSubmitStepGroupStep = async (e) => {
    startLoading();
    await postApi({
      url: API_URL.STEP_GROUP_STEP_POST,
      data: { stepId: e.stepId, stepGroupId: id },
      callAfterSuccess: () => {
        notify(translate!('Step assigned', transFormName), 'success');
        mutateStepGroupStep();
        setIsVisible(false);
      },
    });
    setIsVisible(false);
    stopLoading();
  };

  const { open } = useConfirmDialog({
    title: translate!('Delete', ''),
    bodyHtml: translate!('Are you sure?', ''),
    cancelButtonTitle: translate!('Cancel', ''),
    confirmButtonTitle: translate!('Confirm', ''),
  });

  const handleRemove = async (e: any) => {
    open().then(async (c) => {
      if (c === confirmDialogActions.CONFIRM) {
        await deleteApi({
          url: `${API_URL.STEP_GROUP_STEP_DELETE}?id=${e.data.id}`,
          id: Number(id),
          callAfterSuccess: () => {
            mutateStepGroupStep();
            notify(translate!('Step unassigned', transFormName), 'success');
          },
        });
      } else {
        return false;
      }
    });
  };

  return (
    <PageContentWrapper
      error={error || capacityCategoriesError}
      loading={!formData}
      heading={translate!('Edit step group', transFormName)}
    >
      <StepGroupForm
        onSubmit={onSubmit}
        formData={formData}
        capacityCategories={capacityCategories}
        isEdit
      />
      <StepGroupStepDataGrid
        data={stepGroupSteps}
        stepList={stepList}
        handleRemove={handleRemove}
        onSubmit={onSubmitStepGroupStep}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </PageContentWrapper>
  );
}

export default StepGroupsEdit;
