import ContextMenu from 'devextreme-react/context-menu';
import TreeView from 'devextreme-react/tree-view';
import * as events from 'devextreme/events';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import '../../../src/custom.scss';
import { useAuth } from '../../contexts/auth';
import { useNavigation } from '../../contexts/navigation';
import { useRouting } from '../../contexts/routingContext';
import { useTranslation } from '../../contexts/translation';
import { useScreenSize } from '../../hooks/mediaQuery';
import { Loader } from '../loader/Loader';
export function SideNavigationMenu(props) {
  // eslint-disable-next-line react/prop-types
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
    treeViewItemContextMenu,
    contextMenuItemClick,
    contextMenuItems,
  } = props;
  const { user } = useAuth();
  const { translate } = useTranslation();
  const { routes } = useRouting();
  const transFormName = 'Menu';

  const canAccessRoutes = routes.filter((item) => {
    const hasRole = user?.roles.some((role) => item.restrictedAccess?.includes(role));
    const isOk = !item.restrictedAccess || hasRole;
    const _item = { ...item };
    let isSubItems = item.items && item.items.length > 0;
    if (isOk && item.items && item.items.length > 0) {
      _item.items = [
        ...item.items.filter((subItem) => {
          const hasRoleSub = user?.roles.some((role) => subItem.restrictedAccess?.includes(role));
          return !subItem.restrictedAccess || hasRoleSub;
        }),
      ];
      item.items = [..._item.items];
    }
    return isOk && (!isSubItems || _item.items!.length > 0);
  });

  const translatedRoutes = canAccessRoutes.map((item) => {
    const _item = { ...item, text: translate!(item.text, transFormName) };
    if (_item.items?.length === 0) return _item;
    _item.items = _item.items?.map((subItem) => {
      return { ...subItem, text: translate!(subItem.text, transFormName) };
    });

    return _item;
  });

  const { isLarge } = useScreenSize();

  function normalizePath() {
    return translatedRoutes.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        if (item.path.includes('http')) {
          item.path = `${item.path}`;
        } else {
          item.path = `/${item.path}`;
        }
        item.isExpanded = false;
      }

      // každému odkazu v menu odebrat na konci "/"
      if (item.path) {
        item.path =
          item.path.charAt(item.path.length - 1) === '/'
            ? item.path.substring(0, item.path.length - 1)
            : item.path;
      } else {
        // vnořený link - odebrat na konci "/"
        item.items &&
          item.items.length > 0 &&
          item.items.map((i: any, index: number) => {
            if (i.path.charAt(i.path.length - 1) === '/') {
              item.items![index].path = i.path.substring(0, i.path.length - 1);
            }
          });
      }

      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(normalizePath, [translate]);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, 'dxclick');
      }

      wrapperRef.current = element;
      events.on(element, 'dxclick', (e) => {
        openMenu(e);
      });
    },
    [openMenu],
  );

  useEffect(() => {
    // @ts-ignore
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      // @ts-ignore
      treeView.selectItem(currentPath);
      // @ts-ignore
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      // @ts-ignore
      treeView.collapseAll();
    }
  }, [currentPath, compactMode, translate]);

  useEffect(() => {
    if (!items) return;
    addTargetForContextMenu();
  }, [items]);

  const addTargetForContextMenu = () => {
    // zavolá se při načtení menu a při každém expandu v treeview componentě
    // select položky menu, která není rozcestník -> rozcestník má 2 childy (hidden element)
    document.querySelectorAll('.dx-treeview-item-content').forEach((item) => {
      if (
        item &&
        item.parentNode &&
        item.parentNode?.parentNode &&
        item.parentNode?.parentNode?.children.length > 1
      )
        return;
      // přidám class, kterou použiju v target context menu
      item.parentElement?.classList.add('menu-link');
    });
  };

  if (!items) return <Loader />;
  return (
    <div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <TreeView
          // @ts-ignore
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemExpanded={() => addTargetForContextMenu()}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
          expandedExpr="isExpanded"
          onItemContextMenu={(e) => treeViewItemContextMenu(e)}
        />
        {/* Context menu pro odkazy */}
        <ContextMenu
          dataSource={contextMenuItems}
          target={'.menu-link'}
          // target={'.dx-treeview-item-content'}
          onItemClick={contextMenuItemClick}
        />
      </div>
    </div>
  );
}
