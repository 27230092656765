import moment from 'moment';
import { equals, not } from 'ramda';
import { equalsZero, isNilOrEmpty } from 'ramda-extension';

export const validationNullableDateRule = ({ value }) => {
  if (isNilOrEmpty(value)) {
    return true;
  }
  const date = Date.parse(value);
  return !isNaN(date);
};

export const validationDateBeforeNow = (oldValue, value) => {
  if (!isNilOrEmpty(oldValue) && !isNilOrEmpty(value)) {
    if (equals(moment(oldValue).format('yyyy-MM-dd'), moment(value).format('yyyy-MM-dd'))) {
      return true;
    }
  }

  if (equals(moment().format('yyyy-MM-dd'), moment(value).format('yyyy-MM-dd'))) {
    return true;
  }

  if (isNilOrEmpty(value)) {
    return false;
  }
  return !moment(value).isBefore(moment());
};

export const validationDateTimeBeforeNow =
  (oldValue) =>
  ({ value }) => {
    if (!isNilOrEmpty(oldValue) && !isNilOrEmpty(value)) {
      if (
        equals(
          moment(oldValue).format('yyyy-MM-dd HH:MM:SS'),
          moment(value).format('yyyy-MM-dd HH:MM:SS'),
        )
      ) {
        return true;
      }
    }
    if (isNilOrEmpty(value)) {
      return false;
    }
    return !moment(value).isBefore(moment());
  };
export const notEqualZero = ({ value }) => not(equalsZero(value));
