import { prop } from 'ramda';
import React, { useEffect } from 'react';
import { Platform } from '../../../components/loginForm/LoginForm';
import useItemDataApi from '../../../hooks/useItemDataApi';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Task } from '../../../types/task.types';
import { API_URL } from '../../../utils/apiUrl';
import { ITab } from '../../Assets/AssetsEdit';
import { TasksComments } from '../TasksComments';

interface Props extends ITab {
  id: string;
}
export const TaskCommentsTab = ({ id, active }: Props) => {
  const [{ data, isError: error, isLoading: isValidating }, reFetch] = useItemDataApi<Task>(
    API_URL.TASK_ITEM,
    id,
  );

  useEffect(() => {
    if (active) reFetch();
  }, [id, active]);

  return (
    <PageContentWrapper noContentBlock loading={isValidating} noDxCard error={error}>
      <TasksComments
        data={prop('comments', data)}
        taskId={id}
        mutateTask={reFetch}
        poupWidth={400}
        platform={Platform.DESKTOP}
      />
    </PageContentWrapper>
  );
};
