import { Item, Toolbar } from 'devextreme-react/toolbar';
import React from 'react';
import '../../components/datagridNoResponsive/DatagridNoResponsive.scss';
import { useTranslation } from '../../contexts/translation';
import { FailedLoad } from '../failedLoad/FailedLoad';
import { Loader } from '../loader/Loader';

interface Props {
  children: any;
  error?: any;
  loading?: any;
  noLoadingModal?: boolean;
  heading?: any;
}

export const DatagridNoResponsiveContainer = ({
  children,
  error,
  loading,
  noLoadingModal,
  heading,
}: Props) => {
  const { translate } = useTranslation();

  if (error)
    return (
      <FailedLoad
        message={!error.status ? translate!('There are connection problems.', '') : null}
      />
    );
  if (loading) return <Loader visible={!noLoadingModal} />;

  return (
    <div className="datagrid-no-responsive-container">
      {heading && (
        <Toolbar>
          {' '}
          <Item location={'before'} html={heading} />
        </Toolbar>
      )}
      {children}
    </div>
  );
};
