import { Button, Popup } from 'devextreme-react';
import Form, {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import { formatDate } from 'devextreme/localization';
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useSWR from 'swr';
import { postApi, putApi } from '../../apiUtils';
import { FileFigureList } from '../../components/fileManager/FileFigureList';
import { IAfterSubmitMode } from '../../components/formSuccessButtons/FormSuccessButtons';
import { Loader } from '../../components/loader/Loader';
import { useAssetClass } from '../../contexts/assetClass';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { swrConfig } from '../../pages/Assets/AssetsEdit';
import { TaskChangeStatus } from '../../pages/Tasks/TaskChangeStatus';
import { Asset } from '../../types/asset.types';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DocumentType } from '../../types/documentType.types';
import { File } from '../../types/file.types';
import { LocationState } from '../../types/locationState.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { Person } from '../../types/person.types';
import { Task } from '../../types/task.types';
import { TaskName, TaskNameListItem } from '../../types/taskNames.types';
import { TaskRatings } from '../../types/taskRating.types';
import { TaskStatusApiResponse } from '../../types/TaskStatus';
import { WorkSheet } from '../../types/workSheet.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import {
  dateFieldOptionsWithSerialization,
  dateTimeFieldOptionsWithSerialization,
  displayDateTimeFormat_CZ,
  displayDateTimeFormat_EN,
} from '../../utils/dates.types';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import './TasksForm.scss';
export interface TaskInputsNew {
  id?: number;
  assetTopLevelParenId?: number;
  assetId?: number;
  assetTypeId?: number;
  typeId?: number;
  typeName?: string;
  typeNameTranslated?: string;
  capacityCategoryId?: number;
  taskName?: string;
  requiredDate?: Date | null | string;
  downtimeFrom?: Date | null | string;
  downtimeTo?: Date | null | string;
  description?: string;
  diagnose?: string;
  statusName?: string;
  statusNameTranslated?: string;
  responsiblePersonId?: number;
  assetCycleRunId?: number | null;
}

export const dxSelectBoxConfig = {
  searchEnabled: true,
  searchModeOption: 'contains',
  searchExprOption: 'name',
  searchTimeoutOption: 200,
  showClearButton: true,
};

interface Props {
  defaultFormData: TaskInputsNew;
  isEdit?: boolean;
  defaultIsTaskNameCombo?: boolean;
  defaultShowDowntime?: boolean;
  onSubmitDone?: () => void;
  isReadOnly: boolean;
  oldRequiredDate?: Date | null | string;
  transFormName: string;
  mutateTask?: any;
  isCopy?: boolean;
  worksheets?: WorkSheet[];
}

interface SectionTitles {
  titleLineAsset: ReactElement;
  titleCategory: ReactElement;
  titleTask: ReactElement;
  titleDowntime: ReactElement;
}

export default function TaskForm({
  onSubmitDone,
  defaultFormData,
  isEdit,
  defaultIsTaskNameCombo,
  defaultShowDowntime,
  isReadOnly,
  oldRequiredDate,
  transFormName,
  mutateTask,
  isCopy,
  worksheets,
}: Props) {
  const { translate, lang } = useTranslation();
  const { assetClass, changeAssetClass, refetchAssetClass } = useAssetClass();
  const { startLoading, loading, stopLoading } = useLoading();
  const { state }: { state: LocationState | undefined } = useLocation();
  const history = useHistory();
  const { id } = useParams<DetailPageParams>();
  const [formData, setFormData] = useState<TaskInputsNew>(defaultFormData);
  const [isTaskNameCombo, setTaskNameCombo] = useState<boolean>(defaultIsTaskNameCombo!);
  const [isShowDowntime, setShowDowntime] = useState<boolean>(defaultShowDowntime!);
  const [isAssetChangeDialogVisible, setAssetChangeDialogVisible] = useState<boolean>(false);
  const [afterSubmitMode, setAfterSubmitMode] = useState<IAfterSubmitMode>(IAfterSubmitMode.NEW);
  const [localTaskNames, setLocalTaskNames] = useState<TaskNameListItem[]>();
  const [sectionTitles, setSectionTitles] = useState<SectionTitles>({
    titleLineAsset: <>${translate!('Asset detail', transFormName)}</>,
    titleCategory: <>${translate!('Category detail', transFormName)}</>,
    titleTask: <>${translate!('Request detail', transFormName)}</>,
    titleDowntime: <>${translate!('Production downtime', transFormName)}</>,
  });
  const [contextState] = useLocalStorage(STORAGE_KEYS.CONTEXT_STATE, {});
  const transFormNameAssetClass = 'Task.AssetClassChange';
  const {
    data: task,
    error: errorTask,
    isValidating: taskLoading,
  } = useSWR<Task>(id ? `${API_URL.TASK_ITEM}?id=${id}` : null, fetcher, swrConfig);

  useEffect(() => {
    if (!task) return;
    setFormData(task);
    setTaskNameCombo(task.typeId === 404);
  }, [taskLoading, task]);

  useEffect(() => {
    id &&
      task &&
      task?.assetClassId &&
      task?.assetClassId !== assetClass &&
      setAssetChangeDialogVisible(true);
  }, []);

  const AssetClassChangeDialog = () => {
    const handleAssetClassChange = () => {
      // @ts-ignore
      changeAssetClass(task?.assetClassId);
      // @ts-ignore
      refetchAssetClass();
      setAssetChangeDialogVisible(false);
    };

    return (
      <Popup
        visible={isAssetChangeDialogVisible}
        dragEnabled={false}
        closeOnOutsideClick
        onHiding={() => setAssetChangeDialogVisible(false)}
        showTitle
        title={translate!('Change asset class', transFormNameAssetClass)}
        width={'25%'}
        height="auto"
      >
        <p>
          {translate!(
            'The selected task has a different asset class than the currently selected. Do you want to change asset class?',
            transFormNameAssetClass,
          )}
        </p>
        <div style={{ display: 'inline-block', marginRight: '10px' }}>
          <Button text={translate!('Yes', '')} type={'success'} onClick={handleAssetClassChange} />
        </div>
        <Button text={translate!('No', '')} onClick={() => history.goBack()} />
      </Popup>
    );
  };
  const formRef = useRef<Form>(null);
  const backRoute = state?.backRoute
    ? state.backRoute
    : (contextState && contextState.backRoute) ?? '/tasks';
  const [
    prompt,
    setDirty,
    setClean,
    handleBackButton,
    handleHistoryGoBackButton,
    handleDirtyWithCallback,
    isDirty,
  ] = useUnsavedChangesWarning(backRoute, () => {
    if (!formRef) return;
    if (formRef.current!.instance.validate().isValid)
      onSubmit(null).then((result) => {
        if (result)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        else setDirty();
      });
  });

  /**
   * Načte se seznam topLevelAssetů - linky
   */
  const { data: topLevelAssets, error: topLevelAssetsError } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS_TOP_LEVEL}?classId=${assetClass}&activeOnly=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte seznam assetů, pokud je vybrána linka (assetTopLevelParenId), filtrují se assety podle této linky
   */
  const {
    data: assets,
    error: assetsError,
    isValidating: isValidatingAssets,
  } = useSWR<Asset[]>(
    formData.assetTopLevelParenId
      ? `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}&parentId=${formData.assetTopLevelParenId}&assetToInclude=${formData.assetTopLevelParenId}`
      : `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  useEffect(() => {
    if (assets) {
      const asset = assets.find((item) => item.id === task?.assetId);
      if (asset) {
        setFormData({ ...task, assetTypeId: asset?.typeId });
      }
    }
  }, [assets]);

  // přednastaví Linku a Asset, pokud se task vytváří z assetu
  useEffect(() => {
    if (!assets || !state || !state.assetId) return;
    let asset = assets.find((asset: Asset) => asset.id === Number(state.assetId));
    if (asset)
      setFormData({
        ...task,
        assetTopLevelParenId: asset.topLevelParenId,
        assetId: Number(asset.id),
      });
  }, [assets, state]);

  /**
   * Načte seznam documentTypes.
   */
  const { data: documentTypes, error: documentTypesError } = useSWR<DocumentType[]>(
    `${API_URL.GET_LIST_FOR_TASK_CREATE}?parentId=${assetClass}&taskCreateDesktop=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte seznam taskNames - pro zadání přes combo. TaskNames se načtou pouze pokud máme vybraný documentType,
   * Asset a zároveň pokud se má taskName jako combo zobrazovat. TaskNames se načítají podle assetTypeId.
   */
  const {
    data: taskNames,
    error: taskNamesError,
    isValidating: isValidatingTaskNames,
  } = useSWR<TaskName[]>(
    isTaskNameCombo && formData.typeId && formData.assetTypeId
      ? `${API_URL.TASK_NAME_FILTERED}?documentTypeId=${formData.typeId}&assetTypeId=${
          formData.assetTypeId
        }${
          formData.capacityCategoryId ? `&capacityCategoryId=${formData.capacityCategoryId}` : ''
        }&visibleOnly=true`
      : null,
    fetcher,
    swrConfig,
  );

  /**
   * Načte zodpovědné osoby pro task action. Pouze v editaci.
   */
  const { data: taskActionRespPersons } = useSWR<Person[]>(
    isEdit && formData.id && formData.capacityCategoryId
      ? `${API_URL.CAPACITY_CATEGORY_PERSON_LIST}?parentId=${formData.capacityCategoryId}`
      : null,
    fetcher,
    swrConfig,
  );

  // Načteme číselník - Persons
  const { data: responsiblePersons, error: errorResponsiblePersons } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  /**
   * Načteme stavy pro akční tlačítka. Pouze v editaci.
   */
  const {
    data: statuses,
    mutate: mutateStatuses,
    isValidating: isStatusesValidating,
  } = useSWR<TaskStatusApiResponse>(
    isEdit && formData.id ? `${API_URL.TASK_PROCESS_CONFIG}?taskId=${formData.id}` : null,
    fetcher,
    swrConfig,
  );

  // Načtení rating ikon
  const { data: taskRatings } = useSWR<TaskRatings>(
    statuses && statuses?.find((s: any) => s?.requireRating === true)
      ? `${API_URL.TASK_RATING_LIST}?visibleOnly=true`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (result) => {
        let sortedArr = result.sort((a: any, b: any) =>
          a.rating > b.rating ? 1 : b.rating > a.rating ? -1 : 0,
        );
        return sortedArr;
      },
    },
  );

  // Načteme subory jen když je edit
  const { data: files } = useSWR<File[]>(
    isEdit && formData.id ? `${API_URL.FILE_LIST}?entity=${'Task'}&entityId=${formData.id}` : null,
    fetcher,
  );

  const handleStatusChanged = () => {
    if (mutateTask) mutateTask();
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;

    setClean();
    if (isEdit) isOk = await submitPut();
    else isOk = await submitPost();
    if (onSubmitDone) {
      onSubmitDone();
      mutateStatuses();
    }

    return isOk;
  };

  const submitPost = async () => {
    let isOk = false;
    startLoading();
    await postApi({
      url: API_URL.TASK_POST,
      data: formData,
      callAfterSuccess: (data) => {
        isOk = true;
        if (afterSubmitMode === IAfterSubmitMode.NEW) {
          setFormData(defaultFormData);
          return;
        }
        if (afterSubmitMode === IAfterSubmitMode.LIST)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        if (afterSubmitMode === IAfterSubmitMode.CONTINUE) history.push(`/tasks/edit/${data.id}`);
      },
    });
    stopLoading();

    return isOk;
  };

  const submitPut = async () => {
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.TASK_PUT,
      data: formData,
      callAfterSuccess: async () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  /**
   * Změna linky (TopLevelAssetId). Je potřeba profiltrovat seznam assetů podle vybrané linky.
   * Nastavením formData.assetTopLevelParenId se provede přenačtení číselníku assets.
   * @param data: Data z dropDownListu. (TopLevelParentId)
   */
  const handleTopLevelAssetChanged = (data) => {
    setFormData({ ...formData, assetTopLevelParenId: data.value });
  };

  /**
   * Změna assetu. Při výběru assetu se nastaví příslučná linka. Linka se pozná podle
   * atributu assetTopLevelParenId, který má asset. Dále podle assetu nastavíe assetTypeId,
   * který je potřeba pro načítání taskNames do comba.
   * @param data: Data z dropDownListu (AssetId)
   */
  const handleAssetChanged = (data) => {
    if (!assets) return;
    const asset = assets.find((x) => x.id === data.value);
    if (!asset) {
      setFormData({ ...formData, assetId: undefined });
      return;
    }
    // Asset má atribut assetTopLevelParenId, podle kterého vybereme linku v dropdownlistu
    const assetTopLevelParenId = asset.topLevelParenId;
    // AssetTypeId pro načtení TaskNames do comba
    const assetTypeId = asset.typeId;
    setFormData({ ...formData, assetId: data.value, assetTopLevelParenId, assetTypeId });
  };

  /**
   * Pokud se jedná o editační formulář, potřebujeme již při načtení formuláře nastavit assetTypeI
   */
  useEffect(() => {
    if (!isEdit || !assets) return;
    const asset = assets.find((x) => x.id === formData.assetId);
    if (!asset) return;
    setFormData({ ...formData, assetTypeId: asset.typeId });
  }, [assets]);

  const handleTasknameListChanges = () => {
    if (!taskNames || !formData) return;

    if (!formData.taskName || taskNames.find((x) => x.name === formData.taskName!)) {
      setLocalTaskNames([...taskNames]);
      return;
    }

    if (formData.taskName === '') return;

    const newTaskName: TaskNameListItem = {
      name: formData.taskName!,
    };

    setLocalTaskNames([...taskNames, newTaskName]);
  };

  /**
   * Vytvoříme si lokální taskNames, protože do nich potřebujeme přidat taskName, který je na tasku, jelikož nemusí být v DB
   */
  useEffect(() => {
    handleTasknameListChanges();
  }, [taskNames]);

  /**
   * Při úprave formulářových dat upravíme titulky sekcí.
   */
  const handleChangeTitles = () => {
    const topLevelAsset = topLevelAssets?.find((x) => x.id === formData.assetTopLevelParenId);
    const asset = assets?.find((x) => x.id === formData.assetId);
    const documentType = documentTypes?.find((x) => x.id === formData.typeId);
    const capacityCategory = capacityCategories?.find((x) => x.id === formData.capacityCategoryId);

    const redStar = <span style={{ color: '#f51c36', fontWeight: 600 }}>*</span>;

    // Titulek linka, asset
    let titleLineAsset = (
      <>
        {redStar}
        {`${translate!('Asset', transFormName)}`}
      </>
    );
    if (topLevelAsset || asset) {
      const label1 = topLevelAsset ? topLevelAsset.name : '';
      const label2 = asset ? (
        asset.name
      ) : (
        <>
          {redStar}
          {`${translate!('Asset', transFormName)}`}
        </>
      );
      titleLineAsset = (
        <>
          {label1}
          {label1 ? ', ' : ''}
          {label2}
        </>
      );
    }

    // Kategorie
    let titleCategory = (
      <>
        {redStar}
        {`${translate!('Type', transFormName)}`},{redStar}
        {`${translate!('Category', transFormName)}`}
      </>
    );
    if (documentType || capacityCategory) {
      const label1 = documentType ? (
        documentType.name
      ) : (
        <>
          {redStar}
          {`${translate!('Type', transFormName)}`}
        </>
      );
      const label2 = capacityCategory ? (
        capacityCategory.name
      ) : (
        <>
          {redStar}
          {`${translate!('Category', transFormName)}`}
        </>
      );
      titleCategory = (
        <>
          {label1}
          {label1 ? ', ' : ''}
          {label2}
        </>
      );
    }

    // Task a datum
    let titleTask = (
      <>
        {redStar}
        {`${translate!('Task name', transFormName)}`}
      </>
    );
    if (formData.taskName || formData.requiredDate) {
      const label1 = formData.taskName ? (
        formData.taskName
      ) : (
        <>
          {redStar}
          {`${translate!('Task name', transFormName)}`}
        </>
      );
      const label2 = formData.requiredDate
        ? formatDate(new Date(formData.requiredDate), 'shortDate')
        : '';
      titleTask = (
        <>
          {label1}
          {label1 ? ', ' : ''}
          {label2}
        </>
      );
    }

    // Downtime
    let titleDowntime = (
      <>
        {redStar}
        {`${translate!('Downtime from', transFormName)}`},{redStar}
        {`${translate!('Downtime to', transFormName)}`}
      </>
    );
    if (formData.downtimeFrom || formData.downtimeTo) {
      const label1 = formData.downtimeFrom ? (
        formatDate(new Date(`${formData.downtimeFrom}`), 'shortDateShortTime')
      ) : (
        <>
          {redStar}
          {`${translate!('Downtime from', transFormName)}`}
        </>
      );
      const label2 = formData.downtimeTo
        ? formatDate(new Date(`${formData.downtimeTo}`), 'shortDateShortTime')
        : '';
      titleDowntime = (
        <>
          {translate!('Production downtime', transFormName)}
          {` ${label1}`}
          {label1 ? ', ' : ''}
          {label2}
        </>
      );
    }

    setSectionTitles({ ...sectionTitles, titleLineAsset, titleCategory, titleTask, titleDowntime });
  };

  /**
   * Změna DocumentType. Na základě documentType je třeba zobrazit/skrýt (obecně upravit) prvky formuláře.
   * @param data:  Data z dropDownListu (DocumentTypeId)
   */
  const handleDocumentTypeChanged = (data) => {
    if (!documentTypes) return;

    const documentType = documentTypes.find((x) => x.id === data.value);
    if (!documentType) {
      setFormData({ ...formData, typeId: undefined });
      return;
    }

    // Nastavíme defaulní hodnoty formuláře
    let newFormData = { ...formData, typeId: documentType.id };
    const now = new Date();
    newFormData = {
      ...newFormData,
      taskName: '',
      requiredDate: documentType.requiredDateFilledByDefault
        ? moment(now).format('Y-MM-DD HH:mm')
        : null,
      downtimeFrom: documentType.downTimeFromFilledByDefault
        ? moment(now).format('Y-MM-DD HH:mm')
        : null,
    };
    setFormData(newFormData);

    // Nastavíme, jesli se má TaskName zobrazovat jako textbox nebo jako dropdown
    setTaskNameCombo(documentType ? documentType.taskNameCombo : false);
    // Zobrazíme/skryjeme odstávkové prvky
    setShowDowntime(documentType ? documentType.showDowntime : false);
  };

  /**
   * Pokud se jedná o editační formulář, potřebujeme již při načtení formuláře nastavit viditelnost
   * prvků v závislosti na documentType.
   */
  useEffect(() => {
    if (!isEdit || !documentTypes) return;

    const documentType = documentTypes.find((x) => x.id === formData.typeId);
    setTaskNameCombo(documentType ? documentType.taskNameCombo : false);
    setShowDowntime(documentType ? documentType.showDowntime : false);
  }, [documentTypes, formData]);

  const prepareTasknamesData = () => {
    // @ts-ignore
    return localTaskNames?.some((item: TaskName) => item.groupName)
      ? new DataSource({
          store: {
            type: 'array',
            // @ts-ignore
            data: localTaskNames.map((taskName: TaskName) => ({
              ...taskName,
              groupName: taskName.groupName
                ? taskName.groupName
                : translate!('Others', transFormName),
            })),
            key: 'name',
          },
          group: 'groupName',
        })
      : localTaskNames ?? [];
  };

  // Validační funkce, která se volá při provedení TaskAction
  // Funkce se pokusí provést Edit, pokud se nepodaří, TaskAction se nevykoná
  const customValidationFunctionActions = async (): Promise<boolean> => {
    if (!isDirty) return true;

    if (!formRef || !formRef.current!.instance.validate().isValid) return false;

    const result = await onSubmit(null).then((result) => {
      return result;
    });

    if (!result) {
      setDirty();
      return false;
    }

    return true;
  };

  // Vygeneruje string pro textareu činnosti
  const renderActivities = () => {
    if (!worksheets) return;
    let content = '';
    const dateFormat = lang === 'en-US' ? displayDateTimeFormat_EN : displayDateTimeFormat_CZ;
    worksheets.forEach((item) => {
      content += `--- ${moment(item.activityStarted).format(dateFormat)} ${
        item.operatorName
      } ---\n${item.description || ``}\n`;
    });
    return content;
  };

  if (
    topLevelAssetsError ||
    assetsError ||
    documentTypesError ||
    capacityCategoriesError ||
    taskNamesError ||
    errorResponsiblePersons
  )
    return <>Došlo k chybě na straně API</>;
  if (!topLevelAssets || !documentTypes || !capacityCategories || !responsiblePersons)
    return <Loader />;
  if (isValidatingAssets || isValidatingTaskNames || isStatusesValidating) return <Loader />;

  return (
    <>
      <form onSubmit={onSubmit}>
        <Form
          formData={formData}
          colCount={3}
          onFieldDataChanged={(e) => {
            if (e.dataField === 'worksheetsSimpleList') return;
            handleChangeTitles();
            setDirty();
          }}
          ref={formRef}
        >
          <SimpleItem
            dataField={'assetTopLevelParenId'}
            editorType={'dxSelectBox'}
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              dataSource: topLevelAssets,
              readOnly: isReadOnly,
              onValueChanged: handleTopLevelAssetChanged,
            }}
          >
            <Label text={translate!('Line', transFormName)} />
          </SimpleItem>

          <SimpleItem
            dataField={'assetId'}
            editorType={'dxSelectBox'}
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              dataSource: assets,
              readOnly: isReadOnly,
              onValueChanged: handleAssetChanged,
            }}
          >
            <Label text={translate!('Asset', transFormName)} />
            <RequiredRule message={translate!('Asset is required', transFormName)} />
          </SimpleItem>

          {isEdit ? (
            <SimpleItem
              dataField="typeNameTranslated"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Document type', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="typeId"
              editorType="dxSelectBox"
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'id',
                displayExpr: 'nameTranslated',
                items: documentTypes,
                onValueChanged: handleDocumentTypeChanged,
                readOnly: isReadOnly,
              }}
            >
              <Label text={translate!('Document type', transFormName)} />
              <RequiredRule message={translate!('Select document type', transFormName)} />
            </SimpleItem>
          )}

          <SimpleItem
            dataField="capacityCategoryId"
            editorType="dxSelectBox"
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              items: capacityCategories,
              readOnly: isReadOnly,
            }}
          >
            <RequiredRule message={translate!('Capacity category required', transFormName)} />
            <Label text={translate!('Capacity category', transFormName)} />
          </SimpleItem>

          {isTaskNameCombo ? (
            <SimpleItem
              dataField="taskName"
              editorType="dxSelectBox"
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'name',
                displayExpr: 'name',
                readOnly: isReadOnly,
                dataSource: prepareTasknamesData(),
                // @ts-ignore
                grouped: localTaskNames?.some((item: TaskName) => item.groupName),
              }}
            >
              <Label text={translate!('Task name', transFormName)} />
              <RequiredRule message={translate!('Enter task name', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="taskName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: isReadOnly,
              }}
            >
              <Label text={translate!('Task name', transFormName)} />
              <RequiredRule message={translate!('Enter task name', transFormName)} />
            </SimpleItem>
          )}

          {isEdit && (
            <SimpleItem
              dataField="responsiblePersonId"
              editorType="dxSelectBox"
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'personalNumber',
                displayExpr: 'fullName',
                items: responsiblePersons,
                readOnly: true,
              }}
            >
              <Label text={translate!('Responsible person', transFormName)} />
            </SimpleItem>
          )}

          <SimpleItem
            dataField="requiredDate"
            editorType="dxDateBox"
            editorOptions={{
              ...dateFieldOptionsWithSerialization,
              disabledDates: (args) => {
                const date = moment(formatDate(args.date, 'yyyy-MM-dd'));
                const today = moment().startOf('day');
                const _oldRequiredDate = oldRequiredDate
                  ? moment(oldRequiredDate, 'Y-MM-DD')
                  : today;
                if (isEdit) return date.isBefore(today) && !date.isSame(_oldRequiredDate);
                else return date.isBefore(today);
              },
              readOnly: isEdit,
            }}
          >
            <Label text={translate!('Required date', transFormName)} />
            <CustomRule
              validationCallback={(e) => {
                return (
                  moment(e.value).endOf('day').isSameOrAfter(moment().startOf('day')) ||
                  (oldRequiredDate &&
                    oldRequiredDate.toString().substring(0, 10) ===
                      e.value.toString().substring(0, 10))
                );
              }}
              type={'custom'}
              message={translate!(
                'Date is invalid, must be greater then today or today',
                transFormName,
              )}
              ignoreEmptyValue
            />
          </SimpleItem>
          {isShowDowntime ? (
            <SimpleItem
              dataField="downtimeFrom"
              editorType="dxDateBox"
              editorOptions={{
                ...dateTimeFieldOptionsWithSerialization,
                readOnly: isReadOnly,
              }}
            >
              <Label text={translate!('Downtime from', transFormName)} />
              <RequiredRule message={translate!('Downtime from required', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem itemType={'empty'} />
          )}
          {isShowDowntime ? (
            <SimpleItem
              dataField="downtimeTo"
              editorType="dxDateBox"
              editorOptions={{
                ...dateTimeFieldOptionsWithSerialization,
                readOnly: isReadOnly,
              }}
            >
              <Label text={translate!('Downtime to', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem itemType={'empty'} />
          )}

          {!isEdit && <GroupItem />}

          <GroupItem colCount={6} colSpan={3}>
            <GroupItem colSpan={isEdit && files && files?.length > 0 ? 4 : 6}>
              <SimpleItem
                colSpan={3}
                dataField="description"
                editorType="dxTextArea"
                editorOptions={{
                  height: 100,
                  readOnly: isReadOnly,
                }}
              >
                <Label text={translate!('Description', transFormName)} />
              </SimpleItem>
              <SimpleItem
                colSpan={3}
                dataField="diagnose"
                editorType="dxTextArea"
                editorOptions={{
                  height: 100,
                  readOnly: isReadOnly,
                }}
              >
                <Label text={translate!('Diagnose', transFormName)} />
              </SimpleItem>
              {worksheets && worksheets.length > 0 && (
                <SimpleItem
                  colSpan={3}
                  dataField="worksheetsSimpleList"
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 100,
                    readOnly: true,
                    value: renderActivities(),
                  }}
                >
                  <Label text={translate!('Activities', transFormName)} />
                </SimpleItem>
              )}
            </GroupItem>
            {/* attachments jen v editu */}
            {isEdit && files && files?.length ? (
              <GroupItem colSpan={2}>
                <div
                  style={{
                    height: '230px',
                    overflow: 'auto',
                    display: 'inline-block',
                  }}
                  className={'file-manager'}
                >
                  {files && defaultFormData.id !== undefined && (
                    <FileFigureList
                      items={files}
                      entityName={'Task'}
                      entityId={defaultFormData.id}
                      isReadOnly={true}
                      onFileDelete={() => {}}
                    />
                  )}
                </div>
              </GroupItem>
            ) : (
              <GroupItem colSpan={2}></GroupItem>
            )}
          </GroupItem>
          {isEdit ? (
            !isReadOnly ? (
              <GroupItem colSpan={3} colCount={10}>
                <GroupItem colSpan={2} colCount={2} cssClass={'desktop-save-edit-buttons'}>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={'btn-desktop-save-edit-buttons'}
                  >
                    <ButtonOptions
                      text={translate!('Close', 'Asset.Update')}
                      type={'normal'}
                      width={'100%'}
                      onClick={() =>
                        history.push({
                          pathname: backRoute,
                          state: { loadSettingFromSession: true },
                        })
                      }
                    />
                  </ButtonItem>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={`btn-desktop-save-edit-buttons`}
                  >
                    <ButtonOptions
                      width={'100%'}
                      type={'success'}
                      text={translate!('Save', transFormName)}
                      onClick={() => setAfterSubmitMode(IAfterSubmitMode.LIST)}
                      useSubmitBehavior
                    />
                  </ButtonItem>
                </GroupItem>
                <GroupItem colSpan={8}>
                  <TaskChangeStatus
                    task={defaultFormData}
                    mutate={handleStatusChanged}
                    responsiblePersons={taskActionRespPersons}
                    showCurrentStatus={false}
                    statuses={statuses}
                    mutateStatuses={mutateStatuses}
                    isOnlyButtons
                    poupWidth={400}
                    isEditForm={isEdit}
                    oldRequiredDate={oldRequiredDate}
                    customValidationFunction={customValidationFunctionActions}
                    taskRatings={taskRatings}
                  />
                </GroupItem>
              </GroupItem>
            ) : (
              <GroupItem colSpan={3} colCount={10}>
                <GroupItem colSpan={2} colCount={2} cssClass={'desktop-save-edit-buttons'}>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={'btn-desktop-save-edit-buttons'}
                  >
                    <ButtonOptions
                      text={translate!('Close', 'Asset.Update')}
                      type={'normal'}
                      width={'100%'}
                      onClick={() =>
                        history.push({
                          pathname: backRoute,
                          state: { loadSettingFromSession: true },
                        })
                      }
                    />
                  </ButtonItem>
                </GroupItem>
                <GroupItem colSpan={8}>
                  <TaskChangeStatus
                    task={defaultFormData}
                    mutate={handleStatusChanged}
                    responsiblePersons={taskActionRespPersons}
                    showCurrentStatus={false}
                    statuses={statuses}
                    mutateStatuses={mutateStatuses}
                    isOnlyButtons
                    poupWidth={400}
                    isEditForm={isEdit}
                    oldRequiredDate={oldRequiredDate}
                    customValidationFunction={customValidationFunctionActions}
                    taskRatings={taskRatings}
                  />
                </GroupItem>
              </GroupItem>
            )
          ) : (
            <GroupItem colCount={4} colSpan={2}>
              <ButtonItem horizontalAlignment={'left'}>
                <ButtonOptions
                  text={translate!('Close', transFormName)}
                  type={'normal'}
                  width={'100%'}
                  onClick={() =>
                    history.push({
                      pathname: backRoute,
                      state: { loadSettingFromSession: true },
                    })
                  }
                />
              </ButtonItem>
              <ButtonItem horizontalAlignment={'left'}>
                <ButtonOptions
                  width={'100%'}
                  type={'success'}
                  text={translate!('Save and continue', transFormName)}
                  useSubmitBehavior
                  disabled={loading}
                  onClick={() => setAfterSubmitMode(IAfterSubmitMode.CONTINUE)}
                />
              </ButtonItem>
              <ButtonItem horizontalAlignment={'left'}>
                <ButtonOptions
                  width={'100%'}
                  type={'success'}
                  text={translate!('Save and back to list', transFormName)}
                  onClick={() => setAfterSubmitMode(IAfterSubmitMode.LIST)}
                  useSubmitBehavior
                  disabled={loading}
                />
              </ButtonItem>
            </GroupItem>
          )}
        </Form>
      </form>
      {prompt}
      {isAssetChangeDialogVisible && <AssetClassChangeDialog />}
    </>
  );
}
