import notify from 'devextreme/ui/notify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { API_URL, fetcher } from '../utils/apiUrl';

const VersionContext = createContext<VersionContextType>({ currentVersion: '' });

function VersionProvider(props: any) {
  const [currentVersion, setCurrentVersion] = useState<string | null>();

  const { data } = useSWR(API_URL.APP_INFO, fetcher, {
    refreshInterval: 30000,
  });

  useEffect(() => {
    if (!data) return;

    if (!currentVersion) {
      setCurrentVersion(data.version);
      return;
    }

    if (currentVersion !== data.version) {
      notify(`Existuje nová verze systému. Dojde k přenačtení aplikace.`, 'error');
      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  }, [currentVersion, data]);

  return <VersionContext.Provider value={{ currentVersion }} {...props} />;
}

interface VersionContextType {
  currentVersion: string;
}

const useVersion = () => useContext<VersionContextType>(VersionContext);

export { VersionProvider, useVersion };
