import { Button, TextBox } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import { getListApi } from '../../../../../apiUtils';
import { Loader } from '../../../../../components/loader/Loader';
import { SisDataGrid } from '../../../../../components/table';
import { useTranslation } from '../../../../../contexts/translation';
import { SparePart } from '../../../../../types/spareParts.types';
import { API_URL } from '../../../../../utils/apiUrl';
import { Columns } from './TasksSparePartsSearchColumns';

export const TasksSparePartsSearchForm = () => {
  const transFormName = 'TasksMobile.SparePartsSearch';
  const { translate } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [searchText, setSearchText] = useState('');
  const textBoxRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [spareParts, setSpareParts] = useState<SparePart[]>([]);
  const TIMEOUT = 2000;

  // Načte data z API
  const getSpareParts = async () => {
    setLoading(true);
    await getListApi<SparePart[]>({
      url: `${API_URL.SPARE_PART_SEARCH}`,
      params: {
        search: searchText,
      },
      callAfterSuccess: (result) => {
        setSpareParts(result);
      },
    });
    setLoading(false);
  };

  // Zrušíme timeout, pokud komponent unmountne nebo se změní state
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  // Byla stisknuta klávesa, nastavujeme nový timeout
  useEffect(() => {
    if (typingTimeout) clearTimeout(typingTimeout); // Zrušíme předchozí timeout

    // Nastavíme nový timeout na 2000 ms
    const newTimeout = setTimeout(() => {
      if (!searchText.trim()) setSpareParts([]);
      else getSpareParts();
    }, TIMEOUT);

    setTypingTimeout(newTimeout);
  }, [searchText]);

  // Focus na inout
  const focusSearchTextInput = () => {
    if (!textBoxRef.current) return;
    // @ts-ignore
    textBoxRef.current.instance.focus();
  };

  // Focus při inutu
  useEffect(() => {
    focusSearchTextInput();
  }, [textBoxRef]);

  // Focus při refresh
  useEffect(() => {
    focusSearchTextInput();
  });

  if (isLoading) return <Loader />;

  return (
    <div style={{ padding: 5 }}>
      <div style={{ display: `flex` }}>
        <TextBox
          ref={textBoxRef}
          placeholder={translate!('Search textbox palceholder', transFormName)}
          value={searchText}
          onValueChanged={(e) => setSearchText(e.value)}
          valueChangeEvent="keyup"
          style={{ fontSize: 21, flexGrow: 0.999, marginRight: 5 }}
        />
        <Button icon="close" width={40} onClick={() => setSearchText(``)} />
      </div>
      <div style={{ marginTop: 10 }}>
        <SisDataGrid
          allowColumnReordering={false}
          allowColumnResizing={false}
          columnChooser={false}
          filterRow={false}
          data={spareParts}
          keyExpr={'prodId'}
          transFormName={transFormName}
          columns={Columns}
        />
      </div>
    </div>
  );
};
