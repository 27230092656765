import React from 'react';
import { FileFigure } from './FileFigure';

interface Props {
  items?: any[];
  entityName: string;
  entityId: number;
  isReadOnly: boolean;
  onFileDelete: any;
  onFileRename?: any;
  isTmpFile?: boolean;
}

export function FileFigureList({
  items,
  entityName,
  entityId,
  isReadOnly,
  onFileDelete,
  onFileRename,
  isTmpFile,
}: Props) {
  return (
    <>
      {items &&
        items?.length > 0 &&
        items.map((item, index) => {
          return (
            <FileFigure
              id={item.id ? item.id : index}
              key={index}
              entityName={entityName}
              entityId={entityId}
              name={item.name}
              description={item.description}
              contentType={item.contentType ? item.contentType : item.type}
              isReadonly={isReadOnly}
              onFileDelete={onFileDelete}
              onFileRename={onFileRename}
              item={item}
              height={120}
              isTmpFile={isTmpFile}
            />
          );
        })}
    </>
  );
}
