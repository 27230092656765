import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/Reports/Tasks/Columns';
import { paintCellByTaskColor } from '../../pages/Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../../pages/Tasks/utils/paintRowByColor';
import { UserRoles } from '../../types/applicationUser.types';
import { Task } from '../../types/task.types';
import { getWidthByColumns } from '../../utils/datagridNoResponsive';
import { DatagridNoResponsive } from '../datagridNoResponsive/DatagridNoResponsive';
import BulkEditModal from '../modals/TasksBulkEditModal';
import { IColumnType, SelectionMode, SisDataGrid, SortingMode } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  handleCreateWorksheet?: (e) => void;
  handleCopy?: (e) => void;
  dataGridKey: string;
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
  mutateData?: () => void;
  datagridJsonData?: any;
}

export const TasksReportDataGrid = ({
  data,
  handleEdit,
  handleCopy,
  handleCreateWorksheet,
  resetFilter,
  dataGridKey,
  dataGridRef,
  userCanCreate,
  heading,
  filterVisibility,
  setFilterVisibility,
  mutateData,
  datagridJsonData,
}: Props) => {
  const { user } = useAuth();
  const { translate } = useTranslation();
  const transFormName = 'Task.List';
  const transFormReportBar = 'Reports.Bar';
  const history = useHistory();
  const { pathname } = useLocation();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Array<Task>>([]);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () =>
            history.push({
              pathname: `/tasks/new/`,
              state: { backRoute: pathname },
            }),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'edit',
          type: 'default',
          text: translate!('Bulk changes', transFormName),
          stylingMode: 'outlined',
          onClick: () => setModalVisible(true),
          disabled: selectedRows.length === 0,
        },
      },
    );
  };

  const onSelectionChanged = async () => {
    // @ts-ignore
    if (!dataGridRef || !dataGridRef?.current) return;
    // @ts-ignore
    const selectedRowsData = await dataGridRef?.current?.instance.getSelectedRowsData();
    setSelectedRows(selectedRowsData);
  };

  useEffect(() => {
    if (!data) return;
    setSelectedRows(data?.filter((item) => selectedRows.map((x) => x.id).includes(item.id)));
  }, [data]);

  const columns = Columns(handleEdit, handleCreateWorksheet, handleCopy, user?.roles, translate);

  /**
   * Filtrační a modifikační funkce pro nastavení sloupců pro datagrid v editačním modalu
   */
  const modalColumns = columns
    ?.filter((column) => column?.type !== IColumnType.buttons)
    .map((column) => {
      return { ...column, width: 100 };
    });

  const isWorksheetRole = user?.roles.some(
    (role) => role === UserRoles.WorksheetAll || role === UserRoles.WorksheetOwn,
  );

  return (
    <>
      {isModalVisible && (
        <BulkEditModal
          data={isModalVisible ? selectedRows : []}
          isVisible={isModalVisible}
          mutate={mutateData}
          onClose={() => setModalVisible(false)}
          columns={modalColumns}
          transFormNameColumns={transFormName}
        />
      )}
      <DatagridNoResponsive
        datagridWidth={getWidthByColumns(
          datagridJsonData && datagridJsonData.columns ? datagridJsonData.columns : columns,
          columns,
          datagridJsonData && datagridJsonData.columns,
        )}
      >
        <SisDataGrid
          enableXlsxExport
          exportName={'tasks-data'}
          data={data}
          keyExpr={'id'}
          dataGridRef={dataGridRef}
          transFormName={transFormName}
          stateStoringKey={dataGridKey}
          columns={Columns(
            handleEdit,
            handleCreateWorksheet,
            handleCopy,
            isWorksheetRole,
            translate,
          )}
          totalSummaryEnabled={false}
          totalSummaryColumns={[
            { name: '', column: 'typeNameTranslated', summaryType: 'count' },
            { name: '', column: 'urgeLevel', summaryType: 'max' },
            { name: '', column: 'urgeLevel', summaryType: 'max' },
            {
              name: 'TimeCount',
              column: 'reactionTimeMaintenance',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            {
              name: 'TimeCount',
              column: 'reparationTime',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            {
              name: 'TimeCount',
              column: 'downTimeTime',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            { name: '', column: 'worksheetsCount', summaryType: 'sum' },
            { name: '', column: 'worksheetsCount', summaryType: 'avg' },
          ]}
          groupSummaryEnabled={false}
          groupSummaryColumns={[
            { column: 'typeNameTranslated', summaryType: 'count' },
            { column: 'urgeLevel', summaryType: 'max' },
            { column: 'urgeLevel', summaryType: 'max' },
            {
              name: 'TimeCount',
              column: 'reactionTimeMaintenance',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            {
              name: 'TimeCount',
              column: 'reparationTime',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            {
              name: 'TimeCount',
              column: 'downTimeTime',
              summaryType: 'custom',
              customizeText: (item) => `Total: ${item.value}`,
            },
            { column: 'worksheetsCount', summaryType: 'sum' },
            { column: 'worksheetsCount', summaryType: 'avg' },
          ]}
          onToolbarPreparing={onToolbarPreparing}
          onRowPrepared={paintRowByTaskColor}
          onCellPrepared={paintCellByTaskColor}
          onCellDblClick={(e) => handleEdit && handleEdit(e)}
          selectionMode={SelectionMode.MULTIPLE}
          onSelectionChanged={onSelectionChanged}
          pageSizeChangeVisible={true}
          enableGrouping
          sortingMode={SortingMode.MULTIPLE}
          sto
          isReadOnly={false}
        />
      </DatagridNoResponsive>
    </>
  );
};
