import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Drawer from 'devextreme-react/drawer';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import 'devextreme/dist/css/dx.common.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import '../../../src/custom.scss';
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';
import { SideNavigationMenu } from '../../components/sideNavigationMenu/SideNavigationMenu';
import { useTheme } from '../../contexts/theme';
import { useTranslation } from '../../contexts/translation';
import '../../custom.scss';
import { useScreenSize } from '../../hooks/mediaQuery';
import { useMenuPatch } from '../../hooks/useMenuPatch';
import { scrollDatagridNoResponsive } from '../../utils/datagridNoResponsive';

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};

// eslint-disable-next-line react/prop-types
export function SideNavInnerToolbar({ title, children }) {
  const scrollViewRef = useRef();
  const history = useHistory();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(isLarge ? MenuStatus.Opened : MenuStatus.Closed);
  const { theme } = useTheme();
  const { translate } = useTranslation();
  const [selectedPath, setSelectedPath] = useState<any>();

  // možnosti, co se zobrazí v context menu
  const contextMenuItems = [
    {
      text: translate!('Open link in new tab', ''),
      icon: 'dx-icon-globe',
      onclick: () => contextMenuItemClick(),
    },
  ];
  // načtu path kliknutého odkazu
  const treeViewItemContextMenu = (e) => {
    if (e && e.itemData && e.itemData.path) setSelectedPath(e.itemData.path);
  };
  // po kliku na možnost v context menu
  const contextMenuItemClick = () => {
    if (!selectedPath) return;
    if (selectedPath.includes('http')) {
      // externí link
      window.open(selectedPath, '_blank');
    } else {
      // interní link
      window.open('#' + selectedPath, '_blank');
    }
  };

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed,
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus,
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus,
    );
  }, [isLarge]);

  const onNavigationChanged = useCallback(
    ({ itemData: { path }, event }) => {
      if (menuStatus === MenuStatus.Closed || !path) {
        event.preventDefault();
        return;
      }

      if (path.includes('http')) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }

      // @ts-ignore
      if (scrollViewRef && scrollViewRef.current && scrollViewRef.current.instance)
        // @ts-ignore
        scrollViewRef.current.instance.scrollTo(0);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event.stopPropagation();
      }
    },
    [history, menuStatus, isLarge],
  );

  const handleScroll = (e?) => {
    scrollDatagridNoResponsive();
  };

  useEffect(() => {
    scrollDatagridNoResponsive();
  }, [menuStatus]);

  const darkThemeCss = theme === 'dark' ? 'dx-swatch-additional ' : '';
  return (
    <div className={`${darkThemeCss}side-nav-inner-toolbar`}>
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        // @ts-ignore
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={!isLarge}
        opened={menuStatus !== MenuStatus.Closed}
        template={'menu'}
      >
        <div className={'container datagrid-scroll-container'} onScroll={handleScroll}>
          {/* @ts-ignore */}
          <Header menuToggleEnabled={isXSmall} toggleMenu={toggleMenu} />
          {/* @ts-ignore */}
          <div className={'content'}>
            {React.Children.map(children, (item) => {
              return item.type !== Footer && item;
            })}
          </div>
          <div className={'content-block'}>
            {React.Children.map(children, (item) => {
              return item.type === Footer && item;
            })}
          </div>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            onClick={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
            contextMenuItemClick={contextMenuItemClick}
            treeViewItemContextMenu={treeViewItemContextMenu}
            contextMenuItems={contextMenuItems}
          >
            <Toolbar id={'navigation-header'}>
              {!isXSmall && (
                <Item location={'before'} cssClass={'menu-button'}>
                  <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
              )}
              <Item location={'before'} cssClass={'header-title'} text={title} />
            </Toolbar>
          </SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );
}
