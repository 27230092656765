import { Button, DropDownButton } from 'devextreme-react';
import ResponsiveBox, {
  Col,
  Item,
  Location,
  Row as ResponsiveBoxRow,
} from 'devextreme-react/responsive-box';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { Loader } from '../../../../components/loader/Loader';
import { useAssetClass } from '../../../../contexts/assetClass';
import { useMobileAuth } from '../../../../contexts/mobileAuthContext';
import { useTranslation } from '../../../../contexts/translation';
import { AppInfo } from '../../../../types/appInfo.types';
import { API_URL, fetcher } from '../../../../utils/apiUrl';
import { swrConfig } from '../../../Assets/AssetsEdit';
import './Bar.scss';
import * as Row from './Row';

type SummaryNavBarProps = {
  titles: {
    title: string;
    icon?: ReactNode;
    class: string;
    subtext?: string;
    subtextclass?: string;
  }[];
  onBack: () => void;
};

enum UserPanelAction {
  SIGN_IN,
  SIGN_OUT,
  CHANGE_LANG,
  DB_INFO,
  VERSION_INFO,
}

export const SummaryNavBar = ({ titles, onBack }: SummaryNavBarProps) => {
  return (
    <ResponsiveBox>
      <ResponsiveBoxRow />
      <Col ratio={0.15} />
      <Col ratio={0.85} />
      <Item>
        <Location row={0} col={0} />
        <Button
          className="navbar-icon"
          icon="chevronprev"
          stylingMode="text"
          height={`100%`}
          onClick={onBack}
        />
      </Item>
      <Item>
        <Location row={0} col={1} />
        <Row.Container>
          {titles.map((item, index) => (
            <Row.Item direction="row" key={index}>
              <div className={`navbar-item index-${index} ${item.class ? item.class : ''}`}>
                <div style={{ display: 'flex' }}>
                  {item.title}
                  {item.icon}
                </div>
                {item.subtext ? (
                  <span className={item.subtextclass ? item.subtextclass : ''}>{item.subtext}</span>
                ) : (
                  ''
                )}
              </div>
            </Row.Item>
          ))}
        </Row.Container>
      </Item>
    </ResponsiveBox>
  );
};

export const ActionBar = () => {
  const { user, signOut } = useMobileAuth();
  const { assetClass, assetClassName } = useAssetClass();
  const history = useHistory();
  const transFormName = 'TasksMobile.Bars';
  const { translate, changeLang } = useTranslation();

  // Načteme informaci o aplikaci
  const { data: appInfo } = useSWR<AppInfo>(`${API_URL.APP_INFO}`, fetcher, swrConfig);
  // Načteme seznam dostupných jazyků
  const { data: languages, error: errorLanguages } = useSWR(
    `${API_URL.LANGUAGE_LIST}`,
    fetcher,
    swrConfig,
  );

  /**
   * Obslouží kliknutí na položku uživatelského panelu.
   * @param id
   */
  const handleUserPanelItemClick = (e) => {
    if (e.itemData.id === UserPanelAction.SIGN_IN && signOut) {
      history.push('/mobile/login');
    }
    if (e.itemData.id === UserPanelAction.SIGN_OUT && signOut) {
      signOut();
      return;
    }
    if (e.itemData.id === UserPanelAction.CHANGE_LANG && changeLang) {
      changeLang(e.itemData.langKey);
    }
  };

  const languagesItems = languages
    ? Object.keys(languages).map((key) => {
        return {
          id: UserPanelAction.CHANGE_LANG,
          name: languages[key],
          langKey: key,
          icon: 'isblank',
        };
      })
    : [];

  const userPanelItems = [
    ...[
      {
        id: UserPanelAction.SIGN_OUT,
        name: translate!('Logout', ''),
        icon: 'export',
        isBorderBottom: true,
      },
    ],
    ...languagesItems,
    ...[
      {
        id: UserPanelAction.DB_INFO,
        name: `DB: ${appInfo?.database}`,
        isBorderTop: true,
        isSmaller: true,
      },
      {
        id: UserPanelAction.DB_INFO,
        name: `App: ${appInfo?.version}`,
        isSmaller: true,
      },
    ],
  ];

  if (!user) return <Loader />;

  return (
    <div className="menu-action-bar">
      <ResponsiveBox>
        <ResponsiveBoxRow />
        <Col ratio={0.1} />
        <Col ratio={0.5} />
        <Col ratio={0.4} />
        <Item>
          <Location row={0} col={0} />
          <Button
            className="menu-icon"
            icon="menu"
            stylingMode="text"
            onClick={() => history.push(`/mobile`)}
          />
        </Item>
        <Item>
          <Location row={0} col={1} />
        </Item>
        <Item>
          <Location row={0} col={2} />
          <DropDownButton
            className="signout-button"
            text={`${user.fullName.substring(0, 10)}, ${assetClassName}`}
            displayExpr="name"
            keyExpr="id"
            items={userPanelItems}
            onItemClick={handleUserPanelItemClick}
            itemRender={(e) => {
              return (
                <div
                  style={{
                    borderTop: e.isBorderTop ? 'solid 1px #888' : 'none',
                    paddingTop: e.isBorderTop ? '5px' : 'none',
                    borderBottom: e.isBorderBottom ? 'solid 1px #888' : 'none',
                    paddingBottom: e.isBorderBottom ? '5px' : 'none',
                  }}
                  className={e.isSmaller ? 'small' : ''}
                >
                  {e.icon ? (
                    <div className={'dx-list-item-icon-container'}>
                      <i className={`dx-icon-${e.icon} dx-icon-custom-style`} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {e.name}
                </div>
              );
            }}
          />
        </Item>
      </ResponsiveBox>
    </div>
  );
};
