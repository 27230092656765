import { IDataType, SisDataGridColumns } from '../../../components/table';

export const Columns: SisDataGridColumns = [
  {
    dataField: 'assetId',
    caption: 'Id',
    width: '5%',
  },
  {
    dataField: 'assetName',
    caption: 'Asset',
  },
  {
    dataField: 'levelName',
    caption: 'Level',
    lookupPickBy: 'levelName',
  },
  {
    dataField: 'assetTypeName',
    caption: 'Type',
    width: '10%',
    lookupPickBy: 'assetTypeName',
  },
  {
    dataField: 'assetStatusName',
    caption: 'Status',
    lookupPickBy: 'assetStatusName',
  },
  {
    dataField: 'assetParentName',
    caption: 'Parent',
    lookupPickBy: 'assetParentName',
  },
  {
    dataField: 'assetVisible',
    caption: 'Visible',
    dataType: IDataType.boolean,
  },
  {
    dataField: 'capacityCategoryName',
    caption: 'Capacity category',
    lookupPickBy: 'capacityCategoryName',
  },
  {
    dataField: 'responsiblePersonName',
    caption: 'Responsible person',
    lookupPickBy: 'responsiblePersonName',
  },
  {
    dataField: 'responsibleFrom',
    caption: 'From',
    dataType: IDataType.date,
  },
  {
    dataField: 'responsibleTo',
    caption: 'To',
    dataType: IDataType.date,
  },
];
