// *** API ***
export const fetcher = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.');
    error.status = res.status;
    throw error;
  }

  return res.json();
};
export const imageFetcher = (url) => fetch(url).then((r) => r.blob());

export const API_URL = {
  LOGIN: '/api/User/Login',
  LOGIN_UID: '/api/User/LoginUId',
  LOGOUT: '/api/User/Logout',
  GET_CURRENT_USER: '/api/User/GetCurrentUser',
  GET_CURRENT_LANGUAGE: '/api/Language/GetCurrentLanguage',
  GET_TRANSLATIONS: '/api/Language/GetTranslations',
  IS_USER_AUTHENTICATED: '/api/User/isAuthenticated',
  LOGIN_TECHNICAL_USER: '/api/User/LoginTechnicalUser',
  SET_TEMPORARLY_VALUE: '/api/User/SetTemporalyValue',
  CHANGE_CURRENT_ASSET_CLASS: '/api/User/ChangeCurrentAssetClass',
  CHANGE_CURRENT_PLATFORM: '/api/User/ChangeCurrentPlatform',
  // *** Terminal mapping ***
  TERMINAL_MAPPING_LIST_FROM_CURRENT_IP: '/api/TerminalMapping/GetMappingFromCurrentIpAddress',
  // *** Menu ***
  MENU_LIST: '/api/Menu/GetList',
  MENU_MOBILE_STRUCTURE: '/api/MenuMobile/GetStructure',
  // *** Assets ***
  ASSET_LIST: '/api/Asset/GetList',
  ASSET_LIST_BY_CLASS: '/api/Asset/GetListByClass',
  ASSET_ITEM: '/api/Asset/GetItem',
  ASSET_POST: '/api/Asset/CreateItem',
  ASSET_PUT: '/api/Asset/UpdateItem',
  ASSET_COUNT: '/api/Asset/GetCount',
  ASSET_TREE_VIEW: '/api/Asset/GetTreeView',
  ASSET_LIST_BY_CLASS_TOP_LEVEL: '/api/Asset/GetListFromTopLevel',
  ASSET_REPORT_REQUEST: '/api/Asset/GetAssetReportRequest',
  ASSET_LIST_WITH_HIGHER_LEVEL: 'api/Asset/GetListWithHigherLevelByType',
  ASSET_GET_BY_NFC_TAG: '/api/Asset/GetAssetByNfcTagId',
  // Assets Bulk Edit
  ASSET_BULK_EDIT_PARENT: '/api/Asset/BulkEditParentId',
  ASSET_BULK_EDIT_STATUS: '/api/Asset/BulkEditStatus',
  ASSET_BULK_EDIT_BUILDING: '/api/Asset/BulkEditBuilding',
  ASSET_BULK_EDIT_CENTRE: '/api/Asset/BulkEditCentre',
  ASSET_BULK_EDIT_LOCATION: '/api/Asset/BulkEditLocation',
  ASSET_BULK_EDIT_OWNER: '/api/Asset/BulkEditOwner',
  // AssetClass
  ASSET_CLASS_LIST: '/api/AssetClass/GetList',
  ASSET_CLASS_ITEM: '/api/AssetClass/GetItem',
  ASSET_CLASS_POST: '/api/AssetClass/CreateItem',
  ASSET_CLASS_PUT: '/api/AssetClass/UpdateItem',
  // AssetClassParameter
  ASSET_CLASS_PARAMETER_LIST: '/api/AssetClassParameter/GetList',
  ASSET_CLASS_PARAMETER_ITEM: '/api/AssetClassParameter/GetItem',
  ASSET_CLASS_PARAMETER_POST: '/api/AssetClassParameter/CreateItem',
  ASSET_CLASS_PARAMETER_PUT: '/api/AssetClassParameter/UpdateItem',
  // AssetStatus
  ASSET_STATUS_LIST: '/api/AssetStatus/GetList',
  ASSET_STATUS_ITEM: '/api/AssetStatus/GetItem',
  // AssetType
  ASSET_TYPE_LIST: '/api/AssetType/GetList',
  ASSET_TYPE_LIST_BY_CLASS: '/api/AssetType/GetListByClass',
  ASSET_TYPE_ITEM: '/api/AssetType/GetItem',
  ASSET_TYPE_POST: '/api/AssetType/CreateItem',
  ASSET_TYPE_PUT: '/api/AssetType/UpdateItem',
  // AssetLevel
  ASSET_LEVEL_LIST: '/api/AssetLevel/GetList',
  ASSET_LEVEL_LIST_BY_CLASS: '/api/AssetLevel/GetListByClass',
  ASSET_LEVEL_ITEM: '/api/AssetLevel/GetItem',
  ASSET_LEVEL_POST: '/api/AssetLevel/CreateItem',
  ASSET_LEVEL_PUT: '/api/AssetLevel/UpdateItem',
  // AssetLocation
  ASSET_LOCATION_LIST: '/api/AssetLocation/GetList',
  // AssetParameter
  ASSET_PARAMETER_LIST: '/api/AssetParameter/GetList',
  ASSET_PARAMETER_LIST_FILTERED: '/api/AssetParameter/GetListFiltered',
  ASSET_PARAMETER_MEASURABLE_LIST: '/api/AssetParameter/GetMeasurableList',
  ASSET_PARAMETER_ITEM: '/api/AssetParameter/GetItem',
  ASSET_PARAMETER_POST: '/api/AssetParameter/CreateItem',
  ASSET_PARAMETER_PUT: '/api/AssetParameter/UpdateItem',
  ASSET_PARAMETER_COUNT: '/api/AssetParameter/GetCount',
  // AssetParameterMeasured
  ASSET_PARAMETER_MEASURED_LIST: '/api/AssetParameterMeasured/GetList',
  ASSET_PARAMETER_MEASURED_ASSET_LIST: '/api/AssetParameterMeasured/GetListForAsset',
  ASSET_PARAMETER_MEASURED_ITEM: '/api/AssetParameterMeasured/GetItem',
  ASSET_PARAMETER_MEASURED_POST: '/api/AssetParameterMeasured/CreateItem',
  ASSET_PARAMETER_MEASURED_PUT: '/api/AssetParameterMeasured/UpdateItem',
  ASSET_PARAMETER_MEASURED_COUNT: '/api/AssetParameterMeasured/GetCountForAsset',
  // AssetResponsiblePerson
  ASSET_RESPONSIBLE_PERSON_LIST: '/api/AssetResponsiblePerson/GetList',
  ASSET_RESPONSIBLE_PERSON_ITEM: '/api/AssetResponsiblePerson/GetItem',
  ASSET_RESPONSIBLE_PERSON_POST: '/api/AssetResponsiblePerson/CreateItem',
  ASSET_RESPONSIBLE_PERSON_PUT: '/api/AssetResponsiblePerson/UpdateItem',
  ASSET_RESPONSIBLE_PERSON_DELETE: '/api/AssetResponsiblePerson/DeleteItem',
  ASSET_RESPONSIBLE_PERSON_COUNT: '/api/AssetResponsiblePerson/GetCount',
  // AssetCycle
  ASSET_CYCLE_LIST: '/api/AssetCycle/GetListCommon',
  ASSET_CYCLE_REVISION_LIST: '/api/AssetCycle/GetListRevision',
  ASSET_CYCLE_ITEM: '/api/AssetCycle/GetItem',
  ASSET_CYCLE_POST: '/api/AssetCycle/CreateItem',
  ASSET_CYCLE_PUT: '/api/AssetCycle/UpdateItem',
  ASSET_CYCLE_COMMON_COUNT: '/api/AssetCycle/GetCountCommon',
  ASSET_CYCLE_REVISION_COUNT: '/api/AssetCycle/GetCountRevision',
  ASSET_CYCLE_IS_CYCLE_MONTHLY: '/api/AssetCycle/IsAssetCycleMonthly',
  ASSET_CYCLE_CALCULATE_ESTIMATED_OCCURRANCE: '/api/AssetCycle/CalculateEstimatedOccurrence',
  // SparePart
  SPARE_PART_INFO: '/api/SparePart/GetSparePartInfo',
  SPARE_PART_QTY: '/api/SparePart/GetSparePartQty',
  SPARE_PART_SEARCH: '/api/SparePart/Search',
  // SparePartIssued
  SPARE_PART_ISSUED_LIST: '/api/SparePartIssued/GetList',
  SPARE_PART_ISSUED_LIST_AGGREGATED: '/api/SparePartIssued/GetListAggregated',
  SPARE_PART_ISSUED_LIST_AGGREGATED_FOR_ASSET: '/api/SparePartIssued/GetListAggregatedForAsset',
  SPARE_PART_REPORT_REQUEST: '/api/SparePartIssued/GetSparePartIssuedReportRequest',
  SPARE_PART_ISSUED_ITEM: '/api/SparePartIssued/GetItem',
  // AssetCycleParameters
  ASSET_CYCLE_PARAMETER_LIST: '/api/AssetCycleParameter/GetList',
  ASSET_CYCLE_PARAMETER_ITEM: '/api/AssetCycleParameter/GetItem',
  ASSET_CYCLE_PARAMETER_POST: '/api/AssetCycleParameter/CreateItem',
  ASSET_CYCLE_PARAMETER_PUT: '/api/AssetCycleParameter/UpdateItem',
  // Files
  FILE_UPLOAD: '/api/Attachment/Upload',
  FILE_LIST: '/api/Attachment/GetList',
  FILE_ITEM: '/api/Attachment/Get',
  FILE_ITEM_THUMBNAIL: '/api/Attachment/GetThumbnail',
  FILE_PRIMARY_IMAGE: '/api/Attachment/GetPrimaryImage',
  FILE_DELETE_ITEM: '/api/Attachment/Delete',
  FILE_PUT: '/api/Attachment/Update',
  FILE_SET_PRIMARY: '/api/Attachment/SetPrimary',
  FILE_COUNT: '/api/Attachment/GetCount',
  // *** Units ***
  UNIT_LIST: '/api/Unit/GetList',
  UNIT_ITEM: '/api/Unit/GetItem',
  UNIT_POST: '/api/Unit/CreateItem',
  UNIT_PUT: '/api/Unit/UpdateItem',
  // *** Parameters ***
  PARAMETER_LIST: '/api/Parameter/GetList',
  PARAMETER_LIST_FILTERED: '/api/Parameter/GetListFiltered',
  PARAMETER_BY_CLASS_LIST: '/api/Parameter/GetListByClass',
  PARAMETER_ITEM: '/api/Parameter/GetItem',
  PARAMETER_POST: '/api/Parameter/CreateItem',
  PARAMETER_PUT: '/api/Parameter/UpdateItem',
  // *** Capacity categories ***
  CAPACITY_CATEGORY_LIST: '/api/CapacityCategory/GetList',
  CAPACITY_CATEGORY_LIST_BY_TERMINAL_MAPPING:
    '/api/CapacityCategory/GetListFilteredByTerminalMapping',
  CAPACITY_CATEGORY_ITEM: '/api/CapacityCategory/GetItem',
  CAPACITY_CATEGORY_POST: '/api/CapacityCategory/CreateItem',
  CAPACITY_CATEGORY_PUT: '/api/CapacityCategory/UpdateItem',
  // *** Capacity categories persons ***
  CAPACITY_CATEGORY_PERSON_LIST: '/api/CapacityCategoryPerson/GetList',
  CAPACITY_CATEGORY_PERSON_LIST_UNIQUE: '/api/CapacityCategoryPerson/GetListUniqNames',
  CAPACITY_CATEGORY_PERSON_ITEM: '/api/CapacityCategoryPerson/GetItem',
  CAPACITY_CATEGORY_PERSON_POST: '/api/CapacityCategoryPerson/CreateItem',
  CAPACITY_CATEGORY_PERSON_PUT: '/api/CapacityCategoryPerson/UpdateItem',
  // *** DocumentType ***
  GET_LIST_FOR_TASK_CREATE: '/api/DocumentType/GetListForTaskCreate',
  DOCUMENT_TYPE_LIST: '/api/DocumentType/GetList',
  DOCUMENT_TYPE_ITEM: '/api/DocumentType/GetItem',
  DOCUMENT_TYPE_POST: '/api/DocumentType/CreateItem',
  DOCUMENT_TYPE_PUT: '/api/DocumentType/UpdateItem',
  DOCUMENT_TYPE_FOR_ASSET_CYCLE_LIST: '/api/DocumentType/GetListForAssetCycle',
  // *** RevisionCategory ***
  REVISION_CATEGORY_LIST: '/api/RevisionCategory/GetList',
  REVISION_CATEGORY_ITEM: '/api/RevisionCategory/GetItem',
  REVISION_CATEGORY_POST: '/api/RevisionCategory/CreateItem',
  REVISION_CATEGORY_PUT: '/api/RevisionCategory/UpdateItem',
  // *** Profile ***
  LANGUAGE_POST: '/api/Language/SetLanguage',
  LANGUAGE_LIST: '/api/Language/GetAvailableLanguages',
  // *** Person ***
  PERSON_LIST: '/api/User/GetAllUsers',
  PERSON_FROM_THIS_APP_LIST: '/api/User/GetAllUsersFromThisApp',
  PERSON_LIST_WITH_CAPACITY_CAT: '/api/User/GetAllUsersWithSomeCapacityCategory',
  // *** Transactions ***
  TRANSACTION_GET_URL_FROM_SHORTCUT: '/api/TransactionMapping/GetUrlFromShortcut',
  TRANSACTION_LIST: '/api/TransactionMapping/GetList',
  TRANSACTION_LIST_FOR_CURRENT_USER: '/api/TransactionMapping/GetListForCurrentUser',
  // *** Reports***
  RESPONSIBLE_PERSON_REPORT: '/api/AssetResponsiblePerson/GetAssetResponsiblePersonReportRequest',
  REPORT_SETTING_LIST_FOR_USER: '/api/ReportSetting/GetListForUser',
  REPORT_SETTING_LIST: '/api/ReportSetting/GetList',
  REPORT_SETTING_ITEM: '/api/ReportSetting/GetItem',
  REPORT_SETTING_POST: '/api/ReportSetting/CreateItem',
  REPORT_SETTING_PUT: '/api/ReportSetting/UpdateItem',
  REPORT_SETTING_DELETE: '/api/ReportSetting/DeleteItem',
  REPORT_GET_ALLOWED_LIST: '/api/Report/GetAllowedList',
  REPORT_GET_ITEM: '/api/Report/GetItem',
  // *** General Reports ***
  GENERAL_REPORT_ITEM: '/api/GeneralReport/GetItem',
  GENERAL_REPORT_DATA: '/api/GeneralReport/GetDataForReport',
  GENERAL_REPORT_PARAMETER: '/api/GeneralReportParameter/GetList',
  GENERAL_REPORT_SETTING_LIST_FOR_USER: '/api/GeneralReportSetting/GetListForUser',
  GENERAL_REPORT_SETTING_LIST: '/api/GeneralReportSetting/GetList',
  GENERAL_REPORT_SETTING_ITEM: '/api/GeneralReportSetting/GetItem',
  GENERAL_REPORT_SETTING_POST: '/api/GeneralReportSetting/CreateItem',
  GENERAL_REPORT_SETTING_PUT: '/api/GeneralReportSetting/UpdateItem',
  GENERAL_REPORT_SETTING_DELETE: '/api/GeneralReportSetting/DeleteItem',
  // *** Tasks ***
  TASK_LIST: '/api/Task/GetList',
  TASK_ITEM: '/api/Task/GetItem',
  TASK_POST: '/api/Task/CreateItem',
  TASK_PUT: '/api/Task/UpdateItem',
  TASK_BY_ASSET: '/api/Task/GetListByAsset',
  TASK_REPORT_REQUEST: '/api/Task/GetTaskReportRequest',
  TASK_BY_CLASS: '/api/Task/GetListByClass',
  TASK_BY_CLASS_WORKSHEET_CREATE_EDIT: '/api/Task/GetListForWorksheetCreateEdit',
  TASK_STATUS_CHANGE: '/api/Task/TaskStatusChange',
  TASK_STATUS_CHANGE_BULK: '/api/Task/TaskChangeStatusBulk',
  TASK_DOCUMENT_TYPE_CHANGE: '/api/Task/ChangeDocumentType',
  TASK_DOCUMENT_TYPE_CHANGE_BULK: '/api/Task/ChangeDocumentTypeBulk',
  TASK_OVER: '/api/Task/TaskOver',
  TASK_OVER_BULK: '/api/Task/TaskTakeOverBulk',
  TASK_ASSIGN: '/api/Task/TaskAssign',
  TASK_ASSIGN_BULK: '/api/Task/TaskAssignBulk',
  TASK_RELEASE: '/api/Task/TaskRelease',
  TASK_RELEASE_BULK: '/api/Task/TaskReleaseBulk',
  TASK_URGE: '/api/Task/TaskUrge',
  TASK_URGE_BULK: '/api/Task/TaskUrgeBulk',
  TASK_ASSET_COUNT: '/api/Task/GetCount',
  TASK_COUNT_NEW: '/api/Task/GetCountNew',
  TASK_COUNT_OPENED: '/api/Task/GetCountOpened',
  TASK_COUNT_SOLVED: '/api/Task/GetCountSolved',
  TASK_COUNT_DELAYED: '/api/Task/GetCountDelayed',
  TASK_RESCHEDULE: '/api/Task/ReSchedule',
  TASK_RESCHEDULE_BULK: '/api/Task/ReScheduleBulk',
  TASK_LIST_QUEUE: '/api/Task/GetListQueueForCapacityCategory',
  TASK_SPARE_PART_ISSUE: '/api/Task/SparePartIssue',
  TASK_SPARE_PART_RETURN: '/api/Task/SparePartReturn',
  // *** Task rating ***
  TASK_RATING_LIST: '/api/TaskRating/GetList',
  TASK_RATING_ITEM: '/api/TaskRating/GetItem',
  TASK_RATING_POST: '/api/TaskRating/CreateItem',
  TASK_RATING_PUT: '/api/TaskRating/UpdateItem',
  TASK_RATING_DELETE: '/api/TaskRating/DeleteItem',
  // *** TaskNotificationConfig ***
  TASK_NOTIFICATION_CONFIG_POST: '/api/TaskNotificationConfig/CreateItem',
  TASK_NOTIFICATION_CONFIG_PUT: '/api/TaskNotificationConfig/UpdateItem',
  TASK_NOTIFICATION_CONFIG_DELETE: '/api/TaskNotificationConfig/DeleteItem',
  TASK_NOTIFICATION_CONFIG_LIST: '/api/TaskNotificationConfig/GetList',
  TASK_NOTIFICATION_CONFIG_LIST_CURRENT_ASSET_CLASS:
    '/api/TaskNotificationConfig/GetListFromCurrentAssetClass',
  TASK_NOTIFICATION_CONFIG_ITEM: '/api/TaskNotificationConfig/GetItem',
  // *** Task Step ***
  TASK_STEP_REOPEN: '/api/TaskStep/Reopen',
  TASK_STEP_COMPLETE: '/api/TaskStep/Complete',
  TASK_STEP_CANCEL: '/api/TaskStep/Cancel',
  TASK_STEP_LIST: '/api/TaskStep/GetList',
  TASK_STEP_ITEM: '/api/TaskStep/GetItem',
  TASK_STEP_POST: '/api/TaskStep/CreateItem',
  TASK_STEP_PUT: '/api/TaskStep/UpdateItem',
  // *** Steps ***
  STEP_LIST: '/api/Step/GetList',
  STEP_LIST_BY_TASK: '/api/Step/GetListFilteredByTask',
  STEP_LIST_BY_CYCLE: '/api/Step/GetListFilteredByAssetCycle',
  STEP_ITEM: '/api/Step/GetItem',
  STEP_POST: '/api/Step/CreateItem',
  STEP_PUT: '/api/Step/UpdateItem',
  // *** Steps Group ***
  STEP_GROUP_LIST: '/api/StepGroup/GetList',
  STEP_GROUP_LIST_BY_CYCLE: '/api/StepGroup/GetListFilteredByAssetCycle',
  STEP_GROUP_ITEM: '/api/StepGroup/GetItem',
  STEP_GROUP_POST: '/api/StepGroup/CreateItem',
  STEP_GROUP_PUT: '/api/StepGroup/UpdateItem',
  // *** Steps Group Step ***
  STEP_GROUP_STEP_DELETE: '/api/StepGroupStep/DeleteItem',
  STEP_GROUP_STEP_LIST: '/api/StepGroupStep/GetList',
  STEP_GROUP_STEP_ITEM: '/api/StepGroupStep/GetItem',
  STEP_GROUP_STEP_POST: '/api/StepGroupStep/CreateItem',
  STEP_GROUP_STEP_PUT: '/api/StepGroupStep/UpdateItem',
  // *** Asset cycle step ***
  ASSET_CYCLE_STEP_LIST: '/api/AssetCycleStep/GetList',
  ASSET_CYCLE_STEP_ITEM: '/api/AssetCycleStep/GetItem',
  ASSET_CYCLE_STEP_POST: '/api/AssetCycleStep/CreateItem',
  ASSET_CYCLE_STEP_PUT: '/api/AssetCycleStep/UpdateItem',
  ASSET_CYCLE_STEP_DELETE: '/api/AssetCycleStep/DeleteItem',
  // *** Asset cycle step group ***
  ASSET_CYCLE_GET_ALL_STEPS: '/api/AssetCycle/GetAllSteps',
  ASSET_CYCLE_STEP_GROUP_LIST: '/api/AssetCycleStepGroup/GetList',
  ASSET_CYCLE_STEP_GROUP_ITEM: '/api/AssetCycleStepGroup/GetItem',
  ASSET_CYCLE_STEP_GROUP_POST: '/api/AssetCycleStepGroup/CreateItem',
  ASSET_CYCLE_STEP_GROUP_PUT: '/api/AssetCycleStepGroup/UpdateItem',
  ASSET_CYCLE_STEP_GROUP_DELETE: '/api/AssetCycleStepGroup/DeleteItem',

  // *** NotificationTemplate ***
  NOTIFICATION_TEMPLATE_LIST: '/api/NotificationTemplate/GetList',
  NOTIFICATION_TEMPLATE_ITEM: '/api/NotificationTemplate/GetItem',

  // *** TaskStatuses ***
  TASK_STATUS_LIST: '/api/TaskStatus/GetList',
  TASK_COMMENT_NEW: '/api/Task/AddComment',
  // *** Task priorities ***
  TASK_PRIORITY_LIST: '/api/TaskPriority/GetList',
  TASK_PROCESS_CONFIG: '/api/TaskProcessConfig/GetAvailableListByTask',
  TASK_PROCESS_CONFIG_ARRAY: '/api/TaskProcessConfig/GetAvailableListByTaskArray',
  TASK_PROCESS_CONFIG_LIST: '/api/TaskProcessConfig/GetList',
  TASK_PROCESS_CONFIG_LIST_CURRENT_ASSET_CLASS:
    '/api/TaskProcessConfig/GetListFromCurrentAssetClass',
  // *** Task history ***
  TASK_HISTORY_FILTERED_LIST: '/api/TaskHistory/GetFilteredList',
  TASK_HISTORY_LIST: '/api/TaskHistory/GetList',
  // *** CONFIG ***
  GET_CONFIG: '/api/Config/GetList',
  GET_CONFIG_IS_ENABLED: '/api/Config/IsEnabled',
  GET_CONFIG_HELP: '/api/Config/GetHelpForTerminal',
  IS_MODULE_SPARE_PART_ENABLED: '/api/Config/IsModuleSparePartEnabled',
  IS_MODULE_NFC_ENABLED: '/api/Config/IsModuleNfcEnabled',
  // *** TASK NAME
  TASK_NAME_FILTERED: '/api/TaskName/GetFilteredList',
  // *** TaskAssistancePanel ***
  TASK_ASSISTANCE_CREATE_TASK: '/api/TaskAssistancePanel/CreateTask',
  TASK_ASSISTANCE_PANEL_LIST: '/api/TaskAssistancePanel/GetList',
  TASK_ASSISTANCE_PANEL_LIST_BY_CLASS: '/api/TaskAssistancePanel/GetListByClass',
  TASK_ASSISTANCE_PANEL_ITEM: '/api/TaskAssistancePanel/GetItem',
  TASK_ASSISTANCE_PANEL_POST: '/api/TaskAssistancePanel/CreateItem',
  TASK_ASSISTANCE_PANEL_PUT: '/api/TaskAssistancePanel/UpdateItem',
  TASK_ASSISTANCE_PANEL_DELETE: '/api/TaskAssistancePanel/DeleteItem',
  // *** Task Parameters ***
  TASK_GET_PARAMETERS_FOR_COMPLETE_ASSET_CYCLE:
    '/api/Task/GetParametersForTaskCompleteWithAssetCycle',
  TASK_UPDATE_PARAMETERS_FOR_TASK_WITH_ASSET_CYCLE:
    '/api/Task/UpdateParametersForTaskWithAssetCycle',
  // *** WorkSheets ***
  WORKSHEET_LIST: '/api/WorkSheet/GetList',
  WORKSHEET_ITEM: '/api/WorkSheet/GetItem',
  WORKSHEET_PUT: '/api/WorkSheet/UpdateItem',
  WORKSHEET_POST: '/api/WorkSheet/CreateItem',
  WORKSHEET_POST2: '/api/WorkSheet/CreateItem2',
  WORKSHEET_LIST_FILTERED: '/api/WorkSheet/GetListFiltered',
  WORKSHEET_GET_OPENED_FOR_ASSET: '/api/WorkSheet/GetOpenedWorksheetForAsset',
  WORKSHEET_REPORT_REQUEST: '/api/WorkSheet/GetWorksheetReportRequest',
  GROUPED_WORKSHEET_REPORT_REQUEST: '/api/WorkSheet/GetWorksheetReport2Request',
  WORKSHEET_GET_COUNT_BY_TASK: '/api/WorkSheet/GetCount',
  WORKSHEET_POST_WITH_TASK: '/api/WorkSheet/CreateItemWithTask',
  // *** WorkSheetStatuses ***
  WORKSHEET_STATUS_LIST: '/api/WorkSheetStatus/GetList',
  // *** WorkSheet activity ***
  WORK_ACTIVITY_LIST: '/api/WorkActivity/GetList',
  WORK_ACTIVITY_LIST_BY_ASSET_CLASS: '/api/WorkActivity/GetListFilteredByAssetClass',
  WORK_ACTIVITY_POST: '/api/WorkActivity/CreateItem',
  WORK_ACTIVITY_PUT: '/api/WorkActivity/UpdateItem',
  WORK_ACTIVITY_ITEM: '/api/WorkActivity/GetItem',
  // *** WorkSheet activity group ***
  WORK_ACTIVITY_GROUP_LIST: '/api/WorkActivityGroup/GetList',
  WORK_ACTIVITY_GROUP_LIST_BY_CAPACITY: '/api/WorkActivityGroup/GetListFilteredByCapacityCategory',
  WORK_ACTIVITY_GROUP_LIST_BY_ASSET_CLASS: '/api/WorkActivityGroup/GetListFilteredByAssetClass',
  WORK_ACTIVITY_GROUP_POST: '/api/WorkActivityGroup/CreateItem',
  WORK_ACTIVITY_GROUP_PUT: '/api/WorkActivityGroup/UpdateItem',
  WORK_ACTIVITY_GROUP_ITEM: '/api/WorkActivityGroup/GetItem',
  WORK_ACTIVITY_GROUP_ASSIGNED_DOCUMENT_TYPES: '/api/WorkActivityGroup/GeAssignedDocumentTypes',
  WORK_ACTIVITY_GROUP_REMOVE_DOCUMENT_TYPE: '/api/WorkActivityGroup/RemoveFromDocumentType',
  WORK_ACTIVITY_GROUP_ADD_DOCUMENT_TYPE: '/api/WorkActivityGroup/AddAssignToDocumentType',

  // *** Common ***
  APP_INFO: '/api/Common/AppInfo',
  // *** QR Code ***
  QR_CODE_ITEM: '/api/QrCode/GenerateQrForTaskDetailRedirect',
  QR_CODE_WORKSHEET_REDIRECT: '/api/QrCode/GenerateQrForWorksheetCreateDetailRedirect',
  // *** NFC Tag ***
  NFC_TAG_POST: '/api/NfcTag/CreateItem',
  NFC_TAG_PUT: '/api/NfcTag/UpdateItem',
  NFC_TAG_DELETE: '/api/NfcTag/DeleteItem',
  NFC_TAG_ASSIGN_TO_ASSET: '/api/NfcTag/AssignToAsset',
  NFC_TAG_LIST: '/api/NfcTag/GetList',
  NFC_TAG_ITEM: '/api/NfcTag/GetItem',

  // *** TimePeriod ***
  TIME_PERIOD_ITEM: '/api/TimePeriod/GetItem',
  TIME_PERIOD_LIST: '/api/TimePeriod/GetList',
  TIME_PERIOD_TRY_CLOSE: '/api/TimePeriod/TryClosePeriod',
  TIME_PERIOD_CLOSE: '/api/TimePeriod/ClosePeriod',
  TIME_PERIOD_REOPEN: '/api/TimePeriod/ReopenPeriod',

  // *** Spare Parts ***
};
