import { Button, TextBox } from 'devextreme-react';
import React, { useState } from 'react';
import { putApi } from '../../../apiUtils';
import { useTranslation } from '../../../contexts/translation';
import { RowsStatus } from '../TasksBulkEditModal';
import './bulkFields.scss';

type Props = {
  title: string;
  paramName: string;
  submitUrl: string;
  ids: Array<number>;
  handleRowsStatus: (data: RowsStatus) => void;
  isRequired?: boolean;
};

export default function AssetsBulkEditTextField({
  title,
  paramName,
  submitUrl,
  ids,
  handleRowsStatus,
  isRequired = false,
}: Props) {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const { translate } = useTranslation();
  const transFormName = 'Bulk.Change';

  const onSubmit = async () => {
    const data = {
      assetIdArr: ids,
    };
    data[paramName] = selectedItem;
    await putApi({
      url: submitUrl,
      data,
      callAfterSuccess: (response) => {
        handleRowsStatus(response);
      },
    });
  };

  return (
    <div className="bulk-edit-field-container">
      <div className="bulk-edit-field-label">{title}</div>
      <TextBox
        className="bulk-edit-field-value"
        value={selectedItem}
        onValueChange={setSelectedItem}
        showClearButton
      />
      <Button
        className="bulk-edit-field-button"
        disabled={isRequired && selectedItem.trim().length === 0}
        text={translate!('Save changes', transFormName)}
        onClick={onSubmit}
        stylingMode="contained"
        type="default"
      />
    </div>
  );
}
