import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ResponsiblePersonDataGrid } from '../../../components/datagrid/ResponsiblePersonDatagrid';
import { Loader } from '../../../components/loader/Loader';
import { useBreadcrumbs } from '../../../contexts/breadcrumbsContext';
import { useTranslation } from '../../../contexts/translation';
import AssetResponsiblePersonReportFilter from '../../../forms/filter/assetResponsiblePersonReport/AssetResponsiblePersonReportFilter';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { ResponsiblePersonReportType } from '../../../types/responsiblePersonReport.types';
import { ReportType } from '../../../utils/reportTypeKeys';
import '../Reports.scss';

export function ResponsiblePersonReport() {
  const [data, setData] = useState<Array<ResponsiblePersonReportType> | null>([]);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { translate } = useTranslation();
  const transFormName = 'ResponsiblePersonReport';
  const dataGridRef = useRef<any>();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Responsible person report',
      steps: [{ name: 'Home', pathToCutCount: 1 }],
    });
    return () => {
      setValue(null);
    };
  }, []);

  if (!data) return <Loader />;

  return (
    <PageContentWrapper
      heading={isFilterVisible ? translate!('Responsible person report', transFormName) : ''}
    >
      <>
        <AssetResponsiblePersonReportFilter
          dataGridRef={dataGridRef}
          onDataChange={setData}
          onFilterChange={setSelectedFilter}
          report={ReportType.AssetResponsiblePerson}
          filterVisibility={isFilterVisible}
        />
        <ResponsiblePersonDataGrid
          data={data}
          dataGridRef={dataGridRef}
          heading={`${translate!('Responsible person report', transFormName)}${
            selectedFilter ? ` - ${selectedFilter}` : ''
          }`}
          filterVisibility={isFilterVisible}
          setFilterVisibility={setFilterVisible}
        />
      </>
    </PageContentWrapper>
  );
}
export default forwardRef(ResponsiblePersonReport);
