import { Popup, ScrollView } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import '../../assets/bulk-modal.scss';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from '../../contexts/translation';
import { paintRowByStatus } from '../../pages/Tasks/utils/paintRowByStatus';
import { UserRoles } from '../../types/applicationUser.types';
import { RowsStatus } from '../../types/rowsStatus.types';
import { API_URL } from '../../utils/apiUrl';
import { Loader } from '../loader/Loader';
import QrHiddenComponent from '../qr/QrHiddenComponent';
import { SisDataGrid } from '../table';
import AssetsBulkModalField from './assets/AssetsBulkEditSelectField';
import AssetsBulkEditTextField from './assets/AssetsBulkEditTextField';
import './AssetsBulkEditModal.scss';

type QrAssetData = {
  id: number;
  name: string | null;
  source: string;
};

type Props = {
  data: Array<any>;
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  columns: Array<any>;
  transFormNameColumns: string;
};

/**
 * Modalová komponent pro hromadné změny ve vybraných řádcích z datagridu
 */
export default function AssetsBulkEditModal({
  data,
  isVisible,
  mutate,
  onClose,
  columns,
  transFormNameColumns,
}: Props) {
  const [rowsStatus, setRowsStatus] = useState<RowsStatus>({ successArray: [], failedArray: [] });
  const { translate } = useTranslation();
  const transFormName = 'Bulk.Change';
  const transFormNameLabels = 'Asset.Update';
  const { assetClass } = useAssetClass();
  const { user } = useAuth();

  const [isPrintLoading, setPrintLoading] = useState<boolean>(false);
  const [qrCodes, setQrCodes] = useState<Array<QrAssetData>>([]);
  const canEdit = user?.roles.includes(UserRoles.AssetEdit);

  const prepareQrToPrint = async () => {
    setPrintLoading(true);
    const qrCodesData: Array<QrAssetData> = [];
    for (const asset of data) {
      const response = await fetch(`${API_URL.QR_CODE_WORKSHEET_REDIRECT}?assetId=${asset?.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 200) {
        const responseData = await response.blob();
        const fileUrl: string = URL.createObjectURL(responseData);
        qrCodesData.push({ id: asset?.id, name: asset?.name, source: fileUrl });
      }
    }
    setQrCodes(qrCodesData);
    handlePrint();
    setPrintLoading(false);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
  });

  const handleRowsChange = (rowsStatusData: any) => {
    mutate && mutate();
    setRowsStatus(rowsStatusData);
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Bulk changes', transFormName)}
      minWidth={'75%'}
      maxHeight={'75%'}
      wrapperAttr={{ class: 'modal-bulk-changes' }}
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={{
          text: translate!('Close', transFormName),
          icon: 'close',
          type: 'normal',
          onClick: onClose,
        }}
      />

      {canEdit && (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={{
            icon: 'print',
            type: 'default',
            text: translate!('Bulk print', transFormName),
            stylingMode: 'outlined',
            onClick: () => prepareQrToPrint(),
          }}
        />
      )}

      <div className="bulk-edit-form">
        <AssetsBulkModalField
          title={translate!('Parent asset', transFormNameLabels)}
          paramName="parentId"
          displayExpr="name"
          dataUrl={`${API_URL.ASSET_LIST_BY_CLASS_TOP_LEVEL}?classId=${assetClass}`}
          submitUrl={API_URL.ASSET_BULK_EDIT_PARENT}
          valueExpr="id"
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
        />
        <AssetsBulkModalField
          title={translate!('Status', transFormNameLabels)}
          paramName="statusId"
          displayExpr="nameTranslated"
          dataUrl={API_URL.ASSET_STATUS_LIST}
          submitUrl={API_URL.ASSET_BULK_EDIT_STATUS}
          valueExpr="id"
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
        />
        <AssetsBulkModalField
          title={translate!('Locality', transFormNameLabels)}
          paramName="locationId"
          displayExpr="name"
          dataUrl={API_URL.ASSET_LOCATION_LIST}
          submitUrl={API_URL.ASSET_BULK_EDIT_LOCATION}
          valueExpr="id"
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
        />
        <AssetsBulkEditTextField
          title={translate!('Owner name', transFormNameLabels)}
          paramName="ownerName"
          submitUrl={API_URL.ASSET_BULK_EDIT_OWNER}
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
          isRequired
        />
        <AssetsBulkEditTextField
          title={translate!('Centre', transFormNameLabels)}
          paramName="centre"
          submitUrl={API_URL.ASSET_BULK_EDIT_CENTRE}
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
        />
        <AssetsBulkEditTextField
          title={translate!('Building', transFormNameLabels)}
          paramName="building"
          submitUrl={API_URL.ASSET_BULK_EDIT_BUILDING}
          ids={data.map((item) => item.id)}
          handleRowsStatus={handleRowsChange}
        />
      </div>
      <ScrollView>
        <SisDataGrid
          data={data}
          keyExpr={'id'}
          transFormName={transFormNameColumns}
          columns={columns}
          columnChooser={false}
          allowColumnReordering={false}
          allowColumnResizing={false}
          pageSizeChangeVisible={true}
          allowHeaderFiltering={false}
          filterRow={false}
          onRowPrepared={(e) => paintRowByStatus(e, rowsStatus)}
        />
      </ScrollView>
      <div ref={componentRef}>
        {qrCodes.length > 0 &&
          qrCodes.map((qrCode, idx) => (
            <QrHiddenComponent
              key={idx}
              source={qrCode.source}
              assetId={qrCode?.id}
              assetName={qrCode?.name ?? ''}
            />
          ))}
        {isPrintLoading && <Loader />}
      </div>
    </Popup>
  );
}
