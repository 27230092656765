import Button from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import React, { useState } from 'react';

interface FileRenameInputProps {
  fileId: number;
  entityId: number;
  description: string;
  handleFileRename: Function;
  handleFileRenameCancel: Function;
}

/**
 * Komponenta pro změnu description souboru.
 * @param props
 * @constructor
 */
export function FileRenameInput(props: FileRenameInputProps) {
  const [description, setDescription] = useState(props.description);

  return (
    <>
      <TextArea value={description} onValueChanged={(e) => setDescription(e.value)} />
      <Button
        type="normal"
        stylingMode="outlined"
        icon="save"
        onClick={() => props.handleFileRename(props.fileId, props.entityId, description)}
      />
    </>
  );
}
