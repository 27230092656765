export interface Step {
  id: number;
  name: string;
  description: string;
  capacityId: number;
  capacityCategoryName: string;
  visible: boolean;
  createdBy: number;
  created: string;
  updatedBy: number;
  updated: string;
}

export interface CycleStep {
  id: number;
  stepId: number;
  stepName: string;
  frequency: number;
}

export interface StepInputs {
  id: number;
  name: string;
  description: string;
  capacityCategoryId: number;
  visible: boolean;
}

export interface TaskStep extends Step {
  status: TaskStepStatus;
  taskId: number;
  sortOrder: number;
  completed: number | null;
  completedByName: string | null;
}

export enum TaskStepStatus {
  OPEN = 'Open',
  COMPLETE = 'Complete',
  CANCELED = 'Canceled',
}
