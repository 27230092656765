import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategoryForm } from '../../forms/capacityCategoriesForm/CapacityCategoryForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetClass } from '../../types/assetClass.types';
import { CapacityCategoryInput } from '../../types/capacityCategory.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function CapacityCategoriesEdit() {
  const { translate } = useTranslation();
  const transFormName = 'CapacityCategory.Update';
  const { startLoading, stopLoading } = useLoading();
  const { id } = useParams<DetailPageParams>();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit capacity categories',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Capacity categories', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<CapacityCategoryInput>(
    `${API_URL.CAPACITY_CATEGORY_ITEM}?id=${id}`,
    fetcher,
  );

  // Načteme číselník - asset class
  const { data: dataAssetClasses, error: errorAssetClasses } = useSWR<AssetClass[]>(
    API_URL.ASSET_CLASS_LIST,
    fetcher,
  );

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.CAPACITY_CATEGORY_PUT,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!formData || !dataAssetClasses}
      error={error || errorAssetClasses}
      heading={translate!('Edit capacity category', transFormName)}
    >
      <CapacityCategoryForm
        onSubmit={onSubmit}
        formData={formData}
        dataAssetClasses={dataAssetClasses}
        isEdit
      />
    </PageContentWrapper>
  );
}
export default CapacityCategoriesEdit;
