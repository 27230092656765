import moment from 'moment';
import React from 'react';
import {
  BoolFilterValue,
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';

export const Columns = (
  translate,
  transFormName,
  handleEdit,
  isReadOnly,
  dateFormat,
  dateTimeFormat,
): SisDataGridColumns => [
  {
    dataField: 'parameterId',
    caption: 'Parameter',
    width: '23.75%',
    cellRender: (data) => data.row.data.parameterName,
    dataType: IDataType.string,
  },
  {
    dataField: 'unitId',
    caption: 'Unit',
    width: '23.75%',
    cellRender: (data) => data.row.data.unitName,
    dataType: IDataType.string,
  },
  {
    dataField: 'value',
    caption: 'Value',
    dataType: IDataType.string,
    cellRender: (data) => {
      if (data.row.data.valueInt) return data.row.data.valueInt;
      if (data.row.data.valueFloat) return data.row.data.valueFloat;
      if (data.row.data.valueDate) return moment(data.row.data.valueDate).format(dateFormat());
      if (data.row.data.valueDateTime)
        return moment(data.row.data.valueDateTime).format(dateTimeFormat());
      if (data.row.data.valueText) return data.row.data.valueText;
      if (data.row.data.valueBool === true) {
        return data.row.data.value ? 'True' : 'False';
      } else if (data.row.data.value) {
        return data.row.data.value;
      }
      return null;
    },
    width: '22%',
  },
  {
    dataField: 'visible',
    caption: 'Visible',
    dataType: IDataType.boolean,
    width: '7%',
    filterValue: BoolFilterValue.all,
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, isReadOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, isReadOnly, translate) => {
  return (
    !isReadOnly && (
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={() => handleEdit(cellData)}
      />
    )
  );
};
