import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SparePartsReportDataGrid } from '../../../components/datagrid/SparePartsReportDataGrid';
import { Loader } from '../../../components/loader/Loader';
import { useBreadcrumbs } from '../../../contexts/breadcrumbsContext';
import { useTranslation } from '../../../contexts/translation';
import SparePartReportFilter from '../../../forms/filter/sparePartReport/SparePartReportFilter';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { ReportsState } from '../../../types/locationState.types';
import { AssetSpareParts } from '../../../types/spareParts.types';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { ReportType } from '../../../utils/reportTypeKeys';
import '../Reports.scss';

export function SparePartsReport() {
  const [data, setData] = useState<Array<AssetSpareParts> | null>([]);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const { state }: { state: ReportsState | undefined } = useLocation();
  const transFormName = 'SparePart.List';

  const reportType = ReportType.SpareParts;

  const history = useHistory();
  // Načtení práv uživatele
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const { getActualJsonState, loadFilter, resetFilter, saveFilter } = useSaveDataGridFilter(
    reportType.key,
    dataGridRef,
  );

  const [datagridSetting] = useSessionStorage(STORAGE_KEYS.SPARE_PARTS_REPORT_DATAGRID_DESKTOP, {});
  useEffect(() => {
    if (state?.loadSettingFromSession) {
      loadFilter(datagridSetting);
    }
  }, [state]);

  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Spare Parts', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    if (resetFilterAction) {
      resetFilter();
      setResetFilterAction(false);
    }
    saveFilter();

    if (!dataGridRef.current || !dataGridRef.current.instance) return;
  }, [resetFilterAction]);

  if (!data) return <Loader />;

  return (
    <PageContentWrapper heading={isFilterVisible ? translate!('Spare Parts', transFormName) : ''}>
      <>
        <SparePartReportFilter
          dataGridRef={dataGridRef}
          onDataChange={setData}
          report={reportType}
          onFilterChange={setSelectedFilter}
          filterVisibility={isFilterVisible}
          loadDefaultSetting={!state?.loadSettingFromSession}
        />
        <SparePartsReportDataGrid
          dataGridRef={dataGridRef}
          data={data}
          resetFilter={() => setResetFilterAction(true)}
          userCanCreate={userHasPermissionToCreate}
          dataGridKey={reportType.key}
          heading={`${translate!('Spare Parts', transFormName)}${
            selectedFilter ? ` - ${selectedFilter}` : ''
          }`}
          filterVisibility={isFilterVisible}
          setFilterVisibility={setFilterVisible}
        />
      </>
    </PageContentWrapper>
  );
}
export default forwardRef(SparePartsReport);
