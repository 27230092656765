import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import { concat } from 'ramda';
import { RowsStatus } from '../../../components/modals/TasksBulkEditModal';

export const paintRowByStatus = (e: RowPreparedEvent, rowsStatus: RowsStatus) => {
  const { successArray, failedArray } = rowsStatus;
  const colorRow = successArray?.includes(e?.data?.id)
    ? ` success_row-border`
    : failedArray?.includes(e?.data?.id)
    ? ` danger_row-border`
    : ``;
  if (e.data) {
    if (e.rowElement && e.rowElement.className) {
      e.rowElement.className = concat<string, string>(e.rowElement.className, colorRow);
    }
  }
};
