import React, { useEffect, useState } from 'react';
import { SisDataGrid, SisDataGridColumns } from '../../components/table';
import { useTranslation } from '../../contexts/translation';
import { TaskSparePart } from '../../types/task.types';

type Props = {
  initialData: Array<TaskSparePart>;
  disableOnToolbarPreparing?: boolean;
  columnsWidth?: number[];
};

export const TaskSpareParts = ({ initialData, disableOnToolbarPreparing, columnsWidth }: Props) => {
  const transFormName = 'Task.SpareParts';
  const { translate } = useTranslation();
  const [data, setData] = useState<Array<TaskSparePart>>([]);

  useEffect(() => {
    if (!initialData) return;

    setData(
      initialData.map((item) => {
        return {
          ...item,
        };
      }),
    );
  }, [initialData]);

  const columns: SisDataGridColumns = [
    {
      dataField: 'prodId',
      caption: 'ID',
      width: columnsWidth ? columnsWidth[0] + '%' : '17%',
    },
    {
      dataField: 'name',
      caption: 'Name',
      width: columnsWidth ? columnsWidth[0] + '%' : '66%',
    },
    {
      dataField: 'qty',
      caption: 'Qty',
      width: columnsWidth ? columnsWidth[1] + '%' : '17%',
    },
  ];

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Spare parts issued', transFormName),
    });
  };

  return (
    <SisDataGrid
      onToolbarPreparing={!disableOnToolbarPreparing ? onToolbarPreparing : undefined}
      allowColumnReordering={false}
      allowColumnResizing={false}
      columnChooser={false}
      filterRow={false}
      data={data}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={columns}
    />
  );
};
