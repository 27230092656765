import { Button, CheckBox, Popup } from 'devextreme-react';
import Form, { GroupItem } from 'devextreme-react/form';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { getListApi } from '../../../apiUtils';
import GeneralReportFilterSelector from '../../../components/reportSelector/GeneralReportSelector';
import { FilterActionState } from '../../../components/reportSelector/ReportSelector';
import { useAssetClass } from '../../../contexts/assetClass';
import { useAuth } from '../../../contexts/auth';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { clear, getParameters, parseParameters, useField } from '../../../hooks/useField';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import GeneralReport from '../../../pages/Reports/General/GeneralReport';
import { FieldType } from '../../../types/field.types';
import { Rule } from '../../../types/rule.types';
import { DateFilter } from '../../../utils/dateFilters';
import FieldLabel from '../components/FieldLabel';
import FormField, { fieldTypeMatcher } from '../components/FormField';
import DeleteButton from '../components/ReportDeleteButton';
import '../ReportFilter.scss';

type Props = {
  dataGridRef: React.Ref<any>;
  onFilterChange: (filter: string | null, datagrid: any | null) => void;
  selectedFilter?: string | null;
  report: GeneralReport;
  filterVisibility: boolean;
  loadDefaultSetting: boolean;
  storageKey: string;
  transFormName: string;
  filterFields: Array<any>;
  onParamsValueChange: (params: any) => void;
  isDataPresent: boolean;
  heading?: string;
  setSelectedFilter: any;
};

/**
 * Komponenta představující filtr s report selectorem pro Report strojů (Asset)
 */
export default function GeneralReportFilter({
  dataGridRef,
  onFilterChange,
  selectedFilter,
  report,
  filterVisibility,
  loadDefaultSetting,
  storageKey,
  filterFields,
  onParamsValueChange,
  isDataPresent,
  heading,
  setSelectedFilter,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [dialogAction, setDialogAction] = useState<FilterActionState | null>(null);
  const [isDefault, setDefault] = useState<boolean>(false);
  const { startLoading, loading, stopLoading } = useLoading();
  const [reportFields, setReportFields] = useState<Array<any>>([]);
  const [fieldsDataSource, setFieldsDataSource] = useState<any>({});
  const [filterSubmitted, setFilterSubmitted] = useState<boolean>(false);

  const { translate } = useTranslation();
  const transFormRepSelector = 'Reports.ReportSelector';
  const transFormReportBar = 'Reports.Bar';
  // reference pro report selector na kterou jsou navázané funkce pro práci s filtry:
  //  - smazání, přidání / editace filtru
  const selectorRef = useRef<any>();

  const [filterSettingSession, setFilterSettingSession] = useSessionStorage(storageKey, {});

  const { assetClass } = useAssetClass();
  const { user } = useAuth();

  // dostupné proměnné pro interpolaci hodnot
  const availableVariables = {
    assetClass,
    currentUserId: user?.personalNumber,
  };

  // Funkce pro nalezení a nahrazení interpolovaných hodnot
  const interpolate = (str, values) => {
    return str.replace(/{([^{}]*)}/g, (a, b) => {
      const r = values[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  };

  useEffect(() => {
    prepareFields();
  }, []);

  /**
   * Funkce pro vyhledávání - po zadání hodnot ve filter baru
   */
  const onSearch = (e: FormEvent) => {
    startLoading();
    e.preventDefault();
    // @ts-ignore
    const params = parseParameters(fields.filter((item) => item.name !== null));
    onParamsValueChange(params);
    const filterParams = getParameters(fields);
    setFilterSettingSession(filterParams);
  };

  /**
   * Funkce pro smazání hodnot z fieldů - reset funkce
   */
  const clearDataAndFields = () => {
    clear(fields);
    onParamsValueChange([]);
  };

  /**
   * Definice fieldů pro filter bar + form na přidání nového / editaci stávajího filtru
   */

  const filterNameField = useField({
    name: 'name',
    fieldType: FieldType.Text,
    placeholder: translate!('Filter name', transFormRepSelector),
    rules: [{ type: Rule.RequiredRule, message: 'Filter name is required' }],
    maxLength: 50,
  });

  /** Funkce pro stažení dat pro jednotlivé fieldy (dropwodny) */
  const fetchFieldData = async (sourceUrl: string) => {
    return await getListApi<Array<any>>({
      url: sourceUrl,
      params: {},
    });
  };

  /** Funkce pro zpracování source API pro naplnění fieldů (dropdownů) */
  const prepareFields = async () => {
    const newData = {};
    const filteredFields = filterFields.filter((item) => {
      const type = fieldTypeMatcher(item.type);
      return type === FieldType.MultipleOptions || type === FieldType.SingleOptions;
    });
    for (const item of filteredFields) {
      newData[item.procedureParameterName] = await fetchFieldData(
        interpolate(item.sourceApi, availableVariables),
      ).then((r) => {
        if (item.sourceOrdering === null) return r;
        if (r && item.sourceOrdering) return sortArrByString(r, item.sourceOrdering);
      });
    }

    setFieldsDataSource(newData);
  };

  // seřadit pole podle stringu (hodnota sourceOrdering)
  const sortArrByString = (arr: any[], sortBy: string) => {
    // např. "levelId DESC;name ASC"
    // diakritika
    const locale = 'cs-CZ';
    let sortProperties = sortBy.split(';');
    arr.sort((a, b) => {
      for (let sortProp of sortProperties) {
        // Split --> získat field a order
        let field = sortProp.split(' ')[0];
        let order = sortProp.split(' ')[1].toLowerCase();
        const comparison = a[field].toString().localeCompare(b[field].toString(), locale, {
          numeric: typeof a[field] === 'number' ? true : false,
        });
        if (comparison !== 0) {
          return order === 'asc' ? comparison : -comparison;
        }
      }
      // equal
      return 0;
    });
    return arr;
  };

  const fields = filterFields.map((item) => {
    const fieldType = fieldTypeMatcher(item.type);

    if (fieldType === FieldType.MultipleOptions || fieldType === FieldType.SingleOptions) {
      return useField({
        name: item.procedureParameterName,
        defaultValue: item.defaultValue,
        fieldType,
        dataSource: fieldsDataSource[item.procedureParameterName] ?? [],
        keyExpr: item.valueName ?? '',
        displayExpr: item.textName ?? '',
        placeholder: item.name,
        rules: item.isRequired
          ? [{ type: Rule.RequiredRule, message: `${item.name} is required` }]
          : undefined,
      });
    }

    if (
      fieldType === FieldType.DateSelect ||
      fieldType === FieldType.DateSelectWithDiffField ||
      fieldType === FieldType.DateSelectEnd ||
      fieldType === FieldType.DateSelectWithDiffFieldEnd
    ) {
      return useField({
        name: item.procedureParameterName,
        defaultValue: item.defaultValue,
        fieldType: [FieldType.Date, fieldType],
        dataSource:
          fieldType === FieldType.DateSelect || fieldType === FieldType.DateSelectWithDiffField
            ? [
                DateFilter.PreviousYearStart,
                DateFilter.PreviousMonthStart,
                DateFilter.PreviousWeekStart,
                DateFilter.PreviousDayStart,
                DateFilter.CurrentDayStart,
                DateFilter.CurrentWeekStart,
                DateFilter.CurrentMonthStart,
                DateFilter.CurrentYearStart,
                DateFilter.NextDayStart,
                DateFilter.FollowingWeekStart,
                DateFilter.FollowingMonthStart,
                DateFilter.FollowingYearStart,
              ]
            : [
                DateFilter.PreviousYearEnd,
                DateFilter.PreviousMonthEnd,
                DateFilter.PreviousWeekEnd,
                DateFilter.PreviousDayEnd,
                DateFilter.CurrentDayEnd,
                DateFilter.CurrentWeekEnd,
                DateFilter.CurrentMonthEnd,
                DateFilter.CurrentYearEnd,
                DateFilter.NextDayEnd,
                DateFilter.FollowingWeekEnd,
                DateFilter.FollowingMonthEnd,
                DateFilter.FollowingYearEnd,
              ],
        keyExpr: item.valueName ?? '',
        displayExpr: item.textName ?? '',
        placeholder: item.name,
        rules: item.isRequired
          ? [{ type: Rule.RequiredRule, message: `${item.name} is required` }]
          : undefined,
      });
    }

    return useField({
      name: item.procedureParameterName,
      defaultValue: item.defaultValue,
      fieldType,
      dataSource: item.dataSource ?? [],
      keyExpr: item.keyExpr ?? '',
      displayExpr: item.displayExpr ?? '',
      placeholder: item.name,
      rules: item.isRequired
        ? [{ type: Rule.RequiredRule, message: `${item.name} is required` }]
        : undefined,
    });
  });

  useEffect(() => {
    if (selectedFilter) {
      filterNameField.setValue(selectedFilter);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (!isDataPresent || !filterSubmitted) return;
    onFilterDialogSubmit();
  }, [isDataPresent, filterSubmitted]);

  /**
   * Funkce pro zpracování formuláře při přidání nového / editaci stávajícího filtru
   */
  const onFilterDialogSubmit = async (e?: any) => {
    if (!isDataPresent) {
      setFilterSubmitted(true);
      onSearch(e);
    } else {
      selectorRef.current.saveOrEditFilter(dialogAction);
      setFilterSubmitted(false);
      // e.preventDefault();
    }
  };

  const handleDialogVisibility = (action: FilterActionState | null = null) => {
    setDialogAction(action);
    setIsModalVisible(!!action);
  };

  return (
    <div className="no-responsive-report-filter">
      <div className="no-responsive-report-filter-scrollable">
        <Toolbar>
          {' '}
          <Item location={'before'} html={heading} />
        </Toolbar>
        <div>
          <div
            className="report-selector-bar"
            style={{ display: filterVisibility ? 'block' : 'none' }}
          >
            <GeneralReportFilterSelector
              ref={selectorRef}
              filterName={filterNameField.value}
              fields={fields}
              dataGridRef={dataGridRef}
              onClear={clearDataAndFields}
              onFilterChange={onFilterChange}
              report={report}
              openDialog={handleDialogVisibility}
              fetchData={onParamsValueChange}
              isDefault={isDefault}
              setIsDefault={(value) => setDefault(value)}
              loadDefaultSetting={loadDefaultSetting}
              loadDefaultSettingStorageKey={storageKey}
            />
          </div>
          {isModalVisible && (
            <Popup
              visible={isModalVisible}
              dragEnabled={false}
              closeOnOutsideClick={true}
              onHiding={() => handleDialogVisibility(null)}
              showTitle={true}
              title={
                dialogAction === FilterActionState.EDIT
                  ? translate!('Edit selected filter', transFormRepSelector)
                  : translate!('Save a new filter', transFormRepSelector)
              }
              container=".dx-viewport"
              width="auto"
              height="auto"
            >
              {/* filter add/edit dialog */}
              <form onSubmit={onFilterDialogSubmit}>
                <Form colCount={3} className="fields-container">
                  <GroupItem colSpan={3} colCount={3}>
                    <GroupItem colSpan={fields.length !== 0 ? 1 : 3} colCount={3}>
                      <FieldLabel label={filterNameField.placeholder} />
                      <FormField field={filterNameField} />
                    </GroupItem>
                  </GroupItem>
                  <GroupItem colCount={3} colSpan={3}>
                    {fields.map((item, idx) => {
                      // @ts-ignore
                      if (item.fieldType === FieldType.BlankSpace) {
                        return <GroupItem key={idx} />;
                      }
                      // @ts-ignore
                      if (item.fieldType === FieldType.NewLine) {
                        return Array.from({ length: 3 - (idx % 3) }).map((e, index) => (
                          <GroupItem key={index} />
                        ));
                      }
                      return (
                        <GroupItem key={idx}>
                          {/* @ts-ignore */}
                          <FieldLabel label={item.placeholder} />
                          <FormField field={item} alt />
                        </GroupItem>
                      );
                    })}
                  </GroupItem>
                  <GroupItem colSpan={3} colCount={3}>
                    <GroupItem>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <CheckBox value={isDefault} onValueChanged={(e) => setDefault(e.value)} />{' '}
                        <div style={{ marginLeft: 8 }}>
                          {translate!('Is default', transFormRepSelector)}
                        </div>
                      </div>
                    </GroupItem>
                  </GroupItem>
                  <GroupItem colSpan={3} colCount={3} cssClass={'action-buttons-view'}>
                    {dialogAction === FilterActionState.EDIT && <DeleteButton ref={selectorRef} />}
                    <Button
                      className="button-spacing"
                      text={translate!('Save', transFormRepSelector)}
                      icon="save"
                      type="success"
                      useSubmitBehavior
                      disabled={loading}
                    />
                  </GroupItem>
                </Form>
              </form>
            </Popup>
          )}
        </div>
        <form onSubmit={onSearch} style={{ display: filterVisibility ? 'block' : 'none' }}>
          <Form colCount={7} className="popup-container" style={{ justify: 'center' }}>
            {fields.map((item, idx) => {
              // @ts-ignore
              if (item.fieldType === FieldType.BlankSpace) {
                return <GroupItem key={idx} />;
              }
              // @ts-ignore
              if (item.fieldType === FieldType.NewLine) {
                return Array.from({ length: 7 - (idx % 7) }).map((e, index) => (
                  <GroupItem key={index} />
                ));
              }
              return (
                <GroupItem key={idx}>
                  {/* @ts-ignore */}
                  <FieldLabel label={item.placeholder} />
                  <FormField field={item} />
                </GroupItem>
              );
            })}
            <GroupItem cssClass={'action-buttons-view'}>
              <Button
                className="button-spacing"
                type="success"
                text={translate!('Search', transFormReportBar)}
                useSubmitBehavior
                disabled={loading}
              />
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}
