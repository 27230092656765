import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi, putApi } from '../../apiUtils';
import { TaskAssistancePanelDataGrid } from '../../components/datagrid/TaskAssistancePanelDatagrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import {
  TaskAssistancePanel,
  TaskAssistancePanelInputs,
} from '../../types/taskAssistancePanel.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';

export interface TaskAssistancePanelFilterData {
  dateFrom: string | null;
  dateTo: string | null;
}

const defaultFilterData: TaskAssistancePanelFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
};

/**
 * Přehled entity TaskAssistancePanel.
 */
export default function TaskAssistancePanelDesktop() {
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();
  const { translate } = useTranslation();
  const transFormName = 'TaskAssistancePanel.List';
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<TaskAssistancePanel | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGrid = useRef<any>(null);
  // Nastavení filtru (mimo DataGrid) - ukládáme/načítáme do/z localStorage
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.TASKS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const {
    data: taskAssistancePanels,
    error: taskAssistancePanelsError,
    mutate: mutateTaskAssistancePanels,
  } = useSWR<TaskAssistancePanel[]>(
    `${API_URL.TASK_ASSISTANCE_PANEL_LIST_BY_CLASS}?classId=${assetClass}&visibleOnly=false`,
    fetcher,
  );

  const { data: documentTypes, error: errorDocumentTypes } = useSWR(
    `${API_URL.DOCUMENT_TYPE_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  const { data: capacityCategories, error: errorCapacityCategories } = useSWR(
    `${API_URL.CAPACITY_CATEGORY_LIST}${assetClass ? `?parentId=${assetClass}` : ''}`,
    fetcher,
  );

  const { data: assets, error: assetsError } = useSWR(
    `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
  );

  useEffect(() => {
    setValue({ pageName: 'Task assistance panels', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Task assistance panels', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.TASK_ASSISTANCE_PANEL_POST,
          data: { ..._data },
        });
        break;
      case DataGridActionType.Update:
        const oldData = taskAssistancePanels?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.TASK_ASSISTANCE_PANEL_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      /* case DataGridActionType.Remove:
        await deleteApi({
          url: API_URL.TASK_ASSISTANCE_PANEL_DELETE,
          id: key,
        });
        break; */
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateTaskAssistancePanels();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const handleRemove = (e: any) => {
    const keyId = e?.data?.id;
    keyId && handleApiCall({ type: DataGridActionType.Remove, key: keyId, data: null });
  };

  const onSubmit = (formData: TaskAssistancePanelInputs, type?: DataGridActionType) => {
    handleApiCall({ type, key: formData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateTaskAssistancePanels();
  };

  return (
    <PageContentWrapper
      error={
        taskAssistancePanelsError || errorCapacityCategories || errorDocumentTypes || assetsError
      }
      loading={!taskAssistancePanels || !capacityCategories || !documentTypes || !assets}
    >
      <TaskAssistancePanelDataGrid
        dataGridRef={dataGrid}
        data={taskAssistancePanels}
        mutateData={mutateTaskAssistancePanels}
        onToolbarPreparing={onToolbarPreparing}
        // defaultFilterData={filterData}
        onFilterChanged={handleFilterChanged}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        storageKey={STORAGE_KEYS.TASK_ASSISTANCE_PANELS_DATAGRID_DESKTOP.toString()}
        modalVisibility={isModalVisible}
        handleModalVisibility={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
        assets={assets}
        capacityCategories={capacityCategories}
        documentTypes={documentTypes}
      />
    </PageContentWrapper>
  );
}
