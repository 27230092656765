import React from 'react';
import { DataGridButton, IColumnType, IDataType } from '../../components/table';

export const Columns = (handleEdit, translate) => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset type id',
    width: '5%',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    caption: 'Name',
    width: '40%',
  },
  {
    dataType: IDataType.string,
    dataField: 'levelName',
    caption: 'Level',
    width: '40%',
    lookupPickBy: 'levelName',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={() => handleEdit(cellData)}
    />
  );
};
