import React from 'react';
import { dateTimeFormat, IDataType } from '../../../components/table';

export const Columns = (history, pathname, setPath) => [
  {
    dataField: 'taskCustomId',
    width: '20%',
    caption: 'Task custom id',
    lookupPickBy: 'taskCustomId',
    cellRender: (cellData) => {
      return (
        <span
          className="context-link"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
            // stisknuto levé tlačítko
            if (e.nativeEvent.button === 0)
              return history.push({
                pathname: `/tasks/edit/${cellData.data.taskId}`,
                state: { backRoute: pathname },
              });
            // nastavit cestu pro context menu
            setPath({
              path: `/tasks/edit/${cellData.data.taskId}`,
              backRoute: pathname,
            });
          }}
        >
          {cellData.data[cellData.column.dataField]}
        </span>
      );
    },
  },
  {
    dataField: 'taskDocumentTypeNameTranslated',
    caption: 'Document type',
    width: '20%',
    lookupPickBy: 'taskDocumentTypeNameTranslated',
  },
  {
    dataField: 'assetName',
    caption: 'Asset',
    width: '20%',
    lookupPickBy: 'assetName',
  },
  {
    dataField: 'assetParentName',
    caption: 'Parent',
    width: '20%',
    lookupPickBy: 'assetParentName',
  },
  {
    dataField: 'taskName',
    caption: 'Task',
    width: '20%',
    lookupPickBy: 'taskName',
  },
  {
    dataField: 'prodId',
    caption: 'Product Id',
    width: '10%',
    lookupPickBy: 'prodId',
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '20%',
  },
  {
    dataField: 'qty',
    caption: 'Qty',
    width: '10%',
  },
  {
    dataField: 'taskCompleted',
    caption: 'Time task completed',
    width: '10%',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'created',
    caption: 'Created',
    width: '10%',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
];
