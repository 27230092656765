import { ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import React from 'react';
import { useTranslation } from '../../contexts/translation';

export enum IAfterSubmitMode {
  NEW = 'new',
  CONTINUE = 'continue',
  LIST = 'list',
}

interface Props {
  handleClose: () => void;
  setWho?: (mode: IAfterSubmitMode) => void;
}
export const FormSuccessButtons = ({ handleClose, setWho }: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.Update';
  return (
    <GroupItem colCount={4} colSpan={2}>
      <ButtonItem horizontalAlignment={'left'}>
        <ButtonOptions
          text={translate!('Close', transFormName)}
          type={'normal'}
          width={'100%'}
          onClick={handleClose}
        />
      </ButtonItem>
      <ButtonItem horizontalAlignment={'left'}>
        <ButtonOptions
          width={'100%'}
          text={translate!('Save and add new', transFormName)}
          type={'success'}
          onClick={() => {
            setWho && setWho(IAfterSubmitMode.NEW);
          }}
          useSubmitBehavior
        />
      </ButtonItem>
      <ButtonItem horizontalAlignment={'left'}>
        <ButtonOptions
          width={'100%'}
          type={'success'}
          text={translate!('Save and continue', transFormName)}
          useSubmitBehavior
          onClick={() => {
            setWho && setWho(IAfterSubmitMode.CONTINUE);
          }}
        />
      </ButtonItem>
      <ButtonItem horizontalAlignment={'left'}>
        <ButtonOptions
          width={'100%'}
          type={'success'}
          text={translate!('Save and back to list', transFormName)}
          onClick={() => {
            setWho && setWho(IAfterSubmitMode.LIST);
          }}
          useSubmitBehavior
        />
      </ButtonItem>
    </GroupItem>
  );
};
