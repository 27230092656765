import { identity } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../../apiUtils';
import { ImagePreview } from '../../../components/fileManager/ImagePreview';
import { useAssetClass } from '../../../contexts/assetClass';
import { useLoading } from '../../../contexts/loadingContext';
import { AssetGeneralForm } from '../../../forms/assetGeneral/AssetGeneralForm';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Asset } from '../../../types/asset.types';
import { AssetLevel } from '../../../types/assetLevel.types';
import { AssetLocation } from '../../../types/assetLocation.types';
import { AssetStatus } from '../../../types/assetStatus.types';
import { AssetType } from '../../../types/assetType.type';
import { Attachement } from '../../../types/file.types';
import { DetailPageParams } from '../../../types/pageParams.types';
import { Person } from '../../../types/person.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { dateFieldOptionsWithSerialization } from '../../../utils/dates.types';
import { ITab, swrConfig } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
  setAssetDataChanged: (boolean) => void;
  isEdit: boolean;
  mutateData: () => void;
}

export function AssetTabGeneral({ isReadonly, active, mutateData }: Props) {
  const { assetClass } = useAssetClass();
  const [selectedAssetLevelId, setSelectedAssetLevelId] = useState<number | null>(null);
  const [typeId, setTypeId] = useState<number | null>(null);
  const [parentAssetId, setParentAsssetId] = useState<number | null>(null);
  const [formData, setFormData] = useState<any>(null);
  const { id } = useParams<DetailPageParams>();
  const { startLoading, stopLoading } = useLoading();

  // Načteme číselník - AssetType
  const {
    data: assetTypes,
    error: errorAssetType,
    mutate: mutateAssetTypes,
    isValidating: isValidatingAssetTypes,
  } = useSWR<AssetType[]>(API_URL.ASSET_TYPE_LIST, fetcher, swrConfig);
  // Načteme číselník - AssetStatus
  const {
    data: assetStatuses,
    error: errorAssetStatus,
    mutate: mutateAssetStatus,
    isValidating: isValidatingStatus,
  } = useSWR<AssetStatus[]>(API_URL.ASSET_STATUS_LIST, fetcher, swrConfig);
  // Načteme číselník - Persons
  const {
    data: persons,
    error: errorPersons,
    mutate: mutatePersons,
    isValidating: isValidatingPerson,
  } = useSWR<Person[]>(API_URL.PERSON_FROM_THIS_APP_LIST, fetcher, swrConfig);
  // Načteme číselník - AssetLevel
  const {
    data: levels,
    error: errorLevels,
    mutate: mutateLevels,
    isValidating: isValidatingLevels,
  } = useSWR<AssetLevel[]>(
    `${API_URL.ASSET_LEVEL_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
    swrConfig,
  );
  // Defaultní fotografie
  const {
    data: primaryImage,
    error: errorPrimaryImage,
    mutate: mutatePrimaryImage,
    isValidating: isValidatingImage,
  } = useSWR<Attachement>(`${API_URL.FILE_PRIMARY_IMAGE}?entity=Asset&entityId=${id}`, fetcher, {
    ...swrConfig,
    shouldRetryOnError: false,
  });
  // Načteme Asset
  const {
    data: asset,
    error: errorAsset,
    mutate: mutateAssetDetail,
  } = useSWR<Asset>(`${API_URL.ASSET_ITEM}?id=${id}`, fetcher, swrConfig);

  // Načteme číselník - AsseLocation
  const {
    data: assetLocations,
    error: errorAssetLocations,
    mutate: mutateAssetLocations,
    isValidating: isValidatingAssetLocations,
  } = useSWR<AssetLocation[]>(
    `${API_URL.ASSET_LOCATION_LIST}?visibleOnly=${true}`,
    fetcher,
    swrConfig,
  );

  // Načteme číselník - Asset - kvůli parentu
  const {
    data: assets,
    error: errorAssets,
    mutate: mutateAssets,
    isValidating: isValidatingAsset,
  } = useSWR<Asset[]>(
    typeId ? `${API_URL.ASSET_LIST_WITH_HIGHER_LEVEL}?typeId=${typeId}` : null,
    fetcher,
    swrConfig,
  );

  useEffect(() => {
    if (asset && levels) setSelectedAssetLevelId(asset.levelId);
    if (asset) {
      setTypeId(asset.typeId);
      setParentAsssetId(asset.parentAssetId);
    }
    setFormData({ ...asset, ...formData });
  }, [asset, levels]);

  const mutate = () => {
    mutateData();
    mutateAssetDetail();
    mutateAssets();
    mutateAssetTypes();
    mutateLevels();
    mutatePersons();
    mutatePrimaryImage();
    mutateAssetStatus();
    mutateAssetLocations();
  };

  useEffect(() => {
    if (active) {
      mutate();
    }
  }, [active]);

  const handleTypeChange = (e) => {
    setTypeId(e.value);
    setParentAsssetId(null);
  };

  const getPrimaryImage = () => {
    if (!primaryImage || errorPrimaryImage) return null;

    return <ImagePreview id={primaryImage.id} name={primaryImage.name} entityName={'Asset'} />;
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.ASSET_PUT,
      data: formData,
      callAfterSuccess: (d) => {
        isOk = true;
        mutate();
      },
    });
    stopLoading();
    return isOk;
  };

  const handleAssetLevelChange = (e) => {
    setSelectedAssetLevelId(e.value);
  };

  const getAssetTypes = (): AssetType[] => {
    if (selectedAssetLevelId === null || !assetTypes) return [];

    return assetTypes.filter((x) => x.levelId === selectedAssetLevelId);
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={
        errorLevels ||
        errorAssetStatus ||
        errorAssetType ||
        errorAsset ||
        errorPersons ||
        errorAssetLocations
      }
      loading={
        isValidatingAssetTypes ||
        isValidatingPerson ||
        isValidatingLevels ||
        isValidatingImage ||
        isValidatingStatus ||
        isValidatingAssetLocations
      }
    >
      <AssetGeneralForm
        formData={formData}
        defaultFormData={formData}
        isReadOnly={isReadonly}
        assets={assets}
        levels={levels}
        handleAssetLevelChange={handleAssetLevelChange}
        handleTypeChange={handleTypeChange}
        getPrimaryImage={getPrimaryImage}
        getAssetTypes={getAssetTypes}
        assetStatuses={assetStatuses}
        assetLocations={assetLocations}
        dateFieldOptions={dateFieldOptionsWithSerialization}
        persons={persons}
        handleCloseButton={identity}
        onSubmit={onSubmit}
        isEdit
      />
    </PageContentWrapper>
  );
}
