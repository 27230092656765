import React from 'react';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { TaskStep } from '../../../types/step.types';
import { ITab } from '../../Assets/AssetsEdit';
import { TaskSteps } from '../TaskSteps';

interface Props extends ITab {
  id: string;
  data: TaskStep[];
  errorStepList: any;
  taskStepListMutate: () => void;
}
export const TaskStepsTab = ({ id, data, errorStepList, taskStepListMutate }: Props) => {
  return (
    <PageContentWrapper error={errorStepList} loading={!data} noContentBlock noDxCard>
      <TaskSteps taskId={Number(id)} data={data} mutate={taskStepListMutate} platform="desktop" />
    </PageContentWrapper>
  );
};
