import { useCallback } from 'react';
import { useSessionStorage } from './useSessionStorage';

type Props = {
  rows: number[];
  onRowsChange: (rows: number[]) => void;
};

/**
 * Hook viditelnost clear buttonu při focusu:
 */
export const useRowSelectionSync = (key: string): Props => {
  const [lsValue, setLsValue] = useSessionStorage(key, []);

  const setValue = useCallback(
    (value) => {
      setLsValue(value);
    },
    [lsValue],
  );

  return {
    rows: lsValue,
    onRowsChange: (newRows: number[]) => setValue(newRows),
  };
};
