import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { StepGroupDataGrid } from '../../components/datagrid/StepGroupsDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { Step } from '../../types/step.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function StepGroups() {
  const history = useHistory();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Step groups',
      steps: [{ name: 'Home', pathToCutCount: 1 }],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data, error } = useSWR<Step[]>(
    `${API_URL.STEP_GROUP_LIST}?visibleOnly=false`,
    fetcher,
    swrConfig,
  );

  const handleEditButton = (e): void => {
    history.push(`/step-groups/edit/${e.row.data.id}`);
  };

  return (
    <PageContentWrapper error={error} loading={!data}>
      <StepGroupDataGrid data={data} handleEdit={handleEditButton} />
    </PageContentWrapper>
  );
}
export default StepGroups;
