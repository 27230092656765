// @ts-ignore
import axios from 'axios';
import { includes, isEmpty, isNil } from 'ramda';
import { useEffect, useReducer, useState } from 'react';

type Action<T> = {
  payload?: T | Array<T> | undefined;
  type: string;
};

function dataFetchReducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
}

type State<T> = {
  isLoading: boolean;
  isError: boolean;
  data: T;
};

function useDataApi<T>(
  initialUrl: string,
  id: string,
  param: string = 'id',
): [State<T>, () => void] {
  const [url] = useState<string>(initialUrl);

  const initState: State<T> = {
    isLoading: false,
    isError: false,
    data: {} as T,
  };
  const [state, dispatch] = useReducer(dataFetchReducer, initState);

  const fetchData = async () => {
    if (!id || isNil(id) || isEmpty(id)) return;
    dispatch({ type: 'FETCH_INIT' } as Action<T>);
    try {
      const result = await axios(`${url}?${param}=${id}`);
      dispatch({ type: 'FETCH_SUCCESS', payload: result.data } as Action<T>);
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE' } as Action<T>);
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!id) return;
      if (includes('undefined', url)) return;
      if (includes('null', url)) return;
      if (isMounted) {
        await fetchData();
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [url]);

  const reFetch = () => {
    fetchData();
  };

  return [state, reFetch];
}

export default useDataApi;
