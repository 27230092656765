import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi, putApi } from '../../../apiUtils';
import { AssetTabParameterDataGrid } from '../../../components/datagrid/AssetTabParameterDataGrid';
import { useAssetClass } from '../../../contexts/assetClass';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { DataGridActionType } from '../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../types/pageParams.types';
import { ParameterInputs } from '../../../types/parameter.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
}

export function AssetTabParameters({ isReadonly, active }: Props) {
  const { id } = useParams<DetailPageParams>();
  const { assetClass } = useAssetClass();
  const apiUrlAssetList = `${API_URL.ASSET_PARAMETER_LIST}?visibleOnly=false&parentId=${id}`;
  const apiUrlParamsList = `${API_URL.PARAMETER_LIST_FILTERED}?classId=${assetClass}&excludeAssignedToAsset=${id}&visibleOnly=false`;
  const [selectedUnitId, setSelectedUnitId] = useState<number>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<ParameterInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  // Načteme parametry assetu
  const {
    data: assetParameters,
    error: errorAssetParameters,
    mutate: mutateAssetParameters,
    isValidating: assetsParamsIsValidating,
  } = useSWR(apiUrlAssetList, fetcher, assetEditSwrConfig);
  // Načteme units a
  const {
    data: units,
    error: errorUnits,
    mutate: mutateUnits,
    isValidating: assetsUnitsIsValidating,
  } = useSWR(`${API_URL.UNIT_LIST}`, fetcher, assetEditSwrConfig);
  // Načteme číselník - Parameter
  const {
    data: parameters,
    error: errorParameters,
    mutate: mutateParameter,
    isValidating: paramsIsValidating,
  } = useSWR(apiUrlParamsList, fetcher, assetEditSwrConfig);

  useEffect(() => {
    if (active) {
      mutateParameter();
      mutateAssetParameters();
      mutateUnits();
    }
  }, [active]);

  const parameterChanged = (val) => {
    let selectedParam = parameters.find((p: any) => p.id === val.value);
    let tmpUnit = selectedParam?.baseUnit.id;
    if (tmpUnit) setSelectedUnitId(tmpUnit);
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_PARAMETER_POST,
          data: { ..._data, assetId: id },
        });
        break;
      case DataGridActionType.Update:
        const oldData = assetParameters?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_PARAMETER_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateAssetParameters();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const onSubmit = (formData: ParameterInputs, type?: DataGridActionType) => {
    handleApiCall({ type, key: selectedRowData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
    setSelectedUnitId(undefined);
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={errorAssetParameters || errorParameters || errorUnits}
      loading={paramsIsValidating || assetsParamsIsValidating || assetsUnitsIsValidating}
    >
      <AssetTabParameterDataGrid
        isReadOnly={isReadonly}
        parameterChanged={parameterChanged}
        parameters={parameters}
        units={units}
        selectedUnitId={selectedUnitId}
        data={assetParameters}
        modalVisibility={isModalVisible}
        handleEdit={handleEdit}
        handleNew={handleNew}
        handleClose={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
      />
    </PageContentWrapper>
  );
}
