import TabPanel from 'devextreme-react/tab-panel';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation, useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import useSWR, { SWRConfiguration } from 'swr';
import { Loader } from '../../components/loader/Loader';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useModuleEnabled } from '../../contexts/moduleContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { Asset } from '../../types/asset.types';
import { NfcTag } from '../../types/assignTagToAsset.types';
import { TabLocationState } from '../../types/locationState.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { AssetSpareParts } from '../../types/spareParts.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { AssetTabChildren } from './AssetTabs/AssetTabChildren';
import { AssetTabCycle } from './AssetTabs/AssetTabCycle';
import { AssetTabFiles } from './AssetTabs/AssetTabFiles';
import { AssetTabGeneral } from './AssetTabs/AssetTabGeneral';
import { AssetTabNfcTags } from './AssetTabs/AssetTabNfcTags';
import { AssetTabParameterMeasured } from './AssetTabs/AssetTabParameterMeasured';
import { AssetTabParameters } from './AssetTabs/AssetTabParameters';
import { AssetTabResponsiblePersons } from './AssetTabs/AssetTabResponsiblePersons';
import { AssetTabRevisionCycle } from './AssetTabs/AssetTabRevisionCycle';
import { AssetTabSpareParts } from './AssetTabs/AssetTabSpareParts';
import { AssetTabTasks } from './AssetTabs/AssetTabTasks';

export interface ITab {
  currentTab?: number;
  tab?: number;
  active: boolean;
}

export const swrConfigNoMount: SWRConfiguration = {
  revalidateOnMount: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 0,
};
export const swrConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 0,
};

export const assetEditSwrConfig = {
  revalidateOnMount: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 0,
};

export function AssetsEdit() {
  const { translate } = useTranslation();
  const { isSparePartEnabled, isNfcEnabled } = useModuleEnabled();
  const transFormName = 'Asset.Tabs';
  const { id } = useParams<DetailPageParams>();
  const { state }: { state: TabLocationState | undefined } = useLocation();
  const { user } = useAuth();
  const filesEntity = 'Asset';
  const canEdit = user?.roles.includes(UserRoles.AssetEdit);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [assetDataChanged, setAssetDataChanged] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(state?.defaultTab ?? 0);
  const { assetClass } = useAssetClass();
  // const [asset] = useItemDataApi<Asset>(API_URL.ASSET_ITEM, id);
  const { setValue } = useBreadcrumbs();

  const lastLocation = useLastLocation();
  const location = useLocation();

  useEffect(() => {
    setValue({
      pageName: 'Edit asset',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Assets', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const {
    data: asset,
    error: errorAsset,
    mutate: mutateAsset,
  } = useSWR<Asset>(`${API_URL.ASSET_ITEM}?id=${id}`, fetcher, swrConfig);

  const {
    data: spareParts,
    error: errorSpareParts,
    mutate: mutateSpareParts,
  } = useSWR<AssetSpareParts[]>(
    isSparePartEnabled
      ? `${API_URL.SPARE_PART_ISSUED_LIST_AGGREGATED_FOR_ASSET}?assetId=${id}`
      : null,
    fetcher,
    swrConfig,
  );

  const {
    data: nfcTags,
    error: errorNfcTags,
    mutate: mutateNfcTags,
  } = useSWR<NfcTag[]>(
    isNfcEnabled ? `${API_URL.NFC_TAG_LIST}?parentId=${id}` : null,
    fetcher,
    swrConfig,
  );

  // Counters
  const { data: assetChildCount } = useSWR<number>(
    `${API_URL.ASSET_COUNT}?parentId=${id}`,
    fetcher,
  );
  const { data: filesCount } = useSWR<number>(
    `${API_URL.FILE_COUNT}?entity=${filesEntity}&entityId=${id}`,
    fetcher,
  );
  const { data: assetParametersCount } = useSWR<number>(
    `${API_URL.ASSET_PARAMETER_COUNT}?parentId=${id}`,
    fetcher,
  );
  const { data: assetCyclesCount } = useSWR<number>(
    `${API_URL.ASSET_CYCLE_COMMON_COUNT}?parentId=${id}`,
    fetcher,
  );
  const { data: assetRevisionCyclesCount } = useSWR<number>(
    `${API_URL.ASSET_CYCLE_REVISION_COUNT}?parentId=${id}`,
    fetcher,
  );
  const { data: tasksCount } = useSWR<number>(`${API_URL.TASK_ASSET_COUNT}?assetId=${id}`, fetcher);
  const { data: assetParametersMeasuredCount } = useSWR<number>(
    `${API_URL.ASSET_PARAMETER_MEASURED_COUNT}?assetId=${id}`,
    fetcher,
  );
  const { data: assetResponsiblePersonsCount } = useSWR<number>(
    `${API_URL.ASSET_RESPONSIBLE_PERSON_COUNT}?parentId=${id}`,
    fetcher,
  );

  // otevřeno v nové záložce
  useEffect(() => {
    // nastavení current tab z session storage
    const hasPreviousLocation = lastLocation?.pathname !== location.pathname;
    const valueFromStorage = localStorage.getItem(STORAGE_KEYS.CONTEXT_STATE);
    if (hasPreviousLocation && lastLocation?.pathname !== '/login')
      return localStorage.removeItem(STORAGE_KEYS.CONTEXT_STATE);
    if (!valueFromStorage || valueFromStorage === 'null' || hasPreviousLocation) return;
    const obj = valueFromStorage ? JSON.parse(valueFromStorage) : null;
    if (obj?.tab) setCurrentTab(obj.tab);
  }, [lastLocation]);

  const tabs = [
    {
      title: translate!('General data', transFormName),
      index: 0,
      component: equals(currentTab, 0) && (
        <AssetTabGeneral
          isEdit
          isReadonly={!canEdit}
          setAssetDataChanged={setAssetDataChanged}
          active={equals(currentTab, 0)}
          currentTab={currentTab}
          tab={0}
          mutateData={mutateAsset}
        />
      ),
    },
    {
      title: `${translate!('Child assets', transFormName)} (${assetChildCount ?? 0})`,
      index: 1,
      component: equals(currentTab, 1) && (
        <AssetTabChildren
          isReadonly={!canEdit}
          assetClassId={assetClass}
          assetId={asset?.id}
          active={equals(currentTab, 1)}
          currentTab={currentTab}
          tab={1}
        />
      ),
    },
    {
      title: `${translate!('Files', transFormName)} (${filesCount ?? 0})`,
      index: 2,
      component: equals(currentTab, 2) && (
        <AssetTabFiles
          isReadonly={!canEdit}
          currentTab={currentTab}
          tab={2}
          active={equals(currentTab, 2)}
        />
      ),
    },
    {
      title: `${translate!('Parameters', transFormName)} (${assetParametersCount ?? 0})`,
      index: 3,
      component: equals(currentTab, 3) && (
        <AssetTabParameters
          isReadonly={!canEdit}
          currentTab={currentTab}
          tab={3}
          active={equals(currentTab, 3)}
        />
      ),
    },
    {
      title: `${translate!('Cycles', transFormName)} (${assetCyclesCount ?? 0})`,
      index: 4,
      component: equals(currentTab, 4) && (
        <AssetTabCycle
          isReadonly={!canEdit}
          currentTab={currentTab}
          tab={4}
          active={equals(currentTab, 4)}
        />
      ),
    },
    {
      title: `${translate!('Revision cycles', transFormName)} (${assetRevisionCyclesCount ?? 0})`,
      index: 5,
      component: equals(currentTab, 5) && (
        <AssetTabRevisionCycle
          isReadonly={!canEdit}
          currentTab={currentTab}
          tab={5}
          active={equals(currentTab, 5)}
        />
      ),
    },
    {
      title: `${translate!('Measuring', transFormName)} (${assetParametersMeasuredCount ?? 0})`,
      index: 6,
      component: equals(currentTab, 6) && (
        <AssetTabParameterMeasured
          isReadonly={!canEdit}
          currentTab={currentTab}
          tab={6}
          active={equals(currentTab, 6)}
        />
      ),
    },
    {
      title: `${translate!('Responsible persons', transFormName)} (${
        assetResponsiblePersonsCount ?? 0
      })`,
      index: 7,
      component: equals(currentTab, 7) && (
        <AssetTabResponsiblePersons
          isReadonly={!canEdit}
          active={equals(currentTab, 7)}
          currentTab={currentTab}
          tab={7}
        />
      ),
    },
    {
      title: `${translate!('Tasks', transFormName)} (${tasksCount})`,
      index: 8,
      component: equals(currentTab, 8) && (
        <AssetTabTasks
          isReadonly={!canEdit}
          active={equals(currentTab, 8)}
          currentTab={currentTab}
          tab={8}
        />
      ),
    },
    {
      visible: isSparePartEnabled,
      title: `${translate!('Spare parts', transFormName)} (${spareParts?.length})`,
      index: 8,
      component: equals(currentTab, 9) && spareParts && (
        <AssetTabSpareParts
          active={equals(currentTab, 9)}
          currentTab={currentTab}
          spareParts={spareParts}
          mutateSpareParts={mutateSpareParts}
          tab={8}
        />
      ),
    },
    {
      visible: isNfcEnabled,
      title: `${translate!('Tags', transFormName)} (${nfcTags?.length})`,
      index: 8,
      component: equals(currentTab, 10) && nfcTags && (
        <AssetTabNfcTags
          active={equals(currentTab, 10)}
          currentTab={currentTab}
          nfcTags={nfcTags}
          mutateNfcTags={mutateNfcTags}
          tab={10}
          isReadOnly={!canEdit}
        />
      ),
    },
  ];

  if (!asset || (!spareParts && isSparePartEnabled)) return <Loader />;
  return (
    <>
      <PageContentWrapper heading={`${id}: ${asset?.name}`} loading={!asset} error={errorAsset}>
        <TabPanel
          selectedIndex={currentTab}
          dataSource={tabs}
          itemRender={(a) => a.component}
          activeStateEnabled={true}
          onTitleClick={(e: any) => setCurrentTab(e.itemIndex)}
          deferRendering={false}
          focusStateEnabled={false}
        />
      </PageContentWrapper>
      <Prompt
        // smazat local storage při opuštění stránky
        message={(location, action) => {
          console.log(location, action);
          if (action === 'PUSH') localStorage.removeItem(STORAGE_KEYS.CONTEXT_STATE);
          return true;
        }}
      />
    </>
  );
}
export default AssetsEdit;
