import { Form, Popup } from 'devextreme-react';
import { ButtonItem, ButtonOptions, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { DocumentType } from '../../types/documentType.types';
import './EditCreateModal.scss';
import './TasksBulkEditModal.scss';
type Props = {
  data: any;
  capacityCategories: CapacityCategory[];
  revisionCategories?: any[];
  documentTypes?: DocumentType[];
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
  transFormName: string;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Asset cycle v datagridu
 */
export default function AssetCycleModal({
  isVisible,
  capacityCategories,
  revisionCategories,
  data,
  actionType,
  onClose,
  onSubmit,
  transFormName,
  documentTypes,
}: Props) {
  const { loading } = useLoading();
  const { translate } = useTranslation();
  const formRef = useRef<Form>(null);
  const [formData, setFormData] = useState<any>({
    ...data,
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });

  useEffect(() => {
    setFormData({
      ...data,
      ...(actionType === DataGridActionType.Insert && { visible: true }),
    });
  }, []);

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset cycles', transFormName)}
      width={'auto'}
      minWidth={'40vw'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <GroupItem>
              <SimpleItem dataField="name" editorType="dxTextBox" isRequired={true}>
                <Label text={translate!('Name', transFormName)} />
              </SimpleItem>
            </GroupItem>
            <SimpleItem
              dataField="capacityCategoryId"
              editorType="dxSelectBox"
              editorOptions={{
                items: capacityCategories,
                valueExpr: 'id',
                displayExpr: 'name',
              }}
              isRequired={true}
            >
              <Label text={translate!('Capacity category', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={revisionCategories !== undefined}>
            <SimpleItem
              dataField="revisionCategoryId"
              editorType="dxSelectBox"
              editorOptions={{
                items: revisionCategories,
                valueExpr: 'id',
                displayExpr: 'name',
              }}
            >
              <Label text={translate!('Revision category', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={revisionCategories !== undefined}>
            <SimpleItem dataField="revisionStandard" editorType="dxTextBox">
              <Label text={translate!('Revision standard', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={documentTypes !== undefined}>
            <SimpleItem
              dataField="documentTypeId"
              editorType="dxSelectBox"
              editorOptions={{
                items: documentTypes,
                valueExpr: 'id',
                displayExpr: 'nameTranslated',
              }}
            >
              <Label text={translate!('Document type', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              editorOptions={{
                height: 80,
                rows: 4,
              }}
            >
              <Label text={translate!('Description', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="visible" editorType="dxCheckBox">
              <Label text={translate!('Visible', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={20}>
            <GroupItem colSpan={14}></GroupItem>
            <ButtonItem colSpan={2}>
              <ButtonOptions
                disabled={loading}
                type={'success'}
                text={translate!('Save', '')}
                width={'100%'}
                useSubmitBehavior
              />
            </ButtonItem>
            <GroupItem colSpan={1}></GroupItem>
            <ButtonItem colSpan={2}>
              <ButtonOptions
                type={'normal'}
                text={translate!('Close', '')}
                width={'100%'}
                onClick={onClose}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
