import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { equals } from 'ramda';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationUser } from '../../types/applicationUser.types';
import './UserPanel.scss';

export enum MenuMode {
  CONTEXT = 'context',
  LIST = 'list',
}

interface Props {
  user: ApplicationUser | null | undefined;
  signOut: (() => Promise<void>) | undefined;
  menuMode: MenuMode;
}

export default function UserPanel({ menuMode, user, signOut }: Props) {
  const history = useHistory();

  function navigateToProfile() {
    history.push('/profile');
  }

  const menuItems = useMemo(
    () => [
      {
        text: 'Profile',
        icon: 'user',
        onClick: navigateToProfile,
      },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: signOut,
      },
    ],
    [signOut],
  );

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              // background: `url(${user.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover',
            }}
            className={'user-image'}
          />
        </div>
        <div className={'full-name'}>{user?.fullName}</div>
      </div>

      {equals(menuMode, MenuMode.CONTEXT) && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'top', y: 'center' }} at={{ x: 'top', y: 'center' }} />
        </ContextMenu>
      )}
      {equals(menuMode, MenuMode.LIST) && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
