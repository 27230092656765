import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from './contexts/auth';
import { BreadcrumbsProvider } from './contexts/breadcrumbsContext';
import { LoadingProvider } from './contexts/loadingContext';
import { withNavigationWatcher } from './contexts/navigation';
import { useRouting } from './contexts/routingContext';
import { useTranslation } from './contexts/translation';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts/sideNavInnerToolbar/SideNavInnerToolbar';
import { RouteType, StaticRouteType } from './types/route.types';
import routes from './utils/appRoutes';
import { userHasAccess } from './utils/userHasAccess';

export function Content() {
  const [routesArray, setRoutesArray] = useState<Array<any>>(routes);
  const { user, entryUrl } = useAuth();
  const { translate } = useTranslation();
  const transFormName = 'Menu';
  const { routes: dynamicRoutes } = useRouting();

  useEffect(() => {
    const parsedRoutes: Array<string> = [],
      diff: Array<StaticRouteType> = [],
      cleanup: Array<StaticRouteType> = [];
    dynamicRoutes.forEach((item: RouteType) => {
      if (item?.items) {
        item.items.forEach((subitem: RouteType) => {
          parsedRoutes.push(subitem.path ?? '');
        });
      } else {
        parsedRoutes.push(item.path ?? '');
      }
    });

    parsedRoutes.forEach((item: string) => {
      const found = routes.find((x) => x.path === item);
      if (!found) {
        const included = routes.find((x) => {
          const subst = x.path.split('/:')[0];
          return item.includes(subst);
        });
        diff.push({ ...included, path: item });
        included && cleanup.push(included);
      }
    });

    let newRoutes = [
      ...routesArray.filter((item) => {
        return !cleanup.includes(item);
      }),
    ];

    const cleanupReplacement = Array.from(new Set(cleanup)).map((item) => {
      return { ...item, path: item.path.substring(0, item.path.lastIndexOf('/:')) };
    });

    newRoutes.push(...diff);

    // newRoutes = newRoutes.map((x) => {
    //   let test = x;
    //   // if (test.path === '') test.path = '/workSheets';
    //   console.log(test);

    //   return test;
    // });

    // newRoutes = [...newRoutes, {}];

    let test = Array.from(
      new Set([
        ...newRoutes.filter(
          (routeItem: StaticRouteType) =>
            !cleanupReplacement.some(
              (cleanupItem: StaticRouteType) => routeItem.path === cleanupItem.path,
            ),
        ),
        ...cleanupReplacement,
      ]),
    );

    test = test.map((x) => {
      if (x.path === '') x.path = '/workSheets';
      if (x.path === '/workSheets/new/:assetId') x.path = '/workSheets/new/:assetId/:taskId';

      return x;
    });

    setRoutesArray([...test.filter((item) => !item.path.includes('/generalReports/:id'))]);
  }, []);

  return (
    <>
      <BreadcrumbsProvider>
        <SideNavBarLayout title={translate!('Workflow', transFormName)}>
          <Switch>
            <LoadingProvider>
              {routesArray.map(({ path, component, restrictedAccess }) => {
                if (!restrictedAccess || userHasAccess(user?.roles, restrictedAccess))
                  return (
                    <Route
                      exact
                      key={path}
                      path={path}
                      component={withNavigationWatcher(component)}
                    />
                  );
                else return null;
              })}
              <Redirect to={entryUrl || '/home'} />
            </LoadingProvider>
          </Switch>
        </SideNavBarLayout>
      </BreadcrumbsProvider>
    </>
  );
}
