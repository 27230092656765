import * as icons from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popup } from 'devextreme-react';
import Form, {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import { formatDate } from 'devextreme/localization';
import moment from 'moment';
import { toLower } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { getListApi, postApiList, putApi } from '../../apiUtils';
import { TaskParameterWithAssetCycleDataGrid } from '../../components/datagrid/TaskParameterWithAssetCycleDataGrid';
import { Loader } from '../../components/loader/Loader';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import {
  TaskParameterAssetCycle,
  TaskParameterAssetCycleUpdate,
} from '../../types/parameter.types';
import { TaskInputs, TaskTerminalInputs } from '../../types/task.types';
import { TaskActionTypes } from '../../types/taskActionTypes';
import { TaskRating, TaskRatings } from '../../types/taskRating.types';
import { TaskStatus } from '../../types/TaskStatus';
import { API_URL } from '../../utils/apiUrl';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import './TaskChangeStatus.scss';
import { TaskTerminalInputsNew } from './Terminal/TasksTerminalForm';

interface Props {
  task?: TaskInputs | TaskTerminalInputs | TaskTerminalInputsNew | null;
  mutate?: () => void;
  responsiblePersons: any;
  isTerminal?: boolean;
  isMobile?: boolean;
  showCurrentStatus?: boolean;
  size?: string;
  statuses: any;
  mutateStatuses: any;
  isOnlyButtons?: boolean;
  poupWidth: number;
  isBulk?: boolean;
  tasks?: Array<TaskInputs | TaskTerminalInputs | TaskTerminalInputsNew> | null;
  rowStatus?: (arg) => void;
  hideSuccessNotifications?: boolean;
  isAutoSubmitLastAction?: boolean;
  isEditForm?: boolean;
  oldRequiredDate?: Date | null | string;
  customValidationFunction?: () => Promise<boolean>;
  taskRatings?: TaskRatings;
}

export const TaskChangeStatus = ({
  task,
  mutate,
  responsiblePersons,
  isTerminal,
  isMobile,
  showCurrentStatus,
  size,
  statuses,
  mutateStatuses,
  isOnlyButtons,
  poupWidth,
  isBulk = false,
  tasks,
  rowStatus,
  hideSuccessNotifications,
  isAutoSubmitLastAction,
  isEditForm,
  oldRequiredDate,
  customValidationFunction,
  taskRatings,
}: Props) => {
  const { translate } = useTranslation();
  const { loading, startLoading, stopLoading } = useLoading();
  const transFormName = 'Task.Update';

  // Přeplánování
  const [rescheduleModalVisible, setRescheduleModalVisible] = useState(false);
  const [isRescheduleCommentRequired, setRescheduleCommentRequired] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState<Date | null>(null);
  // Přiřazení
  const [assignPerson, setAssingPerson] = useState<number | null>(null);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  // Samostatný komentář
  const [comment, setComment] = useState<null | string>(null);
  const [commentActionData, setCommentActionData] = useState<any>({
    ratingId: -1,
  });
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [ratingIconSelected, setRatingIconSelected] = useState<number | null>(null);
  // Změna document tyspe
  const [availableDocTypeChanges, setAvailableDocTypeChanges] = useState<Array<TaskStatus>>([]);
  const [selectedDocTypeChange, setSelectedDocTypeChange] = useState<TaskStatus | null>(null);
  const [docTypeChangeModalVisible, setDocTypeChangeModalVisible] = useState(false);
  // parameters AssetCycle
  const [isValid, setIsValid] = useState<boolean>(true);
  const [parametersForTaskComplete, setParametersForTaskComplete] = useState<
    TaskParameterAssetCycleUpdate[] | null
  >(null);
  const [taskParametersAssetCycle, setTaskParametersAssetCycle] = useState<
    TaskParameterAssetCycle[] | null
  >(null);

  const commentsFormRef = useRef<Form>(null);
  const assignFormRef = useRef<Form>(null);
  const taskIdArr = tasks?.map((item) => item.id);

  const inputCss =
    (isMobile ? 'mobile' : isTerminal ? 'terminal' : 'desktop') + '-action-modal-input';
  const buttonCss =
    (isMobile ? 'mobile' : isTerminal ? 'terminal' : 'desktop') + '-action-modal-button';
  const iconsCss =
    (isMobile ? 'mobile' : isTerminal ? 'terminal' : 'desktop') + '-action-modal-icon';

  useEffect(() => {
    setAvailableDocTypeChanges(
      statuses?.filter(
        (status: TaskStatus) => status.actionId === TaskActionTypes.DOCUMENT_TYPE_CHANGE,
      ),
    );
  }, []);

  // Automatické provedené poslední akce
  useEffect(() => {
    if (isAutoSubmitLastAction && statuses.length === 1)
      handleStateButtonClicked(
        statuses[0].actionId,
        statuses[0].statusToId,
        statuses[0].requiredComment,
        statuses[0].requiredRating,
      );
  }, []);

  // Načtení parametrů v případě, že assetCycleRunId !== null
  const getParametersForTaskCompleteWithAssetCycle = async () => {
    if (!task || task.assetCycleRunId === null) return;
    await getListApi<Array<TaskParameterAssetCycle>>({
      url: `${API_URL.TASK_GET_PARAMETERS_FOR_COMPLETE_ASSET_CYCLE}?taskId=${task.id}`,
      params: {},
      callAfterSuccess: (r) => {
        setTaskParametersAssetCycle(r);
      },
    });
  };

  const finishTask = async () => {
    if (!commentActionData) return;
    startLoading();
    try {
      await postApiList({
        url: API_URL.TASK_UPDATE_PARAMETERS_FOR_TASK_WITH_ASSET_CYCLE,
        data: parametersForTaskComplete,
        callAfterSuccess: () =>
          changeStatus({
            ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
            newStatusId: commentActionData.newStatusId,
            actionId: commentActionData.actionId,
            comment: '',
          }).then(() => {
            stopLoading();
          }),
        callAfterError: () => {
          stopLoading();
        },
      });
    } catch (error) {}
  };

  const changeStatus = async (data) => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_STATUS_CHANGE_BULK : API_URL.TASK_STATUS_CHANGE,
      data,
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const changeStatusWithComment = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    if (!commentActionData) return;
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_STATUS_CHANGE_BULK : API_URL.TASK_STATUS_CHANGE,
      data: {
        ...commentActionData,
        comment: comment,
        ratingId: ratingIconSelected,
      },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
        setCommentModalVisible(false);
        setComment('');
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const changeDocumentType = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }

    const data = {
      ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
      newDocumentTypeId: selectedDocTypeChange?.documentTypeToId,
      comment,
    };
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_DOCUMENT_TYPE_CHANGE_BULK : API_URL.TASK_DOCUMENT_TYPE_CHANGE,
      data,
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
        setComment('');
        setDocTypeChangeModalVisible(false);
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const take = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }

    startLoading();

    await putApi({
      url: isBulk ? API_URL.TASK_OVER_BULK : API_URL.TASK_OVER,
      data: { ...(isBulk ? { taskIdArr } : { taskId: task?.id }) },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const assign = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_ASSIGN_BULK : API_URL.TASK_ASSIGN,
      data: { ...(isBulk ? { taskIdArr } : { taskId: task?.id }), personId: assignPerson },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
        setAssignModalVisible(false);
        setAssingPerson(null);
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const release = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_RELEASE_BULK : API_URL.TASK_RELEASE,
      data: { ...(isBulk ? { taskIdArr } : { taskId: task?.id }) },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const urge = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_URGE_BULK : API_URL.TASK_URGE,
      data: {
        ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
      },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const reschedule = async () => {
    if (customValidationFunction) {
      const isOk = await customValidationFunction();
      if (!isOk) return;
    }
    startLoading();
    await putApi({
      url: isBulk ? API_URL.TASK_RESCHEDULE_BULK : API_URL.TASK_RESCHEDULE,
      data: {
        newRequiredDate: rescheduleDate,
        comment,
        ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
      },
      callAfterSuccess: (response) => {
        isBulk && rowStatus && rowStatus(response);
        mutate && mutate();
        mutateStatuses();
        setComment('');
        setRescheduleModalVisible(false);
      },
      hideSuccessNotifications,
    });
    stopLoading();
  };

  const handleStateButtonClicked = (
    actionId: number,
    statusToId: number,
    requiredComment: boolean,
    requireRating: boolean,
  ) => {
    setCommentActionData({
      ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
      newStatusId: statusToId,
      actionId,
      comment: requiredComment ? '' : null,
      ratingId: requireRating ? -1 : null,
    });
    if (task && task?.assetCycleRunId) getParametersForTaskCompleteWithAssetCycle();
    if (
      (requiredComment || requireRating) &&
      actionId !== TaskActionTypes.ASSIGN &&
      actionId !== TaskActionTypes.DOCUMENT_TYPE_CHANGE &&
      actionId !== TaskActionTypes.RESCHEDULE
    ) {
      setCommentModalVisible(true);
      return;
    }
    if (actionId === TaskActionTypes.TAKE) {
      take();
    } else if (actionId === TaskActionTypes.ASSIGN) {
      setAssignModalVisible(true);
    } else if (actionId === TaskActionTypes.RELEASE) {
      release();
    } else if (actionId === TaskActionTypes.URGE) {
      urge();
    } else if (actionId === TaskActionTypes.RESCHEDULE) {
      setRescheduleModalVisible(true);
      setRescheduleCommentRequired(requiredComment);
    } else if (actionId === TaskActionTypes.COMPLETE) {
      if (task && task?.assetCycleRunId) return setCommentModalVisible(true);
      changeStatus({
        ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
        newStatusId: statusToId,
        actionId,
        comment: '',
      });
    } else {
      changeStatus({
        ...(isBulk ? { taskIdArr } : { taskId: task?.id }),
        newStatusId: statusToId,
        actionId,
        comment: '',
      });
    }
  };

  const handleAssignPersonChanged = (e) => {
    setAssingPerson(e.value);
  };

  const handleCommentChanged = (e) => {
    setComment(e.value);
  };

  const paintButtonColor = (s: TaskStatus) => {
    const colorNotNil = s.colorName !== null;
    return colorNotNil ? ` ${toLower(s.colorName)}_button` : ' default_button';
  };

  const renderDocumentChangeButton = () => {
    const docTypeChangeItem = availableDocTypeChanges?.find((item) => item);
    if (docTypeChangeItem) {
      return (
        <Button
          className={`action-button-${
            isTerminal ? 'terminal' : isMobile ? 'mobile' : 'desktop'
          }${paintButtonColor(docTypeChangeItem)}`}
          onClick={() => setDocTypeChangeModalVisible(true)}
          text={docTypeChangeItem.actionNameTranslated}
          type={'normal'}
          disabled={loading}
        />
      );
    }
  };

  const renderActionButtons = () => {
    if (!statuses) return;
    let actionButtons = statuses
      .filter(
        (x) =>
          x.actionId !== TaskActionTypes.EDIT &&
          x.actionId !== TaskActionTypes.DOCUMENT_TYPE_CHANGE &&
          x.actionId !== TaskActionTypes.CLOSE_TIME_PERIOD &&
          x.actionId !== TaskActionTypes.REOPEN_TIME_PERIOD &&
          x.actionId !== TaskActionTypes.ISSUE_SPARE_PART &&
          x.actionId !== TaskActionTypes.RETURN_SPARE_PART &&
          x.actionId !== TaskActionTypes.STEP_ADD &&
          x.actionId !== TaskActionTypes.STEP_CANCEL &&
          x.actionId !== TaskActionTypes.STEP_COMPLETE &&
          x.actionId !== TaskActionTypes.STEP_REOPEN &&
          x.actionId !== TaskActionTypes.STEP_EDIT,
      )
      .map((s) => (
        <Button
          className={`action-button-${
            isTerminal ? `terminal` : isMobile ? `mobile` : `desktop`
          }${paintButtonColor(s)}`}
          key={s.id}
          onClick={() => {
            handleStateButtonClicked(s.actionId, s.statusToId, s.requiredComment, s.requireRating);
          }}
          text={s.actionNameTranslated}
          type={'normal'}
          // disabled={loading}
        />
      ));
    return <>{actionButtons}</>;
  };

  if ((!task && !tasks) || !statuses) return <Loader />;

  // @ts-ignore
  return (
    <>
      <PageContentWrapper
        noContentBlock={true}
        noResponsivePaddings
        noDxCard={isOnlyButtons}
        loading={!task && !tasks && !renderActionButtons()}
        isTerminalBackground={true}
      >
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '17px',
          }}
        >
          {!isBulk && showCurrentStatus && (
            <>
              {translate!('Status', transFormName)}: {task?.statusNameTranslated}
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isTerminal ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}
        >
          {!isTerminal && !isMobile && !isOnlyButtons && (
            <p>{translate!('Change status', transFormName)}:</p>
          )}
          {!isTerminal && !isMobile && !isOnlyButtons && (
            <span>{translate!('Change status', transFormName)}:</span>
          )}
          <div
            className={`${
              isMobile ? 'mobile' : isTerminal ? 'terminal' : 'desktop'
            }-action-buttons-container`}
          >
            {renderActionButtons()}
            {renderDocumentChangeButton()}
          </div>
        </div>
      </PageContentWrapper>
      <Popup
        visible={assignModalVisible}
        dragEnabled={false}
        closeOnOutsideClick={true}
        onHiding={() => setAssignModalVisible(false)}
        showTitle={true}
        title={translate!('Assign task', transFormName)}
        container=".dx-viewport"
        width={poupWidth}
        height={'auto'}
      >
        <form onSubmit={assign}>
          <Form labelLocation={'top'} ref={assignFormRef}>
            <GroupItem>
              <SimpleItem
                editorType="dxSelectBox"
                editorOptions={{
                  items: responsiblePersons,
                  value: assignPerson,
                  valueExpr: 'personId',
                  displayExpr: 'personName',
                  onValueChanged: handleAssignPersonChanged,
                }}
                cssClass={inputCss}
              >
                <Label text={translate!('Responsible person', transFormName)} />
                <RequiredRule message={translate!('Select responsible person', transFormName)} />
              </SimpleItem>
            </GroupItem>
            <ButtonItem
              verticalAlignment={'center'}
              horizontalAlignment={'left'}
              cssClass={buttonCss}
            >
              <ButtonOptions
                text={translate!('Save', transFormName)}
                type={'success'}
                disabled={loading}
                useSubmitBehavior={false}
                onClick={() => {
                  if (assignFormRef.current!.instance.validate().isValid) assign();
                }}
              />
            </ButtonItem>
          </Form>
        </form>
      </Popup>

      <Popup
        visible={docTypeChangeModalVisible}
        dragEnabled={false}
        closeOnOutsideClick={true}
        onHiding={() => setDocTypeChangeModalVisible(false)}
        showTitle={true}
        title={availableDocTypeChanges?.find((item) => item)?.actionNameTranslated}
        container=".dx-viewport"
        width={poupWidth}
        height={'auto'}
      >
        <form onSubmit={changeDocumentType}>
          <Form labelLocation={'top'} formData={{ selectedDocTypeChange, comment }}>
            <GroupItem>
              {task && (
                <SimpleItem
                  editorType="dxTextBox"
                  editorOptions={{
                    // @ts-ignore
                    value: task?.typeNameTranslated,
                    readOnly: true,
                  }}
                  cssClass={inputCss}
                >
                  <Label text={translate!('From type', transFormName)} />
                </SimpleItem>
              )}
              <SimpleItem
                dataField="selectedDocTypeChange"
                editorType="dxSelectBox"
                editorOptions={{
                  displayExpr: 'documentTypeToNameTranslated',
                  items: availableDocTypeChanges,
                  onValueChanged: (e) => setSelectedDocTypeChange(e?.value),
                }}
              >
                <Label text={translate!('To type', transFormName)} />
              </SimpleItem>
              <SimpleItem
                dataField="comment"
                editorType="dxTextArea"
                editorOptions={{
                  value: comment,
                  onValueChanged: handleCommentChanged,
                }}
                cssClass={inputCss}
              >
                <Label text={translate!('Comment', transFormName)} />
                {selectedDocTypeChange?.requiredComment && (
                  <RequiredRule message={translate!('Enter comment', transFormName)} />
                )}
              </SimpleItem>
            </GroupItem>
            <ButtonItem
              verticalAlignment={'center'}
              horizontalAlignment={'left'}
              cssClass={buttonCss}
            >
              <ButtonOptions
                text={translate!('Save', transFormName)}
                type={'success'}
                disabled={loading}
                useSubmitBehavior={false}
                onClick={changeDocumentType}
              />
            </ButtonItem>
          </Form>
        </form>
      </Popup>

      {commentModalVisible && task?.assetCycleRunId && !taskParametersAssetCycle ? (
        <Loader />
      ) : (
        <Popup
          visible={commentModalVisible}
          dragEnabled={false}
          closeOnOutsideClick={true}
          onHiding={() => {
            setCommentModalVisible(false);
          }}
          showTitle={true}
          title={
            task?.assetCycleRunId &&
            commentActionData &&
            commentActionData.actionId === TaskActionTypes.COMPLETE
              ? translate!('Task parameters', transFormName)
              : translate!('Enter comment', transFormName)
          }
          container=".dx-viewport"
          width={poupWidth}
          height={'auto'}
          minWidth={
            commentActionData &&
            commentActionData.actionId === TaskActionTypes.COMPLETE &&
            task?.assetCycleRunId
              ? '50vw'
              : undefined
          }
        >
          <>
            {task?.id &&
              task?.assetCycleRunId &&
              taskParametersAssetCycle &&
              commentActionData &&
              commentActionData.actionId === TaskActionTypes.COMPLETE && (
                <>
                  <TaskParameterWithAssetCycleDataGrid
                    data={taskParametersAssetCycle}
                    setParametersForTaskComplete={setParametersForTaskComplete}
                    taskId={task.id}
                    transFormName={transFormName}
                    setIsValid={setIsValid}
                  />
                  {commentActionData.ratingId !== null ||
                    (commentActionData.comment !== null && <hr />)}
                </>
              )}
            {(commentActionData.ratingId !== null || commentActionData.comment !== null) && (
              <form onSubmit={changeStatusWithComment}>
                <Form labelLocation={'top'} ref={commentsFormRef}>
                  {commentActionData.ratingId !== null ? (
                    <GroupItem>
                      <div style={{ textAlign: 'center' }}>
                        {taskRatings ? (
                          taskRatings?.map((r: TaskRating) => {
                            return (
                              <FontAwesomeIcon
                                key={r.id}
                                icon={icons[r.image]}
                                className={iconsCss}
                                style={{
                                  cursor: 'pointer',
                                  color: ratingIconSelected === r.id ? r.color : '#000',
                                }}
                                onClick={() => setRatingIconSelected(r.id)}
                              />
                            );
                          })
                        ) : (
                          <>Rating nenalezen</>
                        )}
                      </div>
                    </GroupItem>
                  ) : (
                    <></>
                  )}
                  {commentActionData.comment !== null && (
                    <GroupItem>
                      <SimpleItem
                        editorType="dxTextArea"
                        editorOptions={{
                          value: comment,
                          onValueChanged: handleCommentChanged,
                        }}
                        cssClass={inputCss}
                      >
                        <Label text={translate!('Comment', transFormName)} />
                        <RequiredRule message={translate!('Enter comment', transFormName)} />
                      </SimpleItem>
                    </GroupItem>
                  )}
                  <ButtonItem
                    verticalAlignment={'center'}
                    horizontalAlignment={'left'}
                    cssClass={buttonCss}
                  >
                    <ButtonOptions
                      text={translate!('Save', transFormName)}
                      type={'success'}
                      useSubmitBehavior={false}
                      //  disabled když requiredRating = true && uživatel nezvolil ikonu
                      disabled={
                        loading ||
                        (commentActionData.ratingId === -1 && ratingIconSelected === null)
                      }
                      onClick={() => {
                        if (commentsFormRef.current!.instance.validate().isValid)
                          changeStatusWithComment();
                      }}
                    />
                  </ButtonItem>
                </Form>
              </form>
            )}
            {commentActionData &&
              commentActionData.actionId === TaskActionTypes.COMPLETE &&
              task?.assetCycleRunId && (
                <Button
                  onClick={finishTask}
                  text={translate!('Finish task', transFormName)}
                  type={'success'}
                  style={{ display: 'inline-block', margin: '10px 10px 10px 0' }}
                  disabled={!isValid || loading}
                />
              )}
          </>
        </Popup>
      )}

      <Popup
        visible={rescheduleModalVisible}
        dragEnabled={false}
        closeOnOutsideClick={true}
        onHiding={() => setRescheduleModalVisible(false)}
        showTitle={true}
        title={translate!('ReSchedule', transFormName)}
        container=".dx-viewport"
        width={poupWidth}
        height={'auto'}
      >
        <form onSubmit={reschedule}>
          <Form labelLocation={'top'} formData={{ rescheduleDate, comment }}>
            <GroupItem>
              <SimpleItem
                dataField="rescheduleDate"
                editorType="dxDateBox"
                editorOptions={{
                  ...dateFieldOptionsWithSerialization,
                  onValueChanged: (e) => setRescheduleDate(e?.value),
                  disabledDates: (args) => {
                    const date = moment(formatDate(args.date, 'yyyy-MM-dd'));
                    const today = moment().startOf('day');
                    const _oldRequiredDate = oldRequiredDate
                      ? moment(oldRequiredDate, 'Y-MM-DD')
                      : today;
                    if (isEditForm) return date.isBefore(today) && !date.isSame(_oldRequiredDate);
                    else return date.isBefore(today);
                  },
                }}
                cssClass={inputCss}
              >
                <Label text={translate!('New required date', transFormName)} />
              </SimpleItem>
              <SimpleItem
                dataField="comment"
                editorType="dxTextArea"
                editorOptions={{
                  value: comment,
                  onValueChanged: handleCommentChanged,
                }}
                isRequired={isRescheduleCommentRequired}
                cssClass={inputCss}
              >
                <Label text={translate!('Comment', transFormName)} />
              </SimpleItem>
            </GroupItem>
            <ButtonItem
              verticalAlignment={'center'}
              horizontalAlignment={'left'}
              cssClass={buttonCss}
            >
              <ButtonOptions
                text={translate!('Save', transFormName)}
                type={'success'}
                useSubmitBehavior={false}
                disabled={loading}
                onClick={reschedule}
              />
            </ButtonItem>
          </Form>
        </form>
      </Popup>
    </>
  );
};
