import Box, { Item } from 'devextreme-react/box';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { SisDataGrid } from '../../components/table';
import { dateTimeFormat, IDataType, SisDataGridColumns } from '../../components/table/SisDataGrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useTranslation } from '../../contexts/translation';
import { Task } from '../../types/task.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { isNilOrEmpty } from '../../utils/ramdaUtils';
import { TypeNameCell } from '../Tasks/Columns';
import { paintCellByTaskColor } from '../Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../Tasks/utils/paintRowByColor';
import { HomePageBlock, HomePageBlockCount } from './HomePageBlock';

export default function HomePage() {
  const history = useHistory();
  const { assetClass } = useAssetClass();
  const { translate } = useTranslation();
  const transFormName = 'HomePage.Dashboard';

  const { data: countNew, isValidating: loadingCountNew } = useSWR<number>(
    `${API_URL.TASK_COUNT_NEW}?assetClassId=${assetClass}`,
    fetcher,
  );

  const { data: countOpened, isValidating: loadingCountOpened } = useSWR<number>(
    `${API_URL.TASK_COUNT_OPENED}?assetClassId=${assetClass}`,
    fetcher,
  );

  const { data: countSolved, isValidating: loadingCountSolved } = useSWR<number>(
    `${API_URL.TASK_COUNT_SOLVED}?assetClassId=${assetClass}`,
    fetcher,
  );

  const { data: countDelayed, isValidating: loadingCountDelayed } = useSWR<number>(
    `${API_URL.TASK_COUNT_DELAYED}?assetClassId=${assetClass}`,
    fetcher,
  );

  const { data: tasks, isValidating: loadingTasks } = useSWR<Task[]>(
    `${API_URL.TASK_BY_CLASS}?classId=${assetClass}&statusIdArr=10&statusIdArr=20&typeIdArr=404`,
    fetcher,
  );

  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        pathname: `/tasks/edit/${e.row.data.id}`,
        state: { backRoute: `/home` },
      });
    }
  };

  const TasksOkColumns = (): SisDataGridColumns => [
    {
      dataType: IDataType.string,
      dataField: 'customId',
      caption: 'Task id',
      cssClass: 'dg-align-center',
      width: '8%',
    },
    {
      dataField: 'taskName',
      caption: 'Task name',
      cssClass: 'dg-bold',
      width: '15%',
    },
    {
      dataField: 'capacityCategoryName',
      caption: 'Capacity category',
      lookupPickBy: 'capacityCategoryName',
      width: '8%',
    },
    {
      dataField: 'assetTopLevelParenName',
      caption: 'Line',
      width: '8%',
    },
    {
      dataField: 'assetName',
      caption: 'Asset',
      lookupPickBy: 'assetName',
      width: '17%',
    },
    {
      dataField: 'statusNameTranslated',
      caption: 'Status',
      lookupPickBy: 'statusNameTranslated',
      cssClass: 'dg-align-center',
      width: '11%',
    },
    {
      dataField: 'typeNameTranslated',
      caption: 'Type',
      lookupPickBy: 'typeNameTranslated',
      cssClass: 'dg-align-center',
      cellRender: TypeNameCell,
      width: '11%',
    },
    {
      dataField: 'downtimeFrom',
      caption: 'Downtime from',
      dataType: IDataType.dateTime,
      cssClass: 'dg-align-right',
      format: dateTimeFormat(),
      width: '11%',
    },
    {
      dataField: 'downtimeTo',
      caption: 'Downtime to',
      dataType: IDataType.dateTime,
      cssClass: 'dg-align-right',
      format: dateTimeFormat(),
      width: '11%',
    },
  ];

  return (
    <div className={'content-block'}>
      <Box direction="row" width="100%">
        <Item ratio={0.25}>
          <HomePageBlock title={translate!('New', transFormName)}>
            {(!countNew && countNew !== 0) || loadingCountNew ? (
              <>Loading...</>
            ) : (
              <HomePageBlockCount count={countNew} />
            )}
          </HomePageBlock>
        </Item>
        <Item ratio={0.25}>
          <HomePageBlock title={translate!('Open', transFormName)}>
            {(!countOpened && countOpened !== 0) || loadingCountOpened ? (
              <>Loading...</>
            ) : (
              <HomePageBlockCount count={countOpened} />
            )}
          </HomePageBlock>
        </Item>
        <Item ratio={0.25}>
          <HomePageBlock title={translate!('Solved', transFormName)}>
            {(!countSolved && countSolved !== 0) || loadingCountSolved ? (
              <>Loading...</>
            ) : (
              <HomePageBlockCount count={countSolved} />
            )}
          </HomePageBlock>
        </Item>
        <Item ratio={0.25}>
          <HomePageBlock title={translate!('Delayed', transFormName)}>
            {(!countDelayed && countDelayed !== 0) || loadingCountDelayed ? (
              <>Loading...</>
            ) : (
              <HomePageBlockCount count={countDelayed} />
            )}
          </HomePageBlock>
        </Item>
      </Box>
      <HomePageBlock title={translate!('Unsolved accidents', transFormName)}>
        {!tasks || loadingTasks ? (
          <>Loading...</>
        ) : (
          <SisDataGrid
            data={tasks}
            keyExpr={'id'}
            transFormName={'Task.List'}
            columns={TasksOkColumns()}
            columnChooser={false}
            allowColumnResizing={false}
            allowColumnReordering={false}
            filterRow={false}
            allowHeaderFiltering={false}
            pageSizeFromConfig={false}
            defaultPageSize={999}
            pageSize={999}
            onCellDblClick={handleEditButton}
            onRowPrepared={paintRowByTaskColor}
            onCellPrepared={paintCellByTaskColor}
          />
        )}
      </HomePageBlock>
    </div>
  );
}
