import { DateBox, NumberBox, SelectBox } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../contexts/translation';
import { useClearButtonProps } from '../../../hooks/useClearButtonProps';
import { DateSelectWithDiffField } from '../../../types/field.types';
import { getFilterDate } from '../../../utils/dateFilters';
import { displayDateFormat_CZ, displayDateFormat_EN } from '../../../utils/dates.types';
import FieldLabel from './FieldLabel';
import { OptionsObject } from './FormField';

type Props = {
  field: DateSelectWithDiffField;
  min?: Date;
  max?: Date;
  options?: OptionsObject;
};

type DiffSelectType = {
  option: {
    name: string;
    value: string;
  };
  diff: number;
};

/**
 * Field s kombinací výběru datumu nebo možnosti vybrání relativního času ze selectu ("Current day, Next day, ...")
 * a možnosti posunutí o +/- X dnů vůči relativnímu datumu
 */
export default function RelativeDateDiffField({ field, min, max, options }: Props) {
  const [dateValue, setDateValue] = useState<Date | undefined>(undefined);
  const [selectValue, setSelectValue] = useState<DiffSelectType | null>(null);
  const { translate, lang } = useTranslation();
  const transFormName = 'Reports.DateFilters';
  const dateFormat = lang === 'en-US' ? displayDateFormat_EN : displayDateFormat_CZ;
  const clearButtonProps = useClearButtonProps();

  useEffect(() => {
    // @ts-ignore
    const displayDate = field?.value?.option
      ? // @ts-ignore
        field?.dataSource?.find((item) => item?.value === field?.value?.option.value)
      : null;
    if (displayDate) {
      // @ts-ignore
      setSelectValue({ option: displayDate, diff: field?.value?.diff });
      setDateValue(undefined);
    } else {
      console.log({ fieldVal: field?.value})
      // @ts-ignore
      setDateValue(field?.value);
      setSelectValue(null);
    }
  }, []);

  const handleChange = (e: any, dateVal: any, selectVal: any) => {
    field.setValue(e);
    setDateValue(dateVal);
    setSelectValue(selectVal);
  };

  return (
    <>
      <DateBox
        type="date"
        value={dateValue}
        placeholder={field.placeholder}
        onValueChange={(e) => {
          e = e
            ? `${moment(getFilterDate(e)).format('YYYY-MM-DD')} ${options?.defaultTime ?? ''}`
            : undefined;
          handleChange(e, e, null);
        }}
        min={min}
        max={max}
        {...clearButtonProps}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SelectBox
          style={{ flex: 3 }}
          dataSource={field.dataSource}
          displayExpr={(data) => (data?.name ? translate!(data?.name, transFormName) : '')}
          value={selectValue?.option}
          onValueChange={(e) => {
            if (e) {
              handleChange({ option: e, diff: 0 }, undefined, {
                option: e,
                diff: 0,
              });
            } else {
              handleChange(null, undefined, null);
            }
          }}
          {...clearButtonProps}
        />
        <NumberBox
          style={{ flex: selectValue && selectValue?.diff.toString().length > 2 ? 1.6 : 1.3 }}
          value={selectValue?.diff}
          showSpinButtons={true}
          disabled={!selectValue}
          onValueChange={(e) =>
            handleChange({ option: selectValue?.option, diff: e }, undefined, {
              option: selectValue?.option,
              diff: e,
            })
          }
        />
      </div>
      {selectValue && (
        <FieldLabel
          label={translate!('Date', transFormName)}
          text={moment(getFilterDate(selectValue?.option?.value, selectValue?.diff)).format(
            dateFormat,
          )}
        />
      )}
    </>
  );
}
