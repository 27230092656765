export enum UserRoles {
  Admin = 'WfAdmin',
  AssetRead = 'WfAssetRead',
  AssetEdit = 'WfAssetEdit',
  TaskCreate = 'WfTaskCreate',
  TaskRead = 'WfTaskRead',
  WorksheetAll = 'WfWorksheetAll',
  WorksheetOwn = 'WfWorksheetOwn',
}

export interface ApplicationUser {
  id: number;
  uId: number;
  assetClassId: number | null;
  cardNumber: string | null;
  defaultLanguage: number;
  personalNumber: number;
  email: string | null;
  name: string | null;
  userName: string;
  fullName: string;
  type: number | null;
  roles: UserRoles[];
  assetId: number;
  platform: string;
}
