const stateChangeFields = [
  { field: 'AssetName', key: 'Asset' },
  {
    field: 'StatusName',
    key: 'Status',
    translatable: true,
  },
  {
    field: 'TypeName',
    key: 'Document type',
  },
  {
    field: 'PriorityName',
    key: 'Priority',
  },
  {
    field: 'CapacityCategoryName',
    key: 'Capacity category',
  },
  {
    field: 'RevisionCategoryName',
    key: 'Revision category',
  },
  {
    field: 'TaskName',
    key: 'Task name',
  },
  {
    field: 'Description',
    key: 'Description',
  },
];

export const getTranslationByField = (fieldName) => {
  const translation = stateChangeFields.filter((item) => item.field === fieldName);
  return translation.length > 0 ? translation[0] : null;
};
