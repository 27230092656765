// Parameter
import { Unit } from './unit.types';

export interface Parameter {
  id: number;
  name: string;
  description: string;
  baseUnit: Unit;
  measurementAllowed: boolean;
  visible: boolean;
}

export interface ParameterInputs {
  id: number;
  name: string;
  description: string;
  baseUnitId?: number;
  measurementAllowed: boolean;
  visible: boolean;
}

export interface TaskParameterAssetCycle {
  id: number;
  name: string;
  unit: {
    id: number;
    name: string;
    description: string;
    baseValueDataType: string;
    visible: boolean;
  };
  lastValue: string;
  period: number;
}

export enum BaseValueDataType {
  Bool = 'Bool',
  Int = 'Int',
  Float = 'Float',
  Date = 'Date',
  DateTime = 'DateTime',
  Text = 'Text',
}

export interface TaskParameterAssetCycleUpdate {
  taskId: number;
  id: number;
  value: string;
  threshold: string;
}
