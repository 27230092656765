import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AssetTypesForm } from '../../forms/assetTypesNew/AssetTypesForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetTypeInputs } from '../../types/assetType.type';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function AssetTypesNew() {
  const { translate } = useTranslation();
  const transFormName = 'AssetType.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New asset types',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Asset types', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Načteme číselník - AssetLevel
  const { data: assetLevels, error: errorAssetLevels } = useSWR(API_URL.ASSET_LEVEL_LIST, fetcher);

  const data: AssetTypeInputs = {
    id: 0,
    name: '',
    visible: true,
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!data) return;
    let isOk = false;
    startLoading();
    await postApi<AssetTypeInputs>({
      url: API_URL.ASSET_TYPE_POST,
      data,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!assetLevels}
      error={errorAssetLevels}
      heading={translate!('New asset type', transFormName)}
    >
      <AssetTypesForm onSubmit={onSubmit} formData={data} assetLevels={assetLevels} />
    </PageContentWrapper>
  );
}
export default AssetTypesNew;
