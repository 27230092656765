import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import './AssetNfcTagsModal.scss';
import './TasksBulkEditModal.scss';

/**
 * Modalová komponent pro hromadné změny ve vybraných řádcích z datagridu
 */
export default function AssetNfcTagModal({ isVisible, onClose, data, actionType, onSubmit }) {
  const { translate } = useTranslation();
  const transFormName = 'Asset.NfcTag';
  const formRef = useRef<Form>(null);

  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    setFormData({ ...data });
  }, []);

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset tag', transFormName)}
      width={'20%'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        {prompt}
        <Form formData={formData} showColonAfterLabel={true} showValidationSummary={true}>
          <GroupItem>
            <SimpleItem dataField="tagId" editorType="dxTextBox">
              <Label text={translate!('Tag ID', transFormName)} />
              <RequiredRule message={translate!('Tag id is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="description" editorType="dxTextBox">
              <Label text={translate!('Description', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Close', '')}
                onClick={onClose}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
