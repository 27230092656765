import * as icons from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabPanel from 'devextreme-react/tab-panel';
import { equals, includes, split } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation, useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import useSWR from 'swr';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useModuleEnabled } from '../../contexts/moduleContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { TabLocationState } from '../../types/locationState.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { Task } from '../../types/task.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { swrConfig } from '../Assets/AssetsEdit';
import { GeneralTaskTab } from './Tabs/GeneralTab';
import { TaskCommentsTab } from './Tabs/TaskCommentsTab';
import { TaskFilesTab } from './Tabs/TaskFilesTab';
import { TaskHistoryTab } from './Tabs/TaskHistoryTab';
import { TaskSparePartsTab } from './Tabs/TaskSparePartsTab';
import { TaskStepsTab } from './Tabs/TaskStepsTab';
import { TaskWorkSheetsTab } from './Tabs/TaskWorkSheetsTab';

export const tabsIndexes = {
  GENERAL: 0,
  COMMENTS: 1,
  FILES: 2,
  HISTORY: 3,
  WORKSHEETS: 4,
  SPARE_PARTS: 5,
  TASK_STEPS: 6,
};

export function TasksEdit() {
  const { translate } = useTranslation();
  const { isSparePartEnabled } = useModuleEnabled();
  const transFormNameTabs = 'Task.Tabs';
  const transFormNameUpdate = 'Task.Update';
  const transFormNameTaskStatus = 'TaskStatus';
  const { id } = useParams<DetailPageParams>();
  const lastLocation = useLastLocation();
  const location = useLocation();

  const { state }: { state: TabLocationState | undefined } = useLocation();
  const [currentTab, setCurrentTab] = useState<number>(
    state?.defaultTab ? state?.defaultTab : tabsIndexes.GENERAL,
  );

  const assetId = split('/assets/edit/', lastLocation?.pathname ?? '')[1];
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit task',
      steps: [{ name: 'Home', pathToCutCount: 3 }, getLastLocationCrumb()],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // otevřeno v nové záložce
  useEffect(() => {
    // nastavení current tab z session storage
    const hasPreviousLocation = lastLocation?.pathname !== location.pathname;
    const valueFromStorage = localStorage.getItem(STORAGE_KEYS.CONTEXT_STATE);
    if (hasPreviousLocation && lastLocation?.pathname !== '/login')
      return localStorage.removeItem(STORAGE_KEYS.CONTEXT_STATE);
    if (!valueFromStorage || valueFromStorage === 'null' || hasPreviousLocation) return;
    const obj = valueFromStorage ? JSON.parse(valueFromStorage) : null;
    if (obj?.tab) setCurrentTab(obj.tab);
  }, [lastLocation]);

  const {
    data: asset,
    isValidating: assetLoading,
    error: errorAsset,
  } = useSWR(
    assetId
      ? `${API_URL.ASSET_ITEM}?id=${split('/assets/edit/', lastLocation?.pathname ?? '')[1]}`
      : null,
    fetcher,
    swrConfig,
  );

  const {
    data: task,
    error: errorTask,
    isValidating: taskLoading,
  } = useSWR<Task>(`${API_URL.TASK_ITEM}?id=${id}`, fetcher, swrConfig);

  const {
    data: attachmentsCount,
    error: attachmentsCountError,
    isValidating: isValidatingAttachmentCount,
    mutate: mutateAttachmentCount,
  } = useSWR(id ? `${API_URL.FILE_COUNT}?entity=Task&entityId=${id}` : null, fetcher, swrConfig);

  const { data: taskWorksheetList, error: errorTaskWorksheetList } = useSWR(
    `${API_URL.WORKSHEET_LIST}?parentId=${id}`,
    fetcher,
    swrConfig,
  );

  const {
    data: sparePartIssuedList,
    error: errorSparePartIssued,
    mutate: sparePartListMutate,
  } = useSWR(
    isSparePartEnabled ? `${API_URL.SPARE_PART_ISSUED_LIST_AGGREGATED}?parentId=${id}` : null,
    fetcher,
    swrConfig,
  );

  const {
    data: taskStepList,
    error: errorTaskStepList,
    mutate: taskStepListMutate,
  } = useSWR(`${API_URL.TASK_STEP_LIST}?parentId=${id}`, fetcher, swrConfig);

  useEffect(() => {
    if (currentTab === 5) sparePartListMutate();
    if (currentTab === 6) taskStepListMutate();
  }, [currentTab]);

  const getLastLocationCrumb = () => {
    return lastLocation?.pathname && includes('/assets/edit', lastLocation?.pathname)
      ? {
          nameNotTranslate: asset?.name ?? '',
          link: lastLocation.pathname,
          name: '',
          pathToCutCount: 2,
        }
      : { name: 'Tasks', pathToCutCount: 2 };
  };

  const tabs = [
    {
      title: translate!('General', transFormNameTabs),
      index: tabsIndexes.GENERAL,
      component: equals(currentTab, 0) && (
        <GeneralTaskTab
          id={id}
          active={equals(0, currentTab)}
          worksheets={taskWorksheetList?.filter((x) => x.workSheetStatusId !== 0)}
        />
      ),
    },
    {
      title: `${translate!('Comments', transFormNameTabs)} (${task?.comments.length})`,
      index: tabsIndexes.COMMENTS,
      component: equals(currentTab, 1) && (
        <TaskCommentsTab id={id} active={equals(1, currentTab)} />
      ),
    },
    {
      title: `${translate!('Files', transFormNameTabs)} (${attachmentsCount})`,
      index: tabsIndexes.FILES,
      component: equals(currentTab, 2) && (
        <TaskFilesTab
          id={id}
          active={equals(2, currentTab)}
          mutateAttachmentCount={mutateAttachmentCount}
        />
      ),
    },
    {
      title: translate!('History', transFormNameTabs),
      index: tabsIndexes.HISTORY,
      component: equals(currentTab, 3) && <TaskHistoryTab id={id} active={equals(3, currentTab)} />,
    },
    {
      title: `${translate!('WorkSheets', transFormNameTabs)} (${taskWorksheetList?.length ?? 0})`,
      index: tabsIndexes.WORKSHEETS,
      component: equals(currentTab, 4) && (
        <TaskWorkSheetsTab
          task={task}
          id={id}
          active={equals(4, currentTab)}
          data={taskWorksheetList}
          error={errorTaskWorksheetList}
        />
      ),
    },
    {
      visible: isSparePartEnabled,
      title: `${translate!('Spare parts issued', transFormNameTabs)} (${
        sparePartIssuedList?.length ?? 0
      })`,
      index: tabsIndexes.SPARE_PARTS,
      component: equals(currentTab, 5) && (
        <TaskSparePartsTab
          id={id}
          active={equals(5, currentTab)}
          data={sparePartIssuedList}
          errorSparePartIssued={errorSparePartIssued}
        />
      ),
    },
  ];

  if (task && task.typeTaskStepsAllowed) {
    tabs.push({
      title: `${translate!('Steps', transFormNameTabs)}`,
      index: tabsIndexes.TASK_STEPS,
      component: equals(currentTab, 6) && (
        <TaskStepsTab
          id={id}
          active={equals(6, currentTab)}
          data={taskStepList}
          taskStepListMutate={taskStepListMutate}
          errorStepList={errorTaskStepList}
        />
      ),
    });
  }

  return (
    <>
      <PageContentWrapper
        heading={`${translate!('Edit task', transFormNameUpdate)}: ${task?.customId}, ${
          task?.taskName
        }, ${translate!(task?.statusName ?? '', transFormNameTaskStatus)}${
          task?.urgeLevel && task.urgeLevel > 0
            ? `, ${translate!('Urge', transFormNameUpdate)}: ${task?.urgeLevel}`
            : ''
        }`}
        nextToContent={
          task?.ratingImage && (
            <div className="dx-item-content dx-toolbar-item-content">
              <FontAwesomeIcon
                icon={icons[task?.ratingImage]}
                style={{
                  fontSize: '2.5em',
                  marginLeft: 5,
                  color: task?.ratingColor ? task?.ratingColor : '#000',
                }}
              />
            </div>
          )
        }
        loading={assetLoading && taskLoading && isValidatingAttachmentCount}
        error={errorAsset || errorTask || attachmentsCountError}
      >
        <TabPanel
          selectedIndex={currentTab}
          dataSource={tabs}
          itemRender={(a) => a.component}
          activeStateEnabled={true}
          onTitleClick={(e: any) => setCurrentTab(e.itemIndex)}
          deferRendering={false}
          focusStateEnabled={false}
        />
      </PageContentWrapper>
      <Prompt
        // smazat local storage při opuštění stránky
        message={(location, action) => {
          if (action === 'PUSH') localStorage.removeItem(STORAGE_KEYS.CONTEXT_STATE);
          return true;
        }}
      />
    </>
  );
}

export default TasksEdit;
