import { DateBox, SelectBox } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../contexts/translation';
import { useClearButtonProps } from '../../../hooks/useClearButtonProps';
import { DateSelectField } from '../../../types/field.types';
import { getFilterDate } from '../../../utils/dateFilters';
import { displayDateFormat_CZ, displayDateFormat_EN } from '../../../utils/dates.types';
import FieldLabel from './FieldLabel';

type Props = {
  field: DateSelectField;
};

/**
 * Field s kombinací výběru datumu nebo možnosti vybrání relativního času ze selectu ("Current day, Next day, ...")
 */
export default function DateOrSelectField({ field }: Props) {
  const [dateValue, setDateValue] = useState<Date | undefined>(undefined);
  const [selectValue, setSelectValue] = useState<any>(null);
  const { translate, lang } = useTranslation();
  const transFormName = 'Reports.DateFilters';
  const dateFormat = lang === 'en-US' ? displayDateFormat_EN : displayDateFormat_CZ;
  const clearButtonProps = useClearButtonProps();

  useEffect(() => {
    // @ts-ignore
    const displayDate = field?.dataSource?.find((item) => item.value === field.value);
    if (displayDate) {
      setSelectValue(displayDate);
      setDateValue(undefined);
    } else {
      // @ts-ignore
      setDateValue(field.value);
      setSelectValue(null);
    }
  }, []);

  const handleChange = (e: any, dateVal: any, selectVal: any) => {
    field.setValue(dateVal ? e : e.value);
    setDateValue(dateVal);
    setSelectValue(selectVal);
  };

  return (
    <>
      <DateBox
        type="date"
        value={dateValue}
        placeholder={field.placeholder}
        onValueChange={(e) => {
          handleChange(e, e, null);
        }}
        {...clearButtonProps}
      />
      <SelectBox
        dataSource={field.dataSource}
        displayExpr={(data) => (data?.name ? translate!(data?.name, transFormName) : '')}
        value={selectValue}
        onValueChange={(e) => handleChange(e, undefined, e)}
        {...clearButtonProps}
      />
      {selectValue && (
        <FieldLabel
          label={translate!('Date', transFormName)}
          text={moment(getFilterDate(selectValue?.value)).format(dateFormat)}
        />
      )}
    </>
  );
}
