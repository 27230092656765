export enum TaskActionTypes {
  TAKE = 1,
  ASSIGN = 2,
  RELEASE = 3,
  URGE = 4,
  OPEN = 10,
  POSTPONE = 11,
  RETURN = 12,
  RETURN_TO_SOLUTION = 13,
  COMPLETE = 14,
  ACCEPT = 15,
  EDIT = 17,
  DOCUMENT_TYPE_CHANGE = 19,
  RESCHEDULE = 20,
  CLOSE_TIME_PERIOD = 50,
  REOPEN_TIME_PERIOD = 51,
  ISSUE_SPARE_PART = 70,
  RETURN_SPARE_PART = 71,
  STEP_ADD = 100,
  STEP_REOPEN = 101,
  STEP_COMPLETE = 102,
  STEP_CANCEL = 103,
  STEP_EDIT = 104,
}
