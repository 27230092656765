import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/AssetTypes/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const AssetTypesDataGrid = ({ data, handleEdit }: IDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'AssetType.List';
  const history = useHistory();

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset types', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => history.push(`/asset-types/new/`),
        },
      },
    );
  };

  return (
    <SisDataGrid
      data={data}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={Columns(handleEdit, translate)}
      onToolbarPreparing={onToolbarPreparing}
      columnChooser={false}
      allowColumnReordering={false}
      allowColumnResizing={false}
    />
  );
};
