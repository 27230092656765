import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertMessage } from '../../../../../components/alertMessage/AlertMessage';
import { useModuleEnabled } from '../../../../../contexts/moduleContext';
import { useTranslation } from '../../../../../contexts/translation';
import * as Bar from '../../../../WorkSheets/Mobile/components/Bar';
import MobileLayout from '../../components/MobileLayout';
import { TasksSparePartsSearchForm } from './TasksSparePartsSearchForm';

interface Props {}

export const TasksSparePartsSearchPage = ({}: Props) => {
  const transFormName = 'TasksMobile.SparePartsSearch';
  const { translate } = useTranslation();
  const { isSparePartEnabled } = useModuleEnabled();
  const history = useHistory();
  const location = useLocation<{ backRoute: string }>();
  const backRoute =
    location.state && location.state.backRoute ? location.state.backRoute : '/mobile/tasks/list';

  if (!isSparePartEnabled)
    return <AlertMessage message={translate!('Module is not allowed', '')} class={'danger'} />;

  return (
    <MobileLayout>
      <Bar.SummaryNavBar
        title={translate!('Spare part search', transFormName)}
        onBack={() => history.push(backRoute)}
      />
      <TasksSparePartsSearchForm />
    </MobileLayout>
  );
};
