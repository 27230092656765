import React, { useEffect, useRef } from 'react';
import useSWR from 'swr';
import { TimePeriodsDataGrid } from '../../components/datagrid/TimePeriodsDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { TimePeriod } from '../../types/timeperiod.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function TimePeriods() {
  const dataGrid = useRef<any>(null);
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Time periods', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const { data, error, isValidating } = useSWR<TimePeriod[]>(
    `${API_URL.TIME_PERIOD_LIST}`,
    fetcher,
    swrConfig,
  );

  return (
    <PageContentWrapper error={error} loading={!data || isValidating}>
      <TimePeriodsDataGrid dataGridRef={dataGrid} data={data} />
    </PageContentWrapper>
  );
}
