import { and, equals, find, isEmpty, isNil, o, or, prop } from 'ramda';
import useSWR from 'swr';
import { swrConfig } from '../pages/Assets/AssetsEdit';
import { TaskActionTypes } from '../types/taskActionTypes';
import { TaskStatus } from '../types/TaskStatus';
import { API_URL, fetcher } from '../utils/apiUrl';

export const useIsTaskEditable = (
  id: string,
  temporalyValueConfigured: boolean,
): [isEditable: boolean, err: boolean, loading: boolean] => {
  const {
    data: statuses,
    error: errorStatuses,
    isValidating: isValidatingStatuses,
    mutate: reFetch,
  } = useSWR<TaskStatus[]>(
    temporalyValueConfigured ? `${API_URL.TASK_PROCESS_CONFIG}?taskId=${id}` : null,
    fetcher,
    swrConfig,
  );

  return [
    o(
      (f) => or(isNil(f), isEmpty(f)),
      find<TaskStatus>((i) =>
        and(
          equals(prop('actionId', i), TaskActionTypes.EDIT),
          equals(prop('actionName', i), 'Edit'),
        ),
      ),
    )(statuses ?? []),
    errorStatuses,
    isValidatingStatuses,
  ];
};
