import { Form, Popup } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { ButtonItem, ButtonOptions, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/bulk-modal.scss';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { DataGridActionType } from '../../../types/dataGridActionTypes';
import { StepGroup } from '../../../types/stepGroup.types';
import '../EditCreateModal.scss';
import '../TasksBulkEditModal.scss';

type Props = {
  data: any;
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, action: 'customStep' | 'step' | 'stepGroup') => void;
  actionType: DataGridActionType;
  stepGroupList?: StepGroup[];
  transFormName: string;
  popupWidth: string;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Task step
 */
export default function TaskStepGroupModal({
  data,
  isVisible,
  actionType,
  onClose,
  onSubmit,
  stepGroupList,
  transFormName,
  popupWidth,
}: Props) {
  const { translate } = useTranslation();
  const formRef = useRef<Form>(null);
  const { loading } = useLoading();
  const [formData, setFormData] = useState<any>({
    ...data,
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });

  useEffect(() => {
    setFormData({
      ...data,
      ...(actionType === DataGridActionType.Insert && { visible: true }),
    });
  }, []);

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Add step group', transFormName)}
      width={popupWidth}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, 'stepGroup');
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="stepGroupId"
              editorType="dxSelectBox"
              editorOptions={{
                items: stepGroupList,
                valueExpr: 'id',
                displayExpr: 'name',
              }}
            >
              <Label text={translate!('Step group', transFormName)} />
              <RequiredRule message={translate!('Select step group', transFormName)} />
            </SimpleItem>
          </GroupItem>

          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                disabled={loading}
                type={'success'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions type={'normal'} text={translate!('Close', '')} onClick={onClose} />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
