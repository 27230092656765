import Button from 'devextreme-react/button';
import React from 'react';
import { useTranslation } from '../../contexts/translation';

export function NewWindowToolBarButton() {
  const { translate } = useTranslation();
  const transFormName = '';

  const handleClick = () => {
    window.open(window.location.href, '_blank');
  };

  return (
    <>
      <Button
        hint={translate!('New window', transFormName)}
        type={'normal'}
        stylingMode={'contained'}
        icon={'fullscreen'}
        onClick={handleClick}
      />
    </>
  );
}
