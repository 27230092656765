import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { StepForm } from '../../forms/step/StepForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { StepInputs } from '../../types/step.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function StepsNew() {
  const { translate } = useTranslation();
  const transFormName = 'Step.Form';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();
  const { assetClass } = useAssetClass();

  useEffect(() => {
    setValue({
      pageName: 'New step',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Steps', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const formData: StepInputs = {
    id: 0,
    name: '',
    description: '',
    capacityCategoryId: 0,
    visible: true,
  };

  /**
   * Načte seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();
    let isOk = false;
    const _data = formData as any;
    await postApi<StepInputs>({
      url: API_URL.STEP_POST,
      data: { ...formData, capacityCategoryId: _data?.capacityId },
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      heading={translate!('New step', transFormName)}
      error={capacityCategoriesError}
    >
      <StepForm onSubmit={onSubmit} formData={formData} capacityCategories={capacityCategories} />
    </PageContentWrapper>
  );
}

export default StepsNew;
