import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React from 'react';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { AssetClassToolBarIndicator } from '../assetClassToolBarIndicator/AssetClassToolBarIndicator';
import { Breadcrumbs } from '../Breadcrumbs/BreadcrumbsWithHistory';
import { NewWindowToolBarButton } from '../newWindowToolBarButton/NewWindowToolBarButton';
import { TransactionsSearchForm } from '../transactionsSearchForm/TransactionsSearchForm';
import UserPanel, { MenuMode } from '../userPanel/UserPanel';
// import './Header.scss';

// eslint-disable-next-line react/prop-types
export function Header({ menuToggleEnabled, title, toggleMenu }) {
  const { assetClassName } = useAssetClass();
  const { user, signOut } = useAuth();
  const { value } = useBreadcrumbs();

  const linkArr = window.location.href.split('/');  
  const horizontalScrollClass =
    linkArr[linkArr.length - 1] === 'tasks-mobile-view' ||
    linkArr[linkArr.length - 1] === 'tasks' ||
    (linkArr[4] === 'reports' && linkArr[5] === '2') ||
    linkArr[linkArr.length - 1] === 'workSheets' ||
    (linkArr[4] === 'reports' && linkArr[5] === '3') ||
    linkArr[4] === 'generalReports'
      ? ' no-responsive'
      : '';

  return (
    <header className={`header-component${horizontalScrollClass}`}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />
        <Item location={'before'} text={title}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            }}
          >
            <AssetClassToolBarIndicator title={assetClassName!} />
            {value && (
              <div style={{ margin: '0 24px 0' }}>
                <Breadcrumbs pageName={value.pageName} steps={value.steps} />
              </div>
            )}
          </div>
        </Item>
        <Item location={'after'} locateInMenu={'auto'}>
          <TransactionsSearchForm />
        </Item>
        <Item location={'after'} locateInMenu={'auto'}>
          <NewWindowToolBarButton />
        </Item>
        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={MenuMode.CONTEXT} user={user} signOut={signOut} />
          </Button>
        </Item>

        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={MenuMode.LIST} user={user} signOut={signOut} />
        </Template>
      </Toolbar>
    </header>
  );
}
