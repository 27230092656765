import classnames from 'classnames';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import React, { ReactNode } from 'react';
import { FailedLoad } from '../../components/failedLoad/FailedLoad';
import { Loader } from '../../components/loader/Loader';
import { useTranslation } from '../../contexts/translation';
export interface IPageContentWrapper {
  // eslint-disable-next-line no-undef
  children?: JSX.Element | JSX.Element[] | string;
  aboveContent?: ReactNode;
  underContent?: ReactNode;
  nextToContent?: ReactNode;
  heading?: any;
  error?: any;
  loading?: any;
  noDxCard?: boolean;
  noResponsivePaddings?: boolean;
  noContentBlock?: boolean;
  noLoadingModal?: boolean;
  isTerminalBackground?: boolean;
  isModal?: boolean;
}

export const PageContentWrapper = ({
  children,
  aboveContent,
  nextToContent,
  heading,
  error,
  loading,
  noLoadingModal,
  noDxCard,
  noResponsivePaddings,
  noContentBlock,
  underContent,
  isTerminalBackground,
  isModal,
}: IPageContentWrapper) => {
  const { translate } = useTranslation();

  if (error)
    return (
      <FailedLoad
        message={!error.status ? translate!('There are connection problems.', '') : null}
      />
    );
  if (loading) return <Loader visible={!noLoadingModal} />;
  return (
    <>
      <div
        className={classnames({
          'content-block': !noContentBlock,
          'terminal-form-card': isTerminalBackground,
        })}
      >
        <div
          className={classnames({
            'dx-card': !noDxCard,
            'responsive-paddings': !noResponsivePaddings,
          })}
        >
          {aboveContent && <div>{aboveContent}</div>}
          {heading && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Toolbar style={{ width: isModal ? '100%' : 'initial' }}>
                  {' '}
                  <Item location={isModal ? 'before' : undefined} html={heading} />
                </Toolbar>
                {nextToContent && nextToContent}
              </div>
            </>
          )}

          {underContent && <div>{underContent}</div>}
          {children}
        </div>
      </div>
    </>
  );
};
