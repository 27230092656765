import { Button, CheckBox, Popup } from 'devextreme-react';
import Form, { GroupItem } from 'devextreme-react/form';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi } from '../../../apiUtils';
import ReportFilterSelector, {
  FilterActionState,
} from '../../../components/reportSelector/ReportSelector';
import { useAssetClass } from '../../../contexts/assetClass';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { clear, getParameters, parseParameters, useField } from '../../../hooks/useField';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { Asset } from '../../../types/asset.types';
import { AssetLocation } from '../../../types/assetLocation.types';
import { FieldType } from '../../../types/field.types';
import { Rule } from '../../../types/rule.types';
import { AssetSpareParts } from '../../../types/spareParts.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { DateFilter } from '../../../utils/dateFilters';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import FieldLabel from '../components/FieldLabel';
import FormField from '../components/FormField';
import DeleteButton from '../components/ReportDeleteButton';
import '../ReportFilter.scss';

type Props = {
  dataGridRef: React.Ref<any>;
  onDataChange: (data: Array<AssetSpareParts> | null) => void;
  onFilterChange: (filter: string | null) => void;
  report: {
    id: number;
    key: string;
  };
  filterVisibility: boolean;
  loadDefaultSetting: boolean;
};

/**
 * Komponenta představující filtr s report selectorem pro Report náhradních dílů (Spare Parts)
 */
export default function SparePartReportFilter({
  dataGridRef,
  onDataChange,
  onFilterChange,
  report,
  filterVisibility,
  loadDefaultSetting,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [dialogAction, setDialogAction] = useState<FilterActionState | null>(null);
  const [isDefault, setDefault] = useState<boolean>(false);
  const { startLoading, loading, stopLoading } = useLoading();
  const { assetClass } = useAssetClass();

  const { translate } = useTranslation();
  const transFormName = 'SparePart.List';
  const transFormRepSelector = 'Reports.ReportSelector';
  const transFormReportBar = 'Reports.Bar';
  // reference pro report selector na kterou jsou navázané funkce pro práci s filtry:
  //  - smazání, přidání / editace filtru
  const selectorRef = useRef<any>();

  const [filterSettingSession, setFilterSettingSession] = useSessionStorage(
    STORAGE_KEYS.SPARE_PARTS_REPORT_FILTER_DESKTOP,
    {},
  );

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const getData = async (params = {}) => {
    console.log('getData method');
    const response = await postApi<Array<AssetSpareParts>>({
      url: API_URL.SPARE_PART_REPORT_REQUEST,
      data: params,
      hideNotifications: true,
    });
    onDataChange(response ?? null);
    stopLoading();
  };

  useEffect(() => {
    const params = {
      assetIdArr: null,
      locationIdArr: null,
      prodId: null,
      statusIdArr: null,
      typeIdArr: null,
      active: true,
    };
    getData(params);
  }, []);

  /**
   * Funkce pro vyhledávání - po zadání hodnot ve filter baru
   */
  const onSearch = (e: FormEvent) => {
    startLoading();
    e.preventDefault();
    const params = parseParameters(fields);
    getData(params);
    const filterParams = getParameters(fields);
    setFilterSettingSession(filterParams);
  };

  /**
   * Funkce pro smazání hodnot z fieldů - reset funkce
   */
  const clearDataAndFields = () => {
    clear(fields);
    onDataChange([]);
  };

  /**
   * SWR fetch dat z API pro naplnění field data sourců
   */

  // Číselník lokací
  const { data: locations } = useSWR<AssetLocation[]>(`${API_URL.ASSET_LOCATION_LIST}`, fetcher);

  // Číselník strojů
  const { data: assets } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
  );

  /**
   * Definice fieldů pro filter bar + form na přidání nového / editaci stávajího filtru
   */

  const filterNameField = useField({
    name: 'name',
    fieldType: FieldType.Text,
    placeholder: translate!('Filter name', transFormRepSelector),
    rules: [{ type: Rule.RequiredRule, message: 'Filter name is required' }],
    maxLength: 50,
  });

  const assetIdArrayField = useField({
    name: 'assetIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: assets,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset', transFormName),
  });

  const productIdField = useField({
    name: 'prodId',
    fieldType: FieldType.Text,
    placeholder: translate!('Product Id', transFormName),
  });

  const locationIdArrayField = useField({
    name: 'locationIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: locations,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset location', transFormName),
  });

  const issuedFromField = useField({
    name: 'issuedFrom',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearStart,
      DateFilter.PreviousMonthStart,
      DateFilter.PreviousWeekStart,
      DateFilter.PreviousDayStart,
      DateFilter.CurrentDayStart,
      DateFilter.CurrentWeekStart,
      DateFilter.CurrentMonthStart,
      DateFilter.CurrentYearStart,
      DateFilter.NextDayStart,
      DateFilter.FollowingWeekStart,
      DateFilter.FollowingMonthStart,
      DateFilter.FollowingYearStart,
    ],
    defaultValue: undefined,
    placeholder: translate!('Created from', transFormName),
  });

  const issuedToField = useField({
    name: 'issuedTo',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearEnd,
      DateFilter.PreviousMonthEnd,
      DateFilter.PreviousWeekEnd,
      DateFilter.PreviousDayEnd,
      DateFilter.CurrentDayEnd,
      DateFilter.CurrentWeekEnd,
      DateFilter.CurrentMonthEnd,
      DateFilter.CurrentYearEnd,
      DateFilter.NextDayEnd,
      DateFilter.FollowingWeekEnd,
      DateFilter.FollowingMonthEnd,
      DateFilter.FollowingYearEnd,
    ],
    defaultValue: undefined,
    placeholder: translate!('Created to', transFormName),
  });

  const fields = [
    assetIdArrayField,
    filterNameField,
    locationIdArrayField,
    productIdField,
    issuedFromField,
    issuedToField,
  ];

  /**
   * Funkce pro zpracování formuláře při přidání nového / editaci stávajícího filtru
   */
  const onFilterDialogSubmit = (e) => {
    e.preventDefault();
    selectorRef.current.saveOrEditFilter(dialogAction);
  };

  const handleDialogVisibility = (action: FilterActionState | null = null) => {
    setDialogAction(action);
    setIsModalVisible(!!action);
  };

  return (
    <>
      <div>
        <div
          className="report-selector-bar"
          style={{ display: filterVisibility ? 'block' : 'none' }}
        >
          <ReportFilterSelector
            ref={selectorRef}
            filterName={filterNameField.value}
            fields={fields}
            dataGridRef={dataGridRef}
            onClear={clearDataAndFields}
            onFilterChange={onFilterChange}
            report={report}
            openDialog={handleDialogVisibility}
            fetchData={getData}
            isDefault={isDefault}
            setIsDefault={(value) => setDefault(value)}
            loadDefaultSetting={loadDefaultSetting}
            loadDefaultSettingStorageKey={STORAGE_KEYS.ASSETS_REPORT_FILTER_DESKTOP}
          />
        </div>
        {isModalVisible && (
          <Popup
            visible={isModalVisible}
            dragEnabled={false}
            closeOnOutsideClick={true}
            onHiding={() => handleDialogVisibility(null)}
            showTitle={true}
            title={
              dialogAction === FilterActionState.EDIT
                ? translate!('Edit selected filter', transFormRepSelector)
                : translate!('Save a new filter', transFormRepSelector)
            }
            container=".dx-viewport"
            width="auto"
            height="auto"
          >
            {/* filter add/edit dialog */}
            <form onSubmit={onFilterDialogSubmit}>
              <Form colCount={4} className="fields-container">
                <GroupItem colSpan={4} colCount={4}>
                  <GroupItem>
                    <FieldLabel label={filterNameField.placeholder} />
                    <FormField field={filterNameField} />
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={4} colCount={4}>
                  <GroupItem>
                    <FieldLabel label={assetIdArrayField.placeholder} />
                    <FormField field={assetIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={locationIdArrayField.placeholder} />
                    <FormField field={locationIdArrayField} alt />
                  </GroupItem>

                  <GroupItem colSpan={1}>
                    <FieldLabel label={issuedFromField.placeholder} />
                    <FormField
                      field={issuedFromField}
                      max={issuedFromField.value}
                      options={{ defaultTime: '00:00:00' }}
                      alt
                    />
                  </GroupItem>
                  <GroupItem colSpan={1}>
                    <FieldLabel label={issuedToField.placeholder} />
                    <FormField
                      field={issuedToField}
                      min={issuedToField.value}
                      options={{ defaultTime: '23:59:59' }}
                      alt
                    />
                  </GroupItem>

                  <GroupItem>
                    <CheckBox value={isDefault} onValueChanged={(e) => setDefault(e.value)} />{' '}
                    {translate!('Is default', transFormRepSelector)}
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={3} colCount={3}>
                  <GroupItem>
                    <FieldLabel label={productIdField.placeholder} />
                    <FormField field={productIdField} alt />
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={3} colCount={3} cssClass={'action-buttons-view'}>
                  {dialogAction === FilterActionState.EDIT && <DeleteButton ref={selectorRef} />}
                  <Button
                    className="button-spacing"
                    text={translate!('Save', transFormRepSelector)}
                    icon="save"
                    type="success"
                    useSubmitBehavior
                    disabled={loading}
                  />
                </GroupItem>
              </Form>
            </form>
          </Popup>
        )}
      </div>
      <form onSubmit={onSearch} style={{ display: filterVisibility ? 'block' : 'none' }}>
        <Form colCount={7} className="popup-container" style={{ justify: 'center' }}>
          <GroupItem>
            <FieldLabel label={assetIdArrayField.placeholder} />
            <FormField field={assetIdArrayField} alt />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={locationIdArrayField.placeholder} />
            <FormField field={locationIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={productIdField.placeholder} />
            <FormField field={productIdField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={issuedFromField.placeholder} />
            <FormField
              field={issuedFromField}
              max={issuedToField.value}
              options={{ defaultTime: '00:00:00' }}
            />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={issuedToField.placeholder} />
            <FormField
              field={issuedToField}
              min={issuedFromField.value}
              options={{ defaultTime: '23:59:59' }}
            />
          </GroupItem>
          <GroupItem cssClass={'action-buttons-view'}>
            <Button
              className="button-spacing"
              type="success"
              text={translate!('Search', transFormReportBar)}
              useSubmitBehavior
              disabled={loading}
            />
          </GroupItem>
        </Form>
      </form>
    </>
  );
}
