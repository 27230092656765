import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import React, { useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { Step } from '../../types/step.types';
import './TasksBulkEditModal.scss';

type Props = {
  stepList: Step[];
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
};

export default function StepGroupStepModal({ stepList, isVisible, onClose, onSubmit }: Props) {
  const { translate } = useTranslation();
  const transFormName = 'StepGroup.List';
  const formRef = useRef<Form>(null);
  const [formData, setFormData] = useState<any>({});

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Assign step to group', transFormName)}
      width={'auto'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="stepId"
              editorType="dxSelectBox"
              editorOptions={{
                items: stepList,
                valueExpr: 'id',
                displayExpr: 'name',
                width: 300,
              }}
            >
              <Label text={translate!('Step', transFormName)} />
              <RequiredRule message={translate!('Step is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Close', '')}
                onClick={onClose}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
