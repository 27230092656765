import { Button, DropDownButton } from 'devextreme-react';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import HelpPopup from '../components/modals/HelpModal';
import { useTerminalAuth } from '../contexts/terminalAuthContext';
import { useTranslation } from '../contexts/translation';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { swrConfig } from '../pages/Assets/AssetsEdit';
import { AppInfo } from '../types/appInfo.types';
import { API_URL, fetcher } from '../utils/apiUrl';
import { STORAGE_KEYS } from '../utils/localStorageKeys';
import './TerminalBottomToolbar.scss';

enum UserPanelAction {
  SIGN_IN,
  SIGN_OUT,
  CHANGE_LANG,
  TERMINAL_MAPPING_REDIRECT,
}

// eslint-disable-next-line react/prop-types
export const TerminalBottomToolbar = ({ children }) => {
  const { isAuthenticated, user, signOut, isTechnicalUser, terminalMappings } = useTerminalAuth();
  const [helpPopupVisibility, setHelpPopupVisibility] = useState<boolean>(false);
  const history = useHistory();
  const { translate, changeLang } = useTranslation();
  const transFormName = 'Terminal.Common';
  const [sessionTerminalMappingId] = useSessionStorage(STORAGE_KEYS.TERMINAL_MAPPING_ID, {});

  // Načteme informaci o aplikaci
  const { data: appInfo } = useSWR<AppInfo>(`${API_URL.APP_INFO}`, fetcher, swrConfig);
  // Načteme seznam dostupných jazyků
  const { data: languages } = useSWR(`${API_URL.LANGUAGE_LIST}`, fetcher, swrConfig);

  /**
   * Obslouží kliknutí na položku uživatelského panelu.
   * @param id
   */
  const handleUserPanelItemClick = (e) => {
    if (e.itemData.id === UserPanelAction.SIGN_IN && signOut) {
      history.push('/terminal/login/NonTechnicalUser');
    }
    if (
      (e.itemData.id === UserPanelAction.SIGN_OUT ||
        e.itemData.id === UserPanelAction.TERMINAL_MAPPING_REDIRECT) &&
      signOut
    ) {
      signOut();
      return;
    }
    if (e.itemData.id === UserPanelAction.CHANGE_LANG && changeLang) {
      changeLang(e.itemData.langKey);
    }
  };

  const languagesItems = languages
    ? Object.keys(languages).map((key) => {
        return {
          id: UserPanelAction.CHANGE_LANG,
          name: languages[key],
          langKey: key,
          icon: 'isblank',
        };
      })
    : [];

  let userPanelItems = [
    ...[
      isTechnicalUser
        ? {
            id: UserPanelAction.SIGN_IN,
            name: translate!('Login', ''),
            icon: 'user',
          }
        : {
            id: UserPanelAction.SIGN_OUT,
            name: translate!('Logout', ''),
            icon: 'export',
          },
    ],
  ];

  if (isTechnicalUser && terminalMappings && terminalMappings.length > 1) {
    userPanelItems = [
      ...userPanelItems,
      {
        id: UserPanelAction.TERMINAL_MAPPING_REDIRECT,
        name: translate!('Return to terminal mapping', ''),
        icon: 'user',
      },
    ];
  }

  userPanelItems = [...userPanelItems, ...languagesItems];

  const terminalMappingsForAssetName =
    appInfo &&
    !appInfo.assetName &&
    terminalMappings &&
    terminalMappings.length > 0 &&
    sessionTerminalMappingId
      ? terminalMappings.find((x) => x.id === sessionTerminalMappingId)
      : null;
  const terminalMappingAssetName = terminalMappingsForAssetName
    ? terminalMappingsForAssetName.assetName
    : null;

  return (
    <div className={'toolbar-wrapper'}>
      {children}
      {appInfo && languages ? (
        isAuthenticated &&
        user && (
          <div className={'dx-card toolbar footer-toolbar'} style={{ border: '1px solid #ddd' }}>
            <ResponsiveBox>
              <Row />
              <Col ratio={0.2} />
              <Col ratio={0.15} />
              <Col ratio={0.15} />
              {appInfo.currentHostName && <Col ratio={0.2} />}
              <Col ratio={0.2} />
              <Col ratio={0.15} />
              <Col ratio={0.15} />
              <Col ratio={0.05} />
              <Item>
                <Location row={0} col={0} />
                <DropDownButton
                  text={user.fullName}
                  displayExpr="name"
                  keyExpr="id"
                  items={userPanelItems}
                  onItemClick={handleUserPanelItemClick}
                  className="no-border"
                />
              </Item>
              <Item>
                <Location row={0} col={1} />
                <AppInfoColumn
                  title={translate!('Asset name', transFormName)}
                  value={
                    !appInfo.assetName && terminalMappingAssetName
                      ? terminalMappingAssetName
                      : appInfo.assetName
                  }
                />
              </Item>
              <Item>
                <Location row={0} col={2} />
                <AppInfoColumn
                  title={translate!('IP address', transFormName)}
                  value={appInfo.currentIpAddress}
                />
              </Item>
              {appInfo.currentHostName && (
                <Item>
                  <Location row={0} col={3} />
                  <AppInfoColumn
                    title={translate!('HostName', transFormName)}
                    value={appInfo.currentHostName}
                  />
                </Item>
              )}
              <Item>
                <Location row={0} col={appInfo.currentHostName ? 4 : 3} />
                <AppInfoColumn
                  title={translate!('Database name', transFormName)}
                  value={appInfo.database}
                />
              </Item>
              <Item>
                <Location row={0} col={appInfo.currentHostName ? 5 : 4} />
                <AppInfoColumn
                  title={translate!('Server name', transFormName)}
                  value={appInfo.machineName}
                />
              </Item>
              <Item>
                <Location row={0} col={appInfo.currentHostName ? 6 : 5} />
                <AppInfoColumn
                  title={translate!('App version', transFormName)}
                  value={appInfo.version}
                />
              </Item>
              <Item>
                <Location row={0} col={appInfo.currentHostName ? 7 : 6} />
                <Button
                  id="helpButton"
                  icon="help"
                  stylingMode="text"
                  onClick={() => setHelpPopupVisibility(!helpPopupVisibility)}
                />
              </Item>
            </ResponsiveBox>
          </div>
        )
      ) : (
        <div className={'dx-card toolbar footer-toolbar'} style={{ border: '1px solid #ddd' }}>
          Loading app info
        </div>
      )}
      <HelpPopup
        visibility={helpPopupVisibility}
        onClose={() => setHelpPopupVisibility(!helpPopupVisibility)}
      />
    </div>
  );
};

function AppInfoColumn({
  title,
  value,
  component,
}: {
  title: string;
  value?: string;
  component?: ReactElement;
}) {
  return (
    <div style={{ paddingLeft: '7px' }}>
      <div className={'title'}>{title}</div>
      {component || <div className={'value'}>{value}</div>}
    </div>
  );
}
