import { loadMessages, locale } from 'devextreme/localization';
import czMessages from 'devextreme/localization/messages/cs.json';
import enMessages from 'devextreme/localization/messages/en.json';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getItemApi, getListApi, postApi } from '../apiUtils';
import { Language, Translation } from '../types/language.types';
import { API_URL } from '../utils/apiUrl';

/**
 * Kontext pro překlady. Poskytuje informace o aktuálně zvoleném jazyku. Poskytuje seznam překladů.
 * Překlady se načtou při inicializaci kontextu. Při inicializaci se načte aktuální jazyk, podle kterého
 * se nastaví locale celé aplikace (devexpress messages a datum formát).
 * TODO: Kontext by bylo vhodné přepsat na použití SWR.
 */
function TranslationProvider(props: any) {
  const [lang, setLang] = useState<Language>();
  const [translations, setTranslations] = useState<Translation[]>();
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Při inicilizaci načteme aktuální jazyk. Nastaví se devextress locale a messages.
   */
  useEffect(() => {
    (async function () {
      await getItemApi<Language>({
        url: `${API_URL.GET_CURRENT_LANGUAGE}`,
        params: {},
        callAfterSuccess: (result) => {
          loadMessages(result === 'cs-CZ' || result === `sk-SK` ? czMessages : enMessages);
          locale(result);
          setLang(result);
        },
      });
      setLoading(false);
    })();
  }, []);

  /**
   * Při změně aktuálního jazyku dojde k načtení překladů pro daný jazyk.
   */
  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadTranslations();
      setLoading(false);
    })();
  }, [lang]);

  const loadTranslations = () => {
    getListApi<Translation>({
      url: `${API_URL.GET_TRANSLATIONS}`,
      params: {},
      callAfterSuccess: (result) => {
        setTranslations(result);
      },
    });
  };

  /**
   * Změní aktuálně vyvbraný jazyk. Informaci pošle na API.
   */
  const changeLang = useCallback(async (lang: Language) => {
    await postApi<Translation>({
      url: `${API_URL.LANGUAGE_POST}`,
      data: { language: lang },
      callAfterSuccess: () => {
        locale(lang === Language.SK ? Language.CS : lang);
        setLang(lang);
      },
    });
  }, []);

  /**
   * Vrátí překlad z načteného seznamu překladů.
   * @param {string} referenceText ReferenceText, kterému překlad odpovídá.
   * @param {string} formName FormName, kterému překlad odpovídá.
   */
  const translate = useCallback(
    (referenceText: string, formName: string) => {
      if (!translations) return `!${formName}.${referenceText}`;

      const trans = translations.find(
        (x) => x.formName === formName && x.referenceText === referenceText,
      );
      if (trans !== undefined) return trans.translationText;
      else return `!${formName}.${referenceText}`;
    },
    [translations],
  );

  return (
    <TranslationContext.Provider
      value={{ lang, translations, changeLang, translate, loadTranslations, loading }}
      {...props}
    />
  );
}

const TranslationContext = createContext<TranslationContextType>({
  lang: Language.EN,
  translations: [],
  loading: false,
});

interface TranslationContextType {
  lang: Language;
  translations: Translation[];
  loading: boolean;
  changeLang?: (lang: Language) => Promise<void>;
  translate?: (referenceText: string, formName: string) => string;
  loadTranslations?: () => void;
}

const useTranslation = () => useContext<TranslationContextType>(TranslationContext);

export { TranslationProvider, useTranslation };
