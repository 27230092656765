import React from 'react';
import { TabFiles } from '../../../components/fileManager/TabFiles';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { ITab } from '../../Assets/AssetsEdit';

interface Props extends ITab {
  id: string;
  mutateAttachmentCount?: () => void;
}

export const TaskFilesTab = ({ id, mutateAttachmentCount }: Props) => {
  return (
    <PageContentWrapper noLoadingModal noContentBlock noDxCard>
      <TabFiles
        id={id as unknown as number}
        entityName="Task"
        isReadonly={false}
        onFileCountChange={mutateAttachmentCount ? mutateAttachmentCount : undefined}
      />
    </PageContentWrapper>
  );
};
