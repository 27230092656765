import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';

export const Columns = (
  handleEdit,
  handleDelete,
  isReadOnly,
  translate,
  isCycleMonthly,
): SisDataGridColumns => [
  {
    dataField: 'stepGroupName',
    caption: 'Step group name',
    dataType: IDataType.string,
    width: '0%',
    groupIndex: 0,
    cellRender: (data) =>
      ButtonCellGroupHeader(data, handleEdit, handleDelete, isReadOnly, translate),
  },
  {
    dataField: 'stepName',
    caption: 'Step name',
    dataType: IDataType.string,
    width: '31%',
  },
  {
    dataField: 'capacityCategoryName',
    caption: 'Capacity category name',
    dataType: IDataType.string,
    width: '31%',
  },
  {
    dataField: 'frequency',
    caption: 'Frequency',
    dataType: IDataType.number,
    width: '10%',
    cellRender: (cell) => {
      if (isCycleMonthly) {
        switch (cell.data.frequency) {
          case 1:
            return <>{translate!('Monthly', 'Asset.CycleStep')}</>;
          case 3:
            return <>{translate!('Quarterly', 'Asset.CycleStep')}</>;
          case 6:
            return <>{translate!('Semi-annually', 'Asset.CycleStep')}</>;
          case 12:
            return <>{translate!('Annually', 'Asset.CycleStep')}</>;
        }
      }
      return <>{cell.data.frequency}</>;
    },
  },
  {
    dataField: 'counter',
    caption: 'Counter',
    dataType: IDataType.number,
    width: '10%',
    cellRender: (cell) => {
      if (cell.data.frequency === 1) return <></>;
      return <>{cell.data.counter}</>;
    },
  },
  {
    dataField: 'estimatedOccurrence',
    caption: 'Estimated occurrence',
    dataType: IDataType.date,
    width: '10%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, handleDelete, isReadOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, handleDelete, readOnly, translate) => {
  if (cellData.data.stepGroupId) return <></>;
  return (
    !readOnly && (
      <>
        <DataGridButton
          hint={translate!('Edit', '')}
          icon="edit"
          cellData={cellData}
          onClick={() => handleEdit(cellData)}
        />
        <DataGridButton
          hint={translate!('Delete', '')}
          icon="remove"
          cellData={cellData}
          onClick={() => handleDelete(cellData)}
        />
      </>
    )
  );
};

const ButtonCellGroupHeader = (cellData, handleEdit, handleDelete, readOnly, translate) => {
  if (!cellData.data.items[0].stepGroupId) return <div>{cellData.displayValue}</div>;
  return (
    !readOnly && (
      <div style={{ display: 'flex', flexGrow: 2 }}>
        <div>{cellData.displayValue}</div>
        <div style={{ flexGrow: '1', textAlign: 'right', paddingRight: '42px' }}>
          <DataGridButton
            hint={translate!('Edit', '')}
            icon="edit"
            cellData={cellData}
            onClick={() => handleEdit(cellData)}
          />
          <DataGridButton
            hint={translate!('Delete', '')}
            icon="remove"
            cellData={cellData}
            onClick={() => handleDelete(cellData)}
          />
        </div>
      </div>
    )
  );
};
