import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi, putApi } from '../../../apiUtils';
import { AssetTabParameterMeasuredDataGrid } from '../../../components/datagrid/AssetTabParameterMeasuredDataGrid';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { AssetCycleInputs } from '../../../types/assetCycle.types';
import { DataGridActionType } from '../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../types/pageParams.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { MomentDateSerializationFormat } from '../../../utils/dates.types';
import { uniqueLookUpByPropertyAndOriginalBack } from '../../../utils/uniqueLookUpByAttribute';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
}

export function AssetTabParameterMeasured({ isReadonly, active }: Props) {
  const { id } = useParams<DetailPageParams>();
  const apiUrlList = `${API_URL.ASSET_PARAMETER_MEASURED_ASSET_LIST}?visibleOnly=false&assetId=${id}`;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetCycleInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number>();

  // Načteme měření
  const {
    data: assetParametersMeasured,
    error: errorAssetParametersMeasured,
    mutate: mutateAssetParamsMeas,
    isValidating: assetIsValidating,
  } = useSWR(apiUrlList, fetcher, assetEditSwrConfig);
  // Načteme jednotky
  const {
    data: units,
    error: errorUnits,
    mutate: mutateUnits,
    isValidating: unitsIsValidating,
  } = useSWR(`${API_URL.UNIT_LIST}?visibleOnly=false`, fetcher, assetEditSwrConfig);
  // Načteme číselník - AssetParameter
  const {
    data: assetParameters,
    error: errorAssetParameters,
    mutate: mutateAssetParameters,
    isValidating: assetParamsIsValidating,
  } = useSWR(
    `${API_URL.ASSET_PARAMETER_MEASURABLE_LIST}?parentId=${id}`,
    fetcher,
    assetEditSwrConfig,
  );

  useEffect(() => {
    if (active) {
      mutateAssetParamsMeas();
      mutateAssetParameters();
      mutateUnits();
    }
  }, [active]);

  const assetParameterChanged = (val) => {
    if (!assetParameters) return;

    let selectedParam = assetParameters.find((p: any) => p.id === val.value);
    let tmpUnit = selectedParam?.unitId;
    if (tmpUnit) setSelectedUnitId(tmpUnit);
  };

  /**
   * Metoda vrací data pro sloupec "unit". Data jsou profilrována podle zvoleného parametru.
   */
  const getFilteredUnits = useCallback(
    (options) => {
      if (!options.data) {
        return {
          store: uniqueLookUpByPropertyAndOriginalBack(assetParameters, 'unitName'),
          filter: null,
        };
      }
      return {
        store: assetParameters,
        filter: options.data ? ['id', '=', options.data.assetParameterId] : null,
      };
    },
    [assetParameters],
  );

  const handleApiCall = async ({ type, key, data: _data }) => {
    let tmpMeasureToDate = _data.measureToDate
      ? moment(_data.measureToDate).format(MomentDateSerializationFormat)
      : null;
    let tmpValueDate = _data.valueDate
      ? moment(_data.valueDate).format(MomentDateSerializationFormat)
      : null;
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_PARAMETER_MEASURED_POST,
          data: {
            ..._data,
            assetId: id,
            valueDate: tmpValueDate,
            measureToDate: tmpMeasureToDate,
            value: null,
          },
        });
        break;
      case DataGridActionType.Update:
        const oldData = assetParametersMeasured?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_PARAMETER_MEASURED_PUT,
            data: {
              ...oldData,
              ..._data,
              valueDate: tmpValueDate,
              measureToDate: tmpMeasureToDate,
              value: null,
            },
          }));
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateAssetParamsMeas();
    handleClose();
  };

  const handleNew = () => {
    console.log('new');
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const onSubmit = (formData: AssetCycleInputs, type?: DataGridActionType) => {
    handleApiCall({ type, key: selectedRowData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  return (
    <PageContentWrapper
      noDxCard
      noContentBlock
      error={errorAssetParameters || errorAssetParametersMeasured || errorUnits}
      loading={assetParamsIsValidating || assetIsValidating || unitsIsValidating}
    >
      <>
        <AssetTabParameterMeasuredDataGrid
          isReadOnly={isReadonly}
          // onSaving={onSaving}
          assetParameterChanged={assetParameterChanged}
          assetParameters={assetParameters}
          getFilteredUnits={getFilteredUnits}
          data={assetParametersMeasured}
          units={units}
          modalVisible={modalVisible}
          handleEdit={handleEdit}
          handleNew={handleNew}
          handleClose={handleClose}
          onDataSubmit={onSubmit}
          selectedData={selectedRowData}
          selectedAction={selectedAction}
          selectedUnitId={selectedUnitId}
        />
      </>
    </PageContentWrapper>
  );
}
