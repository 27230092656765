import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';
import { AttachmentCountFormat } from '../../../utils/customRenderer';
import { TimeSpanCell, TimeSpanCellSortValue } from '../../Tasks/Columns';
import ChartCell from '../../WorkSheets/Columns';
import '../Reports.scss';

export const Columns = (handleEdit, history, translate, pathname, setPath): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'taskCustomId',
    width: '100px',
    caption: 'Task id',
    cellRender: (cellData) => {
      return (
        <span
          className="context-link"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
            // stisknuto levé tlačítko
            if (e.nativeEvent.button === 0)
              return history.push({
                pathname: `/tasks/edit/${cellData.data.taskId}`,
                state: { backRoute: pathname },
              });
            // nastavit cestu pro context menu
            setPath({
              path: `/tasks/edit/${cellData.data.taskId}`,
              backRoute: pathname,
            });
          }}
        >
          {cellData.data[cellData.column.dataField]}
        </span>
      );
    },
  },
  {
    dataField: 'taskName',
    width: '100px',
    caption: 'Task name',
    lookupPickBy: 'taskName',
  },
  {
    dataField: 'taskAssetName',
    width: '100px',
    caption: 'Asset name',
    lookupPickBy: 'taskAssetName',
  },
  {
    dataField: 'taskAssetTopLevelParenName',
    width: '100px',
    caption: 'Top level asset name',
  },
  {
    dataField: 'workActivityCapacityCategoryName',
    width: '100px',
    caption: 'Capacity Category',
  },
  {
    dataField: 'workActivityName',
    width: '100px',
    caption: 'Activity',
    lookupPickBy: 'workActivityName',
  },
  {
    dataField: 'workSheetStatusNameTranslated',
    caption: 'Status',
    width: '100px',
    cellRender: ChartCell,
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'operatorName',
    width: '100px',
    caption: 'Operator',
    lookupPickBy: 'operatorName',
  },
  {
    dataField: 'activityStarted',
    width: '100px',
    caption: 'Activity Start',
    dataType: IDataType.dateTime,
  },
  {
    dataField: 'activityFinished',
    width: '100px',
    caption: 'Activity Finish',
    dataType: IDataType.dateTime,
  },
  {
    dataField: 'activityDuration',
    width: '100px',
    caption: 'Activity Duration',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'activityDuration');
    },
  },
  {
    dataField: 'description',
    width: '100px',
    caption: 'Description',
    lookupPickBy: 'description',
  },
  {
    dataField: 'attachmentsCount',
    width: '100px',
    caption: 'Attachment count',
    cellRender: AttachmentCountFormat,
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '100px',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={handleEdit}
    />
  );
};
