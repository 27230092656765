import Form, {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { IForm } from '../../types/iForm';
import { notEqualZero } from '../../utils/dateValidation';
import { formTranslationByEdit } from '../../utils/formTranslationByEdit';

export interface IUnitsForm extends IForm {
  baseValueDataItems?: any;
}

export const UnitsForm = ({ onSubmit, formData, baseValueDataItems, isEdit }: IUnitsForm) => {
  const { translate } = useTranslation();
  const transFormName = formTranslationByEdit('Unit', isEdit);
  const { loading } = useLoading();
  const history = useHistory();
  const backRoute = '/units';
  const formRef = useRef<Form>(null);
  const [prompt, setDirty, setClean, handleCloseButton] = useUnsavedChangesWarning(
    backRoute,
    () => {
      if (!formRef) return;
      if (formRef.current!.instance.validate().isValid) {
        setClean();
        onSubmit(null).then((result) => {
          if (result) history.push(backRoute);
          else setDirty();
        });
      }
    },
  );

  return (
    <form
      onSubmit={(e) => {
        onSubmit(e);
        if (formRef && formRef.current && !isEdit) formRef.current?.instance.resetValues();
        // @ts-ignore
        setClean();
      }}
    >
      <Form
        colCount={1}
        formData={formData}
        showValidationSummary={true}
        onFieldDataChanged={() => {
          // @ts-ignore
          setDirty();
        }}
        ref={formRef}
      >
        <GroupItem>
          <SimpleItem dataField="name" editorType="dxTextBox">
            <Label text={translate!('Name', transFormName)} />
            <RequiredRule message={translate!('Enter name', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="baseValueDataType"
            editorType="dxSelectBox"
            editorOptions={{
              items: baseValueDataItems,
              valueExpr: 'value',
              displayExpr: 'name',
            }}
          >
            <Label text={translate!('Data type', transFormName)} />
            <RequiredRule message={translate!('Select data type', transFormName)} />
            <CustomRule
              validationCallback={notEqualZero}
              type={'custom'}
              message={`${translate!('Data type', transFormName)} is required`}
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="description" editorType="dxTextArea">
            <Label text={translate!('Description', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="visible" editorType="dxCheckBox">
            <Label text={translate!('Visible', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem colCount={14}>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              width={'100%'}
              text={translate!('Close', 'Asset.Update')}
              type={'normal'}
              useSubmitBehavior={false}
              onClick={handleCloseButton}
            />
          </ButtonItem>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              width={'100%'}
              disabled={loading}
              text={translate!('Save', transFormName)}
              type={'success'}
              useSubmitBehavior
            />
          </ButtonItem>
        </GroupItem>
        {prompt}
      </Form>
    </form>
  );
};
