import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './assets/style.scss';
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement,
);

//registerServiceWorker();
