import Form, { ButtonItem, ButtonOptions, GroupItem, SimpleItem } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Platform } from '../../components/loginForm/LoginForm';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { IForm } from '../../types/iForm';
import './AddNewComment.scss';

interface Props extends IForm {
  onInitialize: (any) => void;
  closeModal: () => void;
  modalVisible?: boolean;
  platform: string;
}
export const AddNewCommentForm = ({
  onInitialize,
  onSubmit,
  formData,
  closeModal,
  modalVisible,
  platform,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'Task.Update';
  const commentRef = useRef<Form>(null);
  const { pathname } = useLocation();
  const { loading } = useLoading();
  const [Prompt, setDirty, setClean, _, __, handleDirtyWithCallback] =
    useUnsavedChangesWarning(pathname);

  const inputTextareaCss =
    'textarea-comments-' +
    (platform === Platform.MOBILE
      ? 'mobile'
      : platform === Platform.TERMINAL
      ? 'terminal'
      : 'desktop');

  const buttonsCss =
    'button-comments-' +
    (platform === Platform.MOBILE
      ? 'mobile'
      : platform === Platform.TERMINAL
      ? 'terminal'
      : 'desktop');

  useEffect(() => {
    return () => {
      // @ts-ignore
      setClean();
    };
  }, [modalVisible]);
  const afterClose = () => {
    // @ts-ignore
    handleDirtyWithCallback(() => {
      closeModal();
    });
  };
  return (
    <form
      onSubmit={(e) => {
        // @ts-ignore
        setClean();
        onSubmit(e);
      }}
    >
      <Form
        ref={commentRef}
        colCount={4}
        formData={formData}
        onInitialized={() => onInitialize(commentRef)}
        showValidationSummary={true}
        focusStateEnabled={true}
        onFieldDataChanged={(e) => {
          // @ts-ignore
          setDirty();
          return e;
        }}
      >
        <GroupItem colSpan={4}>
          <SimpleItem
            dataField="comment"
            editorType="dxTextArea"
            label={{ visible: false }}
            cssClass={inputTextareaCss}
          >
            <RequiredRule message={translate!('Enter comment', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <ButtonItem
          verticalAlignment={'center'}
          horizontalAlignment={'right'}
          cssClass={buttonsCss}
        >
          <ButtonOptions
            text={translate!('Close', transFormName)}
            type={'normal'}
            width={'100%'}
            useSubmitBehavior={false}
            onClick={afterClose}
          />
        </ButtonItem>
        <ButtonItem
          verticalAlignment={'center'}
          horizontalAlignment={'right'}
          cssClass={buttonsCss}
        >
          <ButtonOptions
            text={translate!('Save', transFormName)}
            type={'success'}
            width={'100%'}
            useSubmitBehavior={false}
            onClick={() => onSubmit(null)}
            disabled={loading}
          />
        </ButtonItem>
      </Form>
      {Prompt}
    </form>
  );
};
