import { LoadPanel } from 'devextreme-react';
import React from 'react';

interface Props {
  visible?: boolean;
  message?: string;
  closeOnOutsideClick?: boolean;
  onHiding?: () => void;
}
/**
 * Komponenta pro zobrazení načítání. Používána napříč celým systémem. Nyní využívá
 * loader knihovny devexpressu. Díky zapouzdření lze lehce zaměnit za jakýkoliv jiný loader.
 * @constructor
 */
export function Loader({ visible, message, closeOnOutsideClick, onHiding }: Props) {
  return (
    <LoadPanel
      message={message ? message : 'Loading'}
      visible={visible ?? true}
      closeOnOutsideClick={closeOnOutsideClick}
      onHiding={onHiding}
    />
  );
}
