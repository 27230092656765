import { Transactions } from '../components/transactions/Transactions';
import { AssetClasses } from '../pages/AssetClasses/AssetClasses';
import { AssetClassParameters } from '../pages/AssetClassParameters/AssetClassParameters';
import { AssetClassParametersEdit } from '../pages/AssetClassParameters/AssetClassParametersEdit';
import { AssetClassParametersNew } from '../pages/AssetClassParameters/AssetClassParametersNew';
import { Assets } from '../pages/Assets/Assets';
import { AssetsEdit } from '../pages/Assets/AssetsEdit';
import { AssetsNew } from '../pages/Assets/AssetsNew';
import { AssetStatuses } from '../pages/AssetStatuses/AssetStatuses';
import { AssetTypes } from '../pages/AssetTypes/AssetTypes';
import { AssetTypesEdit } from '../pages/AssetTypes/AssetTypesEdit';
import { AssetTypesNew } from '../pages/AssetTypes/AssetTypesNew';
import { CapacityCategories } from '../pages/CapacityCategories/CapacityCategories';
import { CapacityCategoriesEdit } from '../pages/CapacityCategories/CapacityCategoriesEdit';
import { CapacityCategoriesNew } from '../pages/CapacityCategories/CapacityCategoriesNew';
import { CapacityCategoriesPersons } from '../pages/CapacityCategoriesPersons/CapacityCategoriesPersons';
import { CapacityCategoriesPersonsEdit } from '../pages/CapacityCategoriesPersons/CapacityCategoriesPersonsEdit';
import { CapacityCategoriesPersonsNew } from '../pages/CapacityCategoriesPersons/CapacityCategoriesPersonsNew';
import HomePage from '../pages/Home/HomePage';
import { Parameters } from '../pages/Parameters/Parameters';
import { ParametersEdit } from '../pages/Parameters/ParametersEdit';
import { ParametersNew } from '../pages/Parameters/ParametersNew';
import { ProfilePage } from '../pages/Profile/ProfilePage';
import { ResponsiblePersonReport } from '../pages/Reports/AssetResponsiblePerson/ResponsiblePersonReport';
import { AssetReport } from '../pages/Reports/Assets/AssetReport';
import GeneralReport from '../pages/Reports/General/GeneralReport';
import { GroupedWorkSheetReport } from '../pages/Reports/GroupedWorkSheets/GroupedWorkSheetReport';
import { SparePartsReport } from '../pages/Reports/SpareParts/SparePartsReport';
import { TaskReport } from '../pages/Reports/Tasks/TaskReport';
import { WorkSheetReport } from '../pages/Reports/WorkSheets/WorkSheetReport';
import Steps from '../pages/Step/Steps';
import StepsEdit from '../pages/Step/StepsEdit';
import StepsNew from '../pages/Step/StepsNew';
import StepGroups from '../pages/StepGroup/StepGroups';
import StepGroupsEdit from '../pages/StepGroup/StepGroupsEdit';
import StepGroupsNew from '../pages/StepGroup/StepGroupsNew';
import TaskAssistancePanelDesktop from '../pages/TaskAssistancePanelDesktop/TaskAssistancePanelDesktop';
import TaskNotificationConfigs from '../pages/TaskNotificationConfig/TaskNotificationConfig';
import TaskNotificationConfigsEdit from '../pages/TaskNotificationConfig/TaskNotificationConfigEdit';
import { TaskNotificationConfigsNew } from '../pages/TaskNotificationConfig/TaskNotificationConfigNew';
import { Tasks } from '../pages/Tasks/Tasks';
import { TasksEdit } from '../pages/Tasks/TasksEdit';
import { TasksMobileView } from '../pages/Tasks/TasksMobileView';
import { TasksNew } from '../pages/Tasks/TasksNew';
import { TimePeriods } from '../pages/TimePeriods/TimePeriods';
import { TimePeriodsClose } from '../pages/TimePeriods/TimePeriodsClose';
import { TimePeriodsOpen } from '../pages/TimePeriods/TimePeriodsOpen';
import { Units } from '../pages/Units/Units';
import { UnitsEdit } from '../pages/Units/UnitsEdit';
import { UnitsNew } from '../pages/Units/UnitsNew';
import WorkActivity from '../pages/WorkActivities/WorkActivity';
import { WorkActivityGroupDetail } from '../pages/WorkActivityGroups/WorkActivityGroupDetail';
import WorkActivityGroups from '../pages/WorkActivityGroups/WorkActivityGroups';
import { WorkSheets } from '../pages/WorkSheets/WorkSheets';
import { WorkSheetsEdit } from '../pages/WorkSheets/WorkSheetsEdit';
import { WorkSheetsNew } from '../pages/WorkSheets/WorkSheetsNew';
import { UserRoles } from '../types/applicationUser.types';

export const routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/assets',
    component: Assets,
    restrictedAccess: [UserRoles.AssetRead, UserRoles.AssetEdit],
  },
  {
    path: '/assets/new',
    component: AssetsNew,
    restrictedAccess: [UserRoles.AssetEdit],
  },
  {
    path: '/assets/new/:id',
    component: AssetsNew,
    restrictedAccess: [UserRoles.AssetEdit],
  },
  {
    path: '/assets/edit/:id',
    component: AssetsEdit,
    restrictedAccess: [UserRoles.AssetRead, UserRoles.AssetEdit],
  },
  {
    path: '/asset-classes',
    component: AssetClasses,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-class-parameters',
    component: AssetClassParameters,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-class-parameters/new',
    component: AssetClassParametersNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-class-parameters/edit/:id',
    component: AssetClassParametersEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-types',
    component: AssetTypes,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-types/edit/:id',
    component: AssetTypesEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-types/new/',
    component: AssetTypesNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/asset-statuses',
    component: AssetStatuses,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories',
    component: CapacityCategories,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories/new',
    component: CapacityCategoriesNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories/edit/:id',
    component: CapacityCategoriesEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories-persons',
    component: CapacityCategoriesPersons,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories-persons/new',
    component: CapacityCategoriesPersonsNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/capacity-categories-persons/edit/:id',
    component: CapacityCategoriesPersonsEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/parameters',
    component: Parameters,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/parameters/new',
    component: ParametersNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/parameters/edit/:id',
    component: ParametersEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/units',
    component: Units,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/units/new',
    component: UnitsNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/units/edit/:id',
    component: UnitsEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/tasks',
    component: Tasks,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/tasks-mobile-view',
    component: TasksMobileView,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/tasks/new',
    component: TasksNew,
    restrictedAccess: [UserRoles.TaskCreate],
  },
  {
    path: '/tasks/new/:id',
    component: TasksNew,
    restrictedAccess: [UserRoles.TaskCreate],
  },
  {
    path: '/tasks/edit/:id',
    component: TasksEdit,
  },
  {
    path: '/tasks/notification-config',
    component: TaskNotificationConfigs,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/tasks/notification-config/new',
    component: TaskNotificationConfigsNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/tasks/notification-config/edit/:id',
    component: TaskNotificationConfigsEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/workSheets/new/:assetId/:taskId',
    component: WorkSheetsNew,
    restrictedAccess: [UserRoles.WorksheetAll, UserRoles.WorksheetOwn],
  },
  {
    path: '/workSheets',
    component: WorkSheets,
    restrictedAccess: [UserRoles.WorksheetAll, UserRoles.WorksheetOwn],
  },
  {
    path: '/workSheets/edit/:id',
    component: WorkSheetsEdit,
  },
  {
    path: '/work-activity-groups',
    component: WorkActivityGroups,
  },
  {
    path: '/work-activity-groups/detail/:id',
    component: WorkActivityGroupDetail,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/work-activity-groups/detail',
    component: WorkActivityGroupDetail,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/work-activities',
    component: WorkActivity,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/steps',
    component: Steps,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/steps/edit/:id',
    component: StepsEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/steps/new',
    component: StepsNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/step-groups',
    component: StepGroups,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/step-groups/edit/:id',
    component: StepGroupsEdit,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/step-groups/new',
    component: StepGroupsNew,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/task-assistance-panels',
    component: TaskAssistancePanelDesktop,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/reports/1/:settingId?',
    component: ResponsiblePersonReport,
  },
  {
    path: '/reports/2/:settingId?',
    component: TaskReport,
  },
  {
    path: '/reports/3/:settingId?',
    component: WorkSheetReport,
    restrictedAccess: [UserRoles.WorksheetAll, UserRoles.WorksheetOwn],
  },
  {
    path: '/reports/5/:settingId?',
    component: AssetReport,
  },
  {
    path: '/reports/6/:settingId?',
    component: SparePartsReport,
  },
  {
    path: '/reports/7/:settingId?',
    component: GroupedWorkSheetReport,
  },
  {
    path: '/generalReports/:id/:settingId?',
    component: GeneralReport,
  },
  {
    path: '/transactions',
    component: Transactions,
  },
  {
    path: '/timeperiods',
    component: TimePeriods,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/timeperiods/close/:id',
    component: TimePeriodsClose,
    restrictedAccess: [UserRoles.Admin],
  },
  {
    path: '/timeperiods/open/:id',
    component: TimePeriodsOpen,
    restrictedAccess: [UserRoles.Admin],
  },
];

export default routes;
/*
const AssetTypesNew = React.lazy(() => import('../pages/AssetTypes/AssetTypesNew'));
const AssetTypesEdit =  React.lazy(() => import('../pages/AssetTypes/AssetTypesEdit'));
const AssetStatuses = React.lazy(() => import('../pages/AssetStatuses/AssetStatuses'));
const CapacityCategories = React.lazy(() => import('../pages/CapacityCategories/CapacityCategories'));
const CapacityCategoriesNew = React.lazy(() => import('../pages/CapacityCategories/CapacityCategoriesNew'));
const CapacityCategoriesEdit = React.lazy(() => import('../pages/CapacityCategories/CapacityCategoriesEdit'));
const CapacityCategoriesPersons = React.lazy(() => import('../pages/CapacityCategoriesPersons/CapacityCategoriesPersons'));
const CapacityCategoriesPersonsNew = React.lazy(() => import('../pages/CapacityCategoriesPersons/CapacityCategoriesPersonsNew'));
const Parameters =   React.lazy(() => import('../pages/Parameters/Parameters'));
const ParametersNew = React.lazy(() => import('../pages/Parameters/ParametersNew'));
const ParametersEdit =  React.lazy(() => import('../pages/Parameters/ParametersEdit'));
const Units =  React.lazy(() => import( '../pages/Units/Units'));
const UnitsNew =  React.lazy(() => import('../pages/Units/UnitsNew'));
const UnitsEdit =  React.lazy(() => import('../pages/Units/UnitsEdit'));
const AssetsNew =  React.lazy(() => import('../pages/Assets/AssetsNew'));
const CapacityCategoriesPersonsEdit =  React.lazy(() => import('../pages/CapacityCategoriesPersons/CapacityCategoriesPersonsEdit'));
const ResponsiblePersonReport =  React.lazy(() => import('../pages/Reports/ResponsiblePersonReport'));
const Transactions = React.lazy(() => import('../components/transactions/Transactions'));
const AssetClassParameters =  React.lazy(() => import('../pages/AssetClassParameters/AssetClassParameters'));
const AssetClassParametersNew = React.lazy(() => import('../pages/AssetClassParameters/AssetClassParametersNew'));
const AssetClassParametersEdit=  React.lazy(() => import('../pages/AssetClassParameters/AssetClassParametersEdit'));
const Tasks =  React.lazy(() => import('../pages/Tasks/Tasks'));
const TasksNew =  React.lazy(() => import('../pages/Tasks/TasksNew'));
const TasksEdit =  React.lazy(() => import('../pages/Tasks/TasksEdit'));
 */
