import { map, not, path, pick } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';

export interface ILookUpIdName {
  id: string;
  name: string;
}
export interface ILookUpName {
  name: string;
}
export const uniqueLookUpByAttribute = (
  data: Array<any>,
  pathAttribute: Array<string>,
  pickBy: Array<string>,
) => {
  const uniqueValuesSet = new Set();
  const a: ILookUpIdName[] = map((x): any => {
    // @ts-ignore
    const { name }: ILookUpName = pick(pickBy, path(pathAttribute, x));
    return { id: name, name: name };
  })(data);
  return a.filter((obj) => {
    const isPresentInSet = uniqueValuesSet.has(obj.name);
    uniqueValuesSet.add(obj.name);
    return !isPresentInSet;
  });
};

export const uniqueLookUpByProperty = (data: Array<any>, pickBy: string) => {
  if (isNilOrEmpty(data)) {
    return;
  }
  return [
    // @ts-ignore
    ...new Map(
      data.reduce(
        (acc, item) =>
          not(isNilOrEmpty(item[pickBy]))
            ? [...acc, [item[pickBy], { id: item[pickBy], name: item[pickBy] }]]
            : acc,
        [],
      ),
    ).values(),
  ];
};

export const uniqueLookUpByPropertyAndOriginalBack = (data: Array<any>, pickBy: string) => {
  if (isNilOrEmpty(data)) {
    return;
  }
  return [
    // @ts-ignore
    ...new Map(
      data.reduce(
        (acc, item) => (not(isNilOrEmpty(item[pickBy])) ? [...acc, [item[pickBy], item]] : acc),
        [],
      ),
    ).values(),
  ];
};
