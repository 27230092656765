import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from '../../contexts/translation';
import { useClearButtonProps } from '../../hooks/useClearButtonProps';
import { Columns } from '../../pages/Tasks/Columns';
import { TasksFilterData } from '../../pages/Tasks/Tasks';
import { paintCellByTaskColor } from '../../pages/Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../../pages/Tasks/utils/paintRowByColor';
import { DetailPageParams } from '../../types/pageParams.types';
import { Task } from '../../types/task.types';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import BulkEditModal from '../modals/TasksBulkEditModal';
import { IColumnType, SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  defaultFilterData?: TasksFilterData;
  onFilterChanged?: (TasksFilterData) => void;
  isDateFilter?: boolean;
  handleCreateWorksheet?: (e) => void;
  handleCopy?: (e) => void;
  mutateData?: () => void;
  storageKey: string;
}

export const AssetTasksDataGrid = ({
  data,
  mutateData,
  handleEdit,
  handleCreateWorksheet,
  handleCopy,
  resetFilter,
  dataGridRef,
  userCanCreate,
  isDateFilter,
  defaultFilterData,
  onFilterChanged,
  storageKey,
}: Props) => {
  const { user } = useAuth();
  const { id: assetId } = useParams<DetailPageParams>();
  const { translate } = useTranslation();
  const transFormName = 'Task.List';
  const history = useHistory();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Array<Task>>([]);
  const [filterData, setFilterData] = useState<TasksFilterData | undefined>(
    isDateFilter ? defaultFilterData : undefined,
  );
  const { pathname } = useLocation();

  useEffect(() => {
    if (!data) return;
    setSelectedRows(data?.filter((item) => selectedRows.map((x) => x.id).includes(item.id)));
  }, [data]);

  const dateFromProps = useClearButtonProps();
  const dateToProps = useClearButtonProps();

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Tasks', transFormName),
    });

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () =>
            history.push({
              pathname: `/tasks/new/`,
              state: { backRoute: pathname, assetId: assetId },
            }),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
    if (isDateFilter && onFilterChanged && filterData) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          template: () => {
            return translate!('Date from', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateFrom,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateFrom: e.value });
            },
            ...dateFromProps,
          },
        },
        {
          location: 'after',
          template: () => {
            return translate!('Date to', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateTo,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateTo: e.value });
            },
            ...dateToProps,
          },
        },
      );
    }
  };

  const columns = Columns(handleEdit, handleCreateWorksheet, handleCopy, user?.roles, translate);

  /**
   * Filtrační a modifikační funkce pro nastavení sloupců pro datagrid v editačním modalu
   */
  const modalColumns = columns
    ?.filter((column) => column?.type !== IColumnType.buttons)
    .map((column) => {
      return { ...column, width: 100 };
    });

  return (
    <>
      {isModalVisible && (
        <BulkEditModal
          data={isModalVisible ? selectedRows : []}
          isVisible={isModalVisible}
          mutate={mutateData}
          onClose={() => setModalVisible(false)}
          columns={modalColumns}
          transFormNameColumns={transFormName}
        />
      )}
      <SisDataGrid
        enableXlsxExport
        exportName={'asset-tasks-data'}
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        stateStoringKey={storageKey}
        columns={columns}
        // columnChooser={false}
        // allowColumnResizing={false}
        // allowColumnReordering={false}
        onToolbarPreparing={onToolbarPreparing}
        onRowPrepared={paintRowByTaskColor}
        onCellPrepared={paintCellByTaskColor}
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeFromConfig={true}
      />
    </>
  );
};
