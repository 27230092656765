import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AssetClassParametersForm } from '../../forms/assetClassParametersEdit/AssetClassParametersForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetClass } from '../../types/assetClass.types';
import { AssetClassParameterInputs } from '../../types/assetClassParameter.types';
import { Parameter } from '../../types/parameter.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function AssetClassParametersNew() {
  const { translate } = useTranslation();
  const transFormName = 'AssetClassParameter.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New asset class parameters',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Asset class parameters', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Číselník - AssetClass
  const { data: dataAssetClasses, error: errorAssetClasses } = useSWR<AssetClass[]>(
    API_URL.ASSET_CLASS_LIST,
    fetcher,
  );
  // Číselník - Parameter
  const { data: dataParameters, error: errorParameters } = useSWR<Parameter[]>(
    API_URL.PARAMETER_LIST,
    fetcher,
  );

  const data: AssetClassParameterInputs = {
    id: 0,
    assetClassId: null,
    parameterId: null,
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!data) return;
    let isOk = false;
    startLoading();
    await postApi<AssetClassParameterInputs>({
      url: API_URL.ASSET_CLASS_PARAMETER_POST,
      data,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!dataAssetClasses || !dataAssetClasses}
      error={errorAssetClasses || errorParameters}
      heading={translate!('New asset class parameter', transFormName)}
    >
      <AssetClassParametersForm
        onSubmit={onSubmit}
        formData={data}
        dataParameters={dataParameters}
        dataAssetClasses={dataAssetClasses}
      />
    </PageContentWrapper>
  );
}
export default AssetClassParametersNew;
