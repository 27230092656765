import React from 'react';
import { IDataType, SisDataGridColumns } from '../../../components/table';

export const Columns = (history): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'taskCustomId',
    width: '10%',
    allowEditing: false,
    caption: 'Task ID',
    cellRender: (cellData) => {
      return (
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => history.push(`/tasks/edit/${cellData.data.taskId}`)}
        >
          {cellData.data[cellData.column.dataField]}
        </span>
      );
    },
  },
  {
    dataField: 'taskName',
    caption: 'Task name',
    width: '10%',
    allowEditing: false,
  },
  {
    dataField: 'taskDocumentTypeNameTranslated',
    caption: 'Task document type',
    width: '10%',
    allowEditing: false,
  },
  {
    dataField: 'taskCreated',
    caption: 'Task created',
    width: '10%',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    allowEditing: false,
  },
  {
    dataField: 'taskCompleted',
    caption: 'Task completed',
    width: '10%',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    allowEditing: false,
  },
  {
    dataField: 'prodId',
    caption: 'ID',
    width: '10%',
    allowEditing: false,
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '30%',
    allowEditing: false,
  },
  {
    dataField: 'qty',
    caption: 'Qty',
    width: '10%',
    allowEditing: false,
  },
];
