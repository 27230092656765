import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import TasksForm, { TaskInputsNew } from '../../forms/tasks/TasksForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DetailPageParams } from '../../types/pageParams.types';
import { Task } from '../../types/task.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function TasksNew() {
  const [defaultFormData, setDefaultFormData] = useState<TaskInputsNew>({});
  const { user } = useAuth();
  const { translate } = useTranslation();
  const transFormName = 'Task.Create';
  const { id } = useParams<DetailPageParams>();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New task',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Tasks', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  /**
   * Načte task podle id.
   */
  const {
    data: task,
    error: taskError,
    mutate: mutateTask,
  } = useSWR<Task>(id ? `${API_URL.TASK_ITEM}?id=${id}` : '', fetcher, swrConfig);

  /**
   * Po načtení tasku je nutné nastavit defaultní hodnoty formuláře.
   */
  useEffect(() => {
    if (!task) return;
    setDefaultFormData({ ...task, assetTopLevelParenId: task.assetTopLevelParenId });
  }, [task]);

  return (
    <PageContentWrapper
      heading={translate!('New task', transFormName)}
      loading={false}
      error={false}
    >
      <TasksForm
        isReadOnly={false}
        defaultFormData={defaultFormData}
        transFormName={'Task.Create'}
        isCopy={!!task}
        isEdit={false}
      />
    </PageContentWrapper>
  );
}
