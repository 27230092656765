import { custom } from 'devextreme/ui/dialog';
import { prop } from 'ramda';

export const confirmDialogActions = {
  CONFIRM: 'CONFIRM',
  CANCEL: 'CANCEL',
};

export interface ConfirmDialog {
  timeout?: number;
  title: string;
  bodyHtml: string;
  confirmButtonTitle: string;
  cancelButtonTitle: string;
}

export const useConfirmDialog = (settings: ConfirmDialog) => {
  const open = () => {
    const myDialog = custom({
      title: settings.title,
      messageHtml: settings.bodyHtml,
      buttons: [
        {
          text: settings.confirmButtonTitle,
          onClick: (e) => {
            return {
              buttonText: e.component.option('text'),
              id: confirmDialogActions.CONFIRM,
            };
          },
        },
        {
          text: settings.cancelButtonTitle,
          onClick: (e) => {
            return {
              buttonText: e.component.option('text'),
              id: confirmDialogActions.CANCEL,
            };
          },
        },
      ],
    });

    if (settings.timeout)
      setTimeout(function () {
        myDialog.hide();
      }, settings.timeout);

    return myDialog.show().then((dialogResult) => {
      return prop('id', dialogResult);
    });
  };
  return { open };
};
