import { all, always, any, ifElse, includes, length, map } from 'ramda';
import { UserRoles } from '../types/applicationUser.types';
import { isNilOrEmpty } from './ramdaUtils';

const checkAllItemsFromLeftInRightArray = (
  leftArray: UserRoles[],
  rightArray: UserRoles[],
): boolean =>
   any(
    // map userRoles, find if contains otherRoles this role. Than sum booleans with OR condiiton
    Boolean,
    map<UserRoles, boolean>((r) => includes(r, leftArray), rightArray),
  );

export const userHasAccess = (userRoles: UserRoles[] | undefined, roles: UserRoles[]): boolean =>
  ifElse(isNilOrEmpty, always(true), (allRoles) =>
    ifElse(
      (r) => isNilOrEmpty(r) && !isNilOrEmpty(allRoles), // userRoles are empty && !otherRoles are not empty => User has not access
      always(false),
      (uR) => {
        if (length(uR) < length(allRoles)) {
          return checkAllItemsFromLeftInRightArray(allRoles, uR);
        } else {
          return checkAllItemsFromLeftInRightArray(uR, allRoles);
        }
      },
    )(userRoles),
  )(roles);
