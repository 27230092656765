import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteApi, postApi, putApi } from '../../apiUtils';
import { useTranslation } from '../../contexts/translation';
import { confirmDialogActions, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { Columns } from '../../pages/Assets/Columns/AssetTabNfcTagsColumns';
import { NfcTag, NfcTagInput } from '../../types/assignTagToAsset.types';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { DetailPageParams } from '../../types/pageParams.types';
import { API_URL } from '../../utils/apiUrl';
import AssetNfcTagModal from '../modals/AssetNfcTagsModal';
import { SisDataGrid } from '../table';

interface Props {
  data: NfcTag[];
  dataGridRef: any;
  mutateData: () => void;
  isReadOnly: boolean;
}

export const AssetNfcTagsDataGrid = ({ data, dataGridRef, mutateData, isReadOnly }: Props) => {
  const { id } = useParams<DetailPageParams>();
  const transFormName = 'Asset.NfcTag';
  const { translate } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<NfcTagInput | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.NFC_TAG_POST,
          data: { ..._data, assetId: +id },
        });
        break;
      case DataGridActionType.Update:
        const oldData = data.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.NFC_TAG_PUT,
            data: { ...oldData, ..._data, assetId: +id },
          }));
        break;
      case DataGridActionType.Remove:
        await deleteApi({
          url: API_URL.NFC_TAG_DELETE,
          id: key,
        });
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateData();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const handleRemove = (e: any) => {
    open().then(async (c) => {
      if (c === confirmDialogActions.CONFIRM) {
        const keyId = e?.data?.id;
        keyId && handleApiCall({ type: DataGridActionType.Remove, key: keyId, data: null });
        mutateData();
      } else if (c === confirmDialogActions.CANCEL) {
        return false;
      }
    });
  };

  const onSubmit = (formData: NfcTagInput, type: DataGridActionType) => {
    handleApiCall({ type, key: formData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  const { open } = useConfirmDialog({
    title: translate!('Delete', ''),
    bodyHtml: translate!('Are you sure?', ''),
    cancelButtonTitle: translate!('Cancel', ''),
    confirmButtonTitle: translate!('Confirm', ''),
  });

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset tags', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        columns={Columns(translate, handleEdit, handleRemove, isReadOnly)}
        allowColumnReordering={false}
        allowColumnResizing={false}
        columnChooser={false}
        onToolbarPreparing={isReadOnly ? undefined : onToolbarPreparing}
        isEditable={false}
        isReadOnly={true}
      />
      {isModalVisible && (
        <AssetNfcTagModal
          isVisible={isModalVisible}
          onClose={handleClose}
          data={selectedRowData}
          actionType={selectedAction}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
