import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { ColumnsDocuments } from '../../pages/WorkActivityGroups/Columns';
import { DocumentType } from '../../types/documentType.types';
import AssignedDocumentTypesModal from '../modals/AssignedDocumentTypesModal';
import { SisDataGrid } from '../table';

interface Props {
  data: DocumentType[] | undefined;
  handleRemove: (id: number) => void;
  documentTypes: DocumentType[];
  onSubmit: (formData: any) => void;
  isVisible: boolean;
  setIsVisible: any;
}

export const WorkActivityGroupsDocumentsDataGrid = ({
  data,
  handleRemove,
  documentTypes,
  onSubmit,
  isVisible,
  setIsVisible,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkActivityGroup.List';
  const columns = ColumnsDocuments(handleRemove, translate);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Assigned document types', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => setIsVisible(true),
        },
      },
    );
  };

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={columns}
        allowHeaderFiltering
        columnChooser={false}
        onToolbarPreparing={onToolbarPreparing}
      />
      {documentTypes && (
        <AssignedDocumentTypesModal
          isVisible={isVisible}
          documentTypes={documentTypes}
          onSubmit={onSubmit}
          onClose={() => setIsVisible(false)}
        />
      )}
    </>
  );
};
