import { Switch } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AssetTreeViewWithBack } from '../../../components/assetTreeView/AssetTreeViewWithBack';
import { AssetsChildrenGrid } from '../../../components/datagrid/AssetsChildrenGrid';
import { useTranslation } from '../../../contexts/translation';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Asset } from '../../../types/asset.types';
import { DetailPageParams } from '../../../types/pageParams.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';

interface Props extends ITab {
  assetClassId: any;
  isReadonly: boolean;
  assetId: any;
}

export function AssetTabChildren({ assetId, active, assetClassId }: Props) {
  const { id } = useParams<DetailPageParams>();
  const [isTreeView, setIsTreeView] = useState<boolean>(false);
  const { translate } = useTranslation();
  const transFormName = 'Asset.List';

  const {
    data: assets,
    error: errorAssets,
    mutate: mutateAsset,
    isValidating: assetsIsValidating,
  } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST}?visibleOnly=false&parentId=${id}`,
    fetcher,
    assetEditSwrConfig,
  );

  useEffect(() => {
    if (active) {
      mutateAsset();
    }
  }, [active]);

  const toolbar = (
    <Toolbar>
      <Item location={'before'} html={translate!('Asset children', transFormName)} />
      <Item location={'after'}>
        <div>
          <span>{translate!('Tree view', '')}</span>
          <Switch
            style={{ marginLeft: '5px' }}
            onValueChange={setIsTreeView}
            defaultValue={false}
          />
        </div>
      </Item>
    </Toolbar>
  );

  return (
    <PageContentWrapper noContentBlock noDxCard error={errorAssets} loading={assetsIsValidating}>
      <AssetTreeViewWithBack
        classId={assetClassId}
        parentId={assetId}
        isTreeView={isTreeView}
        setIsTreeView={setIsTreeView}
        toolbar={toolbar}
        hideToolbar
        dataGrid={<AssetsChildrenGrid data={assets} />}
      />
    </PageContentWrapper>
  );
}
