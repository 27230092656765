import { Button, DateBox, DropDownBox, TreeView } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getListApi } from '../../apiUtils';
import { useTranslation } from '../../contexts/translation';
import { TaskHistory, TaskHistoryAuthor } from '../../types/task.types';
import { API_URL } from '../../utils/apiUrl';
import './TaskHistoryFilter.scss';

type Props = {
  taskId: string;
  initialData: Array<TaskHistory>;
  setFilteredData: (data) => void;
};

const initialState = {
  selectedAuthor: null,
  dateFrom: undefined,
  dateTo: undefined,
};

export default function TaskHistoryFilter({ initialData, taskId, setFilteredData }: Props) {
  const [authors, setAuthors] = useState<Array<TaskHistoryAuthor>>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<TaskHistoryAuthor | null>(
    initialState.selectedAuthor,
  );
  const [dateFrom, setDateFrom] = useState<Date | undefined>(initialState.dateFrom);
  const [dateTo, setDateTo] = useState<Date | undefined>(initialState.dateTo);
  const [isDropdownOpened, setDropdownOpened] = useState<boolean>(false);
  const treeViewRef = useRef<any>();

  const transFormName = 'Task.History.Filter';
  const { translate } = useTranslation();

  const getFilteredTaskHistoryData = async () => {
    const params = {
      parentId: taskId,
    };

    // eslint-disable-next-line
    if (selectedAuthor) params['authorId'] = selectedAuthor[0];
    // eslint-disable-next-line
    if (dateFrom) params['dateFrom'] = moment(dateFrom).format('YYYY-MM-DD');
    // eslint-disable-next-line
    if (dateTo) params['dateTo'] = moment(dateTo).format('YYYY-MM-DD');

    await getListApi<Array<TaskHistory>>({
      url: `${API_URL.TASK_HISTORY_FILTERED_LIST}`,
      params,
      callAfterSuccess: (result) => {
        setFilteredData(result);
      },
    });
  };

  useEffect(() => {
    if (initialData) {
      const authorsDataSource: Array<TaskHistory> = [
        // eslint-disable-next-line
        ...Array.from(new Map(initialData?.map((item) => [item['createdBy'], item])).values()),
      ];
      setAuthors(
        authorsDataSource.map((item) => ({
          authorId: item.createdBy,
          authorName: item.createdByName,
        })),
      );
    }
  }, []);

  const resetFilters = () => {
    setSelectedAuthor(initialState.selectedAuthor);
    setDateFrom(initialState.dateFrom);
    setDateTo(initialState.dateTo);
    setFilteredData([]);
  };

  const treeViewOnContentReady = (e) => {
    e.component.selectItem(selectedAuthor);
  };

  const onTreeItemClick = () => {
    setDropdownOpened(false);
  };

  const treeViewItemSelectionChanged = (e) => {
    setSelectedAuthor(e.component.getSelectedNodeKeys());
  };

  const treeViewRender = () => {
    return (
      <TreeView
        // @ts-ignore
        dataSource={authors}
        ref={treeViewRef}
        dataStructure="plain"
        keyExpr="authorId"
        selectionMode="single"
        displayExpr="authorName"
        selectByClick={true}
        onContentReady={treeViewOnContentReady}
        onItemClick={onTreeItemClick}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  };

  const syncTreeViewSelection = (e) => {
    setSelectedAuthor(e.value);
    if (!treeViewRef) return;

    if (!e.value) {
      treeViewRef.current.instance.unselectAll();
    } else {
      treeViewRef.current.instance.selectItem(e.value);
    }
  };

  const onTreeBoxOpened = (e) => {
    if (e.name === 'opened') {
      setDropdownOpened(e.value);
    }
  };

  return (
    <>
      <div className="filter-view">
        <div className="filters-container">
          <DropDownBox
            className="filter-field"
            value={selectedAuthor}
            opened={isDropdownOpened}
            valueExpr="authorId"
            displayExpr="authorName"
            placeholder={translate!('Select an author..', transFormName)}
            showClearButton={true}
            dataSource={authors}
            onValueChanged={syncTreeViewSelection}
            onOptionChanged={onTreeBoxOpened}
            contentRender={treeViewRender}
          />
          <DateBox
            type="date"
            className="filter-field"
            displayFormat="yyyy-MM-dd"
            value={dateFrom}
            showClearButton={true}
            max={dateTo}
            placeholder={translate!('Date from', transFormName)}
            onValueChanged={(e) => setDateFrom(e.value)}
          />
          <DateBox
            type="date"
            className="filter-field"
            displayFormat="yyyy-MM-dd"
            value={dateTo}
            showClearButton={true}
            min={dateFrom}
            placeholder={translate!('Date to', transFormName)}
            onValueChanged={(e) => setDateTo(e.value)}
          />
        </div>
        <div className="button-container">
          <Button
            className="filter-button"
            text={translate!('Search', transFormName)}
            type="success"
            stylingMode="contained"
            onClick={getFilteredTaskHistoryData}
          />
          <Button
            className="filter-button"
            text={translate!('Reset', transFormName)}
            type="normal"
            stylingMode="contained"
            onClick={resetFilters}
          />
        </div>
      </div>
    </>
  );
}
