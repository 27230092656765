import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from '../../contexts/translation';
import { useClearButtonProps } from '../../hooks/useClearButtonProps';
import { useRowSelectionSync } from '../../hooks/useRowSelectionSync';
import { Columns } from '../../pages/Tasks/Columns';
import { TasksFilterData } from '../../pages/Tasks/Tasks';
import { paintCellByTaskColor } from '../../pages/Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../../pages/Tasks/utils/paintRowByColor';
import { Task } from '../../types/task.types';
import {
  getWidthByColumnsByStorageKey,
  scrollDatagridNoResponsive,
} from '../../utils/datagridNoResponsive';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { DatagridNoResponsive } from '../datagridNoResponsive/DatagridNoResponsive';
import BulkEditModal from '../modals/TasksBulkEditModal';
import { IColumnType, SelectionMode, SisDataGrid, SortingMode } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  defaultFilterData?: TasksFilterData;
  onFilterChanged?: (TasksFilterData) => void;
  isDateFilter?: boolean;
  handleCreateWorksheet?: (e) => void;
  handleCopy?: (e) => void;
  mutateData?: () => void;
  storageKey: string;
}

export const TasksDataGrid = ({
  data,
  mutateData,
  handleEdit,
  handleCreateWorksheet,
  handleCopy,
  resetFilter,
  dataGridRef,
  userCanCreate,
  defaultFilterData,
  onFilterChanged,
  isDateFilter,
  storageKey,
}: Props) => {
  const { user } = useAuth();
  const { translate } = useTranslation();
  const transFormName = 'Task.List';
  const transFormNameBulkChanges = 'Bulk.Change';
  const history = useHistory();
  const [filterData, setFilterData] = useState<TasksFilterData | undefined>(
    isDateFilter ? defaultFilterData : undefined,
  );
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Array<Task>>([]);
  const { rows, onRowsChange } = useRowSelectionSync(STORAGE_KEYS.TASKS_ROW_SELECTION);

  // Trigger scroll
  useEffect(() => {
    scrollDatagridNoResponsive();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      setSelectedRows(data?.filter((item) => rows?.map((x) => x).includes(item.id)));
    }
  }, [data, isModalVisible]);

  useEffect(() => {
    setFilterData(isDateFilter ? defaultFilterData : undefined);
  }, [defaultFilterData]);

  const dateFromProps = useClearButtonProps();
  const dateToProps = useClearButtonProps();

  const onToolbarPreparing = (e) => {
    if (isDateFilter && onFilterChanged && filterData) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        html: translate!('Tasks', transFormName),
      });

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            visible: userCanCreate,
            icon: 'add',
            type: 'default',
            stylingMode: 'contained',
            onClick: () => history.push(`/tasks/new/`),
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'clear',
            type: 'info',
            text: translate!('Clear filter', transFormName),
            stylingMode: 'outlined',
            onClick: () => {
              if (resetFilter && isFunction(resetFilter)) {
                resetFilter();
              }
            },
          },
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'edit',
            type: 'default',
            text: translate!('Bulk changes', transFormNameBulkChanges),
            stylingMode: 'outlined',
            onClick: () => setModalVisible(true),
            disabled: rows.length === 0,
          },
        },
      );

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          template: () => {
            return translate!('Date from', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateFrom,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateFrom: e.value });
            },
            ...dateFromProps,
          },
        },
        {
          location: 'after',
          template: () => {
            return translate!('Date to', transFormName);
          },
        },
        {
          location: 'after',
          widget: 'dxDateBox',
          options: {
            ...dateFieldOptionsWithSerialization,
            value: filterData.dateTo,
            onValueChanged: (e) => {
              onFilterChanged({ ...filterData, dateTo: e.value });
            },
            ...dateToProps,
          },
        },
      );
    }
  };

  const onSelectionChanged = async () => {
    // @ts-ignore
    if (!dataGridRef || !dataGridRef?.current) return;
    // @ts-ignore
    const keys = await dataGridRef?.current?.instance.getSelectedRowKeys();
    onRowsChange(keys);
  };

  const columns = Columns(handleEdit, handleCreateWorksheet, handleCopy, user?.roles, translate);

  /**
   * Filtrační a modifikační funkce pro nastavení sloupců pro datagrid v editačním modalu
   */
  const modalColumns = columns
    ?.filter((column) => column?.type !== IColumnType.buttons)
    .map((column) => {
      return { ...column, width: 100 };
    });

  return (
    <>
      {isModalVisible && (
        <BulkEditModal
          data={isModalVisible ? selectedRows : []}
          isVisible={isModalVisible}
          mutate={mutateData}
          onClose={() => setModalVisible(false)}
          columns={modalColumns}
          transFormNameColumns={transFormName}
        />
      )}
      <DatagridNoResponsive datagridWidth={getWidthByColumnsByStorageKey(storageKey, columns)}>
        <SisDataGrid
          enableXlsxExport
          exportName={'tasks-data'}
          data={data}
          keyExpr={'id'}
          dataGridRef={dataGridRef}
          transFormName={transFormName}
          stateStoringKey={storageKey}
          columns={columns}
          onToolbarPreparing={onToolbarPreparing}
          onRowPrepared={paintRowByTaskColor}
          onCellPrepared={paintCellByTaskColor}
          onCellDblClick={(e) => handleEdit && handleEdit(e)}
          pageSizeChangeVisible={true}
          selectionMode={SelectionMode.MULTIPLE}
          onSelectionChanged={onSelectionChanged}
          enableGrouping
          sortingMode={SortingMode.MULTIPLE}
          selectedRowKeys={rows}
        />
      </DatagridNoResponsive>
    </>
  );
};
