import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi, putApi } from '../../../apiUtils';
import { AlertMessage } from '../../../components/alertMessage/AlertMessage';
import { AssetTabCycleDataGrid } from '../../../components/datagrid/AssetTabCycleDataGrid';
import { useAssetClass } from '../../../contexts/assetClass';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { AssetCycle, AssetCycleInputs } from '../../../types/assetCycle.types';
import { DataGridActionType } from '../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../types/pageParams.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';
import { AssetTabCycleDetail } from './cycleDetail/AssetTabCycleDetail';

interface Props extends ITab {
  isReadonly: boolean;
}

export function AssetTabCycle({ active, isReadonly }: Props) {
  const { translate } = useTranslation();
  const transFormName = 'Asset.Cycle';
  const { id } = useParams<DetailPageParams>();
  const apiUrlList = `${API_URL.ASSET_CYCLE_LIST}?visibleOnly=false&parentId=${id}`;
  const [selectedCycle, setSelectedCycle] = useState<AssetCycle | null>(null);
  const { assetClass } = useAssetClass();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetCycleInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  const { startLoading, stopLoading } = useLoading();

  // Načteme cykly
  const {
    data: cycles,
    error: errorCycles,
    mutate: mutateCycles,
    isValidating: cyclesIsValidating,
  } = useSWR(apiUrlList, fetcher, assetEditSwrConfig);
  // Načteme číselník - CapacityCategory
  const {
    data: capacityCategories,
    error: errorCapacityCategories,
    mutate: mutateCapacity,
    isValidating: capacityCatIsValidating,
  } = useSWR(
    `${API_URL.CAPACITY_CATEGORY_LIST}${assetClass ? `?parentId=${assetClass}` : ''}`,
    fetcher,
    assetEditSwrConfig,
  );
  // Načteme document typy
  const {
    data: documentTypes,
    error: errorDocumentTypes,
    mutate: mutateDocumentTypes,
    isValidating: documentTypesIsValidating,
  } = useSWR(
    `${API_URL.DOCUMENT_TYPE_FOR_ASSET_CYCLE_LIST}?assetClassId=${assetClass}`,
    fetcher,
    assetEditSwrConfig,
  );

  useEffect(() => {
    if (active) {
      mutateCycles();
      mutateCapacity();
      mutateDocumentTypes();
    }
  }, [active]);

  const onSelectionChanged = useCallback((e) => {
    const data = e.selectedRowsData[0];
    if (data === undefined) return;

    setSelectedCycle(data);
  }, []);

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_CYCLE_POST,
          data: { ..._data, assetId: id },
        });
        break;
      case DataGridActionType.Update:
        const oldData = cycles?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_CYCLE_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateCycles();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const onSubmit = (formData: AssetCycleInputs, type?: DataGridActionType) => {
    startLoading();
    handleApiCall({ type, key: selectedRowData?.id, data: formData }).then(() => stopLoading());
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={errorCycles || errorCapacityCategories || errorDocumentTypes}
      loading={capacityCatIsValidating || cyclesIsValidating || documentTypesIsValidating}
    >
      <AssetTabCycleDataGrid
        capacityCategories={capacityCategories}
        documentTypes={documentTypes}
        data={cycles}
        onSelectionChanged={onSelectionChanged}
        modalVisibility={isModalVisible}
        handleEdit={handleEdit}
        handleNew={handleNew}
        handleClose={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
        isReadOnly={isReadonly}
      />
      <>
        {selectedCycle === null ? (
          <AlertMessage message={translate!('Select cycle', transFormName)} class="warning" />
        ) : (
          <AssetTabCycleDetail
            cycleId={selectedCycle.id}
            assetId={id as unknown as number}
            documentTypeTaskStepsAllowed={selectedCycle.documentTypeTaskStepsAllowed}
            isReadonly={isReadonly}
            mutateParent={mutateCycles}
          />
        )}
      </>
    </PageContentWrapper>
  );
}
