import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { concat, isNil, not, path, toLower } from 'ramda';

export const paintCellByWorkSheetColor = (e: CellPreparedEvent) => {
  if (e.data && e.column.dataField === 'workSheetStatusNameTranslated') {
    const colorNotNil = not(isNil(path(['data', 'colorName'], e)));
    e.cellElement.className = concat<string, string>(
      e.cellElement.className,
      colorNotNil ? ` ${toLower(e.data.colorName)}_cell-document-type-highlight` : ' default_cell',
    );
  }
};
