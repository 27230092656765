import { isEmpty } from 'ramda';
import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/Reports/AssetResponsiblePerson/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
}

export const ResponsiblePersonDataGrid = ({
  data,
  dataGridRef,
  heading,
  filterVisibility,
  setFilterVisibility,
}: Props) => {
  const transFormName = 'ResponsiblePersonReport';
  const { translate } = useTranslation();
  const transFormReportBar = 'Reports.Bar';

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
    );
  };

  return (
    <SisDataGrid
      enableXlsxExport
      exportName={'responsible-person-data'}
      show={!isEmpty(data)}
      dataGridRef={dataGridRef}
      data={data}
      keyExpr={'assetId'}
      transFormName={transFormName}
      columns={Columns}
      onToolbarPreparing={onToolbarPreparing}
      pageSizeChangeVisible={true}
    />
  );
};
