import { isFunction } from 'ramda-extension';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/Reports/Assets/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  dataGridKey: string;
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
}

export const AssetsReportDataGrid = ({
  data,
  handleEdit,
  resetFilter,
  dataGridKey,
  dataGridRef,
  userCanCreate,
  heading,
  filterVisibility,
  setFilterVisibility,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.List';
  const transFormReportBar = 'Reports.Bar';
  const history = useHistory();

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => history.push(`/assets/new/`),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };
  return (
    <>
      <SisDataGrid
        enableXlsxExport
        exportName={'assets-data'}
        dataGridRef={dataGridRef}
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        stateStoringKey={dataGridKey}
        onToolbarPreparing={onToolbarPreparing}
        columns={Columns(handleEdit, translate)}
        onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeChangeVisible={true}
        enableGrouping
      />
    </>
  );
};
