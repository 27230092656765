import { isNilOrEmpty } from 'ramda-extension';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TaskWorkSheetsDataGrid } from '../../../components/datagrid/TaskWorkSheetsDataGrid';
import { useIsWorkSheetCreatePermission } from '../../../hooks/useIsWorkSheetCreatePermission';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Task } from '../../../types/task.types';
import { ITab } from '../../Assets/AssetsEdit';

interface Props extends ITab {
  id: string;
  task?: Task;
  data: any;
  error: any;
}
export const TaskWorkSheetsTab = ({ task, id, active, data, error }: Props) => {
  const history = useHistory();
  const dataGrid = useRef<any>(null);
  const [userHasPermissionToCreate] = useIsWorkSheetCreatePermission();

  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        pathname: `/workSheets/edit/${e.row.data.id}`,
        state: { backRoute: `/tasks/edit/${task?.id}` },
      });
    }
  };

  return (
    <PageContentWrapper error={error} noContentBlock noDxCard>
      <TaskWorkSheetsDataGrid
        task={task}
        dataGridRef={dataGrid}
        data={data}
        handleEdit={handleEditButton}
        userCanCreate={userHasPermissionToCreate}
      />
    </PageContentWrapper>
  );
};
