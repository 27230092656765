import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { swrConfig } from '../../pages/Assets/AssetsEdit';
import { Transaction } from '../../types/transaction.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { FailedLoad } from '../failedLoad/FailedLoad';
import { Loader } from '../loader/Loader';
import { SisDataGrid } from '../table';
import { Columns } from './Columns';

export function Transactions() {
  const { translate } = useTranslation();
  const { signOut } = useAuth();
  const transFormName = 'Transaction.List';
  const history = useHistory();
  const { setValue } = useBreadcrumbs();
  const { data, error } = useSWR<Transaction[]>(
    API_URL.TRANSACTION_LIST_FOR_CURRENT_USER,
    fetcher,
    swrConfig,
  );

  console.log(data, 'data');
  useEffect(() => {
    setValue({ pageName: 'Transactions', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const onSelectionChanged = async (e) => {
    const { url } = e.selectedRowsData[0];
    if (url === '/logout' && signOut) signOut();
    // odstranit slash na konci
    let trimmedUrl = url.charAt(url.length - 1) === '/' ? url.substring(0, url.length - 1) : url;
    // odstranit slash na začátku
    trimmedUrl = trimmedUrl.charAt(0) === '/' ? trimmedUrl.substring(1) : trimmedUrl;
    history.push(trimmedUrl);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Transactions', transFormName),
    });
  };

  if (error) return <FailedLoad message={null} />;
  if (!data) return <Loader />;

  return (
    <PageContentWrapper>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        filterRow={false}
        search
        selection={{ mode: 'single' }}
        columnChooser={false}
      />
    </PageContentWrapper>
  );
}
export default Transactions;
