import React, { createContext, useCallback, useContext, useState } from 'react';
import useSWR from 'swr';
import { postApi } from '../apiUtils';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { swrConfig } from '../pages/Assets/AssetsEdit';
import { AssetClass } from '../types/assetClass.types';
import { API_URL, fetcher } from '../utils/apiUrl';

const AssetClassContext = createContext<AssetClassContextType>({
  assetClass: 0,
  assetClassName: '',
});

function AssetClassProvider(props: any) {
  const [lsAssetClass, setLsAssetClass] = useLocalStorage('assetClass', 4);
  const [assetClass, setAssetClass] = useState<number>(lsAssetClass);
  const { data: assetClassData, mutate } = useSWR<AssetClass>(
    `${API_URL.ASSET_CLASS_ITEM}?id=${assetClass}`,
    fetcher,
    swrConfig,
  );

  const changeAssetClass = useCallback((assetClassId: number) => {
    postApi({
      url: `${API_URL.CHANGE_CURRENT_ASSET_CLASS}`,
      data: { assetClassId },
      callAfterSuccess: () => {
        setAssetClass(assetClassId);
        setLsAssetClass(assetClassId);
      },
      hideSuccessNotifications: true,
    });
  }, []);

  const refetchAssetClass = () => {
    mutate();
  };

  return (
    <AssetClassContext.Provider
      value={{
        assetClass,
        assetClassName: assetClassData?.nameTranslated,
        changeAssetClass,
        refetchAssetClass,
      }}
      {...props}
    />
  );
}

interface AssetClassContextType {
  assetClass: number;
  assetClassName: string;
  changeAssetClass?: (assetClassId: number) => void;
  refetchAssetClass?: () => void;
}

const useAssetClass = () => useContext<AssetClassContextType>(AssetClassContext);

export { AssetClassProvider, useAssetClass };
