import ScrollView from 'devextreme-react/scroll-view';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginForm from './components/loginForm/LoginForm';
import { useTerminalAuth } from './contexts/terminalAuthContext';
import { TerminalMappingLogin } from './pages/Tasks/Terminal/TerminalMappingLogin';
import './UnauthenticatedContent.scss';

interface Props {
  path: string;
  platform: string;
  showBackButton?: boolean;
  terminalMappingRedirectEnabled?: boolean;
}
export function UnauthenticatedContent({
  path,
  platform,
  showBackButton,
  terminalMappingRedirectEnabled,
}: Props) {
  const { terminalMappings } = useTerminalAuth();

  return (
    <Switch>
      <Route exact path={path}>
        {terminalMappingRedirectEnabled &&
        path === '/terminal/terminal-mapping-login' &&
        terminalMappings ? (
          <TerminalMappingLogin />
        ) : (
          <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
            <div className={'unauthenticated-content'}>
              <LoginForm platform={platform} showBackButton={showBackButton} />{' '}
            </div>
          </ScrollView>
        )}
      </Route>
      <Redirect to={path} />
    </Switch>
  );
}
