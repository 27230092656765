import moment, { Moment } from 'moment';

interface CondParams {
  first: number | Moment;
  second: number | Moment;
}

export const evaluateStringCondition = (
  condition: string,
  dataType: 'number' | 'date' | 'datetime',
) => {
  let condParams = evalCondSymbol(condition, '>=', dataType);
  if (condParams && dataType === 'number') return condParams.first >= condParams.second;
  if (condParams && (dataType === 'date' || dataType === 'datetime'))
    // @ts-ignore
    return condParams.first.isSameOrAfter(condParams.second);

  condParams = evalCondSymbol(condition, '<=', dataType);
  if (condParams && dataType === 'number') return condParams.first <= condParams.second;
  if (condParams && (dataType === 'date' || dataType === 'datetime'))
    // @ts-ignore
    return condParams.first.isSameOrBefore(condParams.second);

  condParams = evalCondSymbol(condition, '>', dataType);
  if (condParams && dataType === 'number') return condParams.first > condParams.second;
  if (condParams && (dataType === 'date' || dataType === 'datetime'))
    // @ts-ignore
    return condParams.first.isAfter(condParams.second);

  condParams = evalCondSymbol(condition, '<', dataType);
  if (condParams && dataType === 'number') return condParams.first < condParams.second;
  if (condParams && (dataType === 'date' || dataType === 'datetime'))
    // @ts-ignore
    return condParams.first.isBefore(condParams.second);

  condParams = evalCondSymbol(condition, '==', dataType);
  if (condParams && dataType === 'number') return condParams.first == condParams.second;
  if (condParams && (dataType === 'date' || dataType === 'datetime'))
    // @ts-ignore
    return condParams.first.isSame(condParams.second);

  return false;
};

export const evalCondSymbol = (
  condition,
  symbol,
  dataType: 'number' | 'date' | 'datetime',
): CondParams | null => {
  if (!condition.includes(symbol)) return null;
  const condArr = condition.split(symbol);
  if (dataType === 'number')
    return {
      first: Number(condArr[0]),
      second: Number(condArr[1]),
    };

  if (dataType === 'date' || dataType === 'datetime')
    return {
      first: moment(condArr[0]),
      second: moment(condArr[1]),
    };

  return {
    first: condArr[0],
    second: condArr[1],
  };
};
