import notify from 'devextreme/ui/notify';
import themes from 'devextreme/ui/themes';
import { refreshTheme } from 'devextreme/viz/themes';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { STORAGE_KEYS } from '../utils/localStorageKeys';
import { useAuth } from './auth';
const ThemeContext = createContext<ThemeContextType>({ theme: '' });

export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
}

function ThemeProvider(props: any) {
  const { user } = useAuth();
  const [colorTheme, setColorTheme] = useSessionStorage(STORAGE_KEYS.DEFAULT_THEME, '"carmine"');
  const [lsTheme, setLtTheme] = useSessionStorage('dx-theme', 'light');
  const [theme, setTheme] = useState<string>(lsTheme);

  useEffect(() => {
    if (!colorTheme) return;
    setCustomColorTheme(colorTheme, theme);
  }, [colorTheme]);

  useEffect(() => {
    if (!user) return;
    if (window.sessionStorage.getItem(STORAGE_KEYS.DEFAULT_THEME))
      setColorTheme(window.sessionStorage.getItem(STORAGE_KEYS.DEFAULT_THEME));
  }, [user]);

  const setCustomColorTheme = (colorTheme, theme) => {
    switch (colorTheme) {
      case '"carmine"':
        document.documentElement.style.setProperty('--app-base-accent', '#f05b41');
        document.documentElement.style.setProperty('--app-base-bg', '#fff');
        document.documentElement.style.setProperty('--app-base-border-radius', '2px');

        document.documentElement.style.setProperty('--app-additional-accent', '#f05b41');
        document.documentElement.style.setProperty('--app-additional-text-color', '#dedede');
        document.documentElement.style.setProperty('--app-additional-bg', '#2a2a2a');
        document.documentElement.style.setProperty('--app-additional-border-color', '#4d4d4d');
        document.documentElement.style.setProperty('--app-additional-border-radius', '4px');
        if (theme === ThemeType.LIGHT) {
          document.documentElement.style.setProperty('--app-base-text-color', '#000');
          document.documentElement.style.setProperty('--app-base-border-color', '#dee1e3');
          themes.current('generic.carmine.compact');
        } else {
          document.documentElement.style.setProperty('--app-base-text-color', '#dedede');
          document.documentElement.style.setProperty('--app-base-border-color', '#4d4d4d');
          themes.current('generic.carmine.dark.compact');
        }
        break;
      case '"softblue"':
        document.documentElement.style.setProperty('--app-base-accent', '#7ab8eb');
        document.documentElement.style.setProperty('--app-base-text-color', '#dedede');
        document.documentElement.style.setProperty('--app-base-bg', '#fff');
        document.documentElement.style.setProperty('--app-base-border-radius', '2px');

        document.documentElement.style.setProperty('--app-additional-accent', '#7ab8eb');
        document.documentElement.style.setProperty('--app-additional-text-color', '#dedede');
        document.documentElement.style.setProperty('--app-additional-bg', '#fff');
        document.documentElement.style.setProperty('--app-additional-border-color', '#4d4d4d');
        document.documentElement.style.setProperty('--app-additional-border-radius', '2px');
        if (theme === ThemeType.LIGHT) {
          document.documentElement.style.setProperty('--app-base-text-color', '#000');
          document.documentElement.style.setProperty('--app-base-border-color', '#dee1e3');
          themes.current('generic.softblue.compact');
        } else {
          document.documentElement.style.setProperty('--app-base-text-color', '#dedede');
          document.documentElement.style.setProperty('--app-base-border-color', '#4d4d4d');
          themes.current('generic.softblue.dark.compact');
        }
        break;
      case '"greenmist"':
        document.documentElement.style.setProperty('--app-base-accent', '#3cbab2');
        document.documentElement.style.setProperty('--app-base-text-color', '#dedede');
        document.documentElement.style.setProperty('--app-base-bg', '#f5f5f5');
        document.documentElement.style.setProperty('--app-base-border-radius', '2px');

        document.documentElement.style.setProperty('--app-additional-accent', '#3cbab2');
        document.documentElement.style.setProperty('--app-additional-text-color', '#dedede');
        document.documentElement.style.setProperty('--app-additional-bg', '#f5f5f5');
        document.documentElement.style.setProperty('--app-additional-border-color', '#4d4d4d');
        document.documentElement.style.setProperty('--app-additional-border-radius', '2px');
        if (theme === ThemeType.LIGHT) {
          document.documentElement.style.setProperty('--app-base-text-color', '#000');
          document.documentElement.style.setProperty('--app-base-border-color', '#dee1e3');
          themes.current('generic.greenmist.compact');
        } else {
          document.documentElement.style.setProperty('--app-base-text-color', '#dedede');
          document.documentElement.style.setProperty('--app-base-border-color', '#4d4d4d');
          themes.current('generic.greenmist.dark.compact');
        }
        break;
      //--app-base-border-color
      default:
        break;
    }
    refreshTheme();
  };

  const changeTheme = useCallback((theme: string) => {
    setTheme(theme);
    setLtTheme(theme);
    setCustomColorTheme(colorTheme, theme);
    notify(`Success`, 'success');
  }, []);

  return <ThemeContext.Provider value={{ theme, changeTheme }} {...props} />;
}

interface ThemeContextType {
  theme: string;
  changeTheme?: (theme: number) => void;
}

const useTheme = () => useContext<ThemeContextType>(ThemeContext);

export { ThemeProvider, useTheme };
