import { find, propEq } from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getListApi } from '../apiUtils';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { ConfigResponse, ConfigType } from '../types/config.types';
import { API_URL } from '../utils/apiUrl';
import { STORAGE_KEYS } from '../utils/localStorageKeys';

interface IConfigContext {
  config: ConfigResponse;
  loading: boolean;
  setDefaultConfigToStorage?: () => void;
}

enum CONFIG_KEYS {
  ASSET_LIST_DATAGRID_DESKTOP = 'devExpressTableAssetListDesktop',
  TASK_LIST_REFRESH = 'taskListRefreshInSeconds',
  TASK_LIST_DATAGRID_TERMINAL = 'devExpressTableTaskListTerminal',
  TASK_LIST_DATAGRID_DESKTOP = 'devExpressTableTaskListDesktop',
  TASK_LIST_DATAGRID_DESKTOP_MOBILE_VIEW = 'devExpressTableTaskListDesktopMobileView',
  WORKSHEET_LIST_REFRESH = 'devExpressTableWorkSheetListMobile',
  WORKSHEET_LIST_DATAGRID_DESKTOP = 'devExpressTableWorkSheetListDesktop',
  TABLE_ROWS_PER_PAGE_LIST = 'devExpressTableRowsPerPageList',
  ASSET_TASK_LIST_DATAGRID_DESKTOP = 'devExpressTableAssetTasksListDesktop',
  TASK_WORKSHEET_LIST_DATAGRID_DESKTOP = 'devExpressTableTaskWorksheetsListDesktop',
  RESTRICT_WA_BY_CAPACITY_CATEGORY = 'restrictWorkActivitiesByCapacityCategoryPerson',
  ENABLE_THRESHOLD_DATE_CHANGER_FOR_ASSET_CYCLE = 'enableThresholdDateChangeForAssetCycle',
  TERMINAL_IDLE_LOGOUT_TIMEOUT_DEFAULT_USER = 'terminalIdleLogoutTimeoutDefaultUser',
  DEFAULT_THEME = 'default_theme',
}

const ConfigContext = createContext<IConfigContext>({ config: [], loading: false });

function ConfigProvider(props: any) {
  const [config, setConfig] = useState<ConfigResponse | null>();
  const [loading, setLoading] = useState<boolean>(true);

  // Seznam assetů
  const [configAssetsListDesktop, setConfigAssetListDesktop] = useSessionStorage(
    STORAGE_KEYS.ASSETS_DATAGRID_DESKTOP,
    {},
  );

  // Seznam tasků
  const [configTasksListDesktop, setConfigTasksListDesktop] = useSessionStorage(
    STORAGE_KEYS.TASKS_DATAGRID_DESKTOP,
    {},
  );

  // Seznam worksheetů
  const [configWorksheetsListDesktop, setConfigWorksheetsListDesktop] = useSessionStorage(
    STORAGE_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP,
    {},
  );

  // Seznam tasků na terminálu
  const [configTasksListTerminal, setConfigTasksListTerminal] = useSessionStorage(
    STORAGE_KEYS.TASK_LIST_DATAGRID_TERMINAL,
    {},
  );

  // Seznam tasků na desktopu - mobilní pohled
  const [configTasksListDesktopMobileView, setConfigTasksListDesktopMobileView] = useSessionStorage(
    STORAGE_KEYS.TASKS_DATAGRID_DESKTOP_MOBILE_VIEW,
    {},
  );

  // Seznam tasků v detailu assetu
  const [configAssetTasksListDesktop, setConfigAssetTasksListDesktop] = useSessionStorage(
    STORAGE_KEYS.ASSET_TASKS_DATAGRID_DESKTOP,
    {},
  );

  // TODO: Seznam worksheetů v detailu tasku
  const [configTaskWorksheetsListDesktop, setConfigTaskWorksheetsListDesktop] = useSessionStorage(
    STORAGE_KEYS.TASKS_WORKSHEET_DATAGRID_DESKTOP,
    {},
  );

  // default theme
  const [defaultTheme, setDefaultTheme] = useSessionStorage(STORAGE_KEYS.DEFAULT_THEME, {});

  const fetchConfig = async () => {
    setLoading(true);
    await getListApi<ConfigResponse>({
      url: `${API_URL.GET_CONFIG}`,
      params: {},
      callAfterSuccess: (result) => {
        setConfig(result);
        const assetListsDesktop = filterConfig(result, CONFIG_KEYS.ASSET_LIST_DATAGRID_DESKTOP);
        if (assetListsDesktop) setConfigAssetListDesktop(JSON.parse(assetListsDesktop.value));

        const tasksListsDesktop = filterConfig(result, CONFIG_KEYS.TASK_LIST_DATAGRID_DESKTOP);
        if (tasksListsDesktop) setConfigTasksListDesktop(JSON.parse(tasksListsDesktop.value));

        const worksheetsListsDesktop = filterConfig(
          result,
          CONFIG_KEYS.WORKSHEET_LIST_DATAGRID_DESKTOP,
        );
        if (worksheetsListsDesktop)
          setConfigWorksheetsListDesktop(JSON.parse(worksheetsListsDesktop.value));

        const tasksListsTerminal = filterConfig(result, CONFIG_KEYS.TASK_LIST_DATAGRID_TERMINAL);
        if (tasksListsTerminal && Object.keys(configTasksListTerminal).length === 0)
          setConfigTasksListTerminal(JSON.parse(tasksListsTerminal.value));

        const tasksListsDesktopMobileView = filterConfig(
          result,
          CONFIG_KEYS.TASK_LIST_DATAGRID_DESKTOP_MOBILE_VIEW,
        );
        if (tasksListsDesktopMobileView)
          setConfigTasksListDesktopMobileView(JSON.parse(tasksListsDesktopMobileView.value));

        const assetTasksListsDesktop = filterConfig(
          result,
          CONFIG_KEYS.ASSET_TASK_LIST_DATAGRID_DESKTOP,
        );
        if (assetTasksListsDesktop)
          setConfigAssetTasksListDesktop(JSON.parse(assetTasksListsDesktop.value));

        const taskWorksheetsListsDesktop = filterConfig(
          result,
          CONFIG_KEYS.TASK_WORKSHEET_LIST_DATAGRID_DESKTOP,
        );
        if (taskWorksheetsListsDesktop)
          setConfigTaskWorksheetsListDesktop(JSON.parse(taskWorksheetsListsDesktop.value));

        const defaultTheme = filterConfig(result, CONFIG_KEYS.DEFAULT_THEME);
        if (defaultTheme) setDefaultTheme(defaultTheme.value);
      },
      hideNotifications: true,
    });
    setLoading(false);
  };

  useEffect(() => {
    (async function () {
      await fetchConfig();
      setDefaultConfigToStorage();
    })();
  }, []);

  const setDefaultConfigToStorage = () => {
    fetchConfig();
  };

  return (
    <ConfigContext.Provider value={{ loading, config, setDefaultConfigToStorage }} {...props} />
  );
}

const useConfig = () => useContext<IConfigContext>(ConfigContext);

const useConfigByKey = (key: CONFIG_KEYS): ConfigType | undefined => {
  const { config } = useConfig();
  if (config) return filterConfig(config, key);
  return undefined;
};

const filterConfig = (config, key: CONFIG_KEYS) => {
  const result = find<ConfigType>(propEq('key', key))(config);
  return result?.enabled && result?.key.toLowerCase() !== 'not set' ? result : undefined;
};

export { ConfigContext, useConfig, ConfigProvider, useConfigByKey, CONFIG_KEYS };
