import { IDataType, SisDataGridColumns } from '../table';

export const Columns: SisDataGridColumns = [
  {
    dataField: 'id',
    dataType: IDataType.number,
    caption: 'Id transaction',
    width: '5%',
  },
  {
    dataField: 'shortcut',
    caption: 'Shortcut',
    width: '15%',
  },
  {
    dataField: 'url',
    caption: 'Url',
    width: '25%',
    cellRender: (data) => trimSlashFromUrl(data.row.data.url),
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '5%',
  },
];

// skrýt lomítko v tabulce (na konci)
const trimSlashFromUrl = (url: string) => {
  if (url && url.slice(-1) === '/') return url.slice(0, -1);
  return url;
};
