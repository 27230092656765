import TabPanel, { Item } from 'devextreme-react/tab-panel';
import React, { useState } from 'react';
import { TabFiles } from '../../../../components/fileManager/TabFiles';
import { useTranslation } from '../../../../contexts/translation';
import { AssetTabCycleParameters } from './AssetTabCycleParameters';
import { AssetTabCycleSteps } from './AssetTabCycleSteps';

interface AssetTabCycleDetailProps {
  cycleId: number;
  assetId: number;
  isReadonly: boolean;
  documentTypeTaskStepsAllowed: boolean;
  mutateParent: () => void;
}

/**
 * Detail cyklu a revizního cyklu. Komponenta je sdílená pro obě entity. Nyní se chovají entity stejně a odlišují
 * se parametrem revisionCategoryId. Pro revizní cyklus revisionCategoryId = null.
 * @param props
 * @constructor
 */
export function AssetTabCycleDetail(props: AssetTabCycleDetailProps) {
  const { translate } = useTranslation();
  const transFormName = 'Asset.CycleDetail';
  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <div style={{ marginTop: '15px' }}>
      <TabPanel activeStateEnabled onTitleClick={(e: any) => setCurrentTab(e.itemIndex)}>
        <Item title={`${translate!('Parameters', transFormName)}`}>
          <AssetTabCycleParameters
            cycleId={props.cycleId}
            assetId={props.assetId}
            isReadonly={props.isReadonly}
            mutateParent={props.mutateParent}
            active={currentTab === 0}
          />
        </Item>
        <Item title={`${translate!('Files', transFormName)}`}>
          <TabFiles id={props.cycleId} entityName="AssetCycle" isReadonly={props.isReadonly} />
        </Item>
        {props.documentTypeTaskStepsAllowed ? (
          <Item title={`${translate!('Steps', transFormName)}`}>
            <AssetTabCycleSteps
              cycleId={props.cycleId}
              assetId={props.assetId}
              isReadonly={props.isReadonly}
              mutateParent={props.mutateParent}
              active={currentTab === 2}
            />
          </Item>
        ) : (
          <></>
        )}
      </TabPanel>
    </div>
  );
}
