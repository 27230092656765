import { Button, CheckBox, Form, Popup } from 'devextreme-react';
import { GroupItem } from 'devextreme-react/form';
import React, { FormEvent, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi } from '../../../apiUtils';
import ReportFilterSelector, {
  FilterActionState,
} from '../../../components/reportSelector/ReportSelector';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { clear, parseParameters, useField } from '../../../hooks/useField';
import { Asset } from '../../../types/asset.types';
import { AssetType } from '../../../types/assetType.type';
import { CapacityCategory } from '../../../types/capacityCategory.types';
import { FieldType } from '../../../types/field.types';
import { Person } from '../../../types/person.types';
import { ResponsiblePersonReportType } from '../../../types/responsiblePersonReport.types';
import { Rule } from '../../../types/rule.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { DateFilter } from '../../../utils/dateFilters';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { ReportType } from '../../../utils/reportTypeKeys';
import FormField from '../components/FormField';
import DeleteButton from '../components/ReportDeleteButton';
import '../ReportFilter.scss';

type Props = {
  dataGridRef: React.Ref<any>;
  onDataChange: (data: Array<ResponsiblePersonReportType> | null) => void;
  onFilterChange: (filter: string | null) => void;
  report: {
    id: number;
    key: string;
  };
  filterVisibility: boolean;
};

/**
 * Komponenta představující filtr s report selectorem pro Report zodpovědných osob (Asset responsible persons)
 */
export default function AssetResponsiblePersonReportFilter({
  dataGridRef,
  onDataChange,
  onFilterChange,
  report,
  filterVisibility,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [dialogAction, setDialogAction] = useState<FilterActionState | null>(null);
  const [isDefault, setDefault] = useState<boolean>(false);
  const { startLoading, loading, stopLoading } = useLoading();

  const { translate } = useTranslation();
  const transFormName = 'ResponsiblePersonReport';
  const transFormRepSelector = 'Reports.ReportSelector';
  // reference pro report selector na kterou jsou navázané funkce pro práci s filtry:
  //  - smazání, přidání / editace filtru
  const selectorRef = useRef<any>();

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const getData = async (params = {}) => {
    const response = await postApi<Array<ResponsiblePersonReportType>>({
      url: API_URL.RESPONSIBLE_PERSON_REPORT,
      data: params,
      hideNotifications: true,
    });
    onDataChange(response ?? null);
    stopLoading();
  };

  /**
   * Funkce pro vyhledávání - po zadání hodnot ve filter baru
   */
  const onSearch = (e: FormEvent) => {
    startLoading();
    e.preventDefault();
    getData(parseParameters(fields));
  };

  /**
   * Funkce pro smazání hodnot z fieldů - reset funkce
   */
  const clearDataAndFields = () => {
    clear(fields);
    onDataChange([]);
  };

  /**
   * SWR fetch dat z API pro naplnění field data sourců
   */
  const { data: assetTypeList } = useSWR<AssetType[]>(API_URL.ASSET_TYPE_LIST, fetcher);
  const { data: assetList } = useSWR<Asset[]>(API_URL.ASSET_LIST, fetcher);
  const { data: capacityCategoryList } = useSWR<CapacityCategory[]>(
    API_URL.CAPACITY_CATEGORY_LIST,
    fetcher,
  );
  const { data: responsiblePersonList } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  /**
   * Definice fieldů pro filter bar + form na přidání nového / editaci stávajího filtru
   */

  const filterNameField = useField({
    name: 'name',
    fieldType: FieldType.Text,
    placeholder: translate!('Filter name', transFormRepSelector),
    rules: [{ type: Rule.RequiredRule, message: 'Filter name is required' }],
    maxLength: 50,
  });

  const filterToDayField = useField({
    name: 'filterToDay',
    fieldType: [FieldType.Date, FieldType.DateSelect],
    dataSource: [DateFilter.CurrentDay],
    defaultValue: new Date(),
    placeholder: translate!('Filter to day', transFormName),
  });

  const assetIdArrayField = useField({
    name: 'assetIdArray',
    fieldType: FieldType.MultipleOptions,
    dataSource: assetList,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset', transFormName),
  });

  const assetTypeIdArrayField = useField({
    name: 'assetTypeIdArray',
    fieldType: FieldType.MultipleOptions,
    dataSource: assetTypeList ?? [],
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset type', transFormName),
  });

  const capacityCategoryIdArrayField = useField({
    name: 'capacityCategoryIdArray',
    fieldType: FieldType.MultipleOptions,
    dataSource: capacityCategoryList ?? [],
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Capacity category', transFormName),
  });

  const responsiblePersonIdArrayField = useField({
    name: 'responsiblePersonIdArray',
    fieldType: FieldType.MultipleOptions,
    dataSource: responsiblePersonList ?? [],
    keyExpr: 'personalNumber',
    displayExpr: 'fullName',
    placeholder: translate!('Responsible person', transFormName),
  });

  const fields = [
    filterNameField,
    filterToDayField,
    assetIdArrayField,
    assetTypeIdArrayField,
    capacityCategoryIdArrayField,
    responsiblePersonIdArrayField,
  ];

  /**
   * Funkce pro zpracování formuláře při přidání nového / editaci stávajícího filtru
   */
  const onFilterDialogSubmit = (e) => {
    e.preventDefault();
    selectorRef.current.saveOrEditFilter(dialogAction);
  };

  const handleDialogVisibility = (action: FilterActionState | null = null) => {
    setDialogAction(action);
    setIsModalVisible(!!action);
  };

  return (
    <>
      <div>
        <div
          className="report-selector-bar"
          style={{ display: filterVisibility ? 'block' : 'none' }}
        >
          <ReportFilterSelector
            ref={selectorRef}
            filterName={filterNameField.value}
            fields={fields}
            dataGridRef={dataGridRef}
            onClear={clearDataAndFields}
            onFilterChange={onFilterChange}
            report={ReportType.AssetResponsiblePerson}
            openDialog={handleDialogVisibility}
            fetchData={getData}
            isDefault={isDefault}
            setIsDefault={(value) => setDefault(value)}
            loadDefaultSetting={true}
            loadDefaultSettingStorageKey={STORAGE_KEYS.RESP_PERSONS_REPORT_FILTER_DESKTOP}
          />
        </div>
        {isModalVisible && (
          <Popup
            visible={isModalVisible}
            dragEnabled={false}
            closeOnOutsideClick={true}
            onHiding={() => handleDialogVisibility(null)}
            showTitle={true}
            title={
              dialogAction === FilterActionState.EDIT
                ? translate!('Edit selected filter', transFormRepSelector)
                : translate!('Save a new filter', transFormRepSelector)
            }
            container=".dx-viewport"
            width="auto"
            height="auto"
          >
            {/* filter add/edit dialog */}
            <form onSubmit={onFilterDialogSubmit}>
              <Form colCount={3} className="fields-container">
                <GroupItem>
                  <FormField field={filterNameField} alt />
                </GroupItem>
                <GroupItem>
                  <FormField field={filterToDayField} alt />
                </GroupItem>
                <GroupItem>
                  <FormField field={assetIdArrayField} alt />
                </GroupItem>
                <GroupItem>
                  <FormField field={assetTypeIdArrayField} alt />
                </GroupItem>
                <GroupItem>
                  <FormField field={capacityCategoryIdArrayField} alt />
                </GroupItem>
                <GroupItem>
                  <FormField field={responsiblePersonIdArrayField} alt />
                </GroupItem>
                <GroupItem>
                  <CheckBox value={isDefault} onValueChanged={(e) => setDefault(e.value)} />{' '}
                  {translate!('Is default', transFormRepSelector)}
                </GroupItem>
                <GroupItem colSpan={3} cssClass={'action-buttons-view'}>
                  {dialogAction === FilterActionState.EDIT && <DeleteButton ref={selectorRef} />}
                  <Button
                    className="button-spacing"
                    text={translate!('Save', transFormRepSelector)}
                    icon="save"
                    type="success"
                    useSubmitBehavior
                    disabled={loading}
                  />
                </GroupItem>
              </Form>
            </form>
          </Popup>
        )}
      </div>
      <form onSubmit={onSearch} style={{ display: filterVisibility ? 'block' : 'none' }}>
        <Form colCount={6} className="popup-container">
          <GroupItem>
            <FormField field={filterToDayField} />
          </GroupItem>
          <GroupItem>
            <FormField field={assetIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FormField field={assetTypeIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FormField field={capacityCategoryIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FormField field={responsiblePersonIdArrayField} />
          </GroupItem>
          <GroupItem cssClass={''}>
            <Button
              type="success"
              text={translate!('Search', transFormName)}
              useSubmitBehavior
              disabled={loading}
            />
          </GroupItem>
        </Form>
      </form>
    </>
  );
}
