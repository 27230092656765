import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAuth } from '../../contexts/auth';
import { useMobileAuth } from '../../contexts/mobileAuthContext';
import { useTerminalAuth } from '../../contexts/terminalAuthContext';
import { useTranslation } from '../../contexts/translation';
import '../../custom.scss';
import { LoginForm as LoginFormForm } from '../../forms/loginForm/LoginForm';
import { useIsMountedRef } from '../../hooks/useIsMountedRef';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AssetClass } from '../../types/assetClass.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { checkIfImageExists } from '../../utils/checkImageExists';
import { FailedLoad } from '../failedLoad/FailedLoad';
import { Loader } from '../loader/Loader';

interface LoginInputs {
  username: string;
  password: string;
  uid: string;
  assetClassId: number | null;
  platform: string | null;
}

export const Platform = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TERMINAL: 'TERMINAL',
};

interface Props {
  onClickTechnicalUser?: () => void;
  disableAssetClassInput?: boolean;
  platform: string | null;
  showBackButton?: boolean;
}

export default function LoginForm({
  onClickTechnicalUser,
  disableAssetClassInput,
  platform,
  showBackButton,
}: Props) {
  const [defaultAssetClass] = useLocalStorage('assetClass', {});

  const { signIn } = useAuth();
  const { signIn: terminalAuth } = useTerminalAuth();
  const { signIn: mobileAuth } = useMobileAuth();

  const [isLoading, setLoading] = useState<boolean>(false);
  const isMountedRef = useIsMountedRef();

  const { translate } = useTranslation();
  const transFormName = 'LoginPage';
  
  const [isCustomerLogo, setIsCustomerLogo] = useState<boolean | null>(null);

  // Načteme číselník - AssetClass
  const { data: dataAssetClasses, error: errorAssetClasses } = useSWR<AssetClass[]>(
    API_URL.ASSET_CLASS_LIST,
    fetcher,
  );

  // Načteme config, jestli je povoleno přihlášení pomocí UID
  const { data: allowUIdLogin, isValidating: allowUIdLoginValidating } = useSWR<boolean>(
    `${API_URL.GET_CONFIG_IS_ENABLED}?name=allowUIdLogin`,
    fetcher,
  );

  // Kontrola, jestli existuje customer logo. Pokud ne, nechceme zobrazovat.
  useEffect(() => {
    checkIfImageExists(process.env.PUBLIC_URL + '/img/customer_logo.png', (exists) => {
      setIsCustomerLogo(exists);
    });
  }, []);

  const formData: LoginInputs = {
    username: process.env.NODE_ENV === 'development' ? 'workflow' : '',
    password: process.env.NODE_ENV === 'development' ? '123' : '',
    uid: '',
    assetClassId: dataAssetClasses
      ? Number(defaultAssetClass)
        ? defaultAssetClass
        : dataAssetClasses[0].id
      : null,
    platform: null,
  };

  /**
   * Přihlašovací formulář. Pokusí se přihlásit uživatele. Zároveň nastaví globální asset class.
   * Globální asset class se drží pouze v kontextu.
   * @param e
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (signIn || terminalAuth || mobileAuth) {
      if (signIn) {
        await signIn(
          formData.username,
          formData.password,
          formData.uid,
          formData.assetClassId!,
          platform!,
          translate!('Wrong login or password', transFormName)
        );
      } else if (terminalAuth) {
        await terminalAuth(
          formData.username,
          formData.password,
          formData.uid,
          formData.assetClassId!,
          platform!,
        );
      } else if (mobileAuth) {
        await mobileAuth(
          formData.username,
          formData.password,
          formData.uid,
          formData.assetClassId!,
          platform!,
        );
      }
    }

    // Ošetření kvůli možnosti změnu stavu unmounted komponenty
    if (isMountedRef.current) setLoading(false);
  };

  if (errorAssetClasses) return <FailedLoad message={null} />;
  if (!dataAssetClasses || isLoading || allowUIdLoginValidating || isCustomerLogo == null)
    return <Loader />;

  return (
    <>
      <LoginFormForm
        onClickTechnicalUser={onClickTechnicalUser}
        onSubmit={onSubmit}
        formData={formData}
        dataAssetClasses={dataAssetClasses}
        disableAssetClassInput={disableAssetClassInput}
        allowUIdLogin={allowUIdLogin}
        showBackButton={showBackButton}
        showCustomerLogo={isCustomerLogo}
      />
    </>
  );
}
