import React, { createContext, useContext, useEffect, useState } from 'react';
import { getItemApi } from '../apiUtils';
import { API_URL } from '../utils/apiUrl';

const ModuleContext = createContext<ModuleContextType>({
  isSparePartEnabled: null,
  isNfcEnabled: null,
});

function ModuleProvider(props: any) {
  const [isSparePartEnabled, setIsSparePartEnabled] = useState<boolean>();
  const [isNfcEnabled, setIsNfcEnabled] = useState<boolean>();

  useEffect(() => {
    (async function () {
      await getItemApi<boolean>({
        url: `${API_URL.IS_MODULE_SPARE_PART_ENABLED}`,
        params: {},
        callAfterSuccess: (res) => {
          setIsSparePartEnabled(res);
        },
      });
      await getItemApi<boolean>({
        url: `${API_URL.IS_MODULE_NFC_ENABLED}`,
        params: {},
        callAfterSuccess: (res) => {
          setIsNfcEnabled(res);
        },
      });
    })();
  }, []);

  return <ModuleContext.Provider value={{ isSparePartEnabled, isNfcEnabled }} {...props} />;
}

interface ModuleContextType {
  isSparePartEnabled: boolean | null;
  isNfcEnabled: boolean | null;
}

const useModuleEnabled = () => useContext<ModuleContextType>(ModuleContext);

export { ModuleProvider, useModuleEnabled };
