import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { WorksheetCreateDetailFn } from './components/MobileMenu/functions/worksheetCreateDetailFn';
import { LoadingProvider } from './contexts/loadingContext';
import { useMobileAuth } from './contexts/mobileAuthContext';
import { withNavigationWatcher } from './contexts/navigation';
import { TasksSparePartsSearchPage } from './pages/Tasks/Mobile/Detail/SpareParts/TasksSparePartsSearchPage';
import { TaskMobileStepsPage } from './pages/Tasks/Mobile/Detail/Steps/TaskMobileStepsPage';
import { MenuPage } from './pages/_Mobile/MenuPage';
import { UserRoles } from './types/applicationUser.types';

const TasksMobile = React.lazy(() => import('./pages/Tasks/Mobile/TasksMobile'));
const TasksMobileList = React.lazy(() => import('./pages/Tasks/Mobile/List/TasksMobileList'));
const TasksMobileDetail = React.lazy(() => import('./pages/Tasks/Mobile/Detail/TasksMobileDetail'));
const TasksMobileNew = React.lazy(() => import('./pages/Tasks/Mobile/New/TasksMobileNew'));
const TasksMobileQrScan = React.lazy(() => import('./pages/Tasks/Mobile/QrScan/TasksMobileQrScan'));
const TasksMobileBarcodeScanUrl = React.lazy(
  () => import('./pages/Tasks/Mobile/BarcodeScan/TasksMobileBarcodeScanUrl'),
);
const MobileNfcScan = React.lazy(() => import('./pages/NFCScanner/MobileNfcScan'));
const AssignTagToAssetMobile = React.lazy(
  () => import('./pages/AssignTagToAsset/AssignTagToAsset'),
);

const TasksMobileSparePartIssue = React.lazy(
  () => import('./pages/Tasks/Mobile/Detail/SpareParts/TasksSparePartsIssue'),
);
const TasksMobileSparePartReturn = React.lazy(
  () => import('./pages/Tasks/Mobile/Detail/SpareParts/TasksSparePartsReturn'),
);
const WorkSheetsMobileList = React.lazy(
  () => import('./pages/WorkSheets/Mobile/List/WorkSheetsMobileList'),
);
const WorkSheetsMobileDetail = React.lazy(
  () => import('./pages/WorkSheets/Mobile/Detail/WorkSheetsMobileDetail'),
);
const WorkSheetsMobileNew = React.lazy(
  () => import('./pages/WorkSheets/Mobile/New/WorkSheetsMobileNew'),
);

export const mobileRoutes = [
  {
    path: '/mobile',
    component: MenuPage,
    restrictedAccess: [],
  },
  {
    path: '/mobile/menu/:typeId?/:assetId?/:taskId?/:worksheetId?/:tagId?',
    component: MenuPage,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/list/:assetId?',
    component: TasksMobileList,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/mobile/tasks/new/:assetId?',
    component: TasksMobileNew,
    restrictedAccess: [UserRoles.TaskCreate],
  },
  {
    path: '/mobile/tasks/detail/:id/:setTemporalyValue?/:assetId?',
    component: TasksMobileDetail,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/spare-part-issue/:id',
    component: TasksMobileSparePartIssue,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/spare-part-return/:id',
    component: TasksMobileSparePartReturn,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/spare-part-search',
    component: TasksSparePartsSearchPage,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/steps/:id',
    component: TaskMobileStepsPage,
    restrictedAccess: [],
  },
  {
    path: '/mobile/worksheets',
    component: WorkSheetsMobileList,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/mobile/worksheets/new/:assetId?/:taskId?',
    component: WorkSheetsMobileNew,
    restrictedAccess: [UserRoles.TaskCreate],
  },
  {
    path: '/mobile/worksheets/detail/:id/:setTemporalyValue?/:assetId?',
    component: WorkSheetsMobileDetail,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/scan',
    component: TasksMobileQrScan,
    restrictedAccess: [],
  },
  {
    path: '/mobile/tasks/barcodeScan',
    component: TasksMobileBarcodeScanUrl,
    restrictedAccess: [],
  },
  {
    path: '/mobile/nfcScan',
    component: MobileNfcScan,
    restrictedAccess: [],
  },
  {
    path: '/mobile/assignTagToAsset/:tagId?',
    component: AssignTagToAssetMobile,
    restrictedAccess: [],
  },
  {
    path: '/mobile/function/worksheet-create-detail/:assetId',
    component: WorksheetCreateDetailFn,
    restrictedAccess: [],
  },
];

export const routesMobile = mobileRoutes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});

export const MobileRoutes = () => {
  const { entryUrl } = useMobileAuth();

  return (
    <Switch>
      <LoadingProvider>
        {routesMobile.map(({ path, component: Component }) => {
          return <Route exact key={path} path={path} component={Component} />;
        })}
        <Redirect to={entryUrl || '/mobile'} />
      </LoadingProvider>
    </Switch>
  );
};
