import { locale } from 'devextreme/localization';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabParameterMeasuredColumns';
import AssetParameterMeasuredModal from '../modals/AssetParameterMeasuredModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export interface IAssetTabParameterMeasuredDataGrid extends IDataGrid {
  isReadOnly?: boolean;
  assetParameterChanged: (rowData: any, value: any) => void;
  assetParameters: any;
  getFilteredUnits: (options: any) => { store: any; filter: any[] | null };
  units: any;
  selectedUnitId?: number;
  handleNew: () => void;
  handleEdit: (e: any) => void;
  handleClose: () => void;
  modalVisible: boolean;
  selectedData: any;
  selectedAction: any;
  onDataSubmit: (data: any, actionType: any) => void;
}

export const AssetTabParameterMeasuredDataGrid = ({
  data,
  isReadOnly,
  onSaving,
  assetParameterChanged,
  assetParameters,
  getFilteredUnits,
  units,
  selectedUnitId,
  handleNew,
  handleEdit,
  handleClose,
  modalVisible,
  selectedData,
  selectedAction,
  onDataSubmit,
}: IAssetTabParameterMeasuredDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.ParameterMeasured';

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset parameters measured', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const dateFormat = () => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'DD.MM.yyyy';
    else return 'DD/MM/yyyy';
  };

  const dateTimeFormat = () => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'DD.MM.yyyy HH:mm';
    else return 'DD/MM/yyyy HH:mm';
  };

  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);
  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(
          handleEdit,
          isReadOnly,
          translate,
          dateFormat,
          dateTimeFormat,
          transFormName,
        )}
        onToolbarPreparing={onToolbarPreparing}
        columnChooser={false}
        allowColumnResizing={false}
        isReadOnly={isReadOnly}
        allowColumnReordering={false}
        prompt={Prompt}
      />
      {modalVisible && selectedAction && (
        <AssetParameterMeasuredModal
          units={units}
          parameterChanged={assetParameterChanged}
          parameters={assetParameters}
          isVisible={modalVisible}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={transFormName}
          onSubmit={onDataSubmit}
          onClose={handleClose}
          selectedUnitId={selectedUnitId}
        />
      )}
    </>
  );
};
