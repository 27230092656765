import Popup, { Position } from 'devextreme-react/popup';
import { toLower } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { postApi } from '../../../apiUtils';
import { Loader } from '../../../components/loader/Loader';
import { useTerminalAuth } from '../../../contexts/terminalAuthContext';
import { useTranslation } from '../../../contexts/translation';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Person } from '../../../types/person.types';
import { Task } from '../../../types/task.types';
import { TaskAssistancePanel } from '../../../types/taskAssistancePanel.types';
import { TaskRatings } from '../../../types/taskRating.types';
import { TaskStatusApiResponse } from '../../../types/TaskStatus';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { swrConfig } from '../../Assets/AssetsEdit';
import { TaskChangeStatus } from '../TaskChangeStatus';
import './AssistanceStyle.scss';

type GridItemProps = {
  panel?: TaskAssistancePanel;
  color?: string | null;
};

export function TerminalAssistance() {
  const [selectedPanel, setSelectedPanel] = useState<TaskAssistancePanel | null>(null);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [assetId, setAssetId] = useState<number | null>(null);
  const { user, assetIdFromUrl, terminalMappings } = useTerminalAuth();
  const { translate } = useTranslation();
  const location = useLocation();
  const [sessionTerminalMappingId] = useSessionStorage(STORAGE_KEYS.TERMINAL_MAPPING_ID, {});

  /**
   * Načtení tasku pro výběru panelu
   */
  const {
    data: task,
    error: errorTask,
    mutate: mutateTask,
    isValidating: taskLoading,
  } = useSWR<Task>(
    selectedPanel?.lastAssociatedTaskId
      ? `${API_URL.TASK_ITEM}?id=${selectedPanel?.lastAssociatedTaskId}`
      : null,
    fetcher,
    swrConfig,
  );

  /**
   * Načteme všechny asistenční panely
   */
  const {
    data: taskAssistancePanels,
    error: errorTaskAssistancePanels,
    mutate: mutateTaskAssistancePanels,
    isValidating: taskAssistancePanelsLoading,
  } = useSWR<TaskAssistancePanel[]>(
    assetId
      ? `${API_URL.TASK_ASSISTANCE_PANEL_LIST}?parentId=${assetId}&visibleOnly=true`
      : API_URL.TASK_ASSISTANCE_PANEL_LIST,
    fetcher,
  );

  /**
   * Načteme všechny osoby, které jsou u této kapacitní kategorie tasku
   */
  const { data: taskActionRespPersons, error: taskActionRespPersonsError } = useSWR<Person[]>(
    task && task.id && task.capacityCategoryId ? `${API_URL.CAPACITY_CATEGORY_PERSON_LIST}` : null,
    fetcher,
    swrConfig,
  );

  /**
   * Načteme stavy pro akční tlačítka.
   */
  const {
    data: statuses,
    error: statusesError,
    isValidating: statusesLoading,
    mutate: mutateStatuses,
  } = useSWR<TaskStatusApiResponse>(
    task && task.id ? `${API_URL.TASK_PROCESS_CONFIG}?taskId=${task.id}` : null,
    fetcher,
    swrConfig,
  );

  // Načtení rating ikon
  const { data: taskRatings } = useSWR<TaskRatings>(
    statuses && statuses?.find((s: any) => s?.requireRating === true)
      ? `${API_URL.TASK_RATING_LIST}?visibleOnly=true`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (result) => {
        let sortedArr = result.sort((a: any, b: any) =>
          a.rating > b.rating ? 1 : b.rating > a.rating ? -1 : 0,
        );
        return sortedArr;
      },
    },
  );

  /**
   * Funkce pro vytvoření tasku
   */
  const createTask = async (panel: TaskAssistancePanel) => {
    setIsSubmited(true);
    await postApi({
      url: API_URL.TASK_ASSISTANCE_CREATE_TASK,
      data: {
        panelId: panel.id,
      },
      hideSuccessNotifications: true,
    });
    setIsSubmited(false);
  };

  /**
   * Funkce pro změnu stavu tasku nebo změnu viditelnosti a vybraného panelu
   */
  const handleSelectedPanel = async (panel: TaskAssistancePanel) => {
    setSelectedPanel(panel);
    if (panel.panelIsActive) setModalVisibility(true);
    else {
      !isSubmited && (await createTask(panel));
      mutateTaskAssistancePanels();
    }
  };

  /**
   * Funkce pro zavření modálního okna
   */
  const handleCloseModal = () => {
    setModalVisibility(false);
    setSelectedPanel(null);
  };

  /**
   * Funkce pro změnu stavu tasku
   */
  const handleStatusChanged = () => {
    if (mutateTask) mutateTask();
    mutateTaskAssistancePanels();
    handleCloseModal();
  };

  useEffect(() => {
    // Primárně načteme assetId z účtu uživatele
    if (user && user.assetId) {
      setAssetId(user.assetId);
      return;
    }

    // Uživatel assetId nemá, načteme assetId, které mohlo být nastaveno při přihlášení (např. přes URL)
    if (assetIdFromUrl) {
      setAssetId(assetIdFromUrl);
      return;
    }

    // Pokud existuje terminaMapping, načteme ID z něhoo - poslední možnost #430 - id je uložené v sessinStorage
    if (
      sessionTerminalMappingId &&
      terminalMappings &&
      terminalMappings.length > 0 &&
      terminalMappings[0]
    ) {
      const current = terminalMappings.find((x) => x.id === sessionTerminalMappingId);
      if (current) setAssetId(current.assignedAssetId);
      return;
    }

    // Při přihkášení assetId nepřišlo, podíváme se do URL přímo na této obrazovce
    const _assetIdFromUrl: number | null = location.search.includes('assetId')
      ? Number(location.search.split('=')[1])
      : null;
    setAssetId(_assetIdFromUrl);
  }, []);

  const GridItem = ({ panel, color = 'transparent' }: GridItemProps) => {
    const name = panel?.name || '';
    return (
      <div
        className={`assistance-grid-item ${
          color && panel?.panelIsActive
            ? `assistance-${toLower(color)}_row-bg`
            : 'assistance-default_row-bg'
        }`}
        {...(panel ? { onClick: () => handleSelectedPanel(panel) } : {})}
      >
        {name}
      </div>
    );
  };

  const GridAssistance = ({}) => {
    if (!taskAssistancePanels || taskAssistancePanels.length === 0) return null;
    // Výpočet pozice
    let lastPosition: number | undefined =
      taskAssistancePanels[taskAssistancePanels?.length - 1].position;
    if (!lastPosition) lastPosition = 0;

    return (
      <>
        {[...Array(lastPosition)].map((x, i) => {
          const panel = taskAssistancePanels.find((x) => x.position === i + 1);
          if (panel)
            return <GridItem key={i} panel={panel} color={panel?.capacityCategoryColorName} />;
          else return <div key={i} className="assistance-grid-item blank"></div>;
        })}
      </>
    );
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      noResponsivePaddings
      loading={taskAssistancePanelsLoading}
      error={errorTaskAssistancePanels}
    >
      {!assetId ? (
        <div className="assistance-asset-error-message">
          {translate!('Asset configuration error', 'Terminal.Assistance')}
        </div>
      ) : (
        <div className="assistance-container">
          <div className="assistance-grid-container">
            <GridAssistance />
          </div>
        </div>
      )}
      <Popup
        visible={modalVisibility}
        onHiding={handleCloseModal}
        closeOnOutsideClick
        showCloseButton
        showTitle
        dragEnabled={false}
        title={translate!('Change status', 'Task.Update')}
        width={'50%'}
        height={'50%'}
      >
        <Position at={'center'} my="center" />
        {statusesLoading || !statuses || !modalVisibility ? (
          <Loader />
        ) : statuses.length > 0 ? (
          <TaskChangeStatus
            isTerminal
            task={task}
            mutate={handleStatusChanged}
            responsiblePersons={taskActionRespPersons}
            showCurrentStatus={false}
            statuses={statuses}
            mutateStatuses={mutateStatuses}
            poupWidth={350}
            hideSuccessNotifications={true}
            isAutoSubmitLastAction={true}
            taskRatings={taskRatings}
          />
        ) : (
          <div className="assistance-task-actions-error">
            {translate!('No available actions', 'Terminal.Assistance')}
          </div>
        )}
      </Popup>
    </PageContentWrapper>
  );
}

export default TerminalAssistance;
