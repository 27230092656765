import React from 'react';

interface Props {
  parentName: string | null;
  name: string | null;
}

export function MobileMenuAsset({ parentName, name }: Props) {
  return (
    <div style={{ padding: 10, fontSize: 22, fontWeight: 600 }}>
      {parentName} - {name}
    </div>
  );
}
