import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import { concat, isNil, not, path, toLower } from 'ramda';

export const paintRowByTaskColor = (e: RowPreparedEvent) => {
  if (e.data) {
    const colorNotNil = not(isNil(path(['data', 'typeColorName'], e)));
    if (e.rowElement && e.rowElement.className) {
      e.rowElement.className = concat<string, string>(
        e.rowElement.className,
        colorNotNil ? ` ${toLower(e.data.typeColorName)}_row` : ' default_row',
      );
    }
  }
};
