// @ts-ignore
import React from 'react';
import {
  BoolFilterValue,
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../components/table';
import { uniqueLookUpByAttribute } from '../../utils/uniqueLookUpByAttribute';

export const Columns = (handleEdit, data, translate): SisDataGridColumns => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    width: '5%',
    caption: 'Parameter id',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    width: '20%',
    caption: 'Name',
  },
  {
    dataType: IDataType.string,
    dataField: 'description',
    width: '25%',
    caption: 'Description',
  },
  {
    dataType: IDataType.string,
    dataField: 'baseUnit.name',
    width: '10%',
    caption: 'Base unit',
    lookupSource: uniqueLookUpByAttribute(data, ['baseUnit'], ['name']),
  },
  {
    dataField: 'measurementAllowed',
    dataType: IDataType.boolean,
    filterValue: BoolFilterValue.all,
    width: '10%',
    caption: 'Measurement allowed',
  },
  {
    dataField: 'created',
    dataType: IDataType.date,
    width: '15%',
    caption: 'Created',
  },
  {
    dataField: 'visible',
    dataType: IDataType.boolean,
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={() => handleEdit(cellData)}
    />
  );
};
