import moment from 'moment';
import React, { useState } from 'react';
import useSWR from 'swr';
import { Loader } from '../../../components/loader/Loader';
import { useAssetClass } from '../../../contexts/assetClass';
import { CONFIG_KEYS, useConfigByKey } from '../../../contexts/configContext';
import WorkSheetsForm, { getDuration } from '../../../forms/workSheets/WorkSheetsForm';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Asset } from '../../../types/asset.types';
import { CapacityCategory, CapacityCategoryPerson } from '../../../types/capacityCategory.types';
import { DocumentType } from '../../../types/documentType.types';
import { Person } from '../../../types/person.types';
import { Task } from '../../../types/task.types';
import { TaskStatusApiResponse } from '../../../types/TaskStatus';
import { WorkActivity } from '../../../types/workActivity.types';
import { WorkActivityGroup } from '../../../types/workActivityGroup.types';
import { WorkSheet } from '../../../types/workSheet.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { ITab, swrConfig } from '../../Assets/AssetsEdit';

interface Props extends ITab {
  id: string;
}

export const GeneralSheetTab = ({ id, active }: Props) => {
  const [isReadOnly] = [false]; // useIsWorkSheetEditable(id, true);
  const [oldRequiredDate, setOldRequiredDate] = useState<Date | null | string>();
  const { assetClass } = useAssetClass();
  const configRestrictWorkActivitiesByCapacityCategoryPerson = useConfigByKey(
    CONFIG_KEYS.RESTRICT_WA_BY_CAPACITY_CATEGORY,
  );
  const restrictWorkActivitiesByCapacityCategoryPerson =
    configRestrictWorkActivitiesByCapacityCategoryPerson
      ? Boolean(configRestrictWorkActivitiesByCapacityCategoryPerson.value === '1')
      : false;

  /**
   * Načte task podle id.
   */
  const {
    data: workSheet,
    error: sheetError,
    mutate: mutateSheet,
  } = useSWR<WorkSheet>(`${API_URL.WORKSHEET_ITEM}?id=${id}`, fetcher, swrConfig);

  const {
    data: task,
    error: errorTask,
    isValidating: taskLoading,
  } = useSWR<Task>(
    workSheet ? `${API_URL.TASK_ITEM}?id=${workSheet.taskId}` : null,
    fetcher,
    swrConfig,
  );

  function dateFormat(days: number) {
    return moment(
      new Date(24 * 3600 * 1000 * Math.floor(new Date().getTime() / (24 * 3600 * 1000) + days)),
    ).format('Y-MM-DDTHH:mm:ss');
  }

  /**
   * Načte se seznam topLevelAssetů - linky
   */
  const { data: topLevelAssets, error: topLevelAssetsError } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS_TOP_LEVEL}?classId=${assetClass}&activeOnly=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Nacte typ pozadavku pro vytvareni z vykazu
   */
  const { data: newTaskDocumentType, error: errorNewTaskDocumentType } = useSWR<DocumentType[]>(
    `${API_URL.GET_LIST_FOR_TASK_CREATE}?parentId=${assetClass}&taskCreateWorksheet=true&visibleOnly=true`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte se seznam činností
   */
  const { data: workActivities, error: workActivitiesError } = useSWR<WorkActivity[]>(
    `${API_URL.WORK_ACTIVITY_LIST}?parentId=`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte se seznam skupin činností
   */
  const { data: workActivityGroups, error: workActivityGroupsError } = useSWR<WorkActivityGroup[]>(
    `${API_URL.WORK_ACTIVITY_GROUP_LIST}?parentId=`,
    fetcher,
    swrConfig,
  );

  const { data: taskProcessConfigList, error: errorTaskProcessConfigList } =
    useSWR<TaskStatusApiResponse>(
      `${API_URL.TASK_PROCESS_CONFIG_LIST}?visibleOnly=${true}`,
      fetcher,
      swrConfig,
    );

  /**
   * Načte zodpovědné osoby pro task action. Pouze v editaci.
   */
  const { data: workSheetOperators, error: workSheetOperatorsError } = useSWR<
    CapacityCategoryPerson[]
  >(`${API_URL.CAPACITY_CATEGORY_PERSON_LIST}?visibleOnly=true`, fetcher, swrConfig);

  let localWorkSheetOperators: CapacityCategoryPerson[] = [];
  workSheetOperators?.forEach((item) => {
    if (localWorkSheetOperators?.filter((x) => x?.personId === item.personId).length === 0) {
      localWorkSheetOperators = [...localWorkSheetOperators, item];
    }
  });

  /**
   * Načte seznam capacityCategories.
   */
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    swrConfig,
  );

  /**
   * Načte seznam assetů, pokud je vybrána linka (topLevelParenId), filtrují se assety podle této linky
   */
  const {
    data: assets,
    error: assetsError,
    isValidating: isValidatingAssets,
  } = useSWR<Asset[]>(`${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`, fetcher, swrConfig);

  /**
   * Načte se seznam tasků
   */
  const { data: tasksByClass, error: errorTasksByClass } = useSWR<Task[]>(
    `${API_URL.TASK_BY_CLASS_WORKSHEET_CREATE_EDIT}?classId=${assetClass}&statusIdArr=10&statusIdArr=20&statusIdArr=50`,
    fetcher,
    swrConfig,
  );

  // Načteme číselník - Persons
  const { data: operators, error: errorOperators } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  if (sheetError) return <>Došlo k chybě na straně API</>;

  if (
    !workSheet ||
    !task ||
    !operators ||
    !workActivities ||
    !capacityCategories ||
    !assets ||
    !topLevelAssets
  )
    return <Loader />;

  const dateActivityStarted =
    String(workSheet.activityStarted).split('T').length == 2
      ? moment(
          String(workSheet.activityStarted).split('T')[0] +
            ' ' +
            String(workSheet.activityStarted).split('T')[1],
        )
      : moment(workSheet.activityStarted + ':00');
  const dateActivityFinished = workSheet.activityFinished
    ? String(workSheet.activityFinished).split('T').length == 2
      ? moment(
          String(workSheet.activityFinished).split('T')[0] +
            ' ' +
            String(workSheet.activityFinished).split('T')[1],
        )
      : moment(workSheet.activityFinished + ':00')
    : null;

  const config = taskProcessConfigList
    ? taskProcessConfigList.find(
        (x) =>
          x.documentTypeId === task.typeId && x.statusFromId === task.statusId && x.actionId === 14,
      )
    : null;

  const result = !(
    (!workSheet.taskId || (config ? config.statusToId == 50 : false)) &&
    workSheet.activityFinished
  );

  const resultDefault = task.responsiblePersonId === workSheet.operatorId;

  let activityFinished: Date | string | null = workSheet.activityFinished;
  if (task && task.finished && !activityFinished) activityFinished = task.finished;

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={sheetError}
      loading={!workSheet || !taskProcessConfigList}
    >
      <WorkSheetsForm
        defaultFormData={{
          operatorName: operators?.find((x) => x.personalNumber === workSheet.operatorId)?.fullName,
          workActivityName: workActivities?.find((x) => x.id === workSheet.workActivityId)?.name,
          capacityCategoryName: !task
            ? '-'
            : workSheet.workActivityCapacityCategoryId
            ? capacityCategories?.find((x) => x.id === workSheet.workActivityCapacityCategoryId)
                ?.name
            : capacityCategories?.find((x) => x.id === task.capacityCategoryId)?.name,
          assetName: !assets ? '' : assets.find((x) => x.id === workSheet.taskAssetId)?.name,
          topLevelParenName: !assets
            ? '-'
            : topLevelAssets?.find(
                (x) => x.id === assets.find((x) => x.id === workSheet.taskAssetId)?.topLevelParenId,
              )?.name,

          statusNameTranslated: task?.statusNameTranslated,
          typeNameTranslated: task?.typeNameTranslated,
          responsiblePersonName: task?.responsiblePersonName,

          taskProcessConfigList: taskProcessConfigList,
          task: task,
          operators: operators,
          workActivities: workActivities,
          capacityCategories: capacityCategories,
          assets: assets,
          topLevelAssets: topLevelAssets,
          tasksByClass: tasksByClass,
          workSheetOperatorsCapacityCategories: workSheetOperators,
          workSheetOperators: localWorkSheetOperators,

          id: workSheet.id,
          taskCreated: task ? task.created : null,
          taskCloseReadonlyFlag: result,
          taskCloseDefaultValue: result ? false : resultDefault,
          taskComplete: result ? false : resultDefault,
          taskStatusId: task ? task.statusId : null,
          taskDocumentType: task ? task.typeId : null,
          taskResponsiblePersonId: task ? task.responsiblePersonId : null,
          taskId: workSheet.taskId,
          capacityCategoryId: workSheet.workActivityCapacityCategoryId,
          //defaultTaskId: String(workSheet.taskId),
          defaultNewTaskDocumentType:
            newTaskDocumentType && newTaskDocumentType?.length > 0
              ? newTaskDocumentType.find((x) => x !== undefined)!.id
              : -1,
          defaultTaskCompleteBehavior:
            newTaskDocumentType && newTaskDocumentType?.length > 0
              ? newTaskDocumentType.find((x) => x !== undefined)!.worksheetTaskCompleteBehavior
              : undefined,
          operatorId: workSheet.operatorId,
          taskName: workSheet.taskName,
          description: workSheet.description,
          workActivityId: workSheet.workActivityId,
          workActivityGroupId: workSheet.workActivityGroupId,
          workSheetStatusId: workSheet.workSheetStatusId,
          activityStarted: workSheet.activityStarted,
          activityFinished: activityFinished,
          activityDuration: getDuration(dateActivityStarted, dateActivityFinished),

          assetId: workSheet.taskAssetId,
          taskCapacityCategoryId: workSheet.workActivityCapacityCategoryId,
          tmpWorkSheetStatusId: workSheet.workSheetStatusId,
        }}
        defaultFormDataTask={{
          typeId: 401,
          taskName: 'text',
          description: 'a',
        }}
        onSubmitDone={() => {}}
        isReadOnly={isReadOnly}
        oldRequiredDate={oldRequiredDate}
        isEdit
        transFormName={'WorkSheet.Update'}
        mutateTask={mutateSheet}
        today={moment()}
        oldActivityStarted={moment(workSheet.activityStarted)}
        oldActivityFinished={moment(workSheet.activityFinished)}
        restrictWorkActivitiesByCapacityCategoryPerson={
          restrictWorkActivitiesByCapacityCategoryPerson
        }
      />
    </PageContentWrapper>
  );
};
