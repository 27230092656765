import React, { useEffect, useRef } from 'react';
import useSWR from 'swr';
import { File } from '../../types/file.types';
import { API_URL, imageFetcher } from '../../utils/apiUrl';
interface Props {
  id: number;
  name: string;
  entityName: string;
  tmpFile?: File;
  targetHeight?: number | string;
  targetWidth?: number | string;
}

// convert File to Blob
export const fileToBlob = async (file) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export function ImagePreview(props: Props) {
  const imagePlaceholder = `/img/placeholder-image.png`;
  // Reference na obrázek - aby bylo možné po načtení z API přiřadit blob image elementu
  const imageRef = useRef({} as any);
  const targetHeight = props.targetHeight ? `&targetHeight=${props.targetHeight}` : '';
  const targetWidth = props.targetWidth ? `&targetWidth=${props.targetWidth}` : '';
  const { data: file } = useSWR(
    props.id !== 0
      ? `${API_URL.FILE_ITEM_THUMBNAIL}?id=${props.id}${targetWidth}${targetHeight}`
      : null,
    imageFetcher,
  );

  useEffect(() => {
    if (!props.tmpFile || !imageRef || imageRef.current === null) return;
    // ošetřit čekání než se obr. načte
    if (props.tmpFile) {
      let blob = fileToBlob(props.tmpFile);
      blob.then(async (res) => {
        imageRef.current!.src = URL.createObjectURL(await res);
      });
    }
  }, [props.tmpFile, imageRef, imageRef.current]);

  useEffect(() => {
    if (!file || props.tmpFile) return;
    imageRef.current!.src = URL.createObjectURL(file);
  }, [file]);

  if (!file && !props.tmpFile) return <>Loading</>;

  return (
    <img
      ref={imageRef}
      src={imagePlaceholder}
      className="figure-img img-fluid rounded"
      height={props.targetHeight ? props.targetHeight : undefined}
      style={{ width: 'auto' }}
      alt={props.name}
      title={props.name}
    />
  );
}
