import { Form, Popup } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { ButtonItem, ButtonOptions, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getItemApi } from '../../apiUtils';
import '../../assets/bulk-modal.scss';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { Step } from '../../types/step.types';
import { API_URL } from '../../utils/apiUrl';
import { displayDateFormat_CZ, displayDateFormat_EN } from '../../utils/dates.types';
import './EditCreateModal.scss';
import './TasksBulkEditModal.scss';

type Props = {
  data: any;
  stepList?: Step[];
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
  transFormName: string;
  assetCycleId: number;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Asset cycle step v datagridu
 */
export default function AssetCycleStepModalDate({
  isVisible,
  data,
  stepList,
  actionType,
  onClose,
  onSubmit,
  transFormName,
  assetCycleId,
}: Props) {
  const { translate, lang } = useTranslation();
  const formRef = useRef<Form>(null);
  const { loading } = useLoading();
  const [formData, setFormData] = useState<any>({
    ...data,
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });
  const [estimatedOccurrance, setEstimatedOccurrance] = useState<string | null>(
    translate!('Loading', ''),
  );
  const [isCounterVisible, setCounterVisible] = useState<boolean>(true);
  const dateFormat = lang === 'en-US' ? displayDateFormat_EN : displayDateFormat_CZ;

  useEffect(() => {
    setFormData({
      ...data,
      ...(actionType === DataGridActionType.Insert && { visible: true }),
      frequency: data && data.frequency ? data.frequency : 1,
      counter: data && data.counter ? data.counter : 0,
    });
  }, []);

  useEffect(() => {
    if (formData.frequency === undefined || formData.counter === undefined) return;
    getEstimatedOccurrence();
    handleCounterVisibility();
  }, [formData]);

  const getEstimatedOccurrence = async () => {
    setEstimatedOccurrance('Loading');
    getItemApi({
      url: API_URL.ASSET_CYCLE_CALCULATE_ESTIMATED_OCCURRANCE,
      params: {
        assetCycleId: assetCycleId,
        frequency: formData.frequency,
        counter: formData.counter,
      },
      callAfterSuccess: (result) => {
        setEstimatedOccurrance(moment(result).format(dateFormat));
      },
    });
  };

  const handleCounterVisibility = (): boolean => {
    // Skryjeme counter, pokud je měsíční frekvence
    const isFrequencyOne = formData.frequency === 1;
    setCounterVisible(!isFrequencyOne);
    return isFrequencyOne;
  };

  const onFrequencyChanged = () => {
    getEstimatedOccurrence();
    const isFrequencyOne = handleCounterVisibility();
    if (isFrequencyOne)
      setFormData({
        ...formData,
        counter: 0,
      });
  };

  const onCounterChanged = () => {
    getEstimatedOccurrence();
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset cycle step', transFormName)}
      width={'20%'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="stepId"
              editorType="dxSelectBox"
              editorOptions={{
                items: stepList,
                valueExpr: 'id',
                displayExpr: 'name',
                readOnly: actionType === DataGridActionType.Update,
              }}
            >
              <Label text={translate!('Step', transFormName)} />
              <RequiredRule message={translate!('Select step', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="frequency"
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  { value: 1, name: translate!('Monthly', 'Asset.CycleStep') },
                  { value: 3, name: translate!('Quarterly', 'Asset.CycleStep') },
                  { value: 6, name: translate!('Semi-annually', 'Asset.CycleStep') },
                  { value: 12, name: translate!('Annually', 'Asset.CycleStep') },
                ],
                value: formData?.frequency,
                valueExpr: 'value',
                displayExpr: 'name',
                onValueChanged: () => onFrequencyChanged(),
              }}
            >
              <Label text={translate!('Frequency', transFormName)} />
              <RequiredRule message={translate!('Select frequency', transFormName)} />
            </SimpleItem>
          </GroupItem>
          {isCounterVisible && (
            <GroupItem>
              <SimpleItem
                dataField="counter"
                editorType="dxNumberBox"
                editorOptions={{
                  onValueChanged: () => onCounterChanged(),
                }}
              >
                <Label text={translate!('Counter', transFormName)} />
              </SimpleItem>
            </GroupItem>
          )}
          <GroupItem>
            {translate!('Estimated occurrance:', transFormName)}{' '}
            <strong>{estimatedOccurrance}</strong>
          </GroupItem>
          <GroupItem>{translate!('Frequency and counter note', 'Asset.CycleStep')}</GroupItem>

          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                disabled={loading}
                type={'success'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions type={'normal'} text={translate!('Close', '')} onClick={onClose} />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
