import moment from 'moment';
import React from 'react';
import { DataGridButton, IColumnType, IDataType, SisDataGridColumns } from '../../components/table';
import { NotificationTemplate } from '../../types/notificationTemplate.types';
import { HourMinFormat, HourMinSecFormat } from '../../utils/dates.types';

export const Columns = (
  handleEdit,
  handleRemove,
  notificationTemplateList,
  translate,
): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'id',    
    width: '5%',
    caption: 'Id',
    cssClass: 'dg-align-center',
  },
  {
    dataType: IDataType.string,
    dataField: 'taskProcessConfigId',
    width: '19%',
    caption: 'Task process config',
    cellRender: (data) => getTaskProcessConfig(data),
  },
  {
    dataType: IDataType.string,
    dataField: 'assetLocationName',
    width: '8%',
    caption: 'Asset location name',
  },
  {
    dataType: IDataType.string,
    dataField: 'capacityCategoryName',
    width: '8%',
    caption: 'Capacity category name',
  },
  {
    dataType: IDataType.string,
    dataField: 'templateId',
    width: '13%',
    caption: 'Notification template',
    cellRender: (data) => translateTemplate(data, notificationTemplateList),
  },
  {
    dataType: IDataType.string,
    dataField: 'delay',
    width: '8%',
    caption: 'Delay',
    cellRender: (row) =>
      row.data.delay ? moment(row.data.delay, HourMinSecFormat).format(HourMinFormat) : null,
  },
  {
    dataType: IDataType.string,
    dataField: 'repeat',
    width: '8%',
    caption: 'Repeat',
    cellRender: (row) =>
      row.data.repeat ? moment(row.data.repeat, HourMinSecFormat).format(HourMinFormat) : null,
  },
  {
    dataType: IDataType.boolean,
    dataField: 'isActive',
    width: '6%',
    caption: 'Is active',
  },
  {
    dataType: IDataType.string,
    dataField: 'description',
    width: '20%',
    caption: 'Description',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, handleRemove, translate),
    width: '5%',
  },
];

const getTaskProcessConfig = (data) => {
  if (data.data.taskProcessConfig === null) return;
  let taskProcesConfigText: string = '';
  taskProcesConfigText +=
    data.data.taskProcessConfig?.documentTypeNameTranslated === null
      ? ''
      : data.data.taskProcessConfig?.documentTypeNameTranslated;
  taskProcesConfigText +=
    data.data.taskProcessConfig?.actionNameTranslated === null
      ? ''
      : ', ' + data.data.taskProcessConfig?.actionNameTranslated;
  taskProcesConfigText +=
    data.data.taskProcessConfig?.statusFromNameTranslated === null
      ? ''
      : ', ' + data.data.taskProcessConfig?.statusFromNameTranslated;
  taskProcesConfigText +=
    data.data.taskProcessConfig?.statusToNameTranslated === null
      ? ''
      : ', ' + data.data.taskProcessConfig?.statusToNameTranslated;
  taskProcesConfigText +=
    data.data.taskProcessConfig?.documentTypeToNameTranslated === null
      ? ''
      : ', ' + data.data.taskProcessConfig?.documentTypeToNameTranslated;
  taskProcesConfigText +=
    data.data.taskProcessConfig?.id === null ? '' : ` (${data.data.taskProcessConfig?.id})`;
  return <span title={taskProcesConfigText}>{taskProcesConfigText}</span>;
};

const translateTemplate = (data, notificationTemplateList) => {
  let tmpTemplate = notificationTemplateList.find(
    (x: NotificationTemplate) => x.id === data.data.templateId,
  );
  return (
    <span title={tmpTemplate?.name + ' (' + tmpTemplate?.id + ')'}>
      {tmpTemplate?.name + ' (' + tmpTemplate?.id + ')'}
    </span>
  );
};

const ButtonCell = (cellData, handleEdit, handleRemove, translate) => {
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={handleEdit}
      />
      <DataGridButton
        hint={translate!('Delete', '')}
        icon="remove"
        cellData={cellData}
        onClick={handleRemove}
      />
    </>
  );
};
