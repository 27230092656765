import React, { useEffect } from 'react';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { UnitsForm } from '../../forms/units/UnitsForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { BaseValueDataType, UnitInputs } from '../../types/unit.types';
import { API_URL } from '../../utils/apiUrl';

export function UnitsNew() {
  const { translate } = useTranslation();
  const transFormName = 'Unit.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New unit',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Units', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const formData: UnitInputs = {
    id: 0,
    name: '',
    description: '',
    visible: true,
  };

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();
    let isOk = false;
    await postApi<UnitInputs>({
      url: API_URL.UNIT_POST,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  /**
   * Vygeneruje options pro dropdown. Generuje se z enum BaseValueDataType.
   */
  const getBaseValueDataTypeOptions = () => {
    let data: any[] = [];
    Object.keys(BaseValueDataType).forEach((value) => {
      if (typeof BaseValueDataType[value] === 'string') {
        data = [
          ...data,
          {
            name: BaseValueDataType[Number(value)],
            value: BaseValueDataType[Number(value)],
          },
        ];
      }
    });
    return data;
  };

  return (
    <PageContentWrapper heading={translate!('New unit', transFormName)}>
      <UnitsForm
        onSubmit={onSubmit}
        formData={formData}
        baseValueDataItems={getBaseValueDataTypeOptions()}
      />
    </PageContentWrapper>
  );
}

export default UnitsNew;
