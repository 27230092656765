import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { useMobileAuth } from '../../contexts/mobileAuthContext';
import { useModuleEnabled } from '../../contexts/moduleContext';
import { useTranslation } from '../../contexts/translation';
import { swrConfig } from '../../pages/Assets/AssetsEdit';
import { UserRoles } from '../../types/applicationUser.types';
import { Asset } from '../../types/asset.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { userHasAccess } from '../../utils/userHasAccess';
import { Loader } from '../loader/Loader';
import { findMenuItem, removeOptionalParams, replaceUrlParams } from './mobileMenu.functions';
import { MenuItem } from './mobileMenu.types';
import { MobileMenuAsset } from './MobileMenuAsset';
import { MobileMenuBackButton } from './MobileMenuBackButton';
import { MobileMenuItem } from './MobileMenuItem';

interface Props {
  typeId?: number;
  assetId?: number;
  taskId?: number;
  worksheetId?: number;
  tagId?: string;
}

interface SelectedMenuItem {
  id: number | null;
  parentId: number | null;
}

export function MobileMenu({ typeId, assetId, taskId, worksheetId, tagId }: Props) {
  const { user } = useMobileAuth();
  const { isNfcEnabled } = useModuleEnabled();
  const { translate } = useTranslation();
  const transFormName = 'MobileMenu';
  const history = useHistory();

  // Vybraná položka menu
  const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedMenuItem>({
    id: null,
    parentId: null,
  });
  // Položky menu, které chceme zobrazit
  const [selectedMenuItems, setMenuItems] = useState<MenuItem[] | null>(null);

  // Vždy, když otevřeme menu, načteme celý strom z API
  const { data: menuItems, isValidating: menuItemsValidating } = useSWR<MenuItem[]>(
    `${API_URL.MENU_MOBILE_STRUCTURE}${typeId !== undefined ? `?typeId=${typeId}` : ``}`,
    fetcher,
    swrConfig,
  );

  // Pokud je k dispozici assetId, chceme zobrazit Linku -> stroj
  const { data: asset } = useSWR<Asset>(
    assetId ? `${API_URL.ASSET_ITEM}?id=${assetId}` : null,
    fetcher,
    swrConfig,
  );

  /**
   * Položka v menu vybrána - zobrazíme pod menu, nebo přesměrujeme.
   * @param menuItemId identifikátor vybrané položky menu
   */
  const handleMenuItemClick = (menuItemId: number) => {
    if (!selectedMenuItems || !selectedMenuItem) return;

    // V zobrazeném menu nalznete položku, na kterou se kliknulo
    const clickedMenuItem = selectedMenuItems.find((x) => x.id === menuItemId);
    if (!clickedMenuItem) return;

    // Pokud vybraná položka nemá potomky - přesměrujeme na obrazovku
    if (clickedMenuItem.children.length === 0)
      replaceAndPush(
        clickedMenuItem.actionUrl,
        assetId || null,
        taskId || null,
        worksheetId || null,
      );

    // Pokud má vybraná položka 1 potomka, rovnou přesměrujeme
    if (clickedMenuItem.children.length === 1)
      replaceAndPush(
        clickedMenuItem.children[0].actionUrl,
        assetId || null,
        taskId || null,
        worksheetId || null,
      );

    // Položka má potomky - potomky zobrazíme a uložíme vybranou položku
    setSelectedMenuItem({ id: menuItemId, parentId: clickedMenuItem.parentId });
    setMenuItems(clickedMenuItem.children);
  };

  /**
   * Stisknuto tlačítko zpět.
   */
  const handleBackButtonClick = () => {
    if (!selectedMenuItems || !selectedMenuItem || !menuItems) return;

    // V selectedMenuItem je uložen předchozí klik (předek zobrazovaného menu)
    // Pokud parent není nastaven, znamená to, že se vracíme do rootu
    if (!selectedMenuItem.parentId) {
      setSelectedMenuItem({ id: null, parentId: null });
      setMenuItems(menuItems);
      return;
    }

    // Rekurzivně nalezneme parenta - potřebujeme jeho potomky
    const previousMenuItem = findMenuItem(selectedMenuItem.parentId, menuItems);
    if (!previousMenuItem) return;

    // Uložíme parenta a zobrazíme potomky
    setSelectedMenuItem({ id: previousMenuItem.id, parentId: previousMenuItem.parentId });
    setMenuItems(previousMenuItem.children);
  };

  const checkMenuItemVisibility = (menuItem: MenuItem) => {
    if (!user) return false;

    // Kontrola, že je povoleno NFC (pokud není ,nezobrazuejem NFC Scan a Assign tag to asset)
    if (
      (menuItem.actionUrl === '/nfcScan' || menuItem.actionUrl === '/assignTagToAsset') &&
      !isNfcEnabled
    )
      return false;

    // Kontrola práv
    if (!menuItem.actionRequiredRole) return true;
    const hasRole = userHasAccess(
      user.roles,
      menuItem.actionRequiredRole.split(',') as UserRoles[],
    );

    return hasRole;
  };

  const replaceAndPush = (
    url: string,
    assetId: number | null,
    taskId: number | null,
    worksheetId: number | null,
  ) => {
    const _url = removeOptionalParams(
      replaceUrlParams(url, assetId || null, taskId || null, worksheetId || null, tagId || null),
    );

    if (_url.includes('{'))
      console.log(`Chyba konfigurace. Proměnné v URL ${_url} nebyly nahrazeny.`);
    else history.push(`/mobile${_url}`);
  };

  /**
   * Po načtení položek z API je nastavíme jako vybrané (init).
   */
  useEffect(() => {
    if (!menuItems || menuItems.length === 0) return;

    // Pokud už při inicializaci máme pouze 1 odkaz - rovnou přesměrujeme
    if (menuItems.length === 1)
      replaceAndPush(menuItems[0].actionUrl, assetId || null, taskId || null, worksheetId || null);

    setMenuItems(menuItems);
  }, [menuItems]);

  if (!menuItems || !selectedMenuItems || menuItemsValidating) return <Loader />;

  // Tlačítko zpět zobrazuje epouze pokud se nejedná o první úroveň
  const isBackButton = selectedMenuItem && selectedMenuItem.id;

  return (
    <>
      {asset && <MobileMenuAsset parentName={asset.topLevelParenName} name={asset.name} />}
      {isBackButton && <MobileMenuBackButton onClick={handleBackButtonClick} />}
      {selectedMenuItems.map((menuItem, i) => {
        if (!checkMenuItemVisibility(menuItem)) return null;

        // Kontrola, jestli se povedl překlad, pokud ne (překlad obsahuje vykřičník), zobrazíme defaultní text
        // Domluveno na telcu 15.8.2023
        let translation = translate!(menuItem.name, transFormName);
        if (translation.includes('!')) translation = menuItem.name;

        return (
          <MobileMenuItem
            key={i}
            id={menuItem.id}
            name={translation}
            icon={menuItem.icon}
            onClick={handleMenuItemClick}
          />
        );
      })}
    </>
  );
}
