import SelectBox from 'devextreme-react/select-box';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import React from 'react';
import useSWR from 'swr';
import { FailedLoad } from '../../components/failedLoad/FailedLoad';
import { Loader } from '../../components/loader/Loader';
import { useAssetClass } from '../../contexts/assetClass';
import { useConfig } from '../../contexts/configContext';
import { useTheme } from '../../contexts/theme';
import { useTranslation } from '../../contexts/translation';
import { useVersion } from '../../contexts/versionContext';
import { clearLocalSession } from '../../hooks/useSessionStorage';
import { AppInfo } from '../../types/appInfo.types';
import { Asset } from '../../types/asset.types';
import { LangugeSelectOption } from '../../types/language.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function ProfilePage() {
  const { translate, lang, changeLang, loadTranslations } = useTranslation();
  const { assetClass, changeAssetClass, refetchAssetClass } = useAssetClass();
  const { theme, changeTheme } = useTheme();
  const { setDefaultConfigToStorage } = useConfig();
  const { currentVersion } = useVersion();
  const transFormName = 'Profile';

  // Načteme seznam dostupných jazyků
  const { data: languages, error: errorLanguages } = useSWR(`${API_URL.LANGUAGE_LIST}`, fetcher);
  // Načteme číselník - AssetClass
  const {
    data: dataAssetClasses,
    error: errorAssetClasses,
    mutate: mutateAssetClasses,
  } = useSWR<Asset[]>(API_URL.ASSET_CLASS_LIST, fetcher);

  // Načteme informaci o aplikaci
  const { data: appInfo, error: errorAppInfo } = useSWR<AppInfo>(
    `${API_URL.APP_INFO}`,
    fetcher,
    swrConfig,
  );

  const themesList = [
    { value: 'light', name: 'Light' },
    { value: 'dark', name: 'Dark' },
  ];

  const getLanguagesOptions = (): LangugeSelectOption[] => {
    let langOptions: LangugeSelectOption[] = [];
    Object.keys(languages).forEach((key) => {
      langOptions = [...langOptions, { id: key as unknown as number, name: languages[key] }];
    });
    return langOptions;
  };

  const handleLangChanged = async (e) => {
    await changeLang!(e.value);
    if (refetchAssetClass) refetchAssetClass();
    mutateAssetClasses();
  };

  const handleAssetClassChanged = async (e) => {
    await changeAssetClass!(e.value);
    await loadTranslations!();
    clearLocalSession();
    setDefaultConfigToStorage!();
  };

  const handleThemeChanged = (e) => {
    changeTheme!(e.value);
  };

  if (errorLanguages || errorAssetClasses || errorAppInfo) return <FailedLoad message={null} />;
  if (!languages || !dataAssetClasses || !appInfo) return <Loader />;

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{translate!('Profile', transFormName)}</h2>
      <div className={'content-block'}>
        <div className={'dx-card'}>
          <div className="dx-fieldset">
            <div className="dx-field">
              <div className="dx-field-label">{translate!('Language', transFormName)}</div>
              <div className="dx-field-value">
                <SelectBox
                  dataSource={getLanguagesOptions()}
                  valueExpr="id"
                  displayExpr="name"
                  defaultValue={lang}
                  onValueChanged={(e) => handleLangChanged(e)}
                  placeholder={translate!('Language', transFormName)}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={translate!('Select language', transFormName)} />
                  </Validator>
                </SelectBox>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">{translate!('Asset class', transFormName)}</div>
              <div className="dx-field-value">
                <SelectBox
                  dataSource={dataAssetClasses}
                  valueExpr="id"
                  displayExpr="nameTranslated"
                  placeholder={'Asset class'}
                  searchEnabled={true}
                  defaultValue={assetClass}
                  onValueChanged={(e) => handleAssetClassChanged(e)}
                >
                  <Validator>
                    <RequiredRule message={translate!('Select language', transFormName)} />
                  </Validator>
                </SelectBox>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">{translate!('Theme', transFormName)}</div>
              <div className="dx-field-value">
                <SelectBox
                  dataSource={themesList}
                  valueExpr="value"
                  displayExpr="name"
                  searchEnabled={true}
                  defaultValue={theme}
                  onValueChanged={(e) => handleThemeChanged(e)}
                />
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">{translate!('Version', transFormName)}</div>
              <div className="dx-field-value">{currentVersion}</div>
            </div>
            {appInfo.currentHostName && (
              <div className="dx-field">
                <div className="dx-field-label">{translate!('HostName', transFormName)}</div>
                <div className="dx-field-value">{appInfo.currentHostName}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
