// @ts-ignore
import React from 'react';
import {
  DataGridButton,
  dateFormat,
  dateTimeFormat,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../components/table';
import { UserRoles } from '../../types/applicationUser.types';

export function TypeNameCell(cellData) {
  return cellData.column.dataField === 'typeNameTranslated' ? (
    <span className="type-name-translated-container">{cellData.data.typeNameTranslated}</span>
  ) : (
    cellData.data[cellData.column.dataField]
  );
}

export function TypeNameCellFromRowData(rowData, index) {
  return rowData.columns[index].dataField === 'typeNameTranslated' ? (
    <span className="type-name-translated-container">{rowData.data.typeNameTranslated}</span>
  ) : (
    rowData.data[rowData.columns[index].dataField]
  );
}

const convertMinsToHrsMins = (mins) => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const hString = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  const mString = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${hString}:${mString}`;
};

const convertCellDataToMinutes = (value) => {
  if (!value || value === '00:00:00') return 0;
  const daysSplit = value.replace('-', '').split('.');
  let minutes = 0;
  let index = 0;

  // Pokud neobsahuje znak ":", jedná se dny, které je nutné převést na minuty
  if (!daysSplit[0].includes(':')) {
    minutes = Number(daysSplit[index]) * 24 * 60;
    index++;
  }

  const hoursMinutes = daysSplit[index].split(':');
  minutes += Number(hoursMinutes[0]) * 60; // hodiny na minuty
  minutes += Number(hoursMinutes[1]); // minuty jen přičteme

  return minutes;
};

export function TimeSpanCell(cellData) {
  const minutes = convertCellDataToMinutes(cellData.data[cellData.column.dataField]);
  if (minutes === 0) return '';
  return `${convertMinsToHrsMins(minutes)}`;
}

export function TimeSpanCellSortValue(rowData, attributeName) {
  return convertCellDataToMinutes(rowData[attributeName]);
}

export const Columns = (
  handleEdit,
  handleCreateWorksheet,
  handleCopy,
  roles,
  translate,
): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'customId',
    width: '100px',
    caption: 'Task id',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'typeNameTranslated',
    width: '250px',
    caption: 'Type',
    lookupPickBy: 'typeNameTranslated',
    cssClass: 'dg-align-center',
    cellRender: TypeNameCell,
  },
  {
    dataField: 'capacityCategoryName',
    width: '100px',
    caption: 'Capacity category',
    lookupPickBy: 'capacityCategoryName',
  },
  {
    dataField: 'statusNameTranslated',
    width: '100px',
    caption: 'Status',
    lookupPickBy: 'statusNameTranslated',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'assetTopLevelParenName',
    caption: 'Line',
    width: '100px',
  },
  {
    dataField: 'assetName',
    width: '100px',
    caption: 'Asset',
    lookupPickBy: 'assetName',
  },
  {
    dataField: 'taskName',
    width: '100px',
    caption: 'Task name',
    cssClass: 'dg-bold',
  },
  {
    dataField: 'urgeLevel',
    caption: 'Urge',
    width: '100px',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'responsiblePersonName',
    width: '100px',
    caption: 'Responsible person',
    lookupPickBy: 'responsiblePersonName',
  },
  {
    dataField: 'requiredDate',
    width: '100px',
    caption: 'Required date',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateFormat(),
  },
  {
    dataField: 'created',
    width: '10',
    caption: 'Created',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateFormat(),
  },
  {
    dataField: 'createdByName',
    width: '70px',
    caption: 'Created by',
    lookupPickBy: 'createdByName',
  },
  {
    dataField: 'downtimeFrom',
    width: '100px',
    caption: 'Downtime from',
    dataType: IDataType.dateTime,
    format: dateTimeFormat(),
  },
  {
    dataField: 'downtimeTo',
    width: '100px',
    caption: 'Downtime to',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'worksheetsCount',
    width: '350px',
    caption: 'Worksheet count',
    cssClass: 'dg-align-center',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) =>
      ButtonCell(data, handleEdit, handleCreateWorksheet, handleCopy, roles, translate),
    width: '100px',
  },
];

const ButtonCell = (cellData, handleEdit, handleCreateWorksheet, handleCopy, roles, translate) => {
  const isWorksheetRole = roles.some(
    (role) => role === UserRoles.WorksheetAll || role === UserRoles.WorksheetOwn,
  );
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={handleEdit}
      />
      <DataGridButton
        hint={translate!('Copy', '')}
        icon="copy"
        marginRight={isWorksheetRole}
        cellData={cellData}
        onClick={handleCopy}
      />
      {isWorksheetRole && (
        <DataGridButton
          hint={translate!('Create WorkSheet', 'WorkSheet.List')}
          icon="clock"
          cellData={cellData}
          onClick={handleCreateWorksheet}
        />
      )}
    </>
  );
};
