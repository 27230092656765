import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AssetTasksDataGrid } from '../../../components/datagrid/AssetTasksDataGrid';
import { CONFIG_KEYS, useConfigByKey } from '../../../contexts/configContext';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { DetailPageParams } from '../../../types/pageParams.types';
import { Task } from '../../../types/task.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { TasksFilterData } from '../../Tasks/Tasks';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
}

// Defaultní nastavení filtru mimo DataGrid
// TODO: Bylo by vhodné přenést do types
const defaultFilterData: TasksFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
};

export const AssetTabTasks = ({ active }: Props) => {
  const { id } = useParams<DetailPageParams>();
  const history = useHistory();
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const config = useConfigByKey(CONFIG_KEYS.ASSET_TASK_LIST_DATAGRID_DESKTOP);
  // Hook, který umožňuje manipulovat s nastavením DataGridu. Defaultně mu předáváme nastavení z localStorage.
  const { loadFilter, resetFilter, saveFilter, getActualJsonState } = useSaveDataGridFilter(
    STORAGE_KEYS.ASSET_TASKS_DATAGRID_DESKTOP,
    dataGridRef,
  );
  // Nastavení filtru (mimo DataGrid) - ukládáme/načítáme do/z localStorage
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.TASKS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const loadConfig = (config: any) => {
    if (config) {
      loadFilter(JSON.parse(config.value));
      saveFilter();
    }
  };

  useEffect(() => {
    if (config && resetFilterAction) {
      resetFilter();
      loadConfig(config);
      saveFilter();
      setResetFilterAction(false);
      setFilterData({ ...defaultFilterData });
    }

    // @ts-ignore
    if (!dataGridRef.current || !dataGridRef.current.instance) return;
    if (config && !getActualJsonState(false)) loadConfig(config);
  }, [config, resetFilterAction]);

  let tasksApi = `${API_URL.TASK_BY_ASSET}?assetId=${id}`;
  if (filterData.dateFrom)
    tasksApi = `${API_URL.TASK_BY_ASSET}?assetId=${id}&filterCreatedFrom=${filterData.dateFrom}T00:00:00`;
  if (filterData.dateTo)
    tasksApi = `${API_URL.TASK_BY_ASSET}?assetId=${id}&filterCreatedTo=${filterData.dateTo}T23:59:59`;
  if (filterData.dateFrom && filterData.dateTo)
    tasksApi = `${API_URL.TASK_BY_ASSET}?assetId=${id}&filterCreatedFrom=${filterData.dateFrom}T00:00:00&filterCreatedTo=${filterData.dateTo}T23:59:59`;

  const {
    data: tasks,
    error: errorTasks,
    mutate: mutateTasks,
    isValidating,
  } = useSWR<Task[]>(tasksApi, fetcher, assetEditSwrConfig);

  const handleEditButton = (e): void => {
    history.push(`/tasks/edit/${e.row.data.id}`);
  };

  useEffect(() => {
    if (active) mutateTasks();
  }, [active]);

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateTasks();
  };

  return (
    <PageContentWrapper
      loading={isValidating || loadingPermission}
      error={errorTasks}
      noContentBlock
      noDxCard
    >
      <AssetTasksDataGrid
        data={tasks}
        dataGridRef={dataGridRef}
        handleEdit={handleEditButton}
        onFilterChanged={handleFilterChanged}
        resetFilter={() => setResetFilterAction(true)}
        userCanCreate={userHasPermissionToCreate}
        defaultFilterData={filterData}
        isDateFilter
        storageKey={STORAGE_KEYS.ASSET_TASKS_DATAGRID_DESKTOP}
      />
    </PageContentWrapper>
  );
};
