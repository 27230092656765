import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabCycleColumns';
import { DocumentType } from '../../types/documentType.types';
import AssetCycleModal from '../modals/AssetCycleModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export interface IAssetTabCycleDataGrid extends IDataGrid {
  onSelectionChanged?: (e: any) => void;
  isReadOnly?: boolean;
  capacityCategories: any;
  documentTypes?: DocumentType[];
  handleNew: () => void;
  handleEdit: (e: any) => void;
  handleClose: () => void;
  modalVisibility: boolean;
  selectedData: any;
  selectedAction: any;
  onDataSubmit: (data: any, actionType: any) => void;
}

export const AssetTabCycleDataGrid = ({
  data,
  onSelectionChanged,
  isReadOnly,
  capacityCategories,
  documentTypes,
  handleNew,
  handleEdit,
  handleClose,
  modalVisibility,
  selectedData,
  selectedAction,
  onDataSubmit,
}: IAssetTabCycleDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.Cycle';

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset cycles', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        selection={{ mode: 'single' }}
        transFormName={transFormName}
        columns={Columns(isReadOnly, handleEdit, translate)}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        columnChooser={false}
        allowColumnResizing={false}
        allowColumnReordering={false}
        isReadOnly={isReadOnly ?? false}
        prompt={Prompt}
      />
      {modalVisibility && (
        <AssetCycleModal
          isVisible={modalVisibility}
          data={selectedData ?? null}
          actionType={selectedAction}
          capacityCategories={capacityCategories}
          documentTypes={documentTypes}
          transFormName={transFormName}
          onSubmit={onDataSubmit}
          onClose={handleClose}
        />
      )}
    </>
  );
};
