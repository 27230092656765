import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategoriesPersonsForm } from '../../forms/capacityCategoriesPersonsForm/CapacityCategoriesPersonsForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory, CapacityCategoryPersonInput } from '../../types/capacityCategory.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { Person } from '../../types/person.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function CapacityCategoriesPersonsEdit() {
  const { translate } = useTranslation();
  const transFormName = 'CapacityCategoryPerson.Update';
  const { startLoading, stopLoading } = useLoading();
  const { id } = useParams<DetailPageParams>();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit capacity categories persons',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Capacity categories persons', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<CapacityCategoryPersonInput>(
    `${API_URL.CAPACITY_CATEGORY_PERSON_ITEM}?id=${id}`,
    fetcher,
  );
  // Načteme číselník - capacity category
  const { data: dataCapacityCategories, error: errorCapacityCategories } = useSWR<
    CapacityCategory[]
  >(API_URL.CAPACITY_CATEGORY_LIST, fetcher);
  // Načteme číselník - person
  const { data: dataPerson, error: errorPerson } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.CAPACITY_CATEGORY_PERSON_PUT,
      data: formData as CapacityCategoryPersonInput,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      heading={translate!('Edit capacity category person', transFormName)}
      error={error || errorPerson || errorCapacityCategories}
      loading={!formData || !dataPerson || !dataCapacityCategories}
    >
      <CapacityCategoriesPersonsForm
        onSubmit={onSubmit}
        formData={formData}
        dataCapacityCategories={dataCapacityCategories}
        dataPerson={dataPerson}
        isEdit
      />
    </PageContentWrapper>
  );
}
export default CapacityCategoriesPersonsEdit;
