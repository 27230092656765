/**
 * Rekurzivně projde strom odkazů menu a vrátí konkrétní položku a jeho potomky.
 * @param id - hledaný záznam
 * @param dataArray - strom, ve kterém se hledá
 * @returns
 */
export function findMenuItem(id, dataArray) {
  for (const obj of dataArray) {
    // Pokud jsme našli objekt s požadovaným ID, vrátíme ho
    if (obj.id === id) return obj;
    // Nejsou potomoci
    if (obj.children.length === 0) continue;
    // Potomci existují, zkusíme v nich nalézt položku
    const foundInChildren = findMenuItem(id, obj.children);
    if (foundInChildren) return foundInChildren; // Pokud jsme našli objekt v potomcích, vrátíme ho
  }

  return null; // Pokud jsme prošli celé pole a nenašli objekt
}

export const replaceUrlParams = (
  url: string,
  assetId: number | null,
  taskId: number | null,
  worksheetId: number | null,
  tagId: string | null,
): string => {
  let _url: string = url;
  if (assetId)
    _url = _url.replace('{assetId}', assetId.toString()).replace('{assetId?}', assetId.toString());
  if (taskId)
    _url = _url.replace('{taskId}', taskId.toString()).replace('{taskId?}', taskId.toString());
  if (worksheetId)
    _url = _url
      .replace('{worksheetId}', worksheetId.toString())
      .replace('{worksheetId?}', worksheetId.toString());
  if (tagId) _url = _url.replace('{tagId}', tagId).replace('{tagId?}', tagId);

  return _url;
};

export const removeOptionalParams = (url: string) => {
  return url
    .replace('{assetId?}', '')
    .replace('{taskId?}', '')
    .replace('{worksheetId?}', '')
    .replace('{tagId?}', '');
};
