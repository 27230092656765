import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { UnitsForm } from '../../forms/units/UnitsForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DetailPageParams } from '../../types/pageParams.types';
import { BaseValueDataType, UnitInputs } from '../../types/unit.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function UnitsEdit() {
  const { translate } = useTranslation();
  const transFormName = 'Unit.Update';
  const { id } = useParams<DetailPageParams>();
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit unit',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Units', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<UnitInputs>(`${API_URL.UNIT_ITEM}?id=${id}`, fetcher);

  const onSubmit = async (e) => {
    startLoading();
    if (e !== null) e.preventDefault();
    let isOk = false;
    await putApi({
      url: API_URL.UNIT_PUT,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  /**
   * Vygeneruje options pro dropdown. Generuje se z enum BaseValueDataType.
   * TODO: Duplicita
   */
  const getBaseValueDataTypeOptions = () => {
    let data: any[] = [];
    Object.keys(BaseValueDataType).forEach((value) => {
      if (typeof BaseValueDataType[value] === 'string') {
        data = [
          ...data,
          {
            name: BaseValueDataType[Number(value)],
            value: BaseValueDataType[Number(value)],
          },
        ];
      }
    });
    return data;
  };

  return (
    <PageContentWrapper
      error={error}
      loading={!formData}
      heading={translate!('Edit unit', transFormName)}
    >
      <UnitsForm
        onSubmit={onSubmit}
        formData={formData}
        baseValueDataItems={getBaseValueDataTypeOptions()}
        isEdit
      />
    </PageContentWrapper>
  );
}

export default UnitsEdit;
