import { ContextMenu } from 'devextreme-react';
import moment from 'moment';
import { isFunction } from 'ramda-extension';
import React, { CSSProperties, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { timespan } from '../../utils/customRenderer';
import { getWidthByColumns } from '../../utils/datagridNoResponsive';
import {
  displayDateFormat_CZ,
  displayDateFormat_EN,
  displayDateTimeFormat_CZ,
  displayDateTimeFormat_EN,
} from '../../utils/dates.types';
import { evaluateStringCondition } from '../../utils/generalReportHighlightFunctions';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { DatagridNoResponsive } from '../datagridNoResponsive/DatagridNoResponsive';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
  transFormName: string;
  newEntryActionPath: string;
  exportName?: string;
  dataAndStructure: any;
  selectedFilterDatagrid: any;
  datagridNoResponsiveClassName?: string;
}

export const GeneralReportDataGrid = ({
  dataAndStructure,
  handleEdit,
  resetFilter,
  dataGridRef,
  userCanCreate,
  filterVisibility,
  setFilterVisibility,
  transFormName,
  newEntryActionPath,
  exportName,
  selectedFilterDatagrid,
  datagridNoResponsiveClassName,
}: Props) => {
  const { translate, lang } = useTranslation();
  const transFormReportBar = 'Reports.Bar';
  const history = useHistory();
  const { pathname } = useLocation();
  // Pro nastavení path pro context menu
  const [selectedPath, setSelectedPath] = useLocalStorage(STORAGE_KEYS.CONTEXT_STATE, null);
  const dateFormat = lang === 'en-US' ? displayDateFormat_EN : displayDateFormat_CZ;
  const dateTimeFormat = lang === 'en-US' ? displayDateTimeFormat_EN : displayDateTimeFormat_CZ;

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };

  const dataGridHeight = Math.ceil(window.outerHeight * 0.7);

  // Uloží cestu pro context menu do storage
  const handleSetPath = (o: any) => setSelectedPath(o);

  // Doplní proměnné v textu
  const transformParams = (text, cellData) => {
    const params = [...text.matchAll('{(.*?)}')];
    params?.forEach((param) => (text = text.replace(`${param[0]}`, cellData.data[param[1]])));
    return text;
  };

  const processLinkAttribute = (item) => {
    return {
      ...item,
      cellRender: (cellData) => {
        let location: string = transformParams(item.link.location, cellData);
        let title: string = transformParams(item.link.title, cellData);
        return (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            className="context-link"
            // onClick nenaslouchá na pravé tlačítko myši
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
              // stisknuto levé tlačítko
              if (e.nativeEvent.button === 0)
                return history.push({
                  pathname: location,
                  state: { backRoute: pathname, defaultTab: item.link.defaultTab },
                });
              // nastavit cestu pro context menu
              handleSetPath({ path: location, backRoute: pathname, tab: item.link?.defaultTab });
            }}
            title={title}
          >
            {cellData.data[cellData.column.dataField]}
          </span>
        );
      },
    };
  };

  const processHighlightConditionsAttribute = (item) => {
    return {
      ...item,
      cellRender: (cellData) => {
        let toReturn: any;
        if (cellData.column.dataType === 'date')
          toReturn = moment(cellData.data[cellData.column.dataField]).format(dateFormat);
        else if (cellData.column.dataType === 'datetime')
          toReturn = moment(cellData.data[cellData.column.dataField]).format(dateTimeFormat);
        else if (item.customRender === 'Timespan')
          toReturn = timespan(cellData.data[cellData.column.dataField]);
        else toReturn = cellData.data[cellData.column.dataField];

        // Může existovat n podmínek, vyhodnocují se postupně, pokud jedna platí, cyklus končí
        item.highlightConditions.every((cond) => {
          if (evaluateStringCondition(transformParams(cond.condition, cellData), cond.dataType)) {
            let style: CSSProperties = {};
            if (cond.color) style = { ...style, color: cond.color };
            if (cond.backgroundColor)
              style = { ...style, padding: '2px 5px', backgroundColor: cond.backgroundColor };
            if (cond.textDecoration) style = { ...style, textDecoration: cond.textDecoration };
            if (cond.fontWeight) style = { ...style, fontWeight: cond.fontWeight };

            toReturn = <span style={style}>{toReturn}</span>;
            return false;
          } else {
            if (cellData.column.dataType === 'date')
              toReturn = moment(cellData.data[cellData.column.dataField]).format(dateFormat);
            else if (cellData.column.dataType === 'datetime')
              toReturn = moment(cellData.data[cellData.column.dataField]).format(dateTimeFormat);
            else if (item.customRender === 'Timespan')
              toReturn = timespan(cellData.data[cellData.column.dataField]);
            else toReturn = cellData.data[cellData.column.dataField];
            return true;
          }
        });
        return toReturn;
      },
    };
  };

  const prepareColumns = () => {
    let columns = dataAndStructure?.datagrid.columns ?? [];
    columns.sort((col1, col2) => col1.visibleIndex - col2.visibleIndex);
    columns = columns.filter((col) => col.visible);
    columns = columns.map((item) => {
      let _item = item;
      if (_item.link) _item = processLinkAttribute(_item);
      if (_item.highlightConditions && _item.highlightConditions.length > 0) {
        _item = processHighlightConditionsAttribute(_item);
      }
      return _item;
    });

    return columns;
  };

  const columns = prepareColumns();

  useEffect(() => {
    // @ts-ignore
    if (!dataGridRef || !dataGridRef.current || !dataGridRef.current.instance) return;
    // @ts-ignore
    dataGridRef.current.instance.state(selectedFilterDatagrid);
  }, [dataGridRef, selectedFilterDatagrid]);

  // možnosti, co se zobrazí v context menu - po kliku pravým tlačítkem
  const contextMenuItems = [
    {
      text: translate!('Open link in new tab', ''),
      icon: 'dx-icon-globe',
      onclick: () => contextMenuItemClick(),
    },
  ];

  // po kliku na možnost v context menu
  const contextMenuItemClick = () => {
    if (!selectedPath) return;
    if (selectedPath.path.includes('http')) {
      // externí link
      window.open(selectedPath.path, '_blank');
    } else {
      // interní link
      window.open('#' + selectedPath.path, '_blank');
    }
  };

  return (
    <DatagridNoResponsive
      datagridWidth={getWidthByColumns(columns, null, true)}
      className={datagridNoResponsiveClassName}
    >
      <SisDataGrid
        customDataGridStyle={{ visibility: dataAndStructure ? 'visible' : 'hidden' }}
        enableXlsxExport
        exportName={exportName ?? 'export-data'}
        dataGridRef={dataGridRef}
        data={dataAndStructure?.data ?? []}
        keyExpr={'id'}
        height={dataGridHeight}
        virtualizationEnabled
        transFormName={transFormName}
        onToolbarPreparing={onToolbarPreparing}
        columns={columns}
        pageSizeChangeVisible={true}
        enableGrouping
        datagridConfig={dataAndStructure?.datagrid}
        generalReport
      />
      {/* Context menu pro odkazy */}
      <ContextMenu
        dataSource={contextMenuItems}
        target={'.context-link'}
        onItemClick={contextMenuItemClick}
      />
    </DatagridNoResponsive>
  );
};
