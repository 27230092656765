import Box, { Item } from 'devextreme-react/box';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getItemApi } from '../../apiUtils';
import { Loader } from '../../components/loader/Loader';
import { SisDataGrid, SisDataGridColumns } from '../../components/table/SisDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { DetailPageParams } from '../../types/pageParams.types';
import { TimePeriodToClose } from '../../types/timeperiod.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { TypeNameCell } from '../Tasks/Columns';
import { paintCellByTaskColor } from '../Tasks/utils/paintCellByColor';
import { paintRowByTaskColor } from '../Tasks/utils/paintRowByColor';

export function TimePeriodsClose() {
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'TimePeriods.Close';
  const { id } = useParams<DetailPageParams>();
  const { setValue } = useBreadcrumbs();
  const { startLoading, loading, stopLoading } = useLoading();

  useEffect(() => {
    setValue({
      pageName: 'Close time period',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Time periods', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data, error, isValidating } = useSWR<TimePeriodToClose>(
    `${API_URL.TIME_PERIOD_TRY_CLOSE}?id=${id}`,
    fetcher,
  );

  const TasksColumns = (): SisDataGridColumns => [
    {
      dataField: 'customId',
      width: '13%',
      caption: 'Custom id',
      cellRender: (cellData) => {
        return (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => history.push(`/tasks/edit/${cellData.data.id}`)}
          >
            {cellData.data[cellData.column.dataField]}
          </span>
        );
      },
    },
    {
      dataField: 'typeNameTranslated',
      width: '15%',
      caption: 'Type',
      lookupPickBy: 'typeNameTranslated',
      cssClass: 'dg-align-center',
      cellRender: TypeNameCell,
    },
    {
      dataField: 'capacityCategoryName',
      width: '15%',
      caption: 'Capacity category',
      lookupPickBy: 'capacityCategoryName',
    },
    {
      dataField: 'statusNameTranslated',
      width: '15%',
      caption: 'Status',
      lookupPickBy: 'statusNameTranslated',
      cssClass: 'dg-align-center',
    },
    {
      dataField: 'assetTopLevelParenName',
      caption: 'Line',
      width: '13%',
    },
    {
      dataField: 'assetName',
      width: '17%',
      caption: 'Asset',
      lookupPickBy: 'assetName',
    },
    {
      dataField: 'taskName',
      width: '22%',
      caption: 'Task name',
    },
  ];

  const handleTimePeriodClose = async () => {
    startLoading();
    await getItemApi({
      url: `${API_URL.TIME_PERIOD_CLOSE}?id=${id}`,
      params: {},
      callAfterSuccess: async () => {
        history.push(`/timeperiods`);
      },
      callAfterError: (result) => {
        notify(`This action is not allowed now`, 'error');
      },
      hideNotifications: true,
    });
    stopLoading();
  };

  if (error)
    return (
      <>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              {translate!(`Time period can not be closed`, transFormName)}
            </div>
            <div>
              <Button
                text={translate!(`Back`, '')}
                type="normal"
                stylingMode="contained"
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </div>
      </>
    );

  if (!data || isValidating) return <Loader />;

  return (
    <>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{ marginBottom: '5px' }}>
          <div style={{ paddingTop: '5px' }}>
            <Box direction="row" width="100%">
              <Item ratio={0.9}>
                <div style={{ fontSize: '22px' }}>{`${data!.periodToClose.name}, ${
                  data!.periodToClose.statusNameTranslated
                    ? data!.periodToClose.statusNameTranslated
                    : data!.periodToClose.statusName
                }`}</div>
              </Item>
              <Item ratio={0.1}>
                {data!.canBeClosed && (
                  <Button
                    text={translate!(`Close`, transFormName)}
                    type="success"
                    disabled={loading}
                    stylingMode="contained"
                    onClick={handleTimePeriodClose}
                  />
                )}
              </Item>
            </Box>
          </div>
        </div>
        <Box direction="row" width="100%">
          <Item ratio={0.5}>
            <div className={'dx-card responsive-paddings'}>
              <div style={{ fontSize: '18px', marginBottom: '5px' }}>
                {translate!(`Tasks without conflict`, transFormName)}
              </div>
              {data!.listOk.length > 0 ? (
                <SisDataGrid
                  data={data!.listOk}
                  keyExpr={'id'}
                  transFormName={'TimePeriods.Close'}
                  columns={TasksColumns()}
                  columnChooser={false}
                  allowColumnResizing={false}
                  allowColumnReordering={false}
                  onRowPrepared={paintRowByTaskColor}
                  onCellPrepared={paintCellByTaskColor}
                />
              ) : (
                <>{translate!(`There are not tasks without conflict`, transFormName)}</>
              )}
            </div>
          </Item>
          <Item ratio={0.5}>
            <div className={'dx-card responsive-paddings'}>
              <div style={{ fontSize: '18px', marginBottom: '5px' }}>
                {translate!(`Tasks with conflict`, transFormName)}
              </div>
              {data!.listWithConflict.length > 0 ? (
                <SisDataGrid
                  data={data!.listWithConflict}
                  keyExpr={'id'}
                  transFormName={'TimePeriods.Close'}
                  columns={TasksColumns()}
                  columnChooser={false}
                  allowColumnResizing={false}
                  allowColumnReordering={false}
                  onRowPrepared={paintRowByTaskColor}
                  onCellPrepared={paintCellByTaskColor}
                />
              ) : (
                <>{translate!(`There are not tasks with conflict`, transFormName)}</>
              )}
            </div>
          </Item>
        </Box>
      </div>
    </>
  );
}
