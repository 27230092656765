import React, { useEffect } from 'react';
import useSWR from 'swr';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { ITab, swrConfig } from '../../Assets/AssetsEdit';
import { TaskHistory, TaskHistoryVariant } from '../TaskHistory';

interface Props extends ITab {
  id: string;
}
export const TaskHistoryTab = ({ id, active }: Props) => {
  const {
    data: taskHistoryList,
    error: errorTaskHistoryList,
    mutate,
    isValidating,
  } = useSWR(`${API_URL.TASK_HISTORY_LIST}?parentId=${id}`, fetcher, swrConfig);

  useEffect(() => {
    if (active) mutate();
  }, [id, active]);

  return (
    <PageContentWrapper loading={isValidating} error={errorTaskHistoryList} noContentBlock noDxCard>
      <TaskHistory initialData={taskHistoryList} taskId={id} variant={TaskHistoryVariant.SIMPLE} />
    </PageContentWrapper>
  );
};
