import { Button, SelectBox } from 'devextreme-react';
import React, { useState } from 'react';
import useSWR from 'swr';
import { putApi } from '../../../apiUtils';
import { useTranslation } from '../../../contexts/translation';
import { swrConfig } from '../../../pages/Assets/AssetsEdit';
import { fetcher } from '../../../utils/apiUrl';
import './bulkFields.scss';

type Props = {
  title: string;
  paramName: string;
  displayExpr: string;
  valueExpr: string;
  dataUrl: string;
  submitUrl: string;
  ids: Array<number>;
  handleRowsStatus: (data: any) => void;
};

export default function AssetsBulkEditSelectField({
  title,
  paramName,
  displayExpr,
  valueExpr,
  dataUrl,
  submitUrl,
  ids,
  handleRowsStatus,
}: Props) {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const { translate } = useTranslation();
  const transFormName = 'Bulk.Change';

  /**
   * Načte list položek pro select box.
   */
  const { data: items } = useSWR<any[]>(dataUrl, fetcher, swrConfig);

  const onSubmit = async () => {
    const data = {
      assetIdArr: ids,
    };
    data[paramName] = selectedItem;
    await putApi({
      url: submitUrl,
      data,
      callAfterSuccess: (response) => {
        handleRowsStatus(response);
      },
    });
  };

  return (
    <div className="bulk-edit-field-container">
      <div className="bulk-edit-field-label">{title}</div>
      <SelectBox
        className="bulk-edit-field-value"
        value={selectedItem}
        items={items}
        displayExpr={displayExpr}
        valueExpr={valueExpr}
        onValueChange={setSelectedItem}
        showClearButton
      />
      <Button
        className="bulk-edit-field-button"
        text={translate!('Save changes', transFormName)}
        disabled={selectedItem === null}
        onClick={() => selectedItem !== null && onSubmit()}
        stylingMode="contained"
        type="default"
      />
    </div>
  );
}
