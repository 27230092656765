import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategoryForm } from '../../forms/capacityCategoriesForm/CapacityCategoryForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetClass } from '../../types/assetClass.types';
import { CapacityCategoryInput } from '../../types/capacityCategory.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function CapacityCategoriesNew() {
  const { translate } = useTranslation();
  const transFormName = 'CapacityCategory.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New capacity categories',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Capacity categories', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Načteme číselník - asset class
  const { data: dataAssetClasses, error: errorAssetClasses } = useSWR<AssetClass[]>(
    API_URL.ASSET_CLASS_LIST,
    fetcher,
  );

  const formData: CapacityCategoryInput = {
    id: 0,
    name: '',
    description: '',
    visible: true,
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await postApi<CapacityCategoryInput>({
      url: API_URL.CAPACITY_CATEGORY_POST,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!dataAssetClasses}
      error={errorAssetClasses}
      heading={translate!('New capacity category', transFormName)}
    >
      <CapacityCategoryForm
        onSubmit={onSubmit}
        dataAssetClasses={dataAssetClasses}
        formData={formData}
      />
    </PageContentWrapper>
  );
}
export default CapacityCategoriesNew;
