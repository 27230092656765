import { IDataType } from '../../../components/table';

export const Columns = () => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset id',
    width: '5%',
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '12%',
  },
  {
    dataField: 'parentAssetName',
    caption: 'Parent asset',
    width: '10%',
  },
  {
    dataField: 'typeName',
    caption: 'Type',
    width: '10%',
    lookupPickBy: 'typeName',
  },
  {
    dataField: 'statusNameTranslated',
    caption: 'Status',
    width: '7%',
    lookupPickBy: 'statusNameTranslated',
  },
  {
    dataField: 'assetLocationName',
    caption: 'Locality',
    width: '10%',
  },
  {
    dataField: 'ownerName',
    caption: 'Owner name',
    width: '11%',
  },
  {
    dataField: 'centre',
    caption: 'Centre',
    width: '10%',
  },
  {
    dataField: 'building',
    caption: 'Building',
    width: '10%',
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '11%',
  },
  {
    dataField: 'model',
    caption: 'Model',
    width: '10%',
  },
];
