import { Button as GridButton } from 'devextreme-react/data-grid';
import React from 'react';
import { IColumnType, IDataType } from '../../../components/table';

export const Columns = (handleEdit, translate) => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset id',
    width: '50',
  },
  {
    dataType: IDataType.string,
    dataField: 'assetLocationName',
    caption: 'Asset location',
    width: '70',
    lookupPickBy: 'assetLocationName',
  },
  {
    dataType: IDataType.string,
    dataField: 'statusName',
    caption: 'Status',
    width: '70',
    lookupPickBy: 'statusName',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    caption: 'Name',
    width: '120',
  },
  {
    dataType: IDataType.string,
    dataField: 'parentAssetName',
    caption: 'Parent asset',
    width: '120',
  },
  {
    dataType: IDataType.string,
    dataField: 'levelName',
    caption: 'Asset level',
    width: '120',
    lookupPickBy: 'levelName',
  },
  {
    dataType: IDataType.string,
    dataField: 'description',
    caption: 'Description',
    width: '110',
  },
  {
    dataType: IDataType.string,
    dataField: 'model',
    caption: 'Model',
    width: '100',
  },
  {
    dataType: IDataType.string,
    dataField: 'typeName',
    caption: 'Type',
    width: '100',
    lookupPickBy: 'typeName',
  },
  {
    dataType: IDataType.date,
    dataField: 'producedWhen',
    caption: 'Produced when',
    width: '100',
    lookupPickBy: 'producedWhen',
  },
  {
    dataType: IDataType.string,
    dataField: 'producedBySupplierName',
    caption: 'Produced by supplier name',
    width: '100',
    lookupPickBy: 'producedBySupplierName',
  },
  {
    dataType: IDataType.date,
    dataField: 'deliveredWhen',
    caption: 'Delivered when',
    width: '100',
    lookupPickBy: 'deliveredWhen',
  },
  {
    dataType: IDataType.string,
    dataField: 'deliveredBySupplierName',
    caption: 'Delivered by supplier name',
    width: '100',
    lookupPickBy: 'deliveredBySupplierName',
  },
  {
    dataType: IDataType.date,
    dataField: 'activatedWhen',
    caption: 'Activated when',
    width: '100',
    lookupPickBy: 'activatedWhen',
  },
  {
    dataType: IDataType.string,
    dataField: 'ownerName',
    caption: 'Owner name',
    width: '100',
    lookupPickBy: 'ownerName',
  },
  {
    dataType: IDataType.date,
    dataField: 'warrantyUntil',
    caption: 'Warranty until',
    width: '100',
    lookupPickBy: 'warrantyUntil',
  },
  {
    dataType: IDataType.string,
    dataField: 'locality',
    caption: 'Locality',
    width: '100',
    lookupPickBy: 'locality',
  },
  {
    dataType: IDataType.string,
    dataField: 'centre',
    caption: 'Centre',
    width: '100',
    lookupPickBy: 'centre',
  },
  {
    dataType: IDataType.string,
    dataField: 'building',
    caption: 'Building',
    width: '100',
    lookupPickBy: 'building',
  },
  {
    dataType: IDataType.string,
    dataField: 'room',
    caption: 'Room',
    width: '100',
    lookupPickBy: 'room',
  },
  {
    dataType: IDataType.string,
    dataField: 'typeClassName',
    caption: 'Class',
    width: '100',
  },
  {
    dataType: IDataType.string,
    dataField: 'inventoryNumber',
    caption: 'Inventory number',
    width: '100',
  },
  {
    dataType: IDataType.string,
    dataField: 'serialNumber',
    caption: 'Serial number',
    width: '100',
  },
  {
    dataType: IDataType.string,
    dataField: 'partNumber',
    caption: 'Part number',
    width: '100',
  },
  {
    dataType: IDataType.string,
    dataField: 'responsiblePersonName',
    width: '80',
    caption: 'Responsible person',
    lookupPickBy: 'responsiblePersonName',
  },
  {
    dataType: IDataType.string,
    dataField: 'createdByName',
    width: '80',
    caption: 'Created by',
    lookupPickBy: 'createdByName',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '70',
  },
  {
    type: IColumnType.buttons,
    width: '80',
    children: () => <GridButton hint={translate!('Edit', '')} icon="edit" visible={true} onClick={handleEdit} />,
  },
];
