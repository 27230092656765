import React from 'react';
import { DataGridButton, IColumnType, SisDataGridColumns } from '../../../components/table';

export const Columns = (translate, handleEdit, handleRemove, isReadOnly): SisDataGridColumns => [
  {
    dataField: 'tagId',
    caption: 'Tag ID',
    width: '30%',
    allowEditing: false,
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '30%',
    allowEditing: false,
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, translate, handleEdit, handleRemove, isReadOnly),
    width: '15%',
    
  },
];

const ButtonCell = (cellData, translate, handleEdit, handleRemove, isReadOnly) => {
  return (
    <>
      {!isReadOnly && (
        <>
          <DataGridButton
            hint={translate!('Edit', '')}
            text={translate!('Edit', '')}
            stylingMode="text"
            cellData={cellData}
            onClick={handleEdit}
          />
          <DataGridButton
            hint={translate!('Delete', '')}
            text={translate!('Delete', '')}
            stylingMode="text"
            cellData={cellData}
            onClick={handleRemove}
          />
        </>
      )}
    </>
  );
};
