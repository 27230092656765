import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getItemApi } from '../../../apiUtils';
import { TasksReportDataGrid } from '../../../components/datagrid/TasksReportDataGrid';
import { DatagridNoResponsiveContainer } from '../../../components/datagridNoResponsive/DatagridNoResponsiveContainer';
import { Loader } from '../../../components/loader/Loader';
import { useBreadcrumbs } from '../../../contexts/breadcrumbsContext';
import { useTranslation } from '../../../contexts/translation';
import TaskReportFilter from '../../../forms/filter/taskReport/TaskReportFilter';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ReportsState } from '../../../types/locationState.types';
import { Task } from '../../../types/task.types';
import { API_URL } from '../../../utils/apiUrl';
import { scrollDatagridNoResponsive } from '../../../utils/datagridNoResponsive';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { isNilOrEmpty } from '../../../utils/ramdaUtils';
import { ReportType } from '../../../utils/reportTypeKeys';
import { tabsIndexes } from '../../Tasks/TasksEdit';
import '../Reports.scss';

export function TaskReport() {
  const [data, setData] = useState<Array<Task> | null>([]);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const { state }: { state: ReportsState | undefined } = useLocation();
  const settingId = pathname.split('/')[3];
  const transFormName = 'Task.List';
  const { setValue } = useBreadcrumbs();
  const [refetchData, setRefetchData] = useState<boolean>(false);

  useEffect(() => {
    setValue({ pageName: 'Tasks', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const reportType = ReportType.Tasks;

  const history = useHistory();
  // Načtení práv uživatele
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const {
    getActualJsonState,
    loadFilter,
    resetFilter,
    saveFilter,
    data: datagridJsonData,
  } = useSaveDataGridFilter(reportType.key, dataGridRef);

  const [datagridSetting] = useSessionStorage(STORAGE_KEYS.TASKS_REPORT_DATAGRID_DESKTOP, {});

  // Trigger scroll
  useEffect(() => {
    scrollDatagridNoResponsive();
  }, []);

  useEffect(() => {
    if (state?.loadSettingFromSession) {
      loadFilter(datagridSetting);
    }
  }, [state]);

  useEffect(() => {
    if (resetFilterAction) {
      resetFilter();
      setResetFilterAction(false);
    }
    saveFilter();

    if (!dataGridRef.current || !dataGridRef.current.instance) return;
  }, [resetFilterAction]);

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        pathname: `/tasks/edit/${e.row.data.id}`,
        state: { backRoute: pathname },
      });
    }
  };

  /**
   * Handle tlačítka pro vytvoření nového výkazu v DataGridu
   * @param e  Event
   */
  const handleCreateWorksheetButton = (e): void => {
    if (isNilOrEmpty(e.row) || isNilOrEmpty(e.row.data)) return;

    // zjistíme počet výkazů - WORKSHEET_GET_COUNT_BY_TASK
    getItemApi<number>({
      url: `${API_URL.WORKSHEET_GET_COUNT_BY_TASK}?parentId=${e.row.data.id}`,
      params: {},
      callAfterSuccess: (result: number) => {
        // neexistuje výkaz, přesměrujeme na create
        if (result === 0) {
          history.push({
            pathname: '/workSheets/new/0/' + e.row.data.id,
            state: { backRoute: pathname },
          });
          return;
        }

        // jinak přesměrujeme na seznam výkazů v detailu tasku
        history.push({
          pathname: `/tasks/edit/${e.row.data.id}`,
          state: { defaultTabIndex: tabsIndexes.WORKSHEETS, backRoute: pathname },
        });
      },
      hideNotifications: true,
    });
  };

  /**
   * Handle kopírovacího tlačítka v DataGridu
   * @param e  Event
   */
  const handleCopyButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({ pathname: `/tasks/new/${e.row.data.id}`, state: { backRoute: pathname } });
    }
  };

  /**
   * Po změně akce refetchnout data
   */
  const mutateReportData = () => {
    setRefetchData(true);
  };

  if (!data) return <Loader />;
  return (
    <DatagridNoResponsiveContainer>
      <TaskReportFilter
        dataGridRef={dataGridRef}
        onDataChange={setData}
        report={reportType}
        onFilterChange={setSelectedFilter}
        filterVisibility={isFilterVisible}
        loadDefaultSetting={!state?.loadSettingFromSession}
        heading={isFilterVisible ? translate!('Tasks', transFormName) : ''}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
      />
      <TasksReportDataGrid
        datagridJsonData={datagridSetting}
        dataGridRef={dataGridRef}
        data={data}
        resetFilter={() => setResetFilterAction(true)}
        userCanCreate={userHasPermissionToCreate}
        dataGridKey={reportType.key}
        handleEdit={handleEditButton}
        handleCreateWorksheet={handleCreateWorksheetButton}
        handleCopy={handleCopyButton}
        heading={`${translate!('Tasks', transFormName)}${
          selectedFilter ? ` - ${selectedFilter}` : ''
        }`}
        mutateData={mutateReportData}
        filterVisibility={isFilterVisible}
        setFilterVisibility={setFilterVisible}
      />
    </DatagridNoResponsiveContainer>
  );
}
export default forwardRef(TaskReport);
