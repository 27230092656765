import { Chart } from 'devextreme-react';
import {
  ArgumentAxis,
  Export,
  Label,
  Legend,
  Series,
  Size,
  Subtitle,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';
import { ChartConfig } from './Chart.types';

interface Props {
  data: any;
  config: ChartConfig;
  className?: string;
}

export const ChartContainer = ({ data, config, className = 'block' }: Props) => {
  const series = config.series;
  const valuesAxis = config.valueAxis;
  const legend = config.legend;

  return (
    <Chart
      id="chart"
      className={`no-responsive-chart ${className}`}
      dataSource={data}
      rotated={config.rotated}
    >
      <Size width={config.width} height={config.height} />
      <Title text={config.title}>
        <Subtitle text={config.subtitle} />
      </Title>

      <Legend
        visible={legend.visible}
        position={legend.position}
        verticalAlignment={legend.verticalAlignment}
        horizontalAlignment={legend.horizontalAlignment}
      />
      {series.map((item, index) => {
        return (
          <Series
            key={index}
            axis={item.axis}
            valueField={item.valueField}
            argumentField={item.argumentField}
            name={item.name}
            type={item.type}
            color={item.color}
          />
        );
      })}

      <ArgumentAxis title={config.argumentAxis.title}>
        <Tick visible={true} />
        <Label wordWrap="none" overlappingBehavior={config.argumentAxis.overlappingBehavior} />
      </ArgumentAxis>

      {valuesAxis.map((item, index) => {
        return (
          <ValueAxis key={index} name={item.name} title={item.title} position={item.position}>
            <Tick visible={true} />
          </ValueAxis>
        );
      })}

      <Export enabled={config.export.enabled} printingEnabled={config.export.printingEnabled} />
      <Tooltip enabled={true} />
    </Chart>
  );
};
