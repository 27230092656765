import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { WorkActivityGroup } from '../../types/workActivityGroup.types';
import './TasksBulkEditModal.scss';

type Props = {
  data: any;
  isVisible: boolean;
  workActivityGroups: Array<WorkActivityGroup>;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Work Activity v datagridu
 */
export default function WorkActivityModal({
  isVisible,
  onClose,
  workActivityGroups,
  data,
  actionType,
  onSubmit,
}: Props) {
  const { translate } = useTranslation();
  const transFormName = 'WorkActivity.List';
  const formRef = useRef<Form>(null);

  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    setFormData({ ...data, ...(actionType === DataGridActionType.Insert && { visible: true }) });
  }, []);

  const handleCapacityCategoryChanged = (e: any) => {
    setFormData({ ...formData, workActivityGroupId: null });
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Work activity', transFormName)}
      width={'auto'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="workActivityGroupId"
              editorType="dxSelectBox"
              editorOptions={{
                items: workActivityGroups.filter((group) => {
                  return formData.capacityCategoryId
                    ? group.capacityCategoryId === formData.capacityCategoryId
                    : group;
                }),
                valueExpr: 'id',
                displayExpr: 'name',
                width: 300,
              }}
            >
              <Label text={translate!('Work activity group', transFormName)} />
              <RequiredRule
                message={translate!('Work activity group is required', transFormName)}
              />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="name" editorType="dxTextBox">
              <Label text={translate!('Name', transFormName)} />
              <RequiredRule message={translate!('Name is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="visible" editorType="dxCheckBox">
              <Label text={translate!('Visible', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Close', '')}
                onClick={onClose}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
