import React, { createContext, useContext, useEffect, useState } from 'react';

interface NavigationContextType {
  navigationData: NavigationItem;
  setNavigationData: (NavigationItem) => void;
}

interface NavigationItem {
  currentPath: string;
}

const NavigationContext = createContext<NavigationContextType>({
  navigationData: { currentPath: '' },
  setNavigationData: () => {},
});
const useNavigation = () => useContext<NavigationContextType>(NavigationContext);

function NavigationProvider(props) {
  const [navigationData, setNavigationData] = useState({});

  return <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />;
}

function withNavigationWatcher(Component) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    // eslint-disable-next-line react/prop-types
    const { path } = props.match;
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData({ currentPath: path });
    }, [path, setNavigationData]);

    return React.createElement(Component, props);
  };
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
