import { TextBox } from 'devextreme-react';
import Button from 'devextreme-react/button';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { postApi } from '../../apiUtils';
import { useAuth } from '../../contexts/auth';
import { API_URL } from '../../utils/apiUrl';
import './TransactionsSearchForm.scss';

export function TransactionsSearchForm() {
  const [query, setQuery] = useState<string>();
  const { signOut } = useAuth();
  const history = useHistory();

  const submit = async (e) => {
    e.preventDefault();
    await postApi({
      url: `${API_URL.TRANSACTION_GET_URL_FROM_SHORTCUT}?shortcut=${query}`,
      callAfterSuccess: (result) => {
        if (result === '/logout' && signOut) signOut();

        history.push(result);
        setQuery('');
      },
    });
  };

  const showHelp = () => {
    history.push('/transactions');
  };

  return (
    <form onSubmit={submit} style={{ display: 'flex' }}>
      <div style={{ float: 'left' }}>
        <TextBox
          placeholder="Enter transaction"
          value={query}
          onValueChanged={(e) => setQuery(e.value)}
        />
      </div>
      <Button icon={'help'} type={'normal'} stylingMode={'contained'} onClick={showHelp} />
    </form>
  );
}
