import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postApi, putApi } from '../../../apiUtils';
import { AlertMessage } from '../../../components/alertMessage/AlertMessage';
import { AssetTabRevisionCycleDataGrid } from '../../../components/datagrid/AssetTabRevisionCycleDataGrid';
import { useAssetClass } from '../../../contexts/assetClass';
import { useTranslation } from '../../../contexts/translation';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { AssetCycle, AssetRevisionCycleInputs } from '../../../types/assetCycle.types';
import { DataGridActionType } from '../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../types/pageParams.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';
import { AssetTabCycleDetail } from './cycleDetail/AssetTabCycleDetail';

interface Props extends ITab {
  isReadonly: boolean;
}

export function AssetTabRevisionCycle({ isReadonly, active }: Props) {
  const { translate } = useTranslation();
  const transFormName = 'Asset.RevisionCycle';
  const { id } = useParams<DetailPageParams>();
  const apiUrlList = `${API_URL.ASSET_CYCLE_REVISION_LIST}?visibleOnly=false&parentId=${id}`;
  const [selectedCycle, setSelectedCycle] = useState<AssetCycle | null>(null);
  const { assetClass } = useAssetClass();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetRevisionCycleInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);

  // Načteme revizní cykly
  const {
    data: revisionCycles,
    error: errorRevisionCycles,
    mutate: mutateRevCycles,
    isValidating: revCyclesIsValidating,
  } = useSWR(apiUrlList, fetcher, assetEditSwrConfig);
  // Načteme číselník - CapacityCategory
  const {
    data: capacityCategories,
    error: errorCapacityCategories,
    mutate: mutateCapacityCat,
    isValidating: capIsValidating,
  } = useSWR(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
    assetEditSwrConfig,
  );
  // Načteme číselník - RevisionCategory
  const {
    data: revisionCategories,
    error: errorRevisionCategories,
    mutate: mutateRevisionCategories,
    isValidating: revIsValidating,
  } = useSWR(`${API_URL.REVISION_CATEGORY_LIST}`, fetcher, assetEditSwrConfig);

  const emptyOriginalData: AssetRevisionCycleInputs = {
    id: 0,
    assetId: id as unknown as number,
    capacityCategoryId: 0,
    revisionCategoryId: 0,
    revisionStandard: '',
    description: '',
    visible: true,
  };

  const onSelectionChanged = useCallback((e) => {
    const data = e.selectedRowsData[0];
    if (data === undefined) return;

    setSelectedCycle(data);
  }, []);

  useEffect(() => {
    if (active) {
      mutateRevisionCategories();
      mutateRevCycles();
      mutateCapacityCat();
    }
  }, [active]);

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_CYCLE_POST,
          data: { ..._data, assetId: id },
        });
        break;
      case DataGridActionType.Update:
        const oldData = revisionCycles?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_CYCLE_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutateRevCycles();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const onSubmit = (formData: AssetRevisionCycleInputs, type?: DataGridActionType) => {
    handleApiCall({ type, key: selectedRowData?.id, data: formData });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={errorRevisionCycles || errorCapacityCategories || errorRevisionCategories}
      loading={revCyclesIsValidating || revIsValidating || capIsValidating}
    >
      <AssetTabRevisionCycleDataGrid
        onSelectionChanged={onSelectionChanged}
        revisionCategories={revisionCategories}
        capacityCategories={capacityCategories}
        documentTypes={undefined}
        modalVisibility={isModalVisible}
        handleEdit={handleEdit}
        handleNew={handleNew}
        handleClose={handleClose}
        onDataSubmit={onSubmit}
        selectedData={selectedRowData}
        selectedAction={selectedAction}
        isReadOnly={isReadonly}
        data={revisionCycles}
      />

      {selectedCycle === null ? (
        <AlertMessage
          message={translate!('Select revision cycle', transFormName)}
          class="warning"
        />
      ) : (
        <AssetTabCycleDetail
          cycleId={selectedCycle.id}
          documentTypeTaskStepsAllowed={selectedCycle.documentTypeTaskStepsAllowed}
          assetId={id as unknown as number}
          isReadonly={isReadonly}
          mutateParent={mutateRevCycles}
        />
      )}
    </PageContentWrapper>
  );
}
