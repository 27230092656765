import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AssetTabResponsiblePersonsDataGrid } from '../../../components/datagrid/AssetTabResponsiblePersonsDataGrid';
import { useAssetClass } from '../../../contexts/assetClass';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { AssetResponsiblePerson } from '../../../types/assetResponsiblePerson.types';
import { CapacityCategory, CapacityCategoryPerson } from '../../../types/capacityCategory.types';
import { DetailPageParams } from '../../../types/pageParams.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { assetEditSwrConfig, ITab } from '../AssetsEdit';

interface Props extends ITab {
  isReadonly: boolean;
}

export function AssetTabResponsiblePersons({ isReadonly, active }: Props) {
  const { id } = useParams<DetailPageParams>();
  const { assetClass } = useAssetClass();
  const [selectedCapCategory, setSelectedCapCategory] = useState<number | null>(null);
  // Načteme odpovědné osoby
  const {
    data: assetRespPerson,
    error: errorAssetRepPerson,
    mutate: mutateAssetRespPerson,
    isValidating: assetIsValidating,
  } = useSWR<Array<AssetResponsiblePerson>>(
    id ? `${API_URL.ASSET_RESPONSIBLE_PERSON_LIST}?visibleOnly=false&parentId=${id}` : null,
    fetcher,
    assetEditSwrConfig,
  );

  // Načteme číselník - CapacityCategory
  const {
    data: capacityCategories,
    error: errorCapacityCategories,
    mutate: mutateCapCategories,
    isValidating: capCatIsValidating,
  } = useSWR<Array<CapacityCategory>>(
    `${API_URL.CAPACITY_CATEGORY_LIST}${assetClass ? `?parentId=${assetClass}` : ''}`,
    fetcher,
    assetEditSwrConfig,
  );

  // Načteme číselník - CapacityCategoryPerson
  const {
    data: capacityCategoriesPersons,
    error: errorCapacityCategoriesPersons,
    mutate: mutateCapCatPersons,
    isValidating: capCatPersonsIsValidating,
  } = useSWR<Array<CapacityCategoryPerson>>(
    `${API_URL.CAPACITY_CATEGORY_PERSON_LIST}${
      selectedCapCategory ? `?parentId=${selectedCapCategory}` : ''
    }`,
    fetcher,
    assetEditSwrConfig,
  );
  // Načteme číselník - Person
  const {
    data: persons,
    error: errorPersons,
    mutate: mutatePersons,
    isValidating: personsIsValidating,
  } = useSWR(`${API_URL.PERSON_LIST}`, fetcher, assetEditSwrConfig);

  useEffect(() => {
    if (active) {
      mutateCapCatPersons();
      mutateCapCategories();
      mutateAssetRespPerson();
      mutatePersons();
    }
  }, [active, capacityCategoriesPersons]);

  const validateRespFromTo = (e) => {
    const responsibleTo = e.value;
    const { responsibleFrom } = e.data;
    if (responsibleFrom === null || !responsibleTo) return true;

    return new Date(responsibleTo) >= new Date(responsibleFrom);
  };

  const handleCapacityCategoryChanged = (newCategory: any) => {
    setSelectedCapCategory(newCategory.value);
  };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={
        errorAssetRepPerson ||
        errorCapacityCategories ||
        errorPersons ||
        errorCapacityCategoriesPersons
      }
      loading={
        capCatPersonsIsValidating || capCatIsValidating || personsIsValidating || assetIsValidating
      }
    >
      <AssetTabResponsiblePersonsDataGrid
        assetId={id}
        isReadOnly={isReadonly}
        capacityCategoryChanged={handleCapacityCategoryChanged}
        validateRespFromTo={validateRespFromTo}
        filteredPersons={capacityCategoriesPersons}
        persons={persons}
        capacityCategories={capacityCategories}
        data={assetRespPerson}
        mutateData={mutateAssetRespPerson}
      />
    </PageContentWrapper>
  );
}
