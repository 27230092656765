import React from 'react';
import './AlertMessage.scss';

interface AlertMessageProps {
  message: string | null;
  class: 'danger' | 'warning';
}

/**
 * Komponenta pro zobrazení bootstrap alert hlášky. Parametrem je možné ovlivnit typ hlášky.
 * @param props
 * @constructor
 */
export function AlertMessage(props: AlertMessageProps) {
  return (
    <div className={`alert alert-${props.class}`} role="alert">
      {props.message}
    </div>
  );
}
