import { Button, CheckBox, Popup } from 'devextreme-react';
import Form, { GroupItem } from 'devextreme-react/form';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi } from '../../../apiUtils';
import ReportFilterSelector, {
  FilterActionState,
} from '../../../components/reportSelector/ReportSelector';
import { useAssetClass } from '../../../contexts/assetClass';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { clear, getParameters, parseParameters, useField } from '../../../hooks/useField';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { Asset } from '../../../types/asset.types';
import { CapacityCategory } from '../../../types/capacityCategory.types';
import { FieldType } from '../../../types/field.types';
import { Person } from '../../../types/person.types';
import { Rule } from '../../../types/rule.types';
import { Task } from '../../../types/task.types';
import { TaskStatus } from '../../../types/TaskStatus';
import { TimePeriod } from '../../../types/timeperiod.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { DateFilter } from '../../../utils/dateFilters';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import FieldLabel from '../components/FieldLabel';
import FormField from '../components/FormField';
import DeleteButton from '../components/ReportDeleteButton';
import '../ReportFilter.scss';

type Props = {
  dataGridRef: React.Ref<any>;
  onDataChange: (data: Array<Task> | null) => void;
  onFilterChange: (filter: string | null) => void;
  report: {
    id: number;
    key: string;
  };
  filterVisibility: boolean;
  loadDefaultSetting: boolean;
  heading?: string;
  refetchData?: boolean;
  setRefetchData?: any;
};

/**
 * Komponenta představující filtr s report selectorem pro Report zodpovědných osob (Asset responsible persons)
 */
export default function TaskReportFilter({
  dataGridRef,
  onDataChange,
  onFilterChange,
  report,
  filterVisibility,
  loadDefaultSetting,
  heading,
  refetchData,
  setRefetchData,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [dialogAction, setDialogAction] = useState<FilterActionState | null>(null);
  const [isDefault, setDefault] = useState<boolean>(false);
  const { startLoading, loading, stopLoading } = useLoading();
  const { assetClass } = useAssetClass();

  const { translate } = useTranslation();
  const transFormName = 'Task.List';
  const transFormRepSelector = 'Reports.ReportSelector';
  const transFormReportBar = 'Reports.Bar';
  // reference pro report selector na kterou jsou navázané funkce pro práci s filtry:
  //  - smazání, přidání / editace filtru
  const selectorRef = useRef<any>();

  const [filterSettingSession, setFilterSettingSession] = useSessionStorage(
    STORAGE_KEYS.TASKS_REPORT_FILTER_DESKTOP,
    {},
  );

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const getData = async (params = {}) => {
    const response = await postApi<Array<Task>>({
      url: API_URL.TASK_REPORT_REQUEST,
      data: params,
      hideNotifications: true,
    });
    onDataChange(response ?? null);
    setRefetchData(false);
    stopLoading();
  };

  // po změně akce provolat fetch se stejnými parametry
  useEffect(() => {
    if (!refetchData || !setRefetchData) return;
    onSearch();
  }, [refetchData]);

  // useEffect(() => {
  //   const params = {
  //     timePeriodIdArr: null,
  //     statusIdArr: null,
  //     capacityCategoryIdArr: null,
  //     typeIdArr: null,
  //     responsiblePersonIdArr: null,
  //   };
  //   getData(params);
  // }, []);

  /**
   * Funkce pro vyhledávání - po zadání hodnot ve filter baru
   */
  const onSearch = (e?: FormEvent) => {
    startLoading();
    e && e.preventDefault();
    const params = parseParameters(fields);
    getData(params);
    const filterParams = getParameters(fields);
    setFilterSettingSession(filterParams);
  };

  /**
   * Funkce pro smazání hodnot z fieldů - reset funkce
   */
  const clearDataAndFields = () => {
    clear(fields);
    onDataChange([]);
  };

  /**
   * SWR fetch dat z API pro naplnění field data sourců
   */

  // Číselník časových období
  const { data: timePeriods } = useSWR<TimePeriod[]>(`${API_URL.TIME_PERIOD_LIST}`, fetcher);

  // Číselník typů
  const { data: types } = useSWR<DocumentType[]>(
    `${API_URL.DOCUMENT_TYPE_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  // Číselník kategorií
  const { data: capacityCategories } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  // Číselník stavů
  const { data: states } = useSWR<TaskStatus[]>(`${API_URL.TASK_STATUS_LIST}`, fetcher);

  // Číselník strojů
  const { data: assets } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
  );

  // Číselník zodpovědných osob
  const { data: responsiblePersons } = useSWR<Person[]>(API_URL.PERSON_FROM_THIS_APP_LIST, fetcher);

  /**
   * Definice fieldů pro filter bar + form na přidání nového / editaci stávajího filtru
   */

  const filterNameField = useField({
    name: 'name',
    fieldType: FieldType.Text,
    placeholder: translate!('Filter name', transFormRepSelector),
    rules: [{ type: Rule.RequiredRule, message: 'Filter name is required' }],
    maxLength: 50,
  });

  const timePeriodField = useField({
    name: 'timePeriodIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: timePeriods,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Time period', transFormName),
  });

  const dateFromField = useField({
    name: 'createdFrom',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearStart,
      DateFilter.PreviousMonthStart,
      DateFilter.PreviousWeekStart,
      DateFilter.PreviousDayStart,
      DateFilter.CurrentDayStart,
      DateFilter.CurrentWeekStart,
      DateFilter.CurrentMonthStart,
      DateFilter.CurrentYearStart,
      DateFilter.NextDayStart,
      DateFilter.FollowingWeekStart,
      DateFilter.FollowingMonthStart,
      DateFilter.FollowingYearStart,
    ],
    defaultValue: undefined,
    placeholder: translate!('Created from', transFormName),
  });

  const dateToField = useField({
    name: 'createdTo',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearEnd,
      DateFilter.PreviousMonthEnd,
      DateFilter.PreviousWeekEnd,
      DateFilter.PreviousDayEnd,
      DateFilter.CurrentDayEnd,
      DateFilter.CurrentWeekEnd,
      DateFilter.CurrentMonthEnd,
      DateFilter.CurrentYearEnd,
      DateFilter.NextDayEnd,
      DateFilter.FollowingWeekEnd,
      DateFilter.FollowingMonthEnd,
      DateFilter.FollowingYearEnd,
    ],
    defaultValue: undefined,
    placeholder: translate!('Created to', transFormName),
  });

  const assetIdArrayField = useField({
    name: 'assetIdArray',
    fieldType: FieldType.MultipleOptions,
    dataSource: assets,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset', transFormName),
  });

  const documentTypeIdArrayField = useField({
    name: 'typeIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: types ?? [],
    keyExpr: 'id',
    displayExpr: 'nameTranslated',
    placeholder: translate!('Type', transFormName),
  });

  const taskStateIdArrayField = useField({
    name: 'statusIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: states ?? [],
    keyExpr: 'id',
    displayExpr: 'nameTranslated',
    placeholder: translate!('Status', transFormName),
  });

  const capacityCategoryIdArrayField = useField({
    name: 'capacityCategoryIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: capacityCategories ?? [],
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Capacity category', transFormName),
  });

  const responsiblePersonIdArrayField = useField({
    name: 'responsiblePersonIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: responsiblePersons ?? [],
    keyExpr: 'personalNumber',
    displayExpr: 'fullName',
    placeholder: translate!('Responsible person', transFormName),
  });

  const fields = [
    filterNameField,
    timePeriodField,
    dateFromField,
    dateToField,
    documentTypeIdArrayField,
    capacityCategoryIdArrayField,
    taskStateIdArrayField,
    assetIdArrayField,
    responsiblePersonIdArrayField,
  ];

  /**
   * Funkce pro zpracování formuláře při přidání nového / editaci stávajícího filtru
   */
  const onFilterDialogSubmit = (e) => {
    e.preventDefault();
    selectorRef.current.saveOrEditFilter(dialogAction);
  };

  const handleDialogVisibility = (action: FilterActionState | null = null) => {
    setDialogAction(action);
    setIsModalVisible(!!action);
  };

  return (
    <div className="no-responsive-report-filter">
      <div className="no-responsive-report-filter-scrollable">
        <Toolbar>
          {' '}
          <Item location={'before'} html={heading} />
        </Toolbar>
        <div>
          <div
            className="report-selector-bar"
            style={{ display: filterVisibility ? 'block' : 'none' }}
          >
            <ReportFilterSelector
              ref={selectorRef}
              filterName={filterNameField.value}
              fields={fields}
              dataGridRef={dataGridRef}
              onClear={clearDataAndFields}
              onFilterChange={onFilterChange}
              report={report}
              openDialog={handleDialogVisibility}
              fetchData={getData}
              isDefault={isDefault}
              setIsDefault={(value) => setDefault(value)}
              loadDefaultSetting={loadDefaultSetting}
              loadDefaultSettingStorageKey={STORAGE_KEYS.TASKS_REPORT_FILTER_DESKTOP}
            />
          </div>
          {isModalVisible && (
            <Popup
              visible={isModalVisible}
              dragEnabled={false}
              closeOnOutsideClick={true}
              onHiding={() => handleDialogVisibility(null)}
              showTitle={true}
              title={
                dialogAction === FilterActionState.EDIT
                  ? translate!('Edit selected filter', transFormRepSelector)
                  : translate!('Save a new filter', transFormRepSelector)
              }
              container=".dx-viewport"
              width="auto"
              height="auto"
            >
              {/* filter add/edit dialog */}
              <form onSubmit={onFilterDialogSubmit}>
                <Form colCount={3} className="fields-container">
                  <GroupItem colSpan={3} colCount={3}>
                    <GroupItem>
                      <FieldLabel label={filterNameField.placeholder} />
                      <FormField field={filterNameField} />
                    </GroupItem>
                  </GroupItem>
                  <GroupItem colSpan={3} colCount={3}>
                    <GroupItem colSpan={1}>
                      <FieldLabel label={timePeriodField.placeholder} />
                      <FormField field={timePeriodField} />
                    </GroupItem>
                    <GroupItem colSpan={1}>
                      <FieldLabel label={dateFromField.placeholder} />
                      <FormField
                        field={dateFromField}
                        max={dateToField.value}
                        options={{ defaultTime: '00:00:00' }}
                        alt
                      />
                    </GroupItem>
                    <GroupItem colSpan={1}>
                      <FieldLabel label={dateToField.placeholder} />
                      <FormField
                        field={dateToField}
                        min={dateFromField.value}
                        options={{ defaultTime: '23:59:59' }}
                        alt
                      />
                    </GroupItem>
                  </GroupItem>
                  <GroupItem colSpan={3} colCount={3}>
                    <GroupItem>
                      <FieldLabel label={documentTypeIdArrayField.placeholder} />
                      <FormField field={documentTypeIdArrayField} alt />
                    </GroupItem>
                    <GroupItem>
                      <FieldLabel label={capacityCategoryIdArrayField.placeholder} />
                      <FormField field={capacityCategoryIdArrayField} alt />
                    </GroupItem>
                    <GroupItem>
                      <FieldLabel label={taskStateIdArrayField.placeholder} />
                      <FormField field={taskStateIdArrayField} alt />
                    </GroupItem>
                    <GroupItem>
                      <FieldLabel label={assetIdArrayField.placeholder} />
                      <FormField field={assetIdArrayField} alt />
                    </GroupItem>
                    <GroupItem>
                      <FieldLabel label={responsiblePersonIdArrayField.placeholder} />
                      <FormField field={responsiblePersonIdArrayField} alt />
                    </GroupItem>
                    <GroupItem>
                      <CheckBox value={isDefault} onValueChanged={(e) => setDefault(e.value)} />{' '}
                      {translate!('Is default', transFormRepSelector)}
                    </GroupItem>
                  </GroupItem>
                  <GroupItem colSpan={3} colCount={3} cssClass={'action-buttons-view'}>
                    {dialogAction === FilterActionState.EDIT && <DeleteButton ref={selectorRef} />}
                    <Button
                      className="button-spacing"
                      text={translate!('Save', transFormRepSelector)}
                      icon="save"
                      type="success"
                      useSubmitBehavior
                      disabled={loading}
                    />
                  </GroupItem>
                </Form>
              </form>
            </Popup>
          )}
        </div>
        <form onSubmit={onSearch} style={{ display: filterVisibility ? 'block' : 'none' }}>
          <Form colCount={7} className="popup-container" style={{ justify: 'center' }}>
            <GroupItem>
              <FieldLabel label={timePeriodField.placeholder} />
              <FormField field={timePeriodField} />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={dateFromField.placeholder} />
              <FormField
                field={dateFromField}
                max={dateToField.value}
                options={{ defaultTime: '00:00:00' }}
              />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={dateToField.placeholder} />
              <FormField
                field={dateToField}
                min={dateFromField.value}
                options={{ defaultTime: '23:59:59' }}
              />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={documentTypeIdArrayField.placeholder} />
              <FormField field={documentTypeIdArrayField} />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={capacityCategoryIdArrayField.placeholder} />
              <FormField field={capacityCategoryIdArrayField} />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={taskStateIdArrayField.placeholder} />
              <FormField field={taskStateIdArrayField} />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={assetIdArrayField.placeholder} />
              <FormField field={assetIdArrayField} />
            </GroupItem>
            <GroupItem>
              <FieldLabel label={responsiblePersonIdArrayField.placeholder} />
              <FormField field={responsiblePersonIdArrayField} />
            </GroupItem>
            <GroupItem cssClass={'action-buttons-view'}>
              <Button
                className="button-spacing"
                type="success"
                text={translate!('Search', transFormReportBar)}
                useSubmitBehavior
                disabled={loading}
              />
            </GroupItem>
          </Form>
        </form>
      </div>
    </div>
  );
}
