import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { AssetTreeViewWithBack } from '../../components/assetTreeView/AssetTreeViewWithBack';
import { AssetsDataGrid } from '../../components/datagrid/AssetsDataGrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { useSaveDataGridFilter } from '../../hooks/useSaveDataGridFilter';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { Asset } from '../../types/asset.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { isNilOrEmpty } from '../../utils/ramdaUtils';
import { swrConfig } from './AssetsEdit';

export function Assets() {
  const { translate } = useTranslation();
  const transFormName = 'Asset.List';
  const { assetClass } = useAssetClass();
  const { user } = useAuth();
  const canEdit = user?.roles.includes(UserRoles.AssetEdit);
  const history = useHistory();
  const dataGrid = useRef<any>();

  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Assets', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const { resetFilter, getActualJsonState } = useSaveDataGridFilter(
    STORAGE_KEYS.ASSETS_DATAGRID_DESKTOP,
    dataGrid,
  );

  const {
    data,
    error,
    mutate: mutateData,
  } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS}?visibleOnly=false&activeOnly=false&classId=${assetClass}`,
    fetcher,
    swrConfig,
  );
  const [isTreeView, setIsTreeView] = useState<boolean>(false);

  /**
   * Handle kopírovacího tlačítka v DataGridu
   * @param e Event
   */
  const handleEditButton = (e): void => {
    if (!e.row) return;
    history.push(`/assets/edit/${e.row.data.id}`);
  };

  /**
   * Handle kopírovacího tlačítka v DataGridu
   * @param e  Event
   */
  const handleCopyButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push(`/assets/new/${e.row.data.id}`);
    }
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: 'before',
      html: translate!('Assets', transFormName),
    });

    toolbarItems.unshift({
      location: 'after',
      template: 'totalGroupCount',
    });
    toolbarItems.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'clear',
        type: 'info',
        text: translate!('Clear filter', transFormName),
        stylingMode: 'outlined',
        onClick: () => resetFilter(),
      },
    });
    if (canEdit) {
      toolbarItems.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => history.push(`/assets/new/`),
        },
      });
    }
  };

  return (
    <PageContentWrapper error={error} loading={!data}>
      <AssetTreeViewWithBack
        isTreeView={isTreeView}
        classId={assetClass}
        setIsTreeView={setIsTreeView}
        dataGrid={
          <AssetsDataGrid
            data={data}
            dataGridRef={dataGrid}
            onToolbarPreparing={onToolbarPreparing}
            handleEdit={handleEditButton}
            handleCopy={handleCopyButton}
            setIsTreeView={setIsTreeView}
            mutateData={mutateData}
          />
        }
      />
    </PageContentWrapper>
  );
}

export default Assets;
