import { useCallback, useEffect, useState } from 'react';

type ClearButtonPropsType = {
  uniqueFieldId: number;
  showClearButton: boolean;
  onOpened: () => void;
  onClosed: () => void;
  onFocusIn: () => void;
  onFocusOut: () => void;
};

/**
 * Custom hook viditelnost clear buttonu při focusu:
 */
export const useClearButtonProps = (uniqueFieldId = 0): ClearButtonPropsType => {
  const [clearVisibility, setClearVisibility] = useState<boolean>(false);

  useEffect(() => {}, [clearVisibility]);

  const setValue = useCallback((value) => setClearVisibility(value), [clearVisibility]);

  return {
    uniqueFieldId,
    showClearButton: clearVisibility,
    onOpened: () => setValue(true),
    onClosed: () => setValue(false),
    onFocusIn: () => setValue(true),
    onFocusOut: () => setValue(false),
  };
};
