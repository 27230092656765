import React, { useEffect } from 'react';
import useSWR from 'swr';
import { postApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategoriesPersonsForm } from '../../forms/capacityCategoriesPersonsForm/CapacityCategoriesPersonsForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory, CapacityCategoryPersonInput } from '../../types/capacityCategory.types';
import { Person } from '../../types/person.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function CapacityCategoriesPersonsNew() {
  const { translate } = useTranslation();
  const transFormName = 'CapacityCategoryPerson.Create';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'New capacity categories persons',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Capacity categories persons', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Načteme číselník - capacity category
  const { data: dataCapacityCategories, error: errorCapacityCategories } = useSWR<
    CapacityCategory[]
  >(API_URL.CAPACITY_CATEGORY_LIST, fetcher);
  // Načteme číselník - person
  const { data: dataPerson, error: errorPerson } = useSWR<Person[]>(
    API_URL.PERSON_FROM_THIS_APP_LIST,
    fetcher,
  );

  const formData: CapacityCategoryPersonInput = {
    id: 0,
    visible: true,
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    let isOk = false;
    startLoading();
    await postApi<CapacityCategoryPersonInput>({
      url: API_URL.CAPACITY_CATEGORY_PERSON_POST,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      heading={translate!('New capacity category person', transFormName)}
      loading={!dataCapacityCategories || !dataPerson}
      error={errorCapacityCategories || errorPerson}
    >
      <CapacityCategoriesPersonsForm
        onSubmit={onSubmit}
        dataPerson={dataPerson}
        dataCapacityCategories={dataCapacityCategories}
        formData={formData}
      />
    </PageContentWrapper>
  );
}
export default CapacityCategoriesPersonsNew;
