import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { deleteApi, postApi, putApi } from '../../apiUtils';
import { AlertMessage } from '../../components/alertMessage/AlertMessage';
import { WorkActivityGroupsDocumentsDataGrid } from '../../components/datagrid/WorkActivityGroupsDocumentsDatagrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { WorkActivityGroupForm } from '../../forms/workActivityGroupEdit/workActivityGroupForm';
import { confirmDialogActions, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DocumentType } from '../../types/documentType.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { WorkActivityGroup } from '../../types/workActivityGroup.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export type WAGState = {
  backRoute?: string;
  isNew?: boolean;
};

export function WorkActivityGroupDetail() {
  const { translate } = useTranslation();
  const { assetClass, assetClassName } = useAssetClass();
  const transFormName = 'WorkActivityGroup.List';
  const { setValue } = useBreadcrumbs();
  const { state }: { state: WAGState | undefined } = useLocation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [tmpAssignedDocumentsList, setTmpAssignedDocumentsList] = useState<DocumentType[]>([]);
  const [tmpWorkActivityGroup] = useState<WorkActivityGroup>({
    visible: true,
  } as WorkActivityGroup);
  const [isNew] = useState<boolean>(state?.isNew ?? false);
  const backRoute = state?.backRoute ?? '/work-activity-groups';
  const history = useHistory();
  const { id } = useParams<DetailPageParams>();
  const { data: workActivityGroup, error: errorWorkActivityGroup } = useSWR<WorkActivityGroup>(
    !isNew ? `${API_URL.WORK_ACTIVITY_GROUP_ITEM}?id=${id}` : null,
    fetcher,
    {},
  );
  const { data: capacityCategories, error: capacityCategoriesError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );
  const { data: documentTypes, error: documentTypesError } = useSWR<DocumentType[]>(
    `${API_URL.DOCUMENT_TYPE_LIST}?parentId=${assetClass}`,
    fetcher,
  );
  const {
    data: assignedDocumentsList,
    error: errorAssignedDocuments,
    mutate: mutateAssignedDocuments,
  } = useSWR<DocumentType[]>(
    !isNew ? `${API_URL.WORK_ACTIVITY_GROUP_ASSIGNED_DOCUMENT_TYPES}?id=${id}` : null,
    fetcher,
  );

  useEffect(() => {
    setValue({
      pageName: isNew ? 'Create work activity group' : 'Edit work activity group',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Work activity groups', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    if (!isNew && assignedDocumentsList && assignedDocumentsList.length > 0)
      setShowErrorMessage(false);
    if (isNew && tmpAssignedDocumentsList.length > 0) setShowErrorMessage(false);
  }, [assignedDocumentsList, tmpAssignedDocumentsList]);

  const { open } = useConfirmDialog({
    title: translate!('Delete', ''),
    bodyHtml: translate!('Are you sure?', ''),
    cancelButtonTitle: translate!('Cancel', ''),
    confirmButtonTitle: translate!('Confirm', ''),
  });

  const handleRemove = async (e: any) => {
    if (isNew) {
      open().then(async (c) => {
        if (c === confirmDialogActions.CONFIRM) {
          setTmpAssignedDocumentsList(
            tmpAssignedDocumentsList.filter((x: any) => x.id !== e.data.id),
          );
        } else if (c === confirmDialogActions.CANCEL) {
          return false;
        }
      });
    } else {
      open().then(async (c) => {
        if (c === confirmDialogActions.CONFIRM) {
          if (assignedDocumentsList && assignedDocumentsList?.length > 1) {
            await deleteApi({
              url: `${API_URL.WORK_ACTIVITY_GROUP_REMOVE_DOCUMENT_TYPE}?documentTypeId=${e.data.id}`,
              id: Number(id),
              callAfterSuccess: () => {
                mutateAssignedDocuments();
              },
            });
          } else {
            setShowErrorMessage(true);
          }
        } else if (c === confirmDialogActions.CANCEL) {
          return false;
        }
      });
    }
  };

  const handleAddAsignToDocumentType = async (formData) => {
    if (!formData) return;
    if (isNew) {
      if (
        !tmpAssignedDocumentsList.some(
          (assignedDocument: any) => assignedDocument.id === formData.documentTypeId,
        )
      ) {
        let tmpDocument = documentTypes?.find((type: any) => type.id === formData.documentTypeId);
        setTmpAssignedDocumentsList([
          ...tmpAssignedDocumentsList,
          {
            id: formData.documentTypeId,
            assetClassName: assetClassName,
            name: tmpDocument?.nameTranslated,
          } as DocumentType,
        ]);
      }
      setIsVisible(false);
    } else {
      await postApi({
        url: `${API_URL.WORK_ACTIVITY_GROUP_ADD_DOCUMENT_TYPE}?id=${id}&documentTypeId=${formData.documentTypeId}`,
        data: {
          id: id,
          documentTypeId: formData.documentTypeId,
        },
        callAfterSuccess: () => {
          mutateAssignedDocuments();
          setIsVisible(false);
        },
      });
    }
  };

  const onSubmit = async (_workActivityGroup) => {
    if (!_workActivityGroup) return;
    if (isNew) {
      if (tmpAssignedDocumentsList.length < 1) {
        setShowErrorMessage(true);
      } else {
        await postApi({
          url: API_URL.WORK_ACTIVITY_GROUP_POST,
          data: {
            capacityCategoryId: _workActivityGroup.capacityCategoryId,
            name: _workActivityGroup.name,
            visible: _workActivityGroup.visible,
          },
          callAfterSuccess: async (res: any) => {
            await tmpAssignedDocumentsList.map((assignedDocument: DocumentType) =>
              postApi({
                url: `${API_URL.WORK_ACTIVITY_GROUP_ADD_DOCUMENT_TYPE}?id=${res.id}&documentTypeId=${assignedDocument.id}`,
                data: {
                  id: res.id,
                  documentTypeId: assignedDocument.id,
                },
                callAfterSuccess: () => {
                  mutateAssignedDocuments();
                  setIsVisible(false);
                },
              }),
            );
            history.push(backRoute);
          },
        });
      }
    } else {
      await putApi({
        url: API_URL.WORK_ACTIVITY_GROUP_PUT,
        data: {
          id: _workActivityGroup.id,
          capacityCategoryId: _workActivityGroup.capacityCategoryId,
          name: _workActivityGroup.name,
          visible: _workActivityGroup.visible,
        },
        callAfterSuccess: () => {
          history.push(backRoute);
        },
      });
    }
  };

  return (
    <PageContentWrapper
      error={
        (!isNew && errorWorkActivityGroup) ||
        (!isNew && errorAssignedDocuments) ||
        capacityCategoriesError ||
        documentTypesError
      }
      loading={
        (!isNew && !workActivityGroup) ||
        (!isNew && !assignedDocumentsList) ||
        !capacityCategories ||
        !documentTypes
      }
      heading={translate!('Work activity group', transFormName)}
    >
      <>
        {capacityCategories && (
          <WorkActivityGroupForm
            data={isNew ? tmpWorkActivityGroup : workActivityGroup}
            onSubmit={onSubmit}
            capacityCategories={capacityCategories}
            onClose={() => history.push('/work-activity-groups')}
          />
        )}
        {showErrorMessage && (
          <AlertMessage
            message={translate!(
              'Work group activity must have at least 1 assigned document',
              transFormName,
            )}
            class={'danger'}
          />
        )}
        {documentTypes && (
          <div style={{ marginTop: 30 }}>
            <WorkActivityGroupsDocumentsDataGrid
              data={isNew ? tmpAssignedDocumentsList : assignedDocumentsList}
              handleRemove={handleRemove}
              documentTypes={documentTypes}
              onSubmit={handleAddAsignToDocumentType}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          </div>
        )}
      </>
    </PageContentWrapper>
  );
}
export default WorkActivityGroupDetail;
