import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { concat, isNil, not, path, toLower } from 'ramda';

export const paintCellByTaskColor = (e: CellPreparedEvent) => {  
  if (e.data && e.column.dataField === 'typeNameTranslated') {
    const colorNotNil = not(isNil(path(['data', 'typeColorName'], e)));
    e.cellElement.className = concat<string, string>(
      e.cellElement.className,
      colorNotNil
        ? ` ${toLower(e.data.typeColorName)}_cell-document-type-highlight`
        : ' default_cell',
    );
  }
};
