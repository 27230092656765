import Form, {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  EmptyItem,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import moment, { Moment } from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import useSWR from 'swr';
import { postApi, putApi } from '../../apiUtils';
import { IAfterSubmitMode } from '../../components/formSuccessButtons/FormSuccessButtons';
import { Loader } from '../../components/loader/Loader';
import { useAssetClass } from '../../contexts/assetClass';
import { useAuth } from '../../contexts/auth';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { useIsWorkSheetAllPermission } from '../../hooks/useIsWorkSheetAllPermission';
import { useTaskCompleteCheck } from '../../hooks/useTaskCompleteCheck';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { swrConfig } from '../../pages/Assets/AssetsEdit';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { LocationState } from '../../types/locationState.types';
import { Task } from '../../types/task.types';
import { TaskNameListItem } from '../../types/taskNames.types';
import { WorkActivityGroup } from '../../types/workActivityGroup.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import {
  dateTimeFieldOptionsWithSerializationAndInterval,
  timeFieldOptionsWithSerializationWithInterval,
} from '../../utils/dates.types';
import { TaskInputsNew } from '../tasks/TasksForm';
import { DeleteDialog } from './DeleteDialog';
import './WorkSheetsForm.scss';

export interface WorkSheetInputsNew {
  id?: number;
  defaultNewTaskDocumentType?: number | null;
  defaultTaskCompleteBehavior?: number | null;
  topLevelParenId?: number;
  topLevelParenName?: null | string;
  assetId?: number | null;
  assetName?: null | string;
  assetTypeId?: number;
  taskId?: number;
  taskName?: null | string;
  taskComplete?: null | boolean;
  taskCapacityCategoryId?: number;
  taskDocumentType?: null | number;
  taskDocumentTypeCompleteBehavior?: null | number;
  taskStartDate?: Date | null | string;
  taskCreated?: Date | null | string;
  workActivityId?: number;
  workActivityName?: string;
  workActivityGroupId?: number;
  workActivityGroupName?: string;
  capacityCategoryId?: number | null;
  taskStatusId?: number | null;
  workSheetStatusId?: number;
  tmpWorkSheetStatusId?: number;
  taskResponsiblePersonId?: number | null;
  operatorId?: number;
  activityStarted?: Date | null | string;
  activityFinished?: Date | null | string;
  activityDuration?: null | string;
  description?: string;
  taskNameTranslated?: string;
  capacityCategoryName?: string;
  operatorName?: string;
  statusName?: string;
  statusNameTranslated?: string;
  typeNameTranslated?: string;
  responsiblePersonName?: string;
  taskCloseReadonlyFlag?: boolean;
  taskCloseDefaultValue?: boolean;
  task: any;
  operators: any;
  workActivities: any;
  capacityCategories: any;
  assets: any;
  topLevelAssets: any;
  tasksByClass: any;
  taskProcessConfigList: any;
  workSheetOperators: any;
  workSheetOperatorsCapacityCategories: any;
}

export const dxSelectBoxConfig = {
  searchEnabled: true,
  searchModeOption: 'contains',
  searchExprOption: 'name',
  searchTimeoutOption: 200,
  showClearButton: true,
};

const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

export const getDuration = (dateFrom: any, dateTo: any) => {
  if (!dateTo || dateTo <= dateFrom) return '00:00';
  dateTo.set({ second: 0, millisecond: 0 });
  dateTo.toISOString();
  dateTo.format();
  dateFrom.set({ second: 0, millisecond: 0 });
  dateFrom.toISOString();
  dateFrom.format();
  const min = dateTo.diff(dateFrom, 'minutes');
  const hours = Math.floor(min / 60);
  if (hours > 24) return '';
  const result = pad(hours, 2) + ':' + pad(min % 60, 2);
  return result;
};

export const setActivityFinish = (dateFrom: any, activityDuration: string) => {
  return dateFrom
    .add(parseInt(activityDuration.split(':')[0]), 'hours')
    .add(parseInt(activityDuration.split(':')[1]), 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
};

interface Props {
  defaultFormData: WorkSheetInputsNew;
  defaultFormDataTask: TaskInputsNew;
  isEdit?: boolean;
  defaultIsTaskNameCombo?: boolean;
  defaultShowDowntime?: boolean;
  onSubmitDone?: () => void;
  isReadOnly: boolean;
  oldRequiredDate?: Date | null | string;
  transFormName: string;
  mutateTask?: any;
  today: Moment;
  oldActivityStarted?: Moment;
  oldActivityFinished?: Moment;
  restrictWorkActivitiesByCapacityCategoryPerson?: boolean;
}

interface SectionTitles {
  titleActivitytime: ReactElement;
}

export default function SheetForm({
  onSubmitDone,
  defaultFormData,
  defaultFormDataTask,
  isEdit,
  defaultIsTaskNameCombo,
  isReadOnly,
  oldRequiredDate,
  transFormName,
  mutateTask,
  today,
  oldActivityStarted,
  oldActivityFinished,
  restrictWorkActivitiesByCapacityCategoryPerson,
}: Props) {
  const { translate } = useTranslation();
  const { assetClass } = useAssetClass();
  const { startLoading, loading, stopLoading } = useLoading();
  const history = useHistory();
  const { state }: { state: LocationState | undefined } = useLocation();
  const [formData, setFormData] = useState<WorkSheetInputsNew>(defaultFormData);
  const [isTaskNameCombo] = useState<boolean>(defaultIsTaskNameCombo!);
  const [afterSubmitMode, setAfterSubmitMode] = useState<IAfterSubmitMode>(IAfterSubmitMode.NEW);
  const [localTaskNames, setLocalTaskNames] = useState<TaskNameListItem[]>();
  const [hasPermissionAllToCreateWorkSheet, loadingPermission] = useIsWorkSheetAllPermission();
  const { user: authUser } = useAuth();
  const [sectionTitles, setSectionTitles] = useState<SectionTitles>({
    titleActivitytime: <>${translate!('Activity duration', transFormName)}</>,
  });
  const [formDataTask, setFormDataTask] = useState<TaskInputsNew>(defaultFormDataTask);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const [localCapacityCategories, setLocalCapacityCategories] = useState<CapacityCategory[]>(
    formData.capacityCategories,
  );

  const { isVisible: isTaskCompleteVisible, isChecked: isTaskCompleteChecked } =
    useTaskCompleteCheck({
      taskId: formData.taskId,
      taskStatusId: formData.taskStatusId,
      taskDocumentType: formData.taskDocumentType,
      taskDocumentTypeCompleteBehavior:
        formData.taskDocumentTypeCompleteBehavior || formData.taskDocumentTypeCompleteBehavior === 0
          ? formData.taskDocumentTypeCompleteBehavior
          : defaultFormData.defaultTaskCompleteBehavior,
      taskResponsiblePersonId: formData.taskResponsiblePersonId,
      operatorId: formData.operatorId,
      activityFinished: formData.activityFinished,
      taskProcessConfigList: formData.taskProcessConfigList,
    });

  useEffect(() => {
    setTimeout(() => {
      setFormData({ ...formData, taskComplete: isTaskCompleteChecked });
    }, 200);
  }, [isTaskCompleteVisible, isTaskCompleteChecked]);

  useEffect(() => {
    setFormData({ ...defaultFormData, assetId: defaultFormData?.task?.assetId });
    setFormDataTask({ ...defaultFormDataTask });
    setLocalCapacityCategories(filterCapacityCategories);
  }, [defaultFormData, defaultFormDataTask]);

  const formRef = useRef<Form>(null);
  const backRoute = state?.backRoute ?? '/worksheets';
  const [
    prompt,
    setDirty,
    setClean,
    handleBackButton,
    handleHistoryGoBackButton,
    handleDirtyWithCallback,
    isDirty,
  ] = useUnsavedChangesWarning(backRoute, () => {
    if (!formRef) return;
    if (formRef.current!.instance.validate().isValid)
      onSubmit(null).then((result) => {
        if (result)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        else setDirty();
      });
  });

  /**
   * Načte se seznam skupin činností. Načítají se podle caacityCategory a podle documentType. Může se jednat o defaultní hodnoty
   * či o hodnoty z tasku.
   */
  const { data: workActivityGroups, error: workActivityGroup } = useSWR<WorkActivityGroup[]>(
    formData.capacityCategoryId
      ? `${API_URL.WORK_ACTIVITY_GROUP_LIST_BY_CAPACITY}?capacityCategoryId=${
          formData.capacityCategoryId
        }${formData.taskDocumentType ? `&documentTypeId=${formData.taskDocumentType}` : ''}`
      : null,
    fetcher,
    swrConfig,
  );

  const deleteAction = () => {
    //setTimeout(() => {
    if (!formRef) return;
    if (formRef.current!.instance.validate().isValid)
      onSubmit(null).then((result) => {
        if (result)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        else setDirty();
      });
    //}, 100);
  };

  const deletePopupVisible = () => {
    return popupVisible;
  };

  const editFlag = () => {
    return formData.workSheetStatusId == 10 || formData.workSheetStatusId == 20;
  };

  const handleDeleteDialogChange = (visible: boolean) => {
    if (!visible && formData.tmpWorkSheetStatusId) {
      setFormData({
        ...formData,
        workSheetStatusId: formData.tmpWorkSheetStatusId ?? 10,
      });
    }
    setPopupVisible(visible);
  };

  function dateFormat(days: number) {
    return moment(
      new Date(24 * 3600 * 1000 * Math.floor(new Date().getTime() / (24 * 3600 * 1000) + days)),
    ).format('Y-MM-DDTHH:mm:ss');
  }

  /**
   * Načte task
   */
  const { data: tasksById, error: errorTasksById } = useSWR<Task>(
    typeof isEdit !== 'undefined' ? `${API_URL.TASK_ITEM}?id=${formData.taskId}` : null,
    fetcher,
    swrConfig,
  );

  /**
   * Změna assetu. Při výběru assetu se nastaví příslučná linka. Linka se pozná podle
   * atributu topLevelParenId, který má asset. Dále podle assetu nastavíe assetTypeId,
   * který je potřeba pro načítání taskNames do comba.
   * @param data: Data z dropDownListu (AssetId)
   */
  const handleAssetChanged = (data) => {
    if (!formData.assets) return;
    const asset = formData.assets.find((x) => x.id === data.value);
    if (!asset) {
      setFormData({ ...formData, assetId: undefined });
      return;
    }
    // Asset má atribut topLevelParenId, podle kterého vybereme linku v dropdownlistu
    const assetTopLevelParenId = asset.topLevelParenId;
    // AssetTypeId pro načtení TaskNames do comba
    const assetTypeId = asset.typeId;
    const assetName = asset.name;
    let taskId = formData.taskId;

    setFormData({
      ...formData,
      assetId: data.value,
      topLevelParenId: assetTopLevelParenId,
      assetTypeId,
      assetName,
      taskId,
    });
    setFormDataTask({
      ...formDataTask,
      assetId: data.value,
      assetTopLevelParenId,
      assetTypeId,
      typeId: formData.defaultNewTaskDocumentType ? formData.defaultNewTaskDocumentType : undefined,
      taskName: 'text',
      //capacityCategoryId: 1,
      description: 'a',
    });
  };

  /*
   * změna tasku
   */
  const handleTaskChanged = (data) => {
    if (!formData.tasksByClass || !formData.assets) return;

    const selectedTask = formData.tasksByClass.find((x) => x.id === data.value);
    const selectedParentAsset = formData.assets.find((x) => x.id === selectedTask?.assetId);

    // Když se změní task, profiltrují se aktivity podle kapacitní kategorie
    const actions = selectedTask?.capacityCategoryId
      ? formData.workActivities?.filter(
          (x) => x.capacityCategoryId === selectedTask?.capacityCategoryId,
        )
      : formData.workActivities;

    const filteredTask = tasksById
      ? tasksById.created
      : formData.tasksByClass?.find((x) => x.id === formData.taskId);

    const operator = formData.operators.find((x) => x.personalNumber === formData.operatorId);

    let activityStarted = today.format('YYYY-MM-DD HH:mm:ss');
    let activityFinished = undefined;
    if (!isEdit && selectedTask && selectedTask.started) activityStarted = selectedTask.started;
    if (!isEdit && selectedTask && selectedTask.finished) activityFinished = selectedTask.finished;

    let _formData = {
      ...formData,
      activityStarted: activityStarted,
      activityFinished: activityFinished,
      taskCreated: filteredTask?.created,
      capacityCategoryId: selectedTask
        ? selectedTask.capacityCategoryId
        : defaultFormData.capacityCategoryId,
      taskStartDate: selectedTask ? selectedTask.created : null,
      taskDocumentType: selectedTask
        ? selectedTask.typeId
        : defaultFormData.defaultNewTaskDocumentType,
      taskDocumentTypeCompleteBehavior: selectedTask
        ? selectedTask.typeTaskCompleteBehavior
        : defaultFormData.defaultTaskCompleteBehavior,
      taskCapacityCategoryId: selectedTask ? selectedTask.capacityCategoryId : null,
      taskStatusId: selectedTask ? selectedTask.statusId : null,
      taskResponsiblePersonId: selectedTask?.responsiblePersonId,
      workActivityId:
        actions && actions.length > 0
          ? actions[0].name.indexOf('__') === 0
            ? actions[0].id
            : null
          : null,
      workActivityGroupId:
        actions && actions.length > 0
          ? actions[0].name.indexOf('__') === 0
            ? actions[0].workActivityGroupId
            : null
          : null,
      workActivityName:
        actions && actions.length > 0
          ? actions[0].name.indexOf('__') === 0
            ? actions[0].name
            : null
          : null,
      operatorId: operator ? operator.personalNumber : null,
      operatorName: operator ? operator.name : null,
    };

    if (selectedParentAsset) {
      _formData = {
        ..._formData,
        assetId: selectedTask?.assetId,
        topLevelParenId: selectedParentAsset.parentAssetId
          ? selectedParentAsset.parentAssetId
          : selectedParentAsset.id,
      };
    }

    setFormData(_formData);
    setFormDataTask({
      ...formDataTask,
      typeId: selectedTask
        ? selectedTask.typeId
        : formData.defaultNewTaskDocumentType
        ? formData.defaultNewTaskDocumentType
        : undefined,
    });
  };

  /*
   * změna začátku aktivity
   */
  const handleActivityStartedChanged = (data) => {
    if (!formData.activityFinished || !data.value) return;
    const dateActivityFinished =
      String(formData.activityFinished).split('T').length == 2
        ? moment(
            String(formData.activityFinished).split('T')[0] +
              ' ' +
              String(formData.activityFinished).split('T')[1],
          )
        : moment(formData.activityFinished + ':00');

    const dateActivityStarted =
      String(data.value).split('T').length == 2
        ? moment(
            String(data.value).split('T')[0] + ' ' + String(formData.activityStarted).split('T')[1],
          )
        : moment(data.value + ':00');

    setFormData({
      ...formData,
      activityDuration: getDuration(dateActivityStarted, dateActivityFinished),
    });
  };

  /*
   * změna konce aktivity
   */
  const handleActivityFinishedChanged = (data) => {
    if (!formData.activityStarted) return;

    if (!data.value) {
      setFormData({
        ...formData,
        activityFinished: undefined,
      });
      return;
    }

    const dateActivityStarted =
      String(formData.activityStarted).split('T').length == 2
        ? moment(
            String(formData.activityStarted).split('T')[0] +
              ' ' +
              String(formData.activityStarted).split('T')[1],
          )
        : moment(formData.activityStarted + ':00');

    const dateActivityFinished =
      String(data.value).split('T').length == 2
        ? moment(
            String(data.value).split('T')[0] + ' ' + String(formData.activityStarted).split('T')[1],
          )
        : moment(data.value + ':00');

    setFormData({
      ...formData,
      activityDuration: getDuration(dateActivityStarted, dateActivityFinished),
      taskComplete: isTaskCompleteChecked,
    });
  };

  /*
   * změna duration aktivity
   */
  const handleDurationChanged = (data) => {
    if (!formData.activityStarted || !data.value) return;

    const dateActivityStarted =
      String(formData.activityStarted).split('T').length == 2
        ? moment(
            String(formData.activityStarted).split('T')[0] +
              ' ' +
              String(formData.activityStarted).split('T')[1],
          )
        : moment(formData.activityStarted + ':00');

    const aFinish = setActivityFinish(dateActivityStarted, data.value ?? '00:00');

    setFormData({
      ...formData,
      activityFinished: aFinish,
      activityDuration: data.value,
    });
  };

  /*
   * změna kapacitni kategorie
   */
  const handleCapacityCategoryChanged = (data) => {
    //if (!capacityCategories) return;

    const actions = formData.capacityCategoryId
      ? formData.workActivities?.filter((x) => x.capacityCategoryId === formData.capacityCategoryId)
      : formData.workActivities;

    setFormData({
      ...formData,
      capacityCategoryId: data.value,
      workActivityId:
        actions && actions.length > 0
          ? actions[0].name.indexOf('__') === 0
            ? actions[0].id
            : null
          : null,
    });
    setFormDataTask({ ...formDataTask, capacityCategoryId: data.value });
  };

  const handleWorkActivityGroupChanged = (data) => {
    setFormData({
      ...formData,
      workActivityGroupId: data.value,
      workActivityId: undefined,
      workActivityName: undefined,
    });
  };

  /**
   * Změna linky (TopLevelAssetId). Je potřeba profiltrovat seznam assetů podle vybrané linky.
   * Nastavením formData.topLevelParenId se provede přenačtení číselníku assets.
   * @param data: Data z dropDownListu. (TopLevelParentId)
   */
  const handleTopLevelAssetChanged = (data) => {
    setFormData({ ...formData, topLevelParenId: data.value });
    setFormDataTask({ ...formDataTask, assetTopLevelParenId: data.value });
  };

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;

    // Může nastat situace, že není vybrán DocumentType. Kontroluje i BE, ale máme i zde.
    if (!formDataTask.typeId) {
      notify(translate!(`Document type is not selected.`, transFormName), 'error');
      return isOk;
    }

    let submitInterupted = false;
    if (!isEdit && !formData.taskId) {
      const confirmResult = await confirm(
        translate!(
          'No task is selected for the worksheet. Create a new one automatically?',
          'WorkSheet.Create',
        ),
        translate!('Confirm', ''),
      );
      submitInterupted = !confirmResult;
    }
    if (submitInterupted) return isOk;

    setClean();
    if (isEdit) isOk = await submitPut();
    else isOk = await submitPost();

    if (onSubmitDone) onSubmitDone();

    return isOk;
  };

  const willBeActivityTimesChanged = () => {
    if (!formData.task)
      return moment(formData.activityStarted).isBefore(
        moment(formData.tasksByClass.find((x) => x.id === formData.taskId)?.created),
      );
    else return moment(formData.activityStarted).isBefore(moment(formData.task.created));
  };

  const submitTaskPost = async () => {
    let isOk = false;
    startLoading();
    await postApi({
      url: `${API_URL.WORKSHEET_POST_WITH_TASK}?autoFinishOlder=true&assetId=${
        formData.assetId
      }&typeId=${formDataTask.typeId}&capacityCategoryId=${
        formData.capacityCategoryId
      }&priority=${20}&taskName=${formData.assetName}`,
      data: { ...formData, taskId: 0 },
      callAfterSuccess: (data) => {
        isOk = true;
        if (afterSubmitMode === IAfterSubmitMode.NEW) {
          setFormData(defaultFormData);
          return;
        }
        if (afterSubmitMode === IAfterSubmitMode.LIST)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        if (afterSubmitMode === IAfterSubmitMode.CONTINUE)
          history.push({ pathname: `/workSheets/edit/${data.id}`, state: { backRoute } });
      },
    });
    stopLoading();
    return isOk;
  };

  const submitPost = async () => {
    let isOk = false;
    if (!formData.taskId) return submitTaskPost();
    startLoading();
    await postApi({
      //url: API_URL.WORKSHEET_POST,
      url: `${API_URL.WORKSHEET_POST2}?autoFinishOlder=true`,
      data: formData,
      callAfterSuccess: (data) => {
        if (willBeActivityTimesChanged())
          notify(
            `The worksheet was created successfully. The activity time has been adjusted according to the task creation date.`,
            'success',
          );
        isOk = true;
        //openTaskFromTask(formData.taskId);
        if (afterSubmitMode === IAfterSubmitMode.NEW) {
          setFormData(defaultFormData);
          return;
        }
        if (afterSubmitMode === IAfterSubmitMode.LIST)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        if (afterSubmitMode === IAfterSubmitMode.CONTINUE)
          history.push({ pathname: `/workSheets/edit/${data.id}`, state: { backRoute } });
      },
    });
    stopLoading();

    return isOk;
  };

  const submitPut = async () => {
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.WORKSHEET_PUT,
      data: formData,
      callAfterSuccess: async () => {
        isOk = true;
        if (willBeActivityTimesChanged())
          notify(
            translate!(
              `The worksheet was edited successfully. The activity time has been adjusted according to the task creation date.`,
              transFormName,
            ),
            'success',
          );
        if (afterSubmitMode === IAfterSubmitMode.LIST)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
      },
    });
    stopLoading();
    return isOk;
  };

  /**
   * Při úprave formulářových dat upravíme titulky sekcí.
   */
  const handleChangeTitles = () => {
    const redStar = <span style={{ color: '#f51c36', fontWeight: 600 }}>*</span>;
  };

  const filterCapacityCategories = () => {
    if (!formData.capacityCategories || !formData.workSheetOperatorsCapacityCategories) return [];
    if (!restrictWorkActivitiesByCapacityCategoryPerson) return formData.capacityCategories;
    const toReturn = formData.capacityCategories.filter(
      (x) =>
        formData.workSheetOperatorsCapacityCategories.filter(
          (y) => y.personId === formData.operatorId && y.capacityCategoryId === x.id,
        ).length > 0,
    );
    if (
      toReturn.filter((x) => x.id === formData.capacityCategoryId).length === 0 &&
      toReturn.length > 0
    )
      setFormData({ ...formData, capacityCategoryId: toReturn[0].id });

    return toReturn;
  };

  if (
    !formData.workActivities ||
    !formData.operators ||
    !formData ||
    !formData.workSheetOperators ||
    !formData.capacityCategories ||
    !formData.topLevelAssets ||
    (!formData.tasksByClass && !tasksById)
  ) {
    return <Loader />;
  }

  return (
    <>
      <DeleteDialog
        item={formData.taskName ?? ''}
        visibleChange={handleDeleteDialogChange}
        visible={popupVisible}
        translate={translate}
        transFormName={transFormName}
        action={deleteAction}
      />

      <form onSubmit={onSubmit}>
        <Form
          formData={formData}
          colCount={3}
          onFieldDataChanged={(e) => {
            handleChangeTitles();
            if (e.value !== undefined) setDirty();
            setLocalCapacityCategories(filterCapacityCategories());
          }}
          ref={formRef}
        >
          {/* 1. radek */}
          {isTaskNameCombo ? (
            <SimpleItem
              dataField={'topLevelParenId'}
              editorType={'dxSelectBox'}
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'id',
                displayExpr: 'name',
                dataSource: formData.topLevelAssets,
                readOnly: isReadOnly,
                onValueChanged: handleTopLevelAssetChanged,
              }}
            >
              <Label text={translate!('Line', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="topLevelParenName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Line name', transFormName)} />
            </SimpleItem>
          )}

          {isTaskNameCombo ? (
            <SimpleItem
              dataField={'assetId'}
              editorType={'dxSelectBox'}
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'id',
                displayExpr: 'name',
                dataSource: formData.topLevelParenId
                  ? formData.assets.filter((x) => x.topLevelParenId === formData.topLevelParenId)
                  : formData.assets,
                readOnly: isReadOnly,
                onValueChanged: handleAssetChanged,
              }}
            >
              <Label text={translate!('Asset', transFormName)} />
              <RequiredRule message={translate!('Select asset', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="assetName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Asset name', transFormName)} />
            </SimpleItem>
          )}

          {isTaskNameCombo ? (
            <SimpleItem
              dataField="taskId"
              editorType={'dxSelectBox'}
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'id',
                displayExpr: function (data) {
                  return data && data.customId + ' - ' + data.taskName;
                },
                dataSource: formData.assetId
                  ? formData.tasksByClass?.filter((x) => x.assetId === formData.assetId)
                  : formData.tasksByClass,
                readOnly: isReadOnly,
                onValueChanged: handleTaskChanged,
              }}
            >
              <Label text={translate!('Task name', transFormName)} />
              <CustomRule
                validationCallback={(e) => {
                  return formData.defaultNewTaskDocumentType !== -1;
                }}
                type={'custom'}
                message={translate!('Enter task name', transFormName)}
                reevaluate
              />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="taskName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Task name', transFormName)} />
              <RequiredRule message={translate!('Enter task name', transFormName)} />
            </SimpleItem>
          )}

          {!isTaskNameCombo ? (
            <SimpleItem
              dataField="statusNameTranslated"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Status Name', transFormName)} />
            </SimpleItem>
          ) : (
            <></>
          )}

          {!isTaskNameCombo ? (
            <SimpleItem
              dataField="typeNameTranslated"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Document Type Name', transFormName)} />
            </SimpleItem>
          ) : (
            <></>
          )}

          {!isTaskNameCombo ? (
            <SimpleItem
              dataField="responsiblePersonName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Responsible Person Name', transFormName)} />
            </SimpleItem>
          ) : (
            <></>
          )}

          {!isTaskNameCombo ? (
            <EmptyItem colSpan={3} cssClass={'workSheetDivider'}></EmptyItem>
          ) : (
            <></>
          )}
          {/* 2. radek */}
          {isTaskNameCombo ? (
            <SimpleItem
              dataField="operatorId"
              editorType="dxSelectBox"
              // colSpan={3}
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'personId',
                displayExpr: 'personName',
                items: !hasPermissionAllToCreateWorkSheet
                  ? formData.workSheetOperators?.filter(
                      (x) => x.personId === authUser?.personalNumber,
                    )
                  : formData.workSheetOperators,
              }}
            >
              <Label text={translate!('Operator', transFormName)} />
              <RequiredRule message={translate!('Operator required', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="operatorName"
              editorType="dxTextBox"
              // colSpan={3}
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Operator name', transFormName)} />
              <RequiredRule message={translate!('Operator required', transFormName)} />
            </SimpleItem>
          )}
          <EmptyItem />
          <EmptyItem />

          {/* 3. radek */}
          <SimpleItem
            dataField="capacityCategoryId"
            editorType="dxSelectBox"
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              dataSource: localCapacityCategories,
              readOnly: isReadOnly,
              onValueChanged: handleCapacityCategoryChanged,
            }}
          >
            <Label text={translate!('Capacity Category Name', transFormName)} />
            <RequiredRule message={translate!('Select capacity category', transFormName)} />
          </SimpleItem>

          <SimpleItem
            dataField="workActivityGroupId"
            editorType="dxSelectBox"
            editorOptions={{
              ...dxSelectBoxConfig,
              valueExpr: 'id',
              displayExpr: 'name',
              items: formData.capacityCategoryId
                ? workActivityGroups?.filter(
                    (x) => x.capacityCategoryId === formData.capacityCategoryId,
                  )
                : workActivityGroups,
              onValueChanged: handleWorkActivityGroupChanged,
            }}
          >
            <Label text={translate!('Activity group', transFormName)} />
            <RequiredRule message={translate!('Activity group required', transFormName)} />
          </SimpleItem>
          {isTaskNameCombo || true ? (
            <SimpleItem
              dataField="workActivityId"
              editorType="dxSelectBox"
              editorOptions={{
                ...dxSelectBoxConfig,
                valueExpr: 'id',
                displayExpr: 'name',
                items: formData.workActivityGroupId
                  ? formData.workActivities?.filter(
                      (x) => x.workActivityGroupId === formData.workActivityGroupId,
                    )
                  : [],
              }}
            >
              <Label text={translate!('Activity', transFormName)} />
              <RequiredRule message={translate!('Activity required', transFormName)} />
            </SimpleItem>
          ) : (
            <SimpleItem
              dataField="workActivityName"
              editorType="dxTextBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text={translate!('Activity name', transFormName)} />
              <RequiredRule message={translate!('Activity required', transFormName)} />
            </SimpleItem>
          )}

          {/* zbytek radku */}
          <SimpleItem
            dataField="activityStarted"
            editorType="dxDateBox"
            editorOptions={{
              ...dateTimeFieldOptionsWithSerializationAndInterval,
              readOnly: !editFlag(),
              // 21.3.2022 - po domluvě s Dušanem, nebudeme dávat žádná omezení, když není vyplněný task.
              // Pokud je task vyplněn, zobrazuejem defaultně now, ale zamykáme dny do minulosti od TaskCreate
              disabledDates: (args) => {
                if (!formData.taskId) return false;
                // 04.09.2022 - nově nejdeme do minulosti od TaskCreate, ale min(TaskCreate, Task.Started)
                let dateToCompare = formData.taskCreated;
                if (
                  formData.task &&
                  formData.task.started &&
                  moment(formData.task.started).isBefore(moment(formData.taskCreated))
                )
                  dateToCompare = formData.task.started;

                return moment(args.date).isBefore(moment(dateToCompare).startOf('day'));
              },
              onValueChanged: handleActivityStartedChanged,
            }}
          >
            <Label text={translate!('Activity started', transFormName)} />
            <RequiredRule message={translate!('Activity started required', transFormName)} />
            <CustomRule
              validationCallback={(e) => {
                // Editace - chceme dovolit již vybraný datum
                if (moment(e.value).isSame(oldActivityStarted)) return true;
                // Pokud není vybraný task, dovolujeme cokoliv. Domluva s Dušanem 21.3.2022
                if (!formData.taskId) return true;
                else {
                  // 04.09.2022 - nově nejdeme do minulosti od TaskCreate, ale min(TaskCreate, Task.Started)
                  let dateToCompare = formData.taskCreated;
                  if (
                    formData.task &&
                    formData.task.started &&
                    moment(formData.task.started).isBefore(moment(formData.taskCreated))
                  )
                    dateToCompare = formData.task.started;

                  return moment(e.value).endOf('minute').isSameOrAfter(moment(dateToCompare));
                }
              }}
              type={'custom'}
              message={translate!(
                'Date is invalid, must be greater then now or greater than task create date',
                transFormName,
              )}
              ignoreEmptyValue
            />
          </SimpleItem>

          <SimpleItem
            dataField="activityFinished"
            editorType="dxDateBox"
            editorOptions={{
              ...dateTimeFieldOptionsWithSerializationAndInterval,
              readOnly: !editFlag(),
              disabledDates: (args) => {
                return moment(args.date).isAfter(today.clone().endOf('day'));
              },
              onValueChanged: handleActivityFinishedChanged,
            }}
          >
            <Label text={translate!('Activity finished', transFormName)} />
            <CustomRule
              validationCallback={(e) => {
                if (moment(e.value).isSame(oldActivityFinished)) return true;
                return (
                  moment(e.value).startOf('minute').isSameOrBefore(moment()) &&
                  moment(e.value).isSameOrAfter(moment(formData.activityStarted))
                );
              }}
              type={'custom'}
              message={translate!(
                'Date is invalid, must be lower then now and can not be before activity start',
                transFormName,
              )}
              ignoreEmptyValue
            />
          </SimpleItem>

          <SimpleItem
            cssClass={'input-with-custom-label'}
            dataField="activityDuration"
            editorType="dxDateBox"
            editorOptions={{
              ...timeFieldOptionsWithSerializationWithInterval,
              readOnly: !editFlag(),
              onValueChanged: (e) => {
                handleDurationChanged(e);
              },
            }}
          >
            <Label text={translate!('Duration', transFormName)} />
          </SimpleItem>

          <SimpleItem
            colSpan={3}
            dataField="description"
            editorType="dxTextArea"
            editorOptions={{
              readOnly: !editFlag(),
              height: 100,
            }}
          >
            <Label text={translate!('Description', transFormName)} />
          </SimpleItem>

          {isTaskCompleteVisible && (
            <SimpleItem colSpan={3} dataField="taskComplete" editorType="dxCheckBox">
              <Label text={translate!('Task complete', transFormName)} />
            </SimpleItem>
          )}

          {isEdit ? (
            !isReadOnly ? (
              <GroupItem colSpan={3} colCount={3}>
                <GroupItem colSpan={1} colCount={3} cssClass={'desktop-save-edit-buttons'}>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={'btn-desktop-save-edit-buttons'}
                  >
                    <ButtonOptions
                      text={translate!('Close', transFormName)}
                      type={'normal'}
                      width={'100%'}
                      onClick={() =>
                        history.push({
                          pathname: backRoute,
                          state: { loadSettingFromSession: true },
                        })
                      }
                    />
                  </ButtonItem>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={'btn-desktop-save-edit-buttons'}
                  >
                    <ButtonOptions
                      width={'100%'}
                      type={'success'}
                      text={translate!('Save', transFormName)}
                      disabled={loading}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          workSheetStatusId: formData.tmpWorkSheetStatusId ?? 10,
                        });
                        setAfterSubmitMode(IAfterSubmitMode.LIST);
                      }}
                      useSubmitBehavior
                      visible={/* true || isDirty */ editFlag()}
                    />
                  </ButtonItem>
                  <ButtonItem
                    horizontalAlignment={'left'}
                    cssClass={'btn-desktop-save-edit-buttons'}
                  >
                    <ButtonOptions
                      width={'100%'}
                      type={'danger'}
                      disabled={loading}
                      text={translate!('Delete', transFormName)}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          workSheetStatusId: 0,
                          tmpWorkSheetStatusId: formData.workSheetStatusId,
                        });
                        setAfterSubmitMode(IAfterSubmitMode.LIST);

                        handleDeleteDialogChange(true);
                      }}
                      visible={editFlag()}
                    />
                  </ButtonItem>
                </GroupItem>
                <GroupItem colSpan={2}></GroupItem>
              </GroupItem>
            ) : (
              <GroupItem></GroupItem>
            )
          ) : (
            <GroupItem colCount={4} colSpan={2} cssClass={'desktop-save-edit-buttons'}>
              <ButtonItem horizontalAlignment={'left'} cssClass={'btn-desktop-save-edit-buttons'}>
                <ButtonOptions
                  text={translate!('Close', transFormName)}
                  type={'normal'}
                  width={'100%'}
                  onClick={() =>
                    history.push({
                      pathname: backRoute,
                      state: { loadSettingFromSession: true },
                    })
                  }
                />
              </ButtonItem>
              <ButtonItem horizontalAlignment={'left'} cssClass={'btn-desktop-save-edit-buttons'}>
                <ButtonOptions
                  width={'100%'}
                  type={'success'}
                  disabled={loading}
                  text={translate!('Save and continue', transFormName)}
                  useSubmitBehavior
                  onClick={() => setAfterSubmitMode(IAfterSubmitMode.CONTINUE)}
                  visible={false && isDirty}
                />
              </ButtonItem>
              <ButtonItem horizontalAlignment={'left'} cssClass={'btn-desktop-save-edit-buttons'}>
                <ButtonOptions
                  width={'100%'}
                  type={'success'}
                  text={translate!('Save', transFormName)}
                  disabled={loading}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      workSheetStatusId: formData.tmpWorkSheetStatusId ?? 10,
                    });
                    setAfterSubmitMode(IAfterSubmitMode.LIST);
                  }}
                  useSubmitBehavior
                  visible={true || isDirty}
                />
              </ButtonItem>
            </GroupItem>
          )}
        </Form>
      </form>
      {prompt}
    </>
  );
}
