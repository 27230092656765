import { useEffect, useState } from 'react';
import { TaskStatus } from '../types/TaskStatus';

interface Props {
  taskId: number | undefined;
  taskStatusId: number | null | undefined;
  taskDocumentType: number | null | undefined;
  taskDocumentTypeCompleteBehavior: number | null | undefined;
  taskResponsiblePersonId: number | null | undefined;
  operatorId: number | undefined;
  activityFinished: Date | null | string | undefined;
  taskProcessConfigList: TaskStatus[];
}

export const useTaskCompleteCheck = ({
  taskId,
  taskStatusId,
  taskDocumentType,
  taskDocumentTypeCompleteBehavior,
  taskResponsiblePersonId,
  operatorId,
  activityFinished,
  taskProcessConfigList,
}: Props) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    // Pokud není vyplněn datum dokončení výkazu, nikdy nechceme dát možnost ukončit výkaz
    if (!activityFinished) {
      setVisible(false);
      return;
    }

    // Není vybraný task. Checkbox můžeme zobrazit.
    if (!taskId) {
      setVisible(true);
      return;
    }

    // Je vybraný task. Podíváme se, jestli je možné task ukončit tzn. existuje actionId = 14.
    if (!taskProcessConfigList || taskProcessConfigList.length === 0) {
      setVisible(false);
      return;
    }

    if (!!taskId && !!taskProcessConfigList) {
      const isCompleteAction =
        taskProcessConfigList.filter(
          (x) =>
            x.documentTypeId === taskDocumentType &&
            x.statusFromId === taskStatusId &&
            x.actionId === 14,
        ).length > 0;
      setVisible(isCompleteAction);
      return;
    }

    setVisible(false);
  }, [
    taskId,
    taskStatusId,
    taskDocumentType,
    activityFinished,
    taskProcessConfigList,
    taskResponsiblePersonId,
    operatorId,
  ]);

  useEffect(() => {        
    console.log('%c Použitý DocumentType: ', 'background: #222; color: #bada55', taskDocumentType);
    console.log('%c Použitý WorksheetTaskCompleteBehavior: ', 'background: #222; color: #bada55', taskDocumentTypeCompleteBehavior);

    // Pokud není checkbox vidět, musí být nastaven na false
    if (!isVisible) {
      setChecked(false);
      return;
    }

    // Checkbox je vidět, pokud je nastavení v DB = 0 => Bude vždy nezaškrtnuto
    if(taskDocumentTypeCompleteBehavior === 0) {
      console.log('%c Nastaveno na nezaškrtnuto', 'background: #222; color: #bada55');
      setChecked(false);
      return;
    }

    // Checkbox je vidět, pokud je nastavení v DB = 2 => Bude vždy zaškrtnuto
    if(taskDocumentTypeCompleteBehavior === 2) {
      console.log('%c Nastaveno na zaškrtnuto', 'background: #222; color: #bada55');
      setChecked(true);
      return;
    }

    console.log('%c Původní chování', 'background: #222; color: #bada55');

    // 19.12.2022: Checkbox je vidět, pokud je nastavení v DB = 1 => zůstává původní nastavení tj. následující kód
    // Pokud není vybrán task, chceme defaultně checknout
    if (!taskId) {
      setChecked(true);
      return;
    }

    // Task je vybrán. Checkneme pouze pokud se zodpovědná osoba tasku rovná operátorovi výkazu
    if (taskResponsiblePersonId === operatorId) {
      setChecked(true);
      return;
    }

    setChecked(false);
  }, [isVisible]);

  return { isVisible, isChecked };
};
