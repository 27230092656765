import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabCycleStepsColumns';
import { Step } from '../../types/step.types';
import { StepGroup } from '../../types/stepGroup.types';
import AssetCycleStepGroupModal from '../modals/AssetCycleStepGroupModal';
import AssetCycleStepGroupModalDate from '../modals/AssetCycleStepGroupModalDate';
import AssetCycleStepModal from '../modals/AssetCycleStepModal';
import AssetCycleStepModalDate from '../modals/AssetCycleStepModalDate';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export interface IAssetTabCycleStepsDataGrid extends IDataGrid {
  stepList?: Step[];
  stepGroupList?: StepGroup[];
  isReadonly: boolean;
  handleClose: () => void;
  modalVisibilityStep: boolean;
  modalVisibilityStepGroup: boolean;
  selectedData: any;
  selectedAction: any;
  isCycleMonthly?: boolean;
  handleDelete: (e) => void;
  onDataSubmit: (data: any, actionType: any) => void;
  assetCycleId: number;
}

export const AssetTabCycleStepsDataGrid = ({
  isReadonly,
  data,
  stepList,
  stepGroupList,
  onToolbarPreparing,
  handleEdit,
  handleClose,
  handleDelete,
  modalVisibilityStep,
  modalVisibilityStepGroup,
  selectedData,
  selectedAction,
  onDataSubmit,
  isCycleMonthly,
  assetCycleId,
}: IAssetTabCycleStepsDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.CycleStep';
  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);

  if (!data) return null;
  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(handleEdit, handleDelete, isReadonly, translate, isCycleMonthly)}
        onToolbarPreparing={onToolbarPreparing}
        rowAlterantionEnabled
        columnChooser={false}
        allowColumnResizing={false}
        allowColumnReordering={false}
        prompt={Prompt}
        enableGrouping
        disableGroupPanel
      />
      {modalVisibilityStep && !isCycleMonthly && (
        <AssetCycleStepModal
          stepList={stepList}
          isVisible={modalVisibilityStep}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={'Asset.CycleStep.StepModal'}
          onSubmit={onDataSubmit}
          onClose={handleClose}
        />
      )}
      {modalVisibilityStep && isCycleMonthly && (
        <AssetCycleStepModalDate
          stepList={stepList}
          isVisible={modalVisibilityStep}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={'Asset.CycleStep.StepModal'}
          onSubmit={onDataSubmit}
          onClose={handleClose}
          assetCycleId={assetCycleId}
        />
      )}
      {modalVisibilityStepGroup && !isCycleMonthly && (
        <AssetCycleStepGroupModal
          stepGroupList={stepGroupList}
          isVisible={modalVisibilityStepGroup}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={'Asset.CycleStep.StepGroupModal'}
          onSubmit={onDataSubmit}
          onClose={handleClose}
        />
      )}
      {modalVisibilityStepGroup && isCycleMonthly && (
        <AssetCycleStepGroupModalDate
          stepGroupList={stepGroupList}
          isVisible={modalVisibilityStepGroup}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={'Asset.CycleStep.StepGroupModal'}
          onSubmit={onDataSubmit}
          onClose={handleClose}
          assetCycleId={assetCycleId}
        />
      )}
    </>
  );
};
