import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { BaseValueDataType } from '../../types/parameter.types';
import { dateFieldOptions, dateTimeFieldOptions } from '../../utils/dates.types';
import { validationNullableDateRule } from '../../utils/dateValidation';
import './EditCreateModal.scss';
import './TasksBulkEditModal.scss';
type Props = {
  data: any;
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
  transFormName: string;
  parameters: any;
  parameterChanged: any;
  units: any;
  selectedUnitId?: number;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Asset parameter measured v datagridu
 */
export default function AssetParameterMeasuredModal({
  isVisible,
  data,
  actionType,
  onClose,
  onSubmit,
  parameters,
  parameterChanged,
  units,
  selectedUnitId,
  transFormName,
}: Props) {
  const { translate } = useTranslation();
  const formRef = useRef<Form>(null);
  const [selectedUnit, setSelectedUnit] = useState<string>();
  const [formData, setFormData] = useState<any>({
    ...data,
    measureToDate: new Date(),
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });

  useEffect(() => {
    setFormData({
      ...data,
      measureToDate: new Date(),
      ...(actionType === DataGridActionType.Insert && { visible: true }),
    });
  }, []);

  useEffect(() => {
    if (!data) return;
    setUnitType(data.unitId);
  }, [data]);

  useEffect(() => {
    if (!selectedUnitId) return;
    setUnitType(selectedUnitId);
    setFormData({ ...formData, measureToDate: new Date(), unitId: selectedUnitId });
  }, [selectedUnitId]);

  const setUnitType = (selectedId: number) => {
    let tmpUnit = units.find((u: any) => u.id === selectedId);
    if (tmpUnit) setSelectedUnit(tmpUnit.baseValueDataType);
  };

  const handleCloseModal = () => {
    setSelectedUnit(undefined);
    onClose();
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={handleCloseModal}
      showTitle
      title={translate!('Asset parameters measured', transFormName)}
      width={'20%'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem visible={actionType === DataGridActionType.Insert}>
            <SimpleItem
              dataField="assetParameterId"
              editorType="dxSelectBox"
              editorOptions={{
                items: parameters,
                valueExpr: 'id',
                displayExpr: 'parameterName',
                onValueChanged: (val) => parameterChanged(val),
              }}
            >
              <Label text={translate!('Parameter', transFormName)} />
              <RequiredRule message={translate!('Parameter is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={actionType === DataGridActionType.Insert}>
            <SimpleItem
              dataField="unitId"
              editorType="dxSelectBox"
              editorOptions={{
                readOnly: true,
                items: units,
                valueExpr: 'id',
                displayExpr: 'name',
              }}
            >
              <Label text={translate!('Unit', transFormName)} />
            </SimpleItem>
          </GroupItem>
          {/* Value options */}
          <GroupItem visible={selectedUnit === BaseValueDataType.Bool}>
            <SimpleItem
              dataField="valueBool"
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  { id: true, name: translate!('True', transFormName) },
                  { id: false, name: translate!('False', transFormName) },
                ],
                valueExpr: 'id',
                displayExpr: 'name',
              }}
            >
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Int}>
            <SimpleItem dataField="valueInt" editorType="dxNumberBox">
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Float}>
            <SimpleItem dataField="valueFloat" editorType="dxNumberBox">
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Date}>
            <SimpleItem
              dataField="valueDate"
              editorType="dxDateBox"
              editorOptions={{
                ...dateFieldOptions,
              }}
            >
              <CustomRule
                validationCallback={validationNullableDateRule}
                type={'custom'}
                message={'Somethind bad with time'}
                ignoreEmptyValue
              />
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.DateTime}>
            <SimpleItem
              dataField="valueDate"
              editorType="dxDateBox"
              editorOptions={{
                ...dateTimeFieldOptions,
              }}
            >
              <CustomRule
                validationCallback={validationNullableDateRule}
                type={'custom'}
                message={'Somethind bad with time'}
                ignoreEmptyValue
              />
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem
            visible={selectedUnit === undefined || selectedUnit === BaseValueDataType.Text}
          >
            <SimpleItem dataField="valueText" editorType="dxTextBox">
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>

          <GroupItem>
            <SimpleItem
              dataField="measureToDate"
              editorType="dxDateBox"
              editorOptions={{
                ...dateFieldOptions,
                // ...deliveredWhenProps,
                max: new Date(),
              }}
            >
              <CustomRule
                validationCallback={validationNullableDateRule}
                type={'custom'}
                message={'Somethind bad with time'}
                ignoreEmptyValue
              />
              <Label text={translate!('Measure to date', transFormName)} />
              <RequiredRule message={translate!('Measure to date is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="visible" editorType="dxCheckBox">
              <Label text={translate!('Visible', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions type={'success'} text={translate!('Save', '')} useSubmitBehavior />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions type={'normal'} text={translate!('Close', '')} onClick={onClose} />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
