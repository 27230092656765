import moment from 'moment';

export const DateFilter = {
  PreviousDayStart: {
    // včera - začátek dne
    name: 'Previous day',
    value: 'previous.day.start',
  },
  PreviousDayEnd: {
    // včera - konec dne
    name: 'Previous day',
    value: 'previous.day.end',
  },
  CurrentDay: {
    // dnes
    name: 'Current day',
    value: 'current.day',
  },
  CurrentDayStart: {
    // dnes - začátek dne
    name: 'Current day',
    value: 'current.day.start',
  },
  CurrentDayEnd: {
    // dnes - konec dne
    name: 'Current day',
    value: 'current.day.end',
  },
  NextDayStart: {
    // zítra - začátek dne
    name: 'Next day',
    value: 'next.day.start',
  },
  NextDayEnd: {
    // zítra - konec dne
    name: 'Next day',
    value: 'next.day.end',
  },
  PreviousWeekStart: {
    // začátek předchozího týdne - začátek dne
    name: 'Last week',
    value: 'previous.week.start',
  },
  PreviousWeekEnd: {
    // začátek předchozího týdne - konec dne
    name: 'Last week',
    value: 'previous.week.end',
  },
  CurrentWeekStart: {
    // začátek aktuálního týdne
    name: 'Current week',
    value: 'current.week.start',
  },
  CurrentWeekEnd: {
    // konec aktuálního týdne
    name: 'Current week',
    value: 'current.week.end',
  },
  FollowingWeekStart: {
    // začátek následujícího týdne - začátek dne
    name: 'Next week',
    value: 'following.week.start',
  },
  FollowingWeekEnd: {
    // začátek následujícího týdne - konec dne
    name: 'Next week',
    value: 'following.week.end',
  },
  PreviousMonthStart: {
    // začátek předchozího měsíce - začátek dne
    name: 'Last month',
    value: 'previous.month.start',
  },
  PreviousMonthEnd: {
    // začátek předchozího měsíce - konec dne
    name: 'Last month',
    value: 'previous.month.end',
  },
  CurrentMonthStart: {
    // začátek aktuálního měsíce
    name: 'Current month',
    value: 'current.month.start',
  },
  CurrentMonthEnd: {
    // konec aktuálního měsíce
    name: 'Current month',
    value: 'current.month.end',
  },
  FollowingMonthStart: {
    // začátek následujícího měsíce - začátek dne
    name: 'Next month',
    value: 'following.month.start',
  },
  FollowingMonthEnd: {
    // začátek následujícího měsíce - konec dne
    name: 'Next month',
    value: 'following.month.end',
  },
  PreviousYearStart: {
    // začátek předchozího roku
    name: 'Last year',
    value: 'previous.year.start',
  },
  PreviousYearEnd: {
    // konec předchozího roku
    name: 'Last year',
    value: 'previous.year.end',
  },
  CurrentYearStart: {
    // začátek aktuálního roku
    name: 'Current year',
    value: 'current.year.start',
  },
  CurrentYearEnd: {
    // začátek aktuálního roku
    name: 'Current year',
    value: 'current.year.end',
  },
  FollowingYearStart: {
    // začátek následujícího roku - začátek dne
    name: 'Next year',
    value: 'following.year.start',
  },
  FollowingYearEnd: {
    // začátek následujícího roku - konec dne
    name: 'Next year',
    value: 'following.year.end',
  },
} as const;

export enum WeekStartDay {
  SUNDAY,
  MONDAY,
}

export const getFilterDate = (value, diff = 0, weekStart: WeekStartDay = WeekStartDay.MONDAY) => {
  if (!value) return value;
  const now = moment();
  let result;
  switch (value) {
    case DateFilter.PreviousDayStart.value:
      result = now.subtract(1, 'day').startOf('day').toDate();
      break;
    case DateFilter.PreviousDayEnd.value:
      result = now.subtract(1, 'day').endOf('day').toDate();
      break;
    case DateFilter.CurrentDay.value:
    case DateFilter.CurrentDayStart.value:
      result = now.startOf('day').toDate();
      break;
    case DateFilter.CurrentDayEnd.value:
      result = now.endOf('day').toDate();
      break;
    case DateFilter.NextDayStart.value:
      result = now.add(1, 'day').startOf('day').toDate();
      break;
    case DateFilter.NextDayEnd.value:
      result = now.add(1, 'day').endOf('day').toDate();
      break;
    case DateFilter.PreviousWeekStart.value:
      result = now
        .day(weekStart - 7)
        .startOf('day')
        .toDate();
      break;
    case DateFilter.PreviousWeekEnd.value:
      result = now
        .day(weekStart - 7)
        .endOf('week')
        .add(1, 'day')
        .endOf('day')
        .toDate();
      break;
    case DateFilter.CurrentWeekStart.value:
      result = now.day(weekStart).startOf('day').toDate();
      break;
    case DateFilter.CurrentWeekEnd.value:
      result = now.endOf('week').add(weekStart).endOf('day').toDate();
      break;
    case DateFilter.FollowingWeekStart.value:
      result = now
        .day(weekStart + 7)
        .startOf('day')
        .toDate();
      break;
    case DateFilter.FollowingWeekEnd.value:
      result = now
        .day(weekStart + 7)
        .endOf('week')
        .add(1, 'day')
        .endOf('day')
        .toDate();
      break;
    case DateFilter.PreviousMonthStart.value:
      result = now
        .month(now.month() - 1)
        .date(1)
        .startOf('day')
        .toDate();
      break;
    case DateFilter.PreviousMonthEnd.value:
      result = now
        .month(now.month() - 1)
        .endOf('month')
        .endOf('day')
        .toDate();
      break;
    case DateFilter.CurrentMonthStart.value:
      result = now.startOf('month').toDate();
      break;
    case DateFilter.CurrentMonthEnd.value:
      result = now.endOf('month').toDate();
      break;
    case DateFilter.FollowingMonthStart.value:
      result = now
        .month(now.month() + 1)
        .date(1)
        .startOf('day')
        .toDate();
      break;
    case DateFilter.FollowingMonthEnd.value:
      result = now
        .month(now.month() + 1)
        .endOf('month')
        .endOf('day')
        .toDate();
      break;
    case DateFilter.PreviousYearStart.value:
      result = now
        .year(now.year() - 1)
        .startOf('year')
        .toDate();
      break;
    case DateFilter.PreviousYearEnd.value:
      result = now
        .year(now.year() - 1)
        .endOf('year')
        .endOf('day')
        .toDate();
      break;
    case DateFilter.CurrentYearStart.value:
      result = now.date(weekStart).startOf('day').toDate();
      break;
    case DateFilter.CurrentYearEnd.value:
      result = now.endOf('year').toDate();
      break;
    case DateFilter.FollowingYearStart.value:
      result = now
        .year(now.year() + 1)
        .month(0)
        .date(1)
        .startOf('day')
        .toDate();
      break;
    case DateFilter.FollowingYearEnd.value:
      result = now
        .year(now.year() + 1)
        .endOf('year')
        .endOf('day')
        .toDate();
      break;
    default:
      result = moment(value).toDate();
      break;
  }
  return moment(result).add(diff, 'd').toDate();
};
