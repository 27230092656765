import Form, {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  Label,
  SimpleItem,
} from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { IForm } from '../../types/iForm';
import { formTranslationByEdit } from '../../utils/formTranslationByEdit';

export interface IAssetClassParametersForm extends IForm {
  dataAssetClasses?: any;
  dataParameters?: any;
}
export const AssetClassParametersForm = ({
  onSubmit,
  formData,
  dataAssetClasses,
  dataParameters,
  isEdit,
}: IAssetClassParametersForm) => {
  const { translate } = useTranslation();
  const transFormName = formTranslationByEdit('AssetClassParameter', isEdit);
  const history = useHistory();
  const backRoute = '/asset-class-parameters';
  const formRef = useRef<Form>(null);
  const { loading } = useLoading();
  const [prompt, setDirty, setClean, handleCloseButton] = useUnsavedChangesWarning(
    backRoute,
    () => {
      if (!formRef) return;
      if (formRef.current!.instance.validate().isValid) {
        setClean();
        onSubmit(null).then((result) => {
          if (result) history.push(backRoute);
          else setDirty();
        });
      }
    },
  );

  return (
    <form
      onSubmit={(e) => {
        onSubmit(e);
        if (formRef && formRef.current && !isEdit) formRef.current?.instance.resetValues();
        // @ts-ignore
        setClean();
      }}
    >
      <Form
        colCount={1}
        formData={formData}
        onFieldDataChanged={() => {
          // @ts-ignore
          setDirty();
        }}
        showValidationSummary={true}
        ref={formRef}
      >
        <GroupItem>
          <SimpleItem
            dataField="assetClassId"
            editorType="dxSelectBox"
            editorOptions={{
              items: dataAssetClasses,
              valueExpr: 'id',
              displayExpr: 'nameTranslated',
            }}
          >
            <Label text={translate!('Asset class', transFormName)} />
            <RequiredRule message={translate!('Select asset class', transFormName)} />
          </SimpleItem>
          <SimpleItem
            dataField="parameterId"
            editorType="dxSelectBox"
            editorOptions={{
              items: dataParameters,
              valueExpr: 'id',
              displayExpr: 'name',
            }}
          >
            <Label text={translate!('Parameter', transFormName)} />
            <RequiredRule message={translate!('Select parameter', transFormName)} />
          </SimpleItem>
        </GroupItem>

        <GroupItem colCount={14}>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              text={translate!('Close', 'Asset.Update')}
              type={'normal'}
              width={'100%'}
              useSubmitBehavior={false}
              onClick={handleCloseButton}
            />
          </ButtonItem>
          <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
            <ButtonOptions
              text={translate!('Save', transFormName)}
              type={'success'}
              width={'100%'}
              useSubmitBehavior
              disabled={loading}
            />
          </ButtonItem>
        </GroupItem>
        {prompt}
      </Form>
    </form>
  );
};
