export interface ChartConfig {
  enabled: boolean;
  reportPosition: string;
  title?: string;
  subtitle?: string;
  width: number;
  height: number;
  rotated: boolean;
  legend: LegendProps;
  series: SeriesProps[];
  argumentAxis: ArgumentAxisProps;
  valueAxis: ValueAxisProps[];
  export: ExportProps;
}

export interface SeriesProps {
  valueField: string;
  argumentField: string;
  name: string;
  type: string;
  color: string;
  label: SeriesLabelProps;
  axis: string;
}

export interface SeriesLabelProps {
  visible?: boolean;
  backgroundColor: string;
}

export interface ArgumentAxisProps {
  title: string;
  overlappingBehavior: 'none' | 'none' | 'none' | 'none';
}

export interface ValueAxisProps {
  name: string;
  title: string;
  position: 'right' | 'left' | 'bottom' | 'top';
}

export interface LegendProps {
  visible: boolean;
  position: 'inside' | 'outside';
  horizontalAlignment: 'left' | 'center' | 'right';
  verticalAlignment: 'top' | 'bottom';
}

export interface ExportProps {
  enabled: boolean;
  printingEnabled: boolean;
}

export const ReportPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};
