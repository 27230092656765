import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/StepGroup/ColumnsStepsGroupStep';
import { Step } from '../../types/step.types';
import { StepGroupStep } from '../../types/stepGroup.types';
import StepGroupStepModal from '../modals/StepGroupStepModal';
import { SisDataGrid } from '../table';

interface Props {
  data: StepGroupStep[] | undefined;
  handleRemove: (id: number) => void;
  stepList?: Step[];
  onSubmit: (formData: any) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const StepGroupStepDataGrid = ({
  data,
  handleRemove,
  stepList,
  onSubmit,
  isVisible,
  setIsVisible,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'StepGroup.List';

  const columns = Columns(handleRemove, translate);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Assigned steps', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => setIsVisible(true),
        },
      },
    );
  };

  return (
    <div style={{ marginTop: 15 }}>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={columns}
        allowHeaderFiltering
        columnChooser={false}
        onToolbarPreparing={onToolbarPreparing}
      />
      {stepList && (
        <StepGroupStepModal
          isVisible={isVisible}
          stepList={stepList}
          onSubmit={onSubmit}
          onClose={() => setIsVisible(false)}
        />
      )}
    </div>
  );
};
