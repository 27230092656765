import moment from 'moment';
import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';

export const Columns = (
  handleEdit,
  isReadOnly,
  translate,
  dateFormat,
  dateTimeFormat,
): SisDataGridColumns => [
  {
    dataField: 'id',
    caption: 'Cycle parameter id',
    width: '6%',
  },
  {
    dataField: 'assetParameterName',
    caption: 'Parameter',
    width: '22%',
  },
  {
    dataField: 'unitId',
    caption: 'Unit',
    width: '17%',
    cellRender: (data) => data.row.data.unitName,
    dataType: IDataType.string,
  },
  {
    dataField: 'value',
    caption: 'Current value',
    dataType: IDataType.string,
    cellRender: (data) => {
      if (data.row.data.assetParameterValueInt) return data.row.data.assetParameterValueInt;
      if (data.row.data.assetParameterValueFloat) return data.row.data.assetParameterValueFloat;
      if (data.row.data.assetParameterValueDate)
        return moment(data.row.data.assetParameterValueDate).format(dateFormat());
      if (data.row.data.assetParameterValueDateTime)
        return moment(data.row.data.assetParameterValueDateTime).format(dateTimeFormat());
      if (data.row.data.assetParameterValueText) return data.row.data.assetParameterValueText;
      if (data.row.data.unitBaseValueDataType === 'Bool') {
        return data.row.data.assetParameterValue ? 'True' : 'False';
      }
      return null;
    },
    width: '10%',
  },
  {
    dataField: 'period',
    dataType: IDataType.number,
    caption: 'Period',
    width: '10%',
  },
  {
    dataField: 'periodToleranceMinus',
    dataType: IDataType.number,
    caption: 'Tol. minus',
    width: '10%',
  },
  {
    dataField: 'periodTolerancePlus',
    dataType: IDataType.number,
    caption: 'Tol. plus',
    width: '10%',
  },
  {
    dataField: 'threshold',
    dataType: IDataType.number,
    caption: 'Threshold',
    cellRender: (data) => {
      if (data.row.data.threshold) return data.row.data.threshold;
      if (data.row.data.thresholdDate)
        return moment(data.row.data.thresholdDate).format(dateFormat());
      if (data.row.data.thresholdDateTime)
        return moment(data.row.data.thresholdDateTime).format(dateTimeFormat());
      return null;
    },
    width: '11%',
  },
  {
    dataField: 'visible',
    caption: 'Visible',
    dataType: IDataType.boolean,
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, isReadOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, readOnly, translate) => {
  return (
    !readOnly && (
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={() => handleEdit(cellData)}
      />
    )
  );
};
