import { Popup, ScrollView } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { swrConfig } from '../../pages/Assets/AssetsEdit';
import { TaskChangeStatus } from '../../pages/Tasks/TaskChangeStatus';
import { paintRowByStatus } from '../../pages/Tasks/utils/paintRowByStatus';
import { Person } from '../../types/person.types';
import { Task } from '../../types/task.types';
import { TaskActionTypes } from '../../types/taskActionTypes';
import { TaskRatings } from '../../types/taskRating.types';
import { TaskStatus, TaskStatusApiResponse } from '../../types/TaskStatus';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { SisDataGrid } from '../table';
import './TasksBulkEditModal.scss';

type Props = {
  data: Array<any>;
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  columns: Array<any>;
  transFormNameColumns: string;
};

export type RowsStatus = {
  successArray: number[];
  failedArray: number[];
};

/**
 * Modalová komponent pro hromadné změny ve vybraných řádcích z datagridu
 */
export default function BulkEditModal({
  data,
  isVisible,
  mutate,
  onClose,
  columns,
  transFormNameColumns,
}: Props) {
  const [rowsStatus, setRowsStatus] = useState<RowsStatus>({ successArray: [], failedArray: [] });
  const [hideAcceptBtn, setHideAcceptBtn] = useState<boolean>(false);
  const { translate } = useTranslation();
  const transFormName = 'Bulk.Change';

  /**
   * Načte zodpovědné osoby pro task action. Pouze v editaci.
   */
  const { data: taskActionRespPersons } = useSWR<Person[]>(
    API_URL.CAPACITY_CATEGORY_PERSON_LIST_UNIQUE,
    fetcher,
    swrConfig,
  );

  /**
   * Načteme stavy pro akční tlačítka. Pouze v editaci.
   */
  const { data: statuses, mutate: mutateStatuses } = useSWR<TaskStatusApiResponse>(
    data && isVisible
      ? `${API_URL.TASK_PROCESS_CONFIG_ARRAY}?taskIdArr=${data.map((task) => task.id).join(';')}`
      : null,
    fetcher,
    swrConfig,
  );

  // Načtení rating ikon
  const { data: taskRatings } = useSWR<TaskRatings>(
    statuses && statuses?.find((s: any) => s?.requireRating === true)
      ? `${API_URL.TASK_RATING_LIST}?visibleOnly=true`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (result) => {
        let sortedArr = result.sort((a: any, b: any) =>
          a.rating > b.rating ? 1 : b.rating > a.rating ? -1 : 0,
        );
        return sortedArr;
      },
    },
  );

  useEffect(() => {
    if (
      !data ||
      !statuses ||
      !statuses?.find((x: TaskStatus) => x.actionId === TaskActionTypes.ACCEPT)
    )
      return;
    setHideAcceptBtn(data.some((x: Task) => x.assetCycleRunId !== null));
  }, [data, statuses]);

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Bulk changes', transFormName)}
      minWidth={'75%'}
      maxHeight={'75%'}
      wrapperAttr={{ class: 'modal-bulk-changes' }}
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={{
          text: translate!('Close', transFormName),
          icon: 'close',
          type: 'normal',
          onClick: onClose,
        }}
      />
      {statuses && (
        <TaskChangeStatus
          responsiblePersons={taskActionRespPersons}
          showCurrentStatus={false}
          statuses={
            hideAcceptBtn
              ? statuses.filter((s: TaskStatus) => s.actionId !== TaskActionTypes.ACCEPT)
              : statuses
          }
          mutate={mutate}
          mutateStatuses={mutateStatuses}
          rowStatus={setRowsStatus}
          isOnlyButtons
          poupWidth={400}
          tasks={data}
          isBulk
          taskRatings={taskRatings}
        />
      )}
      <ScrollView>
        <SisDataGrid
          data={data}
          keyExpr={'id'}
          transFormName={transFormNameColumns}
          columns={columns}
          columnChooser={false}
          allowColumnReordering={false}
          allowColumnResizing={false}
          pageSizeChangeVisible={true}
          allowHeaderFiltering={false}
          filterRow={false}
          onRowPrepared={(e) => paintRowByStatus(e, rowsStatus)}
        />
      </ScrollView>
    </Popup>
  );
}
