import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AssetsReportDataGrid } from '../../../components/datagrid/AssetsReportDataGrid';
import { Loader } from '../../../components/loader/Loader';
import { useBreadcrumbs } from '../../../contexts/breadcrumbsContext';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import AssetReportFilter from '../../../forms/filter/assetReport/AssetReportFilter';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Asset } from '../../../types/asset.types';
import { ReportsState } from '../../../types/locationState.types';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { isNilOrEmpty } from '../../../utils/ramdaUtils';
import { ReportType } from '../../../utils/reportTypeKeys';
import '../Reports.scss';

export function AssetReport() {
  const [data, setData] = useState<Array<Asset> | null>([]);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const { state }: { state: ReportsState | undefined } = useLocation();
  const transFormName = 'Asset.List';
  const { stopLoading } = useLoading();

  const reportType = ReportType.Assets;

  const history = useHistory();
  // Načtení práv uživatele
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  const { getActualJsonState, loadFilter, resetFilter, saveFilter } = useSaveDataGridFilter(
    reportType.key,
    dataGridRef,
  );

  useEffect(() => {
    // stopLoading();
  }, [data, selectedFilter]);

  const [datagridSetting] = useSessionStorage(STORAGE_KEYS.ASSETS_REPORT_DATAGRID_DESKTOP, {});
  useEffect(() => {
    if (state?.loadSettingFromSession) {
      loadFilter(datagridSetting);
    }
  }, [state]);

  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Assets', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    if (resetFilterAction) {
      resetFilter();
      setResetFilterAction(false);
    }
    saveFilter();

    if (!dataGridRef.current || !dataGridRef.current.instance) return;
  }, [resetFilterAction]);

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        pathname: `/assets/edit/${e.row.data.id}`,
        state: { backRoute: pathname },
      });
    }
  };

  if (!data) return <Loader />;

  return (
    <PageContentWrapper heading={isFilterVisible ? translate!('Assets', transFormName) : ''}>
      <>
        <AssetReportFilter
          dataGridRef={dataGridRef}
          onDataChange={setData}
          report={reportType}
          onFilterChange={setSelectedFilter}
          filterVisibility={isFilterVisible}
          loadDefaultSetting={!state?.loadSettingFromSession}
        />
        <AssetsReportDataGrid
          dataGridRef={dataGridRef}
          data={data}
          resetFilter={() => setResetFilterAction(true)}
          userCanCreate={userHasPermissionToCreate}
          dataGridKey={reportType.key}
          handleEdit={handleEditButton}
          heading={`${translate!('Assets', transFormName)}${
            selectedFilter ? ` - ${selectedFilter}` : ''
          }`}
          filterVisibility={isFilterVisible}
          setFilterVisibility={setFilterVisible}
        />
      </>
    </PageContentWrapper>
  );
}
export default forwardRef(AssetReport);
