import { DropDownButton, Popup } from 'devextreme-react';
import React, { useState } from 'react';
import { deleteApi, putApi } from '../../apiUtils';
import { useTranslation } from '../../contexts/translation';
import { File } from '../../types/file.types';
import { API_URL } from '../../utils/apiUrl';
import './FileFigure.scss';
import { FileRenameInput } from './FileRenameInput';
import { fileToBlob, ImagePreview } from './ImagePreview';

interface FileFigureProps {
  id: number;
  entityName: string;
  entityId: number;
  name: string;
  description: string;
  contentType: string;
  isReadonly: boolean;
  onFileDelete: any;
  onFileRename?: any;
  item?: File;
  isTmpFile?: boolean;
  height?: number | string;
  width?: number | string;
}

const FileContentType = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
};

export const colors = {
  primary: '#346cb0',
};

export const iconStyles = {
  display: 'block',
  marginTop: '35px',
  marginBottom: '5px',
  fontSize: '55px',
  textAlign: 'center' as const,
};

/**
 * Komponenta pro zobrazení a správu konkrétního souboru. Nyní je vše natvrdo svázáno s assety, ale malou úpravou
 * lze komponentu lehce upravit na ostatní entity.
 * @param props
 * @constructor
 */
export function FileFigure(props: FileFigureProps) {
  const { translate } = useTranslation();
  const transFormName = 'File';
  const [isRenameInputVisible, setRenameInputVisible] = useState(false);
  const [description, setDescription] = useState(props.description);
  const [popupVisibility, setPopupVisibility] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  /**
   * Vrátí preview ikonu daného souboru.
   * Pokud se jedná o obrázek (png): Volá se metoda pro načtení obrázku z API.
   * Pokud se jendá o ostatní typy: Vrátí se ipříslušná ikona např. docx, xlsx, pdf.
   */
  const getFilePreview = () => {
    switch (props.contentType) {
      case FileContentType.PDF:
        return (
          <i
            className="dx-icon-pdffile dx-icon-custom-style"
            style={{ ...iconStyles, color: 'red' }}
            aria-hidden="true"
          />
        );
      case FileContentType.DOCX:
        return (
          <i
            className="dx-icon-docxfile dx-icon-custom-style"
            style={{ ...iconStyles, color: '#469bdb' }}
            aria-hidden="true"
          />
        );
      case FileContentType.XLSX:
        return (
          <i
            style={{ ...iconStyles, color: '#02723b' }}
            className="dx-icon-xlsxfile dx-icon-custom-style"
            aria-hidden="true"
          />
        );
      case FileContentType.PNG:
      case FileContentType.JPG:
      case FileContentType.JPEG:
        return (
          <ImagePreview
            id={props.id}
            name={props.name}
            entityName={props.entityName}
            tmpFile={props.isTmpFile ? props.item : undefined}
            targetHeight={props.height ? props.height : undefined}
            targetWidth={props.width ? props.width : undefined}
          />
        );
      default:
        return (
          <i
            className="dx-icon-file dx-icon-custom-style"
            style={{ ...iconStyles, color: colors.primary }}
            aria-hidden="true"
          />
        );
    }
  };

  /**
   * Podle id a entityId smaže file. Voláním API. Po úspěšném voluní API dojde k přenačtení soubůrů.
   * @param id: id souboru.
   * @param entityId: id nadřazení entity.
   */
  const handleFileDelete = async (id: number, entityId: number) => {
    if (!window.confirm(translate!('You sure?', transFormName))) return;

    if (props.isTmpFile) {
      if (props.onFileDelete) props.onFileDelete(props.id);
    } else {
      await deleteApi({
        url: `${API_URL.FILE_DELETE_ITEM}?entity=${props.entityName}&entityId=${entityId}`,
        id: id,
        callAfterSuccess: () => {
          if (props.onFileDelete) props.onFileDelete();
        },
      });
    }
  };

  /**
   * Otevře soubor v novém okně. Soubor se nejdříve načte s api.
   * @param id: id souboru
   */
  const handleFileOpen = (id: number) => {
    if (props.isTmpFile) {
      let blob = fileToBlob(props.item);
      blob.then(async () => {
        const fileUrl: string = URL.createObjectURL(await blob);
        if (/jpg|jpeg|png/.test(props.contentType.toLowerCase())) {
          setImgSrc(fileUrl);
          setPopupVisibility(true);
        }
      });
    } else {
      fetch(`${API_URL.FILE_ITEM}?entity=${props.entityName}&id=${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.blob())
        .then((data) => {
          const fileUrl: string = URL.createObjectURL(data);
          if (/jpg|jpeg|png/.test(props.contentType.toLowerCase())) {
            setImgSrc(fileUrl);
            setPopupVisibility(true);
          } else {
            // window.open(fileUrl, '_blank');
          }
        });
    }
  };

  /**
   * Metoda, která se stará o stažení souboru. Vytvoří dočasný element odkazu, na který se programově
   * klidne. Následně je dočansý odkaz odstraněn.
   * @param fileUrl: url souboru - kde se nachází
   * @param fileName: název souboru, pod kterým se stáhne
   */
  const downloadFile = (fileUrl: string, fileName: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = fileName;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  /**
   * Stáhne požadovaný soubor. Soubor se nejdříve načte z API.
   * @param id: id souboru.
   * @param entityId: id nadřazené entity.
   * @param fileName: pod jakým názvem se má soubor stáhnout.
   */
  const handleFileDownload = (id: number, entityId: number, fileName: string) => {
    fetch(`${API_URL.FILE_ITEM}?entity=${props.entityName}&id=${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.blob())
      .then((data) => {
        const fileUrl: string = URL.createObjectURL(data);
        downloadFile(fileUrl, fileName);
      });
  };

  const handleFileRename = async (id: number, entityId: number, description: string) => {
    const data = {
      id,
      description,
    };

    if (!props.isTmpFile) {
      await putApi({
        url: `${API_URL.FILE_PUT}?entity=${props.entityName}&entityId=${entityId}`,
        data,
        callAfterSuccess: () => {
          // TODO: Zde by se dal použít batch. Kouknout na to a zkusit implementovat.
          setRenameInputVisible(false);
          setDescription(description);
        },
      });
    } else {
      if (props.onFileRename) {
        props.onFileRename(props.id, description);
        setRenameInputVisible(false);
      }
    }
  };

  const handleSetPrimary = async (id: number, entityId: number) => {
    await putApi({
      url: `${API_URL.FILE_SET_PRIMARY}?entity=${props.entityName}&entityId=${entityId}&id=${id}`,
    });
  };

  const dropDownActions = [
    { id: 1, name: translate!('Download', transFormName), icon: 'download' },
    { id: 2, name: translate!('Rename', transFormName), icon: 'edit' },
    { id: 3, name: translate!('Set as primary', transFormName), icon: 'image' },
    { id: 4, name: translate!('Delete', transFormName), icon: 'remove' },
  ];

  const onDropDownItemClick = (e) => {
    switch (e.itemData.id) {
      case 1:
        handleFileDownload(props.id, props.entityId, props.name);
        break;
      case 2:
        setRenameInputVisible(true);
        break;
      case 3:
        handleSetPrimary(props.id, props.entityId);
        break;
      case 4:
        handleFileDelete(props.id, props.entityId);
        break;
    }
  };

  return (
    <div
      key={props.id}
      className={`file-figure ${props.isTmpFile ? 'temp-img' : 'file'}`}
      style={{ width: props.height ? undefined : '30%' }}
    >
      <div
        style={{
          cursor: 'pointer',
          height: props.height ? undefined : '125px',
          minHeight: '125px',
          minWidth: '100px',
          overflow: 'hidden',
        }}
        onClick={() => handleFileOpen(props.id)}
      >
        {getFilePreview()}
      </div>
      {!isRenameInputVisible ? (
        <div className="figure-caption">{description ? description : props.name}</div>
      ) : (
        <FileRenameInput
          fileId={props.id}
          entityId={props.entityId}
          description={description}
          handleFileRename={handleFileRename}
          handleFileRenameCancel={setRenameInputVisible}
        />
      )}
      {!props.isReadonly && !isRenameInputVisible && (
        <div className={'buttons'}>
          <DropDownButton
            text={'More actions'}
            icon="preferences"
            displayExpr="name"
            keyExpr="id"
            // při creatu nezobrazovat možnost downloadu a setPrimary
            items={
              props.isTmpFile
                ? dropDownActions.filter((action: any) => action.id !== 1 && action.id !== 3)
                : dropDownActions
            }
            onItemClick={onDropDownItemClick}
          />
        </div>
      )}
      <Popup
        visible={popupVisibility}
        closeOnOutsideClick={true}
        onHiding={() => {
          setPopupVisibility(false);
          setImgSrc(null);
        }}
        showCloseButton={true}
        showTitle={true}
        title={props.name}
        container=".dx-viewport"
      >
        <div
          className="popup-img-wrapper"
          style={{ backgroundImage: `url("${imgSrc ?? ''}")` }}
        ></div>
      </Popup>
    </div>
  );
}
