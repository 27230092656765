import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { AssetResponsiblePerson } from '../../types/assetResponsiblePerson.types';
import { dateFieldOptionsWithSerialization } from '../../utils/dates.types';
import './TasksBulkEditModal.scss';

type Props = {
  data: Array<any>;
  isVisible: boolean;
  mutate?: () => void;
  onClose: () => void;
  columns: Array<any>;
  transFormNameColumns: string;
};

export type RowsStatus = {
  successArray: number[];
  failedArray: number[];
};

/**
 * Modalová komponent pro hromadné změny ve vybraných řádcích z datagridu
 */
export default function AssetResponsiblePersonModal({
  isVisible,
  onClose,
  capacityCategories,
  persons,
  data,
  actionType,
  onSubmit,
}) {
  const { translate } = useTranslation();
  const transFormName = 'Asset.ResponsiblePerson';
  const formRef = useRef<Form>(null);

  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    setFormData({ ...data });
  }, []);

  const validateRespFromTo = (e) => {
    const responsibleTo = e.value;
    const { responsibleFrom } = formData;
    if (responsibleFrom === null || !responsibleTo) return true;

    return new Date(responsibleTo) >= new Date(responsibleFrom);
  };

  const handleCapacityCategoryChanged = (e: any) => {
    setFormData({ ...formData, personId: null });
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset responsible persons', transFormName)}
      width={'auto'}
      height={'auto'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        {prompt}
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem>
            <SimpleItem
              dataField="capacityCategoryId"
              editorType="dxSelectBox"
              editorOptions={{
                items: capacityCategories,
                valueExpr: 'id',
                displayExpr: 'name',
                onValueChanged: handleCapacityCategoryChanged,
                width: 300,
              }}
            >
              <Label text={translate!('Capacity category', transFormName)} />
              <RequiredRule message={translate!('Capacity category is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="personId"
              editorType="dxSelectBox"
              editorOptions={{
                items: !formData.capacityCategoryId
                  ? []
                  : formData.capacityCategoryId
                  ? persons.filter(
                      (p: AssetResponsiblePerson) =>
                        p.capacityCategoryId === formData.capacityCategoryId,
                    )
                  : persons,
                valueExpr: 'personId',
                displayExpr: 'personName',
                width: 300,
              }}
            >
              <Label text={translate!('Person', transFormName)} />
              <RequiredRule message={translate!('Responsible person is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="responsibleFrom"
              editorType="dxDateBox"
              editorOptions={{
                ...dateFieldOptionsWithSerialization,
                width: 300,
              }}
            >
              <Label text={translate!('Responsible from', transFormName)} />
              <RequiredRule message={translate!('Responsible from is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="responsibleTo"
              editorType="dxDateBox"
              editorOptions={{
                ...dateFieldOptionsWithSerialization,
                width: 300,
              }}
            >
              <Label text={translate!('Responsible to', transFormName)} />
              <CustomRule
                validationCallback={validateRespFromTo}
                type={'custom'}
                message={translate!(
                  'Responsible to needs to be after responsible from',
                  transFormName,
                )}
                ignoreEmptyValue
              />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="visible" editorType="dxCheckBox">
              <Label text={translate!('Visible', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Save', '')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions
                width={'100%'}
                type={'normal'}
                text={translate!('Close', '')}
                onClick={onClose}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
