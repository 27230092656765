import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/TaskNotificationConfig/Columns';
import { TaskNotificationConfigsFilterData } from '../../pages/TaskNotificationConfig/TaskNotificationConfig';
import { NotificationTemplate } from '../../types/notificationTemplate.types';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  defaultFilterData?: TaskNotificationConfigsFilterData;
  onFilterChanged?: (TaskNotificationConfigsFilterData) => void;
  isDateFilter?: boolean;
  mutateData?: () => void;
  handleRemove: (id: number) => void;
  notificationTemplateList: NotificationTemplate[];
}

export const TaskNotificationConfigsDataGrid = ({
  data,
  handleEdit,
  handleRemove,
  defaultFilterData,
  onToolbarPreparing,
  isDateFilter,
  notificationTemplateList,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'TaskNotificationConfig.List';
  const [filterData, setFilterData] = useState<TaskNotificationConfigsFilterData | undefined>(
    isDateFilter ? defaultFilterData : undefined,
  );

  useEffect(() => {
    setFilterData(isDateFilter ? defaultFilterData : undefined);
  }, [defaultFilterData]);

  const columns = Columns(handleEdit, handleRemove, notificationTemplateList, translate);

  return (
    <SisDataGrid
      data={data}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={columns}
      onToolbarPreparing={onToolbarPreparing}
      exportName="task-notification-configs"
      enableXlsxExport
      allowColumnResizing={false}
      columnChooser={false}
      allowColumnReordering={false}
      allowHeaderFiltering
      onCellDblClick={(e) => handleEdit && handleEdit(e)}
    />
  );
};
