import { ContextMenu } from 'devextreme-react';
import { isFunction } from 'ramda-extension';
import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Columns } from '../../pages/Reports/SpareParts/Columns';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  dataGridKey: string;
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
}

export const SparePartsReportDataGrid = ({
  data,
  handleEdit,
  resetFilter,
  dataGridKey,
  dataGridRef,
  userCanCreate,
  heading,
  filterVisibility,
  setFilterVisibility,
}: Props) => {
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const transFormName = 'SparePart.List';
  const transFormReportBar = 'Reports.Bar';
  // Pro nastavení path pro context menu
  const [selectedPath, setSelectedPath] = useLocalStorage(STORAGE_KEYS.CONTEXT_STATE, null);
  const history = useHistory();

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };

  // po kliku na možnost v context menu
  const contextMenuItemClick = () => {
    if (!selectedPath) return;
    // jen interní link na tasky
    window.open('#' + selectedPath.path, '_blank');
  };

  // možnosti, co se zobrazí v context menu - po kliku pravým tlačítkem
  const contextMenuItems = [
    {
      text: translate!('Open link in new tab', ''),
      icon: 'dx-icon-globe',
      onclick: () => contextMenuItemClick(),
    },
  ];

  const handleSetPath = (o: any) => setSelectedPath(o);

  const columns = Columns(history, pathname, handleSetPath);
  return (
    <>
      <SisDataGrid
        enableXlsxExport
        exportName={'assets-data'}
        dataGridRef={dataGridRef}
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        stateStoringKey={dataGridKey}
        onToolbarPreparing={onToolbarPreparing}
        columns={columns}
        // onCellDblClick={(e) => handleEdit && handleEdit(e)}
        pageSizeChangeVisible={true}
        enableGrouping
      />
      {/* Context menu pro odkazy */}
      <ContextMenu
        dataSource={contextMenuItems}
        target={'.context-link'}
        onItemClick={contextMenuItemClick}
      />
    </>
  );
};
