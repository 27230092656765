import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/AssetStatuses/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const AssetStatusesDataGrid = ({ data }: IDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'AssetStatus.List';
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Asset statuses', transFormName),
    });
  };

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns}
        onToolbarPreparing={onToolbarPreparing}
        columnChooser={false}
      />
    </>
  );
};
