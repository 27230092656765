import moment from 'moment';
import { isNilOrEmpty } from 'ramda-extension';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { getItemApi } from '../../apiUtils';
import { TasksDataGrid } from '../../components/datagrid/TasksDataGrid';
import { DatagridNoResponsiveContainer } from '../../components/datagridNoResponsive/DatagridNoResponsiveContainer';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useIsTaskCreatePermission } from '../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { Task } from '../../types/task.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { swrConfig } from '../Assets/AssetsEdit';
import { tabsIndexes } from './TasksEdit';

// Filtr mimo DataGrid
// TODO: Bylo by vhodné přenést do types
export interface TasksFilterData {
  dateFrom: string | null;
  dateTo: string | null;
}

// Defaultní nastavení filtru mimo DataGrid
// TODO: Bylo by vhodné přenést do types
const defaultFilterData: TasksFilterData = {
  dateFrom: `${moment().add(-30, 'days').format('YYYY-MM-DD')}`,
  dateTo: `${moment().format('YYYY-MM-DD')}`,
};

/**
 * Přehled entity Task.
 */
export function Tasks() {
  const history = useHistory();
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();

  // Načtení práv uživatele
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGrid = useRef<any>(null);
  // Defaultní konfigurace DataGridu z databáze.
  const config = useConfigByKey(CONFIG_KEYS.TASK_LIST_DATAGRID_DESKTOP);
  // Hook, který umožňuje manipulovat s nastavením DataGridu. Defaultně mu předáváme nastavení z localStorage.
  const {
    loadFilter,
    resetFilter,
    saveFilter,
    getActualJsonState,
    data: datagridJsonData,
  } = useSaveDataGridFilter(STORAGE_KEYS.TASKS_DATAGRID_DESKTOP, dataGrid);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);
  // Nastavení filtru (mimo DataGrid) - ukládáme/načítáme do/z localStorage
  const [filterData, setFilterData] = useSessionStorage(
    STORAGE_KEYS.TASKS_FILTER_DESKTOP,
    defaultFilterData,
  );

  const loadConfig = (config: any) => {
    if (config) {
      loadFilter(JSON.parse(config.value));
      saveFilter();
    }
  };

  useEffect(() => {
    setValue({ pageName: 'Tasks', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    if (config && resetFilterAction) {
      resetFilter();
      loadConfig(config);
      saveFilter();
      setResetFilterAction(false);
      setFilterData({ ...defaultFilterData });
    }

    if (!dataGrid.current || !dataGrid.current.instance) return;
    if (config && !getActualJsonState(false)) loadConfig(config);
  }, [config, resetFilterAction]);

  let tasksApi = `${API_URL.TASK_BY_CLASS}?classId=${assetClass}`;
  if (filterData.dateFrom)
    tasksApi = `${API_URL.TASK_BY_CLASS}?classId=${assetClass}&filterCreatedFrom=${filterData.dateFrom}T00:00:00`;
  if (filterData.dateTo)
    tasksApi = `${API_URL.TASK_BY_CLASS}?classId=${assetClass}&filterCreatedTo=${filterData.dateTo}T23:59:59`;
  if (filterData.dateFrom && filterData.dateTo)
    tasksApi = `${API_URL.TASK_BY_CLASS}?classId=${assetClass}&filterCreatedFrom=${filterData.dateFrom}T00:00:00&filterCreatedTo=${filterData.dateTo}T23:59:59`;

  const { data, error, mutate: mutateTasks } = useSWR<Task[]>(tasksApi, fetcher, swrConfig);

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push(`/tasks/edit/${e.row.data.id}`);
    }
  };

  /**
   * Handle kopírovacího tlačítka v DataGridu
   * @param e  Event
   */
  const handleCopyButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push(`/tasks/new/${e.row.data.id}`);
    }
  };

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleCreateWorksheetButton = (e): void => {
    if (isNilOrEmpty(e.row) || isNilOrEmpty(e.row.data)) return;

    // zjistíme počet výkazů - WORKSHEET_GET_COUNT_BY_TASK
    getItemApi<number>({
      url: `${API_URL.WORKSHEET_GET_COUNT_BY_TASK}?parentId=${e.row.data.id}`,
      params: {},
      callAfterSuccess: (result: number) => {
        // neexistuje výkaz, přesměrujeme na create
        if (result === 0) {
          history.push({
            pathname: '/workSheets/new/0/' + e.row.data.id,
            state: { backRoute: `/tasks` },
          });
          return;
        }

        // jinak přesměrujeme na seznam výkazů v detailu tasku
        history.push({
          pathname: `/tasks/edit/${e.row.data.id}`,
          state: { defaultTabIndex: tabsIndexes.WORKSHEETS },
        });
      },
      hideNotifications: true,
    });
  };

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateTasks();
  };

  return (
    <DatagridNoResponsiveContainer error={error} loading={!data || loadingPermission}>
      <TasksDataGrid
        dataGridRef={dataGrid}
        data={data}
        mutateData={mutateTasks}
        userCanCreate={userHasPermissionToCreate}
        defaultFilterData={filterData}
        onFilterChanged={handleFilterChanged}
        resetFilter={() => setResetFilterAction(true)}
        handleEdit={handleEditButton}
        handleCreateWorksheet={handleCreateWorksheetButton}
        handleCopy={handleCopyButton}
        isDateFilter
        storageKey={STORAGE_KEYS.TASKS_DATAGRID_DESKTOP.toString()}
      />
    </DatagridNoResponsiveContainer>
  );
}

export default Tasks;
