import React from 'react';
import * as Bar from './Bar';
import './MobileLayout.scss';

export default function MobileLayout({ children }) {
  return (
    <div className="layout-container">
      <Bar.ActionBar />
      <div>{children}</div>
    </div>
  );
}
