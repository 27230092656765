import { equals } from 'ramda';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Platform } from './components/loginForm/LoginForm';
import { TerminalBottomToolbar } from './container/TerminalBottomToolbar';
import { LoadingProvider } from './contexts/loadingContext';
import { withNavigationWatcher } from './contexts/navigation';
import { useTerminalAuth } from './contexts/terminalAuthContext';
import TerminalAssistance from './pages/Tasks/Terminal/TerminalAssistance';
import TerminalAssistanceQueue from './pages/Tasks/Terminal/TerminalAssistanceQueue';
import { UserRoles } from './types/applicationUser.types';
import { UnauthenticatedContent } from './UnauthenticatedContent';

const TasksTerminal = React.lazy(() => import('./pages/Tasks/Terminal/TasksTerminal'));
const TasksTerminalDetail = React.lazy(
  () => import('./pages/Tasks/Terminal/Detail/TasksTerminalDetail'),
);
const TasksTerminalNew = React.lazy(() => import('./pages/Tasks/Terminal/New/TasksTerminalNew'));
const TerminalCssImporter = React.lazy(() => import('./container/TerminalCssImporter'));

export const terminalRoutes = [
  {
    path: '/terminal',
    component: TasksTerminal,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/terminal/tasks',
    component: TasksTerminal,
    restrictedAccess: [UserRoles.TaskRead],
  },
  {
    path: '/terminal/tasks/detail/:id',
    component: TasksTerminalDetail,
    restrictedAccess: [],
  },
  {
    path: '/terminal/tasks/new',
    component: TasksTerminalNew,
    restrictedAccess: [UserRoles.TaskCreate],
  },
  {
    path: '/',
    component: TasksTerminal,
    restrictedAccess: [],
  },
  {
    path: '/terminal/assistance-queue',
    component: TerminalAssistanceQueue,
    restrictedAccess: [],
  },
  {
    path: '/terminal/assistance',
    component: TerminalAssistance,
    restrictedAccess: [],
  },
];
const routesWithoutToolbar: string[] = [];

export const routesTerminal = terminalRoutes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});

interface Props {
  assetIdFromUrl?: number | null;
}

export const TerminalRoutes = ({ assetIdFromUrl }: Props) => {
  const { user, isTechnicalUser, terminalMappingRedirectEnabled } = useTerminalAuth();
  const { entryUrl } = useTerminalAuth();

  return (
    <TerminalCssImporter>
      <LoadingProvider>
        <Switch>
          {routesTerminal.map(({ path, component: Component, restrictedAccess }, index) => {
            const result = equals(
              user?.roles.filter((p) => restrictedAccess?.some((r) => equals(r, p))),
              restrictedAccess,
            );
            if (!restrictedAccess || result) {
              return (
                <Route
                  exact
                  key={path + index}
                  path={path}
                  render={(props) => {
                    return routesWithoutToolbar.includes(path) ? (
                      <Component {...props} />
                    ) : (
                      <TerminalBottomToolbar>
                        <Component {...props} />
                      </TerminalBottomToolbar>
                    );
                  }}
                />
              );
            } else {
              return null;
            }
          })}

          {isTechnicalUser && (
            <Route exact path={'/terminal/login/NonTechnicalUser'}>
              <UnauthenticatedContent
                path={'/terminal/login/NonTechnicalUser'}
                platform={Platform.TERMINAL}
                showBackButton
                terminalMappingRedirectEnabled={terminalMappingRedirectEnabled}
              />
            </Route>
          )}

          <Redirect to={entryUrl || '/terminal/tasks'} />
        </Switch>
      </LoadingProvider>
    </TerminalCssImporter>
  );
};
