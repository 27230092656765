import { DateBox, SelectBox, Tooltip } from 'devextreme-react';
import { locale } from 'devextreme/localization';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { BaseValueDataType, TaskParameterAssetCycle } from '../../types/parameter.types';
import {
  MomentDateSerializationFormat,
  MomentDateTimeSerializationFormat,
} from '../../utils/dates.types';
import { Loader } from '../loader/Loader';
import { IDataGrid } from './AssetsDataGrid';
export interface ITaskParameterWithAssetCycleDataGrid extends IDataGrid {
  taskId?: number;
  transFormName: string;
  setParametersForTaskComplete: any;
  setIsValid: any;
}

interface Error {
  itemIndex: number;
  col: string;
  type?: string;
  message: string;
}

export const TaskParameterWithAssetCycleDataGrid = ({
  data,
  taskId,
  transFormName,
  setParametersForTaskComplete,
  setIsValid,
}: ITaskParameterWithAssetCycleDataGrid) => {
  const configEnabledDateChange = useConfigByKey(
    CONFIG_KEYS.ENABLE_THRESHOLD_DATE_CHANGER_FOR_ASSET_CYCLE,
  );

  const [newData, setNewData] = useState<any[]>([]);
  const [errors, setErrors] = useState<Error[]>([]);
  const [isValidDateTime, setIsValidDateTime] = useState<boolean>(true);
  const [isDateEditable, setIsDateEditable] = useState<boolean>(true);
  const [activeInput, setActiveInput] = useState<any>(null);
  const { translate } = useTranslation();

  // kvůli memory leak in your application warningu
  useEffect(() => {
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (!configEnabledDateChange || !configEnabledDateChange?.enabled) return;
    setIsDateEditable(
      configEnabledDateChange.value.toLocaleLowerCase() !== 'false' &&
        configEnabledDateChange.value.toLocaleLowerCase() !== '0',
    );
  }, [configEnabledDateChange]);

  // počáteční nastavení
  useEffect(() => {
    let tmpArr = data.map((item: any) => {
      return {
        ...item,
        threshold: setThreshold(item),
        currentValue: setCurrentValue(item),
      };
    });
    setNewData(tmpArr);
    prepareDataForSubmit(data);
  }, []);

  // když nevalidní, tak disablnout tlačítko
  useEffect(() => {
    setIsValid(errors.length === 0 && isValidDateTime);
  }, [errors, isValidDateTime]);

  // format date pro submit
  const formatDateForSubmit = (date: Moment, unit: string) => {
    return date.format(
      unit === BaseValueDataType.Date
        ? MomentDateSerializationFormat
        : MomentDateTimeSerializationFormat,
    );
  };

  // nastavit threshold
  const setThreshold = (item: any, isSubmit?: boolean) => {
    let unit = item?.unit?.baseValueDataType ? item.unit.baseValueDataType : BaseValueDataType.Text;
    // pokud změním threshold manuálně, tak uložit
    if (item?.nextThreshold) return item?.nextThreshold;
    // pokud není perioda (nebo je 0), nepřednastavovat threshold
    if (!item?.period || item?.period === 0 || item.threshold === '') return null;
    // measurement not allowed
    if (!item?.threshold && !item?.lastValue && !item.measurementAllowed) {
      return processMeasurementAllowed(item, isSubmit)?.threshold;
    }
    if (unit === BaseValueDataType.Date || unit === BaseValueDataType.DateTime) {
      if (isSubmit)
        return formatDateForSubmit(
          moment().add(item.period, unit === BaseValueDataType.Date ? 'days' : 'minutes'),
          unit,
        );
      return moment().add(item.period, unit === BaseValueDataType.Date ? 'days' : 'minutes');
    } else if (unit === BaseValueDataType.Text) {
      return '';
    } else if (unit === BaseValueDataType.Int || unit === BaseValueDataType.Float) {
      if (item?.lastValue) return item.lastValue + item.period;
      if (!item?.lastValue) return 0 + item.period;
    }
  };

  // nastavit current value
  const setCurrentValue = (item: any, isSubmit?: boolean) => {
    let unit = item?.unit?.baseValueDataType ? item.unit.baseValueDataType : BaseValueDataType.Text;
    if (!item?.currentValue && !item?.lastValue && !item.measurementAllowed)
      return processMeasurementAllowed(item, isSubmit)?.currentValue;
    // pro datum nastavovat today()
    if (unit === BaseValueDataType.Date || unit === BaseValueDataType.DateTime) return moment();
    if (!item?.currentValue && !item?.lastValue) return undefined;
    if (item?.currentValue) return item.currentValue;
    if (item?.lastValue) return item.lastValue;
    if (unit === BaseValueDataType.Text) {
      return '';
    } else if (unit === BaseValueDataType.Int || unit === BaseValueDataType.Float) {
      return 0;
    }
  };

  // hodnota currentValue/thresholdu když measurementAllowed === false
  const processMeasurementAllowed = (item: any, isSubmit?: boolean) => {
    let unit = item?.unit?.baseValueDataType ? item.unit.baseValueDataType : BaseValueDataType.Text;
    // když datum -> dnešní datum, nastavit threshold dnes+perioda
    if (unit === BaseValueDataType.Date || unit === BaseValueDataType.DateTime) {
      if (isSubmit)
        return {
          currentValue: formatDateForSubmit(moment(new Date()), unit),
          threshold: formatDateForSubmit(
            moment(new Date()).add(
              item.period,
              unit === BaseValueDataType.Date ? 'days' : 'minutes',
            ),
            unit,
          ),
        };
      return {
        currentValue: moment(new Date()),
        threshold: moment(new Date()).add(
          item.period,
          unit === BaseValueDataType.Date ? 'days' : 'minutes',
        ),
      };
    } else if (unit === BaseValueDataType.Int || unit === BaseValueDataType.Float) {
      return { currentValue: 0, threshold: 0 + item.period };
    }
  };
  // po změně current value přepočítat threshold (currentValue+perioda)
  const onCurrentValueChange = (item: any, value: any, index: number) => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      item.period === 0 ||
      item.period === null ||
      item.period === undefined
    )
      return;
    let unit = item?.unit?.baseValueDataType ? item.unit.baseValueDataType : BaseValueDataType.Text;
    let newThreshold;
    if (unit === BaseValueDataType.Date) {
      newThreshold = formatDateForSubmit(moment(value).clone().add(item.period, 'days'), unit);
    } else if (unit === BaseValueDataType.DateTime) {
      newThreshold = formatDateForSubmit(moment(value).clone().add(item.period, 'minutes'), unit);
    } else if (unit === BaseValueDataType.Int || unit === BaseValueDataType.Float) {
      newThreshold = Number(value) + Number(item.period);
    } else return;
    let newArr = [...newData];
    newArr[index] = {
      ...newData[index],
      currentValue: value,
      threshold: newThreshold,
    };
    setNewData(newArr);
    prepareDataForSubmit(newArr);
  };

  // po změně hodnoty
  const onSaving = (val, key, index) => {
    if (!newData || newData.length === 0) return;
    let newArr = [...newData];
    if (val === 'Invalid date') val = null;
    newArr[index] = {
      ...newData[index],
      currentValue: key === 'currentValue' ? val : setCurrentValue(newData[index]),
      nextThreshold: key === 'nextThreshold' ? val : setThreshold(newData[index]),
    };
    // zobrazit změny v tabulce
    setNewData(newArr);
    prepareDataForSubmit(newArr);
  };

  // připravit data na submit
  const prepareDataForSubmit = (arr) => {
    let tmpSubmitData: any = [];
    arr.map((i: any) => {
      if (setCurrentValue(i, true) === null || setCurrentValue(i, true) === undefined) return;
      tmpSubmitData = [
        ...tmpSubmitData,
        {
          taskId: taskId,
          id: i?.id,
          value: setCurrentValue(i, true),
          threshold: setThreshold(i, true),
        },
      ];
    });
    setParametersForTaskComplete(tmpSubmitData);
  };

  // kontrola na celé číslo
  const isInt = (n) => {
    return /^[0-9\b]+$/.test(n) && n % 1 === 0;
  };

  const typeErrorMessage = translate!('Predefined type does not match', transFormName);
  const valueErrorMessage = translate!(
    'Current value must not be higher or equal to threshold',
    transFormName,
  );
  // validace - volá se při opuštění inputu
  const validate = (value, col, index) => {
    let typeError = false;
    let valueError = false;
    let type = data[index].unit.baseValueDataType;
    if (type === BaseValueDataType.Int) {
      if (value && !isInt(value)) typeError = true;
      if (
        (col === 'currentValue' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          Number(value) >= newData[index]?.threshold) ||
        (col === 'threshold' &&
          Number(value) <= newData[index]?.currentValue &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          !(
            (col === 'currentValue' && value === undefined) ||
            (col === 'currentValue' && value === null)
          ))
      )
        valueError = true;
    }
    if (type === BaseValueDataType.Float) {
      if (
        (col === 'currentValue' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          Number(value) >= Number(newData[index]?.threshold)) ||
        (col === 'threshold' &&
          Number(value) <= Number(newData[index]?.currentValue) &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          !(
            (col === 'currentValue' && value === undefined) ||
            (col === 'currentValue' && value === null)
          ))
      )
        valueError = true;
    }
    if (type === BaseValueDataType.Date) {
      if (
        (col === 'currentValue' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          moment(value).isSameOrAfter(moment(newData[index]?.threshold))) ||
        (col === 'threshold' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          moment(value).isSameOrBefore(moment(newData[index]?.currentValue)))
      )
        valueError = true;
    }
    if (type === BaseValueDataType.DateTime) {
      if (
        (col === 'currentValue' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          moment(value).isSameOrAfter(moment(newData[index]?.threshold))) ||
        (col === 'threshold' &&
          ((newData[index]?.period && newData[index]?.period !== 0) ||
            (newData[index]?.threshold && newData[index]?.threshold !== '')) &&
          moment(value).isSameOrBefore(moment(newData[index]?.currentValue)))
      )
        valueError = true;
    }

    if (typeError) {
      if (errors.findIndex((x: Error) => x.itemIndex === index && x.col === col) === -1) {
        setErrors([
          ...errors,
          { col: col, itemIndex: index, message: typeErrorMessage, type: type },
        ]);
      }
    } else if (valueError) {
      if (errors.findIndex((x: Error) => x.itemIndex === index && x.col === col) === -1) {
        setErrors([
          ...errors,
          { col: col, itemIndex: index, message: valueErrorMessage, type: type },
        ]);
      }
    } else {
      let newErrors = errors.filter((x: Error) => x.itemIndex !== index);
      setErrors(newErrors);
    }
  };

  // nadpisy sloupců tabulky
  const columns = [
    translate!('Name', transFormName),
    translate!('Unit description', transFormName),
    translate!('Last value', transFormName),
    translate!('Current value', transFormName),
    translate!('Next threshold', transFormName),
  ];

  // zjištění formátu datumu podle aktuálního jazyka
  const dateFormat = (isInput?: boolean) => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK') return isInput ? 'dd.MM.yyyy' : 'DD.MM.yyyy';
    else return isInput ? 'dd/MM/yyyy' : 'DD/MM/yyyy';
  };

  // zjištění formátu datumu a času podle aktuálního jazyka
  const dateTimeFormat = (isInput?: boolean) => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK')
      return isInput ? 'dd.MM.yyyy HH:mm' : 'DD.MM.yyyy HH:mm';
    else return isInput ? 'dd/MM/yyyy HH:mm' : 'DD/MM/yyyy HH:mm';
  };

  // pro formátování datumu v tabulce
  const getLastValType = (item: TaskParameterAssetCycle) => {
    if (!item) return;
    if (item.lastValue === undefined || item.lastValue === null) return undefined;
    switch (item.unit.baseValueDataType) {
      case BaseValueDataType.Date:
        return moment(item.lastValue).clone().format(dateFormat()).toString();
      case BaseValueDataType.DateTime:
        return moment(item.lastValue).clone().format(dateTimeFormat()).toString();
      case BaseValueDataType.Bool:
        return item.lastValue ? 'true' : 'false';
      default:
        return item.lastValue;
    }
  };

  // hover validace int a float inputů
  const manageActiveInput = (index: number, col: string) => {
    setActiveInput({ index, col });
  };

  const setInputType = (dataType: string) => {
    switch (dataType) {
      case BaseValueDataType.Date:
        return 'date';
      case BaseValueDataType.DateTime:
        return 'datetime';
      case BaseValueDataType.Float || BaseValueDataType.Int:
        return 'number';
      case BaseValueDataType.Text:
        return 'text';
    }
  };

  // čekání než se naplní newData
  if (data.length > 0 && newData.length === 0) return <Loader />;
  return (
    <>
      <PageContentWrapper noContentBlock noDxCard noResponsivePaddings isModal>
        <table style={{ border: '1px solid #e8eaeb', width: '100%', textAlign: 'left' }}>
          <thead>
            <tr>
              {columns.map((c: any, index: number) => (
                <th style={{ width: '20%', padding: 5 }} key={index}>
                  {c}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item: TaskParameterAssetCycle, index: number) => {
              let dataType = item.unit.baseValueDataType;
              return (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#e8eaeb' : 'white',
                  }}
                >
                  <td>{item.name}</td>
                  <td>{item.unit.description + ' (' + item.unit.name + ')'}</td>
                  <td>{getLastValType(item)}</td>
                  <td>
                    {(dataType === BaseValueDataType.Int ||
                      dataType === BaseValueDataType.Float ||
                      dataType === BaseValueDataType.Text) && (
                      <input
                        id={'currentValue_' + index}
                        type={setInputType(dataType)}
                        onMouseEnter={() => manageActiveInput(index, 'currentValue')}
                        onMouseLeave={() => setActiveInput(null)}
                        min={0}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: any) => x.itemIndex === index) === -1
                              ? undefined
                              : '1px solid red',
                        }}
                        name="currentValue"
                        onChange={(e) => {
                          onSaving(e.target.value, 'currentValue', index);
                          onCurrentValueChange(item, e.target.value, index);
                        }}
                        disabled={!newData[index].measurementAllowed}
                        step={dataType === BaseValueDataType.Float ? '0.01' : '1'}
                        value={newData[index]?.currentValue ?? undefined}
                        onKeyUp={(e: any) =>
                          e && e.target && validate(e.target.value, 'currentValue', index)
                        }
                      />
                    )}
                    {dataType === BaseValueDataType.Date && (
                      <DateBox
                        showClearButton
                        id={'currentValue_' + index}
                        type={setInputType(dataType) as any}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: any) => x.itemIndex === index) === -1
                              ? '1px solid gray'
                              : '1px solid red',
                        }}
                        className="filter-field"
                        displayFormat={dateFormat(true)}
                        defaultValue={newData[index]?.currentValue ?? ''}
                        placeholder={translate!('Current value', transFormName)}
                        disabled={!newData[index].measurementAllowed}
                        onValueChanged={(e) => {
                          let val = e.value ? e.value : null;
                          onSaving(
                            formatDateForSubmit(moment(val), dataType),
                            'currentValue',
                            index,
                          );
                          if (val) {
                            validate(val, 'currentValue', index);
                            onCurrentValueChange(
                              item,
                              formatDateForSubmit(moment(val), dataType),
                              index,
                            );
                          }
                        }}
                        onKeyUp={(e: any) => {
                          e && e.target && validate(e.target.value, 'currentValue', index);
                        }}
                        onClosed={(e: any) => {
                          e && e.target && validate(e.target.value, 'currentValue', index);
                        }}
                        isValid={
                          newData[index]?.threshold && newData[index]?.currentValue
                            ? moment(newData[index]?.currentValue).isBefore(
                                moment(newData[index]?.threshold),
                              )
                              ? true
                              : false
                            : true
                        }
                      />
                    )}
                    {dataType === BaseValueDataType.DateTime && (
                      <DateBox
                        showClearButton
                        id={'currentValue_' + index}
                        type={setInputType(dataType) as any}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: any) => x.itemIndex === index) === -1
                              ? '1px solid gray'
                              : '1px solid red',
                        }}
                        className="filter-field"
                        displayFormat={dateTimeFormat(true)}
                        defaultValue={newData[index]?.currentValue ?? ''}
                        placeholder={translate!('Current value', transFormName)}
                        disabled={!newData[index].measurementAllowed}
                        onValueChanged={(e) => {
                          let val = e.value ? e.value : null;
                          onSaving(
                            formatDateForSubmit(moment(val), dataType),
                            'currentValue',
                            index,
                          );
                          if (val) {
                            validate(val, 'currentValue', index);
                            onCurrentValueChange(
                              item,
                              formatDateForSubmit(moment(val), dataType),
                              index,
                            );
                          }
                        }}
                        onKeyUp={(e: any) => {
                          e && e.target && validate(e.target.value, 'currentValue', index);
                        }}
                        onClosed={(e: any) => {
                          e && e.target && validate(e.target.value, 'currentValue', index);
                        }}
                        isValid={
                          newData[index]?.threshold && newData[index]?.currentValue
                            ? moment(newData[index]?.currentValue).isBefore(
                                moment(newData[index]?.threshold),
                              )
                              ? true
                              : false
                            : true
                        }
                      />
                    )}
                    {dataType === BaseValueDataType.Bool && (
                      <SelectBox
                        items={['true', 'false']}
                        value={
                          newData[index]?.currentValue
                            ? newData[index]?.currentValue
                            : newData[index]?.lastValue
                            ? newData[index]?.lastValue
                            : 'false'
                        }
                        onValueChange={(e) => onSaving(e, 'currentValue', index)}
                      />
                    )}
                  </td>
                  <td>
                    {(dataType === BaseValueDataType.Int ||
                      dataType === BaseValueDataType.Float) && (
                      <input
                        id={'threshold_' + index}
                        type={setInputType(dataType)}
                        onMouseEnter={() => manageActiveInput(index, 'threshold')}
                        onMouseLeave={() => setActiveInput(null)}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: any) => x.itemIndex === index) === -1
                              ? undefined
                              : '1px solid red',
                        }}
                        name="nextThreshold"
                        onChange={(e) => onSaving(e.target.value, 'nextThreshold', index)}
                        step={dataType === BaseValueDataType.Float ? '0.01' : undefined}
                        value={
                          newData[index]?.nextThreshold
                            ? newData[index]?.nextThreshold
                            : newData[index]?.threshold
                            ? newData[index]?.threshold
                            : undefined
                        }
                        onBlur={(e) => validate(e.target.value, 'threshold', index)}
                      />
                    )}
                    {dataType === BaseValueDataType.Date && (
                      <DateBox
                        showClearButton
                        id={'threshold_' + index}
                        type={'date'}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: Error) => x.itemIndex === index) === -1
                              ? '1px solid gray'
                              : '1px solid red',
                        }}
                        className="filter-field"
                        displayFormat={dateFormat(true)}
                        value={
                          newData[index]?.nextThreshold
                            ? newData[index]?.nextThreshold
                            : newData[index]?.threshold
                            ? newData[index]?.threshold
                            : undefined
                        }
                        placeholder={translate!('Next threshold', transFormName)}
                        hoverStateEnabled
                        onValueChanged={(e) => {
                          let val = e.value ? e.value : null;
                          onSaving(
                            formatDateForSubmit(moment(val), dataType),
                            'nextThreshold',
                            index,
                          );
                          validate(val, 'threshold', index);
                        }}
                        onOptionChanged={(e) => {
                          if (e.name === 'isValid') setIsValidDateTime(e.value);
                        }}
                        onKeyUp={(e: any) => {
                          e && e.target && validate(e.target.value, 'threshold', index);
                        }}
                        onClosed={(e: any) => {
                          e && e.target && validate(e.target.value, 'threshold', index);
                        }}
                        min={
                          newData[index]?.currentValue
                            ? moment(newData[index]?.currentValue).add(1, 'day').toDate()
                            : moment(newData[index]?.lastValue).add(1, 'day').toDate()
                        }
                        disabled={!isDateEditable}
                        dateOutOfRangeMessage={valueErrorMessage}
                      />
                    )}
                    {dataType === BaseValueDataType.DateTime && (
                      <DateBox
                        showClearButton
                        id={'threshold_' + index}
                        type={'datetime'}
                        style={{
                          width: '100%',
                          margin: '0 auto',
                          border:
                            errors.findIndex((x: Error) => x.itemIndex === index) === -1
                              ? '1px solid gray'
                              : '1px solid red',
                        }}
                        className="filter-field"
                        displayFormat={dateTimeFormat(true)}
                        value={
                          newData[index]?.nextThreshold
                            ? newData[index]?.nextThreshold
                            : newData[index]?.threshold
                            ? newData[index]?.threshold
                            : undefined
                        }
                        placeholder={translate!('Next threshold', transFormName)}
                        hoverStateEnabled
                        onOptionChanged={(e) => {
                          if (e.name === 'isValid') setIsValidDateTime(e.value);
                        }}
                        onValueChanged={(e) => {
                          let val = e.value ? e.value : null;
                          onSaving(
                            formatDateForSubmit(moment(val), dataType),
                            'nextThreshold',
                            index,
                          );
                          if (val) validate(val, 'threshold', index);
                        }}
                        onKeyUp={(e: any) => {
                          e && e.target && validate(e.target.value, 'threshold', index);
                        }}
                        onClosed={(e: any) => {
                          e && e.target && validate(e.target.value, 'threshold', index);
                        }}
                        min={
                          newData[index]?.currentValue
                            ? moment(newData[index]?.currentValue).add(1, 'minutes').toDate()
                            : moment(newData[index]?.lastValue).add(1, 'minutes').toDate()
                        }
                        disabled={!isDateEditable}
                        dateOutOfRangeMessage={valueErrorMessage}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PageContentWrapper>
      {/* hover error hláška - zatím pro int a float inputy   */}
      <Tooltip
        target={activeInput ? '#' + activeInput.col + '_' + activeInput.index : undefined}
        visible={
          errors.length > 0 &&
          activeInput !== null &&
          errors.findIndex((e: Error) => e.itemIndex === activeInput.index) > -1
        }
      >
        <div>
          {activeInput && errors.find((e: Error) => e.itemIndex === activeInput.index)?.message}
        </div>
      </Tooltip>
    </>
  );
};
