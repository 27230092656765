import React from 'react';
import './Row.scss';
type ContainerProps = {
  direction?: string;
  justify?: string;
  align?: string;
};

type LabelProps = {
  required?: boolean;
  classes?: string;
};

type ItemProps = {
  direction?: string;
  justify?: string;
  align?: string;
};

export const Container: React.FC<ContainerProps> = ({
  children,
  direction = 'column',
  justify = 'start',
  align = 'normal',
}) => {
  return (
    <div
      className={`container-${direction === 'column' ? 'col' : 'row'}`}
      style={{ justifyContent: justify, alignItems: align }}
    >
      {children}
    </div>
  );
};

export const Item: React.FC<ItemProps> = ({
  children,
  direction = 'column',
  justify = 'start',
  align = 'normal',
}) => (
  <Container direction={direction} justify={justify} align={align}>
    {children}
  </Container>
);

export const Label: React.FC<LabelProps> = ({ children, classes, required = false }) => (
  <div className={`label ${classes}`}>
    {children} {required ? <span className="property-required">*</span> : ''}
  </div>
);
