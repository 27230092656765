import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { ParametersForm } from '../../forms/parameters/ParametersForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DetailPageParams } from '../../types/pageParams.types';
import { Parameter, ParameterInputs } from '../../types/parameter.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function ParametersEdit() {
  const { translate } = useTranslation();
  const transFormName = 'Parameter.Update';
  const { startLoading, stopLoading } = useLoading();
  const { id } = useParams<DetailPageParams>();
  const { data, error } = useSWR<Parameter>(`${API_URL.PARAMETER_ITEM}?id=${id}`, fetcher);
  const [formData, setFormData] = useState<ParameterInputs>();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit parameter',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Parameters', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Načteme číselník - Unit
  const { data: dataUnits, error: errorUnits } = useSWR(API_URL.UNIT_LIST, fetcher);

  useEffect(() => {
    if (!data) return;

    // TODO: Promyslet, jestli takto chceme dělat
    setFormData({
      ...data,
      baseUnitId: data.baseUnit.id,
    });
  }, [data]);

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!data) return;

    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.PARAMETER_PUT,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!data || !dataUnits || !formData}
      error={error || errorUnits}
      heading={translate!('Edit parameter', transFormName)}
    >
      <ParametersForm onSubmit={onSubmit} formData={formData} dataUnits={dataUnits} isEdit />
    </PageContentWrapper>
  );
}

export default ParametersEdit;
