import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabRevisionCycleColumns';
import AssetCycleModal from '../modals/AssetCycleModal';
import { SisDataGrid } from '../table';
import { IAssetTabCycleDataGrid } from './AssetTabCycleDataGrid';

export interface IAssetTabRevisionCycleDataGrid extends IAssetTabCycleDataGrid {
  revisionCategories: any;
}

export const AssetTabRevisionCycleDataGrid = ({
  data,
  onSelectionChanged,
  isReadOnly,
  capacityCategories,
  revisionCategories,
  handleNew,
  handleEdit,
  handleClose,
  modalVisibility,
  selectedData,
  selectedAction,
  onDataSubmit,
}: IAssetTabRevisionCycleDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.RevisionCycle';

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset revision cycles', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(data, handleEdit, isReadOnly, translate)}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        rowAlterantionEnabled
        columnChooser={false}
        allowColumnResizing={false}
        isReadOnly={isReadOnly}
        allowColumnReordering={false}
        selection={{ mode: 'single' }}
        prompt={Prompt}
      />
      {modalVisibility && (
        <AssetCycleModal
          isVisible={modalVisibility}
          data={selectedData ?? null}
          actionType={selectedAction}
          capacityCategories={capacityCategories}
          revisionCategories={revisionCategories}
          documentTypes={undefined}
          transFormName={transFormName}
          onSubmit={onDataSubmit}
          onClose={handleClose}
        />
      )}
    </>
  );
};
