import { and, not, path, prop } from 'ramda';
import { isNilOrEmpty, isNotNil } from 'ramda-extension';
import { useEffect, useState } from 'react';
import { useSessionStorage } from './useSessionStorage';

type ColumnInfoType = {
  dataField: string;
  dataType: string;
  name: string;
  visible: boolean;
  visibleIndex: number;
  width: any;
};

export type DataGridStateType = {
  allowedPageSizes: Array<number>;
  columns: Array<ColumnInfoType>;
  filterPanel: any;
  filterValue: any;
  pageIndex: number;
  pageSize: number;
  searchText: string;
  selectedRowKeys: Array<any>;
};

interface ISaveFilterReturnType {
  saveFilter: () => object | null;
  loadFilter: (state) => object | null;
  resetFilter: () => void;
  getActualState: () => DataGridStateType | null;
  data: any;
  isFilterActive: boolean;
  getActualJsonState: (force: boolean) => string | null;
}

export const useSaveDataGridFilter = (key: string, dataGridRef: any): ISaveFilterReturnType => {
  const [data, setData] = useSessionStorage(key, {});
  const isCurrent = and(
    isNotNil(dataGridRef),
    isNotNil(path(['current', 'instance', dataGridRef])),
  );
  const [isFilterActive, setIsFilterActive] = useState<boolean>(
    not(isNilOrEmpty(prop('filterValue', data))),
  );
  const dataNotEmptyAndCurrent = and(not(isNilOrEmpty(data)), isCurrent);

  useEffect(() => {
    if (not(isNilOrEmpty(prop('filterValue', data)))) setIsFilterActive(true);
  }, [data]);

  const saveFilter = () => {
    if (!dataGridRef || !dataGridRef.current) return;
    if (isCurrent) {
      const data = dataGridRef.current.instance.state();
      setData(data);
      setIsFilterActive(true);
      return data;
    }
    return null;
  };

  // TODO: Projít state úpravu
  const loadFilter = (state = null) => {
    if (!dataGridRef || !dataGridRef.current) return;
    if (!state && dataNotEmptyAndCurrent) {
      dataGridRef.current.instance.state(data);
      setIsFilterActive(true);
      return data;
    } else if (state) {
      dataGridRef.current.instance.state(state);
      setIsFilterActive(true);
      return data;
    }
    return null;
  };

  const resetFilter = () => {
    if (!dataGridRef || !dataGridRef.current) return;
    if (dataNotEmptyAndCurrent) {
      dataGridRef?.current?.instance?.state(null);
      dataGridRef?.current?.instance?.clearFilter();
      localStorage.removeItem(key);
      setIsFilterActive(false);
    }
  };

  const getJSONState = () => {
    return JSON.stringify(dataGridRef?.current?.instance?.state());
  };

  const getActualJsonState = (force = false) => {
    if (force || dataNotEmptyAndCurrent) {
      return getJSONState();
    }
    return null;
  };

  const getActualState = () => {
    if (dataNotEmptyAndCurrent) {
      return dataGridRef?.current?.instance?.state();
    }
    return null;
  };
  return {
    saveFilter,
    loadFilter,
    resetFilter,
    data,
    getActualJsonState,
    getActualState,
    isFilterActive,
  };
};
