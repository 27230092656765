import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AssetClassParametersForm } from '../../forms/assetClassParametersEdit/AssetClassParametersForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetClass } from '../../types/assetClass.types';
import { AssetClassParameterInputs } from '../../types/assetClassParameter.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { Parameter } from '../../types/parameter.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function AssetClassParametersEdit() {
  const { translate } = useTranslation();
  const transFormName = 'AssetClassParameter.Update';
  const { id } = useParams<DetailPageParams>();
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit asset class parameters',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Asset class parameters', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  // Číselník - AssetClass
  const { data: dataAssetClasses, error: errorAssetClasses } = useSWR<AssetClass[]>(
    API_URL.ASSET_CLASS_LIST,
    fetcher,
  );
  // Číselník - Parameter
  const { data: dataParameters, error: errorParameters } = useSWR<Parameter[]>(
    API_URL.PARAMETER_LIST,
    fetcher,
  );
  // Data - AssetClassParameter
  const { data, error } = useSWR<AssetClassParameterInputs>(
    `${API_URL.ASSET_CLASS_PARAMETER_ITEM}?id=${id}`,
    fetcher,
  );

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!data) return;
    let isOk = false;
    startLoading();

    await putApi({
      url: API_URL.ASSET_CLASS_PARAMETER_PUT,
      data,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!data || !dataAssetClasses || !dataParameters}
      error={error || errorAssetClasses || errorParameters}
      heading={translate!('Edit asset class parameter', transFormName)}
    >
      <AssetClassParametersForm
        onSubmit={onSubmit}
        dataAssetClasses={dataAssetClasses}
        dataParameters={dataParameters}
        formData={data}
        isEdit
      />
    </PageContentWrapper>
  );
}
export default AssetClassParametersEdit;
