import moment from 'moment';
import { useCallback, useState } from 'react';
import { FieldType } from '../types/field.types';
import { RuleType } from '../types/rule.types';
import { getFilterDate } from '../utils/dateFilters';

type FieldProps = {
  name: string;
  fieldType: FieldType | Array<FieldType> | any;
  defaultValue?: string | Date | undefined | null | [];
  dataSource?: Array<any>;
  displayExpr?: string;
  parentKeyExpr?: string;
  keyExpr?: string;
  placeholder?: string;
  rules?: Array<RuleType>;
  maxLength?: number;
};

/**
 * Funkce pro zpracování fieldů do jednoho objektu ve formě:
 * params = {
 *  "názevFieldu": {
 *    type: "typ fieldu" | ["více", "typů"]
 *  },
 *  "názevFieldu2": {
 *    type: "typ fieldu" | ["více", "typů"]
 *  },
 *   ...
 * }
 * @param fields pole se všemi fieldy, které chceme zpracovat
 * @param ignoredFields fieldy, které nechceme zpracovávat - může být prázdné
 * @returns zpracovaný objekt s fieldy
 */
export const getParameters = (fields: Array<any>, ignoredFields: Array<any> = []) => {
  const params = {};
  fields
    .filter((item) => !ignoredFields.includes(item) || item.name === null || item.name.length === 0)
    .forEach((item) => {
      params[item.name] = {
        type: item.fieldType,
        value: item.value?.value ? item.value?.value : item.value ?? null,
      };
    });
  return params;
};

/**
 * Funkce pro nastavení všech fieldů podle zadaného filtru
 *  -> hodnoty do fieldů se nastavují podle "názvuFiltru" ve "filter"
 * @param fields fieldy, které chceme nastavovat
 * @param filter hodnota settings filtru z API
 */
export const setParameters = async (fields: Array<any>, filter: any) => {
  for (const item of fields) {
    item.setValue(filter[item.name]?.value);
  }
};

export const parseParameters = (fields: Array<any>) => {
  const params = {};
  fields.forEach((item) => {
    const types = Array.isArray(item.fieldType) ? item.fieldType : [item.fieldType];
    const isDateType = types.some((type) =>
      [FieldType.Date, FieldType.DateSelect, FieldType.DateSelectWithDiffField].includes(type),
    );
    params[item.name] = isDateType
      ? item.value
        ? item.value
          ? moment(
              item.value?.option
                ? getFilterDate(item.value.option.value, item.value.diff)
                : getFilterDate(item.value),
            ).format('YYYY-MM-DD HH:mm:ss')
          : undefined
        : undefined
      : item.value ?? null;
  });
  return params;
};

export const parseFilterParameters = (filter: any) => {
  const params = {};
  Object.keys(filter).forEach((item: string) => {
    const attribute = filter[item];
    const types = Array.isArray(attribute.type) ? attribute.type : [attribute.type];
    const isDateType = types.some((type) =>
      [FieldType.Date, FieldType.DateSelect, FieldType.DateSelectWithDiffField].includes(type),
    );
    params[item] = isDateType
      ? attribute.value
        ? moment(
            attribute.value?.option
              ? getFilterDate(attribute.value.option.value, attribute.value.diff)
              : getFilterDate(attribute.value),
          ).format('YYYY-MM-DD HH:mm:ss')
        : undefined
      : attribute.value ?? null;
  });
  return params;
};

/**
 * Funkce na smazání/nastavení defaultních hodnot pro zadané fieldy
 * @param fields fieldy, které chceme nastavit do výchozích hodnot
 */
export const clear = (fields: Array<any>) => {
  fields.forEach((item) => {
    item.setValue(item.defaultValue);
  });
};

/**
 * Custom hook pro práci s filtry:
 * - name: název fieldu (takto se bude ukládat do settings.filter v API)
 * - fieldType: typ fieldu z FieldType pro zpracování komponentou FormField
 *              - může být i více typů (např. pro DateOrSelectField)
 * - defaultValue: výchozí hodnota, pokud nezadání, tak je undefined
 * - dataSource: datasource pro DropDownBoxy nebo DateOrSelectField (pro povolené relativní datumy - "Current day", ...)
 * - rules: list pravidel, která se na field mají uplatňovat ve formě { type: RuleType, message: "zpráva"}
 *          - aktuálně funguje jen pro RequiredRule
 */
export const useField = ({
  name,
  fieldType,
  placeholder,
  defaultValue = undefined,
  dataSource,
  displayExpr,
  parentKeyExpr,
  keyExpr,
  rules,
  maxLength = undefined,
}: FieldProps) => {
  const [state, setState] = useState<any>(defaultValue);

  const setValue = useCallback((value) => {
    setState(value);
  }, []);

  return {
    name,
    fieldType,
    dataSource,
    value: state,
    setValue,
    displayExpr,
    keyExpr,
    parentKeyExpr,
    placeholder,
    rules,
    defaultValue,
    maxLength,
  };
};
