import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { deleteApi } from '../../apiUtils';
import { TaskNotificationConfigsDataGrid } from '../../components/datagrid/TasksNotificationConfigsDataGrid';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { confirmDialogActions, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetLocation } from '../../types/assetLocation.types';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { NotificationTemplateApiResponse } from '../../types/notificationTemplate.types';
import { TaskNotificationConfig } from '../../types/taskNotificationConfig.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { DevExpressDateSerializationFormat } from '../../utils/dates.types';
import { swrConfig } from '../Assets/AssetsEdit';

export interface TaskNotificationConfigsFilterData {
  dateFrom: string | null;
  dateTo: string | null;
}

const defaultFilterData: TaskNotificationConfigsFilterData = {
  dateFrom: `${moment().add(-30, 'days').format(DevExpressDateSerializationFormat)}`,
  dateTo: `${moment().format(DevExpressDateSerializationFormat)}`,
};

/**
 * Přehled entity task notification config.
 */
export function TaskNotificationConfigs() {
  const { assetClass } = useAssetClass();
  const { setValue } = useBreadcrumbs();
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'TaskNotificationConfig.List';
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGrid = useRef<any>(null);
  // Nastavení filtru (mimo DataGrid)
  const [filterData, setFilterData] = useState(defaultFilterData);

  const {
    data: taskNotificationConfigList,
    error: taskNotificationConfigError,
    mutate: mutateNotificationConfigList,
  } = useSWR<TaskNotificationConfig[]>(
    `${API_URL.TASK_NOTIFICATION_CONFIG_LIST_CURRENT_ASSET_CLASS}`,
    fetcher,
  );

  const { data: catacityCategoryList, error: catacityCategoryError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  const { data: notificationTemplateList, error: notificationTemplateError } =
    useSWR<NotificationTemplateApiResponse>(
      `${API_URL.NOTIFICATION_TEMPLATE_LIST}`,
      fetcher,
      swrConfig,
    );

  const { data: assetLocationList, error: assetLocationError } = useSWR<AssetLocation[]>(
    API_URL.ASSET_LOCATION_LIST,
    fetcher,
  );

  useEffect(() => {
    setValue({
      pageName: 'Task notification configs',
      steps: [{ name: 'Home', pathToCutCount: 1 }],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Task notification configs', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };

  const handleNew = () => {
    history.push({
      pathname: `/tasks/notification-config/new/`,
      state: { backRoute: '/tasks/notification-config', isNew: true },
    });
  };

  const handleEdit = (e: any) => {
    history.push({
      pathname: `/tasks/notification-config/edit/${e.row.data.id}`,
      state: { backRoute: '/tasks/notification-config', isNew: false },
    });
  };

  const { open } = useConfirmDialog({
    title: translate!('Delete', ''),
    bodyHtml: translate!('Are you sure?', ''),
    cancelButtonTitle: translate!('Cancel', ''),
    confirmButtonTitle: translate!('Confirm', ''),
  });

  const handleRemove = async (e: any) => {
    open().then(async (c) => {
      if (c === confirmDialogActions.CONFIRM) {
        await deleteApi({
          url: `${API_URL.TASK_NOTIFICATION_CONFIG_DELETE}?id=${e.data.id}`,
          id: e.data.id,
          callAfterSuccess: () => {
            mutateNotificationConfigList();
          },
        });
      } else if (c === confirmDialogActions.CANCEL) {
        return false;
      }
    });
  };

  /**
   * Handle změny filtru mimo DataGrid
   * @param filterData Data z filtru
   */
  const handleFilterChanged = (filterData) => {
    setFilterData({ ...filterData });
    mutateNotificationConfigList();
  };

  return (
    <PageContentWrapper
      error={
        taskNotificationConfigError ||
        catacityCategoryError ||
        notificationTemplateError ||
        assetLocationError
      }
      loading={
        !taskNotificationConfigList ||
        !catacityCategoryList ||
        !notificationTemplateList ||
        !assetLocationList
      }
    >
      {catacityCategoryList && assetLocationList && notificationTemplateList && (
        <TaskNotificationConfigsDataGrid
          dataGridRef={dataGrid}
          data={taskNotificationConfigList}
          mutateData={mutateNotificationConfigList}
          onToolbarPreparing={onToolbarPreparing}
          defaultFilterData={filterData}
          onFilterChanged={handleFilterChanged}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          notificationTemplateList={notificationTemplateList}
        />
      )}
    </PageContentWrapper>
  );
}

export default TaskNotificationConfigs;
