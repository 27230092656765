import { Form, Popup } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/bulk-modal.scss';
import { useTranslation } from '../../contexts/translation';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { BaseValueDataType } from '../../types/parameter.types';
import {
  dateFieldOptionsWithSerialization,
  dateTimeFieldOptionsWithSerialization,
} from '../../utils/dates.types';
import { validationNullableDateRule } from '../../utils/dateValidation';
import './TasksBulkEditModal.scss';

type Props = {
  data: any;
  isVisible: boolean;
  parameters: any;
  parameterChanged: any;
  units: any;
  selectedUnitId?: number;
  mutate?: () => void;
  onClose: () => void;
  onSubmit: (data: any, actionType: DataGridActionType) => void;
  actionType: DataGridActionType;
  transFormName: string;
};

/**
 * Modalová komponent pro vytváření/editaci záznamů typu Asset parameter v datagridu
 */
export default function AssetParameterModal({
  isVisible,
  parameters,
  parameterChanged,
  units,
  selectedUnitId,
  data,
  actionType,
  onClose,
  onSubmit,
  transFormName,
}: Props) {
  const { translate } = useTranslation();
  const formRef = useRef<Form>(null);
  const [selectedUnit, setSelectedUnit] = useState<string>();
  const [selectedParamId, setSelectedParamId] = useState<number>();
  const [showValField, setShowValField] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>({
    ...data,
    ...(actionType === DataGridActionType.Insert && { visible: true }),
  });

  useEffect(() => {
    setFormData({
      ...data,
      ...(actionType === DataGridActionType.Insert && { visible: true }),
    });
  }, []);

  // zakázat editaci value když measurementAllowed === true
  useEffect(() => {
    if (data && data.parameterMeasurementAllowed) {
      setShowValField(false);
    } else if (data && data.parameterMeasurementAllowed === false) setShowValField(true);
    if (
      !data &&
      parameters &&
      parameters.find((x: any) => x.id === selectedParamId)?.measurementAllowed === true
    ) {
      setShowValField(false);
    } else if (parameters.find((x: any) => x.id === selectedParamId)?.measurementAllowed === false)
      setShowValField(true);
  }, [data, selectedParamId]);

  useEffect(() => {
    if (!data || !units) return;
    setUnitType(data.unitId);
  }, []);

  useEffect(() => {
    if (!selectedUnitId || data) return;
    setUnitType(selectedUnitId);
    setFormData({ ...formData, unitId: selectedUnitId });
  }, [selectedUnitId]);

  const setUnitType = (selectedUnitId: number) => {
    let tmpUnit = units.find((u: any) => u.id === selectedUnitId);
    if (tmpUnit) setSelectedUnit(tmpUnit.baseValueDataType);
  };

  return (
    <Popup
      visible={isVisible}
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={onClose}
      showTitle
      title={translate!('Asset parameter', transFormName)}
      width={'auto'}
      height={'auto'}
      minWidth={'15vw'}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData, actionType);
        }}
      >
        <Form
          formData={formData}
          showColonAfterLabel={true}
          showValidationSummary={true}
          ref={formRef}
        >
          <GroupItem visible={actionType === DataGridActionType.Insert}>
            <SimpleItem
              dataField="parameterId"
              editorType="dxSelectBox"
              editorOptions={{
                items: parameters,
                valueExpr: 'id',
                displayExpr: 'name',
                width: 300,
                onValueChanged: (v) => {
                  parameterChanged(v);
                  setSelectedParamId(v.value);
                },
              }}
            >
              <Label text={translate!('Parameter', transFormName)} />
              <RequiredRule message={translate!('Parameter is required', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={actionType === DataGridActionType.Insert}>
            <SimpleItem
              dataField="unitId"
              editorType="dxSelectBox"
              editorOptions={{
                readOnly: true,
                items: units,
                valueExpr: 'id',
                displayExpr: 'name',
                width: 300,
              }}
            >
              <Label text={translate!('Unit', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Float && showValField}>
            <SimpleItem
              dataField="valueFloat"
              editorType="dxNumberBox"
              editorOptions={{
                width: 300,
              }}
            >
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Int && showValField}>
            <SimpleItem
              dataField="valueInt"
              editorType="dxNumberBox"
              editorOptions={{
                width: 300,
              }}
            >
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>

          <GroupItem visible={selectedUnit === BaseValueDataType.Bool && showValField}>
            <SimpleItem
              dataField="value"
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  { id: true, name: 'True' },
                  { id: false, name: 'False' },
                ],
                valueExpr: 'id',
                displayExpr: 'name',
                width: 300,
              }}
            >
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.Date && showValField}>
            <SimpleItem
              dataField="valueDate"
              editorType="dxDateBox"
              editorOptions={{
                ...dateFieldOptionsWithSerialization,
                width: 300,
              }}
            >
              <Label text={translate!('Value', transFormName)} />
              <CustomRule
                validationCallback={validationNullableDateRule}
                type={'custom'}
                message={'Something bad with time'}
                ignoreEmptyValue
              />
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={selectedUnit === BaseValueDataType.DateTime && showValField}>
            <SimpleItem
              dataField="valueDateTime"
              editorType="dxDateBox"
              editorOptions={{
                ...dateTimeFieldOptionsWithSerialization,
                width: 300,
              }}
            >
              <CustomRule
                validationCallback={validationNullableDateRule}
                type={'custom'}
                message={'Somethind bad with time'}
                ignoreEmptyValue
              />
              <Label text={translate!('Value', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem>
            <SimpleItem dataField="visible" editorType="dxCheckBox">
              <Label text={translate!('Visible', transFormName)} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={4}>
            <GroupItem colSpan={2}></GroupItem>
            <ButtonItem>
              <ButtonOptions type={'success'} text={translate!('Save', '')} useSubmitBehavior />
            </ButtonItem>
            <ButtonItem>
              <ButtonOptions type={'normal'} text={translate!('Close', '')} onClick={onClose} />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </Popup>
  );
}
