// *** Jazyk - hlavní číselník. Odpovídá backendu. ***
export enum Language {
  EN = 'en-US',
  CS = 'cs-CZ',
  SK = 'sk-SK',
}

export interface LangugeSelectOption {
  id: number;
  name: string;
}

// *** Translations ***
export interface Translation {
  formName: string;
  referenceText: string;
  translationText: string;
}
