import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { AlertMessage } from '../alertMessage/AlertMessage';

interface FailedLoadProps {
  message: string | null;
}

/**
 * Komponenta pro chybovou hlášku, která se zobrazuej napříč systémem při chybném načtení dat.
 * Pro zobrazení hlášky využívá komponentu AlertMessage.
 * @param props
 * @constructor
 */
export function FailedLoad(props: FailedLoadProps) {
  const { translate } = useTranslation();
  const transFormName = '';

  return (
    <AlertMessage
      message={props.message === null ? translate!('Failed to load', transFormName) : props.message}
      class={'danger'}
    />
  );
}
