import { IDataType } from '../../../components/table';

export const Columns = [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset id',
    width: '5%',
  },
  {
    dataField: 'statusName',
    caption: 'Status',
    width: '7%',
    lookupPickBy: 'statusName',
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '15%',
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '15%',
  },
  {
    dataField: 'model',
    caption: 'Model',
    width: '10%',
  },
  {
    dataField: 'typeName',
    caption: 'Type',
    width: '10%',
    lookupPickBy: 'typeName',
  },
  {
    dataField: 'typeClassName',
    caption: 'Class',
    width: '10%',
  },
  {
    dataField: 'inventoryNumber',
    caption: 'Inventory number',
    width: '10%',
  },
  {
    dataField: 'serialNumber',
    caption: 'Serial number',
    width: '10%',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '8%',
  },
];
