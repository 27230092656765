import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import TasksForm, { TaskInputsNew } from '../../../forms/tasks/TasksForm';
import { useIsTaskEditable } from '../../../hooks/useIsTaskEditable';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { Task } from '../../../types/task.types';
import { WorkSheet } from '../../../types/workSheet.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { ITab, swrConfig } from '../../Assets/AssetsEdit';

interface Props extends ITab {
  id: string;
  worksheets: WorkSheet[];
}

export const GeneralTaskTab = ({ id, worksheets }: Props) => {
  const [isReadOnly] = useIsTaskEditable(id, true);
  const [defaultFormData, setDefaultFormData] = useState<TaskInputsNew>({});
  const [oldRequiredDate, setOldRequiredDate] = useState<Date | null | string>();

  /**
   * Načte task podle id.
   */
  const {
    data: task,
    error: taskError,
    mutate: mutateTask,
  } = useSWR<Task>(`${API_URL.TASK_ITEM}?id=${id}`, fetcher, swrConfig);

  /**
   * Po načtení tasku je nutné nastavit defaultní hodnoty formuláře.
   */
  useEffect(() => {
    if (!task) return;
    setDefaultFormData({
      id: task.id,
      assetTopLevelParenId: task.assetTopLevelParenId,
      assetId: task.assetId,
      typeId: task.typeId,
      typeName: task.typeName,
      typeNameTranslated: task.typeNameTranslated,
      capacityCategoryId: task.capacityCategoryId,
      taskName: task.taskName,
      requiredDate: task.requiredDate,
      downtimeFrom: task.downtimeFrom ? moment(task.downtimeFrom).format('Y-MM-DD HH:mm') : null,
      downtimeTo: task.downtimeTo ? moment(task.downtimeTo).format('Y-MM-DD HH:mm') : null,
      description: task.description,
      diagnose: task.diagnose,
      responsiblePersonId: task.responsiblePersonId,
      assetCycleRunId: task.assetCycleRunId,
    });
    setOldRequiredDate(task.requiredDate);
  }, [task]);

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      error={taskError}
      loading={!task || !defaultFormData}
    >
      <TasksForm
        defaultFormData={defaultFormData}
        onSubmitDone={() => {}}
        isReadOnly={isReadOnly}
        oldRequiredDate={oldRequiredDate}
        isEdit
        transFormName={'Task.Update'}
        mutateTask={mutateTask}
        worksheets={worksheets}
      />
    </PageContentWrapper>
  );
};
