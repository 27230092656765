import { Button, CheckBox, Popup } from 'devextreme-react';
import Form, { GroupItem } from 'devextreme-react/form';
import React, { FormEvent, useRef, useState } from 'react';
import useSWR from 'swr';
import { postApi } from '../../../apiUtils';
import ReportFilterSelector, {
  FilterActionState,
} from '../../../components/reportSelector/ReportSelector';
import { useAssetClass } from '../../../contexts/assetClass';
import { useLoading } from '../../../contexts/loadingContext';
import { useTranslation } from '../../../contexts/translation';
import { clear, getParameters, parseParameters, useField } from '../../../hooks/useField';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { Asset } from '../../../types/asset.types';
import { CapacityCategory, CapacityCategoryPerson } from '../../../types/capacityCategory.types';
import { FieldType } from '../../../types/field.types';
import { Rule } from '../../../types/rule.types';
import { TimePeriod } from '../../../types/timeperiod.types';
import { WorkActivity } from '../../../types/workActivity.types';
import { WorkSheet } from '../../../types/workSheet.types';
import { WorkSheetStatus } from '../../../types/WorkSheetStatus';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { DateFilter } from '../../../utils/dateFilters';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import FieldLabel from '../components/FieldLabel';
import FormField from '../components/FormField';
import DeleteButton from '../components/ReportDeleteButton';
import '../ReportFilter.scss';

type Props = {
  dataGridRef: React.Ref<any>;
  onDataChange: (data: Array<WorkSheet> | null) => void;
  onFilterChange: (filter: string | null) => void;
  report: {
    id: number;
    key: string;
  };
  filterVisibility: boolean;
  loadDefaultSetting: boolean;
};

/**
 * Komponenta představující filtr s report selectorem pro Report zodpovědných osob (Asset responsible persons)
 */
export default function GroupedWorkSheetReportFilter({
  dataGridRef,
  onDataChange,
  onFilterChange,
  report,
  filterVisibility,
  loadDefaultSetting,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [dialogAction, setDialogAction] = useState<FilterActionState | null>(null);
  const [isDefault, setDefault] = useState<boolean>(false);
  const { startLoading, loading, stopLoading } = useLoading();
  const { assetClass } = useAssetClass();

  const { translate } = useTranslation();
  const transFormName = 'WorkSheet.List';
  const transFormRepSelector = 'Reports.ReportSelector';
  const transFormReportBar = 'Reports.Bar';
  // reference pro report selector na kterou jsou navázané funkce pro práci s filtry:
  //  - smazání, přidání / editace filtru
  const selectorRef = useRef<any>();

  const [filterSettingSession, setFilterSettingSession] = useSessionStorage(
    STORAGE_KEYS.GROUPED_WORKSHEETS_REPORT_DATAGRID_DESKTOP,
    {},
  );

  /**
   * Funkce pro fetch dat z API dle parametrů ve fieldech / defaultních hodnot
   */
  const getData = async (params = {}) => {
    const processedParams = { ...params };
    // @ts-ignore
    processedParams.worksheetActivityIdArr = params?.worksheetActivityIdArr?.map((item) => {
      if (typeof item !== 'string') return item;
    });
    const response = await postApi<Array<WorkSheet>>({
      url: API_URL.GROUPED_WORKSHEET_REPORT_REQUEST,
      data: processedParams,
      hideNotifications: true,
    });
    onDataChange(response ?? null);
    stopLoading();
  };

  /**
   * Funkce pro vyhledávání - po zadání hodnot ve filter baru
   */
  const onSearch = (e: FormEvent) => {
    startLoading();
    e.preventDefault();
    const params = parseParameters(fields);
    getData(params);
    const filterParams = getParameters(fields);
    setFilterSettingSession(filterParams);
  };

  /**
   * Funkce pro smazání hodnot z fieldů - reset funkce
   */
  const clearDataAndFields = () => {
    clear(fields);
    onDataChange([]);
  };

  /**
   * SWR fetch dat z API pro naplnění field data sourců
   */

  // Číselník časových období
  const { data: timePeriods } = useSWR<TimePeriod[]>(`${API_URL.TIME_PERIOD_LIST}`, fetcher);

  // Číselník typů
  const { data: types } = useSWR<DocumentType[]>(
    `${API_URL.DOCUMENT_TYPE_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  // Číselník kategorií
  const { data: capacityCategories } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  // Číselník stavů
  const { data: states } = useSWR<WorkSheetStatus[]>(
    `${API_URL.WORKSHEET_STATUS_LIST}?visibleOnly=true`,
    fetcher,
  );

  // Číselník strojů
  const { data: assets } = useSWR<Asset[]>(
    `${API_URL.ASSET_LIST_BY_CLASS}?classId=${assetClass}`,
    fetcher,
  );

  /**
   * Načte se seznam činností
   */
  const { data: workActivities, error: workActivitiesError } = useSWR<WorkActivity[]>(
    `${API_URL.WORK_ACTIVITY_LIST_BY_ASSET_CLASS}?assetClassId=${assetClass}`,
    fetcher,
  );

  /**
   * Načte zodpovědné osoby pro task action. Pouze v editaci.
   */
  const { data: workSheetOperators, error: workSheetOperatorsError } = useSWR<
    CapacityCategoryPerson[]
  >(
    /* formData.capacityCategoryId ? `${API_URL.CAPACITY_CATEGORY_PERSON_LIST}?parentId=${formData.capacityCategoryId}` : `${API_URL.CAPACITY_CATEGORY_PERSON_LIST}`, */
    `${API_URL.PERSON_LIST_WITH_CAPACITY_CAT}`,
    fetcher,
  );

  /**
   * Definice fieldů pro filter bar + form na přidání nového / editaci stávajího filtru
   */

  const filterNameField = useField({
    name: 'name',
    fieldType: FieldType.Text,
    placeholder: translate!('Filter name', transFormRepSelector),
    rules: [{ type: Rule.RequiredRule, message: 'Filter name is required' }],
    maxLength: 50,
  });

  const timePeriodField = useField({
    name: 'timePeriodIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: timePeriods,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Time period', transFormName),
  });

  const dateFromField = useField({
    name: 'activityStartFrom',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearStart,
      DateFilter.PreviousMonthStart,
      DateFilter.PreviousWeekStart,
      DateFilter.PreviousDayStart,
      DateFilter.CurrentDayStart,
      DateFilter.CurrentWeekStart,
      DateFilter.CurrentMonthStart,
      DateFilter.CurrentYearStart,
      DateFilter.NextDayStart,
      DateFilter.FollowingWeekStart,
      DateFilter.FollowingMonthStart,
      DateFilter.FollowingYearStart,
    ],
    defaultValue: undefined,
    placeholder: translate!('Activity Start', transFormName),
  });

  const dateToField = useField({
    name: 'activityFinishTo',
    fieldType: [FieldType.Date, FieldType.DateSelectWithDiffField],
    dataSource: [
      DateFilter.PreviousYearEnd,
      DateFilter.PreviousMonthEnd,
      DateFilter.PreviousWeekEnd,
      DateFilter.PreviousDayEnd,
      DateFilter.CurrentDayEnd,
      DateFilter.CurrentWeekEnd,
      DateFilter.CurrentMonthEnd,
      DateFilter.CurrentYearEnd,
      DateFilter.NextDayEnd,
      DateFilter.FollowingWeekEnd,
      DateFilter.FollowingMonthEnd,
      DateFilter.FollowingYearEnd,
    ],
    defaultValue: undefined,
    placeholder: translate!('Activity Finish', transFormName),
  });

  const assetIdArrayField = useField({
    name: 'taskAssetIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: assets,
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Asset name', transFormName),
  });

  const documentTypeIdArrayField = useField({
    name: 'taskTypeIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: types ?? [],
    keyExpr: 'id',
    displayExpr: 'nameTranslated',
    placeholder: translate!('Type', transFormName),
  });

  const taskStateIdArrayField = useField({
    name: 'statusIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: states ?? [],
    keyExpr: 'id',
    displayExpr: 'nameTranslated',
    placeholder: translate!('Status', transFormName),
  });

  const capacityCategoryIdArrayField = useField({
    name: 'capacityCategoryIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: capacityCategories ?? [],
    keyExpr: 'id',
    displayExpr: 'name',
    placeholder: translate!('Capacity Category', transFormName),
  });

  const operatorIdArrayField = useField({
    name: 'operatorIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: workSheetOperators ?? [],
    keyExpr: 'personalNumber',
    displayExpr: 'fullName',
    placeholder: translate!('Operator', transFormName),
  });

  let workActivitiesGroups: any = [];
  workActivities?.forEach((item, index) => {
    const customGroupId = `c_${item.workActivityGroupId}`;
    // @ts-ignore
    item.workActivityGroupIdCustom = customGroupId;
    if (workActivitiesGroups.find((x) => x._id === item.workActivityGroupId)) return;
    workActivitiesGroups = [
      ...workActivitiesGroups,
      {
        _id: item.workActivityGroupId,
        id: `c_${item.workActivityGroupId}`,
        name: item.workActivityGroupName,
        expanded: true,
      },
    ];
  });

  const worksheetActivityIdArrayField = useField({
    name: 'worksheetActivityIdArr',
    fieldType: FieldType.MultipleOptions,
    dataSource: workActivities ? [...workActivities, ...workActivitiesGroups] : [],
    keyExpr: 'id',
    parentKeyExpr: 'workActivityGroupIdCustom',
    displayExpr: 'name',
    placeholder: translate!('Activity', transFormName),
  });

  const fields = [
    filterNameField,
    /* timePeriodField, */
    dateFromField,
    dateToField,
    documentTypeIdArrayField,
    capacityCategoryIdArrayField,
    taskStateIdArrayField,
    assetIdArrayField,
    operatorIdArrayField,
    worksheetActivityIdArrayField,
  ];

  /**
   * Funkce pro zpracování formuláře při přidání nového / editaci stávajícího filtru
   */
  const onFilterDialogSubmit = (e) => {
    e.preventDefault();
    selectorRef.current.saveOrEditFilter(dialogAction);
  };

  const handleDialogVisibility = (action: FilterActionState | null = null) => {
    setDialogAction(action);
    setIsModalVisible(!!action);
  };

  return (
    <>
      <div>
        <div
          className="report-selector-bar"
          style={{ display: filterVisibility ? 'block' : 'none' }}
        >
          <ReportFilterSelector
            ref={selectorRef}
            filterName={filterNameField.value}
            fields={fields}
            dataGridRef={dataGridRef}
            onClear={clearDataAndFields}
            onFilterChange={onFilterChange}
            report={report}
            openDialog={handleDialogVisibility}
            fetchData={getData}
            isDefault={isDefault}
            setIsDefault={(value) => setDefault(value)}
            loadDefaultSetting={loadDefaultSetting}
            loadDefaultSettingStorageKey={STORAGE_KEYS.GROUPED_WORKSHEETS_REPORT_DATAGRID_DESKTOP}
          />
        </div>
        {isModalVisible && (
          <Popup
            visible={isModalVisible}
            dragEnabled={false}
            closeOnOutsideClick={true}
            onHiding={() => handleDialogVisibility(null)}
            showTitle={true}
            title={
              dialogAction === FilterActionState.EDIT
                ? translate!('Edit selected filter', transFormRepSelector)
                : translate!('Save a new filter', transFormRepSelector)
            }
            container=".dx-viewport"
            width="auto"
            height="auto"
          >
            {/* filter add/edit dialog */}
            <form onSubmit={onFilterDialogSubmit}>
              <Form colCount={3} className="fields-container">
                <GroupItem colSpan={3} colCount={3}>
                  <GroupItem>
                    <FieldLabel label={filterNameField.placeholder} />
                    <FormField field={filterNameField} />
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={3} colCount={3}>
                  {/*
                  <GroupItem colSpan={1}>
                    <FieldLabel label={timePeriodField.placeholder} />
                    <FormField field={timePeriodField} />
                  </GroupItem>
                   */}
                  <GroupItem colSpan={1}>
                    <FieldLabel label={dateFromField.placeholder} />
                    <FormField
                      field={dateFromField}
                      max={dateToField.value}
                      options={{ defaultTime: '00:00:00' }}
                      alt
                    />
                  </GroupItem>
                  <GroupItem colSpan={1}>
                    <FieldLabel label={dateToField.placeholder} />
                    <FormField
                      field={dateToField}
                      min={dateFromField.value}
                      options={{ defaultTime: '23:59:59' }}
                      alt
                    />
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={3} colCount={3}>
                  <GroupItem>
                    <FieldLabel label={documentTypeIdArrayField.placeholder} />
                    <FormField field={documentTypeIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={capacityCategoryIdArrayField.placeholder} />
                    <FormField field={capacityCategoryIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={taskStateIdArrayField.placeholder} />
                    <FormField field={taskStateIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={assetIdArrayField.placeholder} />
                    <FormField field={assetIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={operatorIdArrayField.placeholder} />
                    <FormField field={operatorIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <FieldLabel label={worksheetActivityIdArrayField.placeholder} />
                    <FormField field={worksheetActivityIdArrayField} alt />
                  </GroupItem>
                  <GroupItem>
                    <CheckBox value={isDefault} onValueChanged={(e) => setDefault(e.value)} />{' '}
                    {translate!('Is default', transFormRepSelector)}
                  </GroupItem>
                </GroupItem>
                <GroupItem colSpan={3} colCount={3} cssClass={'action-buttons-view'}>
                  {dialogAction === FilterActionState.EDIT && <DeleteButton ref={selectorRef} />}
                  <Button
                    className="button-spacing"
                    text={translate!('Save', transFormRepSelector)}
                    icon="save"
                    type="success"
                    useSubmitBehavior
                    disabled={loading}
                  />
                </GroupItem>
              </Form>
            </form>
          </Popup>
        )}
      </div>
      <form onSubmit={onSearch} style={{ display: filterVisibility ? 'block' : 'none' }}>
        <Form colCount={7} className="popup-container" style={{ justify: 'center' }}>
          {/*
          <GroupItem>
            <FieldLabel label={timePeriodField.placeholder} />
            <FormField field={timePeriodField} />
           </GroupItem>
              */}
          <GroupItem>
            <FieldLabel label={dateFromField.placeholder} />
            <FormField
              field={dateFromField}
              max={dateToField.value}
              options={{ defaultTime: '00:00:00' }}
            />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={dateToField.placeholder} />
            <FormField
              field={dateToField}
              min={dateFromField.value}
              options={{ defaultTime: '23:59:59' }}
            />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={documentTypeIdArrayField.placeholder} />
            <FormField field={documentTypeIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={capacityCategoryIdArrayField.placeholder} />
            <FormField field={capacityCategoryIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={taskStateIdArrayField.placeholder} />
            <FormField field={taskStateIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={assetIdArrayField.placeholder} />
            <FormField field={assetIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={operatorIdArrayField.placeholder} />
            <FormField field={operatorIdArrayField} />
          </GroupItem>
          <GroupItem>
            <FieldLabel label={worksheetActivityIdArrayField.placeholder} />
            <FormField field={worksheetActivityIdArrayField} />
          </GroupItem>
          <GroupItem cssClass={'action-buttons-view'}>
            <Button
              className="button-spacing"
              type="success"
              text={translate!('Search', transFormReportBar)}
              useSubmitBehavior
              disabled={loading}
            />
          </GroupItem>
        </Form>
      </form>
    </>
  );
}
