import React, { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AlertMessage } from '../../../../../components/alertMessage/AlertMessage';
import { Loader } from '../../../../../components/loader/Loader';
import { useTranslation } from '../../../../../contexts/translation';
import { PageContentWrapper } from '../../../../../layouts/contentWrapper/PageContentWrapper';
import * as Bar from '../../../../../pages/WorkSheets/Mobile/components/Bar';
import { DetailPageParams } from '../../../../../types/pageParams.types';
import { Task } from '../../../../../types/task.types';
import { API_URL, fetcher } from '../../../../../utils/apiUrl';
import { swrConfig } from '../../../../Assets/AssetsEdit';
import MobileLayout from '../../components/MobileLayout';
import { StepsContainer } from './StepsContainer';

export function TaskMobileStepsPage() {
  const { translate } = useTranslation();
  const { id } = useParams<DetailPageParams>();
  const history = useHistory();
  const location = useLocation<{ backRoute: string }>();

  // Get tasku
  const { data: task } = useSWR<Task>(`${API_URL.TASK_ITEM}?id=${id}`, fetcher, swrConfig);

  const handleBack = () => {
    if (location.state) history.push(location.state.backRoute);
    else history.push(`/mobile/tasks/detail/${Number(id)}`);
  };

  // Loading tasku
  if (!task) return <Loader />;

  // Kontrola, jestli má task povolené stepy
  if (!task.typeTaskStepsAllowed)
    return <AlertMessage message={translate!('Module is not allowed', '')} class={'danger'} />;

  return (
    <MobileLayout>
      <Bar.SummaryNavBar title={task.customId + ' - ' + task.taskName} onBack={handleBack} />
      <PageContentWrapper noResponsivePaddings>
        <StepsContainer
          taskId={Number(id)}
          taskResponsiblePersonId={task.responsiblePersonId ? task.responsiblePersonId : null}
          onSubmit={() => null}
          onBack={handleBack}
          isBackButton
        />
      </PageContentWrapper>
    </MobileLayout>
  );
}
