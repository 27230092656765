import { SetStateAction } from 'react';
import { RuleType } from './rule.types';

export type DateFilterType = {
  name: string;
  value: string;
};

export type DateDiffFilterType = {
  name: string;
  value: string;
  diff: number;
};

export enum FieldType {
  MultipleOptions,
  Date,
  SingleOptions,
  Text,
  DateSelect,
  DateSelectWithDiffField,
  NewLine,
  BlankSpace,
  Month,
  Number,
  DateTime,
  DateSelectEnd,
  DateSelectWithDiffFieldEnd,
}

export interface BaseField {
  placeholder: string;
  fieldType: FieldType;
  setValue: SetStateAction<any>;
  rules: Array<RuleType>;
}

export interface IOptionsField extends BaseField {
  dataSource: Array<any>;
  keyExpr: string;
  displayExpr: string;
  value: any;
}

export interface MultipleOptionsField extends IOptionsField {
  fieldType: FieldType.MultipleOptions;
  parentKeyExpr: string;
}

export interface SingleOptionsField extends IOptionsField {
  fieldType: FieldType.SingleOptions;
  parentKeyExpr: string;
}

export interface DateField extends BaseField {
  fieldType: FieldType.Date;
  value: Date;
}

export interface DateTimeField extends BaseField {
  fieldType: FieldType.DateTime;
  value: Date;
}
export interface TextField extends BaseField {
  fieldType: FieldType.Text;
  value: string;
  maxLength?: number;
}

export interface NumberField extends BaseField {
  fieldType: FieldType.Number;
  value: number;
}
export interface DateSelectField extends BaseField {
  fieldType: FieldType.DateSelect;
  value: Date | DateFilterType | DateDiffFilterType | undefined | null;
  dataSource: Array<DateFilterType>;
}

export interface DateMonth extends BaseField {
  fieldType: FieldType.Month;
  value: Date;
}

export interface DateSelectWithDiffField extends BaseField {
  fieldType: FieldType.DateSelectWithDiffField;
  value: Date | DateDiffFilterType | undefined | null;
  dataSource: Array<DateFilterType>;
  min?: Date;
  max?: Date;
}

export interface DateSelectWithDiffFieldEnd extends BaseField {
  fieldType: FieldType.DateSelectWithDiffFieldEnd;
  value: Date | DateDiffFilterType | undefined | null;
  dataSource: Array<DateFilterType>;
  min?: Date;
  max?: Date;
}

export interface DateSelectFieldEnd extends BaseField {
  fieldType: FieldType.DateSelectEnd;
  value: Date | DateFilterType | DateDiffFilterType | undefined | null;
  dataSource: Array<DateFilterType>;
}

export interface BlankSpaceField extends IOptionsField {}

export interface NewLineField extends BlankSpaceField {
  cols: number;
}

export type Field =
  | DateField
  | TextField
  | MultipleOptionsField
  | SingleOptionsField
  | DateSelectField
  | DateSelectFieldEnd
  | DateSelectWithDiffField
  | DateSelectWithDiffFieldEnd
  | DateMonth
  | BlankSpaceField
  | NewLineField;
