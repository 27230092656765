import React from 'react';
import '../../components/datagridNoResponsive/DatagridNoResponsive.scss';

interface Props {
  children: any;
  datagridWidth: number;
  className?: string;
}

export const DatagridNoResponsive = ({ children, datagridWidth, className }: Props) => {
  return (
    <div className={`datagrid-no-responsive ${className}`} style={{ width: datagridWidth }}>
      {children}
    </div>
  );
};
