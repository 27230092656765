import { DateBox, DropDownBox, NumberBox, TextBox, TreeView, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import moment from 'moment';
import React, { useRef } from 'react';
import { useClearButtonProps } from '../../../hooks/useClearButtonProps';
import {
  DateSelectField,
  DateSelectWithDiffField,
  Field,
  FieldType,
  MultipleOptionsField,
  SingleOptionsField,
} from '../../../types/field.types';
import { Rule, RuleType } from '../../../types/rule.types';
import { getFilterDate } from '../../../utils/dateFilters';
import DateOrSelectField from './DateOrSelectField';
import RelativeDateDiffField from './RelativeDateDiffField';

export type OptionsObject = {
  defaultTime?: string;
  cols?: number;
};

type Props = {
  field: Field | any;
  alt?: boolean;
  children?: any;
  min?: any;
  max?: any;
  options?: OptionsObject;
};

export const fieldTypeMatcher = (fieldType: any) => {
  switch (fieldType) {
    case 'Date':
      return FieldType.Date;
    case 'MultipleOptions':
      return FieldType.MultipleOptions;
    case 'SingleOptions':
      return FieldType.SingleOptions;
    case 'Text':
      return FieldType.Text;
    case 'DateSelect':
      return FieldType.DateSelect;
    case 'DateSelectWithDiffField':
      return FieldType.DateSelectWithDiffField;
    case 'DateSelectEnd':
      return FieldType.DateSelectEnd;
    case 'DateSelectWithDiffFieldEnd':
      return FieldType.DateSelectWithDiffFieldEnd;
    case 'Month':
      return FieldType.Month;
    case 'Number':
      return FieldType.Number;
    case 'DateTime':
      return FieldType.DateTime;
    case 'NewLine':
      return FieldType.NewLine;
    case 'BlankSpace':
      return FieldType.BlankSpace;
    default:
      return '';
  }
};

/**
 * Komponenta form fieldu, která renderuje potřebný field podle typu v props
 * "alt" - pokud je v props předávaný alt, tak se zobrazí alternativní hodnota typu
 *      (platí pokud je fieldType zadaný jako pole více typů - především se používá pro DateOrSelectField)
 */
export default function FormField({ field, alt, min, max, options }: Props) {
  const treeViewRef = useRef<any>(null);
  const clearButtonProps = useClearButtonProps();

  const syncTreeViewSelection = (e) => {
    const treeView =
      (e.component.selectItem && e.component) || (treeViewRef && treeViewRef?.current?.instance);

    if (treeView) {
      if (e.value === null) {
        treeView.unselectAll();
      } else {
        const values = e.value || field.value;
        values &&
          values.forEach((value) => {
            treeView.selectItem(value);
          });
      }
    }

    if (e.value !== undefined) {
      field.setValue(e.value);
    }
  };

  const treeViewItemSelectionChanged = (e) => {
    field.setValue(e.component.getSelectedNodeKeys());
  };

  const handleSelectAllSelection = (e) => {
    if (e.value) {
      field.setValue(e.component.getSelectedNodeKeys());
    }
    if (!e.value && e.value !== undefined) {
      field.setValue(null);
    }
  };

  const treeViewRender = (field: MultipleOptionsField | SingleOptionsField) => {
    return (
      <TreeView
        ref={treeViewRef}
        dataSource={field.dataSource}
        dataStructure="plain"
        keyExpr={field.keyExpr}
        selectionMode={field.fieldType === FieldType.MultipleOptions ? 'multiple' : 'single'}
        showCheckBoxesMode="selectAll"
        parentIdExpr={field.parentKeyExpr}
        selectNodesRecursive
        displayExpr={field.displayExpr}
        selectByClick={true}
        searchEnabled={true}
        onSelectAllValueChanged={handleSelectAllSelection}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  };

  const renderField = (field: Field) => {
    const type = Array.isArray(field.fieldType) ? field.fieldType[alt ? 1 : 0] : field.fieldType;

    const fieldValue = field.value?.option
      ? getFilterDate(field.value.option.value, field.value.diff)
      : getFilterDate(field.value);
    const minValue = min
      ? min?.option
        ? getFilterDate(min.option.value, min.diff)
        : getFilterDate(min)
      : undefined;
    const maxValue = max
      ? max?.option
        ? getFilterDate(max.option.value, max.diff)
        : getFilterDate(max)
      : undefined;

    switch (type) {
      case FieldType.Text:
        return (
          <TextBox
            value={field.value}
            placeholder={field.placeholder}
            onValueChange={(e) => field.setValue(e)}
            // @ts-ignore
            maxLength={field.maxLength}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx: number) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return <></>;
              })}
            </Validator>
          </TextBox>
        );
      case FieldType.Number:
        return (
          <NumberBox
            value={field.value}
            placeholder={field.placeholder}
            onValueChange={(e) => field.setValue(e)}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx: number) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return <></>;
              })}
            </Validator>
          </NumberBox>
        );
      case FieldType.Date:
        return (
          <DateBox
            value={fieldValue}
            type="date"
            placeholder={field.placeholder}
            min={minValue}
            max={maxValue}
            onValueChange={(value) => {
              if (value) {
                field.setValue(
                  `${moment(getFilterDate(value)).format('YYYY-MM-DD')}${
                    options?.defaultTime ? ' ' + options?.defaultTime : ''
                  }`,
                );
              } else {
                field.setValue(undefined);
              }
            }}
            {...clearButtonProps}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return null;
              })}
            </Validator>
          </DateBox>
        );
      case FieldType.DateTime:
        return (
          <DateBox
            value={fieldValue}
            type="datetime"
            placeholder={field.placeholder}
            min={minValue}
            max={maxValue}
            onValueChange={(value) => {
              if (value) {
                field.setValue(`${moment(getFilterDate(value)).format('YYYY-MM-DD hh:mm')}`);
              } else {
                field.setValue(undefined);
              }
            }}
            {...clearButtonProps}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return null;
              })}
            </Validator>
          </DateBox>
        );
      case FieldType.Month:
        return (
          <DateBox
            value={fieldValue}
            type="date"
            placeholder={field.placeholder}
            onValueChange={(value) => {
              if (value) {
                field.setValue(`${moment(getFilterDate(value)).format('YYYY-MM-DD')} 00:00:00`);
              } else {
                field.setValue(undefined);
              }
            }}
            displayFormat="monthAndYear"
            calendarOptions={{
              maxZoomLevel: 'year',
              minZoomLevel: 'century',
            }}
            {...clearButtonProps}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return null;
              })}
            </Validator>
          </DateBox>
        );
      case FieldType.DateSelect:
      case FieldType.DateSelectEnd:
        return <DateOrSelectField field={field as DateSelectField} />;
      case FieldType.DateSelectWithDiffField:
      case FieldType.DateSelectWithDiffFieldEnd:
        return (
          <RelativeDateDiffField
            field={field as DateSelectWithDiffField}
            min={minValue}
            max={maxValue}
            options={options}
          />
        );
      case FieldType.SingleOptions:
      case FieldType.MultipleOptions: {
        const dropDownField = field as SingleOptionsField | MultipleOptionsField;
        return (
          <DropDownBox
            value={dropDownField.value}
            valueExpr={dropDownField.keyExpr}
            placeholder={dropDownField.placeholder}
            displayExpr={dropDownField.displayExpr}
            dataSource={dropDownField.dataSource}
            contentRender={() => treeViewRender(dropDownField)}
            onValueChanged={syncTreeViewSelection}
            {...clearButtonProps}
          >
            <Validator>
              {field?.rules?.map((rule: RuleType, idx: number) => {
                if (rule.type === Rule.RequiredRule) {
                  return <RequiredRule message={rule.message} key={idx} />;
                }
                return null;
              })}
            </Validator>
          </DropDownBox>
        );
      }
      default:
        return <></>;
    }
  };

  return renderField(field);
}
