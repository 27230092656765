import React from 'react';
import { Columns } from '../../pages/Assets/Columns/AssetsChildrenColumns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const AssetsChildrenGrid = ({ data, dataGridRef }: IDataGrid) => {
  const transFormName = 'Asset.List';
  return (
    <>
      <SisDataGrid
        dataGridRef={dataGridRef}
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns}
        allowColumnReordering={false}
        allowColumnResizing={false}
        columnChooser={false}
      />
    </>
  );
};
