import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';
import { uniqueLookUpByPropertyAndOriginalBack } from '../../../utils/uniqueLookUpByAttribute';

export const Columns = (
  data,
  isReadOnly,
  editing,
  capacityCategories,
  translate,
  handleEdit,
  handleRemove,
): SisDataGridColumns => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Responsible person id',
    width: '5%',
  },
  {
    dataType: IDataType.string,
    dataField: 'capacityCategoryId',
    caption: 'Capacity category',
    cssClass: 'dg-align-left',
    lookupSource: editing
      ? capacityCategories
      : uniqueLookUpByPropertyAndOriginalBack(capacityCategories, 'name'),
    requiredRule: true,
    cellRender: (data) => {
      const category = uniqueLookUpByPropertyAndOriginalBack(capacityCategories, 'name')?.find(
        (item) => item.id === data.value,
      );
      return category?.name ?? '';
    },
    width: '25%',
  },
  {
    dataType: IDataType.string,
    dataField: 'personId',
    caption: 'Person',
    cssClass: 'dg-align-left',
    lookupSource: data,
    lookupValueExpr: 'personId',
    lookupDisplayExpr: 'personName',
    requiredRule: true,
    cellRender: ({ data }) => data.personName,
    width: '25%',
  },
  {
    dataField: 'responsibleFrom',
    caption: 'Responsible from',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    requiredRule: true,
    width: '10%',
  },
  {
    dataField: 'responsibleTo',
    caption: 'Responsible to',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    width: '10%',
  },
  {
    dataField: 'visible',
    caption: 'Visible',
    dataType: IDataType.boolean,
    width: '10%',
    allowEditing: !isReadOnly,
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, translate, handleEdit, handleRemove),
    width: '15%',
  },
];

const ButtonCell = (cellData, translate, handleEdit, handleRemove) => {
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        text={translate!('Edit', '')}
        stylingMode="text"
        cellData={cellData}
        onClick={handleEdit}
      />
      <DataGridButton
        hint={translate!('Delete', '')}
        text={translate!('Delete', '')}
        stylingMode="text"
        cellData={cellData}
        onClick={handleRemove}
      />
    </>
  );
};
