import { isEmpty } from 'ramda';
import { isFunction } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useTranslation } from '../contexts/translation';
import { leaveDialogActions, useLeaveDialog } from './useLeaveDialog';

const useUnsavedChangesWarning = (
  redirectBackPath: string,
  onSave?: any,
): [
  /** Prompt as modal JSX Element */
  // eslint-disable-next-line no-undef
  JSX.Element,
  /** Set dirty context, avoid redirect or leave form */
  () => void,
  /** Set clean context, can redirect or leave form */
  () => void,
  /** If dirty open modal, or history. go to redirectPath param */
  () => void,
  /** If dirty open modal, or history.goBack */
  () => void,
  /** If dirty open modal, or call callback */
  (callback: () => void) => void,
  boolean,
] => {
  const { translate } = useTranslation();
  const history = useHistory();
  const [isDirty, setDirty] = useState(false);

  const { open } = useLeaveDialog({
    title: translate!('Are you sure?', ''),
    bodyHtml: translate!('Discard changes', ''),
    saveAndLeaveButtonTitle: translate!('Save and leave', ''),
    leaveWithoutSaveButtonTitle: translate!('Leave without save', ''),
    cancelButtonTitle: translate!('Cancel', ''),
  });

  const openModal = (pathOrFunc): any => {
    if (isDirty) {
      open().then((c) => {
        if (c === leaveDialogActions.SAVE_AND_LEAVE) {
          if (onSave) onSave();
        } else if (c === leaveDialogActions.LEAVE_WITHOUT_SAVE) {
          // DisActive PROMPT
          localStorage.removeItem('formData_task');
          localStorage.removeItem('formData');
          setDirty(false);
          if (isFunction(pathOrFunc)) {
            pathOrFunc();
            return true;
          } else {
            !isEmpty(pathOrFunc) && history.push(pathOrFunc);
            return true;
          }
        } else if (c === leaveDialogActions.CANCEL) {
          return false;
        }
      });
      // Disable Prompt auto redirect
      return false;
    } else {
      // Enable Prompt auto redirect
      return true;
    }
  };

  const handleBackButton = () => {
    if (isDirty) {
      openModal(redirectBackPath);
    } else {
      history.push(redirectBackPath);
    }
  };
  const handleHistoryGoBackButton = () => {
    isFunction(history.goBack) && history.goBack();
  };

  const handleDirtyWithCallback = (callback: () => void) => {
    if (isDirty) {
      openModal(callback);
    } else {
      isFunction(callback) && callback();
    }
  };
  useEffect(() => {
    // Detecting browser closing
    // @ts-ignore
    window.onbeforeunload = isDirty && (() => translate!('Discard changes', ''));

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const routerPrompt = (
    <Prompt
      when={isDirty}
      message={({ pathname }) => {
        return openModal(pathname);
      }}
    />
  );

  return [
    routerPrompt,
    (): void => setDirty(true),
    (): void => setDirty(false),
    handleBackButton,
    handleHistoryGoBackButton,
    handleDirtyWithCallback,
    isDirty,
  ];
};

export default useUnsavedChangesWarning;
