import { Button } from 'devextreme-react';
import React from 'react';
import './MobileMenuItem.scss';

interface Props {
  onClick: () => void;
}

export function MobileMenuBackButton({ onClick }: Props) {
  return (
    <Button
      icon="back"
      text="Back"
      type="normal"
      height={'35px'}
      style={{ margin: '8px', fontSize: '18px' }}
      onClick={onClick}
    />
  );
}
