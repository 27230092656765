import { Switch } from 'devextreme-react';
import ContextMenu from 'devextreme-react/context-menu';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import TreeView from 'devextreme-react/tree-view';
import { cond, equals, ifElse, isNil, pathOr, T } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { useTranslation } from '../../contexts/translation';
import { IAssetTreeView } from '../../types/assetTreeViewClass.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { FailedLoad } from '../failedLoad/FailedLoad';
import { Loader } from '../loader/Loader';

export interface AssetTreeViewProps {
  classId: number;
  visible: boolean | true;
  parentId?: number | string;
  setIsTreeView: (e: any) => void;
  isTreeView: boolean;
  hideToolbar?: boolean;
}

let timer;
export const AssetTreeView = ({
  classId,
  isTreeView,
  visible,
  parentId,
  setIsTreeView,
  hideToolbar,
}: AssetTreeViewProps): any => {
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'Asset.List';
  const menuItems = [
    { id: 'expand', text: 'Expand asset' },
    { id: 'collapse', text: 'Collapse asset' },
    { id: 'details', text: 'Show asset details' },
  ];
  const { data, error } = useSWR<IAssetTreeView[]>(
    `${API_URL.ASSET_TREE_VIEW}?visibleOnly=${visible}&classId=${classId}${
      isNilOrEmpty(parentId) ? '' : `&parentId=${parentId}`
    }`,
    fetcher,
  );
  const contextMenuRef = useRef();
  const treeViewRef = useRef();
  const [selectedTreeItem, setSelectedTreeItem] = useState();
  const onDoubleClick = (id) => {
    history.push(`/assets/edit/${id}`);
  };

  const onClick = () => {};

  const onClickHandler = (event, id) => {
    clearTimeout(timer);
    if (event.detail === 1) {
      timer = setTimeout(onClick, 200);
    } else if (event.detail === 2) {
      onDoubleClick(id);
    }
  };

  const treeViewItemContextMenu = (e) => {
    setSelectedTreeItem(e.itemData);
    const haveChild = isNilOrEmpty(pathOr([], ['itemData', 'assetDescendants'], e));
    // @ts-ignore
    contextMenuRef.current.instance.option('items[0].visible', !haveChild);
    // @ts-ignore
    contextMenuRef.current.instance.option('items[1].visible', !haveChild);
    // @ts-ignore
    contextMenuRef.current.instance.option('items[2].visible', true);
    // @ts-ignore
    contextMenuRef.current.instance.option('items[0].disabled', e.node.expanded);
    // @ts-ignore
    contextMenuRef.current.instance.option('items[1].disabled', !e.node.expanded);
  };

  const contextMenuItemClick = (e) => {
    // @ts-ignore
    cond([
      // @ts-ignore
      [equals('expand'), () => treeViewRef.current.instance.expandItem(selectedTreeItem.id)],
      // @ts-ignore
      [equals('collapse'), () => treeViewRef.current.instance.collapseItem(selectedTreeItem.id)],
      // @ts-ignore
      [equals('details'), () => history.push(`/assets/edit/${selectedTreeItem.id}`)],
      // @ts-ignore
      [T, () => alert('T')],
      // @ts-ignore
    ])(e.itemData.id);
  };

  return ifElse(
    isNil,
    () =>
      cond([
        [isNil, () => <Loader />],
        [
          T,
          () => (
            <div>
              {!hideToolbar && (
                <Toolbar>
                  <Item location={'before'} html={translate!('Assets', transFormName)} />
                  <Item location={'after'}>
                    <div>
                      <span>{translate!('Tree view', '')}</span>
                      <Switch
                        value={isTreeView}
                        style={{ marginLeft: '5px' }}
                        onValueChange={setIsTreeView}
                        defaultValue={false}
                      />
                    </div>
                  </Item>
                </Toolbar>
              )}

              <TreeView
                expandAllEnabled
                // @ts-ignore
                ref={treeViewRef}
                id="treeview"
                width={300}
                // @ts-ignore
                items={data}
                searchEnabled
                searchMode={'contains'}
                itemRender={({ name, id }) => (
                  <div onClick={(e) => onClickHandler(e, id)}>{name}</div>
                )}
                onItemContextMenu={treeViewItemContextMenu}
                itemsExpr={'assetDescendants'}
                displayExpr="name"
                keyExpr="id"
              />
              <ContextMenu
                // @ts-ignore
                ref={contextMenuRef}
                dataSource={menuItems}
                target="#treeview .dx-treeview-item"
                onItemClick={contextMenuItemClick}
              />
            </div>
          ),
        ],
      ])(data),
    () => <FailedLoad message={null} />,
  )(error);
};
