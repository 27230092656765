import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTerminalAuth } from '../../../contexts/terminalAuthContext';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { TerminalMapping } from '../../../types/terminalMapping.types';
import './AssistanceStyle.scss';

type GridItemProps = {
  terminalMapping: TerminalMapping;
};

export function TerminalMappingLogin() {
  const { loginTechnicalUserByTerminalMappingId: terminalAuth, terminalMappings } =
    useTerminalAuth();
  const history = useHistory();

  useEffect(() => {
    if (!terminalMappings || terminalMappings.length === 0) return;
    if (terminalMappings.length === 1) handleSelectedPanel(terminalMappings[0]);
  }, []);

  /**
   * Funkce pro změnu stavu tasku nebo změnu viditelnosti a vybraného panelu
   */
  const handleSelectedPanel = (terminalMapping: TerminalMapping) => {
    if (!terminalAuth) return;
    terminalAuth(terminalMapping.id);

    history.push('/terminal/tasks');
  };

  const GridItem = ({ terminalMapping }: GridItemProps) => {
    const name = terminalMapping?.assetName || 'xx';
    return (
      <div
        className={`assistance-grid-item assistance-default_row-bg`}
        {...(terminalMapping ? { onClick: () => handleSelectedPanel(terminalMapping) } : {})}
      >
        {name}
      </div>
    );
  };

  return (
    <PageContentWrapper noContentBlock noDxCard noResponsivePaddings loading={!terminalMappings}>
      <div className="assistance-container">
        <div className="assistance-grid-container">
          {terminalMappings?.map((terminalMapping: any, idx: number) => {
            return <GridItem key={idx} terminalMapping={terminalMapping} />;
          })}
        </div>
      </div>
    </PageContentWrapper>
  );
}
