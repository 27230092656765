import React from 'react';
import {
  DataGridButton,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';

export const Columns = (readOnly, handleEdit, translate): SisDataGridColumns => [
  {
    dataField: 'customId',
    caption: 'Cycle id',
    width: '7%',
    dataType: IDataType.string,
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '17%',
    dataType: IDataType.string,
  },
  {
    dataField: 'capacityCategoryId',
    caption: 'Capacity category',
    width: '15%',
    dataType: IDataType.string,
    cellRender: (data) => data.row.data.capacityCategoryName,
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '28%',
    dataType: IDataType.string,
  },
  {
    dataField: 'documentTypeNameTranslated',
    caption: 'Document type',
    width: '15%',
    dataType: IDataType.string,
  },
  {
    dataField: 'visible',
    caption: 'Visible',
    dataType: IDataType.boolean,
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, readOnly, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, readOnly, translate) => {
  return (
    !readOnly && (
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={() => handleEdit(cellData)}
      />
    )
  );
};
