import moment from 'moment';
import { toLower } from 'ramda';
import React from 'react';
import '../../assets/assistance-queue.scss';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DocumentTypeStyle } from '../../types/documentType.types';
import { Task } from '../../types/task.types';

type Props = {
  data?: Task[];
  color?: string | null;
  category: CapacityCategory;
};

const injectStyle = (style) => {
  const styleElement = document.createElement('style');
  let styleSheet: CSSStyleSheet | null = null;
  document.head.appendChild(styleElement);
  styleSheet = styleElement.sheet;
  if (styleSheet) styleSheet.insertRule(style, styleSheet.cssRules.length);
};

export default function AssistanceDataGrid({ data, category, color }: Props) {
  const items = data?.slice(0, 3);
  const timeItem = [{ id: 0, assistancePanelQueueDisplayName: moment().format('HH:mm') }];

  const RowItem = ({ data }: { data: Task }) => {
    const _color = data.typeColorName || color;
    const existItems = items && items?.length > 0;
    const style: DocumentTypeStyle = data.typeStyle ? JSON.parse(data.typeStyle) : null;

    if (style && style.blinking) {
      const blinkBgStyle = `.assistance-blink-bg { animation: blinkingBackground ${style.blinking.time}s infinite;}`;
      const keyframesStyle = `
        @keyframes blinkingBackground {
          0%   { background-color: ${style.blinking.color1}; }
          25%  { background-color: ${style.blinking.color2}; }      
          50%  { background-color: ${style.blinking.color1}; }      
          75%  { background-color: ${style.blinking.color2}; }
          100% { background-color: ${style.blinking.color1}; }
        }
      `;
      injectStyle(blinkBgStyle);
      injectStyle(keyframesStyle);
    }

    return (
      <div
        className={`assistance-row-item queue-row-${existItems ? items?.length : 1}${
          style
            ? ' assistance-blink-bg'
            : _color
            ? ` assistance-${existItems ? '' : 'text-'}${toLower(_color)}_row-bg`
            : ` assistance-${existItems ? '' : 'text-'}default_row-bg`
        }`}
        style={
          style
            ? {
                color: existItems ? style.color : 'red',
                backgroundColor: existItems ? style.backgroundColor : '#efefef',
                justifyContent: existItems ? 'left' : 'center',
              }
            : undefined
        }
      >
        {data.assistancePanelQueueDisplayName}
      </div>
    );
  };

  const dataSource = items && items.length > 0 ? items : timeItem;

  return (
    <div className="assistance-table">
      <div className="assistance-table-header">{category?.name || ''}</div>
      {dataSource?.map((item, idx) => (
        // @ts-ignore
        <RowItem key={idx} data={item} />
      ))}
    </div>
  );
}
