import LoadPanel from 'devextreme-react/load-panel';
import { includes } from 'ramda';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Platform } from '../components/loginForm/LoginForm';
import { Content } from '../Content';
import { useAssetClass } from '../contexts/assetClass';
import { AuthProvider, useAuth } from '../contexts/auth';
import { MobileAuthProvider, useMobileAuth } from '../contexts/mobileAuthContext';
import { NavigationProvider } from '../contexts/navigation';
import { RoutingProvider } from '../contexts/routingContext';
import { TerminalAuthProvider, useTerminalAuth } from '../contexts/terminalAuthContext';
import { ThemeProvider } from '../contexts/theme';
import { useScreenSizeClass } from '../hooks/mediaQuery';
import { MobileRoutes } from '../MobileRoutes';
import { TerminalRoutes } from '../TerminalRoutes';
import { UnauthenticatedContent } from '../UnauthenticatedContent';

function App({ entryUrl }) {
  const { user, loading: loadingUser, setEntryUrl, entryUrl: _entryUrl } = useAuth();
  const { assetClass } = useAssetClass();

  useEffect(() => {
    if (entryUrl === '/') entryUrl = '/home';
    if (setEntryUrl && !user && !_entryUrl)
      setEntryUrl(entryUrl?.includes('login') ? (_entryUrl ? _entryUrl : null) : entryUrl);
  }, [entryUrl]);

  if (loadingUser) return <LoadPanel visible={true} />;
  if (user && assetClass)
    return (
      <RoutingProvider>
        <Content />
      </RoutingProvider>
    );
  return <UnauthenticatedContent path={'/login'} platform={Platform.DESKTOP} />;
}

interface TerminalAppProps {
  entryUrl: string;
  query: string | null;
}

function TerminalApp({ entryUrl, query }: TerminalAppProps) {
  const {
    user,
    loading: loadingUser,
    setEntryUrl,
    entryUrl: _entryUrl,
    terminalMappings,
    terminalMappingRedirectEnabled,
    setAssetIdFromUrl,
    assetIdFromUrl: assetIdFromUrlAuth,
  } = useTerminalAuth();
  const { assetClass } = useAssetClass();
  const location = useLocation();

  useEffect(() => {
    if (entryUrl === '/terminal') entryUrl = '/terminal/tasks';
    if (setEntryUrl && !_entryUrl)
      setEntryUrl(entryUrl?.includes('terminal/login') ? (_entryUrl ? _entryUrl : null) : entryUrl);
  }, [entryUrl]);

  // V URL je možné předat id assetu - řeší problém, když není nic v TerminalMapping #442
  const assetIdFromUrl: number | null =
    query && query.includes('assetId')
      ? Number(query.split('=')[1])
      : location.search.includes('assetId')
      ? Number(location.search.split('=')[1])
      : null;

  useEffect(() => {
    if (assetIdFromUrl && setAssetIdFromUrl) setAssetIdFromUrl(assetIdFromUrl);
  });

  if (loadingUser) return <LoadPanel visible={true} />;
  if (user && assetClass) return <TerminalRoutes assetIdFromUrl={assetIdFromUrl} />;

  let path =
    !terminalMappings || terminalMappings.length === 0
      ? '/terminal/login'
      : '/terminal/terminal-mapping-login';

  return (
    <UnauthenticatedContent
      path={path}
      platform={Platform.TERMINAL}
      terminalMappingRedirectEnabled={terminalMappingRedirectEnabled}
    />
  );
}

function MobileApp({ entryUrl }) {
  const { user, loading: loadingUser, setEntryUrl, entryUrl: _entryUrl } = useMobileAuth();
  const { assetClass } = useAssetClass();

  useEffect(() => {
    if (entryUrl === '/mobile') entryUrl = '/mobile/tasks/list';
    if (setEntryUrl && !user && !_entryUrl)
      setEntryUrl(entryUrl?.includes('mobile/login') ? (_entryUrl ? _entryUrl : null) : entryUrl);
  }, [entryUrl]);

  if (loadingUser) return <LoadPanel visible={true} />;
  if (user && assetClass) return <MobileRoutes />;
  return <UnauthenticatedContent path={'/mobile/login'} platform={Platform.MOBILE} />;
}

export const RoutesContainer = () => {
  const screenSizeClass = useScreenSizeClass();
  const { pathname, search } = useLocation();

  if (includes('/terminal', pathname))
    return (
      <TerminalAuthProvider>
        <NavigationProvider>
          <div className={`app-terminal`}>
            <TerminalApp
              entryUrl={search ? `${pathname}${search}` : pathname}
              query={search ? search : null}
            />
          </div>
        </NavigationProvider>
      </TerminalAuthProvider>
    );

  if (includes('/mobile', pathname))
    return (
      <MobileAuthProvider>
        <NavigationProvider>
          <div className={`app-mobile`}>
            <MobileApp entryUrl={pathname} />
          </div>
        </NavigationProvider>
      </MobileAuthProvider>
    );

  return (
    <AuthProvider>
      <NavigationProvider>
        <ThemeProvider>
          <div className={`app ${screenSizeClass}`}>
            <App entryUrl={pathname} />
          </div>
        </ThemeProvider>
      </NavigationProvider>
    </AuthProvider>
  );
};
