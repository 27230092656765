import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { postApi, putApi } from '../../../../apiUtils';
import { AssetTabCycleParameterDataGrid } from '../../../../components/datagrid/AssetTabCycleParameterDataGrid';
import { useLoading } from '../../../../contexts/loadingContext';
import { useTranslation } from '../../../../contexts/translation';
import { AssetCycleParameterInputs } from '../../../../types/assetCycleParameter.types';
import { DataGridActionType } from '../../../../types/dataGridActionTypes';
import { DetailPageParams } from '../../../../types/pageParams.types';
import { ParameterInputs } from '../../../../types/parameter.types';
import { Unit } from '../../../../types/unit.types';
import { API_URL, fetcher } from '../../../../utils/apiUrl';
import { uniqueLookUpByPropertyAndOriginalBack } from '../../../../utils/uniqueLookUpByAttribute';

interface AssetTabCycleParametersProps {
  cycleId: number;
  assetId: number;
  isReadonly: boolean;
  mutateParent: () => void;
  active: boolean;
}

export function AssetTabCycleParameters(props: AssetTabCycleParametersProps) {
  const { translate } = useTranslation();
  const { id } = useParams<DetailPageParams>();

  const transFormName = 'Asset.CycleParameter';
  const apiUrlAssetParametersList = `${API_URL.ASSET_PARAMETER_LIST_FILTERED}?assetId=${id}&excludeAssignedToAssetCycle=${props.cycleId}&visibleOnly=false`;
  const apiUrlAssetCycleParametersList = `${API_URL.ASSET_CYCLE_PARAMETER_LIST}?visibleOnly=false&parentId=${props.cycleId}`;

  const [selectedUnitId, setSelectedUnitId] = useState<number>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AssetCycleParameterInputs | null>(null);
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  const { startLoading, stopLoading } = useLoading();
  // Načteme parametry
  const { data: assetCycleParams, mutate: mutateAssetCycleParams } = useSWR(
    apiUrlAssetCycleParametersList,
    fetcher,
  );

  useEffect(() => {
    if (props.active) mutateAssetCycleParams();
  }, [props.active]);

  // Načteme units
  const { data: units } = useSWR<Array<Unit>>(API_URL.UNIT_LIST, fetcher);
  // Načtení číselníku - AssetParameter
  const { data: assetParameters, error: errorAssetParameters } = useSWR(
    apiUrlAssetParametersList,
    fetcher,
  );

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Asset cycle parameters', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: handleNew,
        },
      },
    );
  };
  /**
   * Metoda vrací data pro sloupec "unit". Data jsou profilrována podle zvoleného parametru.
   */
  const getFilteredUnits = useCallback(
    (options) => {
      return {
        store: uniqueLookUpByPropertyAndOriginalBack(assetParameters, 'parameterName'),
        filter: options.data ? ['id', '=', options.data.assetParameterId] : null,
      };
    },
    [assetParameters],
  );

  const assetParameterChanged = (val) => {
    if (!assetParameters) return;

    let selectedParam = assetParameters.find((p: any) => p.id === val.value);
    let tmpUnit = selectedParam?.unitId;
    if (tmpUnit) setSelectedUnitId(tmpUnit);
  };

  const handleApiCall = async ({ type, key, data: _data }) => {
    switch (type) {
      case DataGridActionType.Insert:
        await postApi({
          url: API_URL.ASSET_CYCLE_PARAMETER_POST,
          data: { ..._data, /* assetId: props.assetId, */ assetCycleId: props.cycleId },
        });
        break;
      case DataGridActionType.Update:
        const oldData = assetCycleParams?.find((item) => item.id === +key);
        oldData &&
          (await putApi({
            url: API_URL.ASSET_CYCLE_PARAMETER_PUT,
            data: { ...oldData, ..._data },
          }));
        break;
      default:
        break;
    }
    setSelectedRowData(null);
    setSelectedAction(null);
    mutate(`${apiUrlAssetCycleParametersList}`);
    mutate(`${apiUrlAssetParametersList}`);
    // mutate();
    handleClose();
  };

  const handleNew = () => {
    setSelectedAction(DataGridActionType.Insert);
    setModalVisible(true);
  };

  const handleEdit = (e: any) => {
    setSelectedRowData(e.data);
    setSelectedAction(DataGridActionType.Update);
    setModalVisible(true);
  };

  const onSubmit = (formData: ParameterInputs, type?: DataGridActionType) => {
    startLoading();
    handleApiCall({ type, key: selectedRowData?.id, data: formData }).then(() => stopLoading());
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRowData(null);
    setSelectedUnitId(undefined);
  };

  return (
    <AssetTabCycleParameterDataGrid
      isReadonly={props.isReadonly}
      assetParameterChanged={assetParameterChanged}
      getFilteredUnits={getFilteredUnits}
      assetParameters={assetParameters}
      data={assetCycleParams}
      units={units}
      onToolbarPreparing={onToolbarPreparing}
      modalVisibility={isModalVisible}
      handleEdit={handleEdit}
      handleNew={handleNew}
      handleClose={handleClose}
      onDataSubmit={onSubmit}
      selectedData={selectedRowData}
      selectedAction={selectedAction}
      selectedUnitId={selectedUnitId}
    />
  );
}
