import React from 'react';
import { useHistory } from 'react-router';
import './AssetClassToolBarIndicator.css';

interface Props {
  title?: string;
}

export function AssetClassToolBarIndicator(props: Props) {
  const { title } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push('/profile');
  };

  if (!title) return <></>;

  return (
    <div className={'asset-class-toolbar-indicator cursor-pointer'} onClick={handleClick}>
      {title}
    </div>
  );
}
