// @ts-ignore
import React from 'react';
import { DataGridButton, IColumnType, IDataType, SisDataGridColumns } from '../../components/table';

export const Columns = (handleEdit, handleRemove, translate): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'id',
    width: '5%',
    caption: 'ID',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'assetName',
    caption: 'Asset',
    width: '20%',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    width: '20%',
    caption: 'Name',
  },
  {
    dataField: 'documentTypeNameTranslated',
    caption: 'Document type',
    width: '20%',
  },
  {
    dataType: IDataType.string,
    dataField: 'capacityCategoryName',
    width: '20%',
    caption: 'Capacity category',
    lookupPickBy: 'capacityCategoryName',
  },
  {
    dataType: IDataType.number,
    dataField: 'position',
    width: '5%',
    caption: 'Position',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'visible',
    dataType: IDataType.boolean,
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, handleRemove, translate),
    width: '15%',
  },
];

const ButtonCell = (cellData, handleEdit, handleRemove, translate) => {
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        cellData={cellData}
        onClick={handleEdit}
      />
      {/* <DataGridButton
        hint={translate!('Remove', '')}
        icon="remove"
        stylingMode="contained"
        cellData={cellData}
        onClick={() => handleRemove(cellData)}
      /> */}
    </>
  );
};
