import { locale } from 'devextreme/localization';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { Columns } from '../../pages/Assets/Columns/AssetTabCycleParametersColumns';
import { Unit } from '../../types/unit.types';
import AssetCycleParameterModal from '../modals/AssetCycleParameterModal';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export interface IAssetTabCycleParameterDataGrid extends IDataGrid {
  isReadonly: boolean;
  assetParameterChanged: (rowData: any, value: any) => void;
  getFilteredUnits: (e: any) => void;
  units: Array<Unit> | undefined;
  handleNew: () => void;
  handleEdit: (e: any) => void;
  handleClose: () => void;
  modalVisibility: boolean;
  selectedData: any;
  selectedAction: any;
  onDataSubmit: (data: any, actionType: any) => void;
  selectedUnitId?: number;
  assetParameters: any;
}

export const AssetTabCycleParameterDataGrid = ({
  isReadonly,
  assetParameterChanged,
  data,
  onToolbarPreparing,
  units,
  handleNew,
  handleEdit,
  handleClose,
  modalVisibility,
  selectedData,
  selectedAction,
  onDataSubmit,
  selectedUnitId,
  assetParameters,
}: IAssetTabCycleParameterDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Asset.CycleParameter';
  const { pathname } = useLocation();
  const [Prompt, setDirty, setClean] = useUnsavedChangesWarning(pathname);

  const dateFormat = () => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'DD.MM.yyyy';
    else return 'DD/MM/yyyy';
  };

  const dateTimeFormat = () => {
    if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'DD.MM.yyyy HH:mm';
    else return 'DD/MM/yyyy HH:mm';
  };

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(handleEdit, isReadonly, translate, dateFormat, dateTimeFormat)}
        onToolbarPreparing={onToolbarPreparing}
        rowAlterantionEnabled
        columnChooser={false}
        allowColumnResizing={false}
        allowColumnReordering={false}
        prompt={Prompt}
      />
      {modalVisibility && (
        <AssetCycleParameterModal
          isVisible={modalVisibility}
          data={selectedData ?? null}
          actionType={selectedAction}
          transFormName={transFormName}
          onSubmit={onDataSubmit}
          onClose={handleClose}
          parameterChanged={assetParameterChanged}
          parameters={assetParameters}
          units={units}
          selectedUnitId={selectedUnitId}
        />
      )}
    </>
  );
};
