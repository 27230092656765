import { custom } from 'devextreme/ui/dialog';
import { prop } from 'ramda';

export const leaveDialogActions = {
  SAVE_AND_LEAVE: 'SAVE_AND_LEAVE',
  LEAVE_WITHOUT_SAVE: 'LEAVE_WITHOUT_SAVE',
  CANCEL: 'CANCEL',
};

export interface ILeaveDialog {
  timeout?: number;
  title: string;
  bodyHtml: string;
  saveAndLeaveButtonTitle: string;
  leaveWithoutSaveButtonTitle: string;
  cancelButtonTitle: string;
}

export const useLeaveDialog = (settings: ILeaveDialog) => {
  const open = () => {
    const myDialog = custom({
      title: settings.title,
      messageHtml: settings.bodyHtml,
      buttons: [
        {
          text: settings.saveAndLeaveButtonTitle,
          onClick: (e) => {
            return {
              buttonText: e.component.option('text'),
              id: leaveDialogActions.SAVE_AND_LEAVE,
            };
          },
        },
        {
          text: settings.leaveWithoutSaveButtonTitle,
          onClick: (e) => {
            return {
              buttonText: e.component.option('text'),
              id: leaveDialogActions.LEAVE_WITHOUT_SAVE,
            };
          },
        },
        {
          text: settings.cancelButtonTitle,
          onClick: (e) => {
            return {
              buttonText: e.component.option('text'),
              id: leaveDialogActions.CANCEL,
            };
          },
        },
      ],
    });

    if (settings.timeout)
      setTimeout(function () {
        myDialog.hide();
      }, settings.timeout);

    return myDialog.show().then((dialogResult) => {
      return prop('id', dialogResult);
    });
  };
  return { open };
};
