import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { cond, equals } from 'ramda';

interface CallApi {
  url: string;
  callAfterSuccess?: (arg?: any) => void;
  callAfterError?: (arg: void) => void;
  hideNotifications?: boolean;
  hideSuccessNotifications?: boolean;
}

interface PostApi extends CallApi {
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: any;
  params?: Record<string, string | number>;
  callAfterUnAuthorized?: () => void;
  ignoreUnAuthorizedError?: boolean;
}

interface DeleteApi extends CallApi {
  id: number;
}

interface GetApi extends CallApi {
  params: Record<string, string | number>;
}

const axiosHandleErrors = (error, hideNotifications: boolean = false) => {
  if (error.isAxiosError && error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // eslint-disable-next-line no-console
    console.log('data', error.response.data);
    // eslint-disable-next-line no-console
    console.log('status', error.response.status);
    // eslint-disable-next-line no-console
    console.log('statusText', error.response.statusText);
    // eslint-disable-next-line no-console
    console.log('headers', error.response.headers);

    if (!hideNotifications) {
      switch (error.response.status) {
        case 401:
          notify(`Došlo k odhlášení, budete přesměrováni na přihlašovací obrazovku.`, 'error');

          if (window.location.href.includes(`/#/terminal/assistance-queue`))
            window.location.href = `/#/terminal/assistance-queue`;
          else if (window.location.href.includes(`/#/terminal/assistance`))
            window.location.href = `/#/terminal/assistance`;
          else if (window.location.href.includes(`/#/terminal`))
            window.location.href = `/#/terminal`;
          else if (window.location.href.includes(`/#/mobile`)) window.location.href = `/#/mobile`;
          else window.location.href = `/#/`;

          setTimeout(() => {
            location.reload();
          }, 2000);
          break;
        case 403:
          notify(`Access forbidden`, 'error');
          break;
        case 404:
          notify(`Server není dostupný.`, 'error');
          break;
        default:
          if (typeof error.response.data === 'object') {
            const errors = Object.keys(error.response.data.errors).map((key) => {
              return error.response.data.errors[key];
            });
            notify(`${error.response.data.title} ${errors}`, 'error');
          } else notify(`${error.response.data}`, 'error');
          break;
      }
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    // eslint-disable-next-line no-console
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    if (!hideNotifications) notify(`${error.message}`, 'error');
  }
  // eslint-disable-next-line no-console
  console.log(error.config);
};

export async function postApi<T>({
  url,
  data,
  params,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
  hideSuccessNotifications,
  callAfterUnAuthorized,
  ignoreUnAuthorizedError,
}: PostApi) {
  try {
    const response = await axios.post<T>(
      url,
      {
        ...data,
      },
      { params },
    );

    // if (!hideNotifications && !hideSuccessNotifications) notify(`Success`, 'success');
    if (callAfterSuccess) callAfterSuccess(response.data);
    return response.data;
  } catch (error: any) {
    if (!ignoreUnAuthorizedError) axiosHandleErrors(error, hideNotifications);
    // if (!hideNotifications) notify(`${error.response.data}`, 'error');
    if (error.isAxiosError && error.response) {
      cond([
        // @ts-ignore
        [equals(401), () => callAfterUnAuthorized && callAfterUnAuthorized()],
        // @ts-ignore
      ])(error.status);
    }
    if (callAfterError != null) callAfterError();
  }
}

export async function postApiList<T>({
  url,
  data,
  params,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
  hideSuccessNotifications,
  callAfterUnAuthorized,
}: PostApi) {
  try {
    const response = await axios.post<T>(url, data, { params });

    // if (!hideNotifications && !hideSuccessNotifications) notify(`Success`, 'success');
    if (callAfterSuccess) callAfterSuccess(response.data);
    return response.data;
  } catch (error: any) {
    axiosHandleErrors(error, hideNotifications);
    // if (!hideNotifications) notify(`${error.response.data}`, 'error');
    if (error.isAxiosError && error.response) {
      cond([
        // @ts-ignore
        [equals(401), () => callAfterUnAuthorized && callAfterUnAuthorized()],
        // @ts-ignore
      ])(error.status);
    }
    if (callAfterError != null) callAfterError();
  }
}

export const putApi = async ({
  url,
  data,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
  hideSuccessNotifications,
}: PostApi) => {
  try {
    const response = await axios({
      method: 'put',
      url,
      data,
    });

    // if (!hideNotifications && !hideSuccessNotifications) notify(`Success`, 'success');
    if (callAfterSuccess != null) callAfterSuccess(response.data);
    return response.data;
  } catch (error) {
    axiosHandleErrors(error, hideNotifications);

    // if (overrideIsAxiosError(error)) {
    //   axiosHandleErrors(error, hideNotifications);
    // }
    // if (overrideIsAxiosError(error) && !hideNotifications) {
    //   notify(`${error.response?.data}`, 'error');
    // }
    if (callAfterError != null) callAfterError();
  }
};

export const deleteApi = async ({
  url,
  id,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
  hideSuccessNotifications,
}: DeleteApi) => {
  try {
    await axios({
      method: 'delete',
      url,
      params: {
        id,
      },
    });

    // if (!hideNotifications && !hideSuccessNotifications) notify(`Success`, 'success');
    if (callAfterSuccess != null) callAfterSuccess();
  } catch (error) {
    axiosHandleErrors(error, hideNotifications);
    // if (!hideNotifications) notify(`${error.response.data}`, 'error');
    if (callAfterError != null) callAfterError();
  }
};

// function overrideIsAxiosError(override: any): override is AxiosError {
//   return (
//     typeof override?.response === 'object' &&
//     override?.code === 'string' &&
//     override?.isAxiosError === 'boolean'
//   );
// }

export async function getItemApi<T>({
  url,
  params,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
}: GetApi) {
  try {
    const response = await axios.get<T>(url, {
      params,
    });
    if (callAfterSuccess != null) callAfterSuccess(response.data);
    return response.data;
  } catch (error) {
    axiosHandleErrors(error, hideNotifications);
    // if (!hideNotifications) notify(`${error.response.data}`, 'error');
    if (callAfterError != null) callAfterError();
  }
}

export async function getListApi<T>({
  url,
  params,
  callAfterError,
  callAfterSuccess,
  hideNotifications,
}: GetApi) {
  try {
    const response = await axios.get<Array<T>>(url, {
      params,
    });
    if (callAfterSuccess != null) callAfterSuccess(response.data);
    return response.data;
  } catch (error) {
    axiosHandleErrors(error, hideNotifications);
    if (callAfterError != null) callAfterError();
  }
}
