import React from 'react';
import './MobileMenuItem.scss';

interface Props {
  id: number;
  name: string;
  icon: string | null;
  onClick: (menuItemId: number) => void;
}

export function MobileMenuItem({ id, name, icon, onClick }: Props) {
  return (
    <div className="button-container" onClick={() => onClick(id)}>
      <div className="button-item">
        {icon && <i className={`dx-icon-${icon} button-start-icon`}></i>}
        <div>{name}</div>
      </div>
    </div>
  );
}
