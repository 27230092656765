import React from 'react';
import { DataGridButton, IColumnType, IDataType, SisDataGridColumns } from '../../components/table';

export const Columns = (handleEdit, translate): SisDataGridColumns => [
  {
    dataField: 'id',
    dataType: IDataType.number,
    width: '6%',
    caption: 'Asset class parameter id',
  },
  {
    dataField: 'assetClassName',
    width: '43%',
    caption: 'Asset class',
  },
  {
    dataField: 'parameterName',
    width: '43%',
    caption: 'Parameter',
    lookupPickBy: 'parameterName',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={() => handleEdit(cellData)}
    />
  );
};
