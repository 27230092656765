import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/TimePeriods/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const TimePeriodsDataGrid = ({ data }: IDataGrid) => {
  const history = useHistory();
  const { translate } = useTranslation();
  const transFormName = 'TimePeriods.List';
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Time periods', transFormName),
    });
  };

  const handleClose = (e: any) => {
    history.push(`/timeperiods/close/${e.row.data.id}`);
  };

  const handleOpen = (e: any) => {
    history.push(`/timeperiods/open/${e.row.data.id}`);
  };

  return (
    <SisDataGrid
      data={data}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={Columns(handleClose, handleOpen, translate)}
      columnChooser={false}
      allowColumnResizing={false}
      allowColumnReordering={false}
      onToolbarPreparing={onToolbarPreparing}
      // isEditable
      isReadOnly={false}
    />
  );
};
