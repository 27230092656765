import React from 'react';
import { DataGridButton, IColumnType, IDataType } from '../../../components/table';

export const Columns = (handleEdit, handleCopy, translate) => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset id',
    width: '5%',
  },
  {
    dataField: 'statusNameTranslated',
    caption: 'Status',
    width: '7%',
    lookupPickBy: 'statusNameTranslated',
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '12%',
  },
  {
    dataField: 'description',
    caption: 'Description',
    width: '11%',
  },
  {
    dataField: 'model',
    caption: 'Model',
    width: '10%',
  },
  {
    dataField: 'typeName',
    caption: 'Type',
    width: '10%',
    lookupPickBy: 'typeName',
  },
  {
    dataField: 'parentAssetName',
    caption: 'Parent asset',
    width: '10%',
  },
  {
    dataField: 'assetLocationName',
    caption: 'Locality',
    width: '10%',
  },
  {
    dataField: 'inventoryNumber',
    caption: 'Inventory number',
    width: '10%',
  },
  {
    dataField: 'serialNumber',
    caption: 'Serial number',
    width: '10%',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, handleCopy, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, handleCopy, translate) => {
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        marginRight={true}
        cellData={cellData}
        onClick={handleEdit}
      />
      <DataGridButton
        hint={translate!('Copy', '')}
        icon="copy"
        marginRight={true}
        cellData={cellData}
        onClick={handleCopy}
      />
    </>
  );
};
