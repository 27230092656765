import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/loader/Loader';
import { useLoading } from '../../contexts/loadingContext';
import './WorkSheetsForm.scss';

interface Props {
  visibleChange: any;
  visible: boolean;
  translate: any;
  transFormName: string;
  action: () => void;
  item: string;
}

export const DeleteDialog = ({
  visibleChange,
  visible,
  translate,
  transFormName,
  action,
  item,
}: Props) => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const { loading } = useLoading();

  useEffect(() => {
    setPopupVisible(visible);
  }, [visible]);

  if (!translate || !transFormName || !action) return <Loader />;

  return (
    <Popup
      visible={popupVisible}
      onHiding={() => visibleChange(false)}
      closeOnOutsideClick={true}
      showCloseButton={true}
      showTitle={true}
      title={translate!('Delete', transFormName)}
      container=".dx-viewport"
      minHeight={180}
      maxHeight={180}
      maxWidth={400}
    >
      <Position at="center" my="center" />

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={{
          icon: '',
          text: translate!('Close', transFormName),
          onClick: () => visibleChange(false),
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        disabled={loading}
        options={{
          icon: 'trash',
          text: translate!('Delete', transFormName),
          onClick: action,
        }}
      />
      <p>
        {`${translate!('Delete worksheet', transFormName)} `}
        <b>{`${item}`}</b>
        {`?`}
      </p>
    </Popup>
  );
};
