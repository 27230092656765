import React from 'react';
import { useHistory } from 'react-router-dom';
import { Columns } from '../../pages/Assets/Columns/AssetTabSparePartsColumns';
import { SisDataGrid } from '../table';

export const AssetSparePartsDataGrid = ({ data, dataGridRef }) => {
  const transFormName = 'Asset.SparePart';
  const history = useHistory();
  const columns = Columns(history);

  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        columns={columns}
        allowColumnReordering={false}
        allowColumnResizing={false}
        columnChooser={false}
        isEditable={false}
        isReadOnly={true}
      />
    </>
  );
};
