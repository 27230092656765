import { Box } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { getListApi } from '../../../apiUtils';
import AssistanceDataGrid from '../../../components/datagrid/AssistanceDataGrid';
import { useAssetClass } from '../../../contexts/assetClass';
import { useTerminalAuth } from '../../../contexts/terminalAuthContext';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { CapacityCategory } from '../../../types/capacityCategory.types';
import { Task } from '../../../types/task.types';
import { API_URL, fetcher } from '../../../utils/apiUrl';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';

// ms
const REFRESH_INTERVAL = 15000;

export function TerminalAssistanceQueue() {
  const { user, assetIdFromUrl, terminalMappings } = useTerminalAuth();
  const { assetClass } = useAssetClass();
  const [tasks1, setTasks1] = useState<Array<Task>>([]);
  const [tasks2, setTasks2] = useState<Array<Task>>([]);
  const [tasks3, setTasks3] = useState<Array<Task>>([]);
  const [tasks4, setTasks4] = useState<Array<Task>>([]);
  const [assetId, setAssetId] = useState<number | null>(null);
  const location = useLocation();
  const [sessionTerminalMappingId] = useSessionStorage(STORAGE_KEYS.TERMINAL_MAPPING_ID, null);

  const { data: capCategories, isValidating: loadingCapCategories } = useSWR<CapacityCategory[]>(
    sessionTerminalMappingId
      ? `${API_URL.CAPACITY_CATEGORY_LIST_BY_TERMINAL_MAPPING}?assetClassId=${assetClass}&terminalMappingId=${sessionTerminalMappingId}`
      : `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}&visibleOnly=true`,
    fetcher,
    {},
  );

  useEffect(() => {
    // Primárně načteme assetId z účtu uživatele
    if (user && user.assetId) {
      setAssetId(user.assetId);
      return;
    }

    // Uživatel assetId nemá, načteme assetId, které mohlo být nastaveno při přihlášení (např. přes URL)
    if (assetIdFromUrl) {
      setAssetId(assetIdFromUrl);
      return;
    }

    // Pokud existuje terminaMapping, načteme ID z něhoo - poslední možnost #430 - id je uložené v sessinStorage
    if (
      sessionTerminalMappingId &&
      terminalMappings &&
      terminalMappings.length > 0 &&
      terminalMappings[0]
    ) {
      const current = terminalMappings.find((x) => x.id === sessionTerminalMappingId);
      if (current) setAssetId(current.assignedAssetId);
      return;
    }

    // Při přihkášení assetId nepřišlo, podíváme se do URL přímo na této obrazovce
    const _assetIdFromUrl: number | null = location.search.includes('assetId')
      ? Number(location.search.split('=')[1])
      : null;
    setAssetId(_assetIdFromUrl);
  }, []);

  useEffect(() => {
    if (capCategories) {
      fetchTasks();
    }
  }, [capCategories, assetId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTasks();
    }, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [capCategories, assetId]);

  const fetchTasks = async () => {
    if (capCategories && capCategories?.length > 0 && capCategories[0] && assetId) {
      getListApi<Array<Task>>({
        url: `${API_URL.TASK_LIST_QUEUE}?capacityCategoryId=${capCategories[0].id}&assetId=${assetId}`,
        params: {},
        callAfterSuccess: (result) => {
          setTasks1(result);
        },
      });
    }

    if (capCategories && capCategories?.length > 1 && capCategories[1] && assetId) {
      getListApi<Array<Task>>({
        url: `${API_URL.TASK_LIST_QUEUE}?capacityCategoryId=${capCategories[1].id}&assetId=${assetId}`,
        params: {},
        callAfterSuccess: (result) => {
          setTasks2(result);
        },
      });
    }

    if (capCategories && capCategories?.length > 2 && capCategories[2] && assetId) {
      getListApi<Array<Task>>({
        url: `${API_URL.TASK_LIST_QUEUE}?capacityCategoryId=${capCategories[2].id}&assetId=${assetId}`,
        params: {},
        callAfterSuccess: (result) => {
          setTasks3(result);
        },
      });
    }

    if (capCategories && capCategories?.length > 3 && capCategories[3] && assetId) {
      getListApi<Array<Task>>({
        url: `${API_URL.TASK_LIST_QUEUE}?capacityCategoryId=${capCategories[3].id}&assetId=${assetId}`,
        params: {},
        callAfterSuccess: (result) => {
          setTasks4(result);
        },
      });
    }
  };

  let tasks3Style = { borderRight: '1px solid white' };
  if (capCategories && capCategories.length <= 3) tasks3Style = { borderRight: 'none' };

  return (
    <PageContentWrapper
      noContentBlock
      noDxCard
      noResponsivePaddings
      loading={loadingCapCategories}
      error={!tasks1 || !tasks2 || !tasks3 || !tasks4}
    >
      {capCategories && (
        <Box>
          {tasks1 && capCategories.length > 0 && (
            <Item ratio={1}>
              <AssistanceDataGrid
                category={capCategories[0]}
                data={tasks1}
                color={capCategories[0].colorName}
              />
            </Item>
          )}
          {tasks2 && capCategories.length > 1 && (
            <Item ratio={1}>
              <div style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
                <AssistanceDataGrid
                  category={capCategories[1]}
                  data={tasks2}
                  color={capCategories[1].colorName}
                />
              </div>
            </Item>
          )}
          {tasks3 && capCategories.length > 2 && (
            <Item ratio={1}>
              <div style={tasks3Style}>
                <AssistanceDataGrid
                  category={capCategories[2]}
                  data={tasks3}
                  color={capCategories[2].colorName}
                />
              </div>
            </Item>
          )}
          {tasks4 && capCategories.length > 3 && (
            <Item ratio={1}>
              <AssistanceDataGrid
                category={capCategories[3]}
                data={tasks4}
                color={capCategories[3].colorName}
              />
            </Item>
          )}
        </Box>
      )}
    </PageContentWrapper>
  );
}

export default TerminalAssistanceQueue;
