import React, { useEffect } from 'react';
import useSWR from 'swr';
import { AssetStatusesDataGrid } from '../../components/datagrid/AssetStatusesDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetStatus } from '../../types/assetStatus.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function AssetStatuses() {
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Asset statuses', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);
  const { data, error } = useSWR<AssetStatus[]>(
    `${API_URL.ASSET_STATUS_LIST}?visibleOnly=false`,
    fetcher,
    swrConfig,
  );

  return (
    <PageContentWrapper error={error} loading={!data}>
      <AssetStatusesDataGrid data={data} />
    </PageContentWrapper>
  );
}
export default AssetStatuses;
