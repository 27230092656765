import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { getItemApi, getListApi, postApi, putApi } from '../../apiUtils';
import TaskCustomStepModal from '../../components/modals/tasks/TaskCustomStepModal';
import TaskStepGroupModal from '../../components/modals/tasks/TaskStepGroupModal';
import TaskStepModal from '../../components/modals/tasks/TaskStepModal';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { DataGridActionType } from '../../types/dataGridActionTypes';
import { Step, TaskStep, TaskStepStatus } from '../../types/step.types';
import { StepGroup } from '../../types/stepGroup.types';
import { Task } from '../../types/task.types';
import { TaskActionTypes } from '../../types/taskActionTypes';
import { TaskStatusApiResponse } from '../../types/TaskStatus';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';
import './TaskSteps.scss';
import { TaskStepsRow } from './TaskStepsRow';

type Props = {
  taskId: number;
  data: TaskStep[];
  mutate: () => void;
  platform: 'desktop' | 'terminal';
};

export const TaskSteps = ({ taskId, data, mutate, platform }: Props) => {
  const transFormName = 'Task.Step';
  const { startLoading, stopLoading } = useLoading();
  const { translate } = useTranslation();

  const [customStepModalVisible, setCustomStepModalVisible] = useState<boolean>(false);
  const [stepModalVisible, setStepModalVisible] = useState<boolean>(false);
  const [stepGroupModalVisible, setStepGroupModalVisible] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<TaskStep | null>(null);
  // create / edit action
  const [selectedAction, setSelectedAction] = useState<DataGridActionType | null>(null);
  // not yet implemented
  const [isPartiallyCompleteAllowed, setIsPartiallyCompleteAllowed] = useState<boolean | null>(
    null,
  );

  // allowed actions for task steps - from TASK_PROCESS_CONFIG
  const [isCompleteAllowed, setIsCompleteAllowed] = useState<boolean | null>(null);
  const [isCancelAllowed, setIsCancelAllowed] = useState<boolean | null>(null);
  const [isReopenAllowed, setIsReopenAllowed] = useState<boolean | null>(null);
  const [isEditAllowed, setIsEditAllowed] = useState<boolean | null>(null);
  const [isAddStepAllowed, setIsAddStepAllowed] = useState<boolean | null>(null);

  // fetch capacity categories
  const { data: capacityCategoryList } = useSWR<CapacityCategory[]>(
    API_URL.CAPACITY_CATEGORY_LIST,
    fetcher,
  );

  // fetch step group for create modal
  const { data: stepGroupList } = useSWR<StepGroup[]>(API_URL.STEP_GROUP_LIST, fetcher);

  // fetch steps for create modal
  const { data: stepList } = useSWR<Step[]>(
    `${API_URL.STEP_LIST_BY_TASK}?taskId=${taskId}`,
    fetcher,
  );

  // fetch task process config settings for actions
  const { data: taskProcessConfigList } = useSWR<TaskStatusApiResponse>(
    `${API_URL.TASK_PROCESS_CONFIG}?taskId=${taskId}`,
    fetcher,
    swrConfig,
  );

  // Načteme task, kvůli responsible person
  const { data: task } = useSWR<Task>(`${API_URL.TASK_ITEM}?id=${taskId}`, fetcher, swrConfig);

  // nastavení akcí podle TASK_PROCESS_CONFIG
  useEffect(() => {
    if (!taskProcessConfigList) return;
    setIsAddStepAllowed(taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_ADD));
    setIsEditAllowed(taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_EDIT));
    setIsReopenAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_REOPEN),
    );
    setIsCompleteAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_COMPLETE),
    );
    setIsCancelAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_CANCEL),
    );
  }, [taskProcessConfigList]);

  // if new action is same as currenct state dont call api
  const handleValueChange = (e, step) => {
    if (e === null && step.status !== TaskStepStatus.CANCELED)
      return handleApiCall({ type: TaskStepStatus.CANCELED, stepId: step.id });
    if (e === false && step.status !== TaskStepStatus.OPEN)
      return handleApiCall({ type: TaskStepStatus.OPEN, stepId: step.id });
    if (e === true && step.status !== TaskStepStatus.COMPLETE)
      return handleApiCall({ type: TaskStepStatus.COMPLETE, stepId: step.id });
  };

  // call api by type
  // Reopen / Complete / Cancel
  const handleApiCall = async ({ type, stepId }) => {
    // TODO jak řešit komentáře?
    switch (type) {
      case TaskStepStatus.OPEN:
        startLoading();
        await putApi({
          url: API_URL.TASK_STEP_REOPEN,
          data: { stepId: stepId, comment: undefined },
        });
        break;
      case TaskStepStatus.COMPLETE:
        startLoading();
        if (!task) return;
        if (!task.responsiblePersonId) {
          notify(
            translate!(
              'Task step cannot be completed. There is not a responsible person.',
              transFormName,
            ),
            'error',
          );
          stopLoading();
          return;
        }

        await putApi({
          url: API_URL.TASK_STEP_COMPLETE,
          data: { stepId: stepId, comment: undefined, userId: task.responsiblePersonId },
        });
        break;
      default:
        break;
    }
    mutate();
    stopLoading();
  };

  const handleCancelStep = async (stepId: number) => {
    await putApi({
      url: API_URL.TASK_STEP_CANCEL,
      data: { stepId: stepId, comment: undefined },
      callAfterSuccess: () => {
        mutate();
      },
    });
  };

  const handleReopenStep = async (stepId: number) => {
    await putApi({
      url: API_URL.TASK_STEP_REOPEN,
      data: { stepId: stepId, comment: undefined },
      callAfterSuccess: () => {
        mutate();
      },
    });
  };

  // edit / create
  const handleOpenCustomStepModal = (step?: TaskStep) => {
    setSelectedStep(step ?? null);
    setSelectedAction(step ? DataGridActionType.Update : DataGridActionType.Insert);
    setCustomStepModalVisible(true);
  };

  const handleOpenStepModal = (step?: TaskStep) => {
    setSelectedStep(step ?? null);
    setSelectedAction(step ? DataGridActionType.Update : DataGridActionType.Insert);
    setStepModalVisible(true);
  };

  const handleOpenStepGroupModal = (step?: TaskStep) => {
    setSelectedStep(step ?? null);
    setSelectedAction(step ? DataGridActionType.Update : DataGridActionType.Insert);
    setStepGroupModalVisible(true);
  };

  const handleClose = () => {
    setCustomStepModalVisible(false);
    setStepGroupModalVisible(false);
    setStepModalVisible(false);
    setSelectedStep(null);
    setSelectedAction(null);
  };

  // call api, mutate
  const onDataSubmit = async (newData, action: 'customStep' | 'step' | 'stepGroup') => {
    switch (selectedAction) {
      case DataGridActionType.Insert:
        if (action === 'customStep')
          await postApi({
            url: API_URL.TASK_STEP_POST,
            data: { ...newData, taskId: taskId },
          });
        else if (action === 'stepGroup') {
          // Máme jen id step group, načteme stepy dané groupy
          let stepsByGroup: Step[] | null = null;
          await getListApi({
            url: API_URL.STEP_GROUP_STEP_LIST,
            params: { parentId: newData.stepGroupId },
            callAfterSuccess: (result) => {
              stepsByGroup = result;
            },
          });
          if (!stepsByGroup) break;
          await postSteps(stepsByGroup);
        } else if (action === 'step') {
          // Načteme si step
          let step: any = null;
          await getItemApi({
            url: API_URL.STEP_ITEM,
            params: { id: newData.stepId },
            callAfterSuccess: (result: Step) => {
              step = result;
            },
          });
          if (!step) break;
          await postSteps([
            {
              stepName: step.name,
              capacityCategoryId: step.capacityId,
              stepDescription: step.description,
            },
          ]);
        }
        break;
      case DataGridActionType.Update:
        const oldData = data?.find((item) => item.id === selectedStep?.id);
        oldData &&
          (await putApi({
            url: API_URL.TASK_STEP_PUT,
            data: { ...oldData, ...newData },
          }));
        break;
      default:
        break;
    }

    await delay(500);

    handleClose();
    mutate();
  };

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async function postSteps(steps: any[]) {
    steps.forEach(async (item: any) => {
      await postApi({
        url: API_URL.TASK_STEP_POST,
        data: {
          taskId: taskId,
          name: item.stepName,
          capacityCategoryId: item.capacityCategoryId,
          description: item.stepDescription,
        },
      });
    });
  }

  return (
    <>
      <div className="dx-datagrid-header-panel">
        <div
          aria-label="Data grid toolbar"
          className="dx-toolbar dx-widget dx-visibility-change-handler dx-collection"
          role="toolbar"
        >
          <div className="dx-toolbar-items-container">
            <div className="dx-toolbar-before">
              <div className="dx-item dx-toolbar-item dx-toolbar-label" style={{ maxWidth: 300 }}>
                <div className="dx-item-content dx-toolbar-item-content">
                  {translate!('Task steps', transFormName)}
                </div>
              </div>
            </div>
            <div
              className="dx-toolbar-center"
              style={{ margin: '0 0 0 167px', float: 'none' }}
            ></div>
            <div className="dx-toolbar-after">
              <div className="dx-item dx-toolbar-item dx-toolbar-button">
                <div className="dx-item-content dx-toolbar-item-content">
                  {isAddStepAllowed && (
                    <>
                      <Button
                        icon="plus"
                        onClick={() => handleOpenCustomStepModal()}
                        text={translate!('Add custom step', transFormName)}
                        type={'default'}
                        style={{ marginRight: 5 }}
                      />
                      <Button
                        icon="plus"
                        onClick={() => handleOpenStepModal()}
                        text={translate!('Add step', transFormName)}
                        type={'default'}
                        style={{ marginRight: 5 }}
                      />
                      <Button
                        icon="plus"
                        onClick={() => handleOpenStepGroupModal()}
                        text={translate!('Add step group', transFormName)}
                        type={'default'}
                        style={{ marginRight: 5 }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table className="step-table dx-datagrid-table dx-datagrid-table-fixed">
        <thead>
          <tr>
            <th style={{ width: 50 }}></th>
            <th style={{ width: 200 }}>{translate!('Name', transFormName)}</th>
            <th>{translate!('Capacity category', transFormName)}</th>
            {platform === 'desktop' && <th>{translate!('Description', transFormName)}</th>}
            <th>{translate!('Completed by', transFormName)}</th>
            <th>{translate!('Completed date', transFormName)}</th>
            <th style={{ width: 80, textAlign: 'right' }}></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((step, index) => (
            <TaskStepsRow
              step={step}
              key={index}
              isCancelAllowed={isCancelAllowed}
              isCompleteAllowed={isCompleteAllowed}
              isEditAllowed={isEditAllowed}
              isPartiallyCompleteAllowed={isPartiallyCompleteAllowed}
              isReopenAllowed={isReopenAllowed}
              handleCancelStep={handleCancelStep}
              handleOpenCustomStepModal={handleOpenCustomStepModal}
              handleReopenStep={handleReopenStep}
              handleValueChange={handleValueChange}
              platform={platform}
            />
          ))}
        </tbody>
      </table>
      {selectedAction && (
        <>
          <TaskCustomStepModal
            capacityCategoryList={capacityCategoryList}
            isVisible={customStepModalVisible}
            data={selectedStep ?? null}
            actionType={selectedAction}
            transFormName={transFormName}
            onSubmit={onDataSubmit}
            onClose={handleClose}
            title={selectedAction === DataGridActionType.Insert ? 'Add custom step' : 'Edit step'}
            popupWidth={platform === 'desktop' ? '20%' : '40%'}
          />
          <TaskStepModal
            stepList={stepList}
            isVisible={stepModalVisible}
            data={selectedStep ?? null}
            actionType={selectedAction}
            transFormName={transFormName}
            onSubmit={onDataSubmit}
            onClose={handleClose}
            popupWidth={platform === 'desktop' ? '20%' : '40%'}
          />
          <TaskStepGroupModal
            stepGroupList={stepGroupList}
            isVisible={stepGroupModalVisible}
            data={selectedStep ?? null}
            actionType={selectedAction}
            transFormName={transFormName}
            onSubmit={onDataSubmit}
            onClose={handleClose}
            popupWidth={platform === 'desktop' ? '20%' : '40%'}
          />
        </>
      )}
    </>
  );
};
