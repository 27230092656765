import { always, ifElse } from 'ramda';
import React, { ReactChildren, ReactNode } from 'react';
import { AssetTreeView } from './AssetTreeView';

export interface ITreeViewWithBack {
  isTreeView: boolean;
  children?: ReactChildren | any;
  classId: number;
  parentId?: number;
  toolbar?: ReactNode;
  dataGrid: any;
  setIsTreeView: (e: any) => void;
  hideToolbar?: boolean;
}

export const AssetTreeViewWithBack = ({
  isTreeView,
  dataGrid,
  classId,
  parentId,
  toolbar,
  setIsTreeView,
  hideToolbar,
}: ITreeViewWithBack) => {
  return (
    <div>
      {toolbar}
      {ifElse(
        always(isTreeView),
        () => (
          <AssetTreeView
            classId={classId}
            isTreeView={isTreeView}
            setIsTreeView={setIsTreeView}
            parentId={parentId}
            visible
            hideToolbar={hideToolbar}
          />
        ),
        () => dataGrid,
      )(
        // @ts-ignore
        isTreeView,
      )}
    </div>
  );
};
