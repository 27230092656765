import React, { useEffect, useRef, useState } from 'react';
import { AssetSparePartsDataGrid } from '../../../components/datagrid/AssetSparePartsDataGrid';
import { PageContentWrapper } from '../../../layouts/contentWrapper/PageContentWrapper';
import { AssetSpareParts } from '../../../types/spareParts.types';
import { ITab } from '../AssetsEdit';

interface Props extends ITab {
  spareParts: AssetSpareParts[];
  mutateSpareParts: any;
}

export const AssetTabSpareParts = ({ active, spareParts, mutateSpareParts }: Props) => {
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);

  useEffect(() => {
    if (active) mutateSpareParts();
  }, [active]);

  return (
    <PageContentWrapper noContentBlock noDxCard>
      <AssetSparePartsDataGrid data={spareParts} dataGridRef={dataGridRef} />
    </PageContentWrapper>
  );
};
