import React, { ForwardedRef, forwardRef } from 'react';
import './qr-print.scss';

type Props = {
  source: any;
  assetId: number;
  assetName: string;
};

const QrHiddenComponent = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { source, assetId, assetName } = props;
  return (
    <div ref={ref} className="qr-hidden-content">
      <img className="qr-image" src={source} alt="generated qr for print" />
      <span className="qr-text">{assetId}</span>
      <span className="qr-text">{assetName}</span>
    </div>
  );
});

QrHiddenComponent.displayName = 'QrHiddenComponent';

export default QrHiddenComponent;
