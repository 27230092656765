import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useAssetClass } from '../../contexts/assetClass';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { TaskNotificationConfigForm } from '../../forms/taskNotificationConfigForm/taskNotificationConfigForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetLocation } from '../../types/assetLocation.types';
import { CapacityCategory } from '../../types/capacityCategory.types';
import { LocationState } from '../../types/locationState.types';
import { NotificationTemplateApiResponse } from '../../types/notificationTemplate.types';
import { DetailPageParams } from '../../types/pageParams.types';
import { TaskNotificationConfig } from '../../types/taskNotificationConfig.types';
import { TaskStatusApiResponse } from '../../types/TaskStatus';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function TaskNotificationConfigEdit() {
  const { translate } = useTranslation();
  const transFormName = 'TaskNotificationConfig.Edit';
  const { startLoading, stopLoading } = useLoading();
  const { id } = useParams<DetailPageParams>();
  const { setValue } = useBreadcrumbs();
  const { assetClass } = useAssetClass();
  const history = useHistory();
  const { state }: { state: LocationState | undefined } = useLocation();
  const backRoute = state?.backRoute ?? '/tasks/notification-config';

  useEffect(() => {
    setValue({
      pageName: 'Edit task notification config',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Task notification config', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { data: formData, error } = useSWR<TaskNotificationConfig>(
    `${API_URL.TASK_NOTIFICATION_CONFIG_ITEM}?id=${id}`,
    fetcher,
  );

  const { data: catacityCategoryList, error: catacityCategoryError } = useSWR<CapacityCategory[]>(
    `${API_URL.CAPACITY_CATEGORY_LIST}?parentId=${assetClass}`,
    fetcher,
  );

  const { data: taskProcessConfigList, error: taskProcessConfigError } =
    useSWR<TaskStatusApiResponse>(
      `${API_URL.TASK_PROCESS_CONFIG_LIST_CURRENT_ASSET_CLASS}`,
      fetcher,
      swrConfig,
    );

  const { data: notificationTemplateList, error: notificationTemplateError } =
    useSWR<NotificationTemplateApiResponse>(
      `${API_URL.NOTIFICATION_TEMPLATE_LIST}`,
      fetcher,
      swrConfig,
    );
  const { data: assetLocationList, error: assetLocationError } = useSWR<AssetLocation[]>(
    API_URL.ASSET_LOCATION_LIST,
    fetcher,
  );

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!formData) return;
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.TASK_NOTIFICATION_CONFIG_PUT,
      data: formData,
      callAfterSuccess: () => {
        isOk = true;
        history.push(backRoute);
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!formData}
      error={
        error ||
        catacityCategoryError ||
        taskProcessConfigError ||
        notificationTemplateError ||
        assetLocationError
      }
      heading={translate!('Edit task notification config', transFormName)}
    >
      {assetLocationList &&
        notificationTemplateList &&
        taskProcessConfigList &&
        catacityCategoryList &&
        formData && (
          <TaskNotificationConfigForm
            onSubmit={onSubmit}
            formData={formData}
            isEdit
            assetLocationList={assetLocationList}
            notificationTemplateList={notificationTemplateList}
            taskProcessConfigList={taskProcessConfigList}
            catacityCategoryList={catacityCategoryList}
          />
        )}
    </PageContentWrapper>
  );
}
export default TaskNotificationConfigEdit;
