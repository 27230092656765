import notify from 'devextreme/ui/notify';
import React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { SWRConfig } from 'swr';
import { Loader } from './components/loader/Loader';
import { RoutesContainer } from './container/RoutesContainer';
import { AssetClassProvider } from './contexts/assetClass';
import { ConfigProvider } from './contexts/configContext';
import { ModuleProvider } from './contexts/moduleContext';
import { TranslationProvider } from './contexts/translation';
import { VersionProvider } from './contexts/versionContext';

export function App() {
  return (
    <SWRConfig
      value={{
        onError: (error) => {
          if (error.status === 401) {
            notify(`Došlo k odhlášení, budete přesměrováni na přihlašovací obrazovku.`, 'error');

            if (window.location.href.includes(`/#/terminal/assistance-queue`))
              window.location.href = `/#/terminal/assistance-queue`;
            else if (window.location.href.includes(`/#/terminal/assistance`))
              window.location.href = `/#/terminal/assistance`;
            else if (window.location.href.includes(`/#/terminal`))
              window.location.href = `/#/terminal`;
            else if (window.location.href.includes(`/#/mobile`)) window.location.href = `/#/mobile`;
            else window.location.href = `/#/`;

            setTimeout(() => {
              location.reload();
            }, 2000);
          }

          if (error.status === 404) {
            notify(`Server není dostupný.`, 'error');
          }
        },
      }}
    >
      <TranslationProvider>
        <ConfigProvider>
          <VersionProvider>
            <ModuleProvider>
              <React.Suspense fallback={<Loader />}>
                <Router>
                  <LastLocationProvider>
                    <AssetClassProvider>
                      <Switch>
                        <RoutesContainer />
                      </Switch>
                    </AssetClassProvider>
                  </LastLocationProvider>
                </Router>
              </React.Suspense>
            </ModuleProvider>
          </VersionProvider>
        </ConfigProvider>
      </TranslationProvider>
    </SWRConfig>
  );
}
