import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/Parameters/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const ParametersDataGrid = ({ data, handleEdit }: IDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'Parameter.List';
  const history = useHistory();

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        html: translate!('Parameters', transFormName),
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => history.push(`/parameters/new/`),
        },
      },
    );
  };
  return (
    <>
      <SisDataGrid
        data={data}
        keyExpr={'id'}
        transFormName={transFormName}
        columns={Columns(handleEdit, data, translate)}
        columnChooser={false}
        allowColumnResizing={false}
        allowColumnReordering={false}
        onToolbarPreparing={onToolbarPreparing}
      />
    </>
  );
};
