import { DataGrid } from 'devextreme-react';
import {
  Column,
  ColumnChooser,
  CustomRule,
  Editing,
  Export,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import { exportDataGrid as exportDataGridToXlsx } from 'devextreme/excel_exporter';
import { locale } from 'devextreme/localization';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import dxDataGrid, {
  CellClickEvent,
  InitNewRowEvent,
  RowClickEvent,
  RowPreparedEvent,
  SavingEvent,
  ToolbarPreparingEvent,
} from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { isEmpty, isNil } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { Arial } from '../../assets/fonts/arial-normal';
import '../../assets/tasks.scss';
import { CONFIG_KEYS, useConfigByKey } from '../../contexts/configContext';
import { useTranslation } from '../../contexts/translation';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { timespan } from '../../utils/customRenderer';
import { customCalculationForSummaryRow } from '../../utils/timeUtils';

export interface SisDataGridProps {
  ref?: any;
  dataGridRef?: any;
  data: any;
  keyExpr: string;
  allowColumnResizing?: boolean;
  allowColumnReordering?: boolean;
  showBorders?: boolean;
  columnChooser?: boolean;
  filterRow?: boolean;
  transFormName: string;
  columns: SisDataGridColumns;
  show?: boolean;
  onToolbarPreparing?: (e: ToolbarPreparingEvent) => void;
  onCellClick?: (e: CellClickEvent) => void;
  stateStoringKey?: string;
  children?: any;
  onSaving?: (e: SavingEvent) => void;
  onInitNewRow?: (e: InitNewRowEvent) => void;
  isEditable?: boolean;
  disableAdding?: boolean;
  isReadOnly?: boolean;
  prompt?: any;
  onSelectionChanged?: (e: EventInfo<dxDataGrid>) => void;
  onRowClick?: (e: RowClickEvent) => void;
  selectionMode?: SelectionMode;
  // eslint-disable-next-line no-undef
  masterDetail?: JSX.Element;
  defaultPageSize?: number | string;
  pageSize?: number | string;
  onRowPrepared?: (e: RowPreparedEvent) => void;
  search?: boolean;
  allowHeaderFiltering?: boolean;
  [x: string]: any;
  enableXlsxExport?: boolean;
  allowExportSelectedData?: boolean;
  exportName?: string;
  enablePdfExport?: boolean;
  pageSizeChangeVisible?: boolean;
  enableGrouping?: boolean;
  disableGroupPanel?: boolean;
  sortingMode?: SortingMode;
  wordWrapEnabled?: boolean;
  totalSummaryEnabled?: boolean;
  totalSummaryColumns?: any[];
  groupSummaryEnabled?: boolean;
  groupSummaryColumns?: any[];
  deferredModeEnabled?: boolean;
  customDataGridStyle?: {};
  autoExpandGroupedItems?: boolean;
  datagridConfig?: any;
  generalReport?: boolean;
}

export enum IDataType {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  dateTime = 'datetime',
  customRender = 'customRender',
}

export enum BoolFilterValue {
  all = 'all',
}

export const dateFormat = () => {
  if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'dd.MM.yyyy';
  else return 'dd/MM/yyyy';
};

export const dateTimeFormat = () => {
  if (locale() === 'cs-CZ' || locale() === 'sk-SK') return 'dd.MM.yyyy HH:mm';
  else return 'dd/MM/yyyy HH:mm';
};

export enum IColumnType {
  buttons = 'buttons',
}

export interface SisDataGridColumnCustomRule {
  message: string;
  validationCallback: (e: any) => any;
}

export interface SisDataGridColumn {
  dataField?: string;
  width?: string | number;
  dataType?: IDataType;
  caption?: string;
  lookupPickBy?: string;
  children?: (data?: any) => any;
  type?: IColumnType;
  lookupSource?: any;
  allowEditing?: boolean;
  lookupValueExpr?: string;
  lookupDisplayExpr?: string;
  requiredRule?: boolean;
  customRules?: Array<SisDataGridColumnCustomRule>;
  editorOptions?: any;
  setCellValue?: any;
  content?: (c: any, index: number) => void;
  customizeText?: any;
  cellRender?: (...params: any) => React.ReactNode;
  dataRowRender?: any;
  selectedRowKeys?: any;
  [x: string]: any;
  groupIndex?: number;
  groupingSortBy?: string;
  groupingSortOrder?: string;
}

export enum SelectionMode {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
}

export enum SortingMode {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  NONE = 'none',
}

export type SisDataGridColumns = Array<SisDataGridColumn>;

export const SisDataGrid = ({
  dataGridRef,
  show = true,
  transFormName,
  columnChooser = true,
  filterRow = true,
  data,
  keyExpr,
  allowColumnReordering = true,
  allowColumnResizing = true,
  showBorders = true,
  columns,
  onToolbarPreparing,
  onCellClick,
  onCellDblClick,
  stateStoringKey,
  children,
  isEditable,
  disableAdding,
  isReadOnly,
  onInitNewRow,
  onSaving,
  prompt,
  onSelectionChanged,
  onRowClick,
  selectionMode,
  masterDetail,
  defaultPageSize = 10,
  pageSize = 10,
  pageSizeFromConfig = true,
  onRowPrepared,
  onCellPrepared,
  allowHeaderFiltering = true,
  search,
  enableXlsxExport = false,
  allowExportSelectedData = false,
  exportName = 'data-export',
  enablePdfExport = false,
  pageSizeChangeVisible = false,
  enableGrouping = false,
  disableGroupPanel = false,
  wordWrapEnabled = false,
  sortingMode = SortingMode.SINGLE,
  totalSummaryEnabled = false,
  totalSummaryColumns,
  groupSummaryEnabled = false,
  groupSummaryColumns,
  deferredModeEnabled = false,
  dataRowRender,
  customDataGridStyle,
  autoExpandGroupedItems = true,
  groupingSortBy,
  groupingSortOrder = 'asc',
  datagridConfig,
  generalReport = false,
  selectedRowKeys,
  ...props
}: SisDataGridProps) => {
  const { translate } = useTranslation();
  const [sessionConfig] = useSessionStorage(stateStoringKey, {});
  const tablePageSizeConfig = useConfigByKey(CONFIG_KEYS.TABLE_ROWS_PER_PAGE_LIST);
  const [allowedPageSizes, setAllowedPageSizes] = useState<any>([]);

  useEffect(() => {
    if (generalReport) {
      setAllowedPageSizes([...datagridConfig.allowedPageSizes, 'all']);
    } else {
      const pageSizes = sessionConfig.allowedPageSizes ??
        tablePageSizeConfig?.value.split(';').filter(Number).map(Number) ?? ['all'];
      !pageSizes.includes('all') && pageSizes.push('all');
      setAllowedPageSizes(pageSizes);
    }
  }, []);

  const customRendering = (cellData, renderer) => {
    switch (renderer) {
      case 'Timespan': {
        return <span>{timespan(cellData.data[cellData.column.dataField])}</span>;
      }
      default:
        return <span>{cellData.data[cellData.column.dataField]}</span>;
    }
  };

  useEffect(() => {}, [selectedRowKeys]);

  const Columns = columns.map((c, index) => {
    if (c.dataType === IDataType.boolean)
      return (
        <Column
          key={`${c.dataField}-${c.dataType}-${index}}`}
          dataField={c.dataField}
          dataType={IDataType.boolean}
          caption={generalReport ? c.caption : c.caption && translate!(c.caption, transFormName)}
          // width={c.width ?? '10%'}
          width={c.width || 100}
          trueText={translate!('Yes', '')}
          falseText={translate!('No', '')}
          selectedFilterOperation={'='}
          filterValue={c.filterValue === BoolFilterValue.all ? undefined : true}
          groupIndex={c.groupIndex ?? undefined}
        />
      );

    if (c.dataType === IDataType.customRender) return c.content && c.content(c, index);

    if (c.cellRender || c.customRender) {
      return (
        <Column
          key={`${c.dataField}c+${index}`}
          customizeText={c.customizeText}
          setCellValue={c.setCellValue}
          dataField={c.dataField}
          dataType={c.dataType}
          caption={generalReport ? c.caption : c.caption && translate!(c.caption, transFormName)}
          // width={c.width ?? '10%'}
          width={c.width || 100}
          type={c.type}
          allowEditing={c.allowEditing}
          format={c.format}
          cellRender={
            c.customRender && !c.highlightConditions
              ? (cellData: any) => customRendering(cellData, c.customRender)
              : c.cellRender
          }
          groupIndex={c.groupIndex ?? undefined}
          groupCellRender={c.cellRender}
          calculateSortValue={c.calculateSortValue ?? null}
        >
          {c.requiredRule && <RequiredRule />}
          {c.customRules?.map((r) => (
            <CustomRule
              key={r.message}
              message={translate!(r.message, transFormName)}
              validationCallback={r.validationCallback}
            />
          ))}
          {c.children && c.children()}
        </Column>
      );
    }

    return (
      <Column
        key={`${c.dataField}c+${index}`}
        customizeText={c.customizeText}
        setCellValue={c.setCellValue}
        dataField={c.dataField}
        dataType={c.dataType}
        caption={generalReport ? c.caption : c.caption && translate!(c.caption, transFormName)}
        // width={c.width ?? '10%'}
        width={c.width || 100}
        type={c.type}
        allowEditing={c.allowEditing}
        format={c.format}
        groupIndex={c.groupIndex ?? undefined}
      >
        {c.requiredRule && <RequiredRule />}
        {c.customRules?.map((r) => (
          <CustomRule
            key={r.message}
            message={translate!(r.message, transFormName)}
            validationCallback={r.validationCallback}
          />
        ))}
        {c.children && c.children()}
      </Column>
    );
  });

  /**
   * Export rozložení datagridu včetně dat do formátu XLSX
   */
  const exportToXlsx = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    exportDataGridToXlsx({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${exportName}.xlsx`);
      });
    });
    e.cancel = true;
  };

  /**
   * Export rozložení datagridu včetně dat do formátu XLSX
   */
  const exportToPdf = useCallback(() => {
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;
    // import konvertovaného Arial fontu pro podporu utf-8
    doc.addFileToVFS('arial-normal.ttf', Arial);
    doc.addFont('arial-normal.ttf', 'arial-normal', 'normal');
    doc.setFont('arial-normal');
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      customizeCell: (options) => {
        const { pdfCell } = options;
        pdfCell.styles = {
          font: 'arial-normal',
        };
      },
    }).then(() => {
      doc.save(`${exportName}.pdf`);
    });
  }, []);

  /**
   * Přidání tlačítka pro export do pdf do existujícího/nového toolbaru nad datagrid
   */
  const onToolbarMerge = (e) => {
    if (onToolbarPreparing) onToolbarPreparing(e);
    if (enablePdfExport) {
      e.toolbarOptions.items.push({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          onClick: () => exportToPdf(),
        },
      });
    }
  };

  return (
    <>
      <DataGrid
        onExporting={exportToXlsx}
        visible={show}
        {...(customDataGridStyle ? { style: customDataGridStyle } : {})}
        ref={dataGridRef}
        dataSource={isNil(data) || isEmpty(data) ? [] : data}
        keyExpr={keyExpr}
        allowColumnResizing={allowColumnResizing}
        allowColumnReordering={allowColumnReordering}
        columnResizingMode={'widget'}
        showBorders={showBorders}
        onToolbarPreparing={onToolbarMerge}
        onCellClick={onCellClick}
        onCellDblClick={onCellDblClick}
        onSaving={onSaving}
        {...(dataRowRender ? { dataRowRender: dataRowRender } : {})}
        wordWrapEnabled={wordWrapEnabled}
        onRowPrepared={(e) => onRowPrepared && onRowPrepared(e)}
        onCellPrepared={(e) => {
          onCellPrepared && onCellPrepared(e);
          if (e.rowType === 'data') {
            // @ts-ignore
            const col = columns?.find((column) => column.dataField === e?.column?.dataField);
            const css = col?.cssClass;
            if (css) e.cellElement.className += ` ${css}`;
          }
        }}
        onInitNewRow={onInitNewRow}
        onRowClick={onRowClick}
        onSelectionChanged={(e) => {
          onSelectionChanged && onSelectionChanged(e);
        }}
        {...props}
      >
        {search && <SearchPanel visible={true} />}
        {/* <Scrolling rowRenderingMode="standard" columnRenderingMode="standard" /> */}
        <ColumnChooser enabled={columnChooser} />
        <Export enabled={enableXlsxExport} allowExportSelectedData={allowExportSelectedData} />
        {filterRow && <FilterRow visible={filterRow} />}
        {allowHeaderFiltering && <HeaderFilter visible={allowHeaderFiltering} />}
        {stateStoringKey && !isEmpty(stateStoringKey) && (
          <StateStoring
            enabled={!isEmpty(stateStoringKey)}
            savingTimeout={100}
            type="sessionStorage"
            storageKey={stateStoringKey ?? 'HE'}
          />
        )}
        {!pageSizeFromConfig && (
          <Paging
            defaultPageSize={(generalReport && datagridConfig.pageSize) ?? defaultPageSize}
            pageSize={pageSize}
          />
        )}
        {generalReport && datagridConfig && <Paging defaultPageSize={datagridConfig.pageSize} />}
        {pageSizeChangeVisible && (
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            showPageSizeSelector={true}
            showInfo={true}
          />
        )}
        {isEditable && prompt}
        {isEditable && (
          <Editing
            refreshMode={'full'}
            mode="cell"
            allowAdding={!isReadOnly && !disableAdding}
            allowUpdating={!isReadOnly}
          />
        )}
        <Sorting mode={sortingMode} />
        {enableGrouping && <Grouping contextMenuEnabled autoExpandAll={autoExpandGroupedItems} />}
        {enableGrouping && !disableGroupPanel && <GroupPanel visible />}
        {selectionMode && <Selection mode={selectionMode} deferred={deferredModeEnabled} />}
        {children}
        {Columns}
        {masterDetail}
        {(totalSummaryEnabled || groupSummaryEnabled) && (
          <Summary calculateCustomSummary={customCalculationForSummaryRow}>
            {totalSummaryEnabled &&
              totalSummaryColumns?.map(({ name, column, summaryType, customizeText }, idx) => (
                <TotalItem
                  key={idx}
                  name={name}
                  column={column}
                  summaryType={summaryType}
                  showInColumn={column}
                  customizeText={customizeText}
                />
              ))}
            {groupSummaryEnabled &&
              groupSummaryColumns?.map(
                (
                  { name, column, summaryType, customizeText, showInGroupFooter, alignByColumn },
                  idx,
                ) => (
                  <GroupItem
                    key={idx}
                    name={name}
                    column={column}
                    summaryType={summaryType}
                    showInColumn={column}
                    showInGroupFooter={showInGroupFooter}
                    alignByColumn={alignByColumn}
                    customizeText={customizeText}
                  />
                ),
              )}
          </Summary>
        )}
        {enableGrouping && groupingSortBy && (
          <SortByGroupSummaryInfo sortOrder={groupingSortOrder} summaryItem={groupingSortBy} />
        )}
      </DataGrid>
    </>
  );
};
