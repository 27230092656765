import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { putApi } from '../../../../../apiUtils';
import { Loader } from '../../../../../components/loader/Loader';
import { useTranslation } from '../../../../../contexts/translation';
import { TaskStep, TaskStepStatus } from '../../../../../types/step.types';
import { TaskActionTypes } from '../../../../../types/taskActionTypes';
import { TaskStatusApiResponse } from '../../../../../types/TaskStatus';
import { API_URL, fetcher } from '../../../../../utils/apiUrl';
import { swrConfig } from '../../../../Assets/AssetsEdit';
import { StepRow } from './StepRow';

interface Props {
  taskId: number;
  taskResponsiblePersonId: number | null;
  isReadonly?: boolean;
  isBackButton?: boolean;
  onSubmit: () => void;
  onBack: () => void;
}

export function StepsContainer({
  taskId,
  taskResponsiblePersonId,
  onSubmit,
  isReadonly = false,
  isBackButton = false,
  onBack,
}: Props) {
  const transFormName = 'Task.Step';
  const { translate } = useTranslation();

  // allowed actions for task steps - from TASK_PROCESS_CONFIG
  const [isCompleteAllowed, setIsCompleteAllowed] = useState<boolean>(false);
  const [isCancelAllowed, setIsCancelAllowed] = useState<boolean>(false);
  const [isReopenAllowed, setIsReopenAllowed] = useState<boolean>(false);

  const { data: steps, mutate: mutateSteps } = useSWR<TaskStep[]>(
    `${API_URL.TASK_STEP_LIST}?parentId=${taskId}`,
    fetcher,
    swrConfig,
  );

  // fetch task process config settings for actions
  const { data: taskProcessConfigList } = useSWR<TaskStatusApiResponse>(
    `${API_URL.TASK_PROCESS_CONFIG}?taskId=${taskId}`,
    fetcher,
    swrConfig,
  );

  // nastavení akcí podle TASK_PROCESS_CONFIG
  useEffect(() => {
    if (!taskProcessConfigList) return;
    setIsReopenAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_REOPEN),
    );
    setIsCompleteAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_COMPLETE),
    );
    setIsCancelAllowed(
      taskProcessConfigList.some((x) => x.actionId === TaskActionTypes.STEP_CANCEL),
    );
  }, [taskProcessConfigList]);

  const checkStep = (stepId: number) => {
    handleApiCall({ type: TaskStepStatus.COMPLETE, stepId: stepId });
    mutateSteps();
    onSubmit();
  };

  const uncheckStep = (stepId: number) => {
    handleApiCall({ type: TaskStepStatus.OPEN, stepId: stepId });
    mutateSteps();
    onSubmit();
  };

  const cancelStep = (stepId: number) => {
    putApi({
      url: API_URL.TASK_STEP_CANCEL,
      data: { stepId: stepId, comment: undefined },
      callAfterSuccess: () => {
        mutateSteps();
        onSubmit();
      },
    });
  };

  const handleApiCall = async ({ type, stepId }) => {
    switch (type) {
      case TaskStepStatus.OPEN:
        await putApi({
          url: API_URL.TASK_STEP_REOPEN,
          data: { stepId: stepId, comment: undefined },
        });
        break;
      case TaskStepStatus.COMPLETE:
        if (!taskResponsiblePersonId) {
          notify(
            translate!(
              'Task step cannot be completed. There is not a responsible person.',
              transFormName,
            ),
            'error',
          );
          return;
        }

        await putApi({
          url: API_URL.TASK_STEP_COMPLETE,
          data: { stepId: stepId, comment: undefined, userId: taskResponsiblePersonId },
        });
        break;
      default:
        break;
    }

    mutateSteps();
  };

  if (!taskProcessConfigList) return <Loader />;

  return (
    <>
      {isBackButton && (
        <Button
          width={'100%'}
          height={40}
          type={'normal'}
          text={translate!('Close', '')}
          onClick={() => onBack()}
        />
      )}
      <table className="step-table dx-datagrid-table dx-datagrid-table-fixed">
        <tbody>
          {steps?.map((item, index) => (
            <StepRow
              key={index}
              step={item}
              onCheck={checkStep}
              onUncheck={uncheckStep}
              onCancel={cancelStep}
              isCompleteAllowed={isCompleteAllowed}
              isCancelAllowed={isCancelAllowed}
              isReopenAllowed={isReopenAllowed}
              isReadonly={isReadonly}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
