import { IDataType, SisDataGridColumns } from '../../components/table';

export const Columns: SisDataGridColumns = [
  {
    dataType: IDataType.number,
    dataField: 'id',
    caption: 'Asset class id',
    width: '5%',
  },
  {
    dataType: IDataType.string,
    dataField: 'nameTranslated',
    caption: 'Name',
    width: '88%',
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    caption: 'Visible',
    width: '7%',
  },
];
