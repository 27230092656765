import { ContextMenu } from 'devextreme-react';
import { isFunction } from 'ramda-extension';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Columns } from '../../pages/Reports/WorkSheets/Columns';
import { paintCellByWorkSheetColor } from '../../pages/WorkSheets/utils/paintCellByColor';
import { paintRowByWorkSheetColor } from '../../pages/WorkSheets/utils/paintRowByColor';
import { getWidthByColumnsByStorageKey } from '../../utils/datagridNoResponsive';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { DatagridNoResponsive } from '../datagridNoResponsive/DatagridNoResponsive';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  handleCreateWorksheet?: (e) => void;
  dataGridKey: string;
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
  pathname: string;
  datagridJsonData?: any;
}

export const WorkSheetsReportDataGrid = ({
  data,
  handleEdit,
  handleCreateWorksheet,
  resetFilter,
  dataGridKey,
  dataGridRef,
  userCanCreate,
  heading,
  filterVisibility,
  setFilterVisibility,
  pathname,
  datagridJsonData,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkSheet.List';
  const transFormReportBar = 'Reports.Bar';
  const history = useHistory();
  const [selectedPath, setSelectedPath] = useLocalStorage(STORAGE_KEYS.CONTEXT_STATE, null);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => history.push(`/worksheets/new/`),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };

  // po kliku na možnost v context menu
  const contextMenuItemClick = () => {
    if (!selectedPath || !selectedPath.path) return;
    // jen interní link na tasky
    window.open('#' + selectedPath?.path, '_blank');
  };

  // možnosti, co se zobrazí v context menu - po kliku pravým tlačítkem
  const contextMenuItems = [
    {
      text: translate!('Open link in new tab', ''),
      icon: 'dx-icon-globe',
      onclick: () => contextMenuItemClick(),
    },
  ];

  const handleSetPath = (o: any) => setSelectedPath(o);

  const columns = Columns(handleEdit, history, translate, pathname, handleSetPath);

  return (
    <>
      <DatagridNoResponsive
        datagridWidth={getWidthByColumnsByStorageKey(
          STORAGE_KEYS.WORKSHEETS_REPORT_DATAGRID_DESKTOP,
          columns,
        )}
      >
        <SisDataGrid
          enableXlsxExport
          exportName={'worksheet-data'}
          data={data}
          keyExpr={'id'}
          dataGridRef={dataGridRef}
          transFormName={transFormName}
          stateStoringKey={dataGridKey}
          columns={columns}
          onToolbarPreparing={onToolbarPreparing}
          onRowPrepared={paintRowByWorkSheetColor}
          onCellPrepared={paintCellByWorkSheetColor}
          onCellDblClick={(e) => handleEdit && handleEdit(e)}
          pageSizeChangeVisible={true}
          enableGrouping
          wordWrapEnabled
        />
      </DatagridNoResponsive>
      {/* Context menu pro odkazy */}
      <ContextMenu
        dataSource={contextMenuItems}
        target={'.context-link'}
        onItemClick={contextMenuItemClick}
      />
    </>
  );
};
