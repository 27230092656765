import { STORAGE_KEYS } from './localStorageKeys';

export const ReportType = {
  AssetResponsiblePerson: {
    id: 1,
    key: STORAGE_KEYS.ASSET_RESPONSIBLE_PERSON_DATAGRID_DESKTOP,
  },
  Tasks: {
    id: 2,
    key: STORAGE_KEYS.TASKS_REPORT_DATAGRID_DESKTOP,
  },
  WorkSheets: {
    id: 3,
    key: STORAGE_KEYS.WORKSHEETS_REPORT_DATAGRID_DESKTOP,
  },
  Assets: {
    id: 5,
    key: STORAGE_KEYS.ASSETS_REPORT_DATAGRID_DESKTOP,
  },
  SpareParts: {
    id: 6,
    key: STORAGE_KEYS.SPARE_PARTS_REPORT_DATAGRID_DESKTOP,
  },
  GroupedWorkSheets: {
    id: 7,
    key: STORAGE_KEYS.GROUPED_WORKSHEETS_REPORT_DATAGRID_DESKTOP,
  },
} as const;
