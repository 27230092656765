import { Button } from 'devextreme-react';
import React from 'react';
import './dataGridButton.scss';

interface Props {
  hint: string;
  icon?: string;
  text?: string;
  marginRight?: boolean;
  stylingMode?: 'text' | 'outlined' | 'contained' | undefined;
  cellData: any;
  onClick: (data: any) => void;
}

export function DataGridButton({
  hint,
  icon,
  text,
  marginRight,
  stylingMode,
  cellData,
  onClick,
}: Props) {
  return (
    <div className={`custom-datagrid-button${marginRight ? ' margin-right' : ''}`}>
      <Button
        hint={hint}
        icon={icon && icon}
        text={text ? text : ''}
        visible={true}
        type={'default'}
        stylingMode={stylingMode ? stylingMode : 'text'}
        onClick={() => onClick(cellData)}
      />
    </div>
  );
}
