import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { AssetTypesDataGrid } from '../../components/datagrid/AssetTypesDataGrid';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetType } from '../../types/assetType.type';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';

export function AssetTypes() {
  const history = useHistory();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'Asset types', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  const { data, error } = useSWR<AssetType[]>(
    `${API_URL.ASSET_TYPE_LIST}?visibleOnly=false`,
    fetcher,
    swrConfig,
  );

  const handleEditButton = (e): void => {
    history.push(`/asset-types/edit/${e.row.data.id}`);
  };

  return (
    <PageContentWrapper loading={!data} error={error}>
      <AssetTypesDataGrid data={data} handleEdit={handleEditButton} />
    </PageContentWrapper>
  );
}
export default AssetTypes;
