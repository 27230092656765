import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getItemApi } from '../../../apiUtils';
import { WorkSheetsReportDataGrid } from '../../../components/datagrid/WorkSheetsReportDataGrid';
import { DatagridNoResponsiveContainer } from '../../../components/datagridNoResponsive/DatagridNoResponsiveContainer';
import { Loader } from '../../../components/loader/Loader';
import { useBreadcrumbs } from '../../../contexts/breadcrumbsContext';
import { useTranslation } from '../../../contexts/translation';
import WorkSheetReportFilter from '../../../forms/filter/worksheetReport/WorkSheetReportFilter';
import { useIsTaskCreatePermission } from '../../../hooks/useIsTaskCreatePermission';
import { useSaveDataGridFilter } from '../../../hooks/useSaveDataGridFilter';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ReportsState } from '../../../types/locationState.types';
import { ReportDefaultSettingParams } from '../../../types/pageParams.types';
import { WorkSheet } from '../../../types/workSheet.types';
import { API_URL } from '../../../utils/apiUrl';
import { STORAGE_KEYS } from '../../../utils/localStorageKeys';
import { isNilOrEmpty } from '../../../utils/ramdaUtils';
import { ReportType } from '../../../utils/reportTypeKeys';
import { tabsIndexes } from '../../WorkSheets/WorkSheetsEdit';
import '../Reports.scss';

export function WorkSheetReport() {
  const [data, setData] = useState<Array<WorkSheet> | null>([]);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const { settingId } = useParams<ReportDefaultSettingParams>();
  const transFormName = 'WorkSheet.List';

  useEffect(() => {}, [settingId]);

  const reportType = ReportType.WorkSheets;

  const history = useHistory();
  // Načtení práv uživatele
  const [userHasPermissionToCreate, loadingPermission] = useIsTaskCreatePermission();
  // Reference na dataGrid. Nutné např. kvůli ukládání nastavení.
  const dataGridRef = useRef<any>(null);
  // Indikátor, zda se má provést reset filtru DataGridu i filtru mimo DataGrid
  const [resetFilterAction, setResetFilterAction] = useState<boolean>(false);
  const { state }: { state: ReportsState | undefined } = useLocation();

  const { getActualJsonState, loadFilter, resetFilter, saveFilter } = useSaveDataGridFilter(
    reportType.key,
    dataGridRef,
  );

  const [datagridSetting] = useSessionStorage(STORAGE_KEYS.WORKSHEETS_REPORT_DATAGRID_DESKTOP, {});
  useEffect(() => {
    if (state?.loadSettingFromSession) loadFilter(datagridSetting);
  }, [state]);

  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({ pageName: 'WorkSheets', steps: [{ name: 'Home', pathToCutCount: 1 }] });
    return () => {
      setValue(null);
    };
  }, []);

  useEffect(() => {
    if (resetFilterAction) {
      resetFilter();
      setResetFilterAction(false);
    }
    saveFilter();

    if (!dataGridRef.current || !dataGridRef.current.instance) return;
  }, [resetFilterAction]);

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleEditButton = (e): void => {
    if (!isNilOrEmpty(e.row) && !isNilOrEmpty(e.row.data)) {
      history.push({
        pathname: `/worksheets/edit/${e.row.data.id}`,
        state: { backRoute: pathname },
      });
    }
  };

  /**
   * Handle editačního tlačítka v DataGridu
   * @param e  Event
   */
  const handleCreateWorksheetButton = (e): void => {
    if (isNilOrEmpty(e.row) || isNilOrEmpty(e.row.data)) return;

    // zjistíme počet výkazů - WORKSHEET_GET_COUNT_BY_TASK
    getItemApi<number>({
      url: `${API_URL.WORKSHEET_GET_COUNT_BY_TASK}?parentId=${e.row.data.id}`,
      params: {},
      callAfterSuccess: (result: number) => {
        // neexistuje výkaz, přesměrujeme na create
        if (result === 0) {
          history.push({
            pathname: '/workSheets/new/0/' + e.row.data.id,
            state: { backRoute: `/reports/${reportType.id}` },
          });
          return;
        }

        // jinak přesměrujeme
        history.push({
          pathname: `/worksheets/edit/${e.row.data.id}`,
          state: { defaultTabIndex: tabsIndexes.GENERAL },
        });
      },
      hideNotifications: true,
    });
  };

  if (!data) return <Loader />;

  return ( 
    <DatagridNoResponsiveContainer
      heading={isFilterVisible ? translate!('WorkSheets', transFormName) : ''}
    >
      <WorkSheetReportFilter
        dataGridRef={dataGridRef}
        onDataChange={setData}
        report={reportType}
        onFilterChange={setSelectedFilter}
        filterVisibility={isFilterVisible}
        loadDefaultSetting={!state?.loadSettingFromSession}
      />
      <WorkSheetsReportDataGrid
        datagridJsonData={datagridSetting}
        dataGridRef={dataGridRef}
        data={data}
        resetFilter={() => setResetFilterAction(true)}
        userCanCreate={userHasPermissionToCreate}
        dataGridKey={reportType.key}
        handleEdit={handleEditButton}
        handleCreateWorksheet={handleCreateWorksheetButton}
        heading={`${translate!('WorkSheets', transFormName)}${
          selectedFilter ? ` - ${selectedFilter}` : ''
        }`}
        filterVisibility={isFilterVisible}
        setFilterVisibility={setFilterVisible}
        pathname={pathname}
      />
    </DatagridNoResponsiveContainer>
  );
}
export default forwardRef(WorkSheetReport);
