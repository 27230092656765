import TabPanel from 'devextreme-react/tab-panel';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import useSWR from 'swr';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useTranslation } from '../../contexts/translation';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { DetailPageParams } from '../../types/pageParams.types';
import { WorkSheet } from '../../types/workSheet.types';
import { API_URL, fetcher } from '../../utils/apiUrl';
import { swrConfig } from '../Assets/AssetsEdit';
import { GeneralSheetTab } from './Tabs/GeneralTab';

export const tabsIndexes = {
  GENERAL: 0,
};

export function WorkSheetsEdit() {
  const { translate } = useTranslation();
  const transFormNameTabs = 'WorkSheet.Tabs';
  const transFormNameUpdate = 'WorkSheet.Update';
  const transFormNameTaskStatus = 'WorkSheetStatus';
  const { id } = useParams<DetailPageParams>();
  const lastLocation = useLastLocation();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit workSheet',
      steps: [{ name: 'Home', pathToCutCount: 3 }, getLastLocationCrumb()],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const {
    data: workSheet,
    error: errorSheet,
    isValidating: sheetLoading,
  } = useSWR<WorkSheet>(`${API_URL.WORKSHEET_ITEM}?id=${id}`, fetcher, swrConfig);
  /*
  const {
    data: attachmentsCount,
    error: attachmentsCountError,
    isValidating: isValidatingAttachmentCount,
  } = useSWR(id ? `${API_URL.FILE_COUNT}?entity=WorkSheet&entityId=${id}` : null, fetcher, swrConfig);
  */
  const getLastLocationCrumb = () => {
    return { name: 'WorkSheets', pathToCutCount: 2 };
  };

  const a = [
    {
      title: translate!('General', transFormNameTabs),
      index: 0,
      component: <GeneralSheetTab id={id} active={equals(0, currentTab)} />,
    },
  ];
  return (
    <>
      <PageContentWrapper
        heading={`${translate!('Edit workSheet', transFormNameUpdate)}: ${
          workSheet?.taskCustomId
        }, ${workSheet?.taskName}`}
        loading={sheetLoading}
        error={errorSheet}
      >
        <TabPanel
          selectedIndex={currentTab}
          dataSource={a}
          itemRender={(a) => a.component}
          activeStateEnabled={true}
          onTitleClick={(e: any) => setCurrentTab(e.itemIndex)}
          deferRendering={false}
          focusStateEnabled={false}
        />
      </PageContentWrapper>
    </>
  );
}

export default WorkSheetsEdit;
