import React from 'react';

type Props = {
  label?: string;
  text?: string;
};

export default function FieldLabel({ label, text }: Props) {
  return (
    <span style={{ fontSize: 10, fontWeight: 400, color: '#aaa' }}>
      {label}: {text}
    </span>
  );
}
