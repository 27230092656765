// @ts-ignore
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../contexts/translation';

export interface Step {
  name: string;
  pathToCutCount: number;
  nameNotTranslate?: string;
  link?: string;
}

interface Props {
  pageName: string;
  steps: Array<Step>;
}

export function Breadcrumbs({ pageName, steps }: Props) {
  const { translate } = useTranslation();
  const transFormName = 'Breadcrumbs';
  const { pathname } = useLocation();
  return (
    <ul className={'breadcrumb'}>
      {steps.map((step, index) => {
        const currentPath = pathname;
        const currentPathArray = currentPath.split('/');
        const lastElementIndex = currentPathArray.length - step.pathToCutCount;
        currentPathArray.splice(lastElementIndex);
        const link = currentPathArray.join('/');
        return (
          <li key={index}>
            <Link to={step.link ? step.link : link} key={index} color="inherit">
              <span>
                {step.nameNotTranslate
                  ? step.nameNotTranslate
                  : translate!(step.name, transFormName)}
              </span>
            </Link>
          </li>
        );
      })}
      <li color="secondary">{translate!(pageName, transFormName)}</li>
    </ul>
  );
}
