import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { putApi } from '../../apiUtils';
import { useBreadcrumbs } from '../../contexts/breadcrumbsContext';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { AssetTypesForm } from '../../forms/assetTypesNew/AssetTypesForm';
import { PageContentWrapper } from '../../layouts/contentWrapper/PageContentWrapper';
import { AssetLevel } from '../../types/assetLevel.types';
import { AssetType } from '../../types/assetType.type';
import { DetailPageParams } from '../../types/pageParams.types';
import { API_URL, fetcher } from '../../utils/apiUrl';

export function AssetTypesEdit() {
  const { translate } = useTranslation();
  const transFormName = 'AssetType.Update';
  const { startLoading, stopLoading } = useLoading();
  const { setValue } = useBreadcrumbs();

  useEffect(() => {
    setValue({
      pageName: 'Edit asset types',
      steps: [
        { name: 'Home', pathToCutCount: 3 },
        { name: 'Asset types', pathToCutCount: 2 },
      ],
    });
    return () => {
      setValue(null);
    };
  }, []);

  const { id } = useParams<DetailPageParams>();
  // Načteme číselník - AssetLevel
  const { data: assetLevels, error: errorAssetLevels } = useSWR<AssetLevel[]>(
    API_URL.ASSET_LEVEL_LIST,
    fetcher,
  );
  // Načteme AssetType
  const { data: assetType, error: errorAssetType } = useSWR<AssetType>(
    `${API_URL.ASSET_TYPE_ITEM}?id=${id}`,
    fetcher,
  );

  const onSubmit = async (e) => {
    if (e !== null) e.preventDefault();
    if (!assetType) return;
    let isOk = false;
    startLoading();
    await putApi({
      url: API_URL.ASSET_TYPE_PUT,
      data: assetType,
      callAfterSuccess: () => {
        isOk = true;
      },
    });
    stopLoading();
    return isOk;
  };

  return (
    <PageContentWrapper
      loading={!assetLevels || !assetType}
      error={errorAssetLevels || errorAssetType}
      heading={translate!('Edit asset type', transFormName)}
    >
      <AssetTypesForm formData={assetType} assetLevels={assetLevels} onSubmit={onSubmit} isEdit />
    </PageContentWrapper>
  );
}
export default AssetTypesEdit;
