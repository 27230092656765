// @ts-ignore
import React from 'react';
import {
  DataGridButton,
  dateFormat,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../components/table';

export const Columns = (handleEdit, translate): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'id',
    width: '10%',
    caption: 'Id',
    cssClass: 'dg-align-center',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    width: '30%',
    caption: 'Name',
  },
  {
    dataType: IDataType.string,
    dataField: 'capacityCategoryName',
    width: '20%',
    caption: 'Capacity category',
    lookupPickBy: 'capacityCategoryName',
  },
  {
    dataType: IDataType.string,
    dataField: 'workActivityGroupName',
    width: '20%',
    caption: 'Work activity group',
    lookupPickBy: 'workActivityGroupName',
  },
  {
    dataType: IDataType.string,
    dataField: 'createdByName',
    width: '20%',
    caption: 'Created by',
  },
  {
    dataField: 'created',
    width: '20%',
    caption: 'Created',
    dataType: IDataType.date,
    format: dateFormat(),
  },
  {
    dataType: IDataType.string,
    dataField: 'updatedByName',
    width: '20%',
    caption: 'Updated by',
  },
  {
    dataField: 'updated',
    width: '20%',
    caption: 'Updated',
    dataType: IDataType.date,
    format: dateFormat(),
  },
  {
    dataType: IDataType.boolean,
    dataField: 'visible',
    width: '10%',
    caption: 'Visible',
    cssClass: 'dg-align-center',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '15%',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return <DataGridButton hint={translate!('Edit', '')} icon="edit" cellData={cellData} onClick={handleEdit} />;
};
