import React from 'react';
import { DataGridButton, IColumnType, IDataType, SisDataGridColumns } from '../../components/table';

export const Columns = (handleEdit, translate): SisDataGridColumns => [
  {
    dataType: IDataType.number,
    dataField: 'id',
    width: '5%',
    caption: 'Capacity category id',
  },
  {
    dataType: IDataType.string,
    dataField: 'name',
    caption: 'Name',
    width: '20%',
  },
  {
    dataType: IDataType.string,
    dataField: 'description',
    caption: 'Description',
    width: '15%',
  },
  {
    dataType: IDataType.string,
    dataField: 'assetClassName',
    caption: 'Asset class',
    width: '45%',
    lookupPickBy: 'assetClassName',
  },
  {
    dataField: 'visible',
    dataType: IDataType.boolean,
    caption: 'Visible',
    width: '7%',
  },
  {
    type: IColumnType.buttons,
    cellRender: (data) => ButtonCell(data, handleEdit, translate),
    width: '8%',
  },
];

const ButtonCell = (cellData, handleEdit, translate) => {
  return (
    <DataGridButton
      hint={translate!('Edit', '')}
      icon="edit"
      cellData={cellData}
      onClick={() => handleEdit(cellData)}
    />
  );
};
