import { SisDataGridColumns } from '../../../../../components/table';

export const Columns: SisDataGridColumns = [
  {
    dataField: 'prodId',
    caption: 'ID',
    width: '20%',
    allowEditing: false,
  },
  {
    dataField: 'name',
    caption: 'Name',
    width: '20%',
  },
  {
    dataField: 'position',
    caption: 'Position',
    width: '10%',
  },
  {
    dataField: 'qty',
    caption: 'Qty',
    width: '10%',
  },
  {
    dataField: 'note',
    caption: 'Note',
    width: '40%',
  },
];
