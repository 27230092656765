import React from 'react';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/AssetClasses/Columns';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

export const AssetClassesDataGrid = ({ data }: IDataGrid) => {
  const { translate } = useTranslation();
  const transFormName = 'AssetClass.List';

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      html: translate!('Asset classes', transFormName),
    });
  };

  return (
    <SisDataGrid
      data={data}
      keyExpr={'id'}
      transFormName={transFormName}
      columns={Columns}
      allowColumnResizing={false}
      columnChooser={false}
      allowColumnReordering={false}
      onToolbarPreparing={onToolbarPreparing}
    />
  );
};
