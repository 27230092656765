import * as icons from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  DataGridButton,
  dateFormat,
  dateTimeFormat,
  IColumnType,
  IDataType,
  SisDataGridColumns,
} from '../../../components/table';
import { AttachmentCountFormat } from '../../../utils/customRenderer';
import { TimeSpanCell, TimeSpanCellSortValue, TypeNameCell } from '../../Tasks/Columns';
import '../Reports.scss';

export const Columns = (
  handleEdit,
  handleCreateWorksheet,
  handleCopy,
  isWorksheetRole,
  translate,
): SisDataGridColumns => [
  {
    dataType: IDataType.string,
    dataField: 'customId',
    width: '100px',
    caption: 'Task id',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'typeNameTranslated',
    width: '100px',
    caption: 'Type',
    lookupPickBy: 'typeNameTranslated',
    cssClass: 'dg-align-center',
    cellRender: TypeNameCell,
  },
  {
    dataField: 'capacityCategoryName',
    width: '100px',
    caption: 'Capacity category',
    lookupPickBy: 'capacityCategoryName',
  },

  {
    dataField: 'statusNameTranslated',
    width: '100px',
    caption: 'Status',
    lookupPickBy: 'statusNameTranslated',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'assetTopLevelParenName',
    caption: 'Line',
    width: '100px',
  },
  {
    dataField: 'assetName',
    width: '150px',
    caption: 'Asset',
    lookupPickBy: 'assetName',
  },
  {
    dataField: 'taskName',
    width: '200px',
    caption: 'Task name',
    cssClass: 'dg-bold',
  },
  {
    dataField: 'priorityName',
    caption: 'Priority',
    width: '50px',
  },
  {
    dataField: 'urgeLevel',
    caption: 'Urge',
    width: '50px',
    cssClass: 'dg-align-center',
  },
  {
    dataField: 'responsiblePersonName',
    width: '80px',
    caption: 'Responsible person',
    lookupPickBy: 'responsiblePersonName',
  },
  {
    dataField: 'createdByName',
    width: '80px',
    caption: 'Created by',
  },
  {
    dataField: 'timePeriodName',
    width: '80px',
    caption: 'Time period',
  },
  {
    dataField: 'attachmentsCount',
    width: '10px',
    caption: 'Attachment count',
    cellRender: AttachmentCountFormat,
  },
  {
    dataField: 'requiredDate',
    width: '80px',
    caption: 'Required date',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateFormat(),
  },
  {
    dataField: 'ratingImage',
    width: '80px',
    caption: 'Rating',
    cssClass: 'dg-align-center',
    cellRender: (row) => renderGridIcon(row.data),
  },
  {
    dataField: 'created',
    width: '100px',
    caption: 'Created',
    dataType: IDataType.date,
    cssClass: 'dg-align-right',
    format: dateFormat(),
  },
  {
    dataField: 'taken',
    width: '80px',
    caption: 'Taken',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'started',
    width: '80px',
    caption: 'Started',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'finished',
    width: '80px',
    caption: 'Finished',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'totalSolutionTime',
    width: '80px',
    caption: 'Total solution time',
    cssClass: 'dg-align-right',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'totalSolutionTime');
    },
  },
  {
    dataField: 'downtimeFrom',
    width: '80px',
    caption: 'Downtime from',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'downtimeTo',
    width: '80px',
    caption: 'Downtime to',
    dataType: IDataType.dateTime,
    cssClass: 'dg-align-right',
    format: dateTimeFormat(),
  },
  {
    dataField: 'reactionTimeMaintenance',
    width: '80px',
    caption: 'Reaction time maintenance',
    cssClass: 'dg-align-right',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'reactionTimeMaintenance');
    },
  },
  {
    dataField: 'reparationTime',
    width: '80px',
    caption: 'Reparation time',
    cssClass: 'dg-align-right',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'reparationTime');
    },
  },
  {
    dataField: 'reactionTimeProduction',
    width: '80px',
    caption: 'Reaction time production',
    cssClass: 'dg-align-right',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'reactionTimeProduction');
    },
  },
  {
    dataField: 'downTimeTime',
    width: '80px',
    caption: 'Downtime time',
    cssClass: 'dg-align-right',
    cellRender: TimeSpanCell,
    calculateSortValue: function (rowData) {
      return TimeSpanCellSortValue(rowData, 'downTimeTime');
    },
  },
  {
    dataField: 'worksheetsCount',
    width: '80px',
    caption: 'Worksheet count',
    cssClass: 'dg-align-center',
  },

  {
    type: IColumnType.buttons,
    cellRender: (data) =>
      ButtonCell(data, handleEdit, handleCreateWorksheet, handleCopy, isWorksheetRole, translate),
    width: '80px',
  },
];

const renderGridIcon = (data) => {
  if (!data.ratingImage) return '';
  return (
    <FontAwesomeIcon
      icon={icons[data.ratingImage]}
      style={{
        fontSize: '1.5em',
        color: data.ratingColor ? data.ratingColor : '#000',
      }}
    />
  );
};

const ButtonCell = (
  cellData,
  handleEdit,
  handleCreateWorksheet,
  handleCopy,
  isWorksheetRole,
  translate,
) => {
  return (
    <>
      <DataGridButton
        hint={translate!('Edit', '')}
        icon="edit"
        marginRight={isWorksheetRole}
        cellData={cellData}
        onClick={handleEdit}
      />
      <DataGridButton
        hint={translate!('Copy', '')}
        icon="copy"
        marginRight={isWorksheetRole}
        cellData={cellData}
        onClick={handleCopy}
      />
      {isWorksheetRole && (
        <DataGridButton
          hint={translate!('Create WorkSheet', 'WorkSheet.List')}
          icon="clock"
          cellData={cellData}
          onClick={handleCreateWorksheet}
        />
      )}
    </>
  );
};
