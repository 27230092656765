import { ContextMenu } from 'devextreme-react';
import { isFunction } from 'ramda-extension';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '../../contexts/translation';
import { Columns } from '../../pages/Reports/GroupedWorkSheets/Columns';
import { paintCellByWorkSheetColor } from '../../pages/WorkSheets/utils/paintCellByColor';
import { paintRowByWorkSheetColor } from '../../pages/WorkSheets/utils/paintRowByColor';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';
import { ReportType } from '../../utils/reportTypeKeys';
import { SisDataGrid } from '../table';
import { IDataGrid } from './AssetsDataGrid';

interface Props extends IDataGrid {
  userCanCreate?: boolean;
  handleCreateWorksheet?: (e) => void;
  dataGridKey: string;
  heading: string;
  filterVisibility: boolean;
  setFilterVisibility: (e: boolean) => void;
  pathname: string;
}

export const GroupedWorkSheetsReportDataGrid = ({
  data,
  handleEdit,
  handleCreateWorksheet,
  resetFilter,
  dataGridKey,
  dataGridRef,
  userCanCreate,
  heading,
  filterVisibility,
  setFilterVisibility,
  pathname,
}: Props) => {
  const { translate } = useTranslation();
  const transFormName = 'WorkSheet.List';
  const transFormReportBar = 'Reports.Bar';
  const history = useHistory();
  const reportType = ReportType.GroupedWorkSheets;

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        text: heading,
        visible: !filterVisibility,
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: userCanCreate,
          icon: 'add',
          type: 'default',
          stylingMode: 'contained',
          onClick: () =>
            history.push({
              pathname: '/worksheets/new/',
              state: { backRoute: `/reports/${reportType.id}` },
            }),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          visible: true,
          text: translate!(filterVisibility ? 'Hide' : 'Show', transFormReportBar),
          type: 'normal',
          onClick: () => setFilterVisibility(!filterVisibility),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'clear',
          type: 'info',
          text: translate!('Clear filter', transFormName),
          stylingMode: 'outlined',
          onClick: () => {
            if (resetFilter && isFunction(resetFilter)) {
              resetFilter();
            }
          },
        },
      },
    );
  };

  // po kliku na možnost v context menu
  const contextMenuItemClick = () => {
    const selectedPath = window.localStorage.getItem(STORAGE_KEYS.CONTEXT_STATE);
    if (!selectedPath) return;
    const _selectedPath = JSON.parse(selectedPath);
    // jen interní link na tasky
    if (_selectedPath.path) window.open('#' + _selectedPath.path, '_blank');
  };

  // možnosti, co se zobrazí v context menu - po kliku pravým tlačítkem
  const contextMenuItems = [
    {
      text: translate!('Open link in new tab', ''),
      icon: 'dx-icon-globe',
      onclick: () => contextMenuItemClick(),
    },
  ];

  return (
    <>
      <SisDataGrid
        enableXlsxExport
        exportName={'worksheet-data'}
        data={data}
        keyExpr={'id'}
        dataGridRef={dataGridRef}
        transFormName={transFormName}
        stateStoringKey={dataGridKey}
        columns={Columns(handleEdit, history, translate, pathname)}
        onToolbarPreparing={onToolbarPreparing}
        onRowPrepared={paintRowByWorkSheetColor}
        onCellPrepared={paintCellByWorkSheetColor}
        onCellDblClick={(e) => e.rowType !== 'group' && handleEdit && handleEdit(e)}
        pageSizeChangeVisible={true}
        enableGrouping
        groupSummaryEnabled
        groupSummaryColumns={[
          { name: '', column: 'taskName', summaryType: 'count' },
          {
            name: 'TimeCount',
            column: 'activityDuration',
            summaryType: 'custom',
            customizeText: (item) => `Total: ${item.value}`,
          },
        ]}
        autoExpandGroupedItems={false}
        wordWrapEnabled
        groupingSortBy="count"
        groupingSortOrder="desc"
      />
      {/* Context menu pro odkazy */}
      <ContextMenu
        dataSource={contextMenuItems}
        target={'.context-link'}
        onItemClick={contextMenuItemClick}
      />
    </>
  );
};
