import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getItemApi } from '../../../apiUtils';
import { useMobileAuth } from '../../../contexts/mobileAuthContext';
import { useTranslation } from '../../../contexts/translation';
import { UserRoles } from '../../../types/applicationUser.types';
import { Asset } from '../../../types/asset.types';
import { WorkSheet } from '../../../types/workSheet.types';
import { API_URL } from '../../../utils/apiUrl';
import { userHasAccess } from '../../../utils/userHasAccess';

interface Props {
  assetId: number;
}

export interface PageParams {
  assetId: string;
}

export function WorksheetCreateDetailFn() {
  const { assetId } = useParams<PageParams>();

  const { user } = useMobileAuth();
  const { translate } = useTranslation();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    handleRedirectToWorksheetCreateDetail(Number(assetId));
  }, []);

  const handleRedirectToWorksheetCreateDetail = (assetId: number) => {
    if (
      !userHasAccess(user?.roles, [UserRoles.WorksheetAll]) &&
      !userHasAccess(user?.roles, [UserRoles.WorksheetOwn])
    ) {
      //staci mit WorksheetAll nebo WorksheetOwn
      setErrorMessage(translate!("You don't have permissions for this action.", ''));
      return;
    }

    // Uživatel má práva - podíváme se, jestli existuje výkaz, jinak jdeme na create
    getItemApi<WorkSheet>({
      url: `${API_URL.WORKSHEET_GET_OPENED_FOR_ASSET}?assetId=${assetId}`,
      params: {},
      callAfterSuccess: (result: WorkSheet) => {
        history.push(`/mobile/worksheets/detail/${result.id}`);
      },
      callAfterError: () => {
        // Worksheet neexistuje, chceme přesměrovat na založení nového
        // Nejdříve je nutné zkontrolovat, jestli existuje Asset
        getItemApi<Asset>({
          url: `${API_URL.ASSET_ITEM}?id=${assetId}`,
          params: {},
          callAfterSuccess: (result: Asset) => {
            if (!result) {
              // Asset neexistuje, zobrazíme chybu
              setErrorMessage(translate!('Asset does not exist.', 'WorkSheet.Create'));
              return;
            }
            // Asset existuje, můžeme přesměrovat
            history.push(`/mobile/worksheets/new/${assetId}`);
          },
          hideNotifications: true,
        });
      },
      hideNotifications: true,
    });
  };

  if (errorMessage) return <>{errorMessage}</>;

  return <></>;
}
