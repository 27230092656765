import Form, {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import { and, not } from 'ramda';
import { isFunction, isNilOrEmpty, isTruthy } from 'ramda-extension';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import QrHiddenComponent from '../../components/qr/QrHiddenComponent';
import { useAuth } from '../../contexts/auth';
import { useLoading } from '../../contexts/loadingContext';
import { useTranslation } from '../../contexts/translation';
import { useClearButtonProps } from '../../hooks/useClearButtonProps';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';
import { UserRoles } from '../../types/applicationUser.types';
import { IForm } from '../../types/iForm';
import { LocationState } from '../../types/locationState.types';
import { API_URL } from '../../utils/apiUrl';
import { validationNullableDateRule } from '../../utils/dateValidation';
import { formTranslationByEdit } from '../../utils/formTranslationByEdit';
import { STORAGE_KEYS } from '../../utils/localStorageKeys';

export interface IAssetGeneralForm extends IForm {
  formData: any;
  defaultFormData: any;
  isReadOnly: boolean;
  assets: any;
  levels: any;
  assetLocations: any;
  handleAssetLevelChange: (e: any) => void;
  getPrimaryImage?: () => any;
  getAssetTypes: () => void;
  assetStatuses: any;
  dateFieldOptions: object;
  persons: any;
  handleCloseButton: (e: any) => void;
  handleTypeChange?: (e: any) => void;
  setWho?: (s: string) => void;
  isEdit?: boolean;
  isCopy?: boolean;
  isNewInstance?: boolean;
  setNewInstance?: (value: boolean) => void;
}

export const AssetGeneralForm = ({
  formData,
  defaultFormData,
  setWho,
  isEdit,
  persons,
  getAssetTypes,
  assetStatuses,
  assetLocations,
  dateFieldOptions,
  onSubmit,
  isReadOnly,
  levels,
  assets,
  handleAssetLevelChange,
  handleTypeChange,
  getPrimaryImage,
  isCopy,
  isNewInstance,
  setNewInstance,
}: IAssetGeneralForm) => {
  const { translate } = useTranslation();
  const transFormName = formTranslationByEdit('Asset', isEdit);
  const history = useHistory();
  const [qrCode, setQrCode] = useState<any>(null);
  const { state }: { state: LocationState | undefined } = useLocation();
  const [contextState] = useLocalStorage(STORAGE_KEYS.CONTEXT_STATE, {});

  const backRoute = state?.backRoute
    ? state.backRoute
    : (contextState && contextState.backRoute) ?? '/assets';
  const formRef = useRef<Form>(null);
  const { user } = useAuth();
  const { loading } = useLoading();
  const canEdit = user?.roles.includes(UserRoles.AssetEdit);
  const [
    prompt,
    setDirty,
    setClean,
    handleBackButton,
    handleHistoryGoBackButton,
    handleDirtyWithCallback,
    isDirty,
  ] = useUnsavedChangesWarning(backRoute, () => {
    if (!formRef) return;
    if (formRef.current!.instance.validate().isValid) {
      setClean();
      onSubmit(null).then((result) => {
        if (result)
          history.push({
            pathname: backRoute,
            state: { loadSettingFromSession: true },
          });
        else setDirty();
      });
    }
  });

  useEffect(() => {
    isNewInstance && invalidateForm();
  }, [formData]);

  /**
   * Stažení QR kódu
   */
  useEffect(() => {
    const fetchQr = async () => {
      const response = await fetch(
        `${API_URL.QR_CODE_WORKSHEET_REDIRECT}?assetId=${formData?.id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      );
      if (response.status === 200) {
        const data = await response.blob();
        const fileUrl: string = URL.createObjectURL(data);
        setQrCode(fileUrl);
      }
    };
    canEdit && formData && formData?.id && fetchQr();
  }, [formData?.id]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
  });

  const levelIdProps = useClearButtonProps();
  const typeIdProps = useClearButtonProps();
  const parentAssetIdProps = useClearButtonProps();
  const statusIdProps = useClearButtonProps();
  const producedWhenProps = useClearButtonProps();
  const deliveredWhenProps = useClearButtonProps();
  const assetLocationIdProps = useClearButtonProps();
  const activatedWhenProps = useClearButtonProps();
  const responsiblePersonIdProps = useClearButtonProps();
  const warrantyUntilProps = useClearButtonProps();

  const invalidateForm = () => {
    formRef.current?.instance.option('formData', defaultFormData);
  };

  return (
    <form
      onSubmit={(e) => {
        // @ts-ignore
        setClean();
        onSubmit(e);
      }}
    >
      {prompt}
      <Form
        colCount={3}
        formData={formData}
        onFieldDataChanged={() => {
          // @ts-ignore
          setDirty();
          isNewInstance && setNewInstance && setNewInstance(false);
        }}
        showColonAfterLabel={true}
        showValidationSummary={true}
        ref={formRef}
      >
        <GroupItem>
          <SimpleItem dataField="name" editorType="dxTextBox">
            <Label text={translate!('Name', transFormName)} />
            <RequiredRule
              message={translate!('Name must be filled in and have to be unique', transFormName)}
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="levelId"
            editorType="dxSelectBox"
            editorOptions={{
              items: levels,
              valueExpr: 'id',
              displayExpr: 'name',
              onValueChanged: handleAssetLevelChange,
              ...levelIdProps,
            }}
          >
            <Label text={translate!('Asset level', transFormName)} />
            <RequiredRule message={translate!('Asset level is required', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          {getPrimaryImage &&
            and(not(isNilOrEmpty(getPrimaryImage)), isFunction(getPrimaryImage)) &&
            getPrimaryImage()}
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="typeId"
            editorType="dxSelectBox"
            editorOptions={{
              items: getAssetTypes(),
              valueExpr: 'id',
              displayExpr: 'name',
              onValueChanged: handleTypeChange,
              ...typeIdProps,
            }}
          >
            <Label text={translate!('Asset type', transFormName)} />
            <RequiredRule message={translate!('Select asset type', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="parentAssetId"
            editorType="dxSelectBox"
            editorOptions={{
              items: assets,
              valueExpr: 'id',
              displayExpr: 'name',
              ...parentAssetIdProps,
            }}
          >
            <Label text={translate!('Parent asset', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="statusId"
            editorType="dxSelectBox"
            editorOptions={{
              items: assetStatuses,
              valueExpr: 'id',
              displayExpr: 'nameTranslated',
              ...statusIdProps,
            }}
          >
            <Label text={translate!('Status', transFormName)} />
            <RequiredRule message={translate!('Select asset status', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="assetLocationId"
            editorType="dxSelectBox"
            editorOptions={{
              items: assetLocations,
              valueExpr: 'id',
              displayExpr: 'name',
              ...assetLocationIdProps,
            }}
          >
            <Label text={translate!('Asset location', transFormName)} />
            <RequiredRule message={translate!('Select asset location', transFormName)} />
          </SimpleItem>
        </GroupItem>

        <GroupItem>
          <SimpleItem dataField="inventoryNumber" editorType="dxTextBox">
            <Label text={translate!('Inventory number', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="serialNumber" editorType="dxTextBox">
            <Label text={translate!('Serial number', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="partNumber" editorType="dxTextBox">
            <Label text={translate!('Part number', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="model" editorType="dxTextBox">
            <Label text={translate!('Model', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="producedWhen"
            editorType="dxDateBox"
            editorOptions={{
              ...dateFieldOptions,
              ...producedWhenProps,
            }}
          >
            <Label text={translate!('Produced when', transFormName)} />
            <CustomRule
              validationCallback={validationNullableDateRule}
              type={'custom'}
              message={'Something bad with time'}
              ignoreEmptyValue
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="producedBySupplierName" editorType="dxTextBox">
            <Label text={translate!('Produced by supplier name', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="deliveredWhen"
            editorType="dxDateBox"
            editorOptions={{
              ...dateFieldOptions,
              ...deliveredWhenProps,
            }}
          >
            <CustomRule
              validationCallback={validationNullableDateRule}
              type={'custom'}
              message={'Somethind bad with time'}
              ignoreEmptyValue
            />
            <Label text={translate!('Delivered when', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="deliveredBySupplierName" editorType="dxTextBox">
            <Label text={translate!('Delivered by supplier name', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="activatedWhen"
            editorType="dxDateBox"
            editorOptions={{
              ...dateFieldOptions,
              ...activatedWhenProps,
            }}
          >
            <Label text={translate!('Activated when', transFormName)} />
            <CustomRule
              validationCallback={validationNullableDateRule}
              type={'custom'}
              message={'Something bad with time'}
              ignoreEmptyValue
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="ownerName" editorType="dxTextBox">
            <Label text={translate!('Owner name', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="warrantyUntil"
            editorType="dxDateBox"
            editorOptions={{
              ...dateFieldOptions,
              ...warrantyUntilProps,
            }}
          >
            <Label text={translate!('Warranty until', transFormName)} />
            <CustomRule
              validationCallback={validationNullableDateRule}
              type={'custom'}
              message={'Something bad with time'}
              ignoreEmptyValue
            />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem
            dataField="responsiblePersonId"
            editorType="dxSelectBox"
            editorOptions={{
              items: persons,
              valueExpr: 'personalNumber',
              displayExpr: 'fullName',
              ...responsiblePersonIdProps,
            }}
          >
            <Label text={translate!('Responsible person', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="centre" editorType="dxTextBox">
            <Label text={translate!('Centre', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="building" editorType="dxTextBox">
            <Label text={translate!('Building', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="room" editorType="dxTextBox">
            <Label text={translate!('Room', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <SimpleItem dataField="description" editorType="dxTextArea">
            <Label text={translate!('Description', transFormName)} />
          </SimpleItem>
        </GroupItem>
        <GroupItem colSpan={3}>
          <SimpleItem
            dataField="visible"
            editorType="dxCheckBox"
            editorOptions={{
              defaultValue: true,
            }}
          >
            <Label text={translate!('Visible', transFormName)} />
          </SimpleItem>
        </GroupItem>
        {isTruthy(isEdit) && !isReadOnly && (
          <GroupItem colCount={3}>
            <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
              <ButtonOptions
                text={translate!('Close', 'Asset.Update')}
                width={'100%'}
                useSubmitBehavior={false}
                onClick={() =>
                  history.push({
                    pathname: backRoute,
                    state: { loadSettingFromSession: true },
                  })
                }
              />
            </ButtonItem>
            <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
              <ButtonOptions
                text={translate!('Save', transFormName)}
                type={!isDirty && !isCopy ? 'normal' : 'success'}
                disabled={loading || (!isDirty && !isCopy)}
                width={'100%'}
                useSubmitBehavior
              />
            </ButtonItem>
            {canEdit && qrCode && (
              <ButtonItem colSpan={1} verticalAlignment={'center'} horizontalAlignment={'left'}>
                <ButtonOptions
                  text={translate!('Print', transFormName)}
                  type={'normal'}
                  width={'100%'}
                  onClick={handlePrint}
                />
              </ButtonItem>
            )}
          </GroupItem>
        )}
        {!isEdit && (
          <GroupItem colCount={4} colSpan={2}>
            <ButtonItem horizontalAlignment={'left'}>
              <ButtonOptions
                text={translate!('Close', 'Asset.Update')}
                type={'normal'}
                width={'100%'}
                useSubmitBehavior={false}
                onClick={() =>
                  history.push({
                    pathname: backRoute,
                    state: { loadSettingFromSession: true },
                  })
                }
              />
            </ButtonItem>
            <ButtonItem horizontalAlignment={'left'}>
              <ButtonOptions
                width={'100%'}
                type={!isDirty && !isCopy ? 'normal' : 'success'}
                disabled={loading || (!isDirty && !isCopy)}
                text={translate!('Save and add new', transFormName)}
                onClick={() => setWho && setWho('new')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem horizontalAlignment={'left'}>
              <ButtonOptions
                width={'100%'}
                type={!isDirty && !isCopy ? 'normal' : 'success'}
                disabled={loading || (!isDirty && !isCopy)}
                text={translate!('Save and continue', transFormName)}
                onClick={() => setWho && setWho('continue')}
                useSubmitBehavior
              />
            </ButtonItem>
            <ButtonItem horizontalAlignment={'left'}>
              <ButtonOptions
                width={'100%'}
                type={!isDirty && !isCopy ? 'normal' : 'success'}
                disabled={loading || (!isDirty && !isCopy)}
                text={translate!('Save and back to list', transFormName)}
                onClick={() => setWho && setWho('list')}
                useSubmitBehavior
              />
            </ButtonItem>
          </GroupItem>
        )}
      </Form>
      {canEdit && qrCode && (
        <QrHiddenComponent
          ref={componentRef}
          source={qrCode}
          assetId={formData?.id}
          assetName={formData?.name}
        />
      )}
    </form>
  );
};
